import { supplierConstants } from '../_constants';
import { supplierService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const supplierActions = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count,
    //do_count_ingredients
};

function getAll() {
    return dispatch => {
        dispatch(request());

        supplierService.getAll()
            .then(
                supplier => dispatch(success(supplier)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: supplierConstants.GETALL_REQUEST } }
    function success(supplier) { return { type: supplierConstants.GETALL_SUCCESS, supplier } }
    function failure(error) { return { type: supplierConstants.GETALL_FAILURE, error } }
}

function get(id) {
    return dispatch => {
        dispatch(request(id));

        supplierService.get(id)
            .then(
                supplier => {
                    dispatch(success(supplier));
                    //history.push('/ingredientdetails');
                },
                error => dispatch(failure(error))
            );
    };

    function request(id) { return { type: supplierConstants.GET_REQUEST ,id} }
    function success(supplier) { return { type: supplierConstants.GET_SUCCESS, supplier } }
    function failure(error) { return { type: supplierConstants.GET_FAILURE, error } }
}

function add(supplier,link) {
    return dispatch => {
        dispatch(request());

        supplierService.add(supplier)
            .then(
                supplier =>{
                    dispatch(success(supplier));
                    let title_text = <FormattedMessage id="suppliers.actions.supplier.add.success.title"
                                                       defaultMessage="Supplier Action"/>
                    let content_text = <FormattedMessage id="suppliers.actions.supplier.add.success.content"
                                                         defaultMessage="Supplier Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "suppliers.actions.supplier.add.success.title",
                                                   content:"suppliers.actions.supplier.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/ingredientssuppliers');
                } 
            );
    };

    function request() { return { type: supplierConstants.ADD_REQUEST } }
    function success(supplier) { return { type: supplierConstants.ADD_SUCCESS, supplier } }
    function failure(error) { return { type: supplierConstants.ADD_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        supplierService.delete(id)
            .then(
                supplier => { 
                    dispatch(success(id,supplier));
                    let title_text = <FormattedMessage id="suppliers.actions.supplier.delete.success.title"
                                                       defaultMessage="Supplier Action"/>
                    let content_text = <FormattedMessage id="suppliers.actions.supplier.delete.success.content"
                                                         defaultMessage="Supplier Deleted Successfully"/>
                                
                    dispatch(alertActions.success({title: "suppliers.actions.supplier.delete.success.title",
                                                   content:"suppliers.actions.supplier.delete.success.content"}));
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                   history.push('/');
                   history.push('/ingredientssuppliers');
                }
            );
    };

    function request() { return { type: supplierConstants.DELETE_REQUEST } }
    function success(id,supplier) { return { type: supplierConstants.DELETE_SUCCESS,id:id, supplier } }
    function failure(error) { return { type: supplierConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        supplierService.count()
            .then(
                supplier => dispatch(success(supplier)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: supplierConstants.COUNT_REQUEST } }
    function success(supplier) { return { type: supplierConstants.COUNT_SUCCESS, supplier } }
    function failure(error) { return { type: supplierConstants.COUNT_FAILURE, error } }
}

function update(id,category) {
    return dispatch => {
        dispatch(request());

        supplierService.update(id,category)
            .then(
                supplier =>{
                    dispatch(success(id,supplier));
                    let title_text = <FormattedMessage id="suppliers.actions.supplier.update.success.title"
                                                       defaultMessage="Supplier Action"/>
                    let content_text = <FormattedMessage id="suppliers.actions.supplier.update.success.content"
                                                         defaultMessage="Supplier Updated Successfully"/>
                                
                    dispatch(alertActions.success({title: "suppliers.actions.supplier.update.success.title",
                                                   content:"suppliers.actions.supplier.update.success.content"}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/ingredientssuppliers');
                }
            );
    };

    function request() { return { type: supplierConstants.UPDATE_REQUEST } }
    function success(id,supplier) { return { type: supplierConstants.UPDATE_SUCCESS, id:id, supplier } }
    function failure(error) { return { type: supplierConstants.UPDATE_FAILURE, error } }
}

/* function do_count_ingredients() {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.do_count_ingredients()
            .then(
                categoriesIngredient => dispatch(success(categoriesIngredient)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_REQUEST } }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_FAILURE, error } }
} */
