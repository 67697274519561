import React, { Component } from 'react';
import { Button,Header, Form,Grid,Icon,Segment,Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {userActions} from '../_actions'
import {toaster_dispatch,trim, isNumeric,isInteger,ADMIN,OWNER,CompanyTypeOptions} from '../_helpers';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,injectIntl} from 'react-intl';
import {Elements} from 'react-stripe-elements';
/* import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css"
import './PhoneInput.css' */
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {UpdateCardForm} from './Payment/UpdateCardForm';
import {styles} from './Styles.jsx';

//StripeProvider gives us access to the Stripe Object
//i.e Stripe.createToken, stripe.elements() etc
//App loads the stripe script asynchronously in CDM
import {StripeProvider} from 'react-stripe-elements';
import { stripeConstants } from '../_constants';
 
  
class Profile extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
            prevUsers : props.users,
            username: "",
            email:"",
            last_name:"",
            first_name:"",
            company:"",
            address:"",
            city: "",
            zipcode: "",
            country: "",
            state: "",
            phone:"",
            idcompany:"",
            vatnumber:"",
            submitted: false,
            userIn: userIn.user,
            active_role:  userIn.user.role?userIn.user.role:OWNER,
            active_org : userIn.user.active_org,
            org_member: userIn.user.active_org?true:false,
        }
        props.dispatch(userActions.get());
    }

    static getDerivedStateFromProps(props, state) {
        if((props.users!==state.prevUsers) && (props.users.items)){
            //console.log(state)
            if (state.org_member){
                return { prevUsers :  props.users,
                    username: props.users.items.username,
                    email: props.users.items.email,
                    last_name: props.users.items.last_name,
                    first_name: props.users.items.first_name, 
                    company: state.active_org.company,
                    address: state.active_org.address,
                    city: state.active_org.city,
                    zipcode: state.active_org.zipcode,
                    country: state.active_org.country,
                    state: state.active_org.state,
                    phone: props.users.items.phone,
                    companyType: props.users.items.company_type,
                    companySize: props.users.items.company_size,
                    idcompany: state.active_org.idcompany,
                    vatnumber: state.active_org.vatnumber,
                   };
            
            }else{
                return { prevUsers :  props.users,
                    username: props.users.items.username,
                    email: props.users.items.email,
                    last_name: props.users.items.last_name,
                    first_name: props.users.items.first_name, 
                    company: props.users.items.company,
                    address: props.users.items.address,
                    city: props.users.items.city,
                    zipcode: props.users.items.zipcode,
                    country: props.users.items.country,
                    state: props.users.items.state,
                    phone: props.users.items.phone,
                    companyType: props.users.items.company_type,
                    companySize: props.users.items.company_size,
                    idcompany: props.users.items.idcompany,
                    vatnumber: props.users.items.vatnumber,
                   };
            }
        }
        return null;
    }
    
    OnUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    }
    
    OnEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }
    
    OnLastNameChange = (e) => {
        this.setState({ last_name: e.target.value });
    }
    
    OnFirstNameChange = (e) => {
        this.setState({ first_name: e.target.value });
    }

    OnCompanyChange = (e) => {
        this.setState({ company: e.target.value });
    }

    OnAdressChange = (e) => {
         this.setState({ address: e.target.value });
    }

    OnCityChange = (e) => {
         this.setState({ city: e.target.value });
    }

    OnCountryChange = (e) => {
        this.setState({ country: e.target.value });
    }

    OnZipcodeChange = (e) => {
        this.setState({ zipcode: e.target.value });
    } 

    OnStateChange = (e) => {
        this.setState({ state: e.target.value });
    } 

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ phone: number });
    } 

    OnTypeChange = (e, data) => {
        this.setState({ companyType: data.value});
    }

    OnSizeChange = (e) => {
        this.setState({ companySize: e.target.value});
    }

    OnIdCompanyChange = (e) => {
        this.setState({ idcompany: e.target.value });
    } 

    OnVatNumberChange = (e) => {
        this.setState({ vatnumber: e.target.value });
    } 

    assertSizeField(){
        let size = this.state.companySize
        if ((isInteger(size) || isNumeric(size))
          && (size !== "")
          && (size !== 0)
          && Math.trunc(size).toString().length<5)
          return true;
        return false;
    }

    handleNameValidation(name){
        /* if(!name){
            return false;
        } */
        if(name !== ""){
            if(!name.match(/^[a-zA-ZÀ-ú]+$/)){
              return false;
            }      	
        }
        return true;
    }

    handlePhoneValidation(number){
        
        if(number && number !== ""){
            return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
        }

        return true;
    }

    handleZipCodeValidation(zipcode){
        if(zipcode !== ""){
            if (!isNumeric(zipcode)){
                return false;
            }
        }
        return true
    }

    handleUserValidation(){
        const { last_name,first_name,phone,companySize,companyType,active_role } = this.state;

        if (!this.handleNameValidation(last_name)){ 
            return false;
        }
        if (!this.handleNameValidation(first_name)){ 
            return false;
        }

        if (!this.handlePhoneValidation(phone)){ 
            return false;
        }

        if (active_role>=ADMIN){
            if (!this.assertSizeField(companySize)){ 
                return false;
            }
    
            if (companyType==="0"){
                return false;
            }
        }

/*         if (!this.handleEmailValidation(user.email)){ 
            return false;
        } */
        return true;
    }

    handleBillingValidation(){
        const { /*last_name,first_name,*/zipcode } = this.state;

/*         if (!this.handleNameValidation(last_name)){ 
            return false;
        }
        if (!this.handleNameValidation(first_name)){ 
            return false;
        } */
/*         if (!isNumeric(zipcode)){
            return false;
        } */
/*         if (!this.handleEmailValidation(user.email)){ 
            return false;
        } */
        return true;
    }

    onSubmitUser = (e) => {
        e.preventDefault();

        const { username,email,last_name, first_name,phone,companySize,companyType} = this.state;
        const { dispatch} = this.props;
        this.setState({ submitted: true });
    
        if (username && email && this.handleUserValidation()) {
           const user_infos = {
                    username:trim(username),
                    last_name: trim(last_name),
                    first_name:trim(first_name),
                    email: trim(email),
                    phone: phone,
                    company_size:companySize,
                    company_type:companyType,
/*                     address: address,
                    company: company,
                    city: city,
                    zipcode: zipcode,
                    country: country,
                    state: state */
            }
           dispatch(userActions.update(user_infos));
           this.setState({ submitted: false });
        }
      }
    
    onSubmitBilling = (e) => {
        e.preventDefault();

        const { username,email,company,address,city,
            zipcode,country,state,idcompany,vatnumber,org_member,active_org} = this.state;
        const { dispatch} = this.props;
        this.setState({ submitted: true });
    
        if (org_member && active_org ){
                const org_infos = {
                             name:trim(active_org.name),
                             address: address,
                             company: company,
                             city: city,
                             zipcode: zipcode,
                             country: country,
                             state: state,
                             idcompany: idcompany,
                             vatnumber: vatnumber
                }
                this.props.dispatch(userActions.editOrganisation(active_org.id,org_infos,"/profile"));
                this.setState({ submitted: false });
        }else{
            if (username && email && this.handleBillingValidation()) {
                const user_infos = {
                         username:trim(username),
                         email:trim(email),
                         address: address,
                         company: company,
                         city: city,
                         zipcode: zipcode,
                         country: country,
                         state: state,
                         idcompany: idcompany,
                         vatnumber: vatnumber
                 }
                dispatch(userActions.update(user_infos));
                this.setState({ submitted: false });
             }
        }
      }

    render() { 
        const { users,alert,dispatch,intl } = this.props;
        const { username,email,last_name, first_name,company,address,city,country,state,phone
            ,zipcode,idcompany,vatnumber,submitted,org_member,active_org,active_role,companySize,companyType} = this.state;

        return ( 
                <SidebarProfile >
                        <Grid stackable  style={styles.FontLato12}>
                            <Grid.Row columns={1} >
                                <Grid.Column width={10}>
                                    <Header as='h1'>
                                        <Icon name='address card' color='blue'  size='huge' />
                                        <Header.Content>
                                            <FormattedMessage id="profile.top.title.header"
                                                              defaultMessage='Personal Information'/>
                                            <Header.Subheader><FormattedMessage id="profile.top.subtitle"
                                                              defaultMessage='Profile, billing & payment'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={8}*/  mobile={12} tablet={10} computer={8}>
                                        <br/>
                                        <Header as='h4' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="profile.top.form.title"
                                                               defaultMessage='Profile overview'/>
                                        </Header>
                                        {users && users.items &&
                                            <Form style={{paddingLeft:'1em'}} success>
                                              <Segment raised>
                                              <Form.Group widths='equal'>
                                                <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.name"
                                                                              defaultMessage='Username'/></label>
                                                    <FormattedMessage id="profile.form.input.name.placeholder"
                                                                    defaultMessage='username'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="username" 
                                                                        icon='user'
                                                                        iconPosition='left'
                                                                        value={username}
                                                                        onChange={this.OnUsernameChange}
                                                                        //error={submitted && !this.handleNameValidation(username)}
                                                                        />}
                                                                    
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.email"
                                                                              defaultMessage='Email'/></label>
                                                   {/*  <label style={{'paddingLeft':'0.5em'}}><Icon name='mail' color='grey'/>{' '}{email}</label> */}
                                                   <div style={{  'pointer-events': 'none', opacity: '0.6'}}><Form.Input //type="mail" 
                                                            placeholder="Email" 
                                                            id="email" 
                                                            value={email}
                                                            //disabled
                                                            /* onChange={this.OnEmailChange} *//></div>
                                                </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.firstname"
                                                                                defaultMessage='First Name'/></label>
                                                    <FormattedMessage id="profile.form.input.firstname.placeholder"
                                                                        defaultMessage='first name'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="first_name" 
                                                                        icon='id card'
                                                                        iconPosition='left'
                                                                        value={first_name}
                                                                        onChange={this.OnFirstNameChange}
                                                                        error={submitted && !this.handleNameValidation(first_name)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.lastname"
                                                                                defaultMessage='Last Name'/></label>
                                                    <FormattedMessage id="profile.form.input.lastname.placeholder"
                                                                        defaultMessage='last Name'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="last_name" 
                                                                        icon='id card'
                                                                        iconPosition='left'
                                                                        value={last_name}
                                                                        onChange={this.OnLastNameChange}
                                                                        error={submitted && !this.handleNameValidation(last_name)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Field width='eight'>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
{/*                                                     <FormattedMessage id="profile.
form.input.phone.placeholder"
                                                                    defaultMessage='phone'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        *---pattern = "[0-9] {3} - [0-9] {2} - [0-9] {3}"
                                                                        placeholder={placeholder}
                                                                        id="phone" 
                                                                        icon='phone'
                                                                        iconPosition='left'
                                                                        value={phone}
                                                                        onChange={this.OnPhoneChange}
                                                                        error={submitted && !this.handlePhoneValidation(phone)}
                                                                        />}
                                                    </FormattedMessage> */}
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="profile.form.input.phone.error.message"
                                                                            defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                               </Form.Field>
                                               <br/>
                                               {active_role>=ADMIN &&
                                                <div>
                                                <Label><Icon name='building outline'/><FormattedMessage id="profile.form.main.part1.title"
                                                                              defaultMessage='Company'/></Label>
                                                <br/> <br/>
                                                </div>}
{/*                                                 {active_role<ADMIN &&
                                                 <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                <Form.Field width='8' fluid> 
                                                            <label style={styles.LabelGrey}><FormattedMessage id="profile.form.type.title"
                                                                                    defaultMessage='Type of activity'/></label>
                                                            <FormattedMessage id="profile.form.select.type.placeholder"
                                                                            defaultMessage='Select a type'>
                                                                {placeholder =>                                                                                
                                                                            <Form.Select type="text" 
                                                                                placeholder={placeholder}
                                                                                id="companytype" 
                                                                                ////fluid
                                                                                value={companyType}
                                                                                options={CompanyTypeOptions} 
                                                                                ////onChange={this.OnTypeChange}
                                                                                error={submitted &&  companyType === "0"}
                                                                                />}
                                                            </FormattedMessage>
                                                        {submitted && this.state.companyType === "0"  &&
                                                                <label style={{color:'red', }}><i>
                                                                    <FormattedMessage id="profile.form.type.error.message"
                                                                                        defaultMessage='select a type'/>
                                                                </i></label> 
                                                            }
                                                </Form.Field></div>} */}
                                                {active_role>=ADMIN &&
                                                <Form.Field width='8' fluid> 
                                                            <label style={styles.LabelGrey}><FormattedMessage id="profile.form.type.title"
                                                                                    defaultMessage='Type of activity'/></label>
                                                            <FormattedMessage id="profile.form.select.type.placeholder"
                                                                            defaultMessage='Select a type'>
                                                                {placeholder =>                                                                                
                                                                            <Form.Select type="text" 
                                                                                placeholder={placeholder}
                                                                                id="companytype" 
                                                                                //fluid
                                                                                value={companyType}
                                                                                options={CompanyTypeOptions} 
                                                                                onChange={this.OnTypeChange}
                                                                                error={submitted &&  companyType === "0"}
                                                                                />}
                                                            </FormattedMessage>
                                                        {submitted && this.state.companyType === "0"  &&
                                                                <label style={{color:'red', }}><i>
                                                                    <FormattedMessage id="profile.form.type.error.message"
                                                                                        defaultMessage='select a type'/>
                                                                </i></label> 
                                                            }
                                                </Form.Field>}
{/*                                                 {active_role<ADMIN &&
                                                    <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                <Form.Field width='8' fluid> 
                                                            <label style={styles.LabelGrey}><FormattedMessage id="profile.form.size.title"
                                                                                    defaultMessage='Number of employees (owner included)'/></label>
                                                            <FormattedMessage id="profile.form.size.name.placeholder"
                                                                            defaultMessage='Enter a number'>
                                                                {placeholder =>                                                                                
                                                                            <Form.Input fluid type="text" 
                                                                                placeholder={placeholder}
                                                                                id="companysize" 
                                                                                value={companySize}
                                                                                ////onChange={this.OnSizeChange}
                                                                                error={submitted && !this.assertSizeField()}
                                                                                focus />} 
                                                            </FormattedMessage>
                                                            {submitted &&  this.state.companySize===0  &&
                                                                <label style={{color:'red', }}><i>
                                                                    <FormattedMessage id="profile.form.size.error.message"
                                                                                        defaultMessage='select a size'/>
                                                                </i></label> 
                                                            }
                                                    </Form.Field></div>} */}
                                                {active_role>=ADMIN &&
                                                    <Form.Field width='8' fluid> 
                                                            <label style={styles.LabelGrey}><FormattedMessage id="profile.form.size.title"
                                                                                    defaultMessage='Number of employees (owner included)'/></label>
                                                            <FormattedMessage id="profile.form.size.name.placeholder"
                                                                            defaultMessage='Enter a number'>
                                                                {placeholder =>                                                                                
                                                                            <Form.Input fluid type="text" 
                                                                                placeholder={placeholder}
                                                                                id="companysize" 
                                                                                value={companySize}
                                                                                onChange={this.OnSizeChange}
                                                                                error={submitted && !this.assertSizeField()}
                                                                                focus />} 
                                                            </FormattedMessage>
                                                            {submitted && !this.assertSizeField() &&
                                                                <label style={{color:'red', }}><i>
                                                                    <FormattedMessage id="profile.form.size.error.message"
                                                                                        defaultMessage='select a size'/>
                                                                </i></label> 
                                                            }
                                                    </Form.Field>}
                                                <br/>
                                                </Segment>
                                                <Button style={styles.ButtonGreen}
                                                    onClick={this.onSubmitUser}>
                                                    <FormattedMessage id="profile.form.button.save"
                                                                       defaultMessage='Save my updates'/>
                                                </Button>
                                            </Form>}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={8}*/  mobile={12} tablet={10} computer={8}>
                                        <br/>
                                        <Header as='h4' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="profile.top.form.billing.title"
                                                               defaultMessage='Company billing'/>
                                          {org_member && 
                                             <Label color='blue'><FormattedMessage id="profile.top.form.billing.organisation"
                                                                                   defaultMessage='ORGANIZATION | '/>{' '}{active_org.name}</Label>}
                                        </Header>

                                        {users && users.items &&
                                            <Form style={{paddingLeft:'1em'}} success>
                                              <Segment raised >
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.company"
                                                                              defaultMessage='Company'/></label>
                                                   {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                        <FormattedMessage id="profile.form.input.company.placeholder"
                                                                            defaultMessage='company'>
                                                                {placeholder =>          
                                                                        <Form.Input type="text" 
                                                                            //placeholder={placeholder}
                                                                            id="company" 
                                                                            icon='building'
                                                                            iconPosition='left'
                                                                            value={company}
                                                                            //onChange={this.OnCompanyChange}
                                                                            />}
                                                            </FormattedMessage>
                                                    </div>}
                                                    {active_role>=ADMIN &&
                                                        <FormattedMessage id="profile.form.input.company.placeholder"
                                                                            defaultMessage='company'>
                                                                {placeholder =>          
                                                                        <Form.Input type="text" 
                                                                            placeholder={placeholder}
                                                                            id="company" 
                                                                            icon='building'
                                                                            iconPosition='left'
                                                                            value={company}
                                                                            onChange={this.OnCompanyChange}/>}
                                                            </FormattedMessage>}
                                                </Form.Field>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.address"
                                                                              defaultMessage='Billing Address'/></label>
                                                  {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                   <FormattedMessage id="profile.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    //placeholder={placeholder}
                                                                    id="address"
                                                                    icon='home'
                                                                    iconPosition='left' 
                                                                    value={address}
                                                                    //onChange={this.OnAdressChange}
                                                                    />}
                                                    </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                   <FormattedMessage id="profile.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="address"
                                                                    icon='home'
                                                                    iconPosition='left' 
                                                                    value={address}
                                                                    onChange={this.OnAdressChange}
                                                                    />}
                                                    </FormattedMessage>}
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.zipcode"
                                                                                                        defaultMessage='Zipcode'/></label>
                                                  {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                    <FormattedMessage id="profile.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        //placeholder={placeholder}
                                                                        icon='zip'
                                                                        iconPosition='left' 
                                                                        id="zipcode" 
                                                                        value={zipcode}
                                                                        //onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                        />}
                                                        </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                    <FormattedMessage id="profile.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='zip'
                                                                        iconPosition='left' 
                                                                        id="zipcode" 
                                                                        value={zipcode}
                                                                        onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                        />}
                                                        </FormattedMessage>}
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.city"
                                                                                defaultMessage='City'/></label>
                                                    {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                    <FormattedMessage id="profile.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        //placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map outline'
                                                                        iconPosition='left' 
                                                                        value={city}
                                                                        //onChange={this.OnCityChange}
                                                                        />}
                                                        </FormattedMessage></div>}
                                                        {active_role>=ADMIN &&
                                                    <FormattedMessage id="profile.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map outline'
                                                                        iconPosition='left' 
                                                                        value={city}
                                                                        onChange={this.OnCityChange}
                                                                        />}
                                                        </FormattedMessage>}
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.state"
                                                                                defaultMessage='State'/></label>
                                                   {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                    <FormattedMessage id="profile.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        //placeholder={placeholder}
                                                                        icon='map outline'
                                                                        iconPosition='left' 
                                                                        id="state" 
                                                                        value={state}
                                                                        //onChange={this.OnStateChange}
                                                                        />}
                                                        </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                    <FormattedMessage id="profile.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='map outline'
                                                                        iconPosition='left' 
                                                                        id="state" 
                                                                        value={state}
                                                                        onChange={this.OnStateChange}
                                                                        />}
                                                        </FormattedMessage>}
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.country"
                                                                              defaultMessage='Country'/></label>
                                                    {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                   <FormattedMessage id="profile.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    //placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left' 
                                                                    value={country}
                                                                    //onChange={this.OnCountryChange}
                                                                    />}
                                                    </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                   <FormattedMessage id="profile.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left' 
                                                                    value={country}
                                                                    onChange={this.OnCountryChange}/>}
                                                    </FormattedMessage>}
                                                </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.idcompany"
                                                                                defaultMessage='Company Identifier'/></label>
                                                   {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                    <FormattedMessage id="profile.form.input.idcompany.placeholder"
                                                                        defaultMessage='company ID'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        //placeholder={placeholder}
                                                                        id="idcompany" 
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={idcompany}
                                                                        //onChange={this.OnIdCompanyChange}
                                                                        />}
                                                        </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                    <FormattedMessage id="profile.form.input.idcompany.placeholder"
                                                                        defaultMessage='company ID'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="idcompany" 
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={idcompany}
                                                                        onChange={this.OnIdCompanyChange}/>}
                                                        </FormattedMessage>}
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="profile.form.input.vatnumber"
                                                                                                        defaultMessage='VAT Identifier'/></label>
                                                    {active_role<ADMIN &&
                                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                                    <FormattedMessage id="profile.form.input.vatnumber.placeholder"
                                                                        defaultMessage='Vat ID'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        //placeholder={placeholder}
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        id="vat" 
                                                                        value={vatnumber}
                                                                        //onChange={this.OnVatNumberChange}
                                                                        //error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                        />}
                                                        </FormattedMessage></div>}
                                                    {active_role>=ADMIN &&
                                                    <FormattedMessage id="profile.form.input.vatnumber.placeholder"
                                                                        defaultMessage='Vat ID'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        id="vat" 
                                                                        value={vatnumber}
                                                                        onChange={this.OnVatNumberChange}
                                                                        //error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                        />}
                                                        </FormattedMessage>}
                                                    </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                </Segment>
                                                <Button style={styles.ButtonGreen} disabled={ active_role<ADMIN}
                                                    onClick={this.onSubmitBilling}>
                                                    <FormattedMessage id="profile.form.button.save"
                                                                       defaultMessage='Save my updates'/>
                                                </Button>
                                            </Form>}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} >
                                <Grid.Column width={8}>
                                    <br/>
                                    <Header as='h4' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="profile.payment.form.title"
                                                               defaultMessage='Payment settings'/>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {org_member && active_role<ADMIN &&
                                <Grid.Row columns={1}>
                                    <Grid.Column width={8}>
                                        <Message compact warning icon size='large'>
                                            <Icon name='settings' color='teal'/>
                                            <Message.Content>
                                               
                                                <Message.Header>
                                                    <FormattedMessage id="profile.role.message.title"
                                                                    defaultMessage='Payment & Cards'/>
                                                </Message.Header>
                                                <Icon name='lock' color='teal'/>
                                                <FormattedMessage id="profile.role.message.details"
                                                                defaultMessage='You should have Administrator privilege.' />
                                                <br/> <br/>
                                            </Message.Content>
                                        </Message>
                                    </Grid.Column>
                            </Grid.Row>} */}
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={8}*/  mobile={12} tablet={10} computer={8}>
{/*                                     <Dimmer.Dimmable dimmed={org_member && active_role<ADMIN} blurring>
                                        <Dimmer active={org_member && active_role<ADMIN} blurring inverted>
                                        </Dimmer> */}
                                    <StripeProvider apiKey={''+stripeConstants.STRIPE_PUBLIC_KEY}>
                                        <Elements locale={intl.locale}>
                                            <UpdateCardForm style={{paddingLeft:'1em'}} disable_ui={ active_role<OWNER}/>
                                        </Elements>
                                    </StripeProvider>
                                   {/*  </Dimmer.Dimmable> */}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </SidebarProfile>
        )
    }
}

function mapStateToProps(state) {
    const { users,alert,dispatch } = state;
    return {
        users,
        alert,
        dispatch,
    };
}

const connectedProfile = injectIntl(connect(mapStateToProps)(Profile));
export { connectedProfile as Profile };
 