import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../_reducers'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

let middleware = [thunkMiddleware]

if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger();
  middleware = [...middleware, loggerMiddleware]
}

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

