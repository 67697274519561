export const blogConstants = {
    GETALL_REQUEST: 'POSTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'POSTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'POSTS_GETALL_FAILURE',

    GET_REQUEST: 'POSTS_GET_REQUEST',
    GET_SUCCESS: 'POSTS_GET_SUCCESS',
    GET_FAILURE: 'POSTS_GET_FAILURE',

    GET_HOME_POSTS_REQUEST: 'POSTS_GET_HOME_REQUEST',
    GET_HOME_POSTS_SUCCESS: 'POSTS_GET_HOME_SUCCESS',
    GET_HOME_POSTS_FAILURE: 'POSTS_GET_HOME_FAILURE',
    
};