import React, { Component } from 'react';
import { Form, Message,Grid,Header, Button,Container,Segment,Icon,List} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {HashLink as Link} from 'react-router-hash-link';
import { userActions } from '../_actions';
import {MainFooter,ResponsiveContainer} from './Segments';
import {FormattedMessage,injectIntl,defineMessages} from 'react-intl';
import {Helmet} from "react-helmet";
import {styles} from './Styles'
import img_logo from '../Assets/ratatool-logo-icon.png';
import img_email_contact from '../Assets/email-contact.jpg';


const containerStyle = {
    height: 'auto',
    width: '1400px',
    padding: '0em 0em 0em 0em',
    border: 'none',
    'background-color': '#fcfcfc',
    //backgroundImage: `url(${backgroundImg})`,
    //backgroundSize: 'cover'
};

const html_tags = defineMessages({
    html_title: {
         id: 'contactPage.html.title.tag',
         defaultMessage: "Contact",
    },
    meta_name_description: {
         id: 'contactPage.meta.name.description.tag',
         defaultMessage: "Contact us and give us your precious feedback",
    }
 });

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                first_name: '',
                last_name: '',
                email: '',
                message: '',
            },
            submitted: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };


    resetComponent = () => {
        this.setState({
            user : {
            first_name: '',
            last_name: '',
            email: '',
            message: ''
        }
      });
    }

    handleEmailValidation(email){
        if(!email){
            return false;
        }

        if(email !== ""){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos 
                && lastAtPos > 0 
                && email.indexOf('@@') == -1 
                && lastDotPos > 2 
                && (email.length - lastDotPos) > 2)) {
              return false;
            }
        }
        return true;
    }

    handleNameValidation(name){
        if(!name){
            return false;
        }
        if(name !== ""){
            if(!name.match(/^[a-zA-Z]+$/)){
              return false;
            }      	
        }
        return true;
    }

    handleMessageValidation(message) {
        if (message.length > 1000 || message.length == 0)    
            return false;
        return true;
    }

    handleValidation(){
        const { user } = this.state;

        if (!this.handleNameValidation(user.last_name)){ 
            return false;
        }
        if (!this.handleNameValidation(user.first_name)){ 
            return false;
        }
        if (!this.handleEmailValidation(user.email)){ 
            return false;
        }
        if (!this.handleMessageValidation(user.message)){ 
            return false;
        }
        return true;
    }


    
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (this.handleValidation()) {
            dispatch(userActions.addLead(user));
            this.resetComponent();
            this.setState({ submitted: false });
        }
    }



    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }



    render( ) { 
        const { users,intl } = this.props;
        const { user, submitted } = this.state;
                                 
        return (  
        <ResponsiveContainer>
            <Helmet>
{/*                 <title> Ratatool - Contactez-nous et donnez-nous votre avis et suggestions</title>
                <meta name="description" content="Calculez votre coût matière avec le mercuriale d'ingrédients de vos recettes, estimez vos marges, réduisez vos pertes, éditez vos fiches techniques de recettes et vos étiquettes nutritionnelles INCO."/> */}
                <title>{intl.formatMessage(html_tags.html_title)}</title>
                <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>

                { intl.locale == 'fr' &&
                  <link rel="canonical" href="https://fr.ratatool.com/contact" />}

                { intl.locale == 'en' &&
                    <link rel="canonical" href="https://www.ratatool.com/contact" />}
                
                <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/contact" />
                <link rel="alternate" hreflang="en" href="https://www.ratatool.com/contact" />
                <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/contact" />
                
            </Helmet>
            <Segment vertical style={{border:'none', 'background-color': '#fcfcfc'}}>
             <Container style={containerStyle}>
                <Grid  textAlign='center' style={{ height: '100%', border:'none',  'background-color': '#fcfcfc'}} verticalAlign='middle'>  
                    <Grid.Row columns={2} only='computer' style={{'padding-bottom':0, border:'none'}} textAlign='left'>
                        <Grid.Column floated='left' style={{width: '40%', maxWidth: 700,  height: '100vh', border:'none'}}> 
                            <img src={img_email_contact} alt="email" style={{width: '100vh',height: '100vh'}}/>
                        </Grid.Column>
                        <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%',maxWidth: 550 , border:'none'}}>
                            <br/>
                            {/* <img src={img_logo} alt="logo" style={{width: '50px', height: '60px' }} verticalAlign='middle'/> */}
                            <Header as='h2' color='grey' textAlign='center'>
                            {' '}<FormattedMessage id="contactPage.form.title"
                                                    defaultMessage='Drop us a message'/>
                            </Header>
                           {/*  <br/> */}
                            <p style={{ fontSize: '1.13em' }}>
                                <FormattedMessage id="contactPage.form.header.title"
                                                defaultMessage="We'd love to hear from you! "/>
                            </p>
                            <p style={{ fontSize: '1.13em' }}>
                                <FormattedMessage id="contactPage.form.header.title2"
                                                defaultMessage="We'll get in touch with you shortly."/>
                            </p>
                           <br/>
                            <Form success secondary>
                                    <Form.Group widths='equal' >
                                        <FormattedMessage id="contactPage.form.name.placeholder"
                                                            defaultMessage='First name'>
                                            {placeholder => 
                                                <Form.Input fluid
                                                            type='text'
                                                            label = {<FormattedMessage id="contactPage.form.name.label"
                                                                        defaultMessage='First name *'/>}
                                                            iconPosition='left'
                                                            icon='user'
                                                            placeholder={placeholder}
                                                            value={user.first_name}
                                                            name='first_name'
                                                            onChange={this.handleChange}
                                                            size='large'
                                                            error={submitted && !this.handleNameValidation(user.first_name)}/>}
                                    </FormattedMessage>
                                    <FormattedMessage id="contactPage.form.lastname.placeholder"
                                                            defaultMessage='Last name'>
                                            {placeholder => 
                                                    <Form.Input fluid 
                                                                type='text'
                                                                label={<FormattedMessage id="contactPage.form.lastname.label"
                                                                                        defaultMessage='Last name *'/>}
                                                                icon='user'
                                                                iconPosition='left'
                                                                placeholder={placeholder}
                                                                value={user.last_name} 
                                                                name='last_name'
                                                                onChange={this.handleChange} 
                                                                size='large'
                                                                error={submitted && !this.handleNameValidation(user.last_name)}/>}
                                        </FormattedMessage>
                                    </Form.Group>
                                    <br/>
                                    <FormattedMessage id="contactPage.form.email.placeholder"
                                                            defaultMessage='Email'>
                                            {placeholder => 
                                                <Form.Input label={<FormattedMessage id="contactPage.form.email.label"
                                                                                    defaultMessage='Email *'/>}
                                                            iconPosition='left'
                                                            icon='mail' 
                                                            type='email'
                                                            placeholder={placeholder}
                                                            value={user.email}
                                                            name='email'
                                                            onChange={this.handleChange}
                                                            size='large'
                                                            error={submitted && !this.handleEmailValidation(user.email)}  />}
                                    </FormattedMessage>
                                    <br/>
                                    <FormattedMessage id="contactPage.form.suggestion.placeholder"
                                                            defaultMessage='Suggestion'>
                                            {placeholder => 
                                                <Form.TextArea label={<FormattedMessage id="contactPage.form.suggestion.label"
                                                                                    defaultMessage='Give your feeback or any suggestion (max 1000 chars)'/>}
                                                            placeholder={placeholder}
                                                            value={user.message}
                                                            name='message'
                                                            onChange={this.handleChange}
                                                            rows='12'
                                                            error={submitted && !this.handleMessageValidation(user.message)}  />}
                                    </FormattedMessage>
                                    {users.subscribing  && 
                                        <img alt="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                    {/* {alert.message && toaster_dispatch(dispatch,alert,intl)} */}
                                    {users.subscribed &&        
                                        <Message success 
                                            header={<FormattedMessage id="contactPage.form.message.success.header"
                                                                    defaultMessage='Message sent'/>} 
                                            content={<FormattedMessage id="contactPage.form.message.success.content"
                                                                    defaultMessage=" - Thank you."/>}  /> 
                                    }
                                    <Button style={styles.ButtonBlueAsIconUpper}//color='teal'
                                            fluid
                                            //disabled={!this.handleValidation()}
                                            onClick={this.onSubmit}>
                                            <FormattedMessage id="contactPage.form.submit"
                                                            defaultMessage='Send'/>
                                    </Button>
                                    <br/><br/>
                                    <Header as='h3' centered style={{color:'#63a4dd'}}>
                                        <FormattedMessage id="contactPage.segment.try.title.text"
                                                                    defaultMessage="A free trial in the meantime ?"/></Header>
                                    <Grid container stackable verticalAlign='middle' centered>
                                        <Grid.Row>
                                            <List divided relaxed style={{ marginLeft: '2em'/*, 'font-style':'italic'*/,textAlign:'left'  }}>
                                                <Header as='h4' centered /* style={{color:'#63a4dd'}} */>
                                                        <Icon name='check' size='tiny' style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text4.title"
                                                                        defaultMessage="Secure your essential business data"/> 
                                                        </Header.Content><br/>
                                                        <Icon name='check' size='tiny'  style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text3.title"
                                                                        defaultMessage="Save hours of work on your daily repetitive tasks"/> 
                                                        </Header.Content><br/>
                                                        <Icon name='check' size='tiny'  style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text2.title"
                                                                        defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                                                        </Header.Content>
                                                </Header>
                                            </List>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                            <Button size='small' as={Link} to="/register" style={styles.ButtonHomeBlack}>
                                                <FormattedMessage id="contactPage.segment.try.button.text"
                                                                    defaultMessage="Try for free"/>
                                            </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        </Grid>
                                        <br/><br/>
                            </Form>
                        </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}} >
                        <Grid.Column  style={{ top: "5%", maxWidth: 450, minHeight:'100vh'}}>
                            <br/>
                           {/*  <img src={img_logo} alt="logo" style={{width: '50px', height: '60px' }} /> */}
                            <Header as='h2' color='grey' textAlign='center'>
                            {' '}<FormattedMessage id="contactPage.form.title"
                                                    defaultMessage='Drop us a message'/>
                            </Header>
                           {/*  <br/> */}
                            <p style={{ fontSize: '1.13em' }}>
                                <FormattedMessage id="contactPage.form.header.title"
                                                defaultMessage="We'd love to hear from you! "/>
                            </p>
                            <p style={{ fontSize: '1.13em' }}>
                                <FormattedMessage id="contactPage.form.header.title2"
                                                defaultMessage="We'll get in touch with you shortly."/>
                            </p>
                            <br/>
                            <Form size='medium' success >
                                    <Form.Group widths='equal' >
                                        <FormattedMessage id="contactPage.form.name.placeholder"
                                                            defaultMessage='First name'>
                                            {placeholder => 
                                                <Form.Input fluid
                                                            type='text'
                                                            label = {<FormattedMessage id="contactPage.form.name.label"
                                                                        defaultMessage='First name *'/>}
                                                            iconPosition='left'
                                                            icon='user'
                                                            placeholder={placeholder}
                                                            value={user.first_name}
                                                            name='first_name'
                                                            onChange={this.handleChange} 
                                                            size='large'
                                                            error={submitted && !this.handleNameValidation(user.first_name)}/>}
                                    </FormattedMessage>
                                    <FormattedMessage id="contactPage.form.lastname.placeholder"
                                                            defaultMessage='Last name'>
                                            {placeholder => 
                                                    <Form.Input fluid 
                                                                type='text'
                                                                label={<FormattedMessage id="contactPage.form.lastname.label"
                                                                                        defaultMessage='Last name *'/>}
                                                                icon='user'
                                                                iconPosition='left'
                                                                placeholder={placeholder}
                                                                value={user.last_name} 
                                                                name='last_name'
                                                                onChange={this.handleChange} 
                                                                size='large'
                                                                error={submitted && !this.handleNameValidation(user.last_name)}/>}
                                        </FormattedMessage>
                                    </Form.Group>
                                        <FormattedMessage id="contactPage.form.email.placeholder"
                                                            defaultMessage='Email'>
                                            {placeholder => 
                                                <Form.Input label={<FormattedMessage id="contactPage.form.email.label"
                                                                                    defaultMessage='Email *'/>}
                                                            iconPosition='left'
                                                            icon='mail' 
                                                            type='email'
                                                            placeholder={placeholder}
                                                            value={user.email}
                                                            name='email'
                                                            onChange={this.handleChange}
                                                            size='large'
                                                            error={submitted && !this.handleEmailValidation(user.email)}  />}
                                        </FormattedMessage>
                                        <FormattedMessage id="contactPage.form.suggestion.placeholder"
                                                            defaultMessage='Suggestion'>
                                            {placeholder => 
                                                <Form.TextArea label={<FormattedMessage id="contactPage.form.suggestion.label"
                                                                                    defaultMessage='Give your feeback or any suggestion (max 1000 chars)'/>}
                                                            placeholder={placeholder}
                                                            value={user.message}
                                                            name='message'
                                                            onChange={this.handleChange}
                                                            rows='12'
                                                            error={submitted && !this.handleMessageValidation(user.message)}  />}
                                        </FormattedMessage>
                                    {users.subscribing  && 
                                        <img alt="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                    {/* {alert.message && toaster_dispatch(dispatch,alert,intl)} */}
                                    {users.subscribed &&        
                                        <Message success 
                                            header={<FormattedMessage id="contactPage.form.message.success.header"
                                                                    defaultMessage='Message sent'/>} 
                                            content={<FormattedMessage id="contactPage.form.message.success.content"
                                                                    defaultMessage=" - Thank you."/>}  /> 
                                    }
                                    <Button style={styles.ButtonBlueAsIconUpper}//color='teal'
                                            fluid
                                            //disabled={!this.handleValidation()}
                                            onClick={this.onSubmit}>
                                            <FormattedMessage id="contactPage.form.submit"
                                                            defaultMessage='Send'/>
                                    </Button>
                                    <br/><br/><br/><br/>
                                    <Header as='h3' centered style={{color:'#63a4dd'}}>
                                        <FormattedMessage id="contactPage.segment.try.title.text"
                                                                    defaultMessage="A free trial in the meantime ?"/></Header> <br/>
                                    <Grid container stackable verticalAlign='middle' centered>
                                        <Grid.Row>
                                            <List divided relaxed style={{ marginLeft: '2em'/*, 'font-style':'italic'*/,textAlign:'left'  }}>
                                                <Header as='h4' centered /* style={{color:'#63a4dd'}} */>
                                                        <Icon name='check' size='tiny' style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text4.title"
                                                                        defaultMessage="Secure your essential business data"/> 
                                                        </Header.Content><br/>
                                                        <Icon name='check' size='tiny'  style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text3.title"
                                                                        defaultMessage="Save hours of work on your daily repetitive tasks"/> 
                                                        </Header.Content><br/>
                                                        <Icon name='check' size='tiny'  style={{color:'#63a4dd'}}/> 
                                                        <Header.Content>
                                                        <FormattedMessage id="contactPage.segment.try.text2.title"
                                                                        defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                                                        </Header.Content>
                                                </Header>
                                            </List>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign='center'>
                                            <Button size='small' as={Link} to="/register" style={styles.ButtonHomeBlack}>
                                                <FormattedMessage id="contactPage.segment.try.button.text"
                                                                    defaultMessage="Try for free"/>
                                            </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        </Grid>
                                        <br/><br/><br/><br/>
                            </Form>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                 </Container>
              </Segment>
            <MainFooter/>
        </ResponsiveContainer>
        )
    }
}



 
function mapStateToProps(state) {
    const {alert, dispatch, users} = state;
    return {
        users,
        dispatch,
        alert
    };
}

const connectedContactPage = injectIntl(connect(mapStateToProps)(ContactPage));
export { connectedContactPage as ContactPage };
