import React, { Component } from 'react';
import { Button,Header,Modal,Card,Grid,Dimmer,Loader,Icon,Form,Message,Label} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {paymentActions,userActions} from '../_actions'
import {history,toaster_dispatch,ADMIN,OWNER} from '../_helpers';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,injectIntl} from 'react-intl';
import {styles} from './Styles.jsx';

const EXTRA_PACK_NUMBER_USERS = 3

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { activeModal: null,
                       activeReasonForm: false,
                       reasonSubmitted: false,
                       clickSubmit: false,
                       user: {
                         first_name: userIn.user.username,
                         last_name: userIn.user.username,
                         email: userIn.user.email,
                         message: '',
                        },
                        active_role:  userIn.user.role?userIn.user.role:OWNER,
                        org_member: userIn.user.active_org?true:false, 
                        userIn:userIn
                     }
        this.hideModal = this.hideModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onReasonSubmit = this.onReasonSubmit.bind(this);
        props.dispatch(paymentActions.getSubscription());
        props.dispatch(paymentActions.getSubscriptionExtraUsers());
        props.dispatch(paymentActions.getAvailableMemberships());
        props.dispatch(paymentActions.getAvailableExtraMemberships());
        //props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(paymentActions.getCustomerCoupons());
    }

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ activeReasonForm: false});
        this.setState({ reasonSubmitted: false});
        this.setState({ clickSubmit: false});
        this.resetComponent()
    }

    cancelSubscriptionClickHandler = (e) => {
        e.preventDefault();
        this.setState({ activeModal: true});

    }; 

    cancelSubscription = (e) => {
        e.preventDefault();
        const { user } = this.state;
        const { dispatch} = this.props;
        dispatch(paymentActions.unsubscribe());

        user.message =  "UNSUBSCRIBED Feedback: \\\n " + user.message
        dispatch(userActions.addLead(user));
        this.resetComponent();
    }

    showReasonForm = (e) => {
        e.preventDefault();
        this.setState({ activeReasonForm: true});
    }


    resetComponent = () => {
        const { user } = this.state;
        this.setState({
            user : {
                ...user,
                message: ''
        }
      });
    }

    handleMessageValidation(message) {
        if (message.length > 2000 || message.length < 100)    
            return false;
        return true;
    }


    handleValidation(){
       const { user } = this.state;

        if (!this.handleMessageValidation(user.message)){ 
            return false;
        }

        return true;
    }


    handleChange(event) {
        const { name,value } = event.target;
        const { user } = this.state;
        this.setState({ clickSubmit: false});
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    onReasonSubmit = (e) => {
        e.preventDefault();
        //const { user } = this.state;
        //const { dispatch } = this.props;
        this.setState({ clickSubmit: true });
        if (this.handleValidation()) {
            //user.message =  "UNSUBSCRIBED Feedback: \\\n " + user.message
            //dispatch(userActions.addLead(user));
            this.setState({ reasonSubmitted: true });
            //this.resetComponent();
            
        }
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: true}})
    }

    addOrganization = () => {
        history.push('/organisation')
    }

    addOrganisationSubscription = () => {
        const { payment } = this.props;
        if (payment && payment.subscription && payment.subscription.customer){
              history.push('/storeplan',{type:  payment.subscription.customer.membership.membership_type,
                                        name:  payment.subscription.customer.membership.name,
                                        interval:  payment.subscription.customer.membership.interval,
                                        price : payment.subscription.customer.membership.price,
                                        bill_locale :  payment.subscription.customer.membership.country})
        }
    }

    addPremiumSubscription = () => {
        const { payment } = this.props;
        const {memberships} = this.props.payment;
        let consistPremiumMembership =[];
        if (memberships && payment && payment.subscription && payment.subscription.customer){
    
            consistPremiumMembership =  memberships.filter( m => {
                    return  m.interval == payment.subscription.customer.membership.interval &&
                            m.membership_type == 'Entreprise' && 
                            m.country == payment.subscription.customer.membership.country
            });
        }
        if (consistPremiumMembership && consistPremiumMembership[0]){
            history.push('/storeplan',{type:  consistPremiumMembership[0].membership_type,
                                       name:  consistPremiumMembership[0].name,
                                       interval:  consistPremiumMembership[0].interval,
                                       price : consistPremiumMembership[0].price,
                                       bill_locale : consistPremiumMembership[0].country})
            }
    }

    addExtraUsersSubscription = () => {
        const { payment } = this.props;
        const {extra_memberships} = this.props.payment;
        let consistExtraMembership =[];
        if (extra_memberships && payment && payment.subscription && payment.subscription.customer){

            consistExtraMembership =  extra_memberships.filter( m => {
                    return  m.interval == payment.subscription.customer.membership.interval &&
                            m.membership_type == 'ExtraUsers' && 
                            m.country == payment.subscription.customer.membership.country
            });
        }

        if (consistExtraMembership && consistExtraMembership[0]){
        history.push('/storeplan',{type:  consistExtraMembership[0].membership_type,
                                   name:  consistExtraMembership[0].name,
                                   interval:  consistExtraMembership[0].interval,
                                   price : consistExtraMembership[0].price,
                                   bill_locale : consistExtraMembership[0].country})
        }
    }

    activateSubscription = (e) => {
        e.preventDefault();
        //history.push('/pricing/');
        const { payment } = this.props;
        if (payment && payment.subscription && payment.subscription.customer){
/*             history.push('/storeplan/'+ payment.subscription.customer.membership.membership_type +'/'
                                      + payment.subscription.customer.membership.name +'/'
                                      + payment.subscription.customer.membership.interval +'/'
                                      + payment.subscription.customer.membership.price) */
              history.push('/storeplan',{type:  payment.subscription.customer.membership.membership_type,
                                        name:  payment.subscription.customer.membership.name,
                                        interval:  payment.subscription.customer.membership.interval,
                                        price : payment.subscription.customer.membership.price,
                                        bill_locale :  payment.subscription.customer.membership.country})
        }
    }

    addCoupons(coupons){
        return coupons.map((coupon,index) => {
            return(
               <p style={styles.LabelGrey}>
                   <FormattedMessage id="subscriptions.card.message.coupon.subscribed.name"
                                    defaultMessage='Coupon'/> {coupon.name}{'  '}
                   <FormattedMessage id="subscriptions.card.message.coupon.subscribed.status"
                                    defaultMessage=' activated on '/>{'  '} 
                   <FormattedDate value={new Date(coupon.date_activated)}
                                  year='numeric'
                                  month='long'
                                  day='2-digit'/>
               </p>
           )
        }
    )}

    addCouponsUnsubscribed(coupons){
        return coupons.map((coupon,index) => {
            return(
               <div>
                <p style={styles.LabelGrey}>
                    <FormattedMessage id="subscriptions.card.message.coupon.unsubscribed.name"
                                        defaultMessage='Coupon'/> {coupon.name}{' '}
                    <FormattedMessage id="subscriptions.card.message.coupon.unsubscribed.status"
                                        defaultMessage=' applied for next subscription '/>
                </p>
                <p style={styles.LabelGrey}><FormattedMessage id="subscriptions.card.message.coupon.unsubscribed.offer"
                                        defaultMessage='Benefits by subscribing : '/>
                <Button style={styles.ButtonBlue}
                                    onClick={(e) => this.upgradeSubscription()}>
                                        <FormattedMessage id="subscriptions.card.button.coupon.apply"
                                                        defaultMessage='Subscribe'/>
                </Button>
                </p>
                </div>
           )
        }
    )}

    extractOrganisationName(organization_id){
        const {orgs_items} = this.props.users;
        let found_org = 0;
        if (orgs_items){
            found_org = orgs_items.find(org => org.id == organization_id)
            if (found_org){
                return found_org.name
            }
        }
        return ""
    }

    addExtraOptionsPerOrg(){
        const {subscription_extra} = this.props.payment;
        return subscription_extra.map((sub,index) => {
            return(
                <p style={styles.LabelGrey}>
                    {'+ '}{Number(sub.quantity) * EXTRA_PACK_NUMBER_USERS}{' '}
                                 <FormattedMessage id="subscriptions.card.message.plan.extra.description"
                                                 defaultMessage="extra users "/>
                     {' '}<FormattedMessage id="subscriptions.card.message.plan.extra.org"
                                            defaultMessage="on my organization {org}."
                                            values = {{org:this.extractOrganisationName(sub.organization_id)}}/>
                </p>
           )
        }
    )}

    
    render() { 
        const { payment,alert,dispatch,intl,/* customer, */loading } = this.props;
        const {activeReasonForm,reasonSubmitted,clickSubmit,user,active_role,org_member,userIn} = this.state;

        return ( 
                <SidebarProfile >
                        <Grid stackable style={styles.FontLato12}>
                            <Grid.Row columns={1} >
                               <Grid.Column width={8}>
                                    <Header as='h1'>
                                        <Icon name='credit card outline' color='blue'  size='huge' />
                                        <Header.Content>
                                            <FormattedMessage id="subscriptions.top.title.header"
                                                              defaultMessage='Subscription'/>
                                            <Header.Subheader><FormattedMessage id="subscriptions.top.subtitle"
                                                              defaultMessage='Plan & Options'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
{/*                                 <Grid.Column width={12}>
                                    <Message size='huge'>
                                           <Message.Header style={styles.PageTitleGrey}>
                                                <Icon name='settings' color='teal'  size='huge'/>  
                                                <FormattedMessage id="subscriptions.top.title.header"
                                                                  defaultMessage='Your Subscription Status'/>
                                            </Message.Header>
                                    </Message>
                                </Grid.Column> */}
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {org_member && active_role<ADMIN &&
                             <Grid.Row columns={1}/>
                            }
                            {org_member && active_role<ADMIN &&
                             <Grid.Row columns={1}/>
                            }
                            {org_member && active_role<ADMIN &&
                                <Grid.Row columns={1}>
                                    <Grid.Column width={8}>
                                        <Message compact info icon size='large'>
                                            <Icon name='settings' color='teal'/>
                                            <Message.Content>
                                                <Message.Header>
                                                    <FormattedMessage id="subscriptions.role.message.title"
                                                                        defaultMessage='Settings'/>
                                                </Message.Header>
                                                <Icon name='lock' color='teal'/>
                                                <FormattedMessage id="subscriptions.role.message.details"
                                                                defaultMessage='You should have Administrator privilege.' />
                                                <br/> <br/>
                                            </Message.Content>
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>}
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={8}*/  mobile={12} tablet={10} computer={10}>
                                        <Dimmer.Dimmable dimmed={org_member && active_role<ADMIN} blurring>
                                                <Dimmer active={org_member && active_role<ADMIN} blurring inverted>
                                                </Dimmer>
                                        <br/>
                                        <Header as='h3' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="subscriptions.top.form.title"
                                                               defaultMessage='Overview'/>
                                        </Header>
                                        {loading &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> } 
                                        {payment && payment.subscription && 
                                            (payment.subscription.active == true) && 
                                            (payment.subscription.cancel_period_end == false)  && 
                                             payment.subscription.customer &&
                                        <div>
                                            <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}> 
                                                <Card.Content header='Status' style={{background:'#f5f5f5'}}>
                                                  {payment.subscription.active && 
                                                    <p style={styles.LabelGrey}>
                                                       <FormattedMessage id="subscriptions.card.message.active.premium"
                                                                         defaultMessage='Active premium account'/>{' '}&nbsp;
                                                      {org_member && 
                                                        <Label size='small'><FormattedMessage id="subscriptions.card.message.organisation"
                                                                                             defaultMessage='ORGANISATION | '/>
                                                                {' '}{userIn.user.active_org.name}
                                                        </Label>}
                                                    </p>}
                                                  {!payment.subscription.active && 
                                                    <p style={styles.LabelGrey}>
                                                        <FormattedMessage id="subscriptions.card.message.cancelled.premium"
                                                                         defaultMessage='Cancelled'/>
                                                    </p>}
                                                </Card.Content>
                                                {payment.subscription.customer && payment.subscription.customer.membership &&
                                                <Card.Content>
                                                    <br/>
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title"
                                                                         defaultMessage='PLAN '/></p>
                                                    <p style={styles.LabelGrey}> 
{/*                                                          <FormattedMessage id="subscriptions.card.message.plan.summary"
                                                                           defaultMessage='  {membership_type}   {membership_price}$/Month'
                                                                           values={{membership_type:payment.subscription.customer.membership.membership_type,
                                                                                    membership_price:payment.subscription.customer.membership.price }}/> */}
                                                            {payment.subscription.customer.membership.name} {' '}
                                                            <FormattedNumber value={Number(payment.subscription.customer.membership.price * payment.subscription.quantity)} 
                                                                             style="currency" 
                                                                             minimumFractionDigits={Number(payment.subscription.customer.membership.price * payment.subscription.quantity) % 1 === 0 ? 0 : 2}
                                                                             maximumFractionDigits={Number(payment.subscription.customer.membership.price * payment.subscription.quantity) % 1 === 0 ? 0 : 2} 
                                                                             currencyDisplay="symbol" 
                                                                             currency={(payment.subscription.customer.membership.country == "fr"?'EUR':'USD')}/>
                                                           {/*  <FormattedNumber value={payment.subscription.customer.membership.price }/> 
                                                            {payment.subscription.customer.membership.country == "fr" && <span>€</span>}
                                                            {payment.subscription.customer.membership.country == "en" && <span>$</span>}*/}
                                                            { payment.subscription.customer.membership.interval =='Annually' && 
                                                                <FormattedMessage id="subscriptions.card.message.plan.mode.year"
                                                                                    defaultMessage="/year"/>}
                                                            { payment.subscription.customer.membership.interval =='Monthly' && 
                                                                <FormattedMessage id="subscriptions.card.message.plan.mode.month"
                                                                                  defaultMessage="/month"/>}
                                                    </p>
                                                    { payment.subscription.quantity && payment.subscription.customer.membership.membership_type == 'Professional' && 
                                                            <p style={styles.LabelGrey}>{'  '}<a style={{color:'#66b2b2','font-weight':'800'}} onClick={(e) => this.addPremiumSubscription()}>&#8658;<FormattedMessage id="subscriptions.card.message.plan.organisation.update"
                                                                                                            defaultMessage="Upgrade to ENTREPRISE plan "/></a><br/>
                                                                {' '}{'  '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <FormattedMessage id="subscriptions.card.message.plan.organisation.update.infos"
                                                                                  defaultMessage="to manage organization(s) and invite your teammates."/></p>}
                                                    { payment.subscription.quantity && payment.subscription.customer.membership.membership_type == 'Entreprise' && 
                                                            <p style={styles.LabelGreySmall}>{'  '}<FormattedMessage id="subscriptions.card.message.plan.organisation.include"
                                                                                                            defaultMessage="include"/>
                                                                {' '}{payment.subscription.quantity}{'  '}
                                                                <FormattedMessage id="subscriptions.card.message.plan.organisation.name"
                                                                                  defaultMessage="organization(s) (or company(s)) "/></p>}
                                                    { payment.subscription.quantity && payment.subscription.customer.membership.membership_type == 'Entreprise' &&  !org_member  &&
                                                            <p style={styles.LabelGrey}><br/>{'  '}<a style={{color:'#66b2b2','font-weight':'300'}} onClick={(e) => this.addOrganization()}><FormattedMessage id="subscriptions.card.message.plan.organisation.add"
                                                                                                            defaultMessage="Create organization(s)"/></a>
                                                                {' '}{'  '}&nbsp;
                                                                <FormattedMessage id="subscriptions.card.message.plan.organisation.add.infos"
                                                                                  defaultMessage="to invite your teammates."/></p>}                                                                                 
                                                   <br/>
{/*                                                    { payment.subscription_extra && payment.subscription_extra.quantity && payment.subscription_extra.type == 'ExtraUsers' &&
                                                            <p style={styles.LabelGrey}>
                                                                {'+ '}{Number(payment.subscription_extra.quantity) * EXTRA_PACK_NUMBER_USERS}{' '}<FormattedMessage id="subscriptions.card.message.plan.extra.description"
                                                                                  defaultMessage="extra additional users "/>
                                                                 {' '}<FormattedMessage id="subscriptions.card.message.plan.extra.org"
                                                                                        defaultMessage="on my organization {org}."
                                                                                        values = {{org:userIn.user.active_org.name}}/>
                                                                    
                                                            </p>} */}
                                                        { payment.subscription_extra && 
                                                          payment.subscription_extra.length > 0 && 
                                                          payment.subscription.customer.membership.membership_type == 'Entreprise' &&
                                                        this.addExtraOptionsPerOrg()}
                                                    <br/><br/>
                                                </Card.Content>}
{/*                                                 <Card.Content>
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.create"
                                                                         defaultMessage='Created: '/></p> 
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                            value={new Date(payment.subscription.created)}
                                                            year='numeric'
                                                            month='long'
                                                            day='2-digit'/> 
                                                    </p>
                                                    
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.period1"
                                                                                        defaultMessage='Current Period Starts: '/></p>    
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                                     value={new Date(payment.subscription.current_period_start)}
                                                                     year='numeric'
                                                                      month='long'
                                                                      day='2-digit'/> 
                                                    </p>
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.period2"
                                                                         defaultMessage='Current Period End: '/></p> 
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                                     value={new Date(payment.subscription.current_period_end)}
                                                                     year='numeric'
                                                                      month='long'
                                                                      day='2-digit'/> 
                                                    </p>      
                                                </Card.Content> */}
                                                {payment.coupons && payment.coupons.length == 1 &&
                                                <Card.Content>
                                                     <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.discount"
                                                                                        defaultMessage='Discount: '/></p>
                                                
                                                    {this.addCoupons(payment.coupons)}
                                                </Card.Content>}
                                            </Card>
                                         <div>  
                                            { payment.subscription.customer.membership.membership_type == 'Entreprise' &&     
                                            <Button compact style={styles.ButtonBlueAsIconSmallPlan} disabled={active_role != OWNER}//basic color='teal'
                                                onClick={(e) => this.addOrganisationSubscription()}>
                                                <FormattedMessage id="subscriptions.card.button.upgrade.org.subscription"
                                                                 defaultMessage=' Add/update organisation(s)'/>

                                            </Button>}
                                            { payment.subscription.customer.membership.membership_type == 'Entreprise' && org_member &&  
                                            <Button compact style={styles.ButtonBlueAsIconSmallPlan} disabled={active_role != OWNER}//basic color='teal'
                                                onClick={(e) => this.addExtraUsersSubscription()}>
                                                <FormattedMessage id="subscriptions.card.button.upgrade.users.subscription"
                                                                 defaultMessage=' Add/update extra user(s)'/>

                                            </Button>}
                                            <Button compact style={styles.ButtonGreenMarginPlan} disabled={active_role != OWNER}//basic color='teal'
                                                onClick={(e) => this.upgradeSubscription()}>
                                                <FormattedMessage id="subscriptions.card.button.upgrade.subscription"
                                                                 defaultMessage=' Change subscription'/>

                                            </Button>
                                            <Button compact style={styles.ButtonRedPlan} disabled={active_role != OWNER}//basic color='red'
                                                onClick={this.cancelSubscriptionClickHandler}>
                                               <FormattedMessage id="subscriptions.card.button.cancel.subscription"
                                                                 defaultMessage=' Cancel subscription'/>
                                            </Button>
                                            </div>
                                            <Modal  open={this.state.activeModal}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    //centered={true}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='trash alternate outline' content={<FormattedMessage id="subscriptions.modal.cancel.title"
                                                                                                    defaultMessage='Cancel subscription confirmation'/>} />
                                                     {!activeReasonForm &&
                                                        <Modal.Content>
                                                         <p><FormattedMessage id="subscriptions.modal.cancel.message.advise"
                                                                            defaultMessage='If you have active organisation(s) and invited user(s), all access permissions will be removed.'/></p>
                                                        <p><FormattedMessage id="subscriptions.modal.cancel.message"
                                                                            defaultMessage='Are you really sure to cancel completely your active subscription ?'/></p>
                                                        <br/> <br/>
                                                    </Modal.Content>}
                                                    {activeReasonForm &&
                                                    <Modal.Content>
                                                        <p><FormattedMessage id="subscriptions.modal.reason.message"
                                                                            defaultMessage='Please give us the main reasons of your cancellation.'/></p>
                                                         <p><FormattedMessage id="subscriptions.modal.reason.message2"
                                                                            defaultMessage='Do not hesitate to precise what you did not find here or need for your business.'/></p>
                                                        <br/>
                                                        <Form success secondary>
                                                            <FormattedMessage id="subscriptions.form.reason.placeholder"
                                                                                    defaultMessage='A few lines to help us improve your experience.'>
                                                                    {placeholder => 
                                                                        <Form.TextArea label={<FormattedMessage id="subscriptions.form.reason.title"
                                                                                                                defaultMessage='Your feedback'/>}
                                                                                    placeholder={placeholder}
                                                                                    name='message'
                                                                                    value={user.message}
                                                                                    onChange={this.handleChange}
                                                                                    rows='8'
                                                                                    disabled={reasonSubmitted}
                                                                                    //style={{ minHeight: 100 }}
                                                                                    error={!reasonSubmitted && clickSubmit && !this.handleValidation(user.message)}
                                                                      />}
                                                            </FormattedMessage>
                                                           {reasonSubmitted &&        
                                                                <Message success >
                                                                        <Message.Header>
                                                                            <FormattedMessage id="subscriptions.form.message.success.header"
                                                                                                defaultMessage='Your opinion is precious'/>
                                                                        </Message.Header>
                                                                        <p><FormattedMessage id="subscriptions.form.message.success.content"
                                                                                                defaultMessage="Thank you."/></p>
                                                                </Message>
                                                            }
                                                            {!reasonSubmitted && clickSubmit && !this.handleValidation(user.message) &&    
                                                                <Message negative >
                                                                    <Message.Header>
                                                                        <FormattedMessage id="subscriptions.form.message.error.header"
                                                                                        defaultMessage='Please write a few consistent lines'/>
                                                                    </Message.Header>
                                                                    <p><FormattedMessage id="subscriptions.form.message.error.content"
                                                                                        defaultMessage="Your feedback is precious."/></p>  
                                                              </Message>
                                                            }
                                                            {!reasonSubmitted &&
                                                            <Button style={styles.ButtonBlue}
                                                                    //compact
                                                                    //floated = 'right'
                                                                    onClick={this.onReasonSubmit}>
                                                                    <FormattedMessage id="subscriptions.form.submit"
                                                                                    defaultMessage='Submit'/>
                                                            </Button>}
                                                            <br/>
                                                        </Form>
                                                    </Modal.Content>}
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGreen} onClick={this.hideModal} 
                                                                //basic color='grey' 
                                                                >
                                                                <FormattedMessage id="subscriptions.modal.cancel.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        {!reasonSubmitted &&
                                                        <Button style={styles.ButtonGrey} onClick={this.showReasonForm} 
                                                                disabled={!reasonSubmitted && activeReasonForm}
                                                                //basic color='grey' 
                                                                >
                                                                <FormattedMessage id="subscriptions.modal.cancel.button.next"
                                                                                defaultMessage='Next'/>
                                                        </Button>}
                                                        {reasonSubmitted &&
                                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                            labelPosition='right' 
                                                            content={<FormattedMessage id="subscriptions.modal.cancel.button.yes"
                                                                                        defaultMessage='Confirm'/> }
                                                            icon='checkmark' 
                                                            onClick={this.cancelSubscription}/>}
                                                    </Modal.Actions>
                                            </Modal> 
                                        </div>
                                        }
                                        {payment && payment.subscription && 
                                            (payment.subscription.active == true) && 
                                            (payment.subscription.cancel_period_end == true) && 
                                            payment.subscription.customer &&
                                        <div>
                                            <Card fluid> 
                                                <Card.Content header='Status'>
                                                <p style={styles.LabelGreyBold}>
                                                    <FormattedMessage id="subscriptions.card.message.active.premium.endperiod"
                                                                      defaultMessage='Active subscription ( Ends at end of billing period on '/>
                                                                      {' '}<span style={{color:'#f29b0f'}}><i><FormattedDate value={new Date(payment.subscription.current_period_end)}
                                                                                        year='numeric'
                                                                                        month='long'
                                                                                        day='2-digit'/></i></span>{' )'}
                                                </p>
                                                </Card.Content>
                                                {payment.subscription.customer && payment.subscription.customer.membership &&
                                                <Card.Content>
                                                     <br/>
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title"
                                                                                        defaultMessage='PLAN '/></p>
                                                    <p style={styles.LabelGrey}> 
                                                         {/* <FormattedMessage id="subscriptions.card.message.plan.summary"
                                                                           defaultMessage='  {membership_type}   {membership_price}$/Month'
                                                                           values={{membership_type:payment.subscription.customer.membership.membership_type,
                                                                                    membership_price:payment.subscription.customer.membership.price }}/> */}
                                                            {payment.subscription.customer.membership.name} {' '}
                                                            <FormattedNumber value={Number(payment.subscription.customer.membership.price * payment.subscription.quantity)} 
                                                                             style="currency" 
                                                                             minimumFractionDigits={Number(payment.subscription.customer.membership.price * payment.subscription.quantity) % 1 === 0 ? 0 : 2}
                                                                             maximumFractionDigits={Number(payment.subscription.customer.membership.price * payment.subscription.quantity) % 1 === 0 ? 0 : 2}  
                                                                             currencyDisplay="symbol" 
                                                                             currency={(payment.subscription.customer.membership.country == "fr"?'EUR':'USD')}/>
                                                           {/*  <FormattedNumber value={payment.subscription.customer.membership.price }/> 
                                                            {payment.subscription.customer.membership.country == "fr" && <span>€</span>}
                                                            {payment.subscription.customer.membership.country == "en" && <span>$</span>}*/}
                                                            { payment.subscription.customer.membership.interval =='Annually' && 
                                                                <FormattedMessage id="subscriptions.card.message.plan.mode.year"
                                                                                    defaultMessage="/year"/>}
                                                            { payment.subscription.customer.membership.interval =='Monthly' && 
                                                                <FormattedMessage id="subscriptions.card.message.plan.mode.month"
                                                                                  defaultMessage="/month"/>}
                                                    </p>
                                                    { payment.subscription.quantity && payment.subscription.customer.membership.membership_type == 'Professional' && 
                                                            <p style={styles.LabelGrey}>{'  '}<a  style={{color:'#66b2b2','font-weight':'800'}}  onClick={(e) => this.addPremiumSubscription()}>&#8658;<FormattedMessage id="subscriptions.card.message.plan.organisation.update"
                                                                                                            defaultMessage="Upgrade to ENTREPRISE plan "/></a><br/>
                                                                {' '}{'  '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <FormattedMessage id="subscriptions.card.message.plan.organisation.update.infos"
                                                                                  defaultMessage="to manage organization(s) and invite your teammates."/></p>}
                                                    { payment.subscription.quantity && payment.subscription.customer.membership.membership_type == 'Entreprise' &&
                                                            <p style={styles.LabelGreySmall}>{'  '}<FormattedMessage id="subscriptions.card.message.plan.organisation.include"
                                                                                                            defaultMessage="include"/>
                                                                {' '}{payment.subscription.quantity}{'  '}
                                                                <FormattedMessage id="subscriptions.card.message.plan.organisation.name"
                                                                                  defaultMessage="organization(s) (or company(s)) "/></p>}
{/*                                                    { payment.subscription_extra && payment.subscription_extra.quantity && payment.subscription_extra.type == 'ExtraUsers' &&
                                                            <p style={styles.LabelGrey}>
                                                                {'+ '}{Number(payment.subscription_extra.quantity) * EXTRA_PACK_NUMBER_USERS}{' '}<FormattedMessage id="subscriptions.card.message.plan.extra.description"
                                                                                  defaultMessage="extra additional users "/>
                                                                 {' '}<FormattedMessage id="subscriptions.card.message.plan.extra.org"
                                                                                        defaultMessage="on my organization {org}."
                                                                                        values = {{org:userIn.user.active_org.name}}/>
                                                                    
                                                            </p>} */}
                                                     <br/>
                                                      { payment.subscription_extra && 
                                                        payment.subscription_extra.length > 0 && 
                                                        payment.subscription.customer.membership.membership_type == 'Entreprise' &&
                                                        this.addExtraOptionsPerOrg()}
                                                    <br/><br/>
                                                </Card.Content>}
{/*                                                 <Card.Content >
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.create"
                                                                         defaultMessage='Created: '/></p> 
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                            value={new Date(payment.subscription.created)}
                                                            year='numeric'
                                                            month='long'
                                                            day='2-digit'/>
                                                    </p>
                                                    
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.period1"
                                                                                        defaultMessage='Current Period Starts: '/></p>    
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                                     value={new Date(payment.subscription.current_period_start)}
                                                                     year='numeric'
                                                                      month='long'
                                                                      day='2-digit'/>
                                                    </p>
                                                    <p style={styles.LabelBlackBold}><FormattedMessage id="subscriptions.card.message.plan.title.date.period2"
                                                                                      defaultMessage='Current Period End: '/></p> 
                                                    <p style={styles.LabelGrey}> <FormattedDate
                                                                     value={new Date(payment.subscription.current_period_end)}
                                                                     year='numeric'
                                                                      month='long'
                                                                      day='2-digit'/>
                                                    </p>   
                                                </Card.Content> */}
                                                {payment.coupons && payment.coupons.length>0 &&
                                                    this.addCoupons(payment.coupons)
                                                }
                                            </Card>
                                            <Button style={styles.ButtonGreen} disabled={active_role != OWNER} //basic color='teal'
                                                    onClick={this.activateSubscription}>
                                                    <FormattedMessage id="subscriptions.card.button.renew.subscription"
                                                                     defaultMessage='Renew subscription'/>
                                            </Button>
                                            <Button style={styles.ButtonBlue} disabled={active_role != OWNER} //basic color='blue'
                                                    onClick={(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="subscriptions.card.button.change.subscription"
                                                                     defaultMessage='Choose another plan'/>
                                            </Button>
                                        </div>
                                        }
                                        {payment && payment.subscription && 
                                            (!payment.subscription.customer || payment.subscription.active == false) &&
                                             //customer &&
                                            <div>
                                                <br/><br/>
                                                <p style={styles.LabelGrey}>
                                                    <FormattedMessage id="subscriptions.card.no.element"
                                                                    defaultMessage='No Plan subscribed'/>{/*  {' ('}
                                                
                                                    
                                                    <FormattedMessage id="subscriptions.card.current.plan"
                                                                        defaultMessage='current plan: '/>{' '}{customer.membership.name}   
                                               {' )'}    */}
                                                <br/>
                                                {payment.coupons && payment.coupons.length == 1 &&
                                                    this.addCouponsUnsubscribed(payment.coupons)
                                                }
                                                </p>
                                                <Button style={styles.ButtonBluePremium}
                                                    onClick={(e) => {this.upgradeSubscription(e)}}>
                                                    <FormattedMessage id="subscriptions.button.premium"
                                                                    defaultMessage='Subscribe'/>
                                                </Button>
                                            </div>
                                        }
                                        </Dimmer.Dimmable>
                                </Grid.Column>
                            </Grid.Row>                                     
                        </Grid>
                </SidebarProfile>
        )
    }
}

function mapStateToProps(state) {
    const { payment,users,alert,dispatch } = state;
    const { /*customer,*/loading } = state.payment;
    return {
        loading,
        payment,
        users,
        //customer,
        alert,
        dispatch,
    };
}

const connectedSubscriptions = injectIntl(connect(mapStateToProps)(Subscriptions));
export { connectedSubscriptions as Subscriptions };
 