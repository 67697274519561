import React, { Component,Label } from 'react';
import './FloatingMenu.css';

export class FloatingMenuItem extends Component {

	handleClick() {
		this.props.action();
    }
    
	render() {
		let label;		
		if (this.props.label && !this.props.mobile) {
			label = <label>{this.props.label}</label>;
		}
		
		return <div onClick={this.handleClick.bind(this)} 
					style={{cursor:'pointer'}}
					className="floating-menu-item">
				  {label}
				  <div className="floating-menu-icon"><i className="material-icons">{this.props.icon}</i></div>
			  </div>;
	}
}

export class FloatingMenuItemOther extends Component {

	handleClick() {
		this.props.action();
    }
    
	render() {

		let label;		
		if (this.props.label && !this.props.mobile) {
			label = <label>{this.props.label}</label>;
		}
		
		return <div onClick={this.handleClick.bind(this)}
					style={{cursor:'pointer'}}
					className="floating-menu-item">
				  {label}
				  <div className="floating-menu-icon-other"><i className="material-icons">{this.props.icon}</i></div>
			  </div>;
	}
}
