import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, Menu, List, Container, Icon } from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';


const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};

class SideLeftUncoverIngredient extends Component {
  state = { visible: false }

  isActive(match1){
    return this.props.location.pathname.indexOf(match1)!=-1
  }

  render() {
    const { id } = this.props;
    return (
      <div>
      <Responsive minWidth={Responsive.onlyComputer.minWidth} >
        {/* <Segment textAlign='left' style={{ minHeight:40, padding: '1em 0em' }} basic vertical> */}
          <Sidebar.Pushable as={Container} style={containerStyle}>
            <Sidebar as={List} selection animation='uncover'width='thin' visible={true} icon='labeled' vertical size='large'>
{/*               <Menu.Item name='New Ingredient' as={Link} to={'/newingredient'}>
                <Icon name='add'/>
                Add Ingredient
              </Menu.Item>
              <Menu.Item name='Edit Ingredient' as={Link} to='/editingredient' >
                <Icon name='edit'/>
                Edit Ingredient
              </Menu.Item>
              <Menu.Item name='Delete Ingredient' >
                <Icon name='trash'/>
                Delete Ingredient
              </Menu.Item> */}
               <br/>
               <List.Item disabled><FormattedMessage id="ingredient.computer.sidemenu.menu.top.title"
                                                       defaultMessage='Ingredient Actions'/></List.Item>
                <List.Item name='View Ingredients' as={Link} to='/ingredients'>
                  <Icon name='home' color={this.isActive('ingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('ingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem1.title"
                                                   defaultMessage='Ingredients'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Info Ingredient' as={Link} to={'/ingredientdetails/'+id}>
                  <Icon name='folder open outline' color={this.isActive('ingredientdetails')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('ingredientdetails')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem2.title"
                                                   defaultMessage='Details'/></List.Header>
                  </List.Content>
                </List.Item>       
                <List.Item name='Edit Ingredient' as={Link} to={'/editingredient/'+id}>
                  <Icon name='edit' color={this.isActive('editingredient')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('editingredient')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem3.title"
                                                   defaultMessage='Edit'/></List.Header>
                  </List.Content>
                </List.Item>
{/*                 <List.Item name='Images' disabled>
                  <Icon name='image' color='grey'/>
                  <List.Content>
                    <List.Header><FormattedMessage id="ingredient.computer.sidemenu.menu.elem4.title"
                                                   defaultMessage='Images'/></List.Header>
                  </List.Content>
                </List.Item> */}
                <List.Item name='Nutrition' as={Link} to={'/ingredientnutritionfacts/'+id}>
                  <Icon name='heartbeat' color={this.isActive('ingredientnutritionfacts')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('ingredientnutritionfacts')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem5.title"
                                                   defaultMessage='Nutrition details'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='History' as={Link} to={'/historyingredient/'+id}>
                  <Icon name='history' color={this.isActive('historyingredient')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('historyingredient')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem6.title"
                                                   defaultMessage='History'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Delete Ingredient' as={Link} to={'/deleteingredient/'+id}>
                  <Icon name='trash alternate outline' color={this.isActive('deleteingredient')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('deleteingredient')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="ingredient.computer.sidemenu.menu.elem7.title"
                                                   defaultMessage='Delete'/></List.Header>
                  </List.Content>
                </List.Item>
            </Sidebar>
            <Sidebar.Pusher style={{ 'min-height':'100vh', width: '100%', padding: '2em 0em 2em 2em' ,'background-color':'#fcfcfc' }}>
                  {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
        {/* </Segment> */}
      </Responsive>
      <Responsive  minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/>Ingredients
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                {/* <Container> */}
{/*                   <Menu.Item name='New Ingredient' as={Link} to={'/newingredient'}>
                    <Icon name='add'/>
                    Add Ingredient
                  </Menu.Item> */}
                   <Menu.Item name='View'as={Link} to='/ingredients'>
                      <Icon name='home' color={this.isActive('ingredients')?'blue': 'black'}/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Ingredients'/>
                    </Menu.Item>
                    <Menu.Item name='Info Ingredient' as={Link} to={'/ingredientdetails/'+id}>
                    <Icon name='folder open outline' color={this.isActive('ingredientdetails')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='Details'/>
                  </Menu.Item>
                  <Menu.Item name='Edit Ingredient' as={Link} to={'/editingredient/'+id}>
                    <Icon name='edit' color={this.isActive('editingredient')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Edit'/>
                  </Menu.Item>
{/*                   <Menu.Item name='Images'disabled>
                      <Icon name='image' color='grey'/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Images'/>
                    </Menu.Item> */}
                    <Menu.Item name='Nutrition'  as={Link} to={'/ingredientnutritionfacts/'+id}>
                      <Icon name='heartbeat' color={this.isActive('ingredientnutritionfacts')?'blue': 'black'}/>
                       <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Nutrition'/>
                    </Menu.Item>
                   <Menu.Item name='History' as={Link} to={'/historyingredient/'+id}>
                      <Icon name='history' color={this.isActive('historyingredient')?'blue': 'black'}/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='History'/>
                    </Menu.Item>
                  <Menu.Item name='Delete Ingredient' as={Link} to={'/deleteingredient/'+id}>
                    <Icon name='trash alternate outline' color={this.isActive('deleteingredient')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Delete'/>
                  </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 2em' }}>
                    {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/>Ingredients
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                {/* <Container> */}
{/*                   <Menu.Item name='New Ingredient' as={Link} to={'/newingredient'}>
                    <Icon name='add'/>
                    Add Ingredient
                  </Menu.Item> */}
                   <Menu.Item name='View'as={Link} to='/ingredients'>
                      <Icon name='home' color={this.isActive('ingredients')?'blue': 'black'}/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Ingredients'/>
                    </Menu.Item>
                    <Menu.Item name='Info Ingredient' as={Link} to={'/ingredientdetails/'+id}>
                    <Icon name='folder open outline' color={this.isActive('ingredientdetails')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='Details'/>
                  </Menu.Item>
                  <Menu.Item name='Edit Ingredient' as={Link} to={'/editingredient/'+id}>
                    <Icon name='edit' color={this.isActive('editingredient')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Edit'/>
                  </Menu.Item>
{/*                   <Menu.Item name='Images'disabled>
                      <Icon name='image' color='grey'/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Images'/>
                    </Menu.Item> */}
                    <Menu.Item name='Nutrition'  as={Link} to={'/ingredientnutritionfacts/'+id}>
                      <Icon name='heartbeat' color={this.isActive('ingredientnutritionfacts')?'blue': 'black'}/>
                       <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Nutrition'/>
                    </Menu.Item>
                   <Menu.Item name='History' as={Link} to={'/historyingredient/'+id}>
                      <Icon name='history' color={this.isActive('historyingredient')?'blue': 'black'}/>
                      <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='History'/>
                    </Menu.Item>
                  <Menu.Item name='Delete Ingredient' as={Link} to={'/deleteingredient/'+id}>
                    <Icon name='trash alternate outline' color={this.isActive('deleteingredient')?'blue': 'black'}/>
                    <FormattedMessage id="ingredient.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Delete'/>
                  </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 0em' }}>
                    {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
      </Responsive>
      </div>
    )
  }
}

export default withRouter(SideLeftUncoverIngredient)
