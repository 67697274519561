import React, { Component } from 'react';
import { Button,Header,Dropdown, Form,Grid,Icon,Segment,Popup, GridColumn,Radio,Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {userActions,alertActions,paymentActions} from '../_actions'
import {toaster_dispatch,Capitalize,GUEST,history,trim} from '../_helpers';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles.jsx';
import currencies from './currency.json';

import Dropzone from 'react-dropzone';
import DEFAULT_LOGO_IMAGE from '../Assets/ratatool-logo.png'
 


let title = <FormattedMessage id="settings.file.error.title"
                              defaultMessage="Saving Error"/>
let content_err_file_size = <FormattedMessage id="settings.file.size.error.content"
                                              defaultMessage="File size or format not supported"/> 
let content_permission = <FormattedMessage id="settings.file.permission.error.content"
                                              defaultMessage="You do not have Write privilege"/>  

/* const currencyList = [
    { key: 0, text: 'Dollar ($)', value: 'USD' },
    { key: 1, text: 'Euro (€)', value: 'EUR' },
    { key: 2, text: 'Pound (£)',  value: 'GBP' },
    { key: 3, text: 'Franc suisse (CHF)',  value: 'CHF' },
    { key: 4, text: <FormattedMessage id="settings.current.list.none"
                                      defaultMessage='None'/>,  value: 'None' }
  ] */

/*  const logoList = [
    { key: 0, text: 'ratatool', value: 'ratatool' },
    { key: 1, text: <FormattedMessage id="settings.current.list.mylogo"
                                      defaultMessage='custom'/>, value: 'logo' },
    { key: 2, text: <FormattedMessage id="settings.current.list.none"
                                      defaultMessage='none'/>, value: 'none' }
  ] */

  
class Settings extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
            prevUsers : props.users,
            username: "",
            currency:"",
            language:"",
            currencyList:[],
            logo:"ratatool",
            active_role: userIn.user.role,
            active_org : userIn.user.active_org,
            org_member: userIn.user.active_org?true:false,
        }
        this.OnCurrencyChange=this.OnCurrencyChange.bind(this);
        //this.OnLogoChange=this.OnLogoChange.bind(this);
        this.OnRadioChange=this.OnRadioChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this); 
        props.dispatch(userActions.get());
        props.dispatch(paymentActions.getCustomerMembership());
    }

    static getDerivedStateFromProps(props, state) {
        if((props.users!==state.prevUsers) && (props.users.items)){

            let image_logo
            let logo
            if (state.org_member && props.users.items.active_org){
                image_logo = props.users.items.active_org.image_logo;
                logo =  props.users.items.active_org.use_logo == true ?'ratatool':'none'
                if (props.users.items.active_org.use_custom_logo == true) {
                    logo = 'logo'
                }
            }else{
                image_logo = props.users.items.image_logo;
                logo =  props.users.items.use_logo == true ?'ratatool':'none'
                if (props.users.items.use_custom_logo == true) {
                    logo = 'logo'
                }
            }
 
            let result = { prevUsers :  props.users,
                           username: props.users.items.username,
                           language: props.users.items.language,
                           currency: props.users.items.currency,
                           logo: logo };

            Object.assign(result,{ file:image_logo}); 
            return result;
        }
        return null;
      }
    
    
    componentDidMount() {
        const currencyList = currencies.map((c,index) => {
            let output_text = ''
            if (c.name == 'None')
            {
                output_text = <FormattedMessage id="settings.current.list.none"
                                                defaultMessage='none'/>
            }else{
                output_text = '' + c.code+' ('+c.name+')';
            }
            return (
                { key: index, text: output_text , value: c.code }
            )

        })
        this.setState({ currencyList: currencyList});
    }
    
    OnCurrencyChange = (e,{ value }) => {
        e.preventDefault();
        this.setState({ currency: value });
        this.forceUpdate();
    }

/*     OnLogoChange = (e,{ value }) => {
        e.preventDefault();
        this.setState({ logo: value });
        this.forceUpdate();
    } */

    OnRadioChange = (e,{ value }) => { 
         e.preventDefault();
         this.setState({ logo: value });
    }
    

    onSubmit = (e) => {
        e.preventDefault();

        const {currency,username,active_role} = this.state; 
        const {dispatch} = this.props;
        if (active_role != GUEST){
            if (currency && username) {
            const user_infos = {
                        username:username,
                        currency:currency,
            }
            dispatch(userActions.update(user_infos,'/settings'));
            }
        }else{
            dispatch(alertActions.error({title: 'settings.file.error.title',
                                        content:'settings.file.permission.error.content'}));
        }
    }

    onSubmitLogo = (e) => {
        e.preventDefault();

        const {logo,username,currency,active_role,org_member,current_file,active_org} = this.state;
        const {dispatch} = this.props;
        if (active_role !== GUEST){

            if (org_member && active_org ){

                if (logo) {
                    const org_infos = {
                                    name:trim(active_org.name),
                                    use_logo:(logo=='ratatool')?true:false,
                                    use_custom_logo:(logo=='logo')?true:false,
                    }
                    this.props.dispatch(userActions.editOrganisationLogo(active_org.id,org_infos,current_file,"/settings"));
                }
            } else{

                if (logo && currency && username) {
                    const user_infos = {
                                username:username,
                                use_logo:(logo=='ratatool')?true:false,
                                use_custom_logo:(logo=='logo')?true:false,
                    }
                dispatch(userActions.updateLogo(user_infos,current_file,'/settings'));
                }
           }
        }else{
                dispatch(alertActions.error({title: 'settings.file.error.title',
                                            content:'settings.file.permission.error.content'}));
        }
    }

    onDropAccepted(acceptedFiles) {
        const { dispatch} = this.props;
        const { username,logo,org_member,active_org} = this.state;
        if (acceptedFiles[0]) {
         let current_file = acceptedFiles[0];
          this.setState({ file: URL.createObjectURL(acceptedFiles[0])});
          this.setState({ current_file: current_file});

          if (org_member && active_org ){
            const org_infos = {name:trim(active_org.name),
                              use_logo:(logo=='ratatool')?true:false,
                              use_custom_logo:(logo=='logo')?true:false}
            this.props.dispatch(userActions.editOrganisationLogo(active_org.id,org_infos,current_file,"/settings"));
          }else{
                const user_infos = { username:username,
                                     use_logo:(logo=='ratatool')?true:false,
                                     use_custom_logo:(logo=='logo')?true:false,}
                dispatch(userActions.updateLogo(user_infos,current_file,'/settings',true));
          }

        }
    }
  
    onDropRejected(rejectedFiles) {
        const {dispatch} = this.props;
        //this.setState({rejectedFile:rejectedFiles[0]});
        dispatch(alertActions.error({title: 'settings.file.error.title',
                                    content:'settings.file.size.error.content'}));
    }
  
    triggerInputFile(event){
      const { dispatch} = this.props;
      const { username,org_member,active_org} = this.state;
      const {file,active_role} = this.state;
      if (file){
          event.stopPropagation()
          if (active_role !== GUEST){
            if (username){
                //this.setState({file:null});
                //this.setState({current_file:null});

                if (org_member && active_org ){
                    const org_infos = {name:trim(active_org.name),
                                        use_logo:true,
                                        logo_delete: true}
                    this.props.dispatch(userActions.editOrganisationLogo(active_org.id,org_infos,null,"/settings"));
                }else{
                    const user_infos = { username:username,
                                         use_logo:true,
                                         logo_delete: true}
                    dispatch(userActions.updateLogo(user_infos,null,'/settings',true));
                }
            }
          }
          else{
            dispatch(alertActions.error({title: 'settings.file.error.title',
                                        content:'settings.file.permission.error.content'}));
          }
      }       
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: true}})
    }

    render() { 
        const { users,alert,dispatch,intl,customer,loading, } = this.props;
        const { currency,language,currencyList,active_role,file,logo,org_member,active_org} = this.state;

        let user = null;
        let membership = null;
        let authorized = false;
    
        if (customer){
          membership = customer.membership;
          user = customer.user;
        }

        if (membership && user && ((membership.membership_type=="Entreprise")  /*||  (user.bypass==true )*/)){
            authorized = true
        }

        return ( 
                <SidebarProfile >
                        <Grid stackable style={styles.FontLato12}>
                            <Grid.Row columns={1} >
                                <Grid.Column width={8}>
                                    <Header as='h1'>
                                        <Icon name='settings' color='blue'  size='huge' />
                                        <Header.Content>
                                            <FormattedMessage id="settings.top.title.header"
                                                              defaultMessage='Settings'/>
                                            <Header.Subheader><FormattedMessage id="settings.top.subtitle"
                                                              defaultMessage='Tool preferences'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={6}*/  mobile={10} tablet={8} computer={6}>
                                        <br/>
                                        <Header as='h3' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="settings.top.form.title"
                                                               defaultMessage='User settings'/>
                                        </Header>
                                        {users && users.items && 
                                          <Segment raised>
                                            <Form style={{paddingLeft:'1em'}}>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="settings.form.input.language"
                                                                                                     defaultMessage='Language'/></label>
                                                    <label style={{'paddingLeft':'1em'}}>{Capitalize(language)}</label>
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="settings.form.choice.currency"
                                                                                                      defaultMessage='Preferred Currency'/></label>
                                                    <FormattedMessage id="settings.form.choice.name.placeholder"
                                                                    defaultMessage='Currency'>
                                                        {placeholder =>                                                                                
                                                                <Dropdown selection
                                                                          options= {currencyList} 
                                                                          value= {currency}
                                                                          placeholder={placeholder}
                                                                          onChange={this.OnCurrencyChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <br/>
                                                <Button style={styles.ButtonGreen}
                                                     onClick={this.onSubmit}>
                                                    <FormattedMessage id="settings.form.button.save"
                                                                       defaultMessage='Save'/>
                                                </Button>
                                            </Form>
                                            </Segment>}
                                </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2} >
                                <Grid.Column /*width={6}*/  mobile={10} tablet={8} computer={6}>
                                        <br/>
                                        <Header as='h3' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="settings.top.form2.title"
                                                               defaultMessage='Documents settings'/>
                                             {org_member && 
                                             <Label color='blue'><FormattedMessage id="settings.top.form.organisation"
                                                                                   defaultMessage='ORGANIZATION | '/>{' '}{active_org.name}</Label>}
                                        </Header>
                                        {!loading && users && users.items && 
                                         <Segment raised>
                                            <Form style={{paddingLeft:'1em'}}>
                                                <br/>
                                                <Form.Field>
                                                <FormattedMessage id="settings.form2.input.logo"
                                                                defaultMessage='Logo display'/>
                                                </Form.Field>
                                                <br/>
                                                <Form.Field>
                                                  <FormattedMessage id="settings.current.list.default"
                                                                     defaultMessage='default'>
                                                     {label => 
                                                        <Radio
                                                            label={label}
                                                            name='radioGroup'
                                                            value='ratatool'
                                                            checked={logo == 'ratatool'}
                                                            onChange={this.OnRadioChange}
                                                        />}
                                                    </FormattedMessage>
                                                    <div style={{paddingLeft:'9.5em'}}>
                                                        <img alt='ratatool logo' src={DEFAULT_LOGO_IMAGE} style={{ marginRight: '2.5em' , width: '138px', height: '42px'  }}/>
                                                    </div>
                                                </Form.Field>
                                                <Segment /* secondary color='teal'*/ raised >
                                                <Form.Field>
                                                   <FormattedMessage id="settings.current.list.mylogo"
                                                                     defaultMessage='custom (500x150 - max 1Mb)'>
                                                    {label => 
                                                        <Radio
                                                            label={label}
                                                            name='radioGroup'
                                                            value='logo'
                                                            checked={logo == 'logo'}
                                                            onChange={this.OnRadioChange}
                                                            //disabled = {!file}
                                                            disabled = {!authorized || !file}
                                                        />}
                                                    </FormattedMessage>
                                                    <div style={{paddingLeft:'6.5em', paddingTop:'0.5em'}}>
                                                        <Dropzone onDropAccepted={this.onDropAccepted.bind(this)}
                                                            onDropRejected={this.onDropRejected.bind(this)}
                                                            multiple = {false}
                                                            maxSize={1048576} //1MB
                                                            accept="image/*"
                                                            disabled = {!authorized || (active_role==GUEST)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section {...getRootProps()}
                                                                        style={{ 'height':'60px',
                                                                                 'width': '180px',
                                                                                 'backgroundColor': '#f5f5f5',
                                                                                 'overflow': 'auto',
                                                                                 'text-align' : 'center'}}>
                                                                <div style={{'text-align':'center'}}>
                                                                    <input {...getInputProps()}/>
                                                                    {!file && <div className="image-div-ingredient"  style={{paddingLeft:'5em',paddingTop:'1em'}}>
                                                                            <Icon color='teal'
                                                                                    size='big' 
                                                                                    name='cloud upload'
                                                                                    style= {{cursor:authorized?'pointer':'not-allowed'}}></Icon>
                                                                    </div>}
                                                                    {file &&
                                                                    <div className="image-div-ingredient" style={{'text-align' : 'center',width:'100%'}}>
                                                                        <img src={file} 
                                                                            style={{display:'block',width:'auto',height:'auto', "max-width": "160px", "max-height":"50px","margin": "0 auto",
                                                                            'text-align' : 'center','justify-content': 'center;',paddingTop:'0.2em' /* ,'object-fit': 'scale-down' */}}/>
                                                                        <Icon className="hidden_button"
                                                                                    style= {{cursor:authorized?'pointer':'not-allowed',textAlign:'right',paddingRight:'1em'}}
                                                                                    color='red'
                                                                                    name='trash'
                                                                                    onClick={(event)=>this.triggerInputFile(event)}></Icon>
                                                                    </div>}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                </Form.Field>
                                                {!loading && !authorized &&
                                                    <Form.Field align='right'>
                                                        <Label color='blue' onClick={(e) => this.upgradeSubscription()} style={{'cursor': 'pointer'}}>
                                                        <FormattedMessage id="settings.recipes.form.options.option.list.premium"
                                                                            defaultMessage='ENTERPRISE option'/>
                                                        </Label>
                                                    </Form.Field>}
                                                </Segment>
                                                <Form.Field>
                                                     <FormattedMessage id="settings.current.list.none"
                                                                       defaultMessage='none'>
                                                        {label => 
                                                            <Radio
                                                                label={label}
                                                                name='radioGroup'
                                                                value='none'
                                                                checked={this.state.logo === 'none'}
                                                                onChange={this.OnRadioChange}
                                                        />}
                                                     </FormattedMessage>
                                                </Form.Field>
                                                <br/>
                                                <Button style={styles.ButtonGreen}
                                                     onClick={this.onSubmitLogo}>
                                                    <FormattedMessage id="settings.form.button.save"
                                                                       defaultMessage='Save'/>
                                                </Button>
                                            </Form>
                                         </Segment>}
                                </Grid.Column>
                                <GridColumn>

                                </GridColumn>
                            </Grid.Row>
                        </Grid>
                </SidebarProfile>
        )
    }
}

function mapStateToProps(state) {
    const { users,alert,dispatch } = state;
    const { customer } = state.payment;
    const { loading } = state.users;
    return {
        customer,
        users,
        loading,
        alert,
        dispatch,
    };
}

const connectedSettings = injectIntl(connect(mapStateToProps)(Settings));
export { connectedSettings as Settings };
 