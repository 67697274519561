import React from 'react';
import {Icon} from 'semantic-ui-react';

class Likes extends React.Component {

  constructor(props){

    super(props);
    this.state ={
      likes: 12,
      updated: false
    }
    this.updateLikes = this.updateLikes.bind(this);
  }

  updateLikes() {

    if(!this.state.updated) {
      this.setState((prevState, props) => {
        return {
          likes: prevState.likes + 1,
          updated: true
        };
      });
    } else {

      this.setState((prevState, props) => {
        return {
          likes: prevState.likes - 1,
          updated: false
        };
      });
    }


  }

  render(){

    return(
      <div style={{color:'#1765af'}} onClick={this.updateLikes}>
       {/*  <p > */}
            <Icon name='heart outline' size='small'/>{this.state.likes} likes
       {/*  </p> */}
      </div>
    );

  }


}

export default Likes;