import React, { Component } from 'react'
import { Sidebar, Segment, Container, Menu, List, Icon, Responsive } from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};


class SideLeftUncoverRecipe extends Component {
  state = { visible: false }

  isActive(match1){
    return this.props.location.pathname.indexOf(match1)!=-1
  }

  render() {
    const { id } = this.props;
    return (
      <div>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        {/* <Segment textAlign='left' style={{ minHeight:40, padding: '1em 0em' }} basic vertical> */}
          <Sidebar.Pushable as={Container} style={containerStyle}>
            <Sidebar as={List} 
                    selection 
                    animation='uncover' 
                    width='thin' 
                    visible={true} 
                    icon='labeled' 
                    vertical
                    size='large'>
               <br/>
               <List.Item disabled>
                      <FormattedMessage id="recipe.computer.sidemenu.menu.top.title"
                                        defaultMessage='Recipe Actions'/>
               </List.Item>
                <List.Item name='View Recipes' as={Link} to='/recipes'>
                  <Icon name='home' color={this.isActive('/recipes')?'blue':'black'}   />
                  <List.Content>
                    <List.Header style={this.isActive('/recipes')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem1.title"
                                          defaultMessage='Recipes'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Summary Recipe' as={Link} to={'/recipedetails/'+ id}>
                  <Icon name='folder open outline' color={this.isActive('recipedetails')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('recipedetails')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem2.title"
                                          defaultMessage='Summary'/>
                    </List.Header>
                  </List.Content>
                </List.Item> 
                <List.Item name='Edit Recipe' as={Link} to={'/editrecipe/'+ id}>
                  <Icon name='edit' color={this.isActive('editrecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('editrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipe.computer.sidemenu.menu.elem3.title"
                                          defaultMessage='Edit'/>
                    </List.Header>
                  </List.Content>
                </List.Item> 
                <List.Item name='Prepare Instructions' as={Link} to={'/instructionsrecipe/'+ id}>
                  <Icon name='book' color={this.isActive('instructionsrecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('instructionsrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                       <FormattedMessage id="recipe.computer.sidemenu.menu.elem4.title"
                                          defaultMessage='Prepare Instructions'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
{/*                 <List.Item name='Images' disabled>
                  <Icon name='image' color='grey'/>
                  <List.Content>
                    <List.Header>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem5.title"
                                          defaultMessage='Images'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Scale' disabled>
                  <Icon name='balance scale' color='grey'/>
                  <List.Content>
                    <List.Header>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem6.title"
                                          defaultMessage='Scale'/>
                    </List.Header>
                  </List.Content>
                </List.Item> */}
                <List.Item name='Nutrition'  as={Link} to={'/recipenutrition/'+ id}>
                  <Icon name='heartbeat' color={this.isActive('recipenutrition')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('recipenutrition')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem10.title"
                                          defaultMessage='Nutrition'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Download Recipe' as={Link} to={'/downloadrecipe/'+ id}>
                  <Icon name='download' color={this.isActive('downloadrecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('downloadrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem8.title"
                                          defaultMessage='Export/Produce'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Copy' as={Link} to={'/copyrecipe/'+ id}>
                  <Icon name='copy' color={this.isActive('copyrecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('copyrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipe.computer.sidemenu.menu.elem7.title"
                                          defaultMessage='Copy'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Delete Recipe' as={Link} to={'/deleterecipe/'+ id }>
                  <Icon name='trash alternate outline' color={this.isActive('deleterecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('deleterecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="recipe.computer.sidemenu.menu.elem9.title"
                                          defaultMessage='Delete'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
            </Sidebar>
            <Sidebar.Pusher style={{  'min-height': '100vh',overflowY: 'auto', width: '100%', padding: '2em 2em 2em 3em','background-color': '#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
        {/* </Segment> */}
      </Responsive>
      <Responsive  minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="recipe.mobile.sidemenu.menu.top.title"
                                                           defaultMessage='Recipes'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                {/* <Container> */}
{/*                 <Menu.Item name='New Recipe' as={Link} to={'/newrecipe'}>
                      <Icon name='add'/>
                      Add Recipe
                  </Menu.Item> */}
                   <Menu.Item name='View'as={Link} to='/recipes'>
                      <Icon name='home' color={this.isActive('/recipes')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Recipes'/>
                    </Menu.Item>
                    <Menu.Item name='Summary Recipe' as={Link} to={'/recipedetails/'+ id}>
                      <Icon name='folder open outline' color={this.isActive('/recipedetails')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='Summary'/>
                  </Menu.Item>
                  <Menu.Item name='Edit Recipe' as={Link} to={'/editrecipe/'+ id}>
                      <Icon name='edit' color={this.isActive('/editrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem3.title"
                                        defaultMessage='Edit'/>
                  </Menu.Item>
                   <Menu.Item name='Prepare' as={Link} to={'/instructionsrecipe/'+ id}>
                      <Icon name='book' color={this.isActive('/instructionsrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem4.title"
                                        defaultMessage='Prepare'/>
                    </Menu.Item>
{/*                    <Menu.Item name='Images' disabled>
                      <Icon name='image' color='grey'/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem5.title"
                                        defaultMessage='Images'/>
                    </Menu.Item>
                    <Menu.Item name='Scale' disabled>
                      <Icon name='balance scale' color='grey'/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem6.title"
                                        defaultMessage='Scale'/>
                    </Menu.Item> */}
                    <Menu.Item name='Nutrition' as={Link} to={'/recipenutrition/'+ id}>
                      <Icon name='heartbeat' color={this.isActive('/recipenutrition')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem10.title"
                                        defaultMessage='Nutrition'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to={'/downloadrecipe/'+ id}>
                      <Icon name='download' color={this.isActive('/downloadrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem8.title"
                                        defaultMessage='Export/Produce'/>
                    </Menu.Item>
                    <Menu.Item name='Copy' as={Link} to={'/copyrecipe/'+ id}>
                      <Icon name='copy' color={this.isActive('/copyrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem7.title"
                                        defaultMessage='Copy'/>
                    </Menu.Item>
                  <Menu.Item name='Delete Recipe' disabled = {!id} as={Link} to={'/deleterecipe/'+ id} >
                      <Icon name='trash alternate outline' color={this.isActive('/deleterecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem9.title"
                                        defaultMessage='Delete'/>
                  </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{minHeight: '100vh', overflow: 'auto', padding: '2em 0em 2em 2em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="recipe.mobile.sidemenu.menu.top.title"
                                                           defaultMessage='Recipes'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                {/* <Container> */}
{/*                 <Menu.Item name='New Recipe' as={Link} to={'/newrecipe'}>
                      <Icon name='add'/>
                      Add Recipe
                  </Menu.Item> */}
                   <Menu.Item name='View'as={Link} to='/recipes'>
                      <Icon name='home' color={this.isActive('/recipes')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Recipes'/>
                    </Menu.Item>
                    <Menu.Item name='Summary Recipe' as={Link} to={'/recipedetails/'+ id}>
                      <Icon name='folder open outline' color={this.isActive('/recipedetails')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='Summary'/>
                  </Menu.Item>
                  <Menu.Item name='Edit Recipe' as={Link} to={'/editrecipe/'+ id}>
                      <Icon name='edit' color={this.isActive('/editrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem3.title"
                                        defaultMessage='Edit'/>
                  </Menu.Item>
                   <Menu.Item name='Prepare' as={Link} to={'/instructionsrecipe/'+ id}>
                      <Icon name='book' color={this.isActive('/instructionsrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem4.title"
                                        defaultMessage='Prepare'/>
                    </Menu.Item>
{/*                    <Menu.Item name='Images' disabled>
                      <Icon name='image' color='grey'/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem5.title"
                                        defaultMessage='Images'/>
                    </Menu.Item>
                    <Menu.Item name='Scale' disabled>
                      <Icon name='balance scale' color='grey'/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem6.title"
                                        defaultMessage='Scale'/>
                    </Menu.Item> */}
                    <Menu.Item name='Nutrition' as={Link} to={'/recipenutrition/'+ id}>
                      <Icon name='heartbeat' color={this.isActive('/recipenutrition')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem10.title"
                                        defaultMessage='Nutrition'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to={'/downloadrecipe/'+ id}>
                      <Icon name='download' color={this.isActive('/downloadrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem8.title"
                                        defaultMessage='Export/Produce'/>
                    </Menu.Item>
                    <Menu.Item name='Copy' as={Link} to={'/copyrecipe/'+ id}>
                      <Icon name='copy' color={this.isActive('/copyrecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem7.title"
                                        defaultMessage='Copy'/>
                    </Menu.Item>
                  <Menu.Item name='Delete Recipe' disabled = {!id} as={Link} to={'/deleterecipe/'+ id} >
                      <Icon name='trash alternate outline' color={this.isActive('/deleterecipe')?'blue':'black'}/>
                      <FormattedMessage id="recipe.mobile.sidemenu.menu.elem9.title"
                                        defaultMessage='Delete'/>
                  </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{minHeight: '100vh', overflow: 'auto', padding: '2em 0em 2em 0em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
      </Responsive>
      </div>
    )
  }
}

export default withRouter(SideLeftUncoverRecipe)
