import React, { Component } from 'react'
import {Segment,Header,Responsive} from 'semantic-ui-react';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import {MainFooter,ResponsiveContainer} from './Segments';
import img_404 from '../Assets/404.jpg';


class Error404Page extends Component {
    render() {
        return (
            <ResponsiveContainer>
               <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                  <Segment vertical style={{ padding: '0em 0em 0em 0em' }}>
                      <div style={styles.image}>
                          <img src={img_404} alt="Error404" width={'100%'} height={'100%'} />
                          <Header as="h1" style={styles.textMainTopLeftImage}>
                                      <FormattedMessage id="error404.image.title.text"
                                                        defaultMessage="Oops! That page can’t be found."/></Header>
      {/*                     <h3 style={styles.textDetailTopLeftImage}>
                                  It looks like nothing was found at this location.
                                  Maybe try one of the links in the menu or press back to go to the previous page.
                          </h3> */}
                      </div> 
                  </Segment>
              </Responsive>
              <Responsive  maxWidth={Responsive.onlyTablet.maxWidth}>
                  <Segment vertical style={{ padding: '0em 0em 0em 0em' }}>
                      <div style={styles.image}>
                          <img src={img_404} alt="Error404" width={'100%'} height={'100%'} />
                          <Header as="h3" style={styles.textMainTopLeftImage}>
                                      <FormattedMessage id="error404.image.title.text"
                                                        defaultMessage="Oops! That page can’t be found."/></Header>
      {/*                     <h3 style={styles.textDetailTopLeftImage}>
                                  It looks like nothing was found at this location.
                                  Maybe try one of the links in the menu or press back to go to the previous page.
                          </h3> */}
                      </div> 
                  </Segment>
              </Responsive>
              <MainFooter/>
        </ResponsiveContainer>
        );
    }
}

const connectedError404Page = injectIntl(Error404Page);
export { connectedError404Page as Error404Page };