import React, { Component } from 'react';
import { Table,Grid,Checkbox,Button,Header,Modal,Card,Input,Dimmer,Loader,Breadcrumb,Popup,Icon} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverIngredients from './SidebarIngredients';
import { categoryIngredientActions } from '../_actions';
import {toaster_dispatch} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
//import img_categoriesRecipes from '../Assets/categoriesRecipes.jpeg'
import {styles} from './Styles';
import img_categories from '../Assets/categories.png'

class CategoriesIngredient extends Component {
    constructor(props) {
        super(props);
        this.state = { newCategory: '',
                       activeModalDelete: null,
                       activeModalEdit: null }
        this.hideModal = this.hideModal.bind(this);
        //this.hideErrorNotification = this.hideErrorNotification.bind(this);
        //this.showErrorNotification = this.showErrorNotification.bind(this);
        props.dispatch(categoryIngredientActions.do_count_ingredients());
        props.dispatch(categoryIngredientActions.getAll());
    }

/*
    hideErrorNotification() {
        const { dispatch } = this.props;
        this.setState({ errorNotification: false })
        this.setState({ activeModalDelete: null })
        this.setState({ activeModalEdit: null })
        dispatch(alertActions.clear());
        window.location.reload();
    }

    showErrorNotification() {
        this.setState({ errorNotification: true })
    }*/

    onNewCategoryChange = (e) => {
        e.preventDefault();
        this.setState({ newCategory: e.target.value });
    }

    onEditCategory = (e) => {
        e.preventDefault();
        this.setState({ newCategory: e.target.value });
    }

    addCategory = (e) => {
        e.preventDefault();
        let newCategory = this.state.newCategory;

        if (newCategory && newCategory !=''){
            newCategory = newCategory.slice(0,50);
            this.props.dispatch(categoryIngredientActions.add(newCategory));
        }
    }
    
    editCategory (event,category) {
        event.preventDefault();
        let newCategory = this.state.newCategory;
        
        if (newCategory !=''){
            newCategory = newCategory.slice(0,50);
            this.props.dispatch(categoryIngredientActions.update(category.id,newCategory,category.is_active));
        }
    }

    deleteCategory (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(categoryIngredientActions.delete(index))
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,name) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ newCategory: name });
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ newCategory: '' });
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }

    onActiveChange = (event, category) => {
        event.preventDefault();
        this.props.dispatch(categoryIngredientActions.update(category.id,
                                                             category.name,
                                                             event.target.checked,
                                                             false));
    }
    

    addCategoriesRows(categoriesIngredient){
        return categoriesIngredient.items.map((category,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell >
                            <a href="#" 
                               onClick={(event)=>this.editClickHandler(event,index,category.name)}>{category.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{category.nb_ingredients}</Table.Cell>
                        <Table.Cell textAlign='center'>
                                <Checkbox id={index}
                                       checked = {category.is_active}
                                       style={{marginTop:'0em'}}
                                       onChange={(event) =>this.onActiveChange(event,category)}/></Table.Cell>
                        <Table.Cell textAlign='right'>
{/*                             <Popup trigger=
                                {<Button className="ui small icon button" 
                                onClick={(event)=>this.editCategory(event,category.id)}>
                                <i className="edit icon"></i>
                                 </Button>} 
                                content='Rename'> 

                                 <Input
                                        type="text"
                                        placeholder={category.name}
                                        id="renameCategory"
                                        value={this.state.newCategory}
                                        onChange={this.onRenameCategory}
                                        style={{minWidth:'15em',maxWidth:'30em'}}/>

                            </Popup> */}
{/*                             <Button basic id={index} className="ui icon button circular" color='blue' 
                                onClick={(event)=>this.editClickHandler(event,index,category.name)}>
                                <i className="edit icon"></i>
                                 </Button>
                            <Button basic id={index} className="ui icon button circular" color='grey' 
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash alternate outline icon"></i>
                            </Button> */}
                            <Icon name="edit outline" size='large' color='blue' style={{cursor:'pointer'}}
                                        onClick={(event)=>this.editClickHandler(event,index,category.name)}/>&nbsp;
                            <Icon name="trash alternate outline" size='large' color='grey' style={{cursor:'pointer'}}
                                        onClick={(event) => this.deleteClickHandler(event,index)}/>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='edit' content={<FormattedMessage id="categories.ingredients.modal.rename.title"
                                                                                defaultMessage='Rename category'/>} />
                                <Modal.Content>
                                    <Input
                                        type="text"
                                        placeholder="Renaming"
                                        id="renameCategory"
                                        value={this.state.newCategory}
                                        onChange={this.onEditCategory}
                                        style={{minWidth:'15em',maxWidth:'30em'}}/>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="categories.ingredients.modal.rename.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="categories.ingredients.modal.rename.button.yes"
                                                                    defaultMessage='Apply'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editCategory(event,category)}/>
                                    </Modal.Actions>
                            </Modal> 
                             <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="categories.ingredients.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="categories.ingredients.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:category.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="categories.ingredients.modal.delete.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="categories.ingredients.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteCategory(event,category.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }


    render() { 
        const { categoriesIngredient,alert,dispatch,loading_ci } = this.props;

        return ( 
            <div>
                <SideLeftUncoverIngredients>
                <Grid stackable style={styles.FontLato13}>
                    <Grid.Row columns={1}>
                        <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="categories.ingredients.link.dashboard"
                                                                            defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="categories.ingredients.link.ingredients"
                                                                            defaultMessage='Ingredients'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="categories.ingredients.link.categories"
                                                                            defaultMessage='Categories'/></Breadcrumb.Section>
                        </Breadcrumb>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h2'>
                                        <img src={img_categories} style={{width:'40px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="categories.ingredients.page.title"
                                                              defaultMessage='Categories'/>
                                            <Header.Subheader><FormattedMessage id="categories.ingredients.page.subtitle"
                                                              defaultMessage='Add your ingredients categories'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
{/*                     <Grid.Row columns={2}>
                        <Grid.Column width={14}>
                                <Message icon >
                                    <Image src={img_categoriesRecipes} rounded/>
                                    <Message.Content>
                                        <Message.Header>
                                                <FormattedMessage id="categories.ingredients.header.caption.title"
                                                                     defaultMessage='Ingredient Categories: '/>
                                        </Message.Header>
                                        <div  style={styles.FontLato13}>
                                                <FormattedMessage id="categories.ingredients.header.caption.subtitle"
                                                                 defaultMessage=' Customize your ingredient categories.' />
                                        </div>
                                    </Message.Content>
                                </Message>
                                <br/><br/>
                        </Grid.Column>
                    </Grid.Row> */}
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={2}>
                        <Grid.Column width={5}> 
                            <p style={styles.FontLato13}>
                                <FormattedMessage id="categories.ingredients.newrecipe.title"
                                                  defaultMessage='Create a new ingredient category' /> 
                            </p>
                            <Card color={'blue'} style={styles.FontLato13} fluid>
                                <Card.Content >
                                    <Header as='h5'>
                                        <FormattedMessage id="categories.ingredients.card.newrecipe.title"
                                                          defaultMessage='Name' /> 
                                    </Header>
                                    <FormattedMessage id="categories.ingredients.card.newrecipe.placeholder"
                                                        defaultMessage='category name'>
                                        {placeholder =>  
                                                <Input fluid size='large'
                                                    type="text"
                                                    placeholder={placeholder}
                                                    id="newCategory"
                                                    value={this.state.newCategory}
                                                    onChange={this.onNewCategoryChange}
                                                    //style={{minWidth:'15em',maxWidth:'30em'}}
                                                    />}
                                    </FormattedMessage>
                                    <br/><br/>
                                    <Button color='blue' floated='right'
                                        onClick={(event) =>this.addCategory(event)}>
                                        <FormattedMessage id="categories.ingredients.card.button.create"
                                                          defaultMessage='Create' /> 
                                    </Button>
                                </Card.Content> 
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={7}> 
                            <p style={styles.FontLato14Border}>
                                {categoriesIngredient && categoriesIngredient.items && 
                                  <span style={{'font-weight': 'bold;'}}>{categoriesIngredient.items.length}{' '}</span>
                               }
                                <FormattedMessage id="categories.ingredients.currentrecipe.title"
                                                  defaultMessage='categories available' /> 
                            </p>
                            <Dimmer.Dimmable>
                                        <Dimmer active={((loading_ci))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                            <Table selectable responsive color={'blue'} fluid unstackable singleLine>
                                <Table.Header style={styles.FontLato13}>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                             <FormattedMessage id="categories.ingredients.currentrecipe.table.row.name"
                                                              defaultMessage='Name' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>
                                            <FormattedMessage id="categories.ingredients.currentrecipe.table.row.nbrecipes"
                                                              defaultMessage='Nb Ingredients' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>
                                         <FormattedMessage id="categories.ingredients.currentrecipe.table.row.active"
                                                              defaultMessage='Active in export files' />{' '} 
                                            <Popup trigger={<Icon name='info circle' color='teal' link size='large' />}
                                                                on='hover'
                                                                position = 'top right'
                                                                flowing>
                                                             <Icon name='info circle' color='teal' size='large'/>
                                                            <FormattedMessage id="categories.ingredients.currentrecipe.table.row.active.help"
                                                                               defaultMessage="You can Enable/Disable categories for your export files: listings, inventories" />
                                            </Popup>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {categoriesIngredient && categoriesIngredient.items &&
                                      this.addCategoriesRows(categoriesIngredient)}
                                </Table.Body>
                            </Table>
                          </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </SideLeftUncoverIngredients>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { categoriesIngredient,alert,dispatch } = state;
    const { loading_ci } = state.categoriesIngredient;
    return {
        categoriesIngredient,
        loading_ci,
        alert,
        dispatch
    };
}

const connectedCategoriesIngredient = injectIntl(connect(mapStateToProps)(CategoriesIngredient));
export { connectedCategoriesIngredient as CategoriesIngredient };
 