import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {Layout} from '../Components/Layout';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('userIn')
            ? <Layout route={props}><Component {...props} /></Layout>
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        
    )} />
)

export const PrivateRouteOutside = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('userIn')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        
    )} />
)