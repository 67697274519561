// CheckoutForm.js
import React from 'react';
import {injectStripe,CardElement} from 'react-stripe-elements';
import {Button,Form,Segment,Icon} from 'semantic-ui-react';
import { toaster_dispatch } from '../../_helpers';
import {paymentActions} from '../../_actions'
import { connect } from 'react-redux';
import {injectIntl,FormattedMessage} from 'react-intl';
import {styles} from '../Styles.jsx';

/* const inlineStyle = {
    button : {
            'white-space': 'nowrap',
            'border': 0,
            'outline': 0,
            'display': 'inline-block',
            'height': '40px',
            'line-height': '40px',
            'padding': '0 14px',
            'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
            'color': '#fff',
            'border-radius': '4px',
            'font-size': '15px',
            'font-weight': 600,
            //'text-transform': 'uppercase',
            'letter-spacing': '0.025em',
            'background-color': '#66b2b2',
            'text-decoration': 'none',
            '-webkit-transition': 'all 150ms ease',
            'transition': 'all 150ms ease',
            'margin-top': '10px'
    },
    label : {
        'color': '#6b7c93',
        'font-weight': '300',
        'font-size': '14px',
        'letter-spacing': '0.025em',
    },
    Form :{
        'margin-bottom':'40px',
        'padding-bottom': '40px',
        //'border-bottom': '3px solid #e6ebf1',
        'paddingLeft':'1em'
    },
  }; */


const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          ...(padding ? {padding} : {}),
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };


class UpdateCardForm extends React.Component {

  constructor(props) {
    super(props);
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state =  {
      userIn: userIn,
      resp_message: "",
      card_errors: ""
    };
  }

  handleCardErrors = card_dets => {
    console.log("Card Section dets", card_dets);
    if (card_dets.error) {
      this.setState({ card_errors: card_dets.error.message });
    } else {
      this.setState({ card_errors: "" });
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    const { dispatch} = this.props;
    const {userIn} = this.state;
    this.setState({ card_errors: "", resp_message: "" });
    let fullName = "";
    if (userIn && userIn.user){
      fullName = userIn.user.username;
    }
    return this.props.stripe
      .createToken({ type: "card", name: fullName })
      .then( result => {
        if (result.error) {
          console.log("THERE IS AN ERROR IN YOUR FORM", result.error);
          return this.setState({ card_errors: result.error.message });
        } else {
        
        /* console.log(
            "Received Stripe token ---> SENDING TO SERVER: ",
            result.token
        ); */

        let pay_infos =  {"stripe_token":result.token.id}
        dispatch(paymentActions.updateCard(pay_infos));
        }
    });

  };

  render() {
    const { alert,dispatch,intl,disable_ui} = this.props;
    return (
      <div>
        <Form style={styles.Form} onSubmit={this.handleSubmit}>
          <Form.Field disabled={disable_ui}>
                <label style={styles.LabelGrey}> 
                      <FormattedMessage id="updatecardform.form.card.details"
                                        defaultMessage="Update your card details"/>
                </label>
                <label style={styles.LabelRed}> 
                      <Icon name='warning circle'/>
                      <FormattedMessage id="updatecardform.form.card.details.subtitle"
                                        defaultMessage="only to update your credit card"/>
                </label>
                <Segment raised>
                    <CardElement 
                        {...createOptions('18px')}
                        onChange={this.handleCardErrors}/>
                    <div role="alert">
                        <h3>{this.state.card_errors}</h3>
                    </div>
                </Segment>
          </Form.Field>
          {/* <br/> */}
          <Button style={styles.ButtonGreen} disabled={disable_ui}>
                      <FormattedMessage id="updatecardform.form.button.update"
                                        defaultMessage="Update"/>
          </Button>
          {this.state.resp_message && <h1>{this.state.resp_message}</h1>}
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </Form>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { payment,alert,dispatch } = state;
  return {
      payment,
      alert,
      dispatch,
  };
}

const connectedUpdateCardForm = injectStripe(injectIntl(connect(mapStateToProps)(UpdateCardForm)));
export { connectedUpdateCardForm as UpdateCardForm};