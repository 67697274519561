import { userConstants } from '../_constants';

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
      case userConstants.REGISTER_CONFIRM_REQUEST:
      return { verifying: true };
    case userConstants.REGISTER_CONFIRM_SUCCESS:
      return  { verified: true };
    case userConstants.REGISTER_CONFIRM_FAILURE:
      return  { verified: false };
    default:
      return state
  }
}