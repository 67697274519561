import React, { Component } from 'react';
import { Grid,Image,Table,Card,Icon,Button,Header,Modal,Form,Input,Popup,Dimmer,Loader,Label,Breadcrumb,Message,Checkbox, CardContent} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import './Recipes.css';
//import styles from './Recipes.css';
import SideLeftUncoverIngredient from './SidebarIngredient';
import {ingredientsActions,supplierActions,alertActions,storageAreaActions} from '../_actions'
import { history,Capitalize,allergens,isNumeric,toaster_dispatch,optimizeDecimalPart,isFloat,unitWeight,unitVolume,trim,get_payload,typeYield,GUEST,isInteger,dateWithoutTimezone,
         grams,mgrams,mliters,cliters} from '../_helpers';
 import {  WEIGHT_INSTRUCTION_LINE_TITLE,
  WEIGHT_INSTRUCTION_LINE_KG,
  WEIGHT_INSTRUCTION_LINE_G,
  WEIGHT_INSTRUCTION_LINE_MG,
  WEIGHT_INSTRUCTION_LINE_OZ,
  WEIGHT_INSTRUCTION_LINE_LB,
  WEIGHT_INSTRUCTION_LINE_EX,
  VOLUME_INSTRUCTION_LINE_TITLE,
  VOLUME_INSTRUCTION_LINE_L,
  VOLUME_INSTRUCTION_LINE_ML,
  VOLUME_INSTRUCTION_LINE_CL,
  VOLUME_INSTRUCTION_LINE_FL,
  VOLUME_INSTRUCTION_LINE_GAL,
  VOLUME_INSTRUCTION_LINE_EX,
  QUANTITY_INSTRUCTION_LINE_TITLE,
  QUANTITY_INSTRUCTION_LINE_NUMBER,
  QUANTITY_INSTRUCTION_LINE_EX,
} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,injectIntl} from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash'
import {styles} from './Styles';
import {Helmet} from "react-helmet";

import img_box from '../Assets/open-box.png'
import img_link from '../Assets/link.png'
import img_stock from '../Assets/boxes.png'
//import img_chart from '../Assets/bar-chart.png'

import img_hearbeat from '../Assets/heartbeat2.png'
//import img_clipboard from '../Assets/clipboard2.png'
import img_suppliers from '../Assets/cargo-truck.png'

import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';
import './hover.css';
import './Search.css';
import Dropzone from 'react-dropzone';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import DEFAULT_INGREDIENT_IMAGE from '../Assets/ingredient_blank.png'


let title = <FormattedMessage id="ingredientdetails.file.error.title"
                              defaultMessage="Upload File Error"/>
let content_err_file_size = <FormattedMessage id="ingredientdetails.file.size.error.content"
                                              defaultMessage="File size or format not supported"/> 
let content_permission = <FormattedMessage id="ingredientdetails.file.permission.error.content"
                                              defaultMessage="You do not have Write privilege"/>  

const variationTypeOptions = [
  { key: 'loss', value: 'loss', text: <FormattedMessage id="ingredientdetails.ingredient.variation.option.loss"
                                                        defaultMessage='Loss'/> },
  { key: 'gain', value: 'gain', text: <FormattedMessage id="ingredientdetails.ingredient.variation.option.more"
                                                        defaultMessage='Gain'/> }
  ];

const  WEIGHT = <FormattedMessage id="ingredientdetails.quantity.select.weight"
                                  defaultMessage="Weight"/>;
const  VOLUME = <FormattedMessage id="ingredientdetails.quantity.select.volume"
                                   defaultMessage="Volume"/>;
const  QUANTITY = <FormattedMessage id="ingredientdetails.quantity.select.quantity"
                                    defaultMessage="Quantity"/>;


class IngredientDetails extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { prevIngredients : props.ingredients,
                       //selected: null,
                       currency : userIn.user.currency,
                       loaded: false,
                       submitted: false,
                       activeModalVariation: false,
                       activeModalExtraSupplierAdd: false,
                       activeStockAreaChange: false,
                       typeVariation: null,
                       valueVariation: null,
                       valueGrossWeight: null,
                       valueNetWeight: null,
                       activeVariation: false,
                       highlighted: false,
                       price: "",
                       purchase_weight: "",
                       purchase_volume: "",
                       purchase_quantity: "",
                       supplierSelected : null,
                       type_quantity: "",
                       type_quantity_updated: false,
                       quantity: "",
                       pack_units: 1,
                       ref_supplier:"",
                       batch_number: "",
                       isDefaultSupplier: false,
                       active_role: userIn.user.role,
                       editCellStock: false,
                       selectedDate : ""
                      };
        this.hideModal = this.hideModal.bind(this);
        this.triggerInputFile = this.triggerInputFile.bind(this);
        props.dispatch(ingredientsActions.getImage(props.match.params.ingredientId));
        props.dispatch(ingredientsActions.get(props.match.params.ingredientId));
        props.dispatch(ingredientsActions.recipes(props.match.params.ingredientId));
        props.dispatch(supplierActions.getAll());
        props.dispatch(storageAreaActions.getAll());

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    static getDerivedStateFromProps(props, state) {
      if(props.ingredients!==state.prevIngredients){
        if (props.ingredients.selected){
          const image = props.ingredients.image;
          let result =  {
            prevIngredients : props.ingredients,
            //selected: selected,
          }

          if (image && image!=state.prevImage){
            Object.assign(result,{ prevImage : image,
                                   file:image}); 
          }

          const selected = props.ingredients.selected;
          if (selected.allergens){
            const allergens = []; 
            for (let elem of selected.allergens){
                 allergens.push(elem.name)
            }       
            Object.assign(result,{ allergens_list:allergens}); 
          }

          Object.assign(result,{ type_quantity:typeYield(selected.unit)}); 
          Object.assign(result,{ quantity_unit: selected.unit });
          Object.assign(result,{ selected: selected });
          Object.assign(result,{ storage_area: selected.stock_storage});

          return result;
        }
      }
      return null;
    }

    componentDidMount() {
      const {intl} = this.props;
      let default_metric = (intl.locale == 'en')?'us':'france' 
      this.setState({ type_metrics: default_metric });
    }

    createAllergensNames(){
      const { intl} = this.props;
      const { allergens_list} = this.state;
      //let allergens_str = " ";

      let new_allergens_list = allergens_list.map((a,index) => {
          if (a == "gluten") return intl.formatMessage(allergens.gluten);
          if (a == "crustaceans") return intl.formatMessage(allergens.crustaceans);
          if (a == "egg") return intl.formatMessage(allergens.egg);
          if (a == "fish") return intl.formatMessage(allergens.fish);
          if (a == "peanuts") return intl.formatMessage(allergens.peanuts);
          if (a == "soya") return intl.formatMessage(allergens.soya);
          if (a == "milk") return intl.formatMessage(allergens.milk);
          if (a == "nuts") return intl.formatMessage(allergens.nuts);
          if (a == "egg") return intl.formatMessage(allergens.egg);
          if (a == "celery") return intl.formatMessage(allergens.celery);
          if (a == "mustard") return intl.formatMessage(allergens.mustard);
          if (a == "sesame") return intl.formatMessage(allergens.sesame);
          if (a == "sulphites") return intl.formatMessage(allergens.sulphites);
          if (a == "lupin") return intl.formatMessage(allergens.lupin);
          if (a == "molluscs") return intl.formatMessage(allergens.molluscs);
      });

/*        for (let elem of new_allergens_list){
            allergens_str+= elem + " - "
        } 
       
        allergens_str = allergens_str.trim();  
        if(allergens_str.charAt( allergens_str.length-1 ) == "-") {
            allergens_str = allergens_str.slice(0, -1)
        } 
      
      return allergens_str;  */

      return new_allergens_list.map((allergen,index) => {
        return (
           <Label color='blue'>
                {Capitalize(allergen)}
           </Label>
        );
      });

    }

    seeRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/recipedetails'));
        history.push('/recipedetails/'+index);
    }

    editCurrentIngredient(event){
      //event.preventDefault();
      const {ingredients} = this.props;
      history.push('/editingredient/'+ingredients.selected.id);
  }

    goToNutrition() {
      let ingredientId = this.props.match.params.ingredientId;
      history.push('/ingredientnutritionfacts/'+ingredientId);
    }
    
    getUnique(arr, comp) {
      const unique = arr
        .map(e => e[comp])
         // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
    
       return unique;
    }

    addRecipesRows(recipes){
        let filtered_recipes = this.getUnique(recipes,'id')                
        return filtered_recipes.map((recipe,index) => {
                return (
                   <li>
                       <a href="#" onClick={(event) => this.seeRecipe(event,recipe.id)}>
                        {recipe.name}</a>
                       {/* {'  '}({recipe.yield_count} {recipe.yield_units}) */}
                   </li>
                );
        });
    }

    extractPayload(field, unit, average){
      let value = field.split(unit)[0] 
      if (value>0){
        return Number((value / average) * 100).toFixed(0)
      }
      return 0;
    }

    editStockQuantityHandlerInput (event,quantity) {
      event.preventDefault();
      this.setState({ editCellStock: true});
      this.setState({ newStockQuantity: Math.round(quantity * 100) / 100});
    };

    onNewStockQuantity = (e) => {
      //e.preventDefault();
      this.setState({ newStockQuantity: e.target.value});
    }


    onIncreaseDecreaseQuantity (event,ingredient,increase) {
      event.preventDefault();
      const {newStockQuantity,selected,active_role} = this.state;
      const {dispatch,extra_suppliers} = this.props;
      let quantity = Number(trim(newStockQuantity.toString()).replace(/,/g, '.')).toFixed(3);

      if (increase){
          quantity++;
      }else{
          if (quantity > 0) quantity--;
      } 

      const title = <FormattedMessage id="ingredientdetails.stock_quantity.check.error.title"
                                      defaultMessage='Stock Update Error'/>

      if (this.assertQuantityField(quantity)/*  && quantity!=ingredient.stock_quantity */){

          if (active_role!= GUEST){
            selected.stock_quantity = quantity

            let price_per_unit = ingredient.price_per_unit
            if (ingredient.default_supplier == false){
                    for (let s of extra_suppliers){
                        if (s.default_supplier == true){
                            price_per_unit = s.price_per_unit
                            break
                        }
                    }
            }
            let price = Number(quantity) * Number(price_per_unit)
            selected.stock_price =  Math.round(price * 100) / 100;
            this.setState({selected: selected});
            this.setState({ newStockQuantity: quantity});
            //dispatch(ingredientsActions.update_stock(ingredient.id,quantity));
            this.setState({ editCellStock: false });
          }else{
              this.setState({ editCellStock: false });
              const content =  <FormattedMessage id="ingredientdetails.stock_quantity.privilege.error.message"
                                                  defaultMessage='You do not have Right Privilege'/>
              dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.privilege.error.message',
                                           content:'ingredientdetails.stock_quantity.check.error.title'}));  
          }
      }else{
          if (Math.trunc(quantity).toString().length>=9){
              const content =  <FormattedMessage id="ingredientdetails.stock_quantity.check.error.message"
                                                defaultMessage='Quantity field to big (must be less than 10 digits)'/>
               dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.check.error.title',
                                           content:'ingredientdetails.stock_quantity.check.error.message'}));   


           }
          this.setState({ editCellStock: false });
      }
  }

    updateStockQuantityOnBlur (event,ingredient) {
      event.preventDefault();
      const {newStockQuantity,selected,active_role} = this.state;
      const {dispatch,extra_suppliers} = this.props;
      let quantity = Number(trim(newStockQuantity.toString()).replace(/,/g, '.')).toFixed(3);

      const title = <FormattedMessage id="ingredientdetails.stock_quantity.check.error.title"
                                      defaultMessage='Stock Update Error'/>

      if (this.assertQuantityField(quantity) /* && quantity!=ingredient.stock_quantity */){

          if (active_role!= GUEST){
            selected.stock_quantity = quantity
            let price_per_unit = ingredient.price_per_unit
            if (ingredient.default_supplier == false){
                    for (let s of extra_suppliers){
                        if (s.default_supplier == true){
                            price_per_unit = s.price_per_unit
                            break
                        }
                    }
            }
            let price = Number(quantity) * Number(price_per_unit)
            selected.stock_price =  Math.round(price * 100) / 100;
            this.setState({selected: selected});
            dispatch(ingredientsActions.update_stock(ingredient.id,quantity));
            this.setState({ editCellStock: false });
          }else{
              this.setState({ editCellStock: false });
              const content =  <FormattedMessage id="ingredientdetails.stock_quantity.privilege.error.message"
                                                  defaultMessage='You do not have Right Privilege'/>
              dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.privilege.error.message',
                                           content:'ingredientdetails.stock_quantity.check.error.title'}));  
          }
      }else{
          if (Math.trunc(quantity).toString().length>=9){
              const content =  <FormattedMessage id="ingredientdetails.stock_quantity.check.error.message"
                                                defaultMessage='Quantity field to big (must be less than 10 digits)'/>
               dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.check.error.title',
                                           content:'ingredientdetails.stock_quantity.check.error.message'}));   


           }
          this.setState({ editCellStock: false });
      }
  }

  updateStockQuantityOnEnter (event,ingredient) {
      const {newStockQuantity,selected,active_role} = this.state;
      const {dispatch,extra_suppliers} = this.props;
      const title = <FormattedMessage id="ingredientdetails.stock_quantity.check.error.title"
                                      defaultMessage='Stock Update Error'/>

      if (event.key === 'Enter') {
              let quantity = Number(trim(newStockQuantity.toString()).replace(/,/g, '.')).toFixed(3);
              if (this.assertQuantityField(quantity) /* && quantity!=ingredient.stock_quantity */){

                  if (active_role!= GUEST){
                    selected.stock_quantity = quantity
                    let price_per_unit = ingredient.price_per_unit
                    if (ingredient.default_supplier == false){
                            for (let s of extra_suppliers){
                                if (s.default_supplier == true){
                                    price_per_unit = s.price_per_unit
                                    break
                                }
                            }
                    }
                    let price = Number(quantity) * Number(price_per_unit)
                    selected.stock_price =  Math.round(price * 100) / 100;
                    this.setState({selected: selected});
                    dispatch(ingredientsActions.update_stock(ingredient.id,quantity));
                    this.setState({ editCellStock: false });
                  }else{
                      this.setState({ editCellStock: false });
                      const content =  <FormattedMessage id="ingredientdetails.stock_quantity.privilege.error.message"
                                                          defaultMessage='You do not have Right Privilege'/>
                      dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.privilege.error.message',
                                                  content:'ingredientdetails.stock_quantity.check.error.title'}));  
                  }

              }else{
                  if (Math.trunc(quantity).toString().length>=9){
                     const content =  <FormattedMessage id="ingredientdetails.stock_quantity.check.error.message"
                                                       defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                      dispatch(alertActions.error({title:'ingredientdetails.stock_quantity.check.error.title',
                                                  content:'ingredientdetails.stock_quantity.check.error.message'}));   
                  }
                  this.setState({ editCellStock: false });
              }
      }
    }

    setExpirationDate (date){
      this.setState({ selectedDate: date})
    }

    addFoodFactsFR(data){
      var energyKJ = data.calories.split("kcal")
      energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)
  
      return(
        <Table compact striped unstackable singleline color='grey' style={styles.FontLato14}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Pour 100g/100ml</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>%VNR *</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}><b>Energie</b></Table.Cell>
            <Table.Cell width={2} textAlign='right'>{data.calories} ({energyKJ}kJ)</Table.Cell>
            <Table.Cell width={2} textAlign='right'>{this.extractPayload(data.calories,"kcal",2000)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Matières Grasses</b></Table.Cell>
            <Table.Cell textAlign='right'>{data.fatTotal}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.fatTotal,"g",70)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={styles.FontLato12}>dont acides gras saturés</Table.Cell>
            <Table.Cell textAlign='right'>{data.saturedFat}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.saturedFat,"g",20)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Glucides</b></Table.Cell>
            <Table.Cell textAlign='right'>{data.totalCarbohydrates}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.totalCarbohydrates,"g",260)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={styles.FontLato12}>dont sucres</Table.Cell>
            <Table.Cell textAlign='right'>{data.sugar}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.sugar,"g",90)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Fibres</b></Table.Cell>
            <Table.Cell textAlign='right'>{data.fiber}</Table.Cell>
            <Table.Cell textAlign='right'>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Protéines</b></Table.Cell>
            <Table.Cell textAlign='right'>{data.protein}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.protein,"g",90)}%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Sel</b></Table.Cell>
            <Table.Cell textAlign='right'>{data.sodium}</Table.Cell>
            <Table.Cell textAlign='right'>{this.extractPayload(data.sodium,"g",6)}%</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Footer>
            <Table.Row fullwidth width={8}>
               <Table.Cell textAlign='left' colSpan='3'>
                <p>* Pourcentage de la valeur quotidienne selon un régime alimentaire de 2000 calories. 
                Vos valeurs quotidiennes personnelles peuvent être plus ou moins élevées selon vos besoins énérgetiques</p>
              </Table.Cell>
            </Table.Row>
        </Table.Footer>
      </Table>
      )
    }

    addFoodFactsCA(data){
        const {intl} = this.props;

        var energyKJ = data.calories.split("kcal")
        energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

        return (     
          <section class="performance-facts" style={{'font-size': '12px','color': 'black','marginLeft':'0.2em', 'backgroundColor':'white'}}
                   id={'label-node'} >
            <header /* class="performance-facts__header" */ >
              <h1 class="performance-facts__title">Nutrition Facts</h1>
              <h1 class="performance-facts__title">Valeurs nutritives</h1>
            </header>
              <table class="performance-facts__table">
                <thead>
                  <tr>
                    <th colspan="3" class="small-info">Per/Pour 100g/100ml</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <th colspan="2"  style={{'font-family': 'verdana', 'font-size': '15px'}}>
                    <b>Calories</b> {data.calories} ({energyKJ}kJ)
                  </th>
                  </tr>
                  <tr class="thick-row">
                    <td colspan="3" class="small-info">
                      <b>% Daily value*</b><br/>
                      <b>% Valeur quotidienne*</b>
                    </td>
                  </tr>
                  <tr>
                  <th colspan="2">
                    <b>Fat / Lipides</b>  {data.fatTotal}
                  </th>
                  <td >
                     {this.extractPayload(data.fatTotal,"g",70)}%
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    Saturated / Saturé {data.saturedFat}
                  </th>
                  <td class="blank-cell">
                      {this.extractPayload(data.saturedFat,"g",20)}%
                  </td>
                </tr>
                <tr > 
                  <th colspan="2">
                    <b>Carbohydrates / Glucides</b> {data.totalCarbohydrates}
                  </th>
                  <td> 
                      {this.extractPayload(data.totalCarbohydrates,"g",260)}%
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    Sugars / Sucres {data.sugar}
                  </th>
                  <td class="blank-cell">
                    {this.extractPayload(data.sugar,"g",90)}%
                  </td>
                </tr>
                <tr>
                <td class="blank-cell"></td>
                  <th>
                    Fibre / Fibres {data.fiber}
                  </th>
                  <td class="blank-cell">
                    {this.extractPayload(data.fiber,"g",25)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Protein / Protéines</b> {data.protein}
                  </th>
                  <td >
                    {this.extractPayload(data.protein,"g",90)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Cholesterol / Cholestérol</b> {data.cholesterol}
                  </th>
                  <td >
                    {this.extractPayload(data.cholesterol,"g",300)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Sodium / Sel</b> {data.sodium}
                  </th>
                  <td>
                    {this.extractPayload(data.sodium,"g",6)}%
                  </td>
                </tr>
                <tr class="thick-row">
                  <th colspan="2">
                      <span>Potassium {data.potassium}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.potassium,"mg",3500)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <span>Calcium {data.calcium}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.calcium,"mg",1000)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                      <span>Iron / Fer {data.iron}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.iron,"mg",18)}%
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="performance-facts__table--grid">
            <tr class="thick-row">
              <th colspan="1">
               <span class="small-info">* 5% or less is  <b>a little</b>, 15% or more is  <b>a lot</b></span><br/>
               <span class="small-info">* 5% ou moins c'est <b>peu</b>, 15% ou plus c'est  <b>beaucoup</b></span>
              </th>
            </tr>
            </table> 
            </section>
          )
    }


   addFoodFactsUS(data){
    const {intl} = this.props;

    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

    return (
        <section class="performance-facts" style={{'font-family': 'verdana', 'font-size': '11px','color': 'grey'}}>
          <header class="performance-facts__header">
            <h1 class="performance-facts__title">Nutrition Facts</h1>
{/*                 <p>Serving Size 1/2 cup (about 82g)</p>
            <p>Serving Per Container 8</p> */}
          </header>
          <table class="performance-facts__table">
            <thead>
              <tr>
                <th colspan="3" class="small-info">Amount Per 100g/100ml</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">
                  <b>Calories</b> {data.calories}
                </th>
                <td>
                  {/* Calories from Fat 0 */}
                </td>
              </tr>
              <tr class="thick-row">
                <td colspan="3" class="small-info">
                  <b>% Daily Value*</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Total Fat</b> {data.fatTotal}
                </th>
                <td>
                  <b>{this.extractPayload(data.fatTotal,"g",65)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell"></td>
                <th>
                  Saturated Fat {data.saturedFat}
                </th>
                <td>
                  <b>{this.extractPayload(data.saturedFat,"g",20)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Trans Fat {data.transFat}
                </th>
                <td>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Cholesterol</b> {data.cholesterol}
                </th>
                <td>
                  <b>{this.extractPayload(data.cholesterol,"g",300)}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Sodium</b> {data.sodium}
                </th>
                <td>
                  <b>{this.extractPayload(data.sodium,"g",2.4)}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Total Carbohydrate</b> {data.totalCarbohydrates}
                </th>
                <td>
                  <b>{this.extractPayload(data.totalCarbohydrates,"g",300)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Dietary Fiber {data.fiber}
                </th>
                <td>
                  <b>{this.extractPayload(data.fiber,"g",25)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Sugars {data.sugar}
                </th>
                <td>
                </td>
              </tr>
              <tr class="thick-end">
                <th colspan="2">
                  <b>Protein</b> {data.protein}
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="performance-facts__table--grid">
            <tbody>
              <tr>
                <td colspan="2">
                  Vitamin A {data.vitaminA}
                </td>
                <td>
                  Vitamin C {data.vitaminC}
                </td>
              </tr>
              <tr class="thin-end">
                <td colspan="2">
                  Calcium {data.calcium}
                </td>
                <td>
                  Iron {data.iron}
                </td>
              </tr>
            </tbody>
          </table>
         <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs:</p>
        <table class="performance-facts__table--small small-info">
          <thead>
            <tr>
              <td colspan="2"></td>
              <th>Calories:</th>
              <th>2,000</th>
              <th>2,500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="2">Total Fat</th>
              <td>Less than</td>
              <td>65g</td>
              <td>80g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th>Saturated Fat</th>
              <td>Less than</td>
              <td>20g</td>
              <td>25g</td>
            </tr>
            <tr>
              <th colspan="2">Cholesterol</th>
              <td>Less than</td>
              <td>300mg</td>
              <td>300 mg</td>
            </tr>
            <tr>
              <th colspan="2">Sodium</th>
              <td>Less than</td>
              <td>2,400mg</td>
              <td>2,400mg</td>
            </tr>
            <tr>
              <th colspan="3">Total Carbohydrate</th>
              <td>300g</td>
              <td>375g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th colspan="2">Dietary Fiber</th>
              <td>25g</td>
              <td>30g</td>
            </tr>
          </tbody>
        </table>
        <p class="small-info">
          Calories per gram:
        </p>
        <p class="small-info text-center">
          Fat 9
          &bull;
          Carbohydrate 4
          &bull;
          Protein 4
        </p>
      </section>
      )  
  }

   hideModal() {
       const {selected} = this.state;
      this.setState({ activeModalVariation: false })
      this.setState({ activeModalExtraSupplierAdd: null })
      this.setState({ activeModalExtraSupplierEdit: null })
      this.setState({ activeModalExtraSupplierDelete: null })
      this.setState({ activeModalDefaultSupplierEdit: null })
      this.setState({ activeStockAreaChange: false })
      this.setState({ typeVariation: null });
      this.setState({ valueVariation: null });
      this.setState({ valueGrossWeight: null });
      this.setState({ valueNetWeight: null });
      this.setState({ submitted: false });

      this.setState({ price:"" });
      this.setState({ supplierSelected:null });
      this.setState({ ref_supplier:"" });
      this.setState({ isDefaultSupplier:false });

      this.setState({ purchase_volume: "" });
      this.setState({ purchase_weight:  "" });
      this.setState({ purchase_quantity: "" });
      this.setState({ quantity:  ""});
      this.setState({ type_quantity:  typeYield(selected.unit)});
      this.setState({ pack_units:  1});
      this.setState({ batch_number:"" });
      this.setState({ selectedDate: "" });
      this.setState({ highlighted: false });
  }


  addVariationHandler (/*event*/) {
      //event.preventDefault();
      this.setState({ activeModalVariation: true});
  };


  changeStockAreaHandler (/*event*/) {
      //event.preventDefault();
      this.setState({ activeStockAreaChange: true});
  };

  onTypeVariationChange = (e,data) => {
      e.preventDefault();
      this.setState({ typeVariation: data.value });
      this.setState({ submitted: false });
  }

  onValueVariationChange = (e) => {
      e.preventDefault();
      this.setState({ valueVariation: e.target.value });
      this.setState({ submitted: false });
  }

  onValueGrossChange= (e) => {
    e.preventDefault();
    let currentGrossValue = e.target.value;
    currentGrossValue = currentGrossValue.toString().replace(/,/g, '.') 
    this.setState({ onValueGrossChange: currentGrossValue });
    this.setState({ submitted: false });

    const {onValueNetChange} = this.state;
    let currentVariation = Number(0)

    if  (onValueNetChange && currentGrossValue){
      currentVariation = Number(currentGrossValue) - Number(onValueNetChange)
      let variation =  Number(currentVariation/Number(currentGrossValue)) * 100;
      if (!isNaN(variation) && _.isFinite(variation)){
        if (Math.sign(variation) >= 0){
          this.setState({ typeVariation: 'loss' });
        }else{
          this.setState({ typeVariation: 'gain' });
        }
        this.setState({ valueVariation: Math.abs(optimizeDecimalPart(variation))});
      }
    }
  }

  onValueNetChange= (e) => {
    e.preventDefault();
    let currentNetValue = e.target.value;
    currentNetValue = currentNetValue.toString().replace(/,/g, '.')
    this.setState({ onValueNetChange:currentNetValue });
    this.setState({ submitted: false });

    const {onValueGrossChange} = this.state;
    let currentVariation = Number(0)

    if  (onValueGrossChange && currentNetValue){
      currentVariation = Number(onValueGrossChange) - Number(currentNetValue)
      let variation = Number(currentVariation/Number(onValueGrossChange)) * 100
      if (!isNaN(variation) && _.isFinite(variation)){
        if (Math.sign(variation) >= 0){
          this.setState({ typeVariation: 'loss' });
        }else{
          this.setState({ typeVariation: 'gain' });
        }
        this.setState({ valueVariation: Math.abs(optimizeDecimalPart(variation))});
      }
    }
  }


  removeVariation (event) {
      event.preventDefault();
      const {dispatch,ingredients} = this.props;

      this.setState({ activeVariation: false });

      if (ingredients && ingredients.selected){
          const params = {
              value: Number(0),
              type: "remove",
              active: false
          }
          dispatch(alertActions.clear());
          dispatch(ingredientsActions.ingredient_weight_variation(ingredients.selected.id,params));
          this.setState({ activeModalVariation: false })
      }
  };

  onApplyVariation = (e) => {
      const {valueVariation,typeVariation} = this.state;
      const {dispatch,ingredients} = this.props;
      this.setState({ submitted: true });
      this.setState({ activeVariation: true });

      if (!this.assertTypeField()){
          return;
      }

      if (!this.assertPercentField()){
          return;
      }

      if (ingredients && ingredients.selected && valueVariation && typeVariation){
          const params = {
              value: Number(valueVariation.toString().replace(/,/g, '.')/100).toFixed(4),
              type: typeVariation,
              active: true
          }
          dispatch(alertActions.clear());
          dispatch(ingredientsActions.ingredient_weight_variation(ingredients.selected.id,params));
          this.setState({ activeModalVariation: false })
      }
  }


  addExraSupplierHandler () {
      this.setState({ activeModalExtraSupplierAdd: true});
  };

  onPriceChange = (e) => {
    e.preventDefault();
    this.setState({ price: e.target.value });
  }

  onSupplierChange = (e,data) => {
    e.preventDefault();
    const {supplier} = this.props;
     if (data.value===""){
        this.setState({ supplierSelected: null });
     }else{
        let supplier_id = data.value;
        let elem = supplier.options.find(supplier => supplier.value === supplier_id)
        this.setState({ supplierSelected: {id:supplier_id,
                                           name: elem.text } });
     }
  }

  onStorageAreaChange = (e,data) => {
    e.preventDefault();
    const {storageAreas} = this.props;
     if (data.value===""){
        this.setState({ storage_area: null });
     }else{
        let storage_area_id = data.value;
        let elem = storageAreas.options.find(s => s.value === storage_area_id)
        this.setState({ storage_area: {id:storage_area_id,
                                       name: elem.text } });
     }
  }


  resetPurchaseQuantities () {
    this.setState({ purchase_weight: "" });
    this.setState({ purchase_volume: ""});
    this.setState({ purchase_quantity: ""});
  }

  onTypeQuantityChange = (e,data) => {
    e.preventDefault();
    const {quantity,selected} = this.state;
    this.setState({ type_quantity: data.value });
    this.resetPurchaseQuantities();

    let type =  data.value
    if ( type === 'volume')
      this.setState({ purchase_volume: quantity });
    else if ( type === 'weight')
      this.setState({ purchase_weight:quantity});
    else if  ( type === 'quantity')
      this.setState({ purchase_quantity:quantity });

    this.setState({ type_quantity_updated: (type!==typeYield(selected.unit))?true:false});
  }


  onFullQuantityChange= (e) => {
    e.preventDefault();
   
    const {type_quantity} = this.state;
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    if (type_quantity === 'volume')
      this.setState({ purchase_volume: e.target.value.toLowerCase() });
    else if (type_quantity === 'weight')
      this.setState({ purchase_weight: e.target.value.toLowerCase() });
    else if  (type_quantity === 'quantity')
      this.setState({ purchase_quantity: e.target.value });
  }

  onQuantityChange = (e) => {
    e.preventDefault();
    const {type_quantity,quantity_unit} = this.state;
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    let quantity_value =  parseFloat(e.target.value.replace(/,/g, '.')).toFixed(2)
    let quantity_string 
    if (quantity_unit){
      quantity_string =  quantity_value + ' ' + quantity_unit
    }else{
      quantity_string =  quantity_value 
    }
    //let quantity_string =  quantity_value + ' ' + quantity_unit
    //console.log(quantity_string)
    
    if (type_quantity === 'volume'){
      this.setState({ purchase_volume: quantity_string.toLowerCase() });
    }  
    else if (type_quantity === 'weight'){
      this.setState({ purchase_weight:quantity_string.toLowerCase()});
    }
    else if  (type_quantity === 'quantity'){
      this.setState({ purchase_quantity: quantity_string });
    }
  }


  onUnitsPackagingChange = (e,data) => {
    e.preventDefault();
    this.setState({ pack_units: data.value });
  }

  onRefSupplierChange = (e) => {
    e.preventDefault();
    this.setState({ ref_supplier: e.target.value });
  }


  onBatchNumberChange = (e) => {
    e.preventDefault();
    this.setState({batch_number: e.target.value });
  }

  onCheckDefaultSupplier = (e,data) => {
    e.preventDefault();
    this.setState({ isDefaultSupplier: data.checked });
  }

  onUpdateStorageArea = (e) => {
    const {storage_area,} = this.state;
    const {dispatch,ingredients} = this.props;
    this.setState({ submitted: true });

/*     if (!this.assertStockAreaField()){
      return;
    } */

    if (ingredients && ingredients.selected){
          const params = {
            area_set: storage_area?true:false,
            area_id: storage_area?storage_area.id:-1,
        }
        dispatch(alertActions.clear());
        dispatch(ingredientsActions.ingredient_update_stock_area(ingredients.selected.id,params));
        this.setState({ activeStockAreaChange: false })   
    }
  }


  onAddExtraSupplier = (e) => {
    const {supplierSelected,ref_supplier,price,purchase_weight,purchase_volume,purchase_quantity,isDefaultSupplier,pack_units,selectedDate,batch_number} = this.state;
    const {dispatch,ingredients} = this.props;
    this.setState({ submitted: true });

    if (!this.assertSupplierField()){
      return;
    }

    if (!this.assertPriceField()){
      return;
    }

    if (!this.assertQuantityValueField()){
      return;
    }

    if (!this.assertPackField()){
      return;
    }

/*     if (!this.assertQuantityField()){
      return;
    }

    if (!this.assertQuantityFieldsValidity()){
      return;
    } */

    if (ingredients && ingredients.selected && supplierSelected && price){
        const params = {
            supplier_id: supplierSelected.id,
            price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
            purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
            purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
            purchase_quantity:trim(purchase_quantity).replace(/,/g, '.'),
            ref_supplier:ref_supplier,
            default_supplier:isDefaultSupplier,
            pack_units:pack_units,
            date_expire: (selectedDate && selectedDate != "" ) ?  dateWithoutTimezone(selectedDate):null,
            batch_number: batch_number
        }
        //dispatch(alertActions.clear());
        this.hideModal()
        dispatch(ingredientsActions.ingredient_add_extra_supplier(ingredients.selected.id,params));       
    }
  }

  deleteExtraSupplierHandler (event,index,supplier,legacy) {
    if (legacy){
      this.setState({ activeModalDefaultSupplierDelete: true});
      this.setState({ supplierSelected: null });
      this.setState({ ref_supplier:"" });
    }else{
      if (supplier.supplier){
        this.setState({ supplierSelected: {id:supplier.supplier.id,
                                          name:supplier.supplier.name} });
      }else{
        this.setState({ supplierSelected: null });
      }
      this.setState({ activeModalExtraSupplierDelete: index});
    }
  };


  editExtraSupplierHandler (event,index,supplier,legacy) {
    const {type_quantity} = this.state;

    if (legacy){
      this.setState({ activeModalDefaultSupplierEdit: true});
    }else{
      this.setState({ activeModalExtraSupplierEdit: index});
    }
  
    this.setState({ price: optimizeDecimalPart(Number(supplier.price)).toString() });

    if (supplier.supplier){
      this.setState({ supplierSelected: {id:supplier.supplier.id,
                                         name:supplier.supplier.name} });
    }else{
      this.setState({ supplierSelected: null });
    }

    this.setState({ ref_supplier:supplier.ref_supplier });
    this.setState({ isDefaultSupplier:supplier.default_supplier });
    this.setState({ pack_units: supplier.pack_units  });
    this.setState({ batch_number: supplier.batch_number  });
    if (supplier.date_expire/*  && supplier.date_expire!="" */){
      this.setState({ selectedDate: new Date(supplier.date_expire)  });
    }


    this.resetPurchaseQuantities();

    if (type_quantity === 'volume'){
      let aQuantity = get_payload(supplier.purchase_volume,true)
      if (aQuantity != "") aQuantity = optimizeDecimalPart(Number(aQuantity)).toString()
      let quantity_string =  aQuantity + ' ' + supplier.unit

      this.setState({ purchase_volume: quantity_string });
      if (supplier && supplier.extra_suppliers && supplier.extra_suppliers.length <=0){
        this.setState({ quantity:  quantity_string });
      }else{
        this.setState({ quantity: aQuantity });
      }
      //this.setState({ purchase_volume: supplier.purchase_volume });
      //this.setState({ quantity: supplier.purchase_volume });
    }
    else if (type_quantity === 'weight'){
      let aQuantity = get_payload(supplier.purchase_weight,true)
      if (aQuantity != "") aQuantity = optimizeDecimalPart(Number(aQuantity)).toString()
      let quantity_string =  aQuantity + ' ' + supplier.unit

      this.setState({ purchase_weight: quantity_string });
      if (supplier && supplier.extra_suppliers && supplier.extra_suppliers.length <=0){
        this.setState({ quantity:  quantity_string});
      }else{
        this.setState({ quantity: aQuantity });
      }
      //this.setState({ purchase_weight: supplier.purchase_weight });
      //this.setState({ quantity:supplier.purchase_weight });
    }
    else if  (type_quantity === 'quantity'){
      let aQuantity = optimizeDecimalPart(Number(supplier.purchase_quantity)).toString()
      this.setState({ purchase_quantity: aQuantity });
      this.setState({ quantity: aQuantity  });
    } 
  };

  editExtraSupplier(event,id) {
    const {supplierSelected,ref_supplier,price,purchase_weight,purchase_volume,purchase_quantity,isDefaultSupplier,pack_units,selectedDate,batch_number} = this.state;
    const {dispatch,ingredients} = this.props;
    this.setState({ submitted: true });

    if (!this.assertSupplierField()){
      return;
    }

    if (!this.assertPriceField()){
      return;
    }

    if (!this.assertQuantityValueField()){
      return;
    }

    if (!this.assertPackField()){
      return;
    }

/*     if (!this.assertQuantityField()){
      return;
    }

    if (!this.assertQuantityFieldsValidity()){
      return;
    } */

    if (ingredients && ingredients.selected && supplierSelected && price){
        const params = {
            id: id,
            supplier_id: supplierSelected.id,
            price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
            purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
            purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
            purchase_quantity:trim(purchase_quantity).replace(/,/g, '.'),
            ref_supplier:ref_supplier,
            default_supplier:isDefaultSupplier,
            pack_units:pack_units,
            date_expire: (selectedDate && selectedDate != "" )  ?  dateWithoutTimezone(selectedDate):null,
            batch_number: batch_number
        }
        //dispatch(alertActions.clear());
        this.hideModal()
        dispatch(ingredientsActions.ingredient_edit_extra_supplier(ingredients.selected.id,params));
    }
  }

  editDefaultSupplier(event) {
    const {supplierSelected,ref_supplier,price,purchase_weight,purchase_volume,purchase_quantity,isDefaultSupplier,selected,pack_units,batch_number,selectedDate} = this.state;
    const {dispatch,ingredients} = this.props;
    this.setState({ submitted: true });

/*     if (!this.assertSupplierField()){
      return;
    } */

    if (!this.assertPriceField()){
      return;
    }

    if (selected && selected.extra_suppliers && (selected.extra_suppliers).length>0){
      if (!this.assertQuantityValueField()){
        return;
      } 
    }

    if (!this.assertPackField()){
      return;
    }
/*     if (!this.assertQuantityField()){
      return;
    }*/

    if (selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0){
      if (!this.assertQuantityFieldsValidity()){
        return
      } 
    }

    if (ingredients && ingredients.selected /*&& supplierSelected*/ && price){
        const ingredient = {
          name:selected.name,
          price:Number(trim(price).replace(/,/g, '.')).toFixed(3),
          purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
          purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
          purchase_quantity:trim(purchase_quantity).replace(/,/g, '.'),
          //category:categorySelected,
          supplier:supplierSelected,
          //allergens:allergensSelected,
          //ref_internal:ref_internal,
          ref_supplier:ref_supplier,
          default_supplier:isDefaultSupplier,
          pack_units:pack_units,
          date_expire: (selectedDate && selectedDate != "" ) ?  dateWithoutTimezone(selectedDate):null,
          batch_number: batch_number
        }
        //dispatch(alertActions.clear());
        this.hideModal()
        dispatch(ingredientsActions.update(selected.id,ingredient))//,'/ingredientdetails/'+selected.id,1));        
      }
  }

  deleteExtraSupplier(event,supplier_item_id) {
      const {dispatch,ingredients} = this.props;
      event.preventDefault();
      const params =  {id: supplier_item_id}
      dispatch(ingredientsActions.ingredient_delete_extra_supplier(ingredients.selected.id,params));
  }

/*   setDefaultSupplier(event,id,legacy){
    const {dispatch,ingredients} = this.props;
    event.preventDefault();
    const params =  {legacy: legacy,
                     supplier_item_id: id}
    dispatch(ingredientsActions.ingredient_default_supplier(ingredients.selected.id,params));
  } */

  assertSupplierField(){
    if (this.state.supplierSelected
           &&(this.state.supplierSelected.name).length>0)
      return true;
    return false;
  }

  assertStockAreaField(){
    if (this.state.storage_area
           &&(this.state.storage_area.name).length>0)
      return true;
    return false;
  }

  assertPriceField(){
    let price = this.state.price.replace(/,/g, '.');
    price = price.replace(/,/g, '.');
    if ((isFloat(price) || isNumeric(price))
      && (price != "")
      && Math.trunc(price).toString().length<7)
      return true;
    return false;
  }

  assertPackField(){
    let units = Number(this.state.pack_units)
    if (isInteger(units)
        && (units > 0)
        && Math.trunc(units).toString().length<7)
      return true;
    return false;
  }


/*   assertQuantityField(){
    if (this.state.quantity != "")
      return true;
    return false;
  } */

  assertSelectedWeight(){
    if (this.state.type_quantity != "")
      return true;
    return false;
   }

  assertQuantityField(quantity){
    if ((isFloat(quantity) || isNumeric(quantity))
      && (quantity != "")
      && Math.trunc(quantity).toString().length<10)
      return true;
    return false;
  }

  assertQuantityValueField(){
    let value = this.state.quantity.toString().replace(/,/g, '.');
    if ((isFloat(value) || isNumeric(value))
      && (value != "")
      && (Number(value) > 0)
      && Math.trunc(value).toString().length<7)
      return true;
    return false;
  }

  assertQuantityPermittedIncluded(quantity){
    let isFound = false;
    let unitPart = "";
    const {type_quantity} = this.state;
    let inputs= quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);

    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (type_quantity == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
        if (inputs <=0){
            return false;
        }
              
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          if (isNumeric(i) && i<=0 ) {
            return false;
          }
          //console.log(unitPart);
        }
      }
    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
        if (isNumeric(i) && i<=0 ) {
          return false;
        }   
      }
    }

    if (type_quantity == 'volume'){
      for (let unit of unitVolume ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (type_quantity == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          break;
        }
     }
    }else {
        if (inputs.length > 1)
         return false
        isFound = true;
    }
    return isFound;
  }


  assertQuantityFieldsValidity(){
    const {quantity} = this.state;
    let clean_quantity = trim(quantity).toLowerCase();
    //this.setState({ quantity: clean_quantity });
    if (!this.assertQuantityPermittedIncluded(clean_quantity)) return false;
    return true;
  }



  onFocusElsewhere(e) {
    e.preventDefault();
    this.setState({ highlighted: false });
  }

  onFocusQuantityField = (e,quantity) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  assertPercentField(){
    const {valueVariation/*,typeVariation*/} = this.state;
/*     let maxValueVariation = 100
    if (typeVariation == 'gain'){
      maxValueVariation = 400
    } */
    if (!valueVariation) return false;
    let value = valueVariation.toString().replace(/,/g, '.')
    if (isNumeric(value)
            && (value > 0)
            && (value < 100))
            //&& (value < maxValueVariation))
        return true;
      return false;
  }

  assertTypeField(){
      if ((this.state.typeVariation)
            && ((this.state.typeVariation == 'gain')
            || (this.state.typeVariation == 'loss')))
        return true;
      return false;
  }

  assertGrossWeightField(){
    if (isNumeric(this.state.valueGrossWeight))
      return true;
    return false;
  }

  assertNetWeightField(){
    if (isNumeric(this.state.valueGrossWeight))
      return true;
    return false;
  }

  onTypeMetricsChange = (e,data) => {
    e.preventDefault();
    this.setState({ type_metrics: data.value });
  }

  onDropAccepted(acceptedFiles) {
      const { dispatch} = this.props;
      const { selected} = this.state;
      if (selected  && acceptedFiles[0]) {
       let current_file = acceptedFiles[0];
       let data = new FormData();  
        data.append('filetoupload', current_file,current_file.name);
        this.setState({ file: URL.createObjectURL(acceptedFiles[0])});
        dispatch(ingredientsActions.updateImage(selected.id,data));
      }
  }

  onDropRejected(rejectedFiles) {
      const {dispatch} = this.props;
      //this.setState({rejectedFile:rejectedFiles[0]});
      dispatch(alertActions.error({title: 'ingredientdetails.file.error.title',
                                  content:'ingredientdetails.file.size.error.content'}));
  }

  triggerInputFile(event){
    const { dispatch} = this.props;
    const { selected} = this.state;
    const {file,active_role} = this.state;
    if (file){
        event.stopPropagation()
        if (active_role != GUEST){
          this.setState({file:null});
          if (selected){
              dispatch(ingredientsActions.deleteImage(selected.id));
          }
        }
        else{
          dispatch(alertActions.error({title: 'ingredientdetails.file.error.title',
                                      content:'ingredientdetails.file.permission.error.content'}));
        }
    }
    /*         else{
        this.fileUpload.current.click();
    } */
     
  }
  
  addExtraSupplierCards(){
    const {updating,supplier,extra_suppliers,intl} = this.props;
    const {currency,submitted,supplierSelected,type_quantity,price,ref_supplier,isDefaultSupplier,quantity,quantity_unit,pack_units,batch_number,selectedDate} = this.state;

    let type_quantity_text;

   switch (type_quantity) {
      case 'weight':
        type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.weight'})
        break;
      case 'volume':
        type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.volume'})
        break;
      case 'quantity':
        type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.quantity'})
        break;
      default:
        type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.weight'})
    }

    return extra_suppliers.map((sup,index) => {
      return (
                  <Grid.Row columns={2} style={{padding:'0.2em 0 0 0'}} /* style={{background:'#fafafa'}} */>
                    <Grid.Column width={12}>            
                    <Image  style={{width:'30px',marginLeft:'3em'}} src={img_suppliers}/>
                    {/* <div> */}
                      <p style={{marginLeft:'6.3em'}}><h style={styles.FontLato14Teal}>
                            {/* <FormattedMessage id="ingredientdetails.card.details.content.line9"
                                            defaultMessage='Supplier :  '/> </h>&nbsp;&nbsp;&nbsp;*/}{sup.supplier && sup.supplier.name}</h>
                            &nbsp;&nbsp;
                            {sup.ref_supplier.length > 0 && <span>({sup.ref_supplier})</span>}
                      </p> 
{/*                       <p  style={{marginLeft:'7.3em'}} ><h style={styles.FontLato13BoldBlack}>
                          <FormattedMessage id="ingredientdetails.card.details.content.line11"
                                            defaultMessage='Supplier code :  '/></h>&nbsp;&nbsp;&nbsp;{supplier.ref_supplier}
                      </p> */}
                      <p  style={{marginLeft:'6.3em'}} ><h style={styles.FontLato13BoldBlack}>
                          <FormattedMessage id="ingredientdetails.card.details.content.line1"
                                            defaultMessage='Price :  '/></h>&nbsp;&nbsp;&nbsp;
                          <FormattedNumber value={sup.price} style="currency" currencyDisplay="symbol" currency={currency}/>
                          {/* {intl.formatMessage({id:'currency.symbol'})} */}
                      </p>
                      { sup.purchase_weight != '' &&
                      <p  style={{marginLeft:'6.3em'}} >
                           { grams.includes(sup.unit) && 
                            <span>
                             <h style={styles.FontLato13BoldBlack}>
                            <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                              defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_weight}
                            {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / kg'}
                            {')'}
                            </span>}
                            { mgrams.includes(sup.unit) && 
                              <span>
                             <h style={styles.FontLato13BoldBlack}>
                            <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                              defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_weight}
                            {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit) * 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / kg'}
                            {')'}
                            </span>}
                            {  !grams.includes(sup.unit) && !mgrams.includes(sup.unit) &&
                              <span>
                             <h style={styles.FontLato13BoldBlack}>
                            <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                              defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_weight}
                            {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{sup.unit}
                            {')'}
                            </span>}
                      </p>}
                      {sup.purchase_volume != '' &&
                      <p  style={{marginLeft:'6.3em'}} >
                         { cliters.includes(sup.unit) && 
                          <span>
                            <h style={styles.FontLato13BoldBlack}>
                            <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                            defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_volume}
                            {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit)*100} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / L'}
                              {')'}
                         </span>}
                         { mliters.includes(sup.unit) && 
                          <span>
                            <h style={styles.FontLato13BoldBlack}>
                           <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                          defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_volume}
                          {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit)*1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / L'}
                            {')'}
                         </span>}
                         { !cliters.includes(sup.unit) && !mliters.includes(sup.unit) &&
                          <span>
                            <h style={styles.FontLato13BoldBlack}>
                           <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                          defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{sup.pack_units} x {sup.purchase_volume}
                          {' '}{' ('}<FormattedNumber value={Number(sup.price_per_unit)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{sup.unit}
                            {')'}
                         </span>}
                      </p>}
                      {sup.purchase_quantity != '' &&
                      <p style={{marginLeft:'6.3em'}}><h style={styles.FontLato13BoldBlack}>
                            <FormattedMessage id="ingredientdetails.card.details.content.line6"
                                              defaultMessage='Purchase quantity   '/></h>&nbsp;&nbsp;&nbsp;
                                              {sup.pack_units} x <FormattedNumber value={sup.purchase_quantity}/>
                            {' '}{' ('}<FormattedNumber value={sup.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}
                            <FormattedMessage id="ingredientdetails.quantity.unit.text"
                                                          defaultMessage='unit'/>
                            {')'}
                      </p>}
                      {sup.date_expire &&
                          <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line21"
                                                              defaultMessage='Use by  '/></h>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              <span style={{color:'#63a4dd' ,fontSize:'14px'/*  ,fontWeight: 'bold' */}}><i><FormattedDate
                                                value={new Date(sup.date_expire)}
                                                year='numeric'
                                                month='long'
                                                day='2-digit'/></i></span>            
                          </p>}
                        {!sup.date_expire &&
                          <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line21"
                                                              defaultMessage='Use by  '/></h>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;           
                           </p>}
                            <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line22"
                                                              defaultMessage='Batch number   '/></h>&nbsp;&nbsp;&nbsp;{sup.batch_number}       
                          </p>
                      {/* </div> */}
                      </Grid.Column>
                       <Grid.Column width={4} style={{textAlign:'right'}}>
                                    {sup.default_supplier &&
                                      <Grid/*  divided='vertically' */>
                                        <Grid.Row columns='equal' style={{marginTop:'1.5em'}}>
                                            <Grid.Column >
                                                <Popup trigger={<Icon color='teal' name='check' size='large' />}
                                                        content={<FormattedMessage id="ingredientdetails.card.details.popup.default.text"
                                                        defaultMessage='Default supplier'/>}
                                                 inverted/>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Popup trigger=
                                                      {<Icon color='blue' name='edit outline' size='large' style={{cursor:'pointer'}}
                                                              onClick={(event)=>this.editExtraSupplierHandler(event,index,sup)}/>
                                                      }
                                                            content={<FormattedMessage id="ingredientdetails.card.details.popup.edit.text"
                                                                                    defaultMessage='Edit'/>}
                                                    inverted/>
                                            </Grid.Column>
                                            <Grid.Column>
                                               <Popup trigger=
                                                  {<Icon color='grey' name='trash alternate outline' size='large' style={{cursor:'pointer'}}
                                                            onClick={(event) => this.deleteExtraSupplierHandler(event,index,sup)}/>
                                                  }
                                                  content={<FormattedMessage id="ingredientdetails.card.details.popup.delete.text"
                                                                            defaultMessage='Delete'/>}
                                                  inverted/>
                                                  </Grid.Column>
                                             </Grid.Row>
                                        </Grid>}
                                     {!sup.default_supplier &&
                                       <Grid/*  divided='vertically' */>
                                        <Grid.Row columns='equal' style={{marginTop:'1.5em'}}>
                                            <Grid.Column style={{padding:'0.2em 2.5em 0 0'}} >
                                             {/*  <a onClick={(event) => this.setDefaultSupplier(event,sup.id,false)} style={{textAlign:'left'}} >
                                                <FormattedMessage id="ingredientdetails.card.details.default.text"
                                                                            defaultMessage='default'/></a> */}
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Popup trigger=
                                                {<Icon color='blue' name='edit outline' size='large' style={{cursor:'pointer'}}
                                                        onClick={(event)=>this.editExtraSupplierHandler(event,index,sup)}/>
                                                }
                                              content={<FormattedMessage id="ingredientdetails.card.details.popup.edit.text"
                                                                              defaultMessage='Edit'/>}
                                              inverted/>
                                            </Grid.Column>
                                            <Grid.Column >
                                              <Popup trigger=
                                                {<Icon color='grey' name='trash alternate outline' size='large' style={{cursor:'pointer'}}
                                                          onClick={(event) => this.deleteExtraSupplierHandler(event,index,sup)}/>
                                                }
                                                content={<FormattedMessage id="ingredientdetails.card.details.popup.delete.text"
                                                                          defaultMessage='Delete'/>}
                                                inverted/>
                                              </Grid.Column>
                                            </Grid.Row>
                                        </Grid>}
                        </Grid.Column>
                        <Modal open={this.state.activeModalExtraSupplierEdit  === index}
                                dimmer='default' 
                                size='small' 
                                style={styles.Modal} 
                                onClose={this.hideModal}>
                                    <Header icon='truck' content={<FormattedMessage id="ingredientdetails.form.extrasupplier.edit.modal.title"
                                                                                    defaultMessage='Edit supplier and price details'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'0em'}}>
                                       <Form.Group > 
                                          <Form.Field width={6}>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.name"
                                                                      defaultMessage='Supplier *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection compact
                                                                  options={supplier && supplier.options} 
                                                                  value = {supplierSelected && supplierSelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onSupplierChange}
                                                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                  clearable
                                                                  error={submitted && !this.assertSupplierField()}
                                                            />}
                                            </FormattedMessage>
                                            {submitted && !this.assertSupplierField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.error.message"
                                                                        defaultMessage='select a supplier'/>
                                                </i></label> 
                                              }
                                          </Form.Field> 
                                          <Form.Field width={5}>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.price"
                                                                    defaultMessage='Price (Purchase Cost) *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.input.price.placeholder"
                                                              defaultMessage='Add a purchase price'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="price"
                                                          value={price}
                                                          onChange={this.onPriceChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                          error={submitted && !this.assertPriceField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPriceField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.price.error.message"
                                                                        defaultMessage='price is numeric'/> 
                                                </i></label> 
                                              }
                                          </Form.Field>
                                          <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.title"
                                                                    defaultMessage='Supplier code'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.placeholder"
                                                              defaultMessage='reference'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="suppliercode"
                                                          value={ref_supplier}
                                                          onChange={this.onRefSupplierChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                              </FormattedMessage>
                                        </Form.Field>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Field  width={5} focus={false} >
                                          <label><FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.type"
                                                                    defaultMessage='Type of purchase'/>
                                          </label>
                                          <div style={{  'pointer-events': 'none', opacity: '0.5'}}>
                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.placeholder"
                                                            defaultMessage='Select a type of quantity'>
                                                {placeholder =>   
                                                        <Form.Input
                                                                //options={ [{key:'volume',text:VOLUME,value:'volume',icon: 'flask'},
                                                                //   {key:'weight',text:WEIGHT,value:'weight',icon: 'balance'},
                                                                //   {key:'quantity',text:QUANTITY,value:'quantity',icon: 'copy'}] } 
                                                                value = {type_quantity_text} 
                                                                placeholder={placeholder} 
                                                                //onChange={this.onTypeQuantityChange}
                                                                //error={submitted && !this.assertSelectedWeight()}
                                                                />}
                                          </FormattedMessage></div>  
{/*                                           {submitted && !this.assertSelectedWeight()  &&
                                              <label style={{color:'red', }}><i>
                                                    <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                      defaultMessage='purchase type to select'/>
                                              </i></label> 
                                            } */}
                                      </Form.Field>
                                      <Form.Field width={6}>
                                        <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                defaultMessage='Quantity *'/>
                                        </label>
                                        <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                          defaultMessage='Enter a number'>
                                            {placeholder =>   
                                             /*  <Form.Group inline>   */
                                                  <Form.Input labelPosition='right'
                                                      type="text"
                                                      placeholder={placeholder}
                                                      id="quantity"
                                                      value={quantity}
                                                      onChange={this.onQuantityChange}
                                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                      error={submitted && (!this.assertQuantityValueField() /* || !this.assertQuantityFieldsValidity() */)}>
                                                    <input />
                                                    {type_quantity == 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}><FormattedMessage id="ingredientdetails.quantity.units.text"
                                                                                                        defaultMessage='unit(s)'/></Label>}
                                                    {type_quantity != 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}>{quantity_unit}</Label>}
                                                    </Form.Input>
                                               /*  </Form.Group> */}
                                          </FormattedMessage>
                                          {submitted && (!this.assertQuantityValueField() /* || !this.assertQuantityFieldsValidity() */) &&
                                            <label style={{color:'red', }}><i>
                                                  <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                    defaultMessage='enter a decimal number'/>
                                            </i></label>}
                                      </Form.Field>
                                      <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.input.packaging"
                                                                    defaultMessage='Case contains'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.input.packaging.units.placeholder"
                                                              defaultMessage='Number units'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="number"
                                                          placeholder={placeholder}
                                                          id="units"
                                                          min="1"
                                                          value={pack_units}
                                                          onChange={this.onUnitsPackagingChange}
                                                          //onFocus={(e)=>this.onFocusPackField(e)}
                                                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                                          error={submitted && !this.assertPackField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPackField() &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.pack.error.message"
                                                                        defaultMessage='units number invalid'/>
                                                </i></label> 
                                              }
                                    </Form.Field>
                                    </Form.Group>
                                    {pack_units >= 1 && quantity && 
                                    <p style={{textAlign:'center',color:'grey',fontSize:'13px'}}>
                                        <FormattedMessage id="ingredientdetails.form.purchase.text1"
                                                          defaultMessage='I buy'/>
                                         <span style={{color:'orange',fontSize:'14px'}}>{' '}{pack_units} x {quantity}{quantity_unit}{' '}</span>
                                         <FormattedMessage id="ingredientdetails.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                                        <span style={{color:'orange',fontSize:'14px'}}> {pack_units}</span>
                                    </p>}
                                    <br/>
                                    <Form.Group> 
                                    <Form.Field width={6}>
                                    <label><Icon name='calendar alternate outline'/>
                                        {' '}<FormattedMessage id="ingredientdetails.form.dlc.title"
                                                              defaultMessage='Use by'/>
                                      </label>
                                      <FormattedMessage id="ingredientdetails.form.dlc.placeholder"
                                                        defaultMessage='---'>
                                          {placeholder =>     
                                                  <DatePicker selected={selectedDate} 
                                                              onChange={(date) => this.setExpirationDate(date)}
                                                              placeholderText = {placeholder} 
                                                              locale = {intl.locale}
                                                              dateFormat="P"
                                                            />}
                                        </FormattedMessage>
                                    </Form.Field>
                                    <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.extrasupplier.batchnumber.code.title"
                                                                    defaultMessage='Batch number'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='XXX_1234'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={batch_number}
                                                          onChange={this.onBatchNumberChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                              </FormattedMessage>
                                        </Form.Field>
                                        <Form.Field width={6}> 
                                                  <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                          defaultMessage="Set as default">
                                                    {text => 
                                                      <Checkbox   label={text}
                                                                  onChange={this.onCheckDefaultSupplier}
                                                                  checked={isDefaultSupplier}  
                                                                  style={{'marginLeft': '1.5em', 'marginTop':'2.2em', 'font-size': '14px'}}/>
                                                      }
                                                    </FormattedMessage>
                                        </Form.Field>
                                        </Form.Group>
 {/*                                    <Form.Group>
                                            <Form.Field width={8}>
                                                <label><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                        defaultMessage='Quantity *'/>
                                                </label>
                                                <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                                  defaultMessage='Enter a quantity'>
                                                    {placeholder =>     
                                                          <Form.Input
                                                              type="text"
                                                              placeholder={placeholder}
                                                              id="quantity"
                                                              value={quantity}
                                                              onChange={this.onQuantityChange}
                                                              onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                              error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                                            />}
                                                  </FormattedMessage>
                                                  {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                                                    <label style={{color:'red', }}><i>
                                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                            defaultMessage='quantity format invalid (follow instructions)'/>
                                                    </i></label> 
                                                  }
                                          </Form.Field>
                                          <Form.Field width={9}> 
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                    defaultMessage="Set as default supplier">
                                              {text => 
                                                <Checkbox   label={text}
                                                            onChange={this.onCheckDefaultSupplier}
                                                            checked={isDefaultSupplier}  
                                                            style={{'marginLeft': '2.5em','marginTop': '2.5em'}}/>
                                                }
                                              </FormattedMessage>
                                          </Form.Field>
                                         </Form.Group>  
                                          { type_quantity == 'weight' 
                                            &&  <Message size='medium' 
                                                        icon='balance'>       
                                                    <Icon name='balance' color='teal'/> 
                                                    <Message.Content>
                                                      <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                      <ul>
                                                        <br/>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                                      </ul> 
                                                      {WEIGHT_INSTRUCTION_LINE_EX}
                                                    </Message.Content>                                     
                                                </Message>
                                          }
                                        {  type_quantity == 'volume' 
                                            &&  <Message size='medium' 
                                                      icon='flask' >       
                                                <Icon name='flask' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                                  </ul> 
                                                  {VOLUME_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                          }
                                        { type_quantity == 'quantity' 
                                            &&  <Message size='medium' 
                                                        icon='copy'>       
                                                <Icon name='copy' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                                                  </ul> 
                                                  {QUANTITY_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                          } */}
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button  color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={(event) =>this.editExtraSupplier(event,sup.id)}>
                                        </Button>}
                                        {!updating && <Button  color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={(event) =>this.editExtraSupplier(event,sup.id)}>
                                        </Button>}
                                    </Modal.Actions>
                        </Modal>  
                        <Modal id={index}
                                   open={this.state.activeModalExtraSupplierDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="ingredientdetails.supplier.modal.delete.title"
                                                                                defaultMessage='Delete supplier'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="ingredientdetails.supplier.modal.delete.message"
                                                         defaultMessage='Really sure to delete the supplier "{name}" ?'
                                                         values={{name:supplierSelected && supplierSelected.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="ingredientdetails.supplier.modal.delete.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="ingredientdetails.supplier.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteExtraSupplier(event,sup.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                      </Grid.Row>
            );
      });
  }


  render() { 
        const { alert,dispatch,supplier,loading,intl,updating,extra_suppliers,default_supplier,recipes,storageAreas } = this.props;
        const {currency,allergens_list,allergens_str,loaded,submitted,type_metrics,file,supplierSelected,type_quantity,price,ref_supplier,
          isDefaultSupplier,quantity,quantity_unit,active_role,editCellStock,newStockQuantity,selected,storage_area,pack_units,batch_number,selectedDate,
          highlighted,type_quantity_updated} = this.state;

        if (allergens_list && !loaded){
          this.setState({ allergens_str: this.createAllergensNames()});
          this.setState({ loaded: true});
        }

        let type_quantity_text;

        switch (type_quantity) {
          case 'weight':
            type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.weight'})
            break;
          case 'volume':
            type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.volume'})
            break;
          case 'quantity':
            type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.quantity'})
            break;
          default:
            type_quantity_text = intl.formatMessage({id:'ingredientdetails.quantity.select.weight'})
        }

        return ( 
            <div>
               <Helmet>
                  <meta name="robots" content="noindex"/>
               </Helmet>
               { loading &&
                  <SideLeftUncoverIngredient>
                    <Dimmer active={(loading)} blurring inverted>
                          <Loader inverted content='Loading' size='small' />
                    </Dimmer> 
                  </SideLeftUncoverIngredient>
               }
               {!loading &&
                <SideLeftUncoverIngredient  id={this.props.match.params.ingredientId}>
                 <Grid stackable style={styles.FontLato13Border}>
                  {alert.message  && toaster_dispatch(dispatch,alert,intl)}
                    <Grid.Row columns={1}>
                            <Breadcrumb style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="ingredientdetails.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="ingredientdetails.link.ingredients"
                                                              defaultMessage='Ingredients'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active>{selected && selected.name}</Breadcrumb.Section>
                            </Breadcrumb>
                     </Grid.Row>
                     <Grid.Row columns={1}>
                        <Grid.Column width={12}>
                            <Header as='h2' >
                                { selected && selected.name}
                            </Header>
                            <span style={{'color':'#b5b1b1',marginLeft:'1em','font-size': '14px'}}>
                                <i>{selected && selected.ref_internal}</i>
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                     <Grid.Row columns={1} >
                        <Grid.Column width={4} style={{ paddingLeft: '140px'}}> 
                            <Dropzone onDropAccepted={this.onDropAccepted.bind(this)}
                                      onDropRejected={this.onDropRejected.bind(this)}
                                      multiple = {false}
                                      maxSize={2097152} //2MB
                                      accept="image/*"
                                      disabled = {active_role==GUEST}
                                      >
                                    {({getRootProps, getInputProps}) => (
                                          <section {...getRootProps()}
                                                    style={{  'height':'220px',
                                                              'width': '320px',
                                                              'backgroundColor': '#f5f5f5',
                                                              'overflow': 'auto',
                                                              'text-align' : 'center'}}>
                                              <div>
                                                  <input {...getInputProps()}/>
                                                 {!file && <div className="image-div-ingredient">
                                                     {active_role==GUEST && 
                                                          <Popup content={<FormattedMessage id="ingredientdetails.image.popup.disabled.text" 
                                                                                      defaultMessage= "No Right privilege"/>}
                                                            position='bottom center'
                                                            trigger={
                                                              <img src={DEFAULT_INGREDIENT_IMAGE}
                                                                 style={{display:'block',width:'320px','height':'220px', cursor:'pointer'/* ,'object-fit': 'scale-down' */ }}/>
                                                            }/>
                                                      }
                                                      {active_role!=GUEST && 
                                                          <Popup content={<FormattedMessage id="ingredientdetails.image.popup.text" 
                                                                                      defaultMessage= "Click or Drag'n'Drop your file"/>}
                                                            position='bottom center'
                                                            trigger={
                                                              <img src={DEFAULT_INGREDIENT_IMAGE}
                                                                 style={{display:'block',width:'320px','height':'220px', cursor:'pointer'/* ,'object-fit': 'scale-down' */ }}/>
                                                            }/>
                                                      }
                                                 </div>}
                                                  {file &&
                                                  <div className="image-div-ingredient" style={{'text-align' : 'center',width:'100%'}}>
                                                      <img src={file} 
                                                           style={{display:'block',width:'auto',height:'auto', "max-width": "320px", "max-height":"220px","margin": "0 auto",
                                                           'text-align' : 'center','justify-content': 'center;'}}/>
                                                     <Button className="hidden_button" style={{cursor:'pointer'}} color='teal' onClick={(event)=>this.triggerInputFile(event)}>
                                                          <FormattedMessage id="ingredientdetails.image.button.text" 
                                                                            defaultMessage= "Remove"/>
                                                     </Button>
                                                  </div>}
                                            </div>
                                        </section>
                                      )}
                                </Dropzone>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column mobile={12} tablet={10} computer={8}>
                            <Card fluid  style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                <div className='floating-menu' style={{paddingBottom:'90px'}}>
                                { selected && !selected.weight_variation_active &&
                                    <FloatingMenuItem label={<FormattedMessage id="ingredientdetails.card.details.weight.variation" 
                                                                               defaultMessage= "Add variation"/>} 
                                                      icon={ <Popup trigger={<Icon name='weight'/>}
                                                                    content={<FormattedMessage id="ingredientdetails.card.popup.view.weight.apply.text"
                                                                                              defaultMessage='Apply weight variation'/>}
                                                                    size='small'
                                                                    inverted/>} 
                                                      action={this.addVariationHandler.bind(this)} key="m"/>}
                                { selected && selected.weight_variation_active &&
                                            <Popup trigger={
                                                          <Button  compact /*floated='right'*/ 
                                                              //labelPosition={'right'}  
                                                              size='small'
                                                              color='teal'
                                                              onClick={(event)=>this.removeVariation(event)}
                                                              >
                                                              <Icon name="delete" size='small'/>
                                                              {(selected.weight_variation_type == 'loss') && ' - '}
                                                              {(selected.weight_variation_type == 'gain') && ' + '}
                                                              {Number(selected.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                          </Button>}
                                                    content={<FormattedMessage id="ingredientdetails.card.popup.view.weight.remove.text"
                                                                               defaultMessage='Variation applied'/>}
                                                    inverted
                                                    size='small'
                                                    position='bottom left'/>}

                                </div>
                                <div className='floating-menu' style={{paddingBottom:'40px'}}>
                                    <FloatingMenuItem label="" 
                                                      icon={ <Popup trigger={<Icon name='edit'/>}
                                                                    content={<FormattedMessage id="ingredientdetails.card.popup.view.edit.text"
                                                                                              defaultMessage='Edit'/>}
                                                                    size='small'
                                                                    inverted/>} 
                                                      action={this.editCurrentIngredient.bind(this)} key="m"/>
                                </div>    
                                <Card.Content style={{background:'#f5f5f5',border:'none'}}>
                                    <Card.Header>
                                        <FormattedMessage id="ingredientdetails.card.details.header"
                                                          defaultMessage='Ingredient details'/>
                                    </Card.Header>
                                </Card.Content>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' size='small'/>
                                    </Dimmer> }
                                { selected && <Card.Content > 
                                    <Image  style={{width:'55px'}} src={img_box}/>
                                    <div>
                                      <p><h style={styles.FontLato14BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.details.content.line0"
                                                            defaultMessage='Internal code  '/></h>&nbsp;&nbsp;&nbsp;{selected.ref_internal}
                                      </p>
                                      <p><h style={styles.FontLato14BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.details.content.line12"
                                                            defaultMessage='Sales Tax   '/></h>&nbsp;&nbsp;&nbsp;
                                                            <FormattedNumber value={selected.tax} maximumFractionDigits="2" style="percent"/>
                                      </p>
                                      <p><h style={styles.FontLato14BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line2"
                                                            defaultMessage='Category   '/></h>&nbsp;&nbsp;&nbsp;{selected.category && selected.category.name}</p>
                                     <p><h style={styles.FontLato14BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line10"
                                                            defaultMessage='Allergens   '/></h>&nbsp;&nbsp;&nbsp;{allergens_str}</p>
                                      <p/>
                                      <p   style={{marginLeft:'7.3em'}}><h style={styles.FontLato14BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line3"
                                                            defaultMessage='Technical note   '/></h>&nbsp;&nbsp;&nbsp;{selected.note}</p>
                                      <p style={{marginLeft:'7.3em'}}><h style={styles.FontLato14BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line7"
                                                            defaultMessage='Created '/></h> &nbsp;&nbsp;&nbsp;
                                          <FormattedDate
                                                value={new Date(selected.created)}
                                                year='numeric'
                                                month='long'
                                                day='2-digit'/>
                                      </p>
                                      <p style={{marginLeft:'7.3em'}}><h style={styles.FontLato14BoldBlack}>
                                        <FormattedMessage id="ingredientdetails.card.details.content.line8"
                                                          defaultMessage='Updated '/></h>&nbsp;&nbsp;&nbsp;
                                        <FormattedDate
                                                value={new Date(selected.updated)}
                                                year='numeric'
                                                month='long'
                                                day='2-digit'/>
                                      </p>
                                    </div>
                                    <br/>
                                </Card.Content>}
                                <br/><br/>
                            </Card>
                            <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                <div className='floating-menu' style={{paddingBottom:'40px'}}>
                                    <FloatingMenuItem label="" 
                                                      icon={ <Popup trigger={<Icon name='add'/>}
                                                                    content={<FormattedMessage id="ingredientdetails.card.popup.view.add.text"
                                                                                              defaultMessage='Add a supplier'/>}
                                                                    size='small'
                                                                    inverted/>} 
                                                      action={this.addExraSupplierHandler.bind(this)} 
                                                      key="m"/>
                                </div> 
                                <Card.Content style={{background:'#f5f5f5',border:'none'}}>
                                    <Card.Header>
                                        <FormattedMessage id="ingredientdetails.card.supplier.header"
                                                          defaultMessage='Supplier(s) details : price, expiration date, batch number'/>
                                    </Card.Header>
                                </Card.Content>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' size='small'/>
                                    </Dimmer> }
                                <Card.Content > 
                                  <Grid divided='vertically' /* textAlign='left' */ style={{marginLeft:'1em'}}>
                                { selected && 
                                  <Grid.Row columns={2} style={{padding:'1.5em 0 0 0'}}>
                                    <Grid.Column width={12}>
                                      <Image  style={{width:'30px',marginLeft:'3em'}} src={img_suppliers}/>
                                      <p style={{marginLeft:'6.3em'}}><h style={styles.FontLato14Teal}>
                                            {/* <FormattedMessage id="ingredientdetails.card.details.content.line9"
                                                            defaultMessage='Supplier :  '/></h>&nbsp;&nbsp;&nbsp; */}{selected.supplier && selected.supplier.name}</h>
                                            &nbsp;&nbsp;<h style={styles.FontLato13BoldBlack}>
                                              {/* <FormattedMessage id="ingredientdetails.card.details.content.line11"
                                                            defaultMessage='Supplier code :  '/> */}</h>{/* &nbsp;&nbsp;&nbsp; {selected.ref_supplier})*/}

                                              {selected.supplier && selected.ref_supplier.length > 0 && <span>({selected.ref_supplier})</span>}
                                      </p> 
{/*                                       <p  style={{marginLeft:'7.3em'}} ><h style={styles.FontLato13BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.details.content.line11"
                                                            defaultMessage='Supplier code :  '/></h>&nbsp;&nbsp;&nbsp;{selected.ref_supplier}
                                      </p> */}
                                      <p style={{marginLeft:'6.3em'}}><h style={styles.FontLato13BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.details.content.line1"
                                                            defaultMessage='Price :  '/></h>&nbsp;&nbsp;&nbsp;
                                          <FormattedNumber value={selected.price} style="currency" currencyDisplay="symbol" currency={currency}/> 
                                      </p>
                                      { selected.purchase_weight != '' &&
                                      <p  style={{marginLeft:'6.3em'}} >
                                            { grams.includes(selected.unit) && 
                                              <span>
                                              <h style={styles.FontLato13BoldBlack}>
                                              <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                                                defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_weight}
                                              {' '}{' ('}<FormattedNumber value={Number(selected.price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / kg'}
                                              {')'}
                                             </span>}
                                             { mgrams.includes(selected.unit) && 
                                              <span>
                                              <h style={styles.FontLato13BoldBlack}>
                                              <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                                                defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_weight}
                                              {' '}{' ('}<FormattedNumber value={Number(selected.price_per_unit) * 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / kg'}
                                              {')'}
                                             </span>}
                                             { !grams.includes(selected.unit) && !mgrams.includes(selected.unit) &&
                                             <span>
                                              <h style={styles.FontLato13BoldBlack}>
                                              <FormattedMessage id="ingredientdetails.card.details.content.line4"
                                                                defaultMessage='Purchase weight   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_weight}
                                              {' '}{' ('}<FormattedNumber value={selected.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{selected.unit}
                                              {')'}
                                             </span>}
                                      </p>}
                                      {selected.purchase_volume != '' &&
                                      <p  style={{marginLeft:'6.3em'}} >
                                        { cliters.includes(selected.unit) && 
                                           <span>
                                           <h style={styles.FontLato13BoldBlack}>
                                           <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                                          defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_volume}
                                            {' '}{' ('}<FormattedNumber value={Number(selected.price_per_unit) * 100} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / L'}
                                             {')'}
                                           </span>}
                                        { mliters.includes(selected.unit) && 
                                           <span>
                                           <h style={styles.FontLato13BoldBlack}>
                                           <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                                          defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_volume}
                                            {' '}{' ('}<FormattedNumber value={Number(selected.price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' / L'}
                                             {')'}
                                           </span>}
                                          { !cliters.includes(selected.unit) && !mliters.includes(selected.unit) && 
                                           <span>
                                           <h style={styles.FontLato13BoldBlack}>
                                           <FormattedMessage id="ingredientdetails.card.details.content.line5"
                                                          defaultMessage='Purchase volume   '/></h> &nbsp;&nbsp;&nbsp;{selected.pack_units} x {selected.purchase_volume}
                                            {' '}{' ('}<FormattedNumber value={selected.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{selected.unit}
                                             {')'}
                                           </span>}
                                      </p>}
                                      {selected.purchase_quantity != '' &&
                                      <p style={{marginLeft:'6.3em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line6"
                                                              defaultMessage='Purchase quantity   '/></h>&nbsp;&nbsp;&nbsp;
                                                              {selected.pack_units} x <FormattedNumber value={selected.purchase_quantity}/>
                                             {' '}{' ('}<FormattedNumber value={selected.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}
                                             <FormattedMessage id="ingredientdetails.quantity.unit.text"
                                                                          defaultMessage='unit'/>
                                             {')'}
                                      </p>}
                                      {selected.date_expire &&
                                      <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line21"
                                                              defaultMessage='Use by  '/></h>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              <span style={{color:'#63a4dd',fontSize:'14px'/*  ,fontWeight: 'bold' */}}><i><FormattedDate
                                                value={new Date(selected.date_expire)}
                                                year='numeric'
                                                month='long'
                                                day='2-digit'/></i></span>            
                                      </p>}
                                      {!selected.date_expire &&
                                      <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line21"
                                                              defaultMessage='Use by  '/></h>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;         
                                      </p>}
                                      <p style={{marginLeft:'6.9em'}}><h style={styles.FontLato13BoldBlack}>
                                            <FormattedMessage id="ingredientdetails.card.details.content.line22"
                                                              defaultMessage='Batch number   '/></h>&nbsp;&nbsp;&nbsp;{selected.batch_number}       
                                      </p>
                                     </Grid.Column>
                                     <Grid.Column width={4}>                                               
                                            {default_supplier && default_supplier.default_supplier &&
                                                <Grid/*  divided='vertically' */>
                                                  <Grid.Row columns='equal' style={{marginTop:'1.5em'}}>
                                                      <Grid.Column >
                                                        <Popup trigger=
                                                        {<Icon color='grey' name='trash alternate outline' size='large' style={{cursor:'pointer',visibility:'hidden'}}
                                                              //onClick={(event)=>this.deleteExraSupplierHandler(event,-1,1)}
                                                              />
                                                          }
                                                          content={<FormattedMessage id="ingredientdetails.card.details.popup.delete.text"
                                                                                    defaultMessage='Delete'/>}
                                                          inverted/>
                                                      </Grid.Column>
                                                      <Grid.Column>
                                                            <Popup trigger={<Icon color='teal' name='check' size='large' />}
                                                              content={<FormattedMessage id="ingredientdetails.card.details.popup.default.text"
                                                              defaultMessage='Default supplier'/>}
                                                              inverted/>
                                                      </Grid.Column>
                                                      <Grid.Column>
                                                        <Popup trigger=
                                                        {<Icon color='blue' name='edit outline' size='large' style={{cursor:'pointer'}}
                                                              onClick={(event)=>this.editExtraSupplierHandler(event,-1,selected,1)}/>
                                                        }
                                                        content={<FormattedMessage id="ingredientdetails.card.details.popup.edit.text"
                                                                                        defaultMessage='Edit'/>}
                                                        inverted/>
                                                            </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>}
                                              {default_supplier && !default_supplier.default_supplier &&
                                                  <Grid/*  divided='vertically' */>
                                                      <Grid.Row columns='equal' style={{marginTop:'1.5em'}} >
                                                          <Grid.Column>
                                                              <Popup trigger=
                                                                  {<Icon color='grey' disabled name='trash alternate outline' size='large' style={{cursor:'pointer',visibility:'hidden'}}
                                                                        //onClick={(event)=>this.deleteExraSupplierHandler(event,-1,1)}
                                                                        />
                                                                    }
                                                                    content={<FormattedMessage id="ingredientdetails.card.details.popup.delete.text"
                                                                                              defaultMessage='Delete'/>}
                                                                    inverted/>
                                                          </Grid.Column>
                                                          <Grid.Column style={{padding:'0.2em 2.5em 0 0'}}>
                                                           {/*  <a onClick={(event) => this.setDefaultSupplier(event,selected.id,true)} style={{textAlign:'left'}}>
                                                            <FormattedMessage id="ingredientdetails.card.details.default.text"
                                                                            defaultMessage='default'/></a> */}
                                                          </Grid.Column>
                                                          <Grid.Column textAlign='right' >
                                                            <Popup trigger=
                                                                {<Icon color='blue' name='edit outline' size='large' style={{cursor:'pointer'}}
                                                                      onClick={(event)=>this.editExtraSupplierHandler(event,-1,selected,1)}/>
                                                                }
                                                                content={<FormattedMessage id="ingredientdetails.card.details.popup.edit.text"
                                                                                                defaultMessage='Edit'/>}
                                                                inverted/>
                                                          </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>}
                                  </Grid.Column>
                                  </Grid.Row>}   
                                 {extra_suppliers &&  extra_suppliers.length > 0 &&
                                    this.addExtraSupplierCards() 
                                 }
                                </Grid>
                                <br/> <br/>{/*   <br/><br/> */}
                              </Card.Content>
                              <br/> <br/>{/*  <br/><br/> */}
                            </Card>
                            <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                <Card.Content style={{background:'#f5f5f5'}}>
                                     <Card.Header>
                                        <FormattedMessage id="ingredientdetails.card.stock.header"
                                                          defaultMessage='Stock'/>
                                    </Card.Header>
                                </Card.Content>
                                
                                <Card.Content >
                                  <Image src={img_stock} style={{width:'55px'}}/>
                                  {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' size='small'/>
                                    </Dimmer> }
                                  <div>
                                 {selected && /* selected.stock_quantity > 0) && */ 
                                     <div style={styles.FontLato14Border}>
                                      <p style={{display:'flex'}} onClick={(event) => this.editStockQuantityHandlerInput(event,selected.stock_quantity)}>
                                        <h style={styles.FontLato14BoldBlack}>
                                           <FormattedMessage id="ingredientdetails.card.stock.content.line1"
                                                            defaultMessage='Available quantity   '/></h>&nbsp;&nbsp;&nbsp;
                                          {!editCellStock &&
                                            <a href='#'>
                                                <FormattedNumber value={selected.stock_quantity} maximumFractionDigits="3"/>{' '}{selected.stock_unit}
                                            </a>
                                          }
                                          {editCellStock &&
                                          <OutsideClickHandler onOutsideClick={(event) => this.updateStockQuantityOnBlur(event,selected)} >
                                               <div style={{display:'flex'}}> 
                                               <Icon name='minus circle' size='large'
                                                        style={{cursor:'pointer',padding:'0.2em 0em 0em 0.5em'}} 
                                                        onClick={(event) =>this.onIncreaseDecreaseQuantity(event,selected,false)} /> 
                                              <Icon name='plus circle'  size='large'
                                                        style={{cursor:'pointer',padding:'0.2em 0.5em 0em 0.5em'}} 
                                                        onClick={(event) => this.onIncreaseDecreaseQuantity(event,selected,true)}/>
                                                     <Input type="text"
                                                             id="stockQuantity"
                                                             value={newStockQuantity}
                                                             onChange={this.onNewStockQuantity}
                                                             //onBlur={(event) => this.updateStockQuantityOnBlur(event,selected)} 
                                                             onKeyPress={(event) => this.updateStockQuantityOnEnter(event,selected)} 
                                                             autoFocus
                                                             style={{height:'2.2em',minWidth:'7em',maxWidth:'7em' , padding:'0em 0.5em 0em 1em' }}
                                                             label={{ basic: false, content: selected.unit }}
                                                             labelPosition='right'
                                                             error={submitted && (!this.assertQuantityField())}/>
                                                       {/* {' '}{selected.unit} */}
                                               </div>
                                            </OutsideClickHandler>} 
                                      </p>
                                      <p><h style={styles.FontLato14BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.stock.content.line2"
                                                            defaultMessage='Value   '/></h>&nbsp;&nbsp;&nbsp;
                                          {<FormattedNumber value={selected.stock_price} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>}
                                      </p>
                                      <p><h style={styles.FontLato14BoldBlack}>
                                          <FormattedMessage id="ingredientdetails.card.stock.content.line3"
                                                            defaultMessage='Storage area   '/></h>&nbsp;&nbsp;&nbsp;
                                          {!selected.stock_storage &&
                                          <a href='#' onClick={(event)=>this.changeStockAreaHandler(event)}><FormattedMessage id="ingredientdetails.card.stock.area.none.text"
                                                            defaultMessage='None '/></a>}
                                          {selected.stock_storage &&
                                           <a href='#' onClick={(event)=>this.changeStockAreaHandler(event)}>{selected.stock_storage.name}</a>}
                                      </p>
                                      <br/>
                                      <span style={{paddingLeft:'7em'}}>
                                        <Button basic compact size='tiny' onClick={(event)=>this.changeStockAreaHandler(event)} color='teal'>
                                            <FormattedMessage id="ingredientdetails.card.stock.update.text"
                                                                    defaultMessage='Change area'/>
                                        </Button>&nbsp;&nbsp;
                                        <Button basic compact size='tiny' as={Link} to={'/stockarea'}>
                                            <FormattedMessage id="ingredientdetails.card.stock.add.text"
                                                                    defaultMessage='Add area'/>
                                        </Button>&nbsp;&nbsp;
                                        <Button basic compact size='tiny' as={Link} to={{pathname:'/inventory',state:{startPage:1}}}>
                                            <FormattedMessage id="ingredientdetails.card.stock.discover.text"
                                                                    defaultMessage='Browse inventory'/>
                                        </Button>
                                      </span>
                                     </div>
                                     }
{/*                                     {selected && selected.stock_quantity == 0 && 
                                          <ul><FormattedMessage id="ingredientdetails.card.stock.none.text"
                                                                defaultMessage='Not available in stock'/></ul>} */}
                                  </div>
                                </Card.Content>
                            </Card>
                            <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                <Card.Content style={{background:'#f5f5f5'}}>
                                     <Card.Header>
                                        <FormattedMessage id="ingredientdetails.card.usage.header"
                                                          defaultMessage='Ingredient usage'/>
                                    </Card.Header>
                                </Card.Content>
                                
                                <Card.Content >
                                  <Image style={{width:'55px'}} src={img_link}/>
                                  {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' size='small'/>
                                    </Dimmer> }
                                  <div>
                                      <p style={styles.FontLato13Border}><FormattedMessage id="ingredientdetails.card.usage.subheader"
                                                                defaultMessage='Used by recipes '/></p>
                                      <div style={{marginLeft:'8em'}}>
                                        {recipes && <ul>{this.addRecipesRows(recipes)}</ul>}
                                        {!recipes && <ul><FormattedMessage id="ingredientdetails.card.usage.none.text"
                                                                                                    defaultMessage='None'/></ul>}
                                     </div>
                                  </div>
                                </Card.Content>
                            </Card>
                            <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                               <div className='floating-menu' style={{paddingBottom:'40px'}}>
                               {selected && selected.metrics &&
                                  <FloatingMenuItem label='' 
                                                    icon={ <Popup trigger={<Icon name='edit'/>}
                                                                  content={<FormattedMessage id="ingredientdetails.card.nutrition.edit.text"
                                                                                            defaultMessage='Edit nutrition values'/>}
                                                                  size='small'
                                                                  inverted/>} 
                                                    action={this.goToNutrition.bind(this)} key="m"/>
                               }
                               {selected && !selected.metrics &&
                                  <FloatingMenuItem label='' 
                                                    icon={ <Popup trigger={<Icon name='add'/>}
                                                                  content={<FormattedMessage id="ingredientdetails.card.nutrition.add.text"
                                                                                            defaultMessage='Add nutrition values'/>}
                                                                  size='small'
                                                                  inverted/>} 
                                                    action={this.goToNutrition.bind(this)} key="m"/>
                               }
                                </div>  
                                <Card.Content style={{background:'#f5f5f5'}}>
                                     <Card.Header>
                                        <FormattedMessage id="ingredientdetails.card.nutrition.header"
                                                          defaultMessage='Nutrition facts'/>
                                                         {/*  Valeurs nutritionnelles moyennes */}
                                    </Card.Header>
                                </Card.Content>
                                
                                <Card.Content >
                                  <Image style={{width:'55px'}} src={img_hearbeat}/>
                                  {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' size='small'/>
                                    </Dimmer> }
                                    <div >
                                            <br/>
                                            <label><FormattedMessage id="ingredientdetails.form.select.nutrition.type"
                                                                      defaultMessage='Nutrition tag format'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.select.nutrition.type.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Select  
                                                                  options={ [{key:'france',text:'FR (INCO)',value:'france'},
                                                                            {key:'us',text:'USA (FDA)',value:'us'},
                                                                            {key:'canada',text:'CA (CANADA)',value:'canada'}] } 
                                                                  value = {type_metrics} 
                                                                  placeholder={placeholder} 
                                                                  style={{minWidth:'15em',maxWidth:'30em'}}
                                                                  onChange={this.onTypeMetricsChange}/>}
                                            </FormattedMessage>  
                                            <br/>  
{/*                                       {selected && selected.metrics && intl.locale == 'fr' &&
                                          this.addFoodFactsFR(selected.metrics)} */}
                                          {selected && selected.metrics && type_metrics =='france' && 
                                                this.addFoodFactsFR(selected.metrics)}
                                          {selected && selected.metrics && type_metrics =='canada' && 
                                                this.addFoodFactsCA(selected.metrics)}
                                          {selected && selected.metrics && type_metrics =='us' &&
                                                this.addFoodFactsUS(selected.metrics)}
{/*                                       {ingredients && ingredients.selected &&
                                          <Button basic color='teal' onClick= {(e) => this.goToNutrition()}>
                                            <FormattedMessage id="ingredientdetails.card.nutrition.button"
                                                                defaultMessage='Retrieve/Update nutrition'/>
                                          </Button>} */}
                                    </div>{/* } */}
                                    <br/><br/><br/>
                                </Card.Content>
                            </Card>
                            <Modal
                                   open={this.state.activeModalVariation}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='weight' content={<FormattedMessage id="ingredientdetails.table.footer.variation.modal.title"
                                                                                    defaultMessage='Apply a cooking variation (applied when used in a recipe)'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'0em'}}>
                                            <Icon color='blue' name='percent'/>{' '}
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="ingredientdetails.table.footer.variation.percent.modal.form.option.weight"
                                                                              defaultMessage='Enter a percentage and variation type :'/></label>
                                             <Form.Group widths='equal'>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.select.name"
                                                                              defaultMessage='Type of variation'/></label>
                                                        <FormattedMessage id="ingredientdetails.table.footer.variation.modal.type.placeholder"
                                                                    defaultMessage='Select'>
                                                                {placeholder =>   
                                                                 <Form.Group>
                                                                        <Form.Select 
                                                                                    options={variationTypeOptions} 
                                                                                    placeholder={placeholder} 
                                                                                    onChange={this.onTypeVariationChange}
                                                                                    value= {this.state.typeVariation}
                                                                                    error={submitted && !this.assertTypeField()}
                                                                                    /></Form.Group>}
                                                    </FormattedMessage>
                                                     {submitted && !this.assertTypeField()  && 
                                                            <label style={{color:'red'}}><i>
                                                             <FormattedMessage id="ingredientdetails.table.footer.variation.modal.select.error.message"
                                                                                defaultMessage='variation type'/> 
                                                            </i></label>
                                                    }
                                                </Form.Field >
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.input.name"
                                                                              defaultMessage='Enter percentage'/></label>
                                                    <Form.Group>
                                                        <Input
                                                            type="text"
                                                            placeholder="1-99"
                                                            id="inputVariation"
                                                            value={this.state.valueVariation}
                                                            onChange={this.onValueVariationChange}
                                                            error={submitted && !this.assertPercentField()}
                                                        />{' '}
                                                        <Label>%</Label>
                                                    </Form.Group>
                                                     {submitted && !this.assertPercentField()  && 
                                                            <label style={{color:'red'}}><i>
                                                            <FormattedMessage id="ingredientdetails.table.footer.variation.modal.input.error.message"
                                                                                defaultMessage='1-99'/>
                                                            </i></label>
                                                    }
                                                </Form.Field>
                                            </Form.Group>
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.label.or"
                                                                              defaultMessage='OR'/></label>
                                            <br/><br/>
                                            <Icon color='blue' name='calculator'/>{' '}
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.weight"
                                                                              defaultMessage='Calculate with your gross/net weights (in the same unit) :'/></label>
                                            <Form.Group widths='equal' style={{padding:'0.5em'}}>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.gross.weight"
                                                                              defaultMessage='Enter gross weight'/></label>
                                                        <FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.gross.weight.placeholder"
                                                                    defaultMessage='gross value'>
                                                                {placeholder =>   
                                                                 <Form.Group>
                                                                        <Input placeholder={placeholder} 
                                                                               onChange={this.onValueGrossChange}
                                                                                //value= {this.state.typeVariation}
                                                                                //error={!this.assertGrossWeightField()}

                                                                        />{' '}
                                                                 <Label><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.weight.unit"
                                                                              defaultMessage='g'/></Label>
                                                                </Form.Group>}
                                                    </FormattedMessage>
                                                </Form.Field >
                                                <Form.Field style={{marginLeft:'1em'}}>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.net.weight"
                                                                              defaultMessage='Enter net weight'/></label>
                                                   
                                                    <FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.net.weight.placeholder"
                                                                    defaultMessage='net value'>
                                                                {placeholder =>   
                                                                 <Form.Group>
                                                                        <Input placeholder={placeholder} 
                                                                               onChange={this.onValueNetChange}
                                                                                //value= {this.state.typeVariation}
                                                                                //error={!this.assertNetWeightField()}
                                                                        />{' '}
                                                                   <Label><FormattedMessage id="ingredientdetails.table.footer.variation.modal.form.option.weight.unit"
                                                                              defaultMessage='g'/></Label>
                                                                   </Form.Group>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredientdetails.table.footer.variation.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.table.footer.variation.modal.validate.button.apply"
                                                                        defaultMessage='Apply'/>}
                                            icon='checkmark' 
                                            onClick={this.onApplyVariation}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.table.footer.variation.modal.validate.button.apply"
                                                                        defaultMessage='Apply'/>}
                                            icon='checkmark' 
                                            onClick={this.onApplyVariation}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                                </Modal>
                                <Modal
                                   open={this.state.activeModalExtraSupplierAdd}
                                   dimmer='default' 
                                   size='small' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='truck' content={<FormattedMessage id="ingredientdetails.form.extrasupplier.add.modal.title"
                                                                                    defaultMessage='Add supplier and price'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'0em'/*,paddingBottom:'3em'*/}}>
                                        <Form.Group > 
                                          <Form.Field width={6}>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.name"
                                                                      defaultMessage='Supplier *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection compact
                                                                  options={supplier && supplier.options} 
                                                                  value = {supplierSelected && supplierSelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onSupplierChange}
                                                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                  clearable
                                                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                                                  error={submitted && !this.assertSupplierField()}
                                                            />}
                                            </FormattedMessage>
                                            {submitted && !this.assertSupplierField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.error.message"
                                                                        defaultMessage='select a supplier'/>
                                                </i></label> 
                                              }
                                          </Form.Field> 
                                          <Form.Field width={5}/* style={{'marginLeft':'0.5em'}} */>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.price"
                                                                    defaultMessage='Price (Purchase Cost) *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.input.price.placeholder"
                                                              defaultMessage='Add a purchase price'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="price"
                                                          value={this.state.price}
                                                          onChange={this.onPriceChange}
                                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                          error={submitted && !this.assertPriceField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPriceField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.price.error.message"
                                                                        defaultMessage='price is numeric'/>
                                                </i></label> 
                                              }
                                          </Form.Field>
                                          <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.title"
                                                                    defaultMessage='Supplier code'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.placeholder"
                                                              defaultMessage='reference'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="suppliercode"
                                                          value={this.state.ref_supplier}
                                                          onChange={this.onRefSupplierChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                              </FormattedMessage>
                                        </Form.Field>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Field width={5}>
                                          <label><FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.type"
                                                                    defaultMessage='Type of purchase'/>
                                          </label>
                                          <div style={{  'pointer-events': 'none', opacity: '0.5'}}>
                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.placeholder"
                                                            defaultMessage='Select a type of quantity'>
                                                {placeholder =>   
                                                        <Form.Input  
                                                                //options={ [{key:'volume',text:VOLUME,value:'volume',icon: 'flask'},
                                                                //   {key:'weight',text:WEIGHT,value:'weight',icon: 'balance'},
                                                                //   {key:'quantity',text:QUANTITY,value:'quantity',icon: 'copy'}] } 
                                                                value = {type_quantity_text} 
                                                                placeholder={placeholder} 
                                                                //onChange={this.onTypeQuantityChange}
                                                                //error={submitted && !this.assertSelectedWeight()}
                                                                />}
                                          </FormattedMessage></div>
{/*                                           {submitted && !this.assertSelectedWeight()  &&
                                              <label style={{color:'red', }}><i>
                                                    <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                      defaultMessage='purchase type to select'/>
                                              </i></label> 
                                            } */}
                                      </Form.Field>
                                      <Form.Field  width={6}>
                                        <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                defaultMessage='Quantity *'/>
                                        </label>
                                        <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                          defaultMessage='Enter a number'>
                                            {placeholder =>   
                                            /*   <Form.Group inline>   */
                                                  <Form.Input labelPosition='right'
                                                      type="text"
                                                      placeholder={placeholder}
                                                      id="quantity"
                                                      value={quantity}
                                                      onChange={this.onQuantityChange}
                                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                      error={submitted && (!this.assertQuantityValueField() /* || !this.assertQuantityFieldsValidity() */)}>
                                                    <input />
                                                    {type_quantity == 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}><FormattedMessage id="ingredientdetails.quantity.units.text"
                                                                                                        defaultMessage='unit(s)'/></Label>}
                                                    {type_quantity != 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}>{quantity_unit}</Label>}
                                                    </Form.Input>
                                                  /* </Form.Group> */}
                                          </FormattedMessage>
                                          {submitted && (!this.assertQuantityValueField() /* || !this.assertQuantityFieldsValidity() */) &&
                                            <label style={{color:'red', }}><i>
                                                  <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                    defaultMessage='enter a decimal number'/>
                                            </i></label>}
                                      </Form.Field>
                                      <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.input.packaging"
                                                                    defaultMessage='Case contains'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.input.packaging.units.placeholder"
                                                              defaultMessage='Number units'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="number"
                                                          placeholder={placeholder}
                                                          id="units"
                                                          min="1"
                                                          value={pack_units}
                                                          onChange={this.onUnitsPackagingChange}
                                                          //onFocus={(e)=>this.onFocusPackField(e)}
                                                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                                          error={submitted && !this.assertPackField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPackField() &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.pack.error.message"
                                                                        defaultMessage='units number invalid'/>
                                                </i></label> 
                                              }
                                    </Form.Field>
                                    </Form.Group>
                                    {pack_units >= 1 && quantity && 
                                    <p style={{textAlign:'center',color:'grey',fontSize:'13px'}}>
                                        <FormattedMessage id="ingredientdetails.form.purchase.text1"
                                          defaultMessage='I buy'/>
                                          <span style={{color:'orange',fontSize:'14px'}}>{' '}{pack_units} x {quantity}{quantity_unit}{' '}</span>
                                         <FormattedMessage id="ingredientdetails.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                                        <span style={{color:'orange',fontSize:'14px'}}> {pack_units}</span>
                                    </p>}
                                    <br/>
                                    <Form.Group> 
                                    <Form.Field width={6}>
                                    <label><Icon name='calendar alternate outline'/>
                                        {' '}<FormattedMessage id="ingredientdetails.form.dlc.title"
                                                              defaultMessage='Use by'/>
                                      </label>
                                      <FormattedMessage id="ingredientdetails.form.dlc.placeholder"
                                                        defaultMessage='---'>
                                          {placeholder =>     
                                                  <DatePicker selected={selectedDate} 
                                                              onChange={(date) => this.setExpirationDate(date)}
                                                              placeholderText = {placeholder} 
                                                              locale = {intl.locale}
                                                              dateFormat="P"/>}
                                        </FormattedMessage>
                                    </Form.Field>
                                    <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.extrasupplier.batchnumber.code.title"
                                                                    defaultMessage='Batch number'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='XXX_1234'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={batch_number}
                                                          onChange={this.onBatchNumberChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)} />}
                                              </FormattedMessage>
                                        </Form.Field>
                                        <Form.Field width={6}> 
                                                  <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                          defaultMessage="Set as default">
                                                    {text => 
                                                      <Checkbox   label={text}
                                                                  onChange={this.onCheckDefaultSupplier}
                                                                  checked={isDefaultSupplier}  
                                                                  style={{'marginLeft': '1.5em', 'marginTop':'2.2em', 'font-size': '14px'}}/>
                                                      }
                                                    </FormattedMessage>
                                        </Form.Field>
                                        </Form.Group>
{/*                                       <Form.Group>
                                            <Form.Field width={8}>
                                                <label><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                        defaultMessage='Quantity *'/>
                                                </label>
                                                <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                                  defaultMessage='Enter a quantity'>
                                                    {placeholder =>     
                                                          <Form.Input
                                                              type="text"
                                                              placeholder={placeholder}
                                                              id="quantity"
                                                              value={quantity}
                                                              onChange={this.onQuantityChange}
                                                              onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                              error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                                            />}
                                                  </FormattedMessage>
                                                  {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                                                    <label style={{color:'red', }}><i>
                                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                            defaultMessage='enter a decimal number'/>
                                                    </i></label> 
                                                  }
                                          </Form.Field>
                                          <Form.Field width={9}> 
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                    defaultMessage="Set as default supplier">
                                              {text => 
                                                <Checkbox   label={text}
                                                            onChange={this.onCheckDefaultSupplier}
                                                            checked={isDefaultSupplier}  
                                                            style={{'marginLeft': '2.5em','marginTop': '2.5em'}}/>
                                                }
                                              </FormattedMessage>
                                          </Form.Field>
                                         </Form.Group>  
                                          { type_quantity == 'weight' 
                                            &&  <Message size='medium' 
                                                        icon='balance'>       
                                                    <Icon name='balance' color='teal'/> 
                                                    <Message.Content>
                                                      <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                      <ul>
                                                        <br/>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                                      </ul> 
                                                      {WEIGHT_INSTRUCTION_LINE_EX}
                                                    </Message.Content>                                     
                                                </Message>
                                          }
                                        {  type_quantity == 'volume' 
                                            &&  <Message size='medium' 
                                                      icon='flask' >       
                                                <Icon name='flask' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                                  </ul> 
                                                  {VOLUME_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                          }
                                        { type_quantity == 'quantity' 
                                            &&  <Message size='medium' 
                                                        icon='copy'>       
                                                <Icon name='copy' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                                                  </ul> 
                                                  {QUANTITY_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                          } */}
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.add"
                                                                        defaultMessage='Add'/>}
                                            icon='checkmark' 
                                            onClick={this.onAddExtraSupplier} >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.add"
                                                                        defaultMessage='Add'/>}
                                            icon='checkmark' 
                                            onClick={this.onAddExtraSupplier} >
                                        </Button>}
                                    </Modal.Actions>
                                </Modal>
                                <Modal
                                   open={this.state.activeModalDefaultSupplierEdit}
                                   dimmer='default' 
                                   size='small'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='truck' content={<FormattedMessage id="ingredientdetails.form.extrasupplier.edit.modal.title"
                                                                                    defaultMessage='Edit supplier and price details'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'0em'/* ,paddingBottom:'3em' */}}>
                                        <Form.Group > 
                                          <Form.Field width={6}>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.name"
                                                                      defaultMessage='Supplier *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection compact
                                                                  options={supplier && supplier.options} 
                                                                  value = {supplierSelected && supplierSelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onSupplierChange}
                                                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                  clearable
                                                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                                                  //error={submitted && !this.assertSupplierField()}
                                                            />}
                                            </FormattedMessage>
{/*                                             {submitted && !this.assertSupplierField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.error.message"
                                                                        defaultMessage='select a supplier'/>
                                                </i></label> 
                                              } */}
                                          </Form.Field> 
                                          <Form.Field width={5}/* style={{'marginLeft':'0.5em'}} */>
                                            <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.price"
                                                                    defaultMessage='Price (Purchase Cost) *'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.input.price.placeholder"
                                                              defaultMessage='Add a purchase price'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="price"
                                                          value={price}
                                                          onChange={this.onPriceChange}
                                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                          error={submitted && !this.assertPriceField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPriceField()  &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.extrasupplier.price.error.message"
                                                                        defaultMessage='price is numeric'/>
                                                </i></label> 
                                              }
                                          </Form.Field>
                                          <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.title"
                                                                    defaultMessage='Supplier code'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.code.placeholder"
                                                              defaultMessage='reference'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="suppliercode"
                                                          value={ref_supplier}
                                                          onChange={this.onRefSupplierChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                              </FormattedMessage>
                                        </Form.Field>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Field width={6}>
                                          <label><FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.type"
                                                                    defaultMessage='Type of purchase'/>
                                          </label>
                                          {selected && selected.extra_suppliers && (selected.extra_suppliers).length <=0 &&
                                          <div>
                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.placeholder"
                                                            defaultMessage='Select a type of quantity'>
                                                {placeholder =>   
                                                        <Form.Select  
                                                                options={ [{key:'volume',text:<span><Icon name='flask' color='teal'/>{VOLUME}</span>,value:'volume'},
                                                                          {key:'weight',text:<span><Icon name='balance' color='teal'/>{WEIGHT}</span>,value:'weight'},
                                                                          {key:'quantity',text:<span><Icon name='copy' color='teal'/>{QUANTITY}</span>,value:'quantity'}] } 
                                                                value = {type_quantity} 
                                                                placeholder={placeholder} 
                                                                onChange={this.onTypeQuantityChange}
                                                                error={submitted && !this.assertSelectedWeight()}
                                                                />}
                                          </FormattedMessage></div>}
                                          {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                          <div style={{  'pointer-events': 'none', opacity: '0.5'}}>
                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.select.purchase.placeholder"
                                                            defaultMessage='Select a type of quantity'>
                                                {placeholder =>   
                                                        <Form.Input  
                                                                value = {type_quantity_text} 
                                                                placeholder={placeholder} 
                                                                //onChange={this.onTypeQuantityChange}
                                                                //error={submitted && !this.assertSelectedWeight()}
                                                                />}
                                          </FormattedMessage></div> }
{/*                                           {submitted && !this.assertSelectedWeight()  &&
                                              <label style={{color:'red', }}><i>
                                                    <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                      defaultMessage='purchase type to select'/>
                                              </i></label> 
                                            } */}
                                      </Form.Field>
                                      <Form.Field  width={5}>
                                        <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                defaultMessage='Quantity *'/>
                                        </label>
                                       {selected && selected.extra_suppliers && (selected.extra_suppliers).length <=0 &&
                                        <div>
                                        <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantityfull.placeholder"
                                                          defaultMessage='Quantity with unit'>
                                            {placeholder =>   
                                                  <Form.Input 
                                                      type="text"
                                                      placeholder={placeholder}
                                                      id="quantity"
                                                      value={quantity}
                                                      onChange={this.onFullQuantityChange}
                                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                      error={submitted && (/* !this.assertQuantityField() || */ !this.assertQuantityFieldsValidity())}/>}
                                          </FormattedMessage>
                                          {submitted && (/*  !this.assertQuantityValueField()   || */  !this.assertQuantityFieldsValidity() ) &&
                                            <label style={{color:'red', }}><i>
                                              <FormattedMessage id="ingredientdetails.form.extrasupplier.quantityfull.error.message"
                                                                    defaultMessage='quantity format invalid (follow instructions)'/>
                                            </i></label>}
                                          </div>}
                                         {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                          <div>
                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                          defaultMessage='Enter a number'>
                                            {placeholder =>   
                                                  <Form.Input labelPosition='right'
                                                      type="text"
                                                      placeholder={placeholder}
                                                      id="quantity"
                                                      value={quantity}
                                                      onChange={this.onQuantityChange}
                                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                      error={submitted && ( !this.assertQuantityValueField() /* ||  !this.assertQuantityFieldsValidity() */)}>
                                                     <input />
                                                    {type_quantity == 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}><FormattedMessage id="ingredientdetails.quantity.units.text"
                                                                                                        defaultMessage='unit(s)'/></Label>}
                                                    {type_quantity != 'quantity' &&
                                                    <Label color='teal' style={styles.FontLato14}>{quantity_unit}</Label>}
                                                    </Form.Input>}
                                          </FormattedMessage>
                                          {submitted && ( !this.assertQuantityValueField() /*  ||  !this.assertQuantityFieldsValidity() */) &&
                                            <label style={{color:'red', }}><i>
                                                        <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                          defaultMessage='enter a decimal number'/>
                                            </i></label>}
                                          </div>}
                                      </Form.Field>
                                      <Form.Field width={4}>
                                            <label><FormattedMessage id="ingredientdetails.form.input.packaging"
                                                                    defaultMessage='Case contains'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.input.packaging.units.placeholder"
                                                              defaultMessage='Number units'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="number"
                                                          placeholder={placeholder}
                                                          id="units"
                                                          min="1"
                                                          value={pack_units}
                                                          onChange={this.onUnitsPackagingChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                                          error={submitted && !this.assertPackField()}
                                                        />}
                                              </FormattedMessage>
                                              {submitted && !this.assertPackField() &&
                                                <label style={{color:'red', }}><i>
                                                      <FormattedMessage id="ingredientdetails.form.pack.error.message"
                                                                        defaultMessage='units number invalid'/>
                                                </i></label> 
                                              }
                                      </Form.Field>
                                    </Form.Group>
                                    {pack_units >= 1 && quantity && 
                                    <p style={{textAlign:'center',color:'grey',fontSize:'13px'}}>
                                        <FormattedMessage id="ingredientdetails.form.purchase.text1"
                                          defaultMessage='I buy'/>
                                             {selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0 &&
                                          <span style={{color:'orange',fontSize:'14px'}}>{' '}{pack_units} x {quantity}{' '}</span>}
                                             {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                          <span style={{color:'orange',fontSize:'14px'}}>{' '}{pack_units} x {quantity}{quantity_unit}{' '}</span>}
                                        <FormattedMessage id="ingredientdetails.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                                        <span style={{color:'orange',fontSize:'14px'}}> {pack_units}</span>
                                    </p>}
                                    {selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0 && type_quantity_updated &&
                                    <p style={{textAlign:'left',color:'grey',fontSize:'13px'} }>
                                      <Icon name='info circle' color='grey'/>
                                      <i><FormattedMessage id="ingredientdetails.form.type_quantity.info.message"
                                                        defaultMessage='If you update the type, adapt your quantites in related recipes'/></i>
                                    </p>}
                                    { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                                      && type_quantity == 'weight' 
                                            &&  <Message size='tiny' compact
                                                        icon='balance'>       
                                                    <Icon name='balance' color='teal'/> 
                                                    <Message.Content>
                                                      <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                      <ul>
                                                        <br/>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                                      </ul> 
                                                      {WEIGHT_INSTRUCTION_LINE_EX}
                                                    </Message.Content>                                     
                                                </Message>
                                      }
                                      { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                                            && type_quantity == 'volume' 
                                            &&  <Message size='tiny' compact
                                                      icon='flask' >       
                                                <Icon name='flask' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                                  </ul> 
                                                  {VOLUME_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                        }
                                        { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                                           && type_quantity == 'quantity' 
                                            &&  <Message size='tiny' compact
                                                        icon='copy'>       
                                                <Icon name='copy' color='teal'/> 
                                                <Message.Content>
                                                  <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                  <ul>
                                                    <br/>
                                                    <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                                                  </ul> 
                                                  {QUANTITY_INSTRUCTION_LINE_EX}
                                                </Message.Content>                                     
                                            </Message>
                                          }
{/*                                   {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                    <p style={{textAlign:'center',color:'grey',fontSize:'13px'} }>
                                      <Icon name='info' color='grey'/>
                                      <i><FormattedMessage id="editingredient.form.extrasupplier.unit.info.message"
                                                        defaultMessage='Units are fixed in case extra suppliers are defined'/></i>
                                    </p>} */}
                                    <br/>
                                    <Form.Group> 
                                      <Form.Field width={6}>
                                        <label> <Icon name='calendar alternate outline'/>
                                          {' '}<FormattedMessage id="ingredientdetails.form.dlc.title"
                                                                defaultMessage='Use by'/>
                                        </label>
                                        <FormattedMessage id="ingredientdetails.form.dlc.placeholder"
                                                          defaultMessage='---'>
                                            {placeholder =>     
                                                    <DatePicker selected={selectedDate} 
                                                                onChange={(date) => this.setExpirationDate(date)}
                                                                placeholderText = {placeholder} 
                                                                locale={intl.locale}
                                                                onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                dateFormat="P" />}
                                          </FormattedMessage>
                                      </Form.Field>
                                      <Form.Field width={4}>
                                              <label><FormattedMessage id="ingredientdetails.form.extrasupplier.batchnumber.code.title"
                                                                      defaultMessage='Batch number'/>
                                              </label>
                                              <FormattedMessage id="ingredientdetails.form.extrasupplier.supplier.batchnumber.placeholder"
                                                                defaultMessage='XXX_1234'>
                                                  {placeholder =>     
                                                        <Form.Input
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="batchcode"
                                                            value={batch_number}
                                                            onChange={this.onBatchNumberChange}
                                                            onFocus={(e)=>this.onFocusElsewhere(e)}/>}
                                                </FormattedMessage>
                                          </Form.Field>
                                          {extra_suppliers && extra_suppliers.length > 0 && //default_supplier && !default_supplier.default_supplier &&
                                          <Form.Field width={6}> 
                                                    <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                            defaultMessage="Set as default">
                                                      {text => 
                                                        <Checkbox   label={text}
                                                                    onChange={this.onCheckDefaultSupplier}
                                                                    checked={isDefaultSupplier}  
                                                                    style={{'marginLeft': '1.5em', 'marginTop':'2.2em', 'font-size': '14px'}}/> }
                                                      </FormattedMessage>
                                          </Form.Field>}
                                    </Form.Group>
{/*                                           <Form.Group>
                                            <Form.Field width={8}>
                                                <label><FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity"
                                                                        defaultMessage='Quantity *'/>
                                                </label>
                                                <FormattedMessage id="ingredientdetails.form.extrasupplier.input.quantity.placeholder"
                                                                  defaultMessage='Enter a quantity'>
                                                    {placeholder =>     
                                                          <Form.Input
                                                              type="text"
                                                              placeholder={placeholder}
                                                              id="quantity"
                                                              value={quantity}
                                                              onChange={this.onQuantityChange}
                                                              onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                                              //style={{minWidth:'10em',maxWidth:'30em'}}
                                                              error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                                            />}
                                                  </FormattedMessage>
                                                  {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                                                    <label style={{color:'red', }}><i>
                                                          <FormattedMessage id="ingredientdetails.form.extrasupplier.quantity.error.message"
                                                                            defaultMessage='enter a decimal number'/>
                                                    </i></label> 
                                                  }
                                          </Form.Field>
                                          <Form.Field width={9}> 
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.default.checkbox"
                                                                    defaultMessage="Set as default supplier">
                                              {text => 
                                                <Checkbox   label={text}
                                                            onChange={this.onCheckDefaultSupplier}
                                                            checked={isDefaultSupplier}  
                                                            style={{'marginLeft': '2.5em','marginTop': '2.5em'}}/>
                                                }
                                              </FormattedMessage>
                                          </Form.Field>
                                              </Form.Group>  */}
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={(event) =>this.editDefaultSupplier(event)}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.extrasupplier.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={(event) =>this.editDefaultSupplier(event)}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                        </Modal> 
                        <Modal    open={this.state.activeStockAreaChange}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='warehouse' content={<FormattedMessage id="ingredientdetails.form.stockarea.change.modal.title"
                                                                                    defaultMessage='Select storage area'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'1em',paddingBottom:'3em'}}>
                                          <Form.Field width={8}>
                                            <label><FormattedMessage id="ingredientdetails.form.stockarea.name"
                                                                      defaultMessage='Storage Area'/>
                                            </label>
                                            <FormattedMessage id="ingredientdetails.form.stockarea.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection search //scrolling 
                                                                  options={storageAreas && storageAreas.options} 
                                                                  value = {storage_area && storage_area.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onStorageAreaChange}
                                                                  style={{'font-size':'13px'}}
                                                                  clearable
                                                            />}
                                            </FormattedMessage>
                                          </Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredientdetails.form.stockarea.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.onUpdateStorageArea}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredientdetails.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.onUpdateStorageArea}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                                </Modal>   
                        </Grid.Column>   
                        <Grid.Column width={5}>
{/*                             {selected && selected.metrics &&
                                this.addFoodFacts(selected.metrics)} */}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </SideLeftUncoverIngredient>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { ingredients,supplier,storageAreas,alert } = state;
    const {loading,updating,default_supplier,extra_suppliers,recipes} = state.ingredients;
    return {
        ingredients,
        supplier,
        loading,
        updating,
        alert,
        default_supplier,
        extra_suppliers,
        recipes,
        storageAreas
    };
}


const connectedIngredientDetails = injectIntl(connect(mapStateToProps)(IngredientDetails));
export { connectedIngredientDetails as IngredientDetails };
 