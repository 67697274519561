import React, { Component } from 'react';
import { recipesActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form,Grid, Header, Table,Message,Image,Modal,Icon,Dimmer,Loader,Breadcrumb} from 'semantic-ui-react';
import {Capitalize,history,trim, unitCalories,unitWeight,unitVolume, 
  grams,mgrams,kgrams,liters,mliters,cliters,flounces,pounds,ounces,get_payload,isNumeric,isQuantity} from '../_helpers';
import "./NutritionFacts.scss";
import {FormattedMessage,injectIntl} from 'react-intl';
import SideLeftUncoverRecipe from './SidebarRecipe';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'
import ReactToPrint from 'react-to-print';
import * as htmlToImage from 'html-to-image';


class NutritionRecipe extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            prevRecipe : props.recipes,
            selected: null,
            activeModalNutrition: null,
            activeModalEditNutrition: null,
            inputText: '',
            //nutrient_ready: false,
            nutrient_data : {},
 /*           current_data : {
               calcium: "0 g",//mg
              calories: "0 kcal",//kcal
              cholesterol: "0 g",//g
              fatTotal: "0 g",//g
              fiber: "0 g",//g   ​
              iron: "0 g",//mg
              name: "",
              protein: "0 g",//g
              saturedFat: "0 g",//g
              sodium: "0 g",//g
              sugar: "0 g",//g
              totalCarbohydrates: "0 g",//g
              transFat: "0 g",//g
              type: "recipe",
              usda_name: "unknown",
              usda_ndbno: "unknown",
              vitaminA: "0 g",//mg
              vitaminC: "0 g",//mg
              weight: "0g",//g 
            },*/
            current_data_edit :{
/*               calcium: "0 g",//mg
              calories: "0 kcal",//kcal
              cholesterol: "0 g",//g
              fatTotal: "0 g",//g
              fiber: "0 g",//g   ​
              iron: "0 g",//mg
              name: "",
              protein: "0 g",//g
              saturedFat: "0 g",//g
              sodium: "0 g",//g
              sugar: "0 g",//g
              totalCarbohydrates: "0 g",//g
              transFat: "0 g",//g
              type: "recipe",
              usda_name: "unknown",
              usda_ndbno: "unknown",
              vitaminA: "0 g",//mg
              vitaminC: "0 g",//mg
              weight: "0g",//g */
            },
         }
        this.hideModal = this.hideModal.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveOnEdit = this.saveOnEdit.bind(this);
        props.dispatch(recipesActions.get(props.match.params.recipeId));
        props.dispatch(paymentActions.getCustomerMembership());
    }


   static getDerivedStateFromProps(props, state) {

      let result= {};
      let nbitems = 0;

     if((props.recipes!==state.prevRecipe) && (props.recipes.selected)) {
          const selected = props.recipes.selected;
          if (selected.metrics){
            Object.assign(result,{prevRecipe:props.recipes})
            //Object.assign(result,{current_data:selected.metrics})
            Object.assign(result,{current_data_edit:selected.metrics})
            nbitems +=1;
          }
      }

      if (nbitems > 0)
        return result; 
      return null;
    }

    componentDidMount() {
      const {intl,recipes} = this.props;
      let default_metric = (intl.locale == 'en')?'us':'france' 
      this.setState({ type_metrics: default_metric });
      if (recipes && recipes.selected && isQuantity(recipes.selected.yield_units)){
           this.setState({ type_unit: 'serving' });
      }else{
           this.setState({ type_unit: 'recipe' });
      }
     
    }


    hideModal() {
      this.setState({ activeModalNutrition: null });
      this.setState({ activeModalEditNutrition: null });
    }

    cancel() {
      //let recipeId = this.props.match.params.recipeId;
      //history.push('/');
      //history.push('/recipenutrition/'+recipeId);
      //history.goBack();
      window.location.reload(); 
    }

    saveOnEdit() {
     const {current_data_edit} = this.state;
      const {dispatch,selected} = this.props;
      let recipeId = this.props.match.params.recipeId;
      if (current_data_edit && selected ){
        
        Object.assign(current_data_edit,{ name:selected.name });

        const recipe = {
            name:trim(selected.name),
            yield_count: trim(selected.yield_count),
            yield_units: trim(selected.yield_units),
            category: selected.category,
            metrics:current_data_edit
        }
        //console.log(recipe)
        dispatch(recipesActions.update(recipeId,recipe,'/recipenutrition/'+recipeId, 1)); 
      }
    }

    handleEditNutrition(e) {
      this.setState({ activeModalEditNutrition: true});
      this.setState({ activeModalNutrition: null});
    }
        

    currentDataChange = (e,{value,id}) => {
      const {selected} = this.props;
      let current_data = selected.metrics;
      e.preventDefault();
      //this.setState({ current_data: {...this.state.current_data, [id]:value } })
      //this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value } })
      
      //let agnostic_value = value.replace(/,/g, '.');
      //let value_value = get_payload(agnostic_value,true)
      //let value_unit = get_payload(agnostic_value,false)
      let value_value = value.replace(/,/g, '.');

      if ( //!isEmpty(value_unit)
          /*&&*/ isNumeric(value_value) 
          /*&& ( grams.includes(value_unit) ||
               mgrams.includes(value_unit) ||
               unitCalories.includes(value_unit.toLowerCase())
             )*/
          ){
            if (id=='calories'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  'kcal' }})
            }else if (id=='fatTotal'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.fatTotal,false) }})
            }else if (id=='saturedFat'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.saturedFat,false) }})
            }else if (id=='transFat'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.transFat,false) }})
            }else if (id=='cholesterol'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.cholesterol,false) }})
            }else if (id=='sodium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.sodium,false) }})
            }else if (id=='totalCarbohydrates'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.totalCarbohydrates,false) }})
            }else if (id=='fiber'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.fiber,false) }})
            }else if (id=='sugar'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.sugar,false) }})
            }else if (id=='protein'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.protein,false) }})
            }else if (id=='vitaminA'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.vitaminA,false) }}) 
            }else if (id=='vitaminC'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.vitaminC,false) }})
            }else if (id=='calcium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.calcium,false) }})                        
            }else if (id=='iron'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.iron,false) }})
            }else if (id=='potassium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.potassium,false) }})
            }
            else{
              //this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:agnostic_value } })
            }
          }
    } 


    seeIngredient (event,index) {
      event.preventDefault();
      history.push('/ingredientdetails/'+index);
    }

    ingredientList(selected){
      return selected.ingredients.map((item,index) => {
            return (
                    <div style={{marginLeft:'2em'/*,'font-family': 'Lato', 'font-size': '12px','color': 'grey'*/}}>
                      <a href="#" onClick={(event) => this.seeIngredient(event,item.ingredient.id)}>
                        {Capitalize(item.ingredient.name)}
                      </a><br/>
                    </div>
            );
      });
    }

    editNutritionItemsList(){
      //const {current_data} = this.state;
      const {selected} = this.props;
      let current_data = selected.metrics;
      return  (
          <Grid.Row columns={2}>
            <Grid.Column>
                <Form>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.calories"
                                            defaultMessage='Calories'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.calories.placeholder"
                                       defaultMessage='calories'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="calories"
                                  defaultValue={get_payload(current_data.calories,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>kcal</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.totalfat"
                                             defaultMessage='Total Fat'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.totalfat.placeholder"
                                       defaultMessage='total fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="fatTotal"
                                  defaultValue={get_payload(current_data.fatTotal,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.fatTotal,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.saturedfat"
                                            defaultMessage='Satured Fat'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.saturedfat.placeholder"
                                            defaultMessage='satured fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="saturedFat"
                                  defaultValue={get_payload(current_data.saturedFat,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.saturedFat,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.transfat"
                                            defaultMessage='Trans Fat'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.transfat.placeholder"
                                            defaultMessage='Trans Fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="transFat"
                                  defaultValue={get_payload(current_data.transFat,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.transFat,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.cholesterol"
                                            defaultMessage='Cholesterol'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.cholesterol.placeholder"
                                            defaultMessage='cholesterol'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="cholesterol"
                                  defaultValue={get_payload(current_data.cholesterol,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.cholesterol,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.sodium"
                                            defaultMessage='Sodium'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.sodium.placeholder"
                                            defaultMessage='sodium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="sodium"
                                  defaultValue={get_payload(current_data.sodium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.sodium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.totalCarbohydrates"
                                            defaultMessage='Total Carbohydrates'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.totalCarbohydrates.placeholder"
                                            defaultMessage='total carbohydrates'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="totalCarbohydrates"
                                  defaultValue={get_payload(current_data.totalCarbohydrates,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.totalCarbohydrates,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <Form>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.fiber"
                                            defaultMessage='Fiber'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.fiber.placeholder"
                                            defaultMessage='Fiber'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="fiber"
                                  defaultValue={get_payload(current_data.fiber,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.fiber,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.sugar"
                                            defaultMessage='Sugars'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.sugar.placeholder"
                                            defaultMessage='sugars'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="sugar"
                                  defaultValue={get_payload(current_data.sugar,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.sugar,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.protein"
                                            defaultMessage='Protein'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.protein.placeholder"
                                            defaultMessage='Protein'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="protein"
                                  defaultValue={get_payload(current_data.protein,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.protein,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.vitaminA"
                                            defaultMessage='Vitamin A'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.vitaminA.placeholder"
                                            defaultMessage='vitamin A'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="vitaminA"
                                  defaultValue={get_payload(current_data.vitaminA,true)}
                                  onChange={this.currentDataChange}
                                style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.vitaminA,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.vitaminC"
                                            defaultMessage='Vitamin C'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.vitaminC.placeholder"
                                            defaultMessage='vitamin C'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="vitaminC"
                                  defaultValue={get_payload(current_data.vitaminC,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.vitaminC,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.calcium"
                                            defaultMessage='Calcium'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.calcium.placeholder"
                                            defaultMessage='calcium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="calcium"
                                  defaultValue={get_payload(current_data.calcium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.calcium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.iron"
                                            defaultMessage='Iron'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.iron.placeholder"
                                            defaultMessage='iron'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="iron"
                                  defaultValue={get_payload(current_data.iron,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.iron,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutritionrecipe.edit.list.potassium"
                                            defaultMessage='Potassium'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutritionrecipe.edit.list.potassium.placeholder"
                                            defaultMessage='potassium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="potassium"
                                  defaultValue={get_payload(current_data.potassium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.potassium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                </Form>
            </Grid.Column>
          </Grid.Row>
        );
  }


  computeFactor(recipe){
    let factor = 1;
    
    if (!recipe)
      return factor;

    if (unitWeight.includes(recipe.yield_units))
    {
        if (grams.includes(recipe.yield_units)){
          factor = 1/(recipe.yield_count /100) ;
        }
        if (mgrams.includes(recipe.yield_units)){
          factor = 1/(recipe.yield_count / 100000);
        }
        if (kgrams.includes(recipe.yield_units)){
          factor = 1/(recipe.yield_count * 10);
        }
        if (pounds.includes(recipe.yield_units)){ 
          factor = 1/(recipe.yield_count * 4.5359);
        }
        if (ounces.includes(recipe.yield_units)){
          factor = 1/(recipe.yield_count / 3.5274 );
        }

    }else if (unitVolume.includes(recipe.yield_units))
    {
      if (liters.includes(recipe.yield_units)){
        factor = 1/(recipe.yield_count*10);
      }
      if ( mliters.includes(recipe.yield_units)){
        factor = 1/(recipe.yield_count/100);
      }
      if ( cliters.includes(recipe.yield_units)){
        factor = 1/(recipe.yield_count/10);
      }
      if ( flounces.includes(recipe.yield_units)){
        factor = 1/(recipe.yield_count/3.3814);
      }
    }else{
      //Case recipe (use yield or 100g/100ml)

      //Case recipe (use 100g or 100ml standard)
      if (unitWeight.includes(recipe.weight_unit))
      {
        if (grams.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value /100) ;
        }
        if (mgrams.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value / 100000);
        }
        if (kgrams.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value * 10);
        }
        if (pounds.includes(recipe.weight_unit)){ 
          factor = 1/(recipe.weight_total_value * 4.5359);
        }
        if (ounces.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value / 3.5274 );
        }

      }else if (unitVolume.includes(recipe.weight_unit))
      {
        if (liters.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value*10);
        }
        if ( mliters.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value/100);
        }
        if ( cliters.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value/10);
        }
        if ( flounces.includes(recipe.weight_unit)){
          factor = 1/(recipe.weight_total_value/3.3814);
        }
      }else{
         factor = 1/recipe.yield_count;
      }

    }
     if (!isFinite(factor)){
        factor = 0
      }
      return factor;
  }
    
  extractVNRPerYield(field, unit, average,recipe){
    let value = field.split(unit)[0]
    let factor = this.computeFactor(recipe);
    if (value>0 && recipe.yield_count > 0){
      return Number(((value / average)*factor) * 100).toFixed(0)
    }
    return 0;
  }

  extractVNRPerServing(field, unit, average,recipe){
    let value = field.split(unit)[0]
    if (value>0 && recipe.yield_count > 0){
      return   Number(( (value/recipe.yield_count) / average) * 100).toFixed(0)
    }
    return 0;
  }

  extractVNRPerRecipe(field, unit, average,recipe){
    let value = field.split(unit)[0]
    if (value>0 ){
      return   Number((value / average) * 100).toFixed(0)
    }
    return 0;
  }


  extractYieldType(recipe){
    let title = 'par portion'

    if (unitWeight.includes(recipe.yield_units)){
      title = 'pour 100g'
    }else if (unitVolume.includes(recipe.yield_units))
    {
      title = 'pour 100ml'
    }else{
        //Case recipe (use 100g or 100ml standard)
        if (unitWeight.includes(recipe.weight_unit)){
          title = 'pour 100g'
        }else if (unitVolume.includes(recipe.weight_unit))
        {
          title = 'pour 100ml'
        }
    }
    return title;
  }

  extractYieldTypeEnFr(recipe){
    let title = 'per serving / par portion'

    if (unitWeight.includes(recipe.yield_units)){
      title = 'per 100g / pour 100g'
    }else if (unitVolume.includes(recipe.yield_units))
    {
      title = 'per 100ml / pour 100ml'
    }else{
        //Case recipe (use 100g or 100ml standard)
        if (unitWeight.includes(recipe.weight_unit)){
          title = 'per 100g / pour 100g'
        }else if (unitVolume.includes(recipe.weight_unit))
        {
          title = 'per 100ml / pour 100ml'
        }
    }
    return title;
  }

  extractYieldTypeEn(recipe){
    let title = 'per serving'

    if (unitWeight.includes(recipe.yield_units)){
      title = 'per 100g'
    }else if (unitVolume.includes(recipe.yield_units))
    {
      title = 'per 100ml'
    }else{
        //Case recipe (use 100g or 100ml standard)
        if (unitWeight.includes(recipe.weight_unit)){
          title = 'per 100g'
        }else if (unitVolume.includes(recipe.weight_unit))
        {
          title = 'per 100ml'
        }
    }
    return title;
  }

  extractPayload(field, unit, average){
    let value = field.split(unit)[0] 
    if (value>0){
      return Number((value / average) * 100).toFixed(0)
    }
    return 0;
  }

  extractPayloadPerYield(field,unit,recipe,rounding){
    let value = field.split(unit)[0] 
    let factor = this.computeFactor(recipe);
    //console.log(factor)
    if (value>0 && recipe.yield_count > 0){
      return Number(value*factor).toFixed(rounding) + unit
    }
    return 0;
  }

  extractPayloadPerServing(field,unit,recipe,rounding){
    let value = field.split(unit)[0] 
    if (value>0 && recipe.yield_count > 0){
      return Number(value/recipe.yield_count).toFixed(rounding) + unit
    }
    return 0;
  }

  
  addFoodFactsFR(data){
    const {selected} = this.props.recipes;
    const {type_unit} = this.state;

    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)
    let factor = this.computeFactor(selected);
    //console.log(selected)

    return(
      <div id={'label-node'} style={{'backgroundColor':'white'}}>
      <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
      <Table compact striped unstackable singleline color='grey' 
             style={{'font-family': 'Lato', 'font-size': '14px','color': 'grey','marginLeft':'0.5em','marginLeft':'0.2em', 'backgroundColor':'white'}}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell  width={3}></Table.HeaderCell>
          <Table.HeaderCell  width={2} textAlign='right'>{this.extractYieldType(selected)}</Table.HeaderCell>
          {type_unit == 'recipe' &&
          <Table.HeaderCell  width={3} textAlign='right'>par recette</Table.HeaderCell>}
          {type_unit == 'serving' &&
           <Table.HeaderCell  width={3} textAlign='right'>par portion ({selected.weight_unit_value}{' '}{selected.weight_unit})</Table.HeaderCell>}
          <Table.HeaderCell  width={2} textAlign='right'>%VNR*</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell width={3} style={styles.FontLato14Bold}><b>Energie</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.calories,"kcal",selected,0)} ({Number(energyKJ*factor).toFixed(0)}kJ)</Table.Cell>
          {type_unit == 'recipe' &&
           <Table.Cell width={3} textAlign='right'>{data.calories} ({energyKJ}kJ)</Table.Cell> }
          {type_unit == 'serving' &&
           <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.calories,"kcal",selected,0)} ({Number(energyKJ/selected.yield_count).toFixed(0)}kJ)</Table.Cell>}
           {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.calories,"kcal",2000,selected)}%</Table.Cell>}
           {type_unit == 'serving' && 
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.calories,"kcal",2000,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell width={3} style={styles.FontLato14Bold}><b>Matières Grasses</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.fatTotal,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' &&
          <Table.Cell width={3} textAlign='right'>{data.fatTotal}</Table.Cell>}
          {type_unit == 'serving' && 
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.fatTotal,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.fatTotal,"g",70,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.fatTotal,"g",70,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato12}>{'  '}dont acides gras saturés</Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.saturedFat,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' &&
          <Table.Cell textAlign='right'>{data.saturedFat}</Table.Cell>}
           {type_unit == 'serving' && 
           <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.saturedFat,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.saturedFat,"g",20,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.saturedFat,"g",20,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Glucides</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.totalCarbohydrates,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' && 
          <Table.Cell textAlign='right'>{data.totalCarbohydrates}</Table.Cell>}
          {type_unit == 'serving' &&
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.totalCarbohydrates,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.totalCarbohydrates,"g",260,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.totalCarbohydrates,"g",260,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato12}>{'  '} dont sucres</Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.sugar,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' &&
          <Table.Cell textAlign='right'>{data.sugar}</Table.Cell>}
          {type_unit == 'serving' && 
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.sugar,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.sugar,"g",90,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.sugar,"g",90,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Fibres</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.fiber,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' && 
          <Table.Cell textAlign='right'>{data.fiber}</Table.Cell>}
          {type_unit == 'serving' && 
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.fiber,"g",selected,1)}</Table.Cell>}
          <Table.Cell textAlign='right'>-</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato1Bo4ld}><b>Protéines</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.protein,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' &&
          <Table.Cell textAlign='right'>{data.protein}</Table.Cell>}
          {type_unit == 'serving' && 
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.protein,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.protein,"g",90,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.protein,"g",90,selected)}%</Table.Cell>}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Sel</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayloadPerYield(data.sodium,"g",selected,1)}</Table.Cell>
          {type_unit == 'recipe' &&
          <Table.Cell textAlign='right'>{data.sodium}</Table.Cell>}
          {type_unit == 'serving' && 
          <Table.Cell width={3} textAlign='right'>{this.extractPayloadPerServing(data.sodium,"g",selected,1)}</Table.Cell>}
          {type_unit == 'recipe' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerRecipe(data.sodium,"g",6,selected)}%</Table.Cell>}
           {type_unit == 'serving' &&
          <Table.Cell width={2} textAlign='right'>{this.extractVNRPerServing(data.sodium,"g",6,selected)}%</Table.Cell>}
        </Table.Row>
      </Table.Body>
      <Table.Footer>
          <Table.Row fullwidth width={8}>
             <Table.Cell textAlign='left' colSpan='3'>
              <p>* Pourcentage de la valeur quotidienne selon un régime alimentaire de 2000 calories. 
              Vos valeurs quotidiennes personnelles peuvent être plus ou moins élevées selon vos besoins énérgetiques</p>
            </Table.Cell>
          </Table.Row>
      </Table.Footer>
    </Table>
    </div>
    )
  }

  addFoodFactsCA(data){
      const {intl} = this.props;
      const {type_unit} = this.state;
      const {selected} = this.props.recipes;

      var energyKJ = data.calories.split("kcal")
      energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

        return (
          <div id={'label-node'}  class="performance-facts_around" style={{'backgroundColor':'white'}}>
          <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
          <section class="performance-facts"
                   style={{'font-size': '12px','color': 'black','marginLeft':'0.2em', 'backgroundColor':'white'}} >
            <header /* class="performance-facts__header" */>
              <h1 class="performance-facts__title">Nutrition Facts</h1>
              <h1 class="performance-facts__title">Valeurs nutritives</h1>
            </header>
            <table class="performance-facts__table">
              <thead>
                <tr>
                  {type_unit == 'recipe' &&
                  <th colspan="3" /* class="small-info" */>Per recipe / Par recette</th>}
                  {type_unit == 'default' &&
                  <th colspan="3" /* class="small-info" */>{this.extractYieldTypeEnFr(selected)}</th>}
                  {type_unit == 'serving' &&
                  <th colspan="3" /* class="small-info" */>Per serving / Par portion ({selected.weight_unit_value}{' '}{selected.weight_unit})</th>}
                  </tr> 
              </thead>
              <tbody>
                <tr>
                  <th colspan="2"  style={{'font-family': 'verdana', 'font-size': '15px'}}>
                    {type_unit == 'recipe' &&
                      <span><b>Calories</b> {data.calories} ({energyKJ}kJ)</span>}
                    {type_unit == 'default' &&
                      <span><b>Calories</b> {this.extractPayloadPerYield(data.calories,"kcal",selected,0)}({energyKJ}kJ)</span>}
                    {type_unit == 'serving' &&
                      <span><b>Calories</b> {this.extractPayloadPerServing(data.calories,"kcal",selected,0)}({Number(energyKJ/selected.yield_count).toFixed(0)}kJ)</span>}
                  </th>
                </tr>
                <tr class="thick-row">
                  <td colspan="3" class="small-info">
                    <b>% Daily value*</b><br/>
                    <b>% Valeur quotidienne*</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    {type_unit == 'recipe' &&
                      <span><b>Fat / Lipides</b>  {data.fatTotal}</span>}
                    {type_unit == 'default' &&
                      <span><b>Fat / Lipides</b> {this.extractPayloadPerYield(data.fatTotal,"g",selected,1)}</span>}
                    {type_unit == 'serving' &&
                      <span><b>Fat / Lipides</b> {this.extractPayloadPerServing(data.fatTotal,"g",selected,1)}</span>}
                  </th>
                  <td >
                  {type_unit == 'recipe' &&
                  <span>{this.extractVNRPerRecipe(data.fatTotal,"g",70,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.fatTotal,"g",70,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.fatTotal,"g",70,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    {/* dont saturé {data.saturedFat} */}
                    {type_unit == 'recipe' &&
                      <span> Saturated / Saturé  {data.saturedFat}</span>}
                    {type_unit == 'default' &&
                      <span> Saturated / Saturé  {this.extractPayloadPerYield(data.saturedFat,"g",selected,1)}</span>}
                      {type_unit == 'serving' &&
                      <span> Saturated / Saturé  {this.extractPayloadPerServing(data.saturedFat,"g",selected,1)}</span>}
                  </th>
                  <td class="blank-cell">
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.saturedFat,"g",20,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.saturedFat,"g",20,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.saturedFat,"g",20,selected)}%</span>}
                  </td>
                </tr>
                <tr > 
                  <th colspan="2">
                    {/* <b>Glucides</b> {data.totalCarbohydrates} */}
                    {type_unit == 'recipe' &&
                      <span><b>Carbohydrates / Glucides</b> {data.totalCarbohydrates}</span>}
                      {type_unit == 'default' &&
                      <span><b>Carbohydrates / Glucides</b> {this.extractPayloadPerYield(data.totalCarbohydrates,"g",selected,1)}</span>} 
                      {type_unit == 'serving' &&
                      <span><b>Carbohydrates / Glucides</b> {this.extractPayloadPerServing(data.totalCarbohydrates,"g",selected,1)}</span>} 
                  </th>
                  <td> 
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.totalCarbohydrates,"g",260,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.totalCarbohydrates,"g",260,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.totalCarbohydrates,"g",260,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    {/* sucres {data.sugar} */}
                    {type_unit == 'recipe' &&
                    <span>Sugars / Sucres {data.sugar}</span>}
                    {type_unit == 'default' &&
                    <span>Sugars / Sucres  {this.extractPayloadPerYield(data.sugar,"g",selected,1)}</span>} 
                    {type_unit == 'serving' &&
                    <span>Sugars / Sucres  {this.extractPayloadPerServing(data.sugar,"g",selected,1)}</span>} 
                  </th>
                  <td class="blank-cell">
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.sugar,"g",90,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.sugar,"g",90,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.sugar,"g",90,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    {/* sucres {data.sugar} */}
                    {type_unit == 'recipe' &&
                    <span>Fibre / Fibres {data.fiber}</span>}
                    {type_unit == 'default' &&
                    <span>Fibre / Fibres  {this.extractPayloadPerYield(data.fiber,"g",selected,1)}</span>} 
                    {type_unit == 'serving' &&
                    <span>Fibre / Fibres  {this.extractPayloadPerServing(data.fiber,"g",selected,1)}</span>} 
                  </th>
                  <td class="blank-cell">
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.fiber,"g",25,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.fiber,"g",25,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.fiber,"g",25,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                   {/*  <b>Protéines</b> {data.protein} */}
                    {type_unit == 'recipe' &&
                    <span><b> Protein / Protéines</b> {data.protein}</span>}
                    {type_unit == 'default' &&
                    <span><b> Protein / Protéines</b> {this.extractPayloadPerYield(data.protein,"g",selected,1)}</span>} 
                    {type_unit == 'serving' &&
                    <span><b> Protein / Protéines</b> {this.extractPayloadPerServing(data.protein,"g",selected,1)}</span>} 
                  </th>
                  <td >
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.protein,"g",90,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.protein,"g",90,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.protein,"g",90,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    {/* <b>Sel</b> {data.sodium} */}
                    {type_unit == 'recipe' &&
                      <span><b>Cholesterol / Cholestérol</b> {data.cholesterol}</span>}
                    {type_unit == 'default' &&
                      <span><b>Cholesterol / Cholestérol</b> {this.extractPayloadPerYield(data.cholesterol,"g",selected,1)}</span>}
                      {type_unit == 'serving' &&
                      <span><b>Cholesterol / Cholestérol</b> {this.extractPayloadPerServing(data.cholesterol,"g",selected,1)}</span>}
                  </th>
                  <td>
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.cholesterol,"g",300,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.cholesterol,"g",300,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.cholesterol,"g",300,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    {/* <b>Sel</b> {data.sodium} */}
                    {type_unit == 'recipe' &&
                      <span><b>Sodium / Sel</b> {data.sodium}</span>}
                    {type_unit == 'default' &&
                      <span><b>Sodium / Sel</b> {this.extractPayloadPerYield(data.sodium,"g",selected,1)}</span>}
                      {type_unit == 'serving' &&
                      <span><b>Sodium / Sel</b> {this.extractPayloadPerServing(data.sodium,"g",selected,1)}</span>}
                  </th>
                  <td>
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.sodium,"g",6,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.sodium,"g",6,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.sodium,"g",6,selected)}%</span>}
                  </td>
                </tr>
                 <tr class="thick-row">
                  <th colspan="2">
                    {type_unit == 'recipe' &&
                      <span>Potassium {data.potassium}</span>}
                    {type_unit == 'default' &&
                      <span>Potassium {this.extractPayloadPerYield(data.potassium,"g",selected,3)}</span>}
                      {type_unit == 'serving' &&
                      <span>Potassium {this.extractPayloadPerServing(data.potassium,"g",selected,3)}</span>}
                  </th>
                  <td>
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.potassium,"g",3.5,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.potassium,"g",3.5,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.potassium,"g",3.5,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    {type_unit == 'recipe' &&
                      <span>Calcium {data.calcium}</span>}
                    {type_unit == 'default' &&
                      <span>Calcium {this.extractPayloadPerYield(data.calcium,"g",selected,3)}</span>}
                      {type_unit == 'serving' &&
                      <span>Calcium {this.extractPayloadPerServing(data.calcium,"g",selected,3)}</span>}
                  </th>
                  <td>
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.calcium,"g",1,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.calcium,"g",1,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.calcium,"g",1,selected)}%</span>}
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    {type_unit == 'recipe' &&
                      <span>Iron / Fer {data.iron}</span>}
                    {type_unit == 'default' &&
                      <span>Iron / Fer {this.extractPayloadPerYield(data.iron,"g",selected,3)}</span>}
                      {type_unit == 'serving' &&
                      <span>Iron / Fer {this.extractPayloadPerServing(data.iron,"g",selected,3)}</span>}
                  </th>
                  <td>
                  {type_unit == 'recipe' &&
                    <span>{this.extractVNRPerRecipe(data.iron,"g",0.018,selected)}%</span>}
                  {type_unit == 'default' &&
                    <span>{this.extractVNRPerYield(data.iron,"g",0.018,selected)}%</span>}
                  {type_unit == 'serving' &&
                    <span>{this.extractVNRPerServing(data.iron,"g",0.018,selected)}%</span>}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="performance-facts__table--grid">
            <tr class="thick-row">
              <th colspan="1">
               <span class="small-info">* 5% or less is  <b>a little</b>, 15% or more is  <b>a lot</b></span><br/>
               <span class="small-info">* 5% ou moins c'est <b>peu</b>, 15% ou plus c'est  <b>beaucoup</b></span>
               </th>
            </tr>
            </table>
           </section>
           </div>
        )
  }

  addFoodFactsUS(data){
    const {intl} = this.props;
    const {type_unit} = this.state;  
    const {selected} = this.props.recipes;

    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

    return (
        <div id={'label-node'}  class="performance-facts_around" style={{'backgroundColor':'white'}}>
        <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
        <section class="performance-facts" 
                 style={{'font-family': 'verdana', 'font-size': '11px','color': 'grey','marginLeft':'0.2em', 'backgroundColor':'white'}}>
          <header class="performance-facts__header">
            <h1 class="performance-facts__title">Nutrition Facts</h1>
{/*                 <p>Serving Size 1/2 cup (about 82g)</p>
            <p>Serving Per Container 8</p> */}
          </header>
          <table class="performance-facts__table">
            <thead>
              <tr>
                {type_unit == 'recipe' &&
                <th colspan="3" class="small-info">Amount per recipe</th>}
                {type_unit == 'default' &&
                <th colspan="3" class="small-info">Amount {this.extractYieldTypeEn(selected)}</th>}
                {type_unit == 'serving' &&
                <th colspan="3" class="small-info">Amount per serving ({selected.weight_unit_value}{' '}{selected.weight_unit})</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">
                {type_unit == 'recipe' &&
                  <span><b>Calories</b> {data.calories}</span>}
                 {type_unit == 'default' &&
                  <span><b>Calories</b> {this.extractPayloadPerYield(data.calories,"kcal",selected,0)}</span>}
                 {type_unit == 'serving' &&
                  <span><b>Calories</b> {this.extractPayloadPerServing(data.calories,"kcal",selected,0)}</span>}
                </th>
                <td>
                  {/* Calories from Fat 0 */}
                </td>
              </tr>
              <tr class="thick-row">
                <td colspan="3" class="small-info">
                  <b>% Daily Value*</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                 {/*  <b>Total Fat</b> {data.fatTotal} */}
                  {type_unit == 'recipe' &&
                  <span><b>Total Fat</b>  {data.fatTotal}</span>}
                 {type_unit == 'default' &&
                  <span><b>Total Fat</b> {this.extractPayloadPerYield(data.fatTotal,"g",selected,1)}</span>}
                  {type_unit == 'serving' &&
                  <span><b>Total Fat</b> {this.extractPayloadPerServing(data.fatTotal,"g",selected,1)}</span>}
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.fatTotal,"g",65,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.fatTotal,"g",65,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.fatTotal,"g",65,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <td class="blank-cell"></td>
                <th>
                  {/* Saturated Fat {data.saturedFat} */}
                  {type_unit == 'recipe' &&
                  <span>Saturated Fat {data.saturedFat}</span>}
                 {type_unit == 'default' &&
                  <span>Saturated Fat {this.extractPayloadPerYield(data.saturedFat,"g",selected,1)}</span>}
                  {type_unit == 'serving' &&
                  <span>Saturated Fat {this.extractPayloadPerServing(data.saturedFat,"g",selected,1)}</span>}
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.saturedFat,"g",20,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.saturedFat,"g",20,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.saturedFat,"g",20,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                 {/*  Trans Fat {data.transFat} */}
                  {type_unit == 'recipe' &&
                  <span>Trans Fat {data.transFat}</span>}
                 {type_unit == 'default' &&
                  <span>Trans Fat {this.extractPayloadPerYield(data.transFat,"g",selected,1)}</span>}
                  {type_unit == 'serving' &&
                  <span>Trans Fat {this.extractPayloadPerServing(data.transFat,"g",selected,1)}</span>}
                </th>
                <td>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  {/* <b>Cholesterol</b> {data.cholesterol} */}
                  {type_unit == 'recipe' &&
                  <span><b>Cholesterol </b> {data.cholesterol}</span>}
                 {type_unit == 'default' &&
                  <span><b>Cholesterol </b> {this.extractPayloadPerYield(data.cholesterol,"g",selected,1)}</span>}
                 {type_unit == 'serving' &&
                  <span><b>Cholesterol </b> {this.extractPayloadPerServing(data.cholesterol,"g",selected,1)}</span>}
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.cholesterol,"g",300,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.cholesterol,"g",300,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.cholesterol,"g",300,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  {/* <b>Sodium</b> {data.sodium} */}
                  {type_unit == 'recipe' &&
                  <span><b>Sodium</b> {data.sodium}</span>}
                 {type_unit == 'default' &&
                  <span><b>Sodium</b> {this.extractPayloadPerYield(data.sodium,"g",selected,1)}</span>}
                  {type_unit == 'serving' &&
                  <span><b>Sodium</b> {this.extractPayloadPerServing(data.sodium,"g",selected,1)}</span>}
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.sodium,"g",2.4,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.sodium,"g",2.4,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.sodium,"g",2.4,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  {/* <b>Total Carbohydrate</b> {data.totalCarbohydrates} */}
                  {type_unit == 'recipe' &&
                  <span><b>Total Carbohydrate</b> {data.totalCarbohydrates}</span>}
                  {type_unit == 'default' &&
                  <span><b>Total Carbohydrate</b> {this.extractPayloadPerYield(data.totalCarbohydrates,"g",selected,1)}</span>} 
                  {type_unit == 'serving' &&
                  <span><b>Total Carbohydrate</b> {this.extractPayloadPerServing(data.totalCarbohydrates,"g",selected,1)}</span>} 
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.totalCarbohydrates,"g",300,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.totalCarbohydrates,"g",300,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.totalCarbohydrates,"g",300,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  {/* Dietary Fiber {data.fiber} */}
                  {type_unit == 'recipe' &&
                  <span> Dietary Fiber  {data.fiber}</span>}
                  {type_unit == 'default' &&
                  <span> Dietary Fiber  {this.extractPayloadPerYield(data.fiber,"g",selected,1)}</span>} 
                  {type_unit == 'serving' &&
                  <span> Dietary Fiber  {this.extractPayloadPerServing(data.fiber,"g",selected,1)}</span>} 
                </th>
                <td>
                {type_unit == 'recipe' &&
                  <b>{this.extractVNRPerRecipe(data.fiber,"g",25,selected)}%</b>}
                {type_unit == 'default' &&
                  <b>{this.extractVNRPerYield(data.fiber,"g",25,selected)}%</b>}
                {type_unit == 'serving' &&
                  <b>{this.extractVNRPerServing(data.fiber,"g",25,selected)}%</b>}
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  {/* Sugars {data.sugar} */}
                  {type_unit == 'recipe' &&
                  <span>Sugars {data.sugar}</span>}
                  {type_unit == 'default' &&
                  <span>Sugars {this.extractPayloadPerYield(data.sugar,"g",selected,1)}</span>} 
                  {type_unit == 'serving' &&
                  <span>Sugars {this.extractPayloadPerServing(data.sugar,"g",selected,1)}</span>} 
                </th>
                <td>
                </td>
              </tr>
              <tr class="thick-end">
                <th colspan="2">
                 {/*  <b>Protein</b> {data.protein} */}
                  {type_unit == 'recipe' &&
                  <span><b> Protein</b> {data.protein}</span>}
                  {type_unit == 'default' &&
                  <span><b> Protein</b> {this.extractPayloadPerYield(data.protein,"g",selected,1)}</span>} 
                  {type_unit == 'serving' &&
                  <span><b> Protein</b> {this.extractPayloadPerServing(data.protein,"g",selected,1)}</span>} 
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="performance-facts__table--grid">
            <tbody>
              <tr>
                <td colspan="2">
                 {/*  Vitamin A {data.vitaminA} */}
                 {type_unit == 'recipe' &&
                  <span> Vitamin A {data.vitaminA}</span>}
                  {type_unit == 'default' &&
                  <span> Vitamin A {this.extractPayloadPerYield(data.vitaminA,"g",selected,3)}</span>} 
                  {type_unit == 'serving' &&
                  <span> Vitamin A {this.extractPayloadPerServing(data.vitaminA,"g",selected,3)}</span>} 
                </td>
                <td>
                  {/* Vitamin C {data.vitaminC} */}
                  {type_unit == 'recipe' &&
                  <span> Vitamin C {data.vitaminC}</span>}
                  {type_unit == 'default' &&
                  <span> Vitamin C {this.extractPayloadPerYield(data.vitaminC,"g",selected,3)}</span>} 
                  {type_unit == 'serving' &&
                  <span> Vitamin C {this.extractPayloadPerServing(data.vitaminC,"g",selected,3)}</span>} 
                </td>
              </tr>
              <tr class="thin-end">
                <td colspan="2">
                  {/* Calcium {data.calcium} */}
                  {type_unit == 'recipe' &&
                  <span>Calcium {data.calcium}</span>}
                  {type_unit == 'default' &&
                  <span>Calcium {this.extractPayloadPerYield(data.calcium,"g",selected,3)}</span>}
                  {type_unit == 'serving' &&
                  <span>Calcium {this.extractPayloadPerServing(data.calcium,"g",selected,3)}</span>} 
                </td>
                <td>
                 {/*  Iron {data.iron} */}
                  {type_unit == 'recipe' &&
                  <span> Iron {data.iron}</span>}
                  {type_unit == 'default' &&
                  <span> Iron {this.extractPayloadPerYield(data.iron,"g",selected,3)}</span>}
                  {type_unit == 'serving' &&
                  <span> Iron {this.extractPayloadPerServing(data.iron,"g",selected,3)}</span>} 
                </td>
              </tr>
            </tbody>
          </table>
         <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs:</p>
        <table class="performance-facts__table--small small-info">
          <thead>
            <tr>
              <td colspan="2"></td>
              <th>Calories:</th>
              <th>2,000</th>
              <th>2,500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="2">Total Fat</th>
              <td>Less than</td>
              <td>65g</td>
              <td>80g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th>Saturated Fat</th>
              <td>Less than</td>
              <td>20g</td>
              <td>25g</td>
            </tr>
            <tr>
              <th colspan="2">Cholesterol</th>
              <td>Less than</td>
              <td>300mg</td>
              <td>300 mg</td>
            </tr>
            <tr>
              <th colspan="2">Sodium</th>
              <td>Less than</td>
              <td>2,400mg</td>
              <td>2,400mg</td>
            </tr>
            <tr>
              <th colspan="3">Total Carbohydrate</th>
              <td>300g</td>
              <td>375g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th colspan="2">Dietary Fiber</th>
              <td>25g</td>
              <td>30g</td>
            </tr>
          </tbody>
        </table>
        <p class="small-info">
          Calories per gram:
        </p>
        <p class="small-info text-center">
          Fat 9
          &bull;
          Carbohydrate 4
          &bull;
          Protein 4
        </p>
      </section>
      </div>
      )  
}

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  onTypeMetricsChange = (e,data) => {
    e.preventDefault();
    const {recipes} = this.props;
    if (recipes && recipes.selected && isQuantity(recipes.selected.yield_units)){
      this.setState({ type_unit: 'serving' });
    }else{
          this.setState({ type_unit: 'recipe' });
    }
    this.setState({ type_metrics: data.value });

  }

  onTypeUnitChange = (e,data) => {
    e.preventDefault();
    this.setState({ type_unit: data.value });
  }

  htmlToImageConvert = () =>  {
    var node = document.getElementById('label-node');

    if (node){
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = 'label-nutrition.png';
          link.href = dataUrl;
          link.click();
        });
    }
  }

  render() {
    const { intl,customer,recipes,loading} = this.props;
    const {activeModalEditNutrition,type_metrics,type_unit} = this.state;

    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
      //console.log(user);
      //console.log(membership);
    }
    //console.log(this.state.current_data)
    return (
      <div>
     { loading &&
        <SideLeftUncoverRecipe>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverRecipe>
      }
      { membership && user && 
            (((membership.membership_type==="Free")||(membership.membership_type==="Professional")) && (user.bypass===false)) &&
        <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
          <Grid stackable style={styles.FontLato12}>
              <Grid.Row columns={1}>
                  <Breadcrumb style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="nutritionrecipe.link.dashboard"
                                                                  defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="nutritionrecipe.link.recipes"
                                                                defaultMessage='Recipes'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{recipes && recipes.selected && recipes.selected.name}</Breadcrumb.Section>   
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="nutritionrecipe.link.nutrition"
                                                                  defaultMessage='Nutrition'/></Breadcrumb.Section>               
                  </Breadcrumb>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={8}>
                          <Header as='h2'>
                              <Icon name='heartbeat' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="nutritionrecipe.header.title"
                                                    defaultMessage=' Recipe Nutrition Facts'/>
                                  <Header.Subheader>{recipes && recipes.selected && recipes.selected.name}</Header.Subheader>
                                </Header.Content>
                          </Header>
{/*                           <Header as='h3' color='blue' >
                             {recipes && recipes.selected && recipes.selected.name}
                          </Header> */}
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                  <Grid.Column  /*width={8}*/  mobile={14} tablet={12} computer={8}>
                      <br/>
                      <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                          <Image src={img_performance} size ='small' rounded/>
                          <br/>
                          <Message.Content style={{marginLeft:'1em'}}>
                              <Message.Header color='black'>
                                  <FormattedMessage id="nutritionrecipe.premium.message.upgradeplan"
                                                      defaultMessage='ENTERPRISE Feature'/>
                              </Message.Header>
                              <br/>
                              <FormattedMessage id="nutritionrecipe.premium.title.message.upgradeplan"
                                              defaultMessage='Your current plan: '/>{' '}{membership.name}   
                              <br/><br/>
                              <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                  <FormattedMessage id="nutritionrecipe.premium.button.chooseplan"
                                              defaultMessage='Choose premium plan'/>
                              </Button>                                      
                          </Message.Content>
                      </Message>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
        </SideLeftUncoverRecipe>
      }
      { membership && user &&
           ((membership.membership_type==="Entreprise") || (user.bypass===true)) &&  
      <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
         <Grid stackable style={styles.FontLato12}>
          <Grid.Row columns={1}>
                  <Breadcrumb style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="nutritionrecipe.link.dashboard"
                                                                  defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="nutritionrecipe.link.recipes"
                                                                defaultMessage='Recipes'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{recipes && recipes.selected && recipes.selected.name}</Breadcrumb.Section>   
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="nutritionrecipe.link.nutrition"
                                                                  defaultMessage='Nutrition'/></Breadcrumb.Section>               
                  </Breadcrumb>
          </Grid.Row>
          <Grid.Row columns={2}>
              <Grid.Column /*width={8}*/  mobile={14} tablet={12} computer={8}>
                    <Header as='h1'>
                              <Icon name='heartbeat' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="nutritionrecipe.header.title"
                                                    defaultMessage=' Recipe Nutrition Facts'/>
                                   <Header.Subheader>{recipes && recipes.selected && recipes.selected.name}</Header.Subheader>
                                </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue'>
                      {recipes && recipes.selected && recipes.selected.name}
                    </Header> */}
                    {/* <br/><br/> */}
                  <Form>
                  {recipes && recipes.selected &&
                   <Form.Field style={{paddingLeft:'2em'}} >
                     <Modal open={activeModalEditNutrition}
                                onClose={this.hideModal}
                                style={styles.Modal}
                                closeIcon>
                        <Modal.Header>
                                <FormattedMessage id="nutritionrecipe.edit.modal.title"
                                                  defaultMessage='Edit Nutrient'/> 
                        </Modal.Header>
                        <Modal.Content scrolling>
                        <Modal.Description>
                            <Header>
                                  <FormattedMessage id="nutritionrecipe.edit.modal.ingredient.name"
                                                    defaultMessage='For {name}'
                                                    values={{name:recipes.selected.name}}/>
                            </Header>
                            <br/>
                            <Grid stackable>
                             {recipes.selected.metrics 
                                && this.editNutritionItemsList()}
                            </Grid>
                          </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                             <Button  style={styles.ButtonGreen} 
                                  //basic color='teal' 
                                  onClick= {this.saveOnEdit}>
                                  <FormattedMessage id="nutritionrecipe.edit.modal.save"
                                                  defaultMessage='Save Nutrient Data'/> 
                              </Button>
                              <Button   style={styles.ButtonGrey} 
                                 //basic  color='grey'  
                                 onClick= {this.hideModal}>   
                                 <FormattedMessage id="nutritionrecipe.edit.modal.cancel"
                                                  defaultMessage='Cancel'/> 
                              </Button>
                           </Modal.Actions>
                    </Modal>
                  </Form.Field>}
                  {recipes && recipes.selected && recipes.selected.metrics &&
                    <div style={styles.FontLato14}>
                      <Form.Field>
                        <br/>
                        <label><FormattedMessage id="nutritionrecipe.form.select.nutrition.type"
                                                  defaultMessage='Nutrition tag format'/>
                        </label>
                        <FormattedMessage id="nutritionrecipe.form.select.nutrition.type.placeholder"
                                          defaultMessage='Select'>
                              {placeholder =>   
                                      <Form.Select  
                                              options={ [{key:'france',text:'FR (INCO)',value:'france'},
                                                         {key:'us'    ,text:'USA (FDA)',value:'us'},
                                                         {key:'canada',text:'CA (CANADA)',value:'canada'}] } 
                                              value = {type_metrics} 
                                              placeholder={placeholder} 
                                              style={{minWidth:'15em',maxWidth:'30em'}}
                                              onChange={this.onTypeMetricsChange}/>}
                        </FormattedMessage>    
                    </Form.Field> 
                    {type_metrics =='france' && !isQuantity(recipes.selected.yield_units) &&
                      <Form.Field>
                                  <br/>
                                  <label><FormattedMessage id="nutritionrecipe.form.select.nutrition.unit"
                                                           defaultMessage='Tag amount'/> </label>
                                     <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                   <FormattedMessage id="nutritionrecipe.form.select.nutrition.type.placeholder"
                                                     defaultMessage='Select'>
                                           {placeholder =>   
                                                   <Form.Input  value = {intl.formatMessage({id:'nutritionrecipe.form.select.option.recipe'})} 
                                                                placeholder={placeholder} 
                                                                style={{minWidth:'15em',maxWidth:'30em'}}/>}
                                  </FormattedMessage>   
                                  </div> 
                      </Form.Field>}
                      {type_metrics =='france' && isQuantity(recipes.selected.yield_units) &&
                      <Form.Field>
                                  <br/>
                                  <label><FormattedMessage id="nutritionrecipe.form.select.nutrition.unit"
                                                           defaultMessage='Tag amount'/> </label>
                                   <FormattedMessage id="nutritionrecipe.form.select.nutrition.type.placeholder"
                                                     defaultMessage='Select'>
                                           {placeholder =>   
                                                   <Form.Select  
                                                          options={ [{key:'serving',text:<FormattedMessage id="nutritionrecipe.form.select.option.serving"
                                                                                                           defaultMessage='Per serving'/>,value:'serving'},
                                                                      {key:'recipe',text: <FormattedMessage id="nutritionrecipe.form.select.option.recipe"
                                                                                                          defaultMessage='Per recipe'/>,value:'recipe'}
                                                                      ] } 
                                                          value = {type_unit} 
                                                          placeholder={placeholder} 
                                                          style={{minWidth:'15em',maxWidth:'30em'}}
                                                          onChange={this.onTypeUnitChange}/>}
                                  </FormattedMessage>    
                      </Form.Field>}
                    {type_metrics !=='france' && isQuantity(recipes.selected.yield_units) &&
                      <Form.Field>
                                  <br/>
                                  <label><FormattedMessage id="nutritionrecipe.form.select.nutrition.unit"
                                                           defaultMessage='Tag amount'/> </label>
                                   <FormattedMessage id="nutritionrecipe.form.select.nutrition.type.placeholder"
                                                     defaultMessage='Select'>
                                           {placeholder =>   
                                                   <Form.Select  
                                                          options={ [  {key:'serving',text:<FormattedMessage id="nutritionrecipe.form.select.option.serving"
                                                                                                             defaultMessage='Per serving'/>,value:'serving'},
                                                                        {key:'default',text:<FormattedMessage id="nutritionrecipe.form.select.option.default"
                                                                                                          defaultMessage='Per 100g/100ml'/>,value:'default'},
                                                                        {key:'recipe',text: <FormattedMessage id="nutritionrecipe.form.select.option.recipe"
                                                                                                          defaultMessage='Per recipe'/>,value:'recipe'},
                                                                        ] } 
                                                          value = {type_unit} 
                                                          placeholder={placeholder} 
                                                          style={{minWidth:'15em',maxWidth:'30em'}}
                                                          onChange={this.onTypeUnitChange}/>}
                                  </FormattedMessage>    
                      </Form.Field>}
                      {type_metrics !=='france' && !isQuantity(recipes.selected.yield_units) &&
                      <Form.Field>
                                  <br/>
                                  <label><FormattedMessage id="nutritionrecipe.form.select.nutrition.unit"
                                                           defaultMessage='Tag amount'/> </label>
                                   <FormattedMessage id="nutritionrecipe.form.select.nutrition.type.placeholder"
                                                     defaultMessage='Select'>
                                           {placeholder =>   
                                                   <Form.Select  
                                                          options={ [  {key:'default',text:<FormattedMessage id="nutritionrecipe.form.select.option.default"
                                                                                                          defaultMessage='Per 100g/100ml'/>,value:'default'},
                                                                        {key:'recipe',text: <FormattedMessage id="nutritionrecipe.form.select.option.recipe"
                                                                                                          defaultMessage='Per recipe'/>,value:'recipe'},] } 
                                                          value = {type_unit} 
                                                          placeholder={placeholder} 
                                                          style={{minWidth:'15em',maxWidth:'30em'}}
                                                          onChange={this.onTypeUnitChange}/>}
                                  </FormattedMessage>    
                      </Form.Field>}
                      <Form.Field>
                            <br/>
                            <label>
                                    <FormattedMessage id="nutritionrecipe.show.metrics.title"
                                                      defaultMessage='Current metrics'/> 
                            </label>
                            <div ref={el => (this.componentRef = el)}>
                            {type_metrics =='france' && 
                                  this.addFoodFactsFR(recipes.selected.metrics)}
                            {type_metrics =='canada' && 
                                  this.addFoodFactsCA(recipes.selected.metrics)}
                            {type_metrics =='us' &&
                                  this.addFoodFactsUS(recipes.selected.metrics)}
                            </div>
                      </Form.Field>
{/*                       {type_metrics =='france' && 
                            <Form.Field>
                                <br/>
                                <p style={styles.FontLato12}> Vitamin A {recipes.selected.metrics.vitaminA} Vitamin D {recipes.selected.metrics.vitaminD}</p>
                                <p style={styles.FontLato12}> Calcium {recipes.selected.metrics.calcium} Fer {recipes.selected.metrics.iron}</p>
                            </Form.Field> } */}
                      <Form.Field>
                            <br/>
                            <Button /* color='teal' */ style={styles.ButtonGreen}   
                                    onClick={(e) => {this.handleEditNutrition()}}
                                    /* style={{marginLeft:'1em'}} */
                                    disabled={!recipes.selected.metrics}>
                                      <FormattedMessage id="nutritionrecipe.edit.button.title"
                                                        defaultMessage='Edit nutrition facts (per recipe)'/>
                            </Button>
                            <Button /* color='teal' */ style={styles.ButtonBlue}   
                                    onClick={(e) => {this.htmlToImageConvert()}}
                                    /* style={{marginLeft:'1em'}} */
                                    disabled={!recipes.selected.metrics}>
                                      <FormattedMessage id="nutritionrecipe.download.button.title"
                                                        defaultMessage='Download tag as image'/>
                            </Button>
                            <ReactToPrint
                              trigger={() => {
                                        return  <Button /* color='grey'  */style={styles.ButtonBlue}   
                                                        disabled={!recipes.selected.metrics}
                                                        /* style={{marginLeft:'1em'}} */>
                                                    <FormattedMessage id="nutritionrecipe.print.button.title"
                                                                      defaultMessage='Print tag'/>
                                                </Button>;}}
                              content={() => this.componentRef}
                              pageStyle="@page { margin: 0;} html{ margin: 0;} body {margin: 0; transform: scale(.6);}"
                          />
                      </Form.Field>
                  </div>
                }
                {recipes && recipes.selected && !recipes.selected.metrics &&
                    <div /*style={styles.LabelGrey}*/>
                      <br/><br/>
                      {recipes.selected.ingredients && recipes.selected.ingredients.length == 0 &&
                      <div style={{marginLeft:'1em'}}>
                        <p /*style={styles.FontLato14}*/>
                              <FormattedMessage id="nutritionrecipe.nometrics.line1"
                                                defaultMessage='No nutrition associated to recipe {name}'
                                                values={{name:recipes.selected.name}}/>
                              <br/> <br/>                   
                              <Link to={'/editrecipe/'+ this.props.match.params.recipeId}>
                                <FormattedMessage id="nutritionrecipe.nometrics.line11"
                                                  defaultMessage='Add ingredient to your recipe'/></Link>
                        </p>
                      </div>}
                      {recipes.selected.ingredients && recipes.selected.ingredients.length > 0 &&
                        <div style={{marginLeft:'1em'}}>
                            <p /*style={{marginLeft:'1em','font-family': 'Lato', 'font-size': '14px','color': 'grey',}}*/> 
                                  <FormattedMessage id="nutritionrecipe.nometrics.line2"
                                                    defaultMessage='Fill nutrition of ingredient(s) to compute it automatically'/> 
                            </p>
                            {this.ingredientList(recipes.selected)}
                            <br/>
{/*                             <p >
                              <FormattedMessage id="nutritionrecipe.edit.info.manual"
                                              defaultMessage='Or set manually your nutrition values'/></p>
                            <br/>
                            <Button basic color='teal' 
                                        //style={{marginLeft:'1em'}} 
                                        onClick={(e) => {this.handleEditNutrition()}}
                                        >
                                        <FormattedMessage id="nutritionrecipe.edit.button.title"
                                                          defaultMessage='Edit nutrition facts'/>
                            </Button> */}
                        </div>
                      }
                    </div>
                }
                </Form>
            </Grid.Column>
{/*             <Grid.Column width={5}>
              <br/><br/><br/><br/><br/>
              <Image src="/assets/calories-diet.jpg" size='large'/>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </SideLeftUncoverRecipe>
      }
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { recipes, nutrition,dispatch} = state;
  const { selected,loading} = state.recipes;
  const { searching,retrieving} = state.nutrition;
  const { customer } = state.payment;
  return {
        recipes,
        nutrition,
        searching,
        retrieving,
        customer,
        selected,
        dispatch,
        loading
  };
}

const connectedNutritionRecipe = injectIntl(connect(mapStateToProps)(NutritionRecipe));
export { connectedNutritionRecipe as NutritionRecipe };
