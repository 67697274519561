import React, { Component } from 'react';
import { Table, Menu, Header, Grid, Button,Form,Popup,Pagination,Modal,Input,Dropdown,Dimmer,Loader,Icon,Message,Image,Breadcrumb} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ingredientsActions,categoryIngredientActions,supplierActions,paymentActions,storageAreaActions,alertActions} from '../_actions'
import './Ingredients.css';
import SideLeftUncoverIngredients from './SidebarIngredients';
import {history,toaster_dispatch,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,optimizeDecimalPart,trim,GUEST,isFloat,isNumeric,grams,mgrams,mliters,cliters} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,injectIntl} from 'react-intl';
import {isEmpty,deburr} from "lodash"
import {styles} from './Styles';
//import img_dressing from '../Assets/dressing-cooking-olive.jpg'
import img_bag from '../Assets/shopping-bag.png'
import img_performance from '../Assets/performance.png'

import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';
import OutsideClickHandler from 'react-outside-click-handler';

const options = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

const filterOptions = [
    {
      key: 'Category',
      text: <FormattedMessage id="ingredients.filter.type.category.text"
                              defaultMessage="Category"/>,
      value: 'Category',
     // label: { color: 'yellow', empty: true, circular: true },
      icon: {name:'sitemap',color:'yellow'}
    },
    {
      key: 'InternalRef',
      text: <FormattedMessage id="ingredients.filter.type.internalref.text"
                              defaultMessage="Internal code"/>,
      value: 'InternalRef',
      //label: { color: 'blue', empty: true, circular: true },
      icon: {name:'tag',color:'blue'}
    },
    {
      key: 'Supplier',
      text: <FormattedMessage id="ingredients.filter.type.supplier.text"
                              defaultMessage="Supplier"/>,
      value: 'Supplier',
      //label: { color: 'black', empty: true, circular: true },
      icon: {name:'shipping fast',color:'black'}
    },
    {
      key: 'SupplierRef',
      text: <FormattedMessage id="ingredients.filter.type.supplierref.text"
                              defaultMessage="Supplier code"/>,
      value: 'SupplierRef',
      //label: { color: 'red', empty: true, circular: true },
      icon: {name:'tag',color:'red'}
    },
    {
        key: 'StorageArea',
        text: <FormattedMessage id="ingredients.filter.type.storageArea.text"
                                defaultMessage="Storage area"/>,
        value: 'StorageArea',
        //label: { color: 'red', empty: true, circular: true },
        icon: {name:'warehouse',color:'grey'}
      }
]

const trigger = (
    <span>
      <Icon name='ellipsis vertical'  size='large' color='teal'/>
    </span>
  )

const optionsPlus = [
    { key: 'none', text: 'OPTIONS', value: 'none' },
/*     { key: 'view', text: 'Detailed view', value: 'view', icon: <Icon name='folder open outline' color='blue' /> }, */
    { key: 'edit', text: <FormattedMessage id="ingredients.menu.options.edit.text"
                                           defaultMessage="Edit"/>, value: 'edit', icon: <Icon name='edit outline' color='blue'/> },
    { key: 'history', text: <FormattedMessage id="ingredients.menu.options.history.text"
                                              defaultMessage="Edit"/>, value: 'history', icon: <Icon name='history' color='black'/> },
  /*   { key: 'clone', text: 'Duplicate', value: 'clone', icon: <Icon name='copy outline' color='yellow'/> }, */
    { key: 'delete', text: <FormattedMessage id="ingredients.menu.options.delete.text"
                                            defaultMessage="Delete"/>, value: 'delete', icon:<Icon name='trash alternate outline' color='red' /> },
]


class Ingredients extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { activeModal: null,
                       errorNotification: false,
                       filtering: false,
                       currency : userIn.user.currency ,
                       toggled:false,
                       isMobile: false,
                       page: 1,
                       itemsPerPage: 10,
                       selectionList:[],
                       actionSelected: "",
                       actionModal: false,
                       allowMultipleSelection:true,
                       typeSelected:'Category',
                       newPrice:0,
                    }

        this.hideModal = this.hideModal.bind(this);
        this.hideErrorNotification = this.hideErrorNotification.bind(this);
        this.showErrorNotification = this.showErrorNotification.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);
        this.filterTypeList=this.filterTypeList.bind(this);
        this.filterSupplierList=this.filterSupplierList.bind(this);
        this.filterSupplierRefList=this.filterSupplierRefList.bind(this);
        this.filterInternalRefList=this.filterInternalRefList.bind(this);
        this.filterStorageAreaList=this.filterStorageAreaList.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        //this.submitAction=this.submitAction.bind(this);
        this.cancelAction=this.cancelAction.bind(this);
        this.createIngredient = this.createIngredient.bind(this);
        this.createMultipleIngredients = this.createMultipleIngredients.bind(this);
        this.createRecipe = this.createRecipe.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        this.setPriceLayout = this.setPriceLayout.bind(this);
        this.setGlobalLayout = this.setGlobalLayout.bind(this);
        
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(ingredientsActions.getAll(true));
        props.dispatch(categoryIngredientActions.getAll());
        props.dispatch(supplierActions.getAll());
        props.dispatch(storageAreaActions.getAll());
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    onOptionsMenu = (e,data,index,ingredient_id) => {
        e.preventDefault();
        if (data && data.value === 'view'){
            this.seeIngredient(e,ingredient_id)
        }
        if (data && data.value === 'edit'){
            this.editIngredient(e,ingredient_id)
        }
        if (data && data.value === 'history'){
            this.historyIngredient(e,ingredient_id)
        }
        if (data && data.value === 'delete'){
            this.deleteClickHandler(e,index)
        }
    }

    setPriceLayout(event){
        event.preventDefault();
        this.setState({priceLayout: true});
    }

    setGlobalLayout(event){
        event.preventDefault();
        this.setState({priceLayout: false});
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({supplierSelected: null});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterNameList(event){
        event.preventDefault();

        if (this.props.ingredients && this.props.ingredients.items){

            if (event.target.value == ""){
                this.removeFilters(event);
                return;
            }

    /*           let updatedList = this.props.ingredients.items.filter((item) => {
            return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
            }); */

            let words = (event.target.value).normalize('NFKD').toLowerCase().split(' ')
            words = words.map(word => deburr(word))
            words = words.filter(item => item.length > 1);
            words = words.filter(item => item);
            let updatedList = this.props.ingredients.items.filter((item) => {
                    let clean_item_name = deburr( item.name)
                    return words.some(word => (clean_item_name).normalize('NFKD').toLowerCase().includes(word))
            });

            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({nameSelected: event.target.value});
            this.setState({categorySelected: ""});
            this.setState({ supplierSelected:null})
            this.setState({ selectionList:[]});
            this.setState({supplierCodeSelected: ""});
            this.setState({codeSelected: ""});
            this.setState({storageAreaSelected: ""});
        }
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesIngredient} = this.props;
        let category_id = data.value;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let elem = categoriesIngredient.options.find(category => category.value == category_id)
        if (elem){
            let updatedList = this.props.ingredients.items.filter((item) => {
/*                 if (item.category__name){
                 return item.category__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                } */
                if (item.category__id){
                 return item.category__id == elem.value
                }
                return null;
              });
      
              this.setState({newItems: updatedList});
              this.setState({ categorySelected: {id:category_id,
                                                 name: elem.text } });
             this.setState({filtering: true});
        }else{
            this.setState({ categorySelected:null})
            this.setState({filtering: false});
        }

        this.setState({ supplierSelected:null})
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterSupplierList(event,data){
        event.preventDefault();
        const {supplier} = this.props;
        let supplier_id = data.value;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let elem = supplier.options.find(supplier => supplier.value == supplier_id) 
        if (elem){
            let updatedList = this.props.ingredients.items.filter((item) => {
/*                 if (item.supplier__name){
                  return item.supplier__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                } */
                if (item.supplier__id){
                    return item.supplier__id == elem.value
                }
                return null;
            });

            this.setState({newItems: updatedList});
            this.setState({ supplierSelected: {id:supplier_id,
                                                name: elem.text } });
            this.setState({filtering: true});
        }else{
            this.setState({ supplierSelected:null})
            this.setState({filtering: false});
        }
        this.setState({nameSelected: ""});
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

     filterInternalRefList(event,data){
        event.preventDefault();

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.props.ingredients.items.filter((item) => {
          if (item.ref_internal){
           return item.ref_internal.toLowerCase().search(data.value.toLowerCase()) !== -1;
          }
          return null;
        });

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: ""});
        this.setState({ supplierSelected:null})
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({storageAreaSelected: ""});
        this.setState({codeSelected: data.value});
    }
    
    filterSupplierRefList(event,data){
        event.preventDefault();

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.props.ingredients.items.filter((item) => {
          if (item.ref_supplier){
           return item.ref_supplier.toLowerCase().search(data.value.toLowerCase()) !== -1;
          }
          return null;
        });

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: ""});
        this.setState({ supplierSelected:null})
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: data.value});
        this.setState({codeSelected: ""});
    }

    filterStorageAreaList(event,data){
        event.preventDefault();
        const {storageAreas} = this.props;
        let storage_area_id = data.value;

        if (data.value === ""){
            this.removeFilters(event);
            return;
        }

        let elem = storageAreas.options.find(s => s.value === storage_area_id) 

        if (elem){
            console.log(elem)
            let updatedList = this.props.ingredients.items.filter((item) => {
                if (item.stock_storage__id){
                    console.log(item.stock_storage__id)
                    return item.stock_storage__id === elem.value;
                }
                return null;
              });
      
              this.setState({newItems: updatedList});
              this.setState({ storageAreaSelected: {id:storage_area_id,
                                                    name: elem.text } });
              this.setState({filtering: true});
        }else{
            this.setState({ storageAreaSelected:null})
            this.setState({filtering: false});
        }
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({supplierSelected: ""});
        this.setState({categorySelected: null});
    }

    filterTypeList(event,data){
        event.preventDefault();
        this.setState({typeSelected: data.value});
        this.removeFilters(event);
    }

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && data.value === 'category'){
            this.setState({actionModalCategory: true});
        }

        if (selectionList.length > 0 && data.value === 'stockarea'){
            this.setState({actionModalStockArea: true});
        }

        if (selectionList.length > 0 && data.value === 'delete'){
            this.setState({actionModal: true});
        }
    }

/*     submitAction(event){
        event.preventDefault();
        //this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
        this.setState({actionModal: true});
    } */

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }


    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModal: index});
    }; 

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ actionModal: false })
        this.setState({ actionModalStockArea: false })
        this.setState({ actionModalCategory: false })
        //this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    createIngredient(){
        history.push('/newingredient/');
    }

    createMultipleIngredients(){
        history.push('/newingredients/');
    }


    createRecipe(){
        history.push('/newrecipe/');
    }

    createOrder(){
        history.push('/orders/');
    }

    hideErrorNotification() {
        const { dispatch } = this.props;
        this.setState({ errorNotification: false })
        this.setState({ activeModal: null })
        dispatch(alertActions.clear());
        window.location.reload();
    }

    showErrorNotification() {
        this.setState({ errorNotification: true })
    }

    seeIngredient (event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
        history.push('/ingredientdetails/'+index);
    }

    editIngredient(event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/editingredient'));
        history.push('/editingredient/'+index);
    }

    historyIngredient(event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/historyingredient'));
        history.push('/historyingredient/'+index);
    }

    deleteIngredient (event,index) {
        event.preventDefault();
        this.props.dispatch(ingredientsActions.delete(index,'/ingredients'))
    }
    
    onStorageAreaChange = (e,data) => {
        e.preventDefault();
        const {storageAreas} = this.props;
         if (data.value===""){
            this.setState({ storageAreaSelected: null });
         }else{
            let storage_area_id = data.value;
            let elem = storageAreas.options.find(s => s.value === storage_area_id)
            this.setState({ storageAreaSelected: {id:storage_area_id,
                                           name: elem.text } });
         }
    }

    onCategoryChange = (e,data) => {
        e.preventDefault();
        const {categoriesIngredient} = this.props;
         if (data.value===""){
            this.setState({ categorySelected: null });
         }else{
            let category_id = data.value;
            let elem = categoriesIngredient.options.find(s => s.value === category_id)
            this.setState({ categorySelected: {id:category_id,
                                           name: elem.text } });
         }
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(ingredientsActions.deleteSelect(selectionList,'/ingredients'))
        this.setState({ selectionList:[]});
    }

    actionSelectStorageArea = (event) => {
        event.preventDefault();
        const { selectionList,storageAreaSelected } = this.state;

        const params = {
            ids: selectionList,
            elem_set: storageAreaSelected?true:false,
            elem_id: storageAreaSelected?storageAreaSelected.id:-1,
        }
        this.props.dispatch(ingredientsActions.stockAreaSelect(params,'/ingredients'))
        console.log(params)
        this.setState({ selectionList:[]});
    }

    actionSelectCategory = (event) => {
        event.preventDefault();
        const { selectionList,categorySelected } = this.state;

        const params = {
            ids: selectionList,
            elem_set: categorySelected?true:false,
            elem_id: categorySelected?categorySelected.id:-1,
        }
        this.props.dispatch(ingredientsActions.categorySelect(params,'/ingredients'))
        console.log(params)
        this.setState({ selectionList:[]});
    }

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((ingredient,index) => {
                allList.push(ingredient.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    toaster(dispatch,alert){
        const { intl } = this.props;
        //this.setState({ errorNotification: false })
        this.setState({ activeModal: null });
        toaster_dispatch(dispatch,alert,intl);
    }

    assertPriceField(price){
        if ((isFloat(price) || isNumeric(price))
          && (price != "")
          && Math.trunc(price).toString().length<10)
          return true;
        return false;
    }
    

    editPriceHandlerInput (event,index,ingredient) {
        event.preventDefault();
        let selling_price = optimizeDecimalPart(Number(ingredient.price))

        this.setState({ newPrice: selling_price});

        //this.setState({ menu_recipe: recipe.id});
        this.setState({ editCellIngredientPrice: true});
        this.setState({ editCellIndexIngredientPrice: index});
    };


    onNewPrice = (e,item) => {
        //e.preventDefault();
        this.setState({ newPrice: e.target.value});
    }


    updatePriceOnEnter (event,ingredient) {
        const {newPrice,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
                let price_origin = ingredient.price;
                price_origin = Number(price_origin).toFixed(3)

                const title = <FormattedMessage id="ingredients.price.check.error.title"
                                                defaultMessage='Price update error'/>
                
                if (this.assertPriceField(price) && (price!=price_origin)){
                        if (active_role!= GUEST){

                            const ingredient_data = {
                                id:ingredient.id,
                                price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                            }

                            dispatch(ingredientsActions.updatePriceDefaultSupplier(ingredient.id,ingredient_data));
                            ingredient.price = price
                            setTimeout(() => {
                                dispatch(ingredientsActions.getAll(true));
                            },200);
                            
                        }else{
                            dispatch(alertActions.error({title:'ingredients.price.privilege.error.message',
                                                        content:'ingredients.price.check.error.title'}));  
                        }
                        this.setState({ editCellIngredientPrice: false });
                }else{
                    if (Math.trunc(price).toString().length>=9){
                       const content =  <FormattedMessage id="ingredients.price.check.error.message"
                                                         defaultMessage='Price field is invalid'/>
                        dispatch(alertActions.error({title:'ingredients.price.check.error.title',
                                                    content:'ingredients.price.check.error.message'}));   
                    }
                    this.setState({ editCellIngredientPrice: false });
                }
        }
    }


    updatePriceOnBlur (event,ingredient) {
        const {newPrice,active_role} = this.state;
        const {dispatch} = this.props;

        let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
        let price_origin = ingredient.price;
        price_origin = Number(price_origin).toFixed(3)

        const title = <FormattedMessage id="ingredients.price.check.error.title"
                                        defaultMessage='Price update error'/>
        
        if (this.assertPriceField(price) && (price!=price_origin)){
                if (active_role!= GUEST){

                    const ingredient_data = {
                        id:ingredient.id,
                        price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                    }

                    dispatch(ingredientsActions.updatePriceDefaultSupplier(ingredient.id,ingredient_data));
                    ingredient.price = price
                    setTimeout(() => {
                        dispatch(ingredientsActions.getAll(true));
                    },200);
        
                }else{
                    dispatch(alertActions.error({title:'ingredients.price.privilege.error.message',
                                                content:'ingredients.price.check.error.title'}));  
                }
                this.setState({ editCellIngredientPrice: false });
        }else{
            if (Math.trunc(price).toString().length>=9){
               const content =  <FormattedMessage id="ingredients.price.check.error.message"
                                                 defaultMessage='Price field is invalid'/>
                dispatch(alertActions.error({title:'ingredients.price.check.error.title',
                                            content:'ingredients.price.check.error.message'}));   
            }
            this.setState({ editCellIngredientPrice: false });
        }
    }


    currencySymbol(){    
        const {currency} = this.state;
        const {intl} = this.props;
        let output_currency_symbol = ''
    
        if (currency == 'None') 
            return output_currency_symbol
    
        let value = Intl.NumberFormat(intl.locale,{
                                                  style:'currency',
                                                  currency: currency,
                                                  currencyDisplay: 'symbol',
                                      }).format(0)
        value = value.replace(/,/g, '.');
        value +='' 
        value = value.replace('.','')
    
        let regexp = /\D+/g;
        let symbol = [...value.matchAll(regexp)];      
        
        if (symbol && symbol.length > 0)
          output_currency_symbol = symbol[0][0]
        
        return ''+output_currency_symbol
    }

    addIngredientsRows(ingredients){
        const {currency,allowMultipleSelection,priceLayout,editCellIngredientPrice,editCellIndexIngredientPrice,newPrice} = this.state;
        return ingredients.map((ingredient,index) => {
              let tax =  Number(ingredient.tax)
/*                 let price_per_unit = ingredient.price_per_unit
                let unit = ingredient.unit
                if (ingredient.default_supplier == false){
                        for (let s of ingredient.extra_suppliers){
                            if (s.default_supplier == true){
                                price_per_unit = s.price_per_unit
                                unit = s.unit
                                break
                            }
                        }
                }  */
                return (
                    <Table.Row key={index}>
                        <Table.Cell width={6} >
                           {allowMultipleSelection && <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,ingredient.id)}
                                   checked={this.isChecked(ingredient.id)}
                                   style={{'transform': 'scale(1.1)'}}
                                  />}
                            <span style={{marginLeft:'0.7em'}}>
                            <Icon name='shopping basket' color='teal'/>
                            <a href={"/ingredientdetails/"+ingredient.id} 
                               onClick={(event) => this.seeIngredient(event,ingredient.id)}
                               > {ingredient.name && ingredient.name.length >= 45 && 
                                ingredient.name.substring(0,45) + '..'}
                                {ingredient.name && ingredient.name.length < 45 && 
                                ingredient.name}
                            </a></span>
                            <p style={{'color':'#b5b1b1',marginLeft:'4em',fontSize:'10px'}}><i>{ingredient.ref_internal}</i>
                            {ingredient.weight_variation_active &&
                                <Popup trigger={<Button  compact floated='right'  
                                                        size='tiny'
                                                        color='teal'>
                                                        {(ingredient.weight_variation_type == 'loss') && ' - '}
                                                        {(ingredient.weight_variation_type == 'gain') && ' + '}
                                                        {Number(ingredient.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                    </Button>}
                                        content={<FormattedMessage id="ingredients.popup.view.weight.info.text"
                                                                    defaultMessage='Variation applied'/>}
                                        inverted
                                        size='small'
                                        position='bottom left'/>
                            }</p>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>{/*  {ingredient.category__name}  */}
                             {ingredient.category__name && ingredient.category__name.length >= 18 && 
                                ingredient.category__name.substring(0,18) + '..'}
                                {ingredient.category__name && ingredient.category__name.length < 18 && 
                                ingredient.category__name}
                        </Table.Cell>
                        
                        <Table.Cell width={1} textAlign='center'>{ingredient.supplier__name} </Table.Cell>
                         {!priceLayout &&
                        <Table.Cell width={1} textAlign='right'>
                           {/*  <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/> */}
                           {ingredient.purchase_quantity !== "" &&
                               <div>
                                    <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}
                                    <FormattedMessage id="ingredients.quantity.unit.text"
                                                     defaultMessage='unit'/>
                                </div>
                            }
                            {(ingredient.purchase_weight !== "" || ingredient.purchase_volume !== "") &&
                             <div>
                                { grams.includes(ingredient.unit) && 
                                  <div>
                                     <FormattedNumber value={Number(ingredient.price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' '}/{' kg'} 
                                  </div>
                                }
                                 { mgrams.includes(ingredient.unit) && 
                                  <div>
                                     <FormattedNumber value={Number(ingredient.price_per_unit) * 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' '}/{' kg'} 
                                  </div>
                                }
                                { cliters.includes(ingredient.unit) && 
                                  <div>
                                     <FormattedNumber value={Number(ingredient.price_per_unit) * 100}  style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' '}/{' L'} 
                                  </div>
                                }
                                 { mliters.includes(ingredient.unit) && 
                                  <div>
                                     <FormattedNumber value={Number(ingredient.price_per_unit) * 1000}  style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>{' '}/{' L'} 
                                  </div>
                                }
                                {  !grams.includes(ingredient.unit) &&
                                    !mgrams.includes(ingredient.unit) &&
                                     !cliters.includes(ingredient.unit) &&
                                      !mliters.includes(ingredient.unit) &&
                                    <div>
                                     <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{ingredient.unit}
                                    </div>
                                }
                            </div>
                            }
                        </Table.Cell>}
                        {priceLayout &&
                          <Table.Cell width={1} textAlign='center'>
{/*                             {ingredient.purchase_weight != '' &&
                            <Popup trigger=
                                            {<Icon name='shopping bag' color='blue' style={{cursor:'pointer'}} size='large'/>}
                                   //inverted
                                   size='mini'>
                                   <Popup.Header>
                                        <FormattedMessage id="ingredients.popup.purchase.weight.text"
                                                            defaultMessage='Purchase details'/>
                                   </Popup.Header>
                                   <Popup.Content>
                                        <FormattedMessage id="ingredients.popup.purchase.weight.content"
                                                              defaultMessage='Weight {weight}'
                                                              values={{weight:ingredient.purchase_weight}}/>
                                   </Popup.Content>
                                   <Popup.Content>
                                        <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                   </Popup.Content>
                            </Popup>} */}
                            {ingredient.purchase_weight !== "" &&
                                        <FormattedMessage id="ingredients.popup.purchase.weight.content"
                                                              defaultMessage='{weight}'
                                                              values={{weight:ingredient.purchase_weight}}/>}
{/*                             {ingredient.purchase_volume != '' &&
                            <Popup trigger=
                                            {<Icon name='shopping bag' color='blue' style={{cursor:'pointer'}} size='large'/>}
                                   //inverted
                                   size='mini'>
                                   <Popup.Header>
                                        <FormattedMessage id="ingredients.popup.purchase.volume.text"
                                                            defaultMessage='Purchase details'/>
                                   </Popup.Header>
                                   <Popup.Content>
                                        <FormattedMessage id="ingredients.popup.purchase.volume.content"
                                                          defaultMessage='Volume {volume}'
                                                          values={{volume:ingredient.purchase_volume}}/>
                                   </Popup.Content>
                                   <Popup.Content>
                                        <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                   </Popup.Content>
                            </Popup> } */}
                            {ingredient.purchase_volume != '' &&
                                        <FormattedMessage id="ingredients.popup.purchase.volume.content"
                                                          defaultMessage='{volume}'
                                                          values={{volume:ingredient.purchase_volume}}/>}
{/*                             {ingredient.purchase_quantity != '' &&
                            <Popup trigger=
                                            {<Icon name='shopping bag' color='blue' style={{cursor:'pointer'}} size='large'/>}
                                   //inverted
                                   size='mini'>
                                   <Popup.Header>
                                        <FormattedMessage id="ingredients.popup.purchase.quantity.text"
                                                            defaultMessage='Purchase details'/>
                                   </Popup.Header>
                                   <Popup.Content>
                                        <FormattedMessage id="ingredients.popup.purchase.quantity.content"
                                                          defaultMessage='Quantity {quantity}'
                                                          values={{quantity:ingredient.purchase_quantity}}/>
                                   </Popup.Content>
                                   <Popup.Content>
                                        <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                   </Popup.Content>
                            </Popup>} */}
                            {ingredient.purchase_quantity != '' &&
                                        <FormattedMessage id="ingredients.popup.purchase.quantity.content"
                                                          defaultMessage='{quantity} unit(s)'
                                                          values={{quantity:ingredient.purchase_quantity}}/>}
                        </Table.Cell>}
                        {priceLayout &&
                              <Table.Cell width={1} textAlign='center'> <FormattedNumber value={ingredient.pack_units}/></Table.Cell>}
                        {priceLayout &&
                        <Table.Cell width={2} textAlign='center' onClick={(event) => this.editPriceHandlerInput(event,index,ingredient)} >
                            {/* <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/>  */}
                            {(!editCellIngredientPrice || (editCellIngredientPrice && index !== editCellIndexIngredientPrice) ) &&
                                <a href='#'>
                                    <FormattedNumber value={ingredient.price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                </a>}
                                
                                {editCellIngredientPrice && index === editCellIndexIngredientPrice && 
                                <OutsideClickHandler onOutsideClick={(event) => this.updatePriceOnBlur(event,ingredient)}>       
                                        {/* <Form>
                                            <Form.Group widths='equal'> */}
                                                        <Input type="text"
                                                                id="quantity"
                                                                value={newPrice}
                                                                onChange={this.onNewPrice}
                                                                onBlur={(event) => this.updatePriceOnBlur(event,ingredient)} 
                                                                onKeyPress={(event) => this.updatePriceOnEnter(event,ingredient)} 
                                                                autoFocus
                                                                style={{height:'2.6em',minWidth:'6em',maxWidth:'6em'}}
                                                                label={{ basic: false, content: this.currencySymbol() }}
                                                                labelPosition='right'
                                                        />{/* <div style={{'marginTop':'0.4em'}} >{this.currencySymbol()}</div> */}
                                          {/*   </Form.Group>
                                        </Form>  */}
                            </OutsideClickHandler>}
                            <p style={{'color':'#b5b1b1',marginLeft:'1em',fontSize:'12px'}}><i><FormattedNumber value={ingredient.tax} maximumFractionDigits="2" style="percent"/></i></p>
                        </Table.Cell>}
{/*                         {priceLayout && (tax > 0) &&
                              <Table.Cell width={1} textAlign='center'> <FormattedNumber value={ingredient.tax} maximumFractionDigits="2" style="percent"/></Table.Cell>} */}
{/*                         {priceLayout &&  (tax <= 0) &&
                              <Table.Cell width={1} textAlign='center'> - </Table.Cell>} */}
                        {priceLayout &&
                        <Table.Cell width={1} textAlign='center'>{ingredient.ref_supplier}</Table.Cell>}
                        {priceLayout && ingredient.date_expire &&
                        <Table.Cell width={1} textAlign='right'>
                            <span style={{/* color:'#63a4dd' , */fontSize:'13px'/*  ,fontWeight: 'bold' */}}><i>
                                                     <FormattedDate value={new Date(ingredient.date_expire)}
                                                            year='numeric'
                                                            month='short'
                                                            day='2-digit'/></i></span>
                        </Table.Cell>}
                        {priceLayout && !ingredient.date_expire &&
                        <Table.Cell width={1} textAlign='center'>---</Table.Cell>}
                        {!priceLayout &&<Table.Cell width={1} textAlign='right'><FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/> {ingredient.stock_unit} </Table.Cell>}
                        {/* <Table.Cell width={1} textAlign='right'><FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/> {ingredient.unit} </Table.Cell> */}
                        {!priceLayout &&
                        <Table.Cell width={2} textAlign='right'>
                               {ingredient.price_change > 0 &&
                                 <Icon link 
                                      name='long arrow alternate up' 
                                      color='green'
                                      onClick={(event) => this.historyIngredient(event,ingredient.id)}></Icon>}
                                {ingredient.price_change > 0 &&
                                      <a href="#" 
                                         style={{color:'green','textAlign':'right'}}
                                         onClick={(event) => this.historyIngredient(event,ingredient.id)}>
                                         <FormattedNumber value={ingredient.price_change} maximumFractionDigits="2" style="percent"/></a>}
                                {ingredient.price_change < 0 &&
                                  <Icon link 
                                       name='long arrow alternate down' 
                                       color='red'
                                       onClick={(event) => this.historyIngredient(event,ingredient.id)}></Icon>}
                                {ingredient.price_change < 0 &&
                                    <a href="#" 
                                       style={{color:'red'}}
                                       onClick={(event) => this.historyIngredient(event,ingredient.id)}>
                                       <FormattedNumber value={ingredient.price_change} maximumFractionDigits="2" style="percent"/></a>}
                        </Table.Cell>}
                        {/* <Table.Cell>{ingredient.note}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                            <Popup trigger=
                                     {<Icon color='blue' name='folder open outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.seeIngredient(event,ingredient.id)}/>
                                    }
                                    //header='See Ingredient details'
                                    content={<FormattedMessage id="ingredients.popup.view.text"
                                                                     defaultMessage='Detailed view'/>}
                                    inverted />&nbsp;&nbsp;&nbsp;&nbsp;
{/*                             <Popup trigger=
                                     {<Icon color='blue' name='edit outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.editIngredient(event,ingredient.id)}/>
                                    }
                                    //header='See Ingredient details'
                                    content={<FormattedMessage id="ingredients.popup.edit.text"
                                                                     defaultMessage='Edit'/>}
                                    inverted/> &nbsp; */}
{/*                                <Popup trigger=
                                     {<Icon color='black' name='history' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.historyIngredient(event,ingredient.id)}/>
                                    }
                                    content={<FormattedMessage id="ingredients.popup.history.text"
                                                               defaultMessage='History'/>}
                                    inverted/> &nbsp;   */}
{/*                              <Popup trigger=
                                     {<Icon color='grey' id={index} name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.deleteClickHandler(event,index)}/>
                                    }
                                    //header='See Ingredient details'
                                    content={<FormattedMessage id="ingredients.popup.delete.text"
                                                               defaultMessage='Delete'/>}
                                    inverted/>      */}                        
                          <Popup trigger=
                                    {<Dropdown id={index}
                                               trigger={trigger}
                                               pointing='top right'
                                               icon={null}
                                               value = {null}
                                               options={optionsPlus}
                                               label='plus'
                                               onChange={(event,data) => this.onOptionsMenu(event,data,index,ingredient.id)} />}
                                    content={<FormattedMessage id="ingredients.popup.options.text"
                                                               defaultMessage='Options'/>}
                                    inverted/> &nbsp;&nbsp;
                            <Modal id={index}
                                   open={this.state.activeModal === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="ingredients.modal.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="ingredients.modal.deletion.message"
                                                         defaultMessage='Really sure to delete the ingredient {name} ?'
                                                         values={{name:ingredient.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="ingredients.modal.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="ingredients.modal.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.deleteIngredient(event,ingredient.id)}/>
                                    </Modal.Actions>
                            </Modal>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }
    
    render() { 
        const { ingredients,categoriesIngredient,supplier,alert,dispatch,loading_i,storageAreas,updating } = this.props;
        const { newItems,filtering,categorySelected,supplierSelected,nameSelected,isMobile,page,itemsPerPage,
                selectionList,isCheckedAll,actionSelected ,allowMultipleSelection,typeSelected,supplierCodeSelected,codeSelected,
                storageAreaSelected,priceLayout} = this.state;
        //console.log(selectionList)
        //console.log(actionSelected)
        let buttons = [];
		let className = "floating-menu";
		let icon = <Icon name='add'/>;
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;

/*             buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="ingredients.floatingmenu.item3.title" defaultMessage= "Create an order"/>}
                                                icon={<Icon name='cart'/>} 
                                                action={this.createOrder} 
                                                mobile={isMobile}/>); */
            buttons.push(<FloatingMenuItemOther label={<FormattedMessage id="ingredients.floatingmenu.item2.title" defaultMessage= "Create a recipe"/>}  
                                                icon={<Icon name='food'/>} 
                                                action={this.createRecipe} 
                                                mobile={isMobile}/>);
            buttons.push(<FloatingMenuItem label={<FormattedMessage id="ingredients.floatingmenu.item4.title" defaultMessage= "Create multiple ingredients"/>} 
                                           icon={<Icon name='copy'/>} 
                                           action={this.createMultipleIngredients} 
                                           mobile={isMobile}/>);
            buttons.push(<FloatingMenuItem label={<FormattedMessage id="ingredients.floatingmenu.item1.title" defaultMessage= "Create an ingredient"/>} 
                                           icon={<Icon name='shopping basket'/>} 
                                           action={this.createIngredient} 
                                           mobile={isMobile}/>);
        }
        
        buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 
        
        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering && ingredients && ingredients.items){
            length = ingredients.items.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = ingredients.items.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            if (begin > length) begin=0;
            items = newItems.slice(begin,end);
        }
        //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
        }

        return ( 
            <div>
                 <SideLeftUncoverIngredients>
                        <Grid stackable style={styles.FontLato13Border}>
                          <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="ingredients.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="ingredients.link.ingredients"
                                                              defaultMessage='Ingredients'/></Breadcrumb.Section>
                            </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_bag} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="ingredients.page.title"
                                                              defaultMessage='Ingredients'/>
                                            <Header.Subheader><FormattedMessage id="ingredients.page.subtitle"
                                                              defaultMessage='Food, packaging ...'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
{/*                             
                            <Message icon >
                                          <Image src={img_dressing}  rounded/>
                                          <Message.Content>
                                                <Message.Header>
                                                    <FormattedMessage id="ingredients.header.caption.title"
                                                                     defaultMessage='Your Ingredients List'/>
                                                </Message.Header>
                                                 <div  style={inlineStyle}>
                                                    <FormattedMessage id="ingredients.header.caption.subtitle"
                                                                 defaultMessage='Manage your ingredients and their price.' />
                                                </div>
                                          </Message.Content>
                                    </Message>
                                <br/>*/}
                             {alert.message && this.toaster(dispatch,alert)}
                           {/*  <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column width={6}> 
                                    <p style={styles.FontLato13}><FormattedMessage id="ingredients.filter.name.title"
                                                         defaultMessage='Search (one or multiple words)'/></p>
                                    <FormattedMessage id="ingredients.filter.byname.placeholder"
                                                        defaultMessage='Type at least 2 letters per each word'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                disabled={this.isUnSubscribedUser()}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}}><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column width={1}/>
                                <Grid.Column width={8}> 
                                   <p style={styles.FontLato13}><FormattedMessage id="ingredients.filter.title"
                                                         defaultMessage='Filter by'/></p>
                                    <Dropdown
                                        button basic
                                        className='icon'
                                        labeled
                                        icon={{name:'filter', color:'teal'}}
                                        options={filterOptions}
                                        value = {typeSelected}
                                        onChange={this.filterTypeList}
                                        style={{'font-size':'14px'}}/>
                                    {typeSelected == 'Category' && 
                                        <FormattedMessage id="ingredients.filter.category.placeholder"
                                                        defaultMessage='Select'>
                                        {placeholder =>  
                                                <Dropdown selection search
                                                        options= {categoriesIngredient && categoriesIngredient.options} 
                                                        value= {categorySelected && categorySelected.id}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{'font-size':'14px'}}
                                                        //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                        />}
                                    </FormattedMessage>}
                                    {typeSelected == 'Supplier' && 
                                        <FormattedMessage id="ingredients.filter.supplier.placeholder"
                                                         defaultMessage='Select'>
                                        {placeholder =>  
                                                    <Dropdown selection search
                                                            options= {supplier && supplier.options}  
                                                            value= {supplierSelected && supplierSelected.id}
                                                            placeholder={placeholder}
                                                            onChange={this.filterSupplierList}
                                                            clearable
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}
                                                            //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                            />}
                                    </FormattedMessage>}
                                    {typeSelected == 'SupplierRef' && 
                                        <FormattedMessage id="ingredients.filter.internalref.placeholder"
                                                         defaultMessage='Search'>
                                        {placeholder =>  
                                                    <Input placeholder={placeholder}
                                                            value= {supplierCodeSelected} 
                                                            onChange={this.filterSupplierRefList}
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}/>}
                                    </FormattedMessage>}
                                    {typeSelected == 'InternalRef' && 
                                        <FormattedMessage id="ingredients.filter.supplierref.placeholder"
                                                         defaultMessage='Search'>
                                        {placeholder =>  
                                                    <Input placeholder={placeholder}
                                                            value={codeSelected}
                                                            onChange={this.filterInternalRefList}
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}/>}
                                    </FormattedMessage>}
                                    {typeSelected == 'StorageArea' && 
                                        <FormattedMessage id="ingredients.filter.storageArea.placeholder"
                                                         defaultMessage='Select'>
                                        {placeholder =>  
                                                    <Dropdown selection search
                                                            options= {storageAreas && storageAreas.options}  
                                                            value= {storageAreaSelected && storageAreaSelected.id}
                                                            placeholder={placeholder}
                                                            onChange={this.filterStorageAreaList}
                                                            clearable
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}
                                                            //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                            />}
                                    </FormattedMessage>}
                                </Grid.Column>
{/*                                 <Grid.Column width={2}/> 
                                <Grid.Column> 
                                   <p style={styles.FontLato14Grey}><FormattedMessage id="ingredients.filter.category.title"
                                                         defaultMessage='Category'/></p>
                                    <FormattedMessage id="ingredients.filter.category.placeholder"
                                                        defaultMessage='Select a category'>
                                        {placeholder =>  
                                                <Dropdown selection
                                                        options= {categoriesIngredient && categoriesIngredient.options} 
                                                        value= {categorySelected}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{minWidth:'20em',maxWidth:'20em', minHeight:'3em'}}>
                                                 </Dropdown>}
                                    </FormattedMessage>
                                </Grid.Column> */}
                            </Grid.Row>
                             <Grid.Row columns={2}>
{/*                                  <Grid.Column width={4}> 
                                  {selectionList.length > 0 && <FormattedMessage id="ingredients.action.main.placeholder"
                                                        defaultMessage='Action'>
                                        {placeholder =>  
                                                <Dropdown selection
                                                        options= {[
                                                            { key: 'none', text: '---- Action ----', value: 'none' },
                                                            { key: 'delete', text: 'delete all selected', value: 'delete' },]}
                                                        value= {actionSelected}
                                                        placeholder={placeholder}
                                                        onChange={this.selectActionList}
                                                        style={{minWidth:'18em',maxWidth:'28em','font-size':'14px',
                                                        'font-weight':800,'background-color':'#20B2AA',color:'white'}}/>}
                                    </FormattedMessage>}
                                    {actionSelected !='none' && selectionList.length > 0 &&
                                        <Button //basic 
                                                //icon='check'
                                                //circular 
                                                compact 
                                                className="ui icon button"
                                                color='teal' 
                                                style={{marginLeft:'0.3em'}}
                                                onClick={this.submitAction}>
                                                <Icon name="check"></Icon></Button>
                                    }
                                    {actionSelected !='none' && selectionList.length == 0 &&
                                        <span  style={{marginLeft:'0.5em', color:'#008080'}}>select item(s) !</span>
                                    }
                                </Grid.Column> */}
                                 <Grid.Column width={4}> 
                                         <Button.Group basic>
                                            <Button icon 
                                                    onClick={this.setGlobalLayout} 
                                                    active={!priceLayout}> 
                                                 <span> 
                                                 <Icon name='table' color='blue'/>&nbsp; 
                                                 <FormattedMessage id="ingredients.tab.button.global.text"
                                                                    defaultMessage='Summary'/> </span> 
                                            </Button>
                                            <Button icon 
                                                    onClick={this.setPriceLayout} 
                                                    active={priceLayout}>
                                                 <span> 
                                                <Icon name='shopping cart' color='teal'/>&nbsp; 
                                                     <FormattedMessage id="ingredients.tab.button.supplier.text"
                                                                       defaultMessage='Suppliers'/> </span> 
                                            </Button>
                                        </Button.Group>
                                </Grid.Column> 
                                <Grid.Column mobile={14} tablet={12} computer={10}> 
                                    <div className={className}>
                                        {buttons}
                                    </div>
                                </Grid.Column>
                             </Grid.Row> 
                            <Grid.Row columns={1} /* style={{'overflow-x': 'auto'}} */>
                              <Grid.Column /*width={14}*/  mobile={18} tablet={16} computer={14}>
                                  {selectionList.length > 0 && 
                                     <FormattedMessage id="ingredients.action.main.placeholder"
                                                       defaultMessage='{number} selected'
                                                       values={{number:selectionList.length}}>
                                        {placeholder =>  
                                                <Dropdown selection floating labeled
                                                        options= {[{ key: 'none', text: ' ', value: 'none' },
                                                                    { key: 'category', icon:  {name:'sitemap',color:'yellow'},
                                                                    text: <FormattedMessage id="ingredients.action.category.text"
                                                                                            defaultMessage='Category'/>, value: 'category' },
                                                                   { key: 'stockarea', icon: {name:'warehouse',color:'grey'},
                                                                     text: <FormattedMessage id="ingredients.action.stockarea.text"
                                                                                            defaultMessage='Storage Area'/>, value: 'stockarea' },
                                                                   { key: 'delete', icon: {name:'trash alternate outline',color:'red'},
                                                                    text: <FormattedMessage id="ingredients.action.delete.text"
                                                                                            defaultMessage='Delete'/>, value: 'delete' },]}
                                                        value= {actionSelected}
                                                        text={placeholder}
                                                        className='icon'
                                                        onChange={this.selectActionList}
                                                        style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                    </FormattedMessage>}
{/*                                         <span>{actionSelected !='none' && selectionList.length > 0 &&
                                                <Button compact 
                                                className="ui icon button"
                                                style={{marginLeft:'0.5em', 'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#498caa',color:'white'}}
                                                onClick={this.submitAction}>
                                                <FormattedMessage id="ingredients.action.button.validate.text"
                                                                   defaultMessage='Submit'/>
                                        </Button>} */}
                                        {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="ingredients.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}{/* </span>
                                    {selectionList.length > 0 &&
                                        <span  style={{marginLeft:'0.5em', color:'grey'}}>{selectionList.length}{' '}
                                                <FormattedMessage id="ingredients.action.info.nbitems.text"
                                                                  defaultMessage='item(s)'/> </span>
                                    } */}
                                <Dimmer.Dimmable> 
                                        <Dimmer active={loading_i} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer>
                                 <Table //selectable  
                                           //definition
                                           //stackable
                                           unstackable 
                                           compact 
                                           striped 
                                           singleLine 
                                           style={styles.FontLato13Border} >
                                        <Table.Header>
{/*                                             <Table.Row>
                                                <Table.HeaderCell colSpan='7' color='grey'>
                                                <Button  floated='right' 
                                                        basic
                                                        icon
                                                        labelPosition='left' 
                                                        //primary 
                                                        size='medium'
                                                        color='teal'
                                                        //style={styles.ButtonGrey}
                                                        onClick={this.createIngredient}>
                                                    <Icon name='add' /><FormattedMessage id="ingredients.table.footer.button.addingredient"
                                                                                         defaultMessage='Ingredient'/>
                                                </Button>
                                               </Table.HeaderCell>
                                            </Table.Row> */}
                                            <Table.Row>
                                                {/* <Table.HeaderCell width={0} style={{width:'0.2em'}} ><Checkbox/></Table.HeaderCell> */}
                                                <Table.HeaderCell width={6}>
                                                  {allowMultipleSelection && <Input type='checkbox'
                                                            onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.1)'}}
                                                            checked={isCheckedAll}/>}
                                                       <span style={{marginLeft:'0.7em'}}><FormattedMessage id="ingredients.table.header.column.name"
                                                                             defaultMessage='Name'/></span>
                                                      <p style={{'color':'#b5b1b1','font-size':'11px',marginLeft:'2em'}}><i><FormattedMessage id="ingredients.table.header.column.reference"
                                                                          defaultMessage='Reference code'/></i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.category"
                                                                          defaultMessage='Category'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.supplier"
                                                                          defaultMessage='Default Supplier'/>
                                                </Table.HeaderCell>
                                                {!priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="ingredients.table.header.column.unitprice"
                                                                          defaultMessage='Unit price'/>
                                                </Table.HeaderCell>}
                                                {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.purchase"
                                                                          defaultMessage='Purchase'/>
                                                </Table.HeaderCell>}
                                                {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.pack"
                                                                          defaultMessage='Pack of'/>
                                                </Table.HeaderCell>}
                                                {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.price"
                                                                          defaultMessage='Price'/>
                                                   <p style={{'color':'#b5b1b1','font-size':'11px',marginLeft:'1em'}}><i> <FormattedMessage id="ingredients.table.header.column.tax"
                                                                          defaultMessage='Tax %'/></i></p>
                                                </Table.HeaderCell>}
{/*                                                 {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.tax"
                                                                          defaultMessage='Tax %'/>
                                                </Table.HeaderCell>} */}
                                                {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="ingredients.table.header.column.codesupplier"
                                                                          defaultMessage='Suppl. ref'/>
                                                </Table.HeaderCell>}
                                                {priceLayout &&
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="ingredients.table.header.column.expire"
                                                                          defaultMessage='Use By'/>
                                                </Table.HeaderCell>}
                                                
                                                {!priceLayout && <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="ingredients.table.header.column.stock_quantity"
                                                                          defaultMessage='Stock Quantity '/>
                                                </Table.HeaderCell>}
                                                {!priceLayout && <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="ingredients.table.header.column.pricechange"
                                                                          defaultMessage='Price Change'/>
                                                </Table.HeaderCell>}
                                                <Table.HeaderCell width={2} textAlign='right'>
{/*                                                     <Button  floated='right' 
                                                            basic
                                                            icon
                                                            labelPosition='left' 
                                                            //primary 
                                                            size='medium'
                                                            color='teal'
                                                            //style={styles.ButtonGrey}
                                                            onClick={this.createIngredient}>
                                                        <Icon name='add' /><FormattedMessage id="ingredients.table.footer.button.addingredient"
                                                                                            defaultMessage='Ingredient'/>
                                                    </Button>*/}
                                                </Table.HeaderCell> 
                                            </Table.Row>
                                        </Table.Header>     
                                        <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="ingredients.table.header.row.noitems"
                                                                            defaultMessage='No ingredients'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                        {items && items.length > 0 &&
                                            this.addIngredientsRows(items)
                                        }
                                        </Table.Body>
                                        {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="ingredients.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Recipes limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {ingredients && ingredients.items && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="ingredients.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{ingredients.items.length}{' '}
                                                                <FormattedMessage id="ingredients.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="ingredients.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                </Table>
                                </Dimmer.Dimmable>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={options} 
                                                  //simple 
                                                  item
                                                  inline
                                                  //icon='list'
                                                  //button 
                                                  fluid
                                                  //search
                                                  //header='Page'
                                                  //text='ingredients'
                                                  onChange={this.onElemPerPageChange}
                                                  disabled={this.isUnSubscribedUser()}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="ingredients.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="ingredients.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        disabled={this.isUnSubscribedUser()}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="ingredients.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="ingredients.modal.select.deletion"
                                                         defaultMessage='Really sure to delete the selected ingredient(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="ingredients.modal.select.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="ingredients.modal.select.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark'
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                        <Modal   open={this.state.actionModalStockArea}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='warehouse' content={<FormattedMessage id="ingredients.form.stockarea.change.modal.title"
                                                                                    defaultMessage='Select storage area'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'1em',paddingBottom:'3em'}}>
                                          <Form.Field width={8}>
                                            <label><FormattedMessage id="ingredients.form.stockarea.name"
                                                                      defaultMessage='Storage Area'/>
                                            </label>
                                            <FormattedMessage id="ingredients.form.stockarea.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection search //scrolling 
                                                                  options={storageAreas && storageAreas.options} 
                                                                  value = {storageAreaSelected && storageAreaSelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onStorageAreaChange}
                                                                  style={{'font-size':'13px'}}
                                                                  clearable
                                                            />}
                                            </FormattedMessage>
                                          </Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredients.form.stockarea.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredients.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectStorageArea}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredients.form.stockarea.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectStorageArea}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                            </Modal>
                            <Modal  open={this.state.actionModalCategory}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='sitemap' content={<FormattedMessage id="ingredients.form.category.change.modal.title"
                                                                                    defaultMessage='Select category'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'1em',paddingBottom:'3em'}}>
                                          <Form.Field width={8}>
                                            <label><FormattedMessage id="ingredients.form.category.name"
                                                                      defaultMessage='Category'/>
                                            </label>
                                            <FormattedMessage id="ingredients.form.category.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection search //scrolling 
                                                                  options={categoriesIngredient && categoriesIngredient.options} 
                                                                  value = {categorySelected && categorySelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onCategoryChange}
                                                                  style={{'font-size':'13px'}}
                                                                  clearable
                                                            />}
                                            </FormattedMessage>
                                          </Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="ingredients.form.category.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredients.form.category.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectCategory}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="ingredients.form.category.modal.validate.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectCategory}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                            </Modal>      
                </SideLeftUncoverIngredients>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { ingredients, categoriesIngredient,supplier,storageAreas,dispatch, alert } = state;
    const { loading_i,updating} = state.ingredients;
    const { subscription } = state.payment;
    return {
        ingredients,
        categoriesIngredient,
        supplier,
        dispatch,
        alert,
        loading_i,
        updating,
        subscription,
        storageAreas
    };
}

const connectedIngredients = injectIntl(connect(mapStateToProps)(Ingredients));
export { connectedIngredients as Ingredients };
 