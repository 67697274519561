import React, { Component } from 'react';
import { ingredientsActions, recipesActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid,Icon,Header,Breadcrumb,Select,Modal,Image} from 'semantic-ui-react';
import SideLeftUncoverInventory from './SidebarInventory';
import { history,toaster_dispatch,Capitalize} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'


const optionsTypeStock = [
   { key: 1, text: <FormattedMessage id="resetstock.options.ingredients"
                                               defaultMessage='Ingredients'/>, value: 'ingredient'},
   { key: 2, text: <FormattedMessage id="resetstock.options.recipes"
                                               defaultMessage='Recipes'/>, value: 'recipe'}]


class ResetStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockType: 'ingredient',
    }
    props.dispatch(paymentActions.getCustomerMembership());
 }

 applyResetInventory (event) {
   const { stockType } = this.state;
    event.preventDefault();
    if (stockType == 'ingredient'){
      this.props.dispatch(ingredientsActions.reset_stock());
    }else{
      this.props.dispatch(recipesActions.reset_stock());
    }
 }



 onStockTypeChange = (e,data) => {
   e.preventDefault();
   //this.setState({ stockType: data.value });
   const {customer} = this.props;
   let user = null;
   let membership = null;

   if (customer){
     membership = customer.membership;
     user = customer.user;
   }

   if (membership && user &&
       ((membership.membership_type=="Entreprise") || (user.bypass==true)))
   {
       this.setState({stockType:data.value});
   }else{
       this.setState({ activeModalPremium: true});
   }
 }

 seeInventory (event) {
    event.preventDefault();
    history.push({pathname:'/inventory',state:{startPage:1}});
 }

 upgradeSubscription = () => {
  history.push({pathname: '/profilepremium', state: {toggled: false}})
 }

 hideModal() {
  this.setState({ activeModalPremium: false });
}


  render() {
    const { dispatch,alert,intl,updating,customer } = this.props;
    return (
      <div>
      <SideLeftUncoverInventory id={this.props.match.params.recipeId}>
         <Grid stackable style={{border:'none','background-color':'#fcfcfc','font-family': 'Lato', 'font-size': '12px','color': 'grey'}}>
            <Grid.Row columns={1}>
                  <Breadcrumb  style={{background:'#fcfcfc'}}>
                          <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="resetstock.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section link as={Link} to={{pathname:'/inventory',state:{startPage:1}}}><FormattedMessage id="resetstock.link.inventory"
                                                                       defaultMessage='Inventory'/></Breadcrumb.Section>
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section active><FormattedMessage id="resetstock.link.reset"
                                                                      defaultMessage='Reset'/></Breadcrumb.Section>
                  </Breadcrumb>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={10}>
                        <Header as='h2'>
                              <Icon name='redo' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="resetstock.header.title"
                                                    defaultMessage=' Reset Inventory '/>
                                  {/* <Header.Subheader>{recipes && recipes.selected && Capitalize(recipes.selected.name)}</Header.Subheader> */}
                                </Header.Content>
                        </Header>
                        <br/>
                </Grid.Column>
           </Grid.Row>
           <Grid.Row columns={1}>
              <Grid.Column width={6}>
                 <Message style={styles.FontLato13}>
                    <Message.Header> 
                          <FormattedMessage id="resetstock.card.header.title"
                                            defaultMessage='Reset all inventory of type'/>
                    </Message.Header>
                    <br/>
                    <br/>
                     <FormattedMessage id="resetstock.card.type.placeholder"
                                                defaultMessage='Which type ?'>
                                    {placeholder =>   
                                            <Select 
                                                    options={optionsTypeStock}
                                                    placeholder={placeholder} 
                                                    value={this.state.stockType}
                                                    onChange={this.onStockTypeChange}
                                                    fluid
                                                    style={{'font-size': '16px','font-weight': '500','color': 'black'}}
                                            />}
                     </FormattedMessage>  
                    <br/> <br/>
                    <p> 
                          <FormattedMessage id="resetstock.card.header.message"
                                            defaultMessage='Are you really sure to proceed a reset on the selected inventory ?'/>
                    </p>
                    {updating && 
                        <Button loading style={styles.ButtonGreen} //basic bsstyle="primary" color='teal'
                            onClick={(event) =>this.applyResetInventory(event)}>
                            <i className="check icon"></i>
                            <FormattedMessage id="resetstock.card.button.proceed"
                                            defaultMessage='Proceed'/>
                    </Button>}
                    {!updating && 
                        <Button style={styles.ButtonGreen} //basic bsstyle="primary" color='teal'
                            onClick={(event) =>this.applyResetInventory(event)}>
                            <i className="check icon"></i>
                            <FormattedMessage id="resetstock.card.button.proceed"
                                            defaultMessage='Proceed'/>
                    </Button>}
                    <Button style={styles.ButtonGrey} //basic bsstyle="primary" color='grey'
                            onClick={(event) =>this.seeInventory(event)}>
                            <i className="cancel icon"></i>
                            <FormattedMessage id="resetstock.card.button.cancel"
                                            defaultMessage='Cancel'/>
                    </Button>
                    <br/>
                </Message>
             </Grid.Column>
          </Grid.Row >
          <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="resetstock.premium.header.title"
                                                    defaultMessage='Reset Inventory'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' /* style={styles.FontLato12Bold} */ color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="resetstock.premium.message.upgradeplan"
                                                                defaultMessage='ENTERPRISE Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="resetstock.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{customer && customer.membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="resetstock.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="resetstock.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="resetstock.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </Grid>
      </SideLeftUncoverInventory>
     </div> 
    );
  }
}

function mapStateToProps(state) {
  const { recipes,dispatch,alert } = state;
  const {loading} = state.recipes;
  const {updating} = state.ingredients;
  const { customer } = state.payment;
  return {
        recipes,
        dispatch,
        alert,
        loading,
        updating,
        customer
  };
}

const connectedResetStock = injectIntl(connect(mapStateToProps)(ResetStock));
export { connectedResetStock as ResetStock };
