import React, { Component } from 'react';
import { ingredientsActions, categoryIngredientActions,supplierActions,alertActions } from '../_actions';
import { connect } from 'react-redux';
import { Button, Form, Header,Grid,Table,Message,Modal,Icon,Segment,Popup}  from 'semantic-ui-react'
import SideLeftUncoverIngredients from './SidebarIngredients';
import {history,toaster_dispatch,toaster_error,isNumeric,isFloat,trim,unitWeight,unitVolume,tablespoons,teaspoons,isInteger,dateWithoutTimezone} from '../_helpers';
import times from 'lodash/times';
import {cloneDeep} from "lodash"
import OutsideClickHandler from 'react-outside-click-handler';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
          WEIGHT_INSTRUCTION_LINE_KG,
          WEIGHT_INSTRUCTION_LINE_G,
          WEIGHT_INSTRUCTION_LINE_MG,
          WEIGHT_INSTRUCTION_LINE_OZ,
          WEIGHT_INSTRUCTION_LINE_LB,
          WEIGHT_INSTRUCTION_LINE_EX,
          VOLUME_INSTRUCTION_LINE_TITLE,
          VOLUME_INSTRUCTION_LINE_L,
          VOLUME_INSTRUCTION_LINE_ML,
          VOLUME_INSTRUCTION_LINE_CL,
          VOLUME_INSTRUCTION_LINE_FL,
          VOLUME_INSTRUCTION_LINE_GAL,
          VOLUME_INSTRUCTION_LINE_EX,
          QUANTITY_INSTRUCTION_LINE_TITLE,
          QUANTITY_INSTRUCTION_LINE_NUMBER,
          QUANTITY_INSTRUCTION_LINE_EX,
          allergens,
          VAToptions
        } from '../_helpers';

import "./NutritionFacts.scss";
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

const  WEIGHT = <FormattedMessage id="newingredients.quantity.select.weight"
                                    defaultMessage="Weight"/>;
const  VOLUME = <FormattedMessage id="newingredients.quantity.select.volume"
                                    defaultMessage="Volume"/>;
const  QUANTITY = <FormattedMessage id="newingredients.quantity.select.quantity"
                                    defaultMessage="Quantity"/>;



class NewMultipleIngredients extends Component {
  constructor(props) {
    super(props);
    const {intl} = props;
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state = {
      prevCategoriesIngredient : props.categoriesIngredient,
      ingredientsData:[
          {
            name: "",
            price: "",
            purchase_weight: "",
            purchase_volume: "",
            purchase_quantity: "",
            category : null,
            supplier : null,
            allergens : null,
            type_quantity: "",
            quantity: "",
            highlighted : false,
            tax: null,
            pack_units:1,
            date_expire:"",
            batch_number:"",
          },
          {
            name: "",
            price: "",
            purchase_weight: "",
            purchase_volume: "",
            purchase_quantity: "",
            category: null,
            supplier : null,
            allergens : null,
            type_quantity: "",
            quantity: "",
            highlighted : false,
            tax: null,
            pack_units:1,
            date_expire:"",
            batch_number:"",
          },
          {
            name: "",
            price: "",
            purchase_weight: "",
            purchase_volume: "",
            purchase_quantity: "",
            category : null,
            supplier : null,
            allergens : null,
            type_quantity: "",
            quantity: "",
            highlighted : false,
            tax: null,
            pack_units:1,
            date_expire:"",
            batch_number:"",
          },
      ],
      activeModal: true,
      currency : userIn.user.currency,
      isMobile: false,
    };
    this.hideModal = this.hideModal.bind(this);
    props.dispatch(categoryIngredientActions.getAll());
    props.dispatch(supplierActions.getAll());
    //props.dispatch(ingredientsActions.getAll());

    registerLocale('en', en)
    registerLocale('fr', fr)
    if(intl.locale == 'fr'){
        setDefaultLocale('fr',fr)
    }else{
        setDefaultLocale('en',en)
    }

  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({isMobile: window.innerWidth <= 760});
  }

  onNameChange = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            let value = e.target.value;
            ingredient.name = value;
            return ingredient;
        }else {
            return ingredient;
        }
      });
            
    this.setState({ ingredientsData: updates });

  }

  onPriceChange = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            let value = e.target.value;
            ingredient.price = value;
            return ingredient;
        }else {
            return ingredient;
        }
      });
            
    this.setState({ ingredientsData: updates });
  }


  onCategoryChange = (e,data,index) => {
    e.preventDefault();
    const {categoriesIngredient} = this.props;
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            if (data.value==""){
              ingredient.category =  null;
            }else{
              let category_id = data.value;
              let elem = categoriesIngredient.options.find(category => category.value == category_id)
              ingredient.category = {id:category_id,
                                     name:elem.text}
            }
            //ingredient.category =  {name:data.value};
            return ingredient;
        }else {
            return ingredient;
        }
      });          
    this.setState({ ingredientsData: updates });
  }

  onTaxChange = (e,data,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            let value = data.value;
            ingredient.tax = value;
            return ingredient;
        }else {
            return ingredient;
        }
      });
            
    this.setState({ ingredientsData: updates });
  }


  onAllergensChange = (e,data,index) => {
    e.preventDefault(); 
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
      if (index === idx) {
            if (data.value==""){
              ingredient.allergens = null;
            }else{
              ingredient.allergens = data.value;
            }
            return ingredient;
        }else {
            return ingredient;
        }
      });   
    this.setState({ ingredientsData: updates });
  }


  createAllergensList = (allergens) => {
    let listAllergens = [];  
    if (allergens){
      for (let elem of allergens){
        listAllergens.push({id:0,name:elem})
      }   
    }
    return listAllergens;    
  }

  onSupplierChange = (e,data,index) => {
    e.preventDefault();
    const {supplier} = this.props;
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
      if (index === idx) {
            if (data.value==""){
              ingredient.supplier = null;
            }else{
              //ingredient.supplier =  {name:data.value};
              let supplier_id = data.value;
              let elem = supplier.options.find(supplier => supplier.value == supplier_id)
              ingredient.supplier = {id:supplier_id,
                                     name: elem.text };
            }
            return ingredient;
        }else {
            return ingredient;
        }
      });   
    this.setState({ ingredientsData: updates });
  }

  onTypeQuantityChange = (e,data,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            ingredient.purchase_weight = "";
            ingredient.purchase_volume = "";
            ingredient.purchase_quantity = "";
            ingredient.type_quantity =  data.value;

            if (ingredient.type_quantity === 'volume')
                ingredient.purchase_volume = ingredient.quantity;
            else if (ingredient.type_quantity === 'weight')
                ingredient.purchase_weight = ingredient.quantity;
            else if  (ingredient.type_quantity === 'quantity')
                ingredient.purchase_quantity = ingredient.quantity;
            
            return ingredient;
        }else {
            return ingredient;
        }
      });   
    this.setState({ ingredientsData: updates });
  }

  onQuantityChange = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            ingredient.purchase_weight = "";
            ingredient.purchase_volume = "";
            ingredient.purchase_quantity = "";
            ingredient.quantity = e.target.value;

            if (ingredient.type_quantity === 'volume')
                  ingredient.purchase_volume = e.target.value.toLowerCase();
            else if (ingredient.type_quantity === 'weight')
                  ingredient.purchase_weight = e.target.value.toLowerCase();
            else if  (ingredient.type_quantity === 'quantity')
                  ingredient.purchase_quantity = e.target.value;

            return ingredient;
            
        }else {
            return ingredient;
        }
    });
    
    this.setState({ ingredientsData: updates });
  }


  onUnitsPackagingChange = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            ingredient.pack_units = e.target.value;
            return ingredient;
            
        }else {
            return ingredient;
        }
    });
    
    this.setState({ ingredientsData: updates });
  }

  onBatchNumberChange = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
      if (index === idx) {
          ingredient.batch_number = e.target.value;
          return ingredient;
          
      }else {
          return ingredient;
      }
  });
  
    this.setState({ ingredientsData: updates });
  }

  setExpirationDate (date,index){
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
      if (index === idx) {
          ingredient.date_expire = date;
          return ingredient;
          
      }else {
          return ingredient;
      }
  });
    this.setState({ ingredientsData: updates });
  }

  onFocusElsewhere(e) {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
            ingredient.highlighted = null;
            return ingredient;
      });
            
    this.setState({ ingredientsData: updates });
  }

  onFocusQuantityField = (e,index) => {
    e.preventDefault();
    const updates = this.state.ingredientsData.map((ingredient, idx) => {
        if (index === idx) {
            ingredient.highlighted = true;
            return ingredient;
        }else {
            ingredient.highlighted = null;
            return ingredient;
        }
      });
    
    this.setState({ ingredientsData: updates });
  }

  assertNameField(index){
    let ingredient = this.state.ingredientsData[index]

    if (ingredient &&  (ingredient.name != "")
          &&(trim(ingredient.name).length>0)
          &&(trim(ingredient.name).length<100))
      return true;
    return false;
  }

  assertAllNameField(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        if (ingredient &&  (ingredient.name != "")
            &&(trim(ingredient.name).length>0)
            &&(trim(ingredient.name).length<100)){
                values.push(true)
        }else{
            values.push(false)
        }         
    });
    return values.every(v => v===true)
  }

  assertSelectedWeight(index){
    let ingredient = this.state.ingredientsData[index]
    if (ingredient && ingredient.type_quantity != "")
      return true;
    return false;
  }

  assertAllSelectedWeight(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        if (ingredient && ingredient.type_quantity != ""){
                values.push(true)
        }else{
            values.push(false)
        }         
    });
    return values.every(v => v===true)
  }

  assertQuantityField(index){
    let ingredient = this.state.ingredientsData[index] 
    if (ingredient && ingredient.quantity != "")
      return true;
    return false;
  }

  assertAllQuantityField(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        if (ingredient && ingredient.quantity != ""){
                values.push(true)
        }else{
            values.push(false)
        }         
    });
    return values.every(v => v===true)
  }

  assertPackField(index){
    let ingredient = this.state.ingredientsData[index] 
    let units = Number(ingredient.pack_units)
    if ( isInteger(units)
         && (units > 0)
         && (Math.trunc(units).toString().length<7)
       )
      return true;
    return false;
  }

  assertAllPackField(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        let units = Number(ingredient.pack_units)
        if ( isInteger(units)
              && (units > 0)
              && Math.trunc(units).toString().length<7
              ){
                values.push(true)
        }else{
            values.push(false)
        }         
    });
    return values.every(v => v===true)
  }

  assertPriceField(index){
    let ingredient = this.state.ingredientsData[index]  
    let price = trim(ingredient.price).replace(/,/g, '.')
    if ((isFloat(price) || isNumeric(price))
      && (ingredient.price != "")
      && Math.trunc(ingredient.price).toString().length<7)
      return true;
    return false;
  }

  assertAllPriceField(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        let price = trim(ingredient.price).replace(/,/g, '.')
        if ((isFloat(price) || isNumeric(price))
            && (ingredient.price != "")
            && Math.trunc(ingredient.price).toString().length<7){
                values.push(true)
        }else{
            values.push(false)
        }         
    });
    return values.every(v => v===true)
  }

  assertQuantityPermittedIncluded(quantity,type_quantity){
    let isFound = false;
    let unitPart = "";
    // Filter some units not available for ingredients
    var volumes = unitVolume.filter((item) => !teaspoons.includes(item));
    volumes = volumes.filter((item) => !tablespoons.includes(item));
    let inputs= quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);

    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (type_quantity == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
        if (inputs <=0){
              return false;
        }
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          if (isNumeric(i) && i<=0 )
                return false;
          //console.log(unitPart);
        }
      }
    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
        if (isNumeric(i) && i<=0 )
              return false;
        }
    }

    if (type_quantity == 'volume'){
      for (let unit of volumes ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (type_quantity == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          break;
        }
     }
    }else {
        if (inputs.length > 1)
         return false
        isFound = true;
    }
    return isFound;
  }

  assertQuantityFieldsValidity(index){
    let ingredient = this.state.ingredientsData[index]
    let quantity = ingredient.quantity;
    let type_quantity = ingredient.type_quantity;
    let clean_quantity = trim(quantity).toLowerCase();
    //this.setState({ quantity: clean_quantity});
    if (!this.assertQuantityPermittedIncluded(clean_quantity,type_quantity)) return false;
    return true;
  }

  assertAllQuantityFieldsValidity(){
    let values = [];
    this.state.ingredientsData.map((ingredient, idx) => {
        let quantity = ingredient.quantity;
        let type_quantity = ingredient.type_quantity;
        let clean_quantity = trim(quantity).toLowerCase();
        if (!this.assertQuantityPermittedIncluded(clean_quantity,type_quantity)){
                values.push(false)
        }else{
            values.push(true)
        }         
    });
    return values.every(v => v===true)
  }

  onSubmit = (e,newroute) => {
    e.preventDefault();
    const {ingredientsData} = this.state;
    const { dispatch } = this.props;

    dispatch(alertActions.clear());
    this.setState({ submitted: true });

    if (!this.assertAllNameField()){
      //dispatch(alertActions.error('name field should be filled'));
      //toaster_error('Submit Error','name field should be filled');
      return;
    }

    if (!this.assertAllPriceField()){
      //dispatch(alertActions.error('price field should be numeric (<1000)'));
      //toaster_error('Submit Error','price field should be numeric (<1000)');
      return;
    }

    if (!this.assertAllSelectedWeight()){
      //dispatch(alertActions.error('weight type should be selected'));
      //toaster_error('Submit Error','weight type should be selected');
      return;
    }


    if (!this.assertAllQuantityField()){
      //dispatch(alertActions.error('Quantity field should be filled'));
      //toaster_error('Submit Error','Quantity field should be filled');
      return;
    }

    if (!this.assertAllQuantityFieldsValidity()){
      //dispatch(alertActions.error('Quantity field format invalid (follow instructions)'));
      //toaster_error('Submit Error','Quantity field format invalid (follow instructions)');
      return;
    }

    if (!this.assertAllPackField()){
      return;
    }

    if (ingredientsData && ingredientsData.length>0) {

      if (ingredientsData.length<51){

        let ingredientsDataCopy = cloneDeep(this.state.ingredientsData)

        const updates = ingredientsDataCopy.map((ingredient, idx) => {
          ingredient.name =  trim(ingredient.name.replace(/\n|\r|\t/g, " "));
          ingredient.price = Number(trim(ingredient.price).replace(/,/g, '.')).toFixed(3);
          ingredient.note = "";
          ingredient.purchase_weight = trim(ingredient.purchase_weight).replace(/,/g, '.');
          ingredient.purchase_volume = trim(ingredient.purchase_volume).replace(/,/g, '.');
          ingredient.purchase_quantity = trim(ingredient.purchase_quantity);
          ingredient.allergens = this.createAllergensList(ingredient.allergens)
          ingredient.tax = (ingredient.tax!=null)?ingredient.tax:0.00
          ingredient.pack_units = ingredient.pack_units
          ingredient.date_expire = ingredient.date_expire != "" ? dateWithoutTimezone(ingredient.date_expire):null
          ingredient.batch_number = ingredient.batch_number
          return ingredient;
        });          
        dispatch(ingredientsActions.add(updates,newroute));
      }else
      {
        toaster_error('Submit Error','Max 50 elements');
      }
    }
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.push('/ingredients');
  }

  hideModal() {
    this.setState({ activeModal: false });
    history.push('/ingredients');
  }


  currencySymbol(){    
    const {currency} = this.state;
    const {intl} = this.props;
    let output_currency_symbol = ''

    if (currency == 'None') 
        return output_currency_symbol

    let value = Intl.NumberFormat(intl.locale,{
                                              style:'currency',
                                              currency: currency,
                                              currencyDisplay: 'symbol',
                                  }).format(0)
    value = value.replace(/,/g, '.');
    value +='' 
    value = value.replace('.','')

    let regexp = /\D+/g;
    let symbol = [...value.matchAll(regexp)];      
    
    if (symbol && symbol.length > 0)
      output_currency_symbol = symbol[0][0]
    
    return '  '+output_currency_symbol
  }

  onDeleteRow= (index) => {
    var updates = [...this.state.ingredientsData];
    updates.splice(index, 1); 
    this.setState({ ingredientsData: updates });
  }

  removeEmptyRows= (e) => {
    var updates = [...this.state.ingredientsData];  
    let removeIndexes=[]
    this.state.ingredientsData.map((ingredient, idx) => {
        if (ingredient.name === "" 
                && ingredient.price==="" 
                && ingredient.type_quantity==="" 
                && ingredient.quantity==="" 
                && ingredient.category===null
                && ingredient.supplier===null
                && ingredient.allergens===null
                && ingredient.tax==null
                && ingredient.pack_units==1
                && ingredient.date_expire==""
                && ingredient.batch_number==""
                ) {
            removeIndexes.push(idx)
            //console.log(idx)
        }
    });

    for (let i=removeIndexes.length - 1;i>=0; i--){
        updates.splice(removeIndexes[i], 1);
        //console.log('removed' + removeIndexes[i])
    }

    //console.log(updates)

    if (removeIndexes.length > 0){
        //console.log(removeIndexes)
        if (updates.length == 0){
            updates = [        {   name: "",
                                   price: "",
                                   purchase_weight: "",
                                   purchase_volume: "",
                                   purchase_quantity: "",
                                   category : null,
                                   supplier : null,
                                   allergens : null,
                                   type_quantity: "",
                                   quantity: "",
                                   highlighted : false,
                                   tax: null,
                                   pack_units:1,
                                   date_expire:"",
                                   batch_number:"", }];
        }
        this.setState({ ingredientsData: updates })
    }
  }

  addRows(number) {
    var updates = this.state.ingredientsData;

    times(number, (index) => {
        updates = [...updates, {   name: "",
                                   price: "",
                                   purchase_weight: "",
                                   purchase_volume: "",
                                   purchase_quantity: "",
                                   category : null,
                                   supplier : null,
                                   allergens: null,
                                   type_quantity: "",
                                   quantity: "",
                                   highlighted : false, 
                                   tax: null,
                                   pack_units:1,
                                   date_expire:"",
                                   batch_number:"",}];
    });
    
    this.setState({ ingredientsData: updates });
    this.setState({ submitted: false });

  }

  addTableRows(){
    const { ingredientsData,submitted,isMobile} = this.state;
    const { categoriesIngredient,supplier,intl } = this.props;

    const renderLabel = (option) => ({
      color: option.color,
      content: option.text,
    })

    return ingredientsData.map((ingredient,index) => {
      return (
        <div>
            <Form.Group  /* widths='equal' */ style={{marginBottom:'0em'}}>
                <FormattedMessage id="newingredients.form.input.name.placeholder"
                                defaultMessage='Enter name *'>
                      {placeholder =>     
                          <Form.Input
                              type="text"
                              placeholder={placeholder}
                              id="name"
                              value={ingredient.name}
                              onChange={(e)=>this.onNameChange(e,index)}
                              onFocus={(e)=>this.onFocusElsewhere(e)} 
                              error={submitted && !this.assertNameField(index)}/>}
                  </FormattedMessage>
        {/*                       {submitted && !this.assertNameField()  &&
                    <label style={{color:'red', }}><i>
                        <FormattedMessage id="newingredient.form.name.error.message"
                                            defaultMessage='name is empty'/>
                    </i></label> 
                } */} 
                <FormattedMessage id="newingredients.form.tax.placeholder"
                                        defaultMessage='Tax'>
                             {placeholder =>     
                                <Form.Dropdown compact selection
                                            options={VAToptions} 
                                            type="text"
                                            placeholder={placeholder}
                                            id="tax"
                                            value={ingredient.tax}
                                            onChange={(e,data)=>this.onTaxChange(e,data,index)}
                                            onFocus={(e)=>this.onFocusElsewhere(e)}/>}
                </FormattedMessage>
                <FormattedMessage id="newingredients.form.category.placeholder"
                                defaultMessage='Category'>
                    {placeholder =>   
                            <Form.Dropdown compact selection search
                                    options={categoriesIngredient && categoriesIngredient.options} 
                                    value = {ingredient.category && ingredient.category.id} 
                                    placeholder={placeholder} 
                                    onChange={(e,data)=>this.onCategoryChange(e,data,index)}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                    clearable
                                />}
                </FormattedMessage> 
                <FormattedMessage id="newingredients.form.allergens.placeholder"
                                defaultMessage='Allergens'>
                    {placeholder =>   
                            <Form.Dropdown selection multiple search
                                     options={ [{key:'peanuts',text:intl.formatMessage(allergens.peanuts),value:'peanuts', color:'red'},
                                                {key:'celery',text:intl.formatMessage(allergens.celery),value:'celery',color:'olive'},
                                                {key:'crustaceans',text:intl.formatMessage(allergens.crustaceans),value:'crustaceans',color:'pink'},
                                                {key:'egg',text:intl.formatMessage(allergens.egg),value:'egg',color:'yellow'},
                                                {key:'fish',text:intl.formatMessage(allergens.fish),value:'fish',color:'teal'},
                                                {key:'gluten',text:intl.formatMessage(allergens.gluten),value:'gluten',color:'brown'},
                                                {key:'lupin',text:intl.formatMessage(allergens.lupin),value:'lupin',color:'grey'},
                                                {key:'milk',text:intl.formatMessage(allergens.milk),value:'milk',color:'blue'},
                                                {key:'molluscs',text:intl.formatMessage(allergens.molluscs),value:'molluscs',color:'purple'},
                                                {key:'mustard',text:intl.formatMessage(allergens.mustard),value:'mustard',color:'brown'},
                                                {key:'nuts',text:intl.formatMessage(allergens.nuts),value:'nuts',color:'violet'},
                                                {key:'sesame',text:intl.formatMessage(allergens.sesame),value:'sesame',color:'yellow'},
                                                {key:'soya',text:intl.formatMessage(allergens.soya),value:'soya',color:'green'},
                                                {key:'sulphites',text:intl.formatMessage(allergens.sulphites),value:'sulphites',color:'orange'}] } 
                                    value = {ingredient.allergens} 
                                    placeholder={placeholder} 
                                    onChange={(e,data)=>this.onAllergensChange(e,data,index)}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                    renderLabel={renderLabel} 
                                    />}
                </FormattedMessage>
                <FormattedMessage id="newingredients.form.supplier.placeholder"
                                defaultMessage='Supplier'>
                    {placeholder =>   
                            <Form.Dropdown selection search
                                    options={supplier && supplier.options} 
                                    value = {ingredient.supplier && ingredient.supplier.id} 
                                    placeholder={placeholder} 
                                    onChange={(e,data)=>this.onSupplierChange(e,data,index)}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                    clearable/>}
                </FormattedMessage> 
                <FormattedMessage id="newingredients.form.input.price.placeholder"
                                defaultMessage='Price {currency} *'
                                values={{currency:this.currencySymbol()}}>
                    {placeholder =>     
                        <Form.Input
                            type="text"
                            placeholder={placeholder}
                            id="price"
                            value={ingredient.price}
                            onChange={(e)=>this.onPriceChange(e,index)}
                            onFocus={(e)=>this.onFocusElsewhere(e)}
                            style={{minWidth:'6em',maxWidth:'6em'}}
                            error={submitted && !this.assertPriceField(index)}/>}
                </FormattedMessage>
        {/*                       {submitted && !this.assertPriceField()  &&
                    <label style={{color:'red', }}><i>
                        <FormattedMessage id="newingredient.form.price.error.message"
                                            defaultMessage='price is numeric'/>
                    </i></label>  */}
                <FormattedMessage id="newingredients.form.select.purchase.placeholder"
                                defaultMessage='Type *'>
                    {placeholder =>   
                            <Form.Select
                                    options={ [{key:'volume',text:<span>{/* <Icon name='flask' color='teal'/> */}{VOLUME}</span>,value:'volume'},
                                                {key:'weight',text:<span>{/* <Icon name='balance' color='teal'/> */}{WEIGHT}</span>,value:'weight'},
                                              {key:'quantity',text:<span>{/* <Icon name='copy' color='teal'/> */}{QUANTITY}</span>,value:'quantity'}] } 
                                    value = {ingredient.type_quantity} 
                                    placeholder={placeholder} 
                                    onChange={(e,data)=>this.onTypeQuantityChange(e,data,index)}
                                    style={{minWidth:'6em',maxWidth:'6em'}}
                                    error={submitted && !this.assertSelectedWeight(index)}
                                    />}
                </FormattedMessage>    
        {/*                     {submitted && !this.assertSelectedWeight()  &&
                    <label style={{color:'red', }}><i>
                        <FormattedMessage id="newingredient.form.select.purchase.error.message"
                                            defaultMessage='purchase type to select'/>
                    </i></label> 
                } */}

                <FormattedMessage id="newingredients.form.input.quantity.placeholder"
                                    defaultMessage='Quantity *'>
                        {placeholder =>     
                            <Form.Input compact
                                type="text"
                                placeholder={placeholder}
                                id="quantity"
                                value={ingredient.quantity}
                                onChange={(e)=>this.onQuantityChange(e,index)}
                                onFocus={(e)=>this.onFocusQuantityField(e,index)}
                                style={{minWidth:'8em',maxWidth:'8em'}}
                                error={submitted && (!this.assertQuantityField(index) || !this.assertQuantityFieldsValidity(index))}
                                />}
                    </FormattedMessage>
        {/*                           {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                        <label style={{color:'red', }}><i>
                            <FormattedMessage id="newingredient.form.quantity.error.message"
                                                defaultMessage='quantity format invalid (follow instructions)'/>
                        </i></label> 
                    } */}
                    <FormattedMessage id="newingredients.form.input.packaging.placeholder"
                                      defaultMessage='Units'>
                            {placeholder =>     
                                  <Form.Input compact
                                      type="number"
                                      placeholder={placeholder}
                                      id="units"
                                      min="1"
                                      value={ingredient.pack_units}
                                      onChange={(e)=>this.onUnitsPackagingChange(e,index)}
                                      //onFocus={(e)=>this.onFocusPackField(e)}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      style={{minWidth:'6em',maxWidth:'6em'}}
                                      error={submitted && !this.assertPackField(index)}
                                      icon={ingredient.pack_units == 1 ? <Icon name='tag' color="grey"/>: <Icon name='tags' color="teal"/>}
                                      iconPosition='left'
                                    />}
                    </FormattedMessage>
                    <Form.Field  style={{minWidth:'9em',maxWidth:'9em'}}>
                                 <FormattedMessage id="newingredients.form.dlc.placeholder"
                                                        defaultMessage='Expiration'>
                                          {placeholder =>     
                                                  <DatePicker selected={ingredient.date_expire} 
                                                              onChange={(date) => this.setExpirationDate(date,index)}
                                                              placeholderText = {placeholder} 
                                                              locale={intl.locale}
                                                              dateFormat="P"/>}
                                 </FormattedMessage>
                          </Form.Field>
                          <Form.Field style={{minWidth:'7em',maxWidth:'7em'}}>
                                 <FormattedMessage id="newingredients.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='Batch N°'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={ingredient.batch_number}
                                                          onChange={(e) => this.onBatchNumberChange(e,index)}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}/>}
                                 </FormattedMessage>
                  </Form.Field>                  
                <div style={{marginLeft:'0.5em',right:'0',cursor: 'pointer'}} onClick={() => {this.onDeleteRow(index)}}>
                { (index==0) && (ingredientsData.length > 1) && 
                    <Form.Button icon='delete'
                                 size='small'
                                 style={{marginTop: '1em',padding: 0,border: 'none',background: 'none','align-items': 'center','justify-content': 'center','display': 'flex'}} 
                                 onClick={() => {this.onDeleteRow(index)}}
                                 />}
                { (index==0) && (ingredientsData.length == 1) && 
                     <Form.Button //icon='delete' 
                                 size='small'
                                 style={{marginTop: '1em', padding: 0,border: 'none',background: 'none','align-items': 'center','justify-content': 'center','display': 'flex'}} 
                                 //onClick={() => {this.onDeleteRow(index)}} 
                                 />}
                { (index!=0) && 
                    <Form.Button icon='delete' 
                                 size='small'
                                 style={{marginTop: '1em',padding: 0,border: 'none',background: 'none','align-items': 'center','justify-content': 'center','display': 'flex'}} 
                                 onClick={() => {this.onDeleteRow(index)}} />}
                </div>
            </Form.Group>
            <b/>
                {ingredient.pack_units >= 1 && ingredient.quantity && 
                    <p style={{textAlign:'right',paddingRight:'10em',color:'grey',fontSize:'13px'}}>
                        <FormattedMessage id="newingredients.form.purchase.text1"
                                          defaultMessage='I buy'/> <span style={{color:'#00CC99',fontSize:'14px'}}>{ingredient.pack_units} x {ingredient.quantity}</span>{' '}
                        <FormattedMessage id="newingredients.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                         <span style={{color:'#00CC99',fontSize:'14px'}}> {ingredient.pack_units}</span>
            </p>}
            <Grid stackable>
                 <Grid.Row columns={3}  style={{marginTop:'0.15em'}}>
                     <Grid.Column  width={11} />
                     <Grid.Column  width={4} >
                        { ingredient.highlighted 
                                && ingredient.type_quantity == 'weight' 
                                &&  <Message size='tiny' style={{marginLeft:'1em'/* width:'22vh'*/}} icon='balance' floating compact>       
                                            <Icon name='balance' color='teal'/> 
                                        <Message.Content>
                                            <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                <ul>
                                                    {/* <br/> */}
                                                    <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                                        <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                                </ul> 
                                                        {WEIGHT_INSTRUCTION_LINE_EX}
                                        </Message.Content>                                     
                                    </Message>
                            }
                            {  ingredient.highlighted 
                                && ingredient.type_quantity == 'volume'   
                                    &&  <Message size='tiny' style={{marginLeft:'1em'/* width:'22vh'*/}} icon='flask' >       
                                            <Icon name='flask' color='teal'/> 
                                            <Message.Content>
                                                <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                <ul>
                                                    {/* <br/> */}
                                                    <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                                    <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                                        <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                                        <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                                        <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                                </ul> 
                                                {VOLUME_INSTRUCTION_LINE_EX}
                                            </Message.Content>                                     
                                        </Message>
                            }
                            { ingredient.highlighted 
                                    && ingredient.type_quantity == 'quantity' 
                                    &&  <Message size='tiny'  style={{marginLeft:'1em'/* width:'22vh'*/}} icon='copy'>       
                                            <Icon name='copy' color='teal'/> 
                                        <Message.Content>
                                                <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                                                <ul>
                                                {/*   <br/> */}
                                                    <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                                                </ul> 
                                                {QUANTITY_INSTRUCTION_LINE_EX}
                                            </Message.Content>                                     
                                        </Message>
                            }
                </Grid.Column>
                <Grid.Column  width={1} />
                </Grid.Row>
               </Grid>
            <br/>
          </div>
        );
    });
  }

  

  render() {
    const { alert,dispatch,adding,intl} = this.props;
    const {ingredientsData} = this.state;

    return (
    <SideLeftUncoverIngredients>
                <Modal open={this.state.activeModal}
                            dimmer='true' 
                            size='fullscreen' 
                            scrolling
                            style={styles.Modal} 
                            onClose={this.hideModal}
                            closeIcon>
                        <Modal.Content /*image*/ scrolling style={{height:'120vh'}}>
                          <Grid stackable style={styles.FontLato12}>
                                    <Grid.Row columns={1} >
                                        <Grid.Column width={16}>
                                            <Header as='h2'>
                                                <Icon name='th' size='mini' color='blue'/>
                                                <Header.Content>
                                                    <FormattedMessage id="newingredients.header.title"
                                                                    defaultMessage=' Add multiple ingredients'/>
                                                    {/*  <Header.Subheader>Manage here your list</Header.Subheader> */}
                                                </Header.Content>
                                            </Header>
                                            <br/>
                            {/*                 {alert.message  &&  alert.type == alertConstants.ERROR &&
                                                <Message  error size='small' > {alert.message}</Message>
                                            } */}
                                            </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>  
                                            <Grid.Column width={16} textAlign='right'>
                                                <Popup trigger={<Button icon circular style={{'background-color':'#aed3e3',
                                                                                              height: '52px',
                                                                                              width: '52px',
                                                                                              display: 'inline-block',
                                                                                              'font-size': '18px',
                                                                                              'font-weight': '800',
                                                                                              padding: '10px',
                                                                                              'border-radius': '50%'}}
                                                                              onClick= {(e) => this.addRows(1)}>
                                                                  <Icon name='copy outline'/>{' '}+1
                                                                </Button>}
                                                        content={<FormattedMessage id="newingredients.popup.view.add.one.text"
                                                                                    defaultMessage='Add 1 extra line'/>}
                                                        inverted
                                                        size='small'
                                                        position='top center'/>&nbsp;&nbsp;
{/*                                                 <Button icon circular style={{'background-color':'#fab77f',
                                                                            height: '52px',
                                                                            width: '52px',
                                                                            display: 'inline-block',
                                                                            'font-size': '18px',
                                                                            'font-weight': '800',
                                                                            padding: '10px',
                                                                            'border-radius': '50%'}}
                                                                          onClick= {(e) => this.addRows(1)}>
                                                  <Icon name='copy'/>{' '}+1
                                                </Button> */}
                                              <Popup trigger={ <Button icon circular style={{'background-color':'#86beda',
                                                                                            height: '52px',
                                                                                            width: '52px',
                                                                                            display: 'inline-block',
                                                                                            'font-size': '18px',
                                                                                            'font-weight': '800',
                                                                                            padding: '10px',
                                                                                            'border-radius': '50%'}}
                                                                          onClick= {(e) => this.addRows(3)}>
                                                                  <Icon name='copy outline'/>{' '}+3
                                                                </Button>}
                                                        content={<FormattedMessage id="newingredients.popup.view.add.three.text"
                                                                                    defaultMessage='Add 3 extra lines'/>}
                                                        inverted
                                                        size='small'
                                                        position='top center'/>&nbsp;&nbsp;
{/*                                                 <Button icon circular style={{'background-color':'#ff8d33',
                                                                            height: '52px',
                                                                            width: '52px',
                                                                            display: 'inline-block',
                                                                            'font-size': '18px',
                                                                            'font-weight': '800',
                                                                            padding: '10px',
                                                                            'border-radius': '50%'}}
                                                                          onClick= {(e) => this.addRows(3)}>
                                                  <Icon name='copy'/>{' '}+3
                                                </Button> */}
                                                <Popup trigger={ <Button icon circular style={{'background-color':'#009dcf',
                                                                                      height: '52px',
                                                                                      width: '52px',
                                                                                      display: 'inline-block',
                                                                                      'font-size': '18px',
                                                                                      'font-weight': '800',
                                                                                      padding: '10px',
                                                                                      'border-radius': '50%'}}
                                                                          onClick= {(e) => this.addRows(10)}>
                                                                  <Icon name='copy outline'/>{' '}+10
                                                                </Button>}
                                                        content={<FormattedMessage id="newingredients.popup.view.add.ten.text"
                                                                                    defaultMessage='Add 10 extra lines'/>}
                                                        inverted
                                                        size='small'
                                                        position='top center'/>
{/*                                                 <Button icon circular style={{'background-color':'#ff7000',
                                                                            height: '52px',
                                                                            width: '52px',
                                                                            display: 'inline-block',
                                                                            'font-size': '18px',
                                                                            'font-weight': '800',
                                                                            padding: '10px',
                                                                            'border-radius': '50%'}}
                                                                          onClick= {(e) => this.addRows(10)}>
                                                  <Icon name='copy'/>{' '}+10
                                                </Button> */}
{/*                                                 <Button circular style={{'background-color':'#fab77f',
                                                                            height: '50px',
                                                                            width: '50px',
                                                                            display: 'inline-block',
                                                                            'font-size': '14px',
                                                                            padding: '10px',
                                                                            'border-radius': '50%'}}  
                                                                    onClick= {(e) => this.addRows(1)}>+1</Button>
                                                <Button circular style={{'background-color':'#ff8d33',
                                                                        height: '50px',
                                                                        width: '50px',
                                                                        display: 'inline-block',
                                                                        'font-size': '14px',
                                                                        padding: '10px',
                                                                        'border-radius': '50%'}} 
                                                                 onClick= {(e) => this.addRows(3)}>+3</Button>
                                                <Button circular style={{'background-color':'#ff7000',                                                                        
                                                                         height: '50px',
                                                                         width: '50px',
                                                                         display: 'inline-block',
                                                                         'font-size': '14px',
                                                                         padding: '10px',
                                                                         'border-radius': '50%'}} 
                                                                 onClick= {(e) => this.addRows(10)}>+10</Button> */}
                                            </Grid.Column>
                                        </Grid.Row>
                                    <Grid.Row columns={2}>   
                                       <Grid.Column stackable /*stretched*/ width={16}>
                                          <Form stackable onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                                            <Table attached='top' basic style={{border:'none'}}>
                                               <Table.Body>
                                                  <OutsideClickHandler onOutsideClick={(e) => {this.onFocusElsewhere(e)}}>
                                                    <Segment /*size={'large'}*/ 
                                                            attached
                                                            stackable 
                                                            raised
                                                            style={{marginLeft:'1em',backgroundColor: '#fafafa',minWidth: '100%',width: '100%'/* , overflow: 'auto' */}}>  
                                                        {ingredientsData && this.addTableRows()}
                                                    </Segment>
                                                  </OutsideClickHandler>
                                                </Table.Body>    
                                            </Table>
                                             <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                        </Form>                   
                                      </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                          </Modal.Content>
                          <Modal.Actions>
                            <a href="#" 
                               onClick={(e) => this.removeEmptyRows(e)}
                               style={{marginRight:'3em', color:'#ff6459', 'text-decoration': 'none', 'font-size': '14px','font-weight': 600 }}
                               ><FormattedMessage id="newingredients.form.nutrition.modal.button.clean"
                                                  defaultMessage='Clean empty rows'/>
                            </a>
                            <Button style={styles.ButtonGrey} 
                                    onClick= {(e) => this.hideModal()}>
                                   {/*  <Icon name='exit' /> */}
                              <FormattedMessage id="newingredients.form.nutrition.modal.button.cancel"
                                              defaultMessage='Cancel'/>
                            </Button>
                            {adding && <Button  loading/*disabled={this.state.name.length === 0 
                                            || this.state.price.length === 0 
                                            ||(this.state.purchase_quantity.length===0 
                                                && this.state.purchase_volume.length=== 0 
                                                && this.state.purchase_weight.length=== 0)}*/
                                    onClick={(e) => {this.onSubmit(e,'/ingredients/')}} 
                                    style={styles.ButtonGreen}
                                    //color='teal' basic
                                    >
                                <FormattedMessage id="newingredients.button.submit"
                                                defaultMessage='Create Ingredients'/>
                            </Button>}
                            {!adding && <Button /*disabled={this.state.name.length === 0 
                                            || this.state.price.length === 0 
                                            ||(this.state.purchase_quantity.length===0 
                                                && this.state.purchase_volume.length=== 0 
                                                && this.state.purchase_weight.length=== 0)}*/
                                    onClick={(e) => {this.onSubmit(e,'/ingredients/')}} 
                                    style={styles.ButtonGreen}
                                    //color='teal' basic
                                    >
                                <FormattedMessage id="newingredients.button.submit"
                                                defaultMessage='Create Ingredients'/>
                            </Button>}
                          </Modal.Actions>
       </Modal> 
       {alert.message && toaster_dispatch(dispatch,alert,intl)}
     </SideLeftUncoverIngredients>
    );
  }
}






function mapStateToProps(state) {
  const { categoriesIngredient, dispatch,supplier, alert } = state;
  const {adding} = state.ingredients;
  return {
      supplier,
      adding,
      categoriesIngredient,
      dispatch,
      alert,
  };
}


const connectedNewMultipleIngredients = injectIntl(connect(mapStateToProps)(NewMultipleIngredients));
export { connectedNewMultipleIngredients as NewMultipleIngredients };
