import { saleConstants } from '../_constants';
import { saleService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const saleActions = {
    getAll,
    add,
    retrieveFromPos
};

function getAll(short=false) {
    return dispatch => {
        dispatch(request());

        saleService.getAll(short)
            .then(
                sales => dispatch(success(sales)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: saleConstants.GETALL_REQUEST } }
    function success(sales) { return { type: saleConstants.GETALL_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.GETALL_FAILURE, error } }
}

function add(recipe,link, isSameLink=0) {
    return dispatch => {
        dispatch(request());

        saleService.add(recipe)
            .then(
                recipes =>{
                    dispatch(success(recipes));
                    //dispatch(alertActions.success({title:'Recipe Action',content:'Recipe Added Successfully'}));
/*                     let title_text = <FormattedMessage id="recipe.actions.recipe.add.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="recipe.actions.recipe.add.success.content"
                                                         defaultMessage="Recipe Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "recipe.actions.recipe.add.success.title",
                                                   content:"recipe.actions.recipe.add.success.content"})); */

                     if (link){
                        if (isSameLink) history.push('/');
                        history.push(link);
                    }else{
                        //history.push('/');
                        history.push('/recipes');
                    }
                },
                error => {
                    dispatch(failure(error));
/*                     let title_text = <FormattedMessage id="sales.actions.recipe.add.error.title"
                                                       defaultMessage="Recipe Action Error"/>

                    if ("yield_count" in error) {
                        let content_text = <FormattedMessage id="sales.actions.recipe.add.error.yield_count"
                                                             defaultMessage="yield_count: {message}"
                                                             values={{message:error.yield_count[0]}}/>
                        dispatch(alertActions.error({title: "sales.actions.recipe.add.error.title",
                                                     content:"recipe.actions.recipe.add.error.yield_count"}));


                     }else if ("name" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.name"
                                                            defaultMessage="name: {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.name"}));
                     }else if ("yield_units" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.yieldunits"
                                                            defaultMessage="yield_units: {message}"
                                                            values={{message:error.yield_units[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.yieldunits"}));
                    }else if ("ingredients" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.ingredients"
                                                            defaultMessage="ingredients: {message}"
                                                            values={{message:error.ingredients[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.ingredients"}));
                    }else if ("subrecipes" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.subrecipes"
                                                            defaultMessage="subrecipes: {message}"
                                                            values={{message:error.recipes[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.subrecipes"}));
                    }else if ("category" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        let content_text = <FormattedMessage id="recipe.actions.recipe.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "recipe.actions.recipe.add.error.title",
                                                    content:"recipe.actions.recipe.add.error.non_field_errors"}));
                    }else{
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                   stringMode: true }));
                    } */ 
                }

            );
    };

    function request() { return { type: saleConstants.ADD_REQUEST } }
    function success(recipes) { return { type: saleConstants.ADD_SUCCESS, recipes } }
    function failure(error) { return { type: saleConstants.ADD_FAILURE, error } }
}

function retrieveFromPos() {
    return dispatch => {
        dispatch(request());

        saleService.retrieveFromPos()
            .then(
                sales => {
                    dispatch(success(sales))
                    history.push('/');
                    history.push('/sales');

                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: saleConstants.RETRIEVEALL_POS_REQUEST } }
    function success(sales) { return { type: saleConstants.RETRIEVEALL_POS_SUCCESS, sales } }
    function failure(error) { return { type: saleConstants.RETRIEVEALL_POS_FAILURE, error } }
}
