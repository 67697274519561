import React, { Component } from 'react';
import { Table,Grid,Button,Header,Modal,Form,Popup,Icon,Message,Image,Dimmer/*,Loader*/} from 'semantic-ui-react'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import SideLeftUncoverProfile from './SidebarProfile';
import {userActions,paymentActions } from '../_actions';
import {toaster_dispatch,trim,isEmpty,history,ADMIN,OWNER/*,Capitalize,isNumeric*/} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'
/*import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css"
import './PhoneInput.css' */
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

class Organisation extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
            name: "",
            contact: "",
            phone: "",
            email:"",
            note:"",
            activeModalDelete: null,
            activeModalEdit: null,
            activeModalCreate: null,
            activeModalPremium: false,
            submitted: false,
            active_role:  userIn.user.role,
            active_org: userIn.user.active_org,
            org_member: userIn.user.active_org?true:false, 
        }
        this.hideModal = this.hideModal.bind(this);
        props.dispatch(userActions.getOrganisation());
        props.dispatch(paymentActions.getCustomerMembership());
    }

    OnNameChange = (e) => {
         this.setState({ name: e.target.value });
    }

    OnContactChange = (e) => {
         this.setState({ contact: e.target.value });
    } 
    
    OnEmailChange = (e) => {
         this.setState({ email: e.target.value });
    }

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ phone: number });
    }

   OnNoteChange = (e) => {
    this.setState({ note: e.target.value });
    }


    addOrganisation = (e) => {
        e.preventDefault();
        const { name/*,email*/} = this.state;
        const {customer,users} = this.props;
        let user = null;
        let membership = null;
        let nb_actual_orgs = 0;
        let create_org_permitted = false;

        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        this.setState({ submitted: true });

/*         if (!this.handleValidation()){
            return;
        } */

        if (!this.handleNameValidation()){ 
            return;
        }

        if (users && users.orgs_items){
            nb_actual_orgs = users.orgs_items.length

            let nb_subscription = 1
            if (customer && customer.quantity){
                nb_subscription = customer.quantity
            }

            if (nb_actual_orgs < nb_subscription)
            {
                create_org_permitted = true
            }
        }


        if (membership && user &&
            // ((membership.membership_type=="Entreprise") /*|| (user.bypass==true)*/))
            membership.membership_type=="Entreprise" && create_org_permitted)
        {
            if (name && name !='' /*&& this.handleValidation()*/){
                const org_infos = {
                             name:trim(name),
                             //email:trim(email),
                }
                this.props.dispatch(userActions.addOrganisation(org_infos));
                this.setState({ submitted: false });
                this.props.dispatch(userActions.getOrganisation());
            }
        }else {
            this.hideModal()
            this.setState({ activeModalPremium: true});
        }  
    }
    
    editOrganisation(event,index) {
        event.preventDefault();
        const { name,email,phone,note} = this.state;
        const {customer} = this.props;
        let user = null;
        let membership = null;

        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        this.setState({ submitted: true });

        if (!this.handleValidation()){
            return;
        }


        if (membership && user &&
            ((membership.membership_type=="Entreprise") /*|| (user.bypass==true)*/)) 
        {  
            if (name && name !='') {
                const org_infos = {
                             name:trim(name),
                             email:trim(email),
                             phone:phone,
                             note:note,
                }
                this.props.dispatch(userActions.editOrganisation(index,org_infos));
                this.setState({ submitted: false });
            }      

        }else {
            this.hideModal()
            this.setState({ activeModalPremium: true});
        }  
    }

/*     deleteSupplier (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(supplierActions.delete(index))
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
    } */

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,org) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ name: org.name });
        this.setState({ phone: org.phone });
        this.setState({ email: org.email });
        this.setState({ note: org.note });
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ activeModalCreate: null });
        this.setState({ activeModalPremium: false });
        this.setState({ submitted: false });
        this.setState({ name: '' });
        this.setState({ email: ''});
        this.setState({ phone: ''});
        this.setState({ note: '' });
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }


    handleNameValidation(){
        const { name } = this.state;
        if(isEmpty(trim(name))){
            return false
        }
        
        return true
    }

    handleValidation(){
        const { phone } = this.state;

        if (!this.handleNameValidation()){ 
            return false;
        }

        if (!this.handlePhoneValidation(phone)){ 
            return false;
        }

/*         if (!this.handleEmailValidation()){ 
            return false;
        } */
        return true;
    }

    handleEmailValidation(){
        const { email} = this.state;
        if(!email){
            return false;
        }

        if(email !== ""){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos 
                && lastAtPos > 0 
                && email.indexOf('@@') == -1 
                && lastDotPos > 2 
                && (email.length - lastDotPos) > 2)){
              return false;
            }
        }
        return true;
    }

    handlePhoneValidation(number){
        
        if(number && number !== ""){
            return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
        }

        return true;
    }
    
    createOrganisation(event){
        event.preventDefault();
        this.setState({activeModalCreate: true});
    } 

    addOrganisationRows(orgs){
        const { name,email,phone,note,submitted,active_org} = this.state;
        const {intl}  = this.props;
        return orgs.map((org,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell >
                            <a href="#" 
                               onClick={(event)=>this.editClickHandler(event,index,org)}>{org.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell>{org.email}</Table.Cell>
                        <Table.Cell>{org.phone}</Table.Cell>
                        {active_org && (active_org.id == org.id) && 
                            <Table.Cell textAlign='center'><Icon color='teal' name='check' size='large' /></Table.Cell>}
                       {(!active_org || (active_org.id != org.id)) &&
                            <Table.Cell textAlign='center'></Table.Cell>}
                        <Table.Cell textAlign='right'>
                                <Icon className="edit outline" size='large' color='blue' onClick={(event)=>this.editClickHandler(event,index,org)}/>
{/*                             <Button basic id={index} className="ui icon button circular" color='blue' size='small'
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash alternate outline icon"></i>
                            </Button> */}
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='building outline' content={<FormattedMessage id="organisations.modal.edit.title"
                                                                                defaultMessage='Edit Organization'/>} />
                                <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.name"
                                                                              defaultMessage='Name *'/></label>
                                                    <FormattedMessage id="organisations.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='building outline'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="organisations.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        //type="email"
                                                                        id="email" 
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        //error={submitted && !this.handleEmailValidation()}
                                                                        />}
                                                        </FormattedMessage>
                                                </Form.Field>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
{/*                                                    <FormattedMessage id="organisations.modal.form.input.phone.placeholder"
                                                                    defaultMessage='phone'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="phone" 
                                                                    icon='phone'
                                                                    iconPosition='left'
                                                                    value={phone}
                                                                    onChange={this.OnPhoneChange}
                                                                    error={submitted && !this.handlePhoneValidation(phone)}/>}
                                                    </FormattedMessage> */}
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="organisations.modal.form.input.phone.error.message"
                                                                            defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.note"
                                                                              defaultMessage='Technical note'/></label>
                                                   <FormattedMessage id="organisations.modal.form.input.note.placeholder"
                                                                    defaultMessage='note'>
                                                        {placeholder =>          
                                                                <Form.TextArea type="text" 
                                                                    placeholder={placeholder}
                                                                    id="note" 
                                                                    icon='text'
                                                                    iconPosition='left'
                                                                    value={note}
                                                                    rows='5'
                                                                    maxlength="990"
                                                                    onChange={this.OnNoteChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <br/>
                                         </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button style={styles.ButtonRed} onClick={this.hideModal}>
                                        <FormattedMessage id="organisations.supplier.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen}
                                        labelPosition='right' 
                                        content={<FormattedMessage id="organisations.supplier.modal.rename.button.save"
                                                                    defaultMessage='Save'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editOrganisation(event,org.id)}/>
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
{/*                              <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="suppliers.supplier.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="suppliers.supplier.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:org.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="suppliers.supplier.modal.delete.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button basic color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="suppliers.supplier.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteSupplier(event,org.id)}/>
                                    </Modal.Actions>
                            </Modal>  */}
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }


    render() { 
        const { users,alert,dispatch,customer} = this.props;
        const { name,email,phone,note,submitted,active_role,org_member} = this.state;
        let className = "floating-menu";

        let membership = null;

        if (customer){
            membership = customer.membership;
        }

        return ( 
            <div>
                <SideLeftUncoverProfile>
                <Grid stackable style={styles.FontLato12}>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h1'>
                                        <Icon name='building outline' color='blue'  size='huge' />
                                        <Header.Content>
                                            <FormattedMessage id="organisations.organisation.page.title"
                                                              defaultMessage='Organization(s)'/>
                                            <Header.Subheader><FormattedMessage id="organisations.organisation.page.subtitle"
                                                              defaultMessage='Manage your companies'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={1}/>
                    {org_member && active_role<ADMIN && 
                        <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Message compact info icon size='large'>
                                    <Icon name='settings' color='teal'/>
                                    <Message.Content>
                                        <Message.Header>
                                            <FormattedMessage id="organisations.message.title"
                                                                defaultMessage='Settings'/>
                                        </Message.Header>
                                        <Icon name='lock' color='teal'/>
                                        <FormattedMessage id="organisations.message.details"
                                                          defaultMessage='You should have Administrator privilege.' />
                                        <br/> <br/>
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                    </Grid.Row>}
                    <Grid.Row columns={1} >
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                            <Popup trigger=
                                                    { <Button circular 
                                                            color='teal'
                                                            size='big' 
                                                            icon='add' 
                                                            onClick={(event)=>this.createOrganisation(event)}
                                                            disabled = {org_member && active_role<OWNER}
                                                            ></Button>}
                                                    content={<FormattedMessage id="organisations.floatingmenu.item1.title"
                                                                                defaultMessage='Add organization'/>}
                                                    position='left center'
                                                    inverted
                                            />
                                    </div>
                                </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column mobile={18} tablet={16} computer={14}> 
                            <Dimmer.Dimmable dimmed={org_member && active_role<ADMIN} blurring>
                                        <Dimmer active={org_member && active_role<ADMIN} blurring inverted>
                                        </Dimmer>
                            <Table unstackable
                                   compact 
                                   striped 
                                   singleLine 
                                   style={styles.FontLato13Border}>
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell width={5}>
                                             <FormattedMessage id="organisations.organisation.currentorg.table.row.name"
                                                              defaultMessage='Name' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={4}>
                                             <FormattedMessage id="organisations.organisation.currentorg.table.row.company_email"
                                                              defaultMessage='Email' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                             <FormattedMessage id="organisations.organisation.currentorg.table.row.company_phone"
                                                              defaultMessage='Phone' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='center'>
                                             <FormattedMessage id="organisations.organisation.currentorg.table.row.active_status"
                                                              defaultMessage='Current' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {users && users.orgs_items && users.orgs_items.length == 0 &&
                                    <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="organisations.table.header.row.noitems"
                                                                            defaultMessage='No organization'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                    </Table.Row>}
                                {users && users.orgs_items && //org_member &&
                                      this.addOrganisationRows(users.orgs_items)}
                                </Table.Body>
                            </Table>
                            </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                <Modal open={this.state.activeModalCreate === true}
                       dimmer='default' 
                       size='tiny'
                       style={styles.Modal} 
                       onClose={this.hideModal}>
                       <Header icon='building outline' content={<FormattedMessage id="organisations.modal.create.title"
                                                                                defaultMessage='Add an organization'/>} />
                        <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field> 
                                                    <Message compact icon warning list>
                                                        <Icon name='warning' color='teal'/>
                                                        <Message.Content>
                                                            <Message.Header>
                                                                <FormattedMessage id="organisations.organisation.notice.important.title"
                                                                                    defaultMessage='Important before proceed'/>
                                                            </Message.Header>
                                                            <Message.List>
                                                            <Message.Item>
                                                            <FormattedMessage id="organisations.organisation.notice.important.details"
                                                                             defaultMessage="An organization can't be deleted (proceed only if needed)." />
                                                            
                                                            </Message.Item>
                                                            <Message.Item><FormattedMessage id="organisations.organisation.notice.important.subdetails"
                                                                             defaultMessage="One organization is permitted per Enterprise plan (choose more when subscribing or update subscription if needed)." />
                                                            </Message.Item>
                                                            <Message.Item>
                                                            <FormattedMessage id="organisations.organisation.notice.important.subsubdetails"
                                                                             defaultMessage="Each organization (or company) means separate recipes, ingredients, billing ..." />
                                                            </Message.Item>
                                                            </Message.List> 
                                                        </Message.Content>
                                                   </Message>
                                                </Form.Field> 
                                                <br/>
                                                <Form.Field /* width='eight' */> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.name"
                                                                              defaultMessage='Name *'/></label>
                                                    <FormattedMessage id="organisations.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='building outline'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
{/*                                                 <Form.Field /* width='eight' >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="organisations.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="organisations.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        name='email'
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        //error={submitted && !this.handleEmailValidation()}
                                                                        />}
                                                        </FormattedMessage>
                                                </Form.Field> */}
                                                <br/>
                                                <br/>
                                        </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button style={styles.ButtonRed} labelPosition='right' icon='cancel' onClick={this.hideModal}
                                            content={<FormattedMessage id="organisations.organisation.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>}/>
                                    <Button style={styles.ButtonGreen} 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="organisations.organisation.modal.create.button.create"
                                                                    defaultMessage='Create'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.addOrganisation(event)}
                                        />
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="organisations.header.title"
                                                    defaultMessage='Manage organization(s)'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato14Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="organisations.premium.message.upgradeplan"
                                                                defaultMessage='ENTERPRISE Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="organisations.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                    {customer && customer.quantity && customer.membership.membership_type == 'Entreprise' &&
                                                            <p style={styles.LabelGreySmall}>{'  '}<FormattedMessage id="organisations.premium.message.plan.include"
                                                                                                            defaultMessage="include"/>
                                                                {' '}{customer.quantity}{'  '}
                                                                <FormattedMessage id="organisations.premium.message.plan.organisation"
                                                                                  defaultMessage="organization(s) (or company(s)) "/></p>}
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato14Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="organisations.premium.button.chooseplan0"
                                                        defaultMessage='Select premium plan (and choose number of organization(s))'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="organisations.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="organisations.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Grid>
                </SideLeftUncoverProfile>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { users,alert,dispatch } = state;
    const { customer } = state.payment;
    return {
        users,
        customer,
        alert,
        dispatch
    };
}

const connectedOrganisation = injectIntl(connect(mapStateToProps)(Organisation));
export { connectedOrganisation as Organisation };
 