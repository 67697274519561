import { nutritionConstants } from '../_constants';
import { nutritionService } from '../_api';

export const nutritionActions = {
    search,
    getNutrition,
};

function search(item,locale) {
    return dispatch => {
        dispatch(request());

        nutritionService.search(item,locale)
            .then(
                payload => dispatch(success(payload)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: nutritionConstants.SEARCH_REQUEST } }
    function success(payload) { return { type: nutritionConstants.SEARCH_SUCCESS, payload } }
    function failure(error) { return { type: nutritionConstants.SEARCH_FAILURE, error } }
}


function getNutrition(ndbno,locale) {
    return dispatch => {
        dispatch(request());

        nutritionService.getNutrition(ndbno,locale)
            .then(
                payload => dispatch(success(payload)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: nutritionConstants.GET_REQUEST } }
    function success(payload) { return { type: nutritionConstants.GET_SUCCESS, payload } }
    function failure(error) { return { type: nutritionConstants.GET_FAILURE, error } }
}