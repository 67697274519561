import React from 'react'
import { Grid, Button, Message, Form, Header,Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { paymentActions,alertActions } from '../_actions';
import { alertConstants } from '../_constants';
import {FormattedMessage} from 'react-intl';
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'

class ActivateVoucher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          no_parameters : false,
          email: '',
          submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
      
        if (this.props.location && this.props.location.search){
            this.setState({ no_parameters: false });
        }else{
          this.setState({ no_parameters: true });
        }
      }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { dispatch,location } = this.props;
        const { email } = this.state;

        dispatch(alertActions.clear());
        this.setState({ submitted: true });

        if (email) {
            if (location && location.search){
                console.log(this.props.location.search)
                dispatch(paymentActions.activateOfferVoucher(location.search + '&e='+ email));

                this.setState({ submitted: false });
                this.setState({ email: '' });
            }
        }
    }

    render() {
        const { alert,validating_voucher,validated_voucher,error_voucher,error } = this.props;
        const { no_parameters,email,submitted } = this.state;
        //console.log(alert)
        
        return (
            <div className='login-form'>
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
                <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}}>
                  <Grid.Column style={{ top: "25%", maxWidth: 450 }}>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
{/*                       {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      } 
                      {alert.message && alert.type == alertConstants.ERROR && 
                            <Header as='h4' color='red' textAlign='center'>
                                <div className={`alert ${alert.type}`}>{alert.message}</div>
                            </Header> 
                      }*/}
                      {' '}{!no_parameters && <FormattedMessage id="activateVoucher.form.title"
                                             defaultMessage='Enter your email to validate your voucher'/>}
                    </Header>
                    {!no_parameters &&
                    <Form size='large' onSubmit={this.handleSubmit}>
                        <FormattedMessage id="activateVoucher.form.email.placeholder"
                                                defaultMessage='Email'>
                                {placeholder => 
                                        <Form.Input
                                            fluid
                                            icon='mail'
                                            iconPosition='left'
                                            placeholder={placeholder}
                                            type='email'
                                            name='email'
                                            value={email}
                                            onChange={this.handleChange} 
                                        />}
                            </FormattedMessage>
                            {submitted && !email &&
                            <label style={{color:'red'}}><i>
                                <FormattedMessage id="activateVoucher.form.email.error.message"
                                                    defaultMessage='Email is required'/> 
                                </i></label>
                            }
                            <Button color='blue' 
                                fluid size='large' 
                                type='submit'>
                                <FormattedMessage id="activateVoucher.form.button.continue"
                                                   defaultMessage='Submit'/>
                            </Button>
                      </Form>}
                      <br/><br/>
                    {validating_voucher && 
                        <img alt="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    {validated_voucher && 
                         <div>
                         <p style={{color:'green'}}>
                          <FormattedMessage id="activateVoucher.form.ok"
                                            defaultMessage='Voucher applied with success on your account'/>
                         </p>
                          <p style={{color:'green'}}>                  
                          <FormattedMessage id="activateVoucher.form.connect.message"
                                          defaultMessage='Log in to subscribe and benefit.'/>
                        </p>
                        </div>
                    }
                    {error_voucher && 
                        <span style={{color:'red'}}>
                          {error}
{/*                          <FormattedMessage id="validateVoucher.form.ko"
                                             defaultMessage='Invalid voucher'/> */}
                        </span>
                    }
                   {no_parameters && 
                        <span style={{color:'red'}}>
                        <FormattedMessage id="activateVoucher.form.invalid"
                                          defaultMessage='Invalid request'/>
                        </span>
                    }
                    <Message>
                      <Link to="/login"><FormattedMessage id="activateVoucher.form.link.back"
                                                          defaultMessage='Return to log in'/></Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 800,  height: '100vh'}}> 
                     <img src={img_window}  alt='window' style={{width: '100v',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <img src={img_logo}  alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                      <Header as='h3' color='grey' textAlign='center'>
{/*                         {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }  
                       {alert.message && alert.type == alertConstants.ERROR && 
                            <Header as='h4' color='red' textAlign='center'>
                                <div className={`alert ${alert.type}`}>{alert.message}</div>
                            </Header> 
                        }*/}
                        {' '}{!no_parameters && <FormattedMessage id="activateVoucher.form.title"
                                             defaultMessage='Enter your email to validate your voucher'/>}
                      </Header>
                      {!no_parameters &&
                      <Form size='large' onSubmit={this.handleSubmit}>
                        <FormattedMessage id="activateVoucher.form.email.placeholder"
                                                defaultMessage='Email'>
                                {placeholder => 
                                        <Form.Input
                                            fluid
                                            icon='mail'
                                            iconPosition='left'
                                            placeholder={placeholder}
                                            type='email'
                                            name='email'
                                            value={email}
                                            onChange={this.handleChange} 
                                        />}
                            </FormattedMessage>
                            {submitted && !email &&
                            <label style={{color:'red'}}><i>
                                <FormattedMessage id="activateVoucher.form.email.error.message"
                                                    defaultMessage='Email is required'/> 
                                </i></label>
                            }
                            <Button color='blue' 
                                fluid size='large' 
                                type='submit'>
                                <FormattedMessage id="activateVoucher.form.button.continue"
                                                   defaultMessage='Submit'/>
                            </Button>
                      </Form>}
                      <br/><br/>
                       {validating_voucher && 
                          <img  alt='wait' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      }
                      {validated_voucher && 
                         <div>
                         <p style={{color:'green'}}>
                          <FormattedMessage id="activateVoucher.form.ok"
                                            defaultMessage='Voucher applied with success on your account'/>
                         </p>
                          <p style={{color:'green'}}>                  
                          <FormattedMessage id="activateVoucher.form.connect.message"
                                          defaultMessage='Log in to subscribe and benefit.'/>
                        </p>
                        </div>
                      }
                      {error_voucher && 
                          <span style={{color:'red'}}>
                            {error}
{/*                             <FormattedMessage id="validateVoucher.form.ko"
                                              defaultMessage='Invalid voucher'/> */}
                          </span>
                      }
                      {no_parameters && 
                        <span style={{color:'red'}}>
                        <FormattedMessage id="activateVoucher.form.invalid"
                                          defaultMessage='Invalid request'/>
                        </span>
                      } 
                      <Message>
                            <Link to="/login"><FormattedMessage id="activateVoucher.form.link.back"
                                                                defaultMessage='Return to log in'/></Link>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
            </div>
          );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { validating_voucher,validated_voucher,error_voucher,error } = state.payment;
    return {
        alert,
        validating_voucher,
        validated_voucher,
        error_voucher,
        error
    };
}

const connectedActivateVoucher = connect(mapStateToProps)(ActivateVoucher);
export { connectedActivateVoucher as ActivateVoucher };