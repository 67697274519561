export const supplierConstants = {
    GETALL_REQUEST: 'SUPPLIER_GETALL_REQUEST',
    GETALL_SUCCESS: 'SUPPLIER_GETALL_SUCCESS',
    GETALL_FAILURE: 'SUPPLIER_GETALL_FAILURE',

    GET_REQUEST: 'SUPPLIER_GET_REQUEST',
    GET_SUCCESS: 'SUPPLIER_GET_SUCCESS',
    GET_FAILURE: 'SUPPLIER_GET_FAILURE',

    ADD_REQUEST: 'SUPPLIER_ADD_REQUEST',
    ADD_SUCCESS: 'SUPPLIER_ADD_SUCCESS',
    ADD_FAILURE: 'SUPPLIER_ADD_FAILURE',

    UPDATE_REQUEST: 'SUPPLIER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SUPPLIER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SUPPLIER_UPDATE_FAILURE',

    DELETE_REQUEST: 'SUPPLIER_DELETE_REQUEST',
    DELETE_SUCCESS: 'SUPPLIER_DELETE_SUCCESS',
    DELETE_FAILURE: 'SUPPLIER_DELETE_FAILURE',
    
    COUNT_REQUEST: 'SUPPLIER_COUNT_REQUEST',
    COUNT_SUCCESS: 'SUPPLIER_COUNT_SUCCESS',
    COUNT_FAILURE: 'SUPPLIER_COUNT_FAILURE',

    //COUNT_INGREDIENTS_REQUEST: 'SUPPLIER_COUNT_INGREDIENTS_REQUEST',
    //COUNT_INGREDIENTS_SUCCESS: 'SUPPLIER_COUNT_INGREDIENTS_SUCCESS',
    //COUNT_INGREDIENTS_FAILURE: 'SUPPLIER_COUNT_INGREDIENTS_FAILURE',
};