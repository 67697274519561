import { userConstants } from '../_constants';
import { userService } from '../_api';
import { alertActions,paymentActions } from './';
import { history,isEmpty,OWNER } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const userActions = {
    login,
    logout,
    register,
    registerConfirm,
    get,
    update,
    //getAll,
    delete: _delete,
    verify,
    renew,
    passwordReset,
    passwordConfirm,
    passwordChange,
    addLead,
    registerFromAds,
    getOrganisation,
    addOrganisation,
    editOrganisation,
    getInvitation,
    sendInvitation,
    updateInvitation,
    deleteInvitation,
    updateLogo,
    editOrganisationLogo
/*     connectPOS,
    getPOS,
    deletePOS,
    updatePOS, */
};


/* export const  WELCOME_MESSAGE = <FormattedMessage id="user.actions.login.message.welcome.content"
                                                  defaultMessage="Welcome to Ratatool"/>; */

function login(username, password,link, state) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    if (link){
                        history.push({'pathname': link,'state':state});
                    } else{
                        history.push('/dashboard');
                    }

                    let title_text = <FormattedMessage id="user.actions.login.message.welcome.title"
                                                       defaultMessage="Hello {username} !!!"
                                                       values={{username:user.user.username}}/>
                    let content_text = <FormattedMessage id="user.actions.login.message.welcome.content"
                                                       defaultMessage="Welcome to Ratatool"/>
                                                       
                    dispatch(alertActions.success({title: "user.actions.login.message.welcome.title",
                                                   content:"user.actions.login.message.welcome.content",
                                                   vars: user.user.username}));
                    //dispatch(paymentActions.getCustomerMembership());                        
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
/*                     dispatch(alertActions.error(<FormattedMessage id="user.actions.login.message.error"
                                                                  defaultMessage="Unable to log in with provided user/password"/>)); */

                    let context_text = <FormattedMessage id="user.actions.login.message.error.content"
                                                                  defaultMessage="Please try again"/>
                    let title_text = <FormattedMessage id="user.actions.login.message.error.title"
                                                                   defaultMessage="Unable to log in with provided user/password (remember to confirm your email)"/>
             
                    dispatch(alertActions.error({title:'user.actions.login.message.error.title',
                                                 content:'user.actions.login.message.error.content',
                                                 stringMode: false}));
                }
            )
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
   userService.logout();
   return { type: userConstants.LOGOUT }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                
                user => { 
                    //console.log(user)
                    dispatch(success(user));
                    //history.push('/login');
                    //dispatch(alertActions.success('Registration successful'));
                    //Case with email confirmation
                    //dispatch(alertActions.error('Check your mail to confirm your registration'));
                    //history.push('/pwdconfirmdone',{message: 'Check your mail to confirm your registration'});

                    let register_title =  <FormattedMessage id="user.actions.register.title"
                                                            defaultMessage="Register"/>
                    let register_message = <FormattedMessage id="user.actions.register.checkmail"
                                                            defaultMessage="Check your mail to confirm your registration (MANDATORY)"/>

                    history.push('/confirmdone?register',
                                        { title: "user.actions.register.title",
                                          message: "user.actions.register.checkmail",
                                          login:false});
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    //dispatch(alertActions.error('Password or Email is invalid'));
                    //console.log(error);

                    let context_text = <FormattedMessage id="user.actions.register.message.error.content"
                                                         defaultMessage="Please update"/>

                     if ("email" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.email.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.email[0]}}/>)); */


                        let title_text = <FormattedMessage id="user.actions.register.message.error.mail.title"
                                                            defaultMessage="{message}"
                                                            values={{message:error.email[0]}}/>
                 
                        dispatch(alertActions.error({title:'user.actions.register.message.error.mail.title',
                                                     content:'user.actions.register.message.error.content',
                                                     stringMode: false,
                                                     vars: error.email[0]}));
                        //A user is already registered with this e-mail address.'
                     }else if ("username" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.username.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.username[0]}}/>)); */

                        let title_text = <FormattedMessage id="user.actions.register.message.error.username.title"
                                                           defaultMessage="{message}"
                                                          values={{message:error.username[0]}}/>
                           
                         dispatch(alertActions.error({title:'user.actions.register.message.error.username.title',
                                                      content:'user.actions.register.message.error.content',
                                                      stringMode: false,
                                                      vars: error.username[0]}));
                     }else if ("password1" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.password1.title"
                                                                        defaultMessage="{message}"
                                                                        values={{message:error.password1[0]}}/>)); */

                          let title_text = <FormattedMessage id="user.actions.register.message.error.password1.title"
                                                             defaultMessage="{message}"
                                                            values={{message:error.password1[0]}}/>
                                        
                          dispatch(alertActions.error({title:'user.actions.register.message.error.password1.title',
                                                      content:'user.actions.register.message.error.content',
                                                      stringMode: false,
                                                      vars: error.password1[0]}));
                        //This password is too short. It must contain at least 8 characters.
                    }else if ("password2" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.password2.title"
                                                                        defaultMessage="{message}"
                                                                        values={{message:error.password2[0]}}/>)); */
                          let title_text = <FormattedMessage id="user.actions.register.message.error.password2.title"
                                                                        defaultMessage="{message}"
                                                                       values={{message:error.password2[0]}}/>
                                                   
                          dispatch(alertActions.error({title:'user.actions.register.message.error.password2.title',
                                                       content:'user.actions.register.message.error.content',
                                                       stringMode: false,
                                                       vars: error.password2[0]}));                                             
                        //This password is too short. It must contain at least 8 characters.
                    }else if ("non_field_errors" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.nonfield.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.non_field_errors[0]}}/>)); */
                        
                        let title_text = <FormattedMessage id="user.actions.register.message.error.nonfield.title"
                                                           defaultMessage="{message}"
                                                           values={{message:error.non_field_errors[0]}}/>
                                                 
                        dispatch(alertActions.error({title:'user.actions.register.message.error.nonfield.title',
                                                     content:'user.actions.register.message.error.content',
                                                     stringMode: false,
                                                     vars: error.non_field_errors[0]}));   
                        //The two password fields didn't match.
                        //Email is not verified
                     }
                }
            );
    };
    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}



function registerFromAds(user) {
    return dispatch => {
        dispatch(request(user));

        let origin_password = user.password1
        let origin_username = user.username

        userService.register(user)
            .then(
                
                user => { 
                    //console.log(user)
                    dispatch(success(user));
                    //history.push('/login');
                    //dispatch(alertActions.success('Registration successful'));
                    //Case with email confirmation
                    //dispatch(alertActions.error('Check your mail to confirm your registration'));
                    //history.push('/pwdconfirmdone',{message: 'Check your mail to confirm your registration'});

/*                     let register_title =  <FormattedMessage id="user.actions.register.title"
                                                            defaultMessage="Register"/>
                    let register_message = <FormattedMessage id="user.actions.register.checkmail"
                                                            defaultMessage="Check your mail to confirm your registration (mandatory)"/>

                    history.push('/confirmdone?register',
                                        { title: "user.actions.register.title",
                                          message: "user.actions.register.checkmail",
                    
                                          login:false}); */
/*                   login(origin_username, origin_password,'/storeplan', { type: "Entreprise",
                                                                           name: "Premium",
                                                                           interval: "Annually",
                                                                           price : 492,
                                                                           bill_locale : 'en' }) */

                    //console.log(user)
                    userService.login(origin_username, origin_password)
                        .then(
                            user => { 
                                dispatch(success(user));
                                history.push({'pathname': '/storeplanoutside','state':{ type: "Entreprise",
                                                                                        name: "Premium",
                                                                                        interval: "Annually",
                                                                                        price : 492,
                                                                                        bill_locale : 'en' }});

/*                                 let title_text = <FormattedMessage id="user.actions.login.message.welcome.title"
                                                                defaultMessage="Hello {username} !!!"
                                                                values={{username:user.user.username}}/>
                                let content_text = <FormattedMessage id="user.actions.login.message.welcome.content"
                                                                defaultMessage="Welcome to Ratatool"/>
                                                                
                                dispatch(alertActions.success({title: "user.actions.login.message.welcome.title",
                                                            content:"user.actions.login.message.welcome.content",
                                                            vars: user.user.username})); */
                                dispatch(paymentActions.getCustomerMembership());                          
                            },
                            error => {
                                dispatch(failure(error));

                                let context_text = <FormattedMessage id="user.actions.login.message.error.content"
                                                                            defaultMessage="Please try again"/>
                                let title_text = <FormattedMessage id="user.actions.login.message.error.title"
                                                                            defaultMessage="Unable to log in with provided user/password (remember to confirm your email)"/>
                        
                                dispatch(alertActions.error({title:'user.actions.login.message.error.title',
                                                            content:'user.actions.login.message.error.content',
                                                            stringMode: false}));
                            }
                        )

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    //dispatch(alertActions.error('Password or Email is invalid'));
                    //console.log(error);

                    let context_text = <FormattedMessage id="user.actions.register.message.error.content"
                                                         defaultMessage="Please update"/>

                     if ("email" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.email.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.email[0]}}/>)); */


                        let title_text = <FormattedMessage id="user.actions.register.message.error.mail.title"
                                                            defaultMessage="{message}"
                                                            values={{message:error.email[0]}}/>
                 
                        dispatch(alertActions.error({title:'user.actions.register.message.error.mail.title',
                                                     content:'user.actions.register.message.error.content',
                                                     stringMode: false,
                                                     vars: error.email[0]}));
                        //A user is already registered with this e-mail address.'
                     }else if ("username" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.username.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.username[0]}}/>)); */

                        let title_text = <FormattedMessage id="user.actions.register.message.error.username.title"
                                                           defaultMessage="{message}"
                                                          values={{message:error.username[0]}}/>
                           
                         dispatch(alertActions.error({title:'user.actions.register.message.error.username.title',
                                                      content:'user.actions.register.message.error.content',
                                                      stringMode: false,
                                                      vars: error.username[0]}));
                     }else if ("password1" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.password1.title"
                                                                        defaultMessage="{message}"
                                                                        values={{message:error.password1[0]}}/>)); */

                          let title_text = <FormattedMessage id="user.actions.register.message.error.password1.title"
                                                             defaultMessage="{message}"
                                                            values={{message:error.password1[0]}}/>
                                        
                          dispatch(alertActions.error({title:'user.actions.register.message.error.password1.title',
                                                      content:'user.actions.register.message.error.content',
                                                      stringMode: false,
                                                      vars: error.password1[0]}));
                        //This password is too short. It must contain at least 8 characters.
                    }else if ("password2" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.password2.title"
                                                                        defaultMessage="{message}"
                                                                        values={{message:error.password2[0]}}/>)); */
                          let title_text = <FormattedMessage id="user.actions.register.message.error.password2.title"
                                                                        defaultMessage="{message}"
                                                                       values={{message:error.password2[0]}}/>
                                                   
                          dispatch(alertActions.error({title:'user.actions.register.message.error.password2.title',
                                                       content:'user.actions.register.message.error.content',
                                                       stringMode: false,
                                                       vars: error.password2[0]}));                                             
                        //This password is too short. It must contain at least 8 characters.
                    }else if ("non_field_errors" in error) {
/*                         dispatch(alertActions.error(<FormattedMessage id="user.actions.register.error.nonfield.title"
                                                                      defaultMessage="{message}"
                                                                      values={{message:error.non_field_errors[0]}}/>)); */
                        
                        let title_text = <FormattedMessage id="user.actions.register.message.error.nonfield.title"
                                                           defaultMessage="{message}"
                                                           values={{message:error.non_field_errors[0]}}/>
                                                 
                        dispatch(alertActions.error({title:'user.actions.register.message.error.nonfield.title',
                                                     content:'user.actions.register.message.error.content',
                                                     stringMode: false,
                                                     vars: error.non_field_errors[0]}));   
                        //The two password fields didn't match.
                        //Email is not verified
                     }
                }
            );
    };
    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}


function registerConfirm(token) {
    return dispatch => {
        dispatch(request(token));

        userService.registerConfirm(token)
            .then(
                user => { 
                    dispatch(success(user));
                    //history.push('/login');
                    //dispatch(alertActions.success('VerifiRegistration successful'));
                    //Case with email confirmation
                    //dispatch(alertActions.error('Check your mail to confirm your registration'));
                    
                   let register_title =  <FormattedMessage id="user.actions.registerconfirm.title"
                                                           defaultMessage="Email Verification"/>
                    let register_message = <FormattedMessage id="user.actions.registerconfirm.message.succeed"
                                                             defaultMessage="You have successfully registered"/>

                    history.push('/confirmdone?registerconfirm',
                                    { title: "user.actions.registerconfirm.title",
                                      message: "user.actions.registerconfirm.message.succeed",
                                      login:false});
                },            
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error('Password or Email is invalid'));
 
                    let register_title =  <FormattedMessage id="user.actions.registerconfirm.title"
                                                            defaultMessage="Email Verification"/>
                    let register_message = <FormattedMessage id="user.actions.registerconfirm.message.failed"
                                                            defaultMessage="Registration failed"/>
 
                     history.push('/confirmdone?registerconfirmerror',
                                     { title: "user.actions.registerconfirm.title",
                                       message: "user.actions.registerconfirm.message.failed",
                                       login:false});
                }
            );
    };
    function request(user) { return { type: userConstants.REGISTER_CONFIRM_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_CONFIRM_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_CONFIRM_FAILURE, error } }
}

/* function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
} */

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(user) { return { type: userConstants.DELETE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DELETE_FAILURE, error } }
}

function verify(token) {
    return dispatch => {
        dispatch(request(token));

        userService.verify(token)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
/*                     dispatch(alertActions.error({title: error.statusText,
                                                 content:error.message,
                                                 stringMode: true })); */
                    this.logout();
                    history.push('/login'); 
                    let title_text = <FormattedMessage id="user.actions.notification.title"
                                                        defaultMessage="Session Expired"/>
                    let content_text = <FormattedMessage id="user.actions.notification.content"
                                                         defaultMessage="Please log again"/>

                     dispatch(alertActions.error({title:'user.actions.notification.title',
                                                  content:'user.actions.notification.content',
                                                  stringMode: false}));
 
                                                 
                }
            );
    };
    function request(user) { return { type: userConstants.VERIFY_REQUEST, user } }
    function success(user) { return { type: userConstants.VERIFY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.VERIFY_FAILURE, error } }
}

function renew(token) {
    return dispatch => {
        dispatch(request(token));

        userService.verify(token)
            .then(
                user => { 
                    dispatch(success(user));
                    
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: error.statusText,
                                                 content:error.message,
                                                stringMode: true }));
                    this.logout();
                    history.push('/login');
                }
            );
    };
    function request(user) { return { type: userConstants.VERIFY_REQUEST, user } }
    function success(user) { return { type: userConstants.VERIFY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.VERIFY_FAILURE, error } }
}


function get() {
    return dispatch => {
        dispatch(request());

        userService.user()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };
    function request() { return { type: userConstants.GET_REQUEST } }
    function success(user) { return { type: userConstants.GET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FAILURE, error } }
}


function update(infos,link,silent=false) {
    return dispatch => {
        dispatch(request());

        userService.update(infos)
            .then(
                user => {
                            dispatch(success(user))

                            if (silent == false){
                            
                                let title_text = <FormattedMessage id="user.actions.update.profile.message.title"
                                                                    defaultMessage='Profile Action'/>
                                let content_text = <FormattedMessage id="user.actions.update.profile.message.content"
                                                                    defaultMessage='Profile Updated Successfully'/>
                                                    
                                dispatch(alertActions.success({title: "user.actions.update.profile.message.title",
                                                               content:"user.actions.update.profile.message.content"}));

                                if (link){
                                    history.push('/');
                                    history.push(link);
                                }else{
                                    history.push('/');
                                    history.push('/profile');
                                }  
                            }                          
                },
                error => {
                        dispatch(failure(error));
                        let title_text = <FormattedMessage id="user.actions.update.profile.error.message.title"
                                                           defaultMessage='Profile Update'/>
                        let error_username = <FormattedMessage id="user.actions.update.profile.error.username.title"
                                                           defaultMessage="{message}"
                                                           values={{message:error.username[0]}}/>
                        let error_others = <FormattedMessage id="user.actions.update.profile.error.others.title"
                                                             defaultMessage="Invalid Parameters"/>
                        
                        if ("username" in error) {
                            dispatch(alertActions.error({title: "user.actions.update.profile.error.message.title",
                                                         content: "user.actions.update.profile.error.username.title",
                                                         vars: error.username[0],
                                                         stringMode: false}));
                        }else {
                            dispatch(alertActions.error({title: "user.actions.update.profile.error.message.title",
                                                         content: "user.actions.update.profile.error.others.title",
                                                         stringMode: false}));
                        }
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_REQUEST } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}



function updateLogo(user_infos, file_data,link,silent=false) {
    return dispatch => {
        dispatch(request());

        userService.updateLogo(user_infos,file_data)
            .then(
                user => {
                            dispatch(success(user))

                            if (silent == false){

                                let title_text = <FormattedMessage id="user.actions.update.profile.message.title"
                                                                        defaultMessage='Profile Action'/>
                                let content_text = <FormattedMessage id="user.actions.update.profile.message.content"
                                                                        defaultMessage='Profile Updated Successfully'/>
                                                        
                                dispatch(alertActions.success({title: "user.actions.update.profile.message.title",
                                                                content:"user.actions.update.profile.message.content"}));
                             }
                             if (link){
                                        history.push('/');
                                        history.push(link);
                             }else{
                                        history.push('/');
                                        history.push('/profile');
                             } 
                                                              
                },
                error => {
                        dispatch(failure(error));
                        let title_text = <FormattedMessage id="user.actions.update.profile.error.message.title"
                                                           defaultMessage='Profile Update'/>
                        let error_username = <FormattedMessage id="user.actions.update.profile.error.username.title"
                                                           defaultMessage="{message}"
                                                           values={{message:error.username[0]}}/>
                        let error_others = <FormattedMessage id="user.actions.update.profile.error.others.title"
                                                             defaultMessage="Invalid Parameters"/>
                        
                        if ("username" in error) {
                            dispatch(alertActions.error({title: "user.actions.update.profile.error.message.title",
                                                         content: "user.actions.update.profile.error.username.title",
                                                         vars: error.username[0],
                                                         stringMode: false}));
                        }else {
                            dispatch(alertActions.error({title: "user.actions.update.profile.error.message.title",
                                                         content: "user.actions.update.profile.error.others.title",
                                                         stringMode: false}));
                        }
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_REQUEST } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}


function passwordReset(email) {
    return dispatch => {
        dispatch(request(email));
        userService.passwordReset(email)
            .then(
                user => { 
                    dispatch(success(user));  
                    //dispatch(alertActions.error('Check your mail to reset your password'));
                    //history.push('/pwdconfirmdone',{message: 'Check your mail to reset your password'})

                    let register_title =  <FormattedMessage id="user.actions.pwdreset.title"
                                                              defaultMessage="Password Reset"/>
                    let register_message = <FormattedMessage id="user.actions.pwdreset.message.succeed"
                                                             defaultMessage="Check your mail to reset your password"/>

                    history.push('/confirmdone?pwdreset',
                                     { title: "user.actions.pwdreset.title",
                                        message: "user.actions.pwdreset.message.succeed",
                                        login:false});
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
/*                     dispatch(alertActions.error(<FormattedMessage id="user.actions.pwdreset.message.failed"
                                                                  defaultMessage='Email is invalid'/>)); */
                    let context_text = <FormattedMessage id="user.actions.pwdreset.message.error.content"
                                                                  defaultMessage="Please try again"/>
                    let title_text = <FormattedMessage id="user.actions.pwdreset.message.error.title"
                                                                   defaultMessage="Email is invalid"/>
             
                    dispatch(alertActions.error({title:'user.actions.pwdreset.message.error.title',
                                                 content:'user.actions.pwdreset.message.error.content',
                                                 stringMode: false}));
                                                                   
                }
            );
    };
    function request(user) { return { type: userConstants.PWD_RESET_REQUEST, user } }
    function success(user) { return { type: userConstants.PWD_RESET_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PWD_RESET_FAILURE, error } }
}

function passwordConfirm(infos) {
    return dispatch => {
        dispatch(request(infos));
        userService.passwordConfirm(infos)
            .then(
                user => { 
                    dispatch(success(user));
                    //history.push('/pwdconfirmdone',{message: 'Password successfully changed'}); 

                    let register_title =  <FormattedMessage id="user.actions.pwdconfirm.title"
                                                              defaultMessage="Password Confirm"/>
                    let register_message = <FormattedMessage id="user.actions.pwdconfirm.message.succeed"
                                                                 defaultMessage="Password successfully changed"/>

                    history.push('/confirmdone?pwdconfirm',
                                        { title: "user.actions.pwdconfirm.title",
                                          message: "user.actions.pwdconfirm.message.succeed",
                                          login:false});
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
/*                     dispatch(alertActions.error(<FormattedMessage id="user.actions.pwdconfirm.message.failed"
                                                                  defaultMessage='Passwords did not match or format invalid'/>)); */
                    let context_text = <FormattedMessage id="user.actions.pwdconfirm.message.error.content"
                                                                  defaultMessage="Please try again"/>
                    let title_text = <FormattedMessage id="user.actions.pwdconfirm.message.error.title"
                                                                   defaultMessage="Passwords did not match or format invalid"/>
             
                    dispatch(alertActions.error({title:'user.actions.pwdconfirm.message.error.title',
                                                 content:'user.actions.pwdconfirm.message.error.content',
                                                 stringMode: false}));
                }
            );
    };
    function request(user) { return { type: userConstants.PWD_CONFIRM_REQUEST, user } }
    function success(user) { return { type: userConstants.PWD_CONFIRM_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PWD_CONFIRM_FAILURE, error } }
}

function passwordChange(infos) {
    return dispatch => {
        dispatch(request(infos));
        userService.passwordChange(infos)
            .then(
                user => { 
                    dispatch(success(user));
                    //history.push('/pwdconfirmdone',{message: 'Password successfully changed'}); 

                    let register_title =  <FormattedMessage id="user.actions.pwdchange.title"
                                                                defaultMessage="Password Confirm"/>
                    let register_message = <FormattedMessage id="user.actions.pwdchange.message.succeed"
                                                                defaultMessage="Password successfully changed"/>

                    history.push('/confirmdone?pwdchange',
                                     { title: "user.actions.pwdchange.title",
                                       message: "user.actions.pwdchange.message.succeed",
                                       login:false});

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    //history.push('/pwdconfirmdone',{message: 'Passwords did not match or format invalid'}); 
                            
/*                     let register_title =  <FormattedMessage id="user.actions.pwdchange.title"
                                                    defaultMessage="Password Confirm"/>
                    let register_message = <FormattedMessage id="user.actions.pwdchange.message.failed"
                                                    defaultMessage="Passwords did not match or format invalid"/> */

/*                     history.push('/confirmdone?pwdchangeerror',
                                    { title: "user.actions.pwdchange.title",
                                      message: "user.actions.pwdchange.message.failed",
                                      login:false}); */

                    let title =  <FormattedMessage id="user.actions.pwdchange.message.title"
                                                   defaultMessage="Passwords did not match or format invalid"/>
                    let message = <FormattedMessage id="user.actions.pwdchange.message.content"
                                                    defaultMessage="Please retry"/>
                    dispatch(alertActions.error({title:'user.actions.pwdchange.message.title',
                                                content:'user.actions.pwdchange.message.content',
                                                stringMode: false}));
                }
            );
    };
    function request(user) { return { type: userConstants.PWD_CHANGE_REQUEST, user } }
    function success(user) { return { type: userConstants.PWD_CHANGE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PWD_CHANGE_FAILURE, error } }
}



function addLead(infos) {
    return dispatch => {
        dispatch(request(infos));
        userService.addLead(infos)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message[0],
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.ADD_LEAD_REQUEST, user } }
    function success(user) { return { type: userConstants.ADD_LEAD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_LEAD_FAILURE, error } }
}


function getOrganisation() {
    return dispatch => {
        dispatch(request());
        userService.getOrganisation()
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.GET_ORGANISATION_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_ORGANISATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_ORGANISATION_FAILURE, error } }
}

function addOrganisation(infos) {
    return dispatch => {
        dispatch(request());
        userService.addOrganisation(infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.add.organisation.message.title"
                                                    defaultMessage='Organisation Action'/>
                    let content_text = <FormattedMessage id="user.actions.add.organisation.message.content"
                                        defaultMessage='Organisation added successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.add.organisation.message.title",
                                                   content:"user.actions.add.organisation.message.content"}));
                    

                    //Update active_org in localstorage
                    let userIn = JSON.parse(localStorage.getItem("userIn"));

                    if (userIn){
                        userIn.user.active_org = user
                        userIn.user.role = OWNER
                        // Update users in localStorage.
                        localStorage.setItem("userIn", JSON.stringify(userIn));
                    }

                    history.push('/')
                    history.push('/organisation')

                    let title_text2 = <FormattedMessage id="user.actions.add.organisation.switch.message.title"
                                                    defaultMessage='Organisation Change'/>
                    let content_text2 = <FormattedMessage id="user.actions.add.organisation.switch.message.content"
                                        defaultMessage='You have changed your active organisation'/>
                        
                    dispatch(alertActions.success({title: "user.actions.add.organisation.switch.message.title",
                                                   content:"user.actions.add.organisation.switch.message.content"}));

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.ADD_ORGANISATION_REQUEST, user } }
    function success(user) { return { type: userConstants.ADD_ORGANISATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_ORGANISATION_FAILURE, error } }
}


function editOrganisation(id,infos,link) {
    return dispatch => {
        dispatch(request());
        userService.editOrganisation(id,infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.edit.organisation.message.title"
                                                    defaultMessage='Organisation Action'/>
                    let content_text = <FormattedMessage id="user.actions.edit.organisation.message.content"
                                        defaultMessage='Organisation edited successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.edit.organisation.message.title",
                                content:"user.actions.edit.organisation.message.content"}));
                
                    //Update active_org in localstorage
                    let userIn = JSON.parse(localStorage.getItem("userIn"));

                    if (userIn){
                        userIn.user.active_org = user
                        localStorage.setItem("userIn", JSON.stringify(userIn));
                    }
                    if (link){
                        history.push('/');
                        history.push(link);
                    }else{
                        history.push('/');
                        history.push('/organisation');
                    }  
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.EDIT_ORGANISATION_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_ORGANISATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_ORGANISATION_FAILURE, error } }
}


function editOrganisationLogo(id,infos,file_data,link) {
    return dispatch => {
        dispatch(request());
        userService.editOrganisationLogo(id,infos,file_data)
            .then(
                user => { 
                    dispatch(success(user));


                    let title_text = <FormattedMessage id="user.actions.edit.organisation.message.title"
                                                defaultMessage='Organisation Action'/>
                    let content_text = <FormattedMessage id="user.actions.edit.organisation.message.content"
                                    defaultMessage='Organisation edited successfully'/>

                    dispatch(alertActions.success({title: "user.actions.edit.organisation.message.title",
                            content:"user.actions.edit.organisation.message.content"}));
                
                    //Update active_org in localstorage
                    let userIn = JSON.parse(localStorage.getItem("userIn"));

                    if (userIn){
                        userIn.user.active_org = user
                        localStorage.setItem("userIn", JSON.stringify(userIn));
                    }
                    if (link){
                        history.push('/');
                        history.push(link);
                    }else{
                        history.push('/');
                        history.push('/organisation');
                    }  
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.EDIT_ORGANISATION_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_ORGANISATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_ORGANISATION_FAILURE, error } }
}


function getInvitation() {
    return dispatch => {
        dispatch(request());
        userService.getInvitation()
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.GET_INVITATION_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_INVITATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_INVITATION_FAILURE, error } }
}


function sendInvitation(infos) {
    return dispatch => {
        dispatch(request());
        userService.sendInvitation(infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.send.invitation.message.title"
                                                       defaultMessage='Invitation Action'/>
                    let content_text = <FormattedMessage id="user.actions.send.invitation.message.content"
                                                         defaultMessage='Invitation sent successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.send.invitation.message.title",
                                                   content:"user.actions.send.invitation.message.content"}));
                    history.push('/')
                    history.push('/invitation')
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.SEND_INVITATION_REQUEST, user } }
    function success(user) { return { type: userConstants.SEND_INVITATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SEND_INVITATION_FAILURE, error } }
}


function updateInvitation(id,infos) {
    return dispatch => {
        dispatch(request());
        userService.updateInvitation(id,infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.update.invitation.message.title"
                                                       defaultMessage='Invitation Action'/>
                    let content_text = <FormattedMessage id="user.actions.update.invitation.message.content"
                                                         defaultMessage='Invitation sent successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.update.invitation.message.title",
                                                   content:"user.actions.update.invitation.message.content"}));

                    history.push('/')
                    history.push('/invitation')
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.UPDATE_INVITATION_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_INVITATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_INVITATION_FAILURE, error } }
}


function deleteInvitation(id) {
    return dispatch => {
        dispatch(request());
        userService.deleteInvitation(id)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.delete.invitation.message.title"
                                                    defaultMessage='Invitation Action'/>
                    let content_text = <FormattedMessage id="user.actions.delete.invitation.message.content"
                                        defaultMessage='Invitation deleted successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.delete.invitation.message.title",
                                content:"user.actions.delete.invitation.message.content"}));
                    
                    history.push('/')
                    history.push('/invitation')
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.DELETE_INVITATION_REQUEST, user } }
    function success(user) { return { type: userConstants.DELETE_INVITATION_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DELETE_INVITATION_FAILURE, error } }
}

/* 
function connectPOS(infos) {
    return dispatch => {
        dispatch(request());
        userService.connectPOS(infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.add.pos.message.title"
                                                    defaultMessage='POS Action'/>
                    let content_text = <FormattedMessage id="user.actions.add.pos.message.content"
                                        defaultMessage='POS connected successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.add.pos.message.title",
                                                   content:"user.actions.add.pos.message.content"}));
                    

                     //Update active_org in localstorage
                    //let userIn = JSON.parse(localStorage.getItem("userIn"));

                    // if (userIn){
                    //    userIn.user.active_pos = user
                        // Update users in localStorage.
                    //    localStorage.setItem("userIn", JSON.stringify(userIn));
                    //} 

                    history.push('/')
                    history.push('/pos')

                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.CONNECT_POS_REQUEST, user } }
    function success(user) { return { type: userConstants.CONNECT_POS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CONNECT_POS_FAILURE, error } }
}



function getPOS() {
    return dispatch => {
        dispatch(request());
        userService.getPOS()
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.GET_POS_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_POS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_POS_FAILURE, error } }
}


function deletePOS(id) {
    return dispatch => {
        dispatch(request(id));

        userService.deletePOS(id)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/')
                    history.push('/pos')
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request(id) { return { type: userConstants.DELETE_POS_REQUEST, id } }
    function success(user) { return { type: userConstants.DELETE_POS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DELETE_POS_FAILURE, error } }
}


function updatePOS(id,infos) {
    return dispatch => {
        dispatch(request());
        userService.updatePOS(id,infos)
            .then(
                user => { 
                    dispatch(success(user));

                    let title_text = <FormattedMessage id="user.actions.update.pos.message.title"
                                                       defaultMessage='POS Action'/>
                    let content_text = <FormattedMessage id="user.actions.update.pos.message.content"
                                                         defaultMessage='POS setting updated successfully'/>
                        
                    dispatch(alertActions.success({title: "user.actions.update.pos.message.title",
                                                   content:"user.actions.update.pos.message.content"}));

                    history.push('/')
                    history.push('/pos')
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };
    function request(user) { return { type: userConstants.UPDATE_POS_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_POS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_POS_FAILURE, error } }
} */