
import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import {Message} from 'semantic-ui-react';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
import {injectIntl,FormattedMessage} from 'react-intl';
import { Button} from 'semantic-ui-react';
import {styles} from '../Styles.jsx';
import { paymentActions } from '../../_actions';


class SCAForm extends React.Component {

  constructor(props) {
    super(props);
    //let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state =  { checkError : false,
                    errorMessage: "" };
  }

  componentDidMount(){
     const {client_secret,dispatch,intl} = this.props;
      this.props.stripe.handleCardPayment(client_secret)
            .then( result => {
                if (result.error) {
                   console.log("THERE IS AN ERROR IN YOUR VALIDATION STEP", result.error);
                   this.setState({ checkError: true });
                   this.setState({ errorMessage: result.error.message });
                   dispatch(paymentActions.getAvailableMemberships());
                   //return this.setState({ card_errors: result.error.message });
                } else {
                    history.push('/subscriptions');
                }
            });
  }  

  retrySubscription = (e) => {
    e.preventDefault();
    //history.push('/pricing/');
    const { last_intent_infos, payment } = this.props;

    if (last_intent_infos){

        let memberships = payment.memberships.filter(
                           m => { return m.membership_type === last_intent_infos.membership
                                         && m.interval === last_intent_infos.mode
                                         && m.country === last_intent_infos.locale})

        if (memberships && memberships.length == 1){
/*             history.push('/storeplan/'+ memberships[0].membership_type +'/'
                                      + memberships[0].name +'/'
                                      + memberships[0].interval +'/'
                                      + memberships[0].price) */
               history.push('/storeplan',{type: memberships[0].membership_type,
                                          name: memberships[0].name,
                                          interval: memberships[0].interval,
                                          price : memberships[0].price,
                                          bill_locale : memberships[0].country})
        }
    }
  }

  render() {
    //const { alert,dispatch ,intl} = this.props;
    const {checkError,errorMessage} = this.state;
    return (
      <div>
      {!checkError &&
        <h1><FormattedMessage id="scaform.title.ok"
                              defaultMessage="Complete security step"/></h1>  
      }  
      {checkError &&
        <div>
            <h1><FormattedMessage id="scaform.title.error"
                              defaultMessage="Security check fails"/></h1>  
            <Message negative>
                <Message.Header>{errorMessage}</Message.Header>
            </Message>
            <Button style={styles.ButtonGreen}
                    onClick={this.retrySubscription}>
                    <FormattedMessage id="scacheck.card.button.retry.subscription"
                                       defaultMessage='Retry to subscribe'/>
            </Button>
            {/* <p>Retry to register with credit card</p>   */}
        </div>
      }  
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { payment,alert,dispatch } = state;
  return {
      payment,
      alert,
      dispatch,
  };
}

const connectedSCAForm = injectStripe(injectIntl(connect(mapStateToProps)(SCAForm)));
export { connectedSCAForm as SCAForm};