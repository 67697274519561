import React, { Component } from 'react';
import { Table,Grid,Button,Header,Modal,Form,Popup,Dimmer,Loader,Breadcrumb,Icon} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverIngredients from './SidebarIngredients';
import { supplierActions } from '../_actions';
import {toaster_dispatch,trim,isNumeric} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_suppliers from '../Assets/cargo-truck.png'
/* import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css"
import './PhoneInput.css' */
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

class Suppliers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            company_type: "",
            company_id: "",
            company_tax_id: "", 
            contact: "",
            phone: "",
            address: "",
            zipcode: "",
            city: "",
            country: "",
            state: "",
            email:"",
            note:"",
            activeModalDelete: null,
            activeModalEdit: null,
            activeModalCreate: null,
            submitted: false,
        }
        this.hideModal = this.hideModal.bind(this);
        props.dispatch(supplierActions.getAll());
    }

    OnNameChange = (e) => {
         this.setState({ name: e.target.value });
    }
    OnCompanyTypeChange = (e) => {
         this.setState({ company_type: e.target.value });
    }

    OnCompanyIdChange = (e) => {
         this.setState({ company_id: e.target.value });
    }

    OnCompanyTaxIdChange = (e) => {
         this.setState({ company_tax_id: e.target.value });
    }

    OnContactChange = (e) => {
         this.setState({ contact: e.target.value });
    }
    
    OnEmailChange = (e) => {
         this.setState({ email: e.target.value });
    }

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ phone: number });
    }
    OnAdressChange = (e) => {
        this.setState({ address: e.target.value });
    }

    OnCityChange = (e) => {
        this.setState({ city: e.target.value });
    }

    OnCountryChange = (e) => {
       this.setState({ country: e.target.value });
    }

    OnZipcodeChange = (e) => {
       this.setState({ zipcode: e.target.value });
    } 

   OnStateChange = (e) => {
       this.setState({ state: e.target.value });
   }

   OnNoteChange = (e) => {
    this.setState({ note: e.target.value });
}

/*     onNewSupplierChange = (e) => {
        e.preventDefault();
        this.setState({ name: e.target.value });
    } */

    addSupplier = (e) => {
        e.preventDefault();
        const { name,company_type,company_id,company_tax_id,contact,address,email,zipcode,city,
            country,state,phone,note} = this.state;
        
        this.setState({ submitted: true });

        if (name && name !='' && this.handleValidation()){
            const supplier_infos = {
                         name:trim(name),
                         company_type: trim(company_type),
                         company_id:trim(company_id),
                         company_tax_id:trim(company_tax_id),
                         contact:trim(contact),
                         phone:phone,
                         address: address,
                         zipcode: zipcode,
                         city: city,
                         country: country,
                         state: state,
                         email:email,
                         note:note,
            }
            this.props.dispatch(supplierActions.add(supplier_infos));
            this.setState({ submitted: false });
        }
    }
    
    editSupplier (event,index) {
        event.preventDefault();
        const { name,company_type,company_id,company_tax_id,contact,address,email,zipcode,city,
            country,state,phone,note} = this.state;

        this.setState({ submitted: true });

        if (name && name !='' && this.handleValidation()) {
            const supplier_infos = {
                         name:trim(name),
                         company_type: trim(company_type),
                         company_id:trim(company_id),
                         company_tax_id:trim(company_tax_id),
                         contact:trim(contact),
                         phone:phone,
                         address: address,
                         zipcode: zipcode,
                         city: city,
                         country: country,
                         state: state,
                         email:email,
                         note:note,
            }
            this.props.dispatch(supplierActions.update(index,supplier_infos));
            this.setState({ submitted: false });
        }
    }

    deleteSupplier (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(supplierActions.delete(index))
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,supplier) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ name: supplier.name });
        this.setState({ company_id: supplier.company_id });
        this.setState({ company_tax_id: supplier.company_tax_id });
        this.setState({ company_type: supplier.company_type });
        this.setState({ contact: supplier.contact });
        this.setState({ phone: supplier.phone });
        this.setState({ address: supplier.address });
        this.setState({ zipcode: supplier.zipcode });
        this.setState({ state: supplier.state });
        this.setState({ email: supplier.email });
        this.setState({ city: supplier.city });
        this.setState({ country: supplier.country });
        this.setState({ note: supplier.note });
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ activeModalCreate: null });
        this.setState({ submitted: false });
        this.setState({ name: '' });
        this.setState({ company_id: '' });
        this.setState({ company_tax_id: '' });
        this.setState({ company_type:'' });
        this.setState({ contact: '' });
        this.setState({ adress: '' });
        this.setState({ zipcode: '' });
        this.setState({ state: '' });
        this.setState({ email: ''});
        this.setState({ phone: ''});
        this.setState({ city: '' });
        this.setState({ country: '' });
        this.setState({ note: '' });
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }


    handleNameValidation(){
        const { name } = this.state;
        if(name == ""){
            return false
        }
        return true
    }

    handleValidation(){
        const { email,zipcode,phone } = this.state;

        if (!this.handleNameValidation()){ 
            return false;
        }
/*         if (!isNumeric(zipcode)){
            return false;
        } */
        if (!this.handleEmailValidation(email)){ 
            return false;
        }

        if (!this.handlePhoneValidation(phone)){ 
            return false;
        }

        return true;
    }

    handleZipCodeValidation(zipcode){
/*         if(zipcode !== ""){
            if (!isNumeric(zipcode)){
                return false;
            }
        } */
        return true
    }

    handlePhoneValidation(number){
        
        if(number && number !== ""){
            return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
        }

        return true;
    }

    handleEmailValidation(email){
/*         if(!email){
            return false;
        } */

        if(email !== ""){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos 
                && lastAtPos > 0 
                && email.indexOf('@@') == -1 
                && lastDotPos > 2 
                && (email.length - lastDotPos) > 2)) {
              return false;
            }
        }
        return true;
    }
    
    createSupplier(event){
        event.preventDefault();
        this.setState({activeModalCreate: true});
    } 

    addSuppliersRows(suppliers){
        const { name,email,company_id,company_tax_id,company_type,contact,address,city,country,state,zipcode,phone,note,submitted} = this.state;
        const { intl } = this.props;
        return suppliers.items.map((supplier,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell >
                            <a href="#" 
                               onClick={(event)=>this.editClickHandler(event,index,supplier)}>{supplier.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell>{supplier.company_type}</Table.Cell>
                        <Table.Cell>{supplier.company_id}</Table.Cell>
                        <Table.Cell>{supplier.phone}</Table.Cell>
                        <Table.Cell>{supplier.email}</Table.Cell>
                        <Table.Cell textAlign='right'>
{/*                             <Button basic className="ui icon button circular" color='blue' 
                                onClick={(event)=>this.editClickHandler(event,index,supplier)}>
                                <i className="edit icon"></i>
                                 </Button>
                            <Button basic id={index} className="ui icon button circular" color='grey' 
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash alternate outline icon"></i>
                            </Button> */}
                            <Icon name="edit outline" size='large' color='blue' style={{cursor:'pointer'}}
                                       onClick={(event)=>this.editClickHandler(event,index,supplier)}/>&nbsp;
                            <Icon name="trash alternate outline" size='large' color='grey' style={{cursor:'pointer'}}
                                        onClick={(event) => this.deleteClickHandler(event,index)}/>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   //size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='shipping fast' content={<FormattedMessage id="suppliers.supplier.modal.rename.title"
                                                                                defaultMessage='Edit supplier'/>} />
                                <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field width='six'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.name"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='shipping fast'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companytype"
                                                                                defaultMessage='Type'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companytype.placeholder"
                                                                        defaultMessage='Type'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_type"
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={company_type}
                                                                        onChange={this.OnCompanyTypeChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companyid"
                                                                                defaultMessage='Code'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companyid.placeholder"
                                                                        defaultMessage='ID'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_id"
                                                                        icon='tag'
                                                                        iconPosition='left' 
                                                                        value={company_id}
                                                                        onChange={this.OnCompanyIdChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companytaxid"
                                                                                defaultMessage='Sales Tax ID'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companytaxid.placeholder"
                                                                        defaultMessage='Tax ID'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_tax_id"
                                                                        icon='tag'
                                                                        iconPosition='left'  
                                                                        value={company_tax_id}
                                                                        onChange={this.OnCompanyTaxIdChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.contact"
                                                                                defaultMessage='Contact'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.contact.placeholder"
                                                                        defaultMessage='Contact'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="contact" 
                                                                        icon='user'
                                                                        iconPosition='left'
                                                                        value={contact}
                                                                        onChange={this.OnContactChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        error={submitted && !this.handleEmailValidation(email)}/>}
                                                        </FormattedMessage>
                                                        <label style={{color:'teal', }}>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.info.message"
                                                                                  defaultMessage='Fill if you need to send directly your orders to your supplier'/>
                                                        </label> 
{/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                            <label style={{color:'red', }}><i>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                    defaultMessage='email is used to send your orders'/>
                                                            </i></label> 
                                                        } */}
                                                       </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.address"
                                                                              defaultMessage='Billing Address'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="address" 
                                                                    icon='address card'
                                                                    iconPosition='left'
                                                                    value={address}
                                                                    onChange={this.OnAdressChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.city"
                                                                                defaultMessage='City'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={city}
                                                                        onChange={this.OnCityChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.zipcode"
                                                                                defaultMessage='Zipcode'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="zipcode" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={zipcode}
                                                                        onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.state"
                                                                                defaultMessage='State'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="state" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={state}
                                                                        onChange={this.OnStateChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.country"
                                                                              defaultMessage='Country'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left'
                                                                    value={country}
                                                                    onChange={this.OnCountryChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
{/*                                                    <FormattedMessage id="suppliers.modal.form.input.phone.placeholder"
                                                                    defaultMessage='phone'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="phone" 
                                                                    icon='phone'
                                                                    iconPosition='left'
                                                                    value={phone}
                                                                    onChange={this.OnPhoneChange}/>}
                                                    </FormattedMessage> */}
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="suppliers.modal.form.input.phone.error.message"
                                                                          defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                                </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.note"
                                                                              defaultMessage='Technical note'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.note.placeholder"
                                                                    defaultMessage='note (website, comment ...)'>
                                                        {placeholder =>          
                                                                <Form.TextArea type="text" 
                                                                    placeholder={placeholder}
                                                                    id="note" 
                                                                    icon='text'
                                                                    iconPosition='left'
                                                                    value={note}
                                                                    rows='5'
                                                                    maxlength="990"
                                                                    onChange={this.OnNoteChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                </Form.Group>
                                                <br/>
                                         </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="suppliers.supplier.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="suppliers.supplier.modal.rename.button.save"
                                                                    defaultMessage='Save'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editSupplier(event,supplier.id)}/>
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                             <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="suppliers.supplier.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="suppliers.supplier.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:supplier.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="suppliers.supplier.modal.delete.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="suppliers.supplier.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteSupplier(event,supplier.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }


    render() { 
        const { supplier,alert,dispatch,loading_s,intl } = this.props;
        const { name,email,company_id,company_tax_id,company_type,contact,address,city,country,state,zipcode,phone,note,submitted} = this.state;
        let className = "floating-menu";

        return ( 
            <div>
                <SideLeftUncoverIngredients>
                <Grid stackable style={styles.FontLato12}>
                    <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                    <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="suppliers.link.dashboard"
                                                                                defaultMessage='Dashboard'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="suppliers.link.ingredients"
                                                                                defaultMessage='Ingredients'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section active><FormattedMessage id="suppliers.link.suppliers"
                                                                                defaultMessage='Suppliers'/></Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h2'>
                                        <img src={img_suppliers} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="suppliers.supplier.page.title"
                                                              defaultMessage='Suppliers'/>
                                            <Header.Subheader><FormattedMessage id="suppliers.supplier.page.subtitle"
                                                              defaultMessage='Manage your suppliers'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    {/* <Grid.Row columns={1}/> */}
                    {/* <Grid.Row columns={1}/> */}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={1}/>
{/*                     <Grid.Row columns={2}>
                        <Grid.Column width={5}> 
                            <p style={styles.FontLato12}>
                                <FormattedMessage id="suppliers.supplier.newrecipe.title"
                                                  defaultMessage='Create a new supplier' /> 
                            </p>
                             <Card color={'blue'} style={styles.FontLato12} fluid>
                                <Card.Content >
                                    <Header as='h5'>
                                        <FormattedMessage id="suppliers.supplier.card.newsupplier.title"
                                                          defaultMessage='Name' /> 
                                    </Header>
                                    <FormattedMessage id="suppliers.supplier.card.newsupplier.placeholder"
                                                        defaultMessage='supplier name'>
                                        {placeholder =>  
                                                <Input fluid
                                                    type="text"
                                                    placeholder={placeholder}
                                                    id="newSupplier"
                                                    value={this.state.name}
                                                    onChange={this.onNewSupplierChange}
                                                    //style={{minWidth:'15em',maxWidth:'30em'}}
                                                    />}
                                    </FormattedMessage>
                                    <br/><br/>
                                    <Button basic color='blue' floated='right'
                                        onClick={(event) =>this.addSupplier(event)}>
                                        <FormattedMessage id="suppliers.supplier.card.button.create"
                                                          defaultMessage='Create' /> 
                                    </Button>
                                </Card.Content> 
                                <Card.Content>
                                     <Icon name='idea' color='blue'/>
                                    <FormattedMessage id="suppliers.supplier.card.newsupplier.instructions2"
                                                          defaultMessage='Feel free to edit supplier and provide contact details' /> 
                                </Card.Content>
                            </Card>                        
                        </Grid.Column>
                    </Grid.Row> */}
                    <Grid.Row columns={1} >
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                            <Popup trigger=
                                                    { <Button circular 
                                                            color='teal'
                                                            size='big' 
                                                            icon='add' 
                                                            onClick={(event)=>this.createSupplier(event)}
                                                            ></Button>}
                                                    content={<FormattedMessage id="suppliers.floatingmenu.item1.title"
                                                                                defaultMessage='Add new supplier'/>}
                                                    position='left center'
                                                    inverted
                                            />
                                    </div>
                                </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column mobile={18} tablet={16} computer={14}> 
                          <Dimmer.Dimmable>
                                        <Dimmer active={((loading_s))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                            <Table unstackable
                                   compact 
                                   striped 
                                   singleLine 
                                   style={styles.FontLato14Border}>
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell width={5}>
                                             <FormattedMessage id="suppliers.supplier.currentsupplier.table.row.name"
                                                              defaultMessage='Supplier' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                             <FormattedMessage id="suppliers.supplier.currentsupplier.table.row.company_type"
                                                              defaultMessage='Company Type' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2}>
                                             <FormattedMessage id="suppliers.supplier.currentsupplier.table.row.company_id"
                                                              defaultMessage='Company Code' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2}>
                                             <FormattedMessage id="suppliers.supplier.currentsupplier.table.row.company_phone"
                                                              defaultMessage='Phone' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2}>
                                             <FormattedMessage id="suppliers.supplier.currentsupplier.table.row.company_email"
                                                              defaultMessage='Email' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {supplier && supplier.items &&
                                      this.addSuppliersRows(supplier)}
                                </Table.Body>
                            </Table>
                           </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                <Modal open={this.state.activeModalCreate === true}
                       dimmer='default' 
                       //size='tiny'
                       style={styles.Modal} 
                       onClose={this.hideModal}>
                       <Header icon='shipping fast' content={<FormattedMessage id="suppliers.supplier.modal.create.title"
                                                                                defaultMessage='Create a supplier'/>} />
                        <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field width='six'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.name"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='shipping fast'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companytype"
                                                                                defaultMessage='Type'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companytype.placeholder"
                                                                        defaultMessage='Type'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_type"
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={company_type}
                                                                        onChange={this.OnCompanyTypeChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companyid"
                                                                                defaultMessage='Code'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companyid.placeholder"
                                                                        defaultMessage='ID'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_id"
                                                                        icon='tag'
                                                                        iconPosition='left' 
                                                                        value={company_id}
                                                                        onChange={this.OnCompanyIdChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.companytaxid"
                                                                                defaultMessage='Sales Tax ID'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.companytaxid.placeholder"
                                                                        defaultMessage='Tax ID'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_tax_id"
                                                                        icon='tag'
                                                                        iconPosition='left'  
                                                                        value={company_tax_id}
                                                                        onChange={this.OnCompanyTaxIdChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.contact"
                                                                                defaultMessage='Contact'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.contact.placeholder"
                                                                        defaultMessage='Contact'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="contact" 
                                                                        icon='user'
                                                                        iconPosition='left'
                                                                        value={contact}
                                                                        onChange={this.OnContactChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        error={submitted && !this.handleEmailValidation(email)}
                                                                        />}
                                                        </FormattedMessage>
                                                        <label style={{color:'teal', }}>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.info.message"
                                                                                  defaultMessage='Fill if you need to send directly your orders to your supplier'/>
                                                        </label> 
{/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                            <label style={{color:'red', }}><i>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                    defaultMessage='email is used to send your orders'/>
                                                            </i></label> 
                                                        } */}
                                                    </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.address"
                                                                              defaultMessage='Billing Address'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="address" 
                                                                    icon='address card'
                                                                    iconPosition='left'
                                                                    value={address}
                                                                    onChange={this.OnAdressChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.city"
                                                                                defaultMessage='City'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={city}
                                                                        onChange={this.OnCityChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.zipcode"
                                                                                defaultMessage='Zipcode'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="zipcode" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={zipcode}
                                                                        onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.state"
                                                                                defaultMessage='State'/></label>
                                                    <FormattedMessage id="suppliers.modal.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="state" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={state}
                                                                        onChange={this.OnStateChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.country"
                                                                              defaultMessage='Country'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left'
                                                                    value={country}
                                                                    onChange={this.OnCountryChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field >
                                                   <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
{/*                                                    <FormattedMessage id="suppliers.modal.form.input.phone.placeholder"
                                                                    defaultMessage='phone'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="phone" 
                                                                    icon='phone'
                                                                    iconPosition='left'
                                                                    value={phone}
                                                                    onChange={this.OnPhoneChange}/>}
                                                    </FormattedMessage> */}
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="suppliers.modal.form.input.phone.error.message"
                                                                            defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                                </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="suppliers.modal.form.input.note"
                                                                              defaultMessage='Technical note'/></label>
                                                   <FormattedMessage id="suppliers.modal.form.input.note.placeholder"
                                                                    defaultMessage='note (website, comment ...)'>
                                                        {placeholder =>          
                                                                <Form.TextArea type="text" 
                                                                    placeholder={placeholder}
                                                                    id="note" 
                                                                    icon='text'
                                                                    iconPosition='left'
                                                                    value={note}
                                                                    rows='5'
                                                                    maxlength="990"
                                                                    onChange={this.OnNoteChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                </Form.Group>
                                                <br/>
                                        </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="suppliers.supplier.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="suppliers.supplier.modal.create.button.create"
                                                                    defaultMessage='Create'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.addSupplier(event)}
                                        />
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                        </Grid>
                </SideLeftUncoverIngredients>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { supplier,alert,dispatch } = state;
    const { loading_s } = state.supplier;
    return {
        supplier,
        loading_s,
        alert,
        dispatch
    };
}

const connectedSuppliers = injectIntl(connect(mapStateToProps)(Suppliers));
export { connectedSuppliers as Suppliers };
 