import React, { Component } from 'react';
import { ingredientsActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid,Icon,Header,Dimmer,Loader,Breadcrumb} from 'semantic-ui-react';
import SideLeftUncoverIngredient from './SidebarIngredient';
import { history,toaster_dispatch,Capitalize} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import { alertConstants } from '../_constants';
import {styles} from './Styles';


class DeleteIngredient extends Component {
    
  constructor(props) {
    super(props);    
    this.state={
      prevIngredients : props.ingredients,
      ingredient_delete : null,
    };
    props.dispatch(ingredientsActions.get(props.match.params.ingredientId));
  }

  static getDerivedStateFromProps(props, state) {

    if((props.ingredients!==state.prevIngredients) && props.ingredients.selected) {
        const selected = props.ingredients.selected;
        return {
          ingredient_delete: selected,
        }
    }
   return null;
  }

  deleteIngredient (event,index) {
    event.preventDefault();
    this.props.dispatch(ingredientsActions.delete(index));
  }


  toaster(dispatch,alert){
    const { intl } = this.props;
    toaster_dispatch(dispatch,alert,intl);
    history.push('/ingredients/');
  }
  
  seeIngredient (event,index) {
    event.preventDefault();
    //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
    history.push('/ingredientdetails/'+index);
  }


  render() {
    const {loading, ingredients,dispatch,alert,intl} = this.props;
    const {ingredient_delete} = this.state;
    return (
      <div>
      { loading &&
        <SideLeftUncoverIngredient>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverIngredient>
      }
      { !loading &&
      <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
         <Grid stackable style={{border:'none','background-color':'#fcfcfc','font-family': 'Lato', 'font-size': '12px','color': 'grey'}}>
          <Grid.Row columns={1}>
                      <Breadcrumb style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="deleteingredient.link.dashboard"
                                                                      defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="deleteingredient.link.ingredients"
                                                                    defaultMessage='Ingredients'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{ingredient_delete && ingredient_delete.name}
                                </Breadcrumb.Section>   
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="deleteingredient.link.delete"
                                                                      defaultMessage='Delete'/></Breadcrumb.Section>               
                      </Breadcrumb>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={10}>
                       <Header as='h2'>
                              <Icon name='trash alternate outline' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="deleteingredient.header.title"
                                                    defaultMessage='  Delete Ingredient '/>
                                  <Header.Subheader>{ingredient_delete && ingredient_delete.name}</Header.Subheader>
                                </Header.Content>
                        </Header>
{/*                         <Message size='big'>
                                <Message.Header>
                                     <Icon name='trash' color='teal'  size='big'/>
                                     <FormattedMessage id="deleteingredient.header.title"
                                                       defaultMessage='  Delete Ingredient '/>
                                </Message.Header>
                        </Message> */}
                        <br/>
                </Grid.Column>
           </Grid.Row>
           {ingredients &&
           <Grid.Row columns={1}>
              <Grid.Column width={10}>
              {ingredient_delete &&
                 <Message  style={styles.FontLato13}>
                    <Message.Header>
                        <FormattedMessage id="deleteingredient.card.header.title"
                                          defaultMessage='Deletion of Ingredient  : {name}'
                                          values={{name:ingredient_delete.name}}/> 
                    </Message.Header>
                    <br/>
                    <p> 
                          <FormattedMessage id="deleteingredient.card.header.message"
                                            defaultMessage='Are you really sure to complete the deletion ?'/>
                    </p>
                    <br/>
                    <Button style={styles.ButtonGreen}//basic bsstyle="primary"  
                            //color='grey'
                            onClick={(event) =>this.deleteIngredient(event,ingredient_delete.id)}>
                            <i className="trash alternate outline icon"></i>
                            <FormattedMessage id="deleteingredient.card.button.delete"
                                            defaultMessage='Delete'/>
                    </Button>
                    <Button style={styles.ButtonGrey}//basic bsstyle="primary"  
                            //color='teal'
                            onClick={(event) =>this.seeIngredient(event,ingredient_delete.id)}>
                            <i className="cancel icon"></i>
                            <FormattedMessage id="deleteingredient.card.button.cancel"
                                              defaultMessage='Cancel'/>
                    </Button>
                    <br/>
                </Message>}
             </Grid.Column>
          </Grid.Row >}
          <Grid.Row columns={1}>
              <Grid.Column width={10}>
              {alert.message && this.toaster(dispatch,alert) 
              }
{/*               {alert.message && (alert.type == alertConstants.ERROR) &&
                  toaster_dispatch(dispatch,alert,intl,true)
              } */}
              </Grid.Column>
          </Grid.Row >
        </Grid>
      </SideLeftUncoverIngredient>}
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { ingredients,dispatch,alert } = state;
  const {loading,error} = state.ingredients;
  return {
        ingredients,
        loading,
        error,
        dispatch,
        alert
  };
}

const connectedDeleteIngredient = injectIntl(connect(mapStateToProps)(DeleteIngredient));
export { connectedDeleteIngredient as DeleteIngredient };
