import { blogConstants } from '../_constants';
import { blogService } from '../_api';
import { alertActions } from './';
import { history } from '../_helpers';

export const blogActions = {
    getPosts,
    getPost,
    getHomepagePosts
};


function getPosts(locale,short=false) {
    return dispatch => {
        dispatch(request());

        blogService.getPosts(locale,short)
            .then(
                blog => dispatch(success(blog)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: blogConstants.GETALL_REQUEST } }
    function success(blog) { return { type: blogConstants.GETALL_SUCCESS, blog } }
    function failure(error) { return { type: blogConstants.GETALL_FAILURE, error } }
}

function getPost(id,locale) {
    return dispatch => {
        dispatch(request());

        blogService.getPost(id,locale)
            .then(
                blog => dispatch(success(blog)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: blogConstants.GET_REQUEST } }
    function success(blog) { return { type: blogConstants.GET_SUCCESS, blog } }
    function failure(error) { return { type: blogConstants.GET_FAILURE, error } }
}

function getHomepagePosts(locale) {
    return dispatch => {
        dispatch(request());

        blogService.getHomepagePosts(locale)
            .then(
                blog => dispatch(success(blog)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: blogConstants.GET_HOME_POSTS_REQUEST } }
    function success(blog) { return { type: blogConstants.GET_HOME_POSTS_SUCCESS, blog } }
    function failure(error) { return { type: blogConstants.GET_HOME_POSTS_FAILURE, error } }
}