import React from 'react'
import { Grid, Header, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {FormattedMessage} from 'react-intl';
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import TagManager from 'react-gtm-module'

class RegisterConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
        };

        if (props.match && props.match.params){
          props.dispatch(userActions.registerConfirm(props.match.params.token));

          let tagManagerArgs = {
            dataLayer: {
                token: props.match.params.token,
                page: 'RegisterConfirmed'
            },
            dataLayerName: 'PageDataLayer'
          }

          TagManager.dataLayer(tagManagerArgs)
        }
    }

    render() {
        const { alert,verifying } = this.props;
        return (
            <div className='login-form'>
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
                <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}}>
                  <Grid.Column style={{ top: "25%", maxWidth: 450 }}>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      }
                      {' '}<FormattedMessage id="registerconfirm.form.title"
                                             defaultMessage='Verifying email ...'/>
                    </Header>
                    {verifying && 
                        <img alt="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <Message>
                      <FormattedMessage id="registerconfirm.form.text.cancel"
                                                  defaultMessage='Cancel ?  '/>{' '}<Link to="/login"><FormattedMessage id="registerconfirm.form.link.back"
                                                                                                                  defaultMessage='Return to log in'/></Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 700,  height: '100vh'}}> 
                     <img src={img_window}  alt='window' style={{width: '100v',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <img src={img_logo}  alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                      <Header as='h3' color='grey' textAlign='center'>
                        {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }
                        {' '}<FormattedMessage id="registerconfirm.form.title"
                                             defaultMessage='Verifying email ...'/>
                      </Header>
                      {verifying && 
                          <img  alt='wait' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      }
                      <Message>
                               <FormattedMessage id="registerconfirm.form.text.cancel"
                                                  defaultMessage='Cancel ?  '/>{' '}<Link to="/login"><FormattedMessage id="registerconfirm.form.link.back"
                                                                                                                  defaultMessage='Return to log in'/></Link>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
            </div>
          );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { verifying } = state.registration;
    return {
        alert,
        verifying
    };
}

const connectedRegisterConfirm = connect(mapStateToProps)(RegisterConfirm);
export { connectedRegisterConfirm as RegisterConfirm };