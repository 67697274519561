import React, { Component } from 'react';
import { Table,Grid,Button,Header,Modal,Form,Popup,Icon,Message,Image,Dimmer,Label} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverProfile from './SidebarProfile';
import {userActions,paymentActions } from '../_actions';
import {toaster_dispatch,trim,extractRoleFromCode,isEmpty,history,ADMIN,/*,Capitalize,isNumeric*/} from '../_helpers';
import {FormattedMessage,FormattedDate,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'


const  ROLE_GUEST = <FormattedMessage id="organisations.role.select.guest"
                                    defaultMessage="guest (read only)"/>;
const  ROLE_USER = <FormattedMessage id="organisations.role.select.user"
                                    defaultMessage="team (r/w access)"/>;
const  ROLE_ADMIN = <FormattedMessage id="organisations.role.select.admin"
                                    defaultMessage="admin (r/w access + settings)"/>;


class Invitation extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
            first_name: "",
            last_name: "",
            email:"",
            //contact: "",
            //phone: "",
            //note:"",
            activeModalDelete: null,
            activeModalEdit: null,
            activeModalCreate: null,
            activeModalPremium: false,
            submitted: false,
            user_role: "0",
            user: userIn.user,
            active_role:  userIn.user.role,
            org_member: userIn.user.active_org?true:false, 
            active_org: userIn.user.active_org
        }
        this.hideModal = this.hideModal.bind(this);

        props.dispatch(userActions.getInvitation());
        props.dispatch(paymentActions.getCustomerMembership());
    }

    OnFirstNameChange = (e) => {
         this.setState({ first_name: e.target.value });
    }

    OnLastNameChange = (e) => {
        this.setState({ last_name: e.target.value });
   }
    
    OnEmailChange = (e) => {
         this.setState({ email: e.target.value });
    }
/*     OnPhoneChange = (e) => {
        this.setState({ phone: e.target.value });
    } */

/*     OnContactChange = (e) => {
         this.setState({ contact: e.target.value });
    }  */

/*    OnNoteChange = (e) => {
    this.setState({ note: e.target.value });
   }       */
   
   onUserRoleChange = (e,data) => {
        e.preventDefault();
        this.setState({ user_role: data.value });
    }


    inviteUser = (e) => {
        e.preventDefault();
        const { first_name,last_name,email,user_role,active_org} = this.state;
        const {customer} = this.props;
        let user = null;
        let membership = null;

        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
        
        this.setState({ submitted: true });

        if (!this.handleValidation()){
            return;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            if (email && email !='' /*&& this.handleEmailValidation()*/){
                const invite_infos = { 
                    first_name:trim(first_name),
                    last_name:trim(last_name),
                    email:trim(email),
                    role: user_role,
                    id_organisation: active_org.id
                }
                this.props.dispatch(userActions.sendInvitation(invite_infos));
                this.setState({ submitted: false });
            }
        }else {
            this.hideModal()
            this.setState({ activeModalPremium: true});
        }  
    }
    
    updateInvitation(event,item) {
        event.preventDefault();
        const { first_name,last_name,user_role} = this.state;
        const {customer} = this.props;
        let user = null;
        let membership = null;

        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        this.setState({ submitted: true });

        if (!this.handleValidation()){
            return;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            const invite_infos = {
                    first_name:trim(first_name),
                    last_name:trim(last_name),
                    email:item.email,
                    role: user_role
            }
            this.props.dispatch(userActions.updateInvitation(item.id,invite_infos));
            this.setState({ submitted: false });
        }else {
            this.hideModal()
            this.setState({ activeModalPremium: true});
        }  
    }

    deleteInvitation (event,index) {
        event.preventDefault();
        const {customer} = this.props;
        let user = null;
        let membership = null;

        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            this.props.dispatch(userActions.deleteInvitation(index))
        }else {
            this.hideModal()
            this.setState({ activeModalPremium: true});
        }         
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,invitation) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ first_name: invitation.first_name });
        this.setState({ last_name: invitation.last_name });
        this.setState({ email: invitation.email });
        this.setState({ user_role: invitation.role });
     /* this.setState({ phone: supplier.phone }); */
      /*   this.setState({ note: supplier.note }); */
         /*      this.setState({ contact: supplier.contact });  */
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ activeModalCreate: null });
        this.setState({ activeModalPremium: false });
        this.setState({ submitted: false });
        this.setState({ first_name: '' });
        this.setState({ last_name: '' });
        this.setState({ email: ''});
        this.setState({ user_role: "0"});
       /*  this.setState({ contact: '' }); */
      /*   this.setState({ phone: ''}); */
       /*  this.setState({ note: '' }); */
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }


    handleFirstNameValidation(){
        const { first_name } = this.state;
        if(isEmpty(trim(first_name))){
            return false
        }
        return true
    }

    handleLastNameValidation(){
        const { last_name } = this.state;
        if(isEmpty(trim(last_name))){
            return false
        }
        return true
    }

    handleValidation(){   

        if (!this.handleFirstNameValidation()){ 
            return false;
        }

        if (!this.handleLastNameValidation()){ 
            return false;
        }

        if (!this.handleEmailValidation()){ 
            return false;
        }
        return true;
    }

    handleEmailValidation(){
        const { email } = this.state;
        if(!email){
            return false;
        }

        if(email !== ""){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos 
                && lastAtPos > 0 
                && email.indexOf('@@') == -1 
                && lastDotPos > 2 
                && (email.length - lastDotPos) > 2)) {
              return false;
            }
        }
        return true;
    }

    
    createInvitation(event){
        event.preventDefault();
        this.setState({activeModalCreate: true});
    } 

    addInvitationRows(items){
        const { first_name,last_name,email,user_role,submitted,user} = this.state;
        return items.map((item,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell >
                          <a href="#"
                               onClick={(event)=>this.editClickHandler(event,index,item)}>{item.first_name}{' '}{item.last_name}
                            </a>
                        </Table.Cell>
                        <Table.Cell>{item.email}&nbsp;&nbsp;
                        {(item.email == user.email) && <Label color='blue' size='tiny'>
                            <FormattedMessage id="invitations.table.row.you.title"
                                               defaultMessage='You'/></Label>} </Table.Cell>
                        <Table.Cell textAlign='center'><FormattedDate value={new Date(item.sent)}
                                                   year='numeric'
                                                   month='long'
                                                   day='2-digit'/></Table.Cell>
                        {item.has_registered && 
                            <Table.Cell textAlign='center'><Icon color='teal' name='check' size='large' /></Table.Cell>}
                        {!item.has_registered && 
                            <Table.Cell textAlign='center'><Icon color='orange' name='cancel' size='large' /></Table.Cell>}
                        {item.is_active && 
                            <Table.Cell textAlign='center'><Icon color='teal' name='check' size='large' /></Table.Cell>}
                        {!item.is_active && 
                            <Table.Cell textAlign='center'>
                                  {/* <Icon color='orange' name='ban' size='large' /> */}
                                  {/* <Popup trigger={<Icon color='orange' name='ban' size='large' />} hoverable>
                                    <Grid centered divided columns={1}>
                                        <Grid.Column textAlign='center'>
                                        
                                            <Header as='h4'><Icon color='yellow' name='gift' />
                                            <Header.Content><FormattedMessage id="invitations.premium.message.upgradeplan"
                                                                             defaultMessage='Premium Feature'/></Header.Content></Header>
                                            <Button color='blue' onClick={(e) => this.upgradeSubscription()} >
                                                <FormattedMessage id="invitations.premium.button.chooseplan"
                                                                  defaultMessage='Choose premium plan'/></Button>
                                        </Grid.Column>
                                    </Grid>
                                </Popup> {' '}*/}
                                <Popup trigger={<Button icon='ban' color='red' size='tiny' content='Premium'  />} flowing hoverable>
                                    <Grid centered divided columns={1}>
                                        <Grid.Column textAlign='center'>
                                        
                                            <Header as='h4'><Icon color='yellow' name='gift'/>
                                            <Header.Content><FormattedMessage id="invitations.premium.message.upgradeplan"
                                                                             defaultMessage='ENTERPRISE Feature'/></Header.Content></Header>
                                            <Button color='blue' onClick={(e) => this.upgradeSubscription()} >
                                                <FormattedMessage id="invitations.premium.button.chooseplan"
                                                                  defaultMessage='Choose premium plan'/></Button>
                                        </Grid.Column>
                                    </Grid>
                                </Popup>
                            </Table.Cell>}
{/*                         {item.role!='guest' && 
                            <Table.Cell textAlign='center'><Icon color='teal' name='check' size='small' /></Table.Cell>}
                        {!item.role=='guest' && 
                            <Table.Cell textAlign='center'><Icon color='red' name='cancel' size='small' /></Table.Cell>} */}
                        <Table.Cell textAlign='center'><Label color='lightgrey'>{extractRoleFromCode(item.role)}</Label></Table.Cell>
                        <Table.Cell textAlign='right'>
{/*                           <Button basic className="ui icon button circular" color='blue' size='small'  
                                onClick={(event)=>this.editClickHandler(event,index,item)}>
                                <i className="edit icon"></i>
                                 </Button> */}
{/*                            <Button basic id={index} className="ui icon button circular" color='blue' size='small'
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash alternate outline icon"></i>
                            </Button> */}
                            <Icon className="edit outline" size='large' color='blue' onClick={(event)=>this.editClickHandler(event,index,item)}/>
                            <Icon className="trash alternate outline" size='large' color='grey' onClick={(event)=>this.deleteClickHandler(event,index)}/>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>  
                                <Header icon='building outline' content={<FormattedMessage id="invitations.modal.edit.title"
                                                                                defaultMessage='Update user access'/>} />
                                <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.first_name"
                                                                              defaultMessage='First Name *'/></label>
                                                    <FormattedMessage id="invitations.modal.form.input.first_name.placeholder"
                                                                    defaultMessage='first name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='building outline'
                                                                        iconPosition='left'
                                                                        value={first_name}
                                                                        onChange={this.OnFirstNameChange}
                                                                        error= {submitted && !this.handleFirstNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.last_name"
                                                                              defaultMessage='Last Name *'/></label>
                                                    <FormattedMessage id="invitations.modal.form.input.last_name.placeholder"
                                                                    defaultMessage='last name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='building outline'
                                                                        iconPosition='left'
                                                                        value={last_name}
                                                                        onChange={this.OnLastNameChange}
                                                                        error= {submitted && !this.handleLastNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.email"
                                                                                defaultMessage='Email *'/></label>
                                                    <div style={{  'pointer-events': 'none', opacity: '0.5'}}>
                                                        <FormattedMessage id="invitations.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                               
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        //type="email"
                                                                        //id="email" 
                                                                        value={email}
                                                                        //onChange={this.OnEmailChange}
                                                                        //error={submitted && !this.handleEmailValidation(email)}
                                                                        />}
                                                        </FormattedMessage>
                                                    </div>
                                                </Form.Field>
                                               
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.select.permission.title"
                                                                            defaultMessage='Permissions'/>
                                                    </label>
                                                    <FormattedMessage id="invitations.modal.select.permission.placeholder"
                                                                    defaultMessage='Role'>
                                                        {placeholder =>   
                                                                <Form.Select
                                                                        options={ [{key:'guest',text:ROLE_GUEST,value:"0",icon:'lock'},
                                                                                   {key:'user',text:ROLE_USER,value:"1",icon:'lock open'},
                                                                                   {key:'admin',text:ROLE_ADMIN,value:"2",icon:'lock open'}] } 
                                                                        value = {user_role}
                                                                        placeholder={placeholder} 
                                                                        onChange={this.onUserRoleChange}
                                                                  /> }
                                                    </FormattedMessage>    
                                                </Form.Field>
                                                <br/>
                                         </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button style={styles.ButtonGrey} onClick={this.hideModal}>
                                        <FormattedMessage id="invitations.invitation.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen} 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="invitations.invitation.modal.update.button.save"
                                                                    defaultMessage='Save'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.updateInvitation(event,item)}/>
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                            <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="invitations.invitation.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="invitations.invitation.modal.delete.message"
                                                         defaultMessage='Really sure to remove "{name}" all access to your organisation ?'
                                                         values={{name: item.last_name + ' ' + item.first_name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} onClick={this.hideModal}>
                                        <FormattedMessage id="invitations.invitation.modal.delete.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonRed}  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="invitations.invitation.modal.delete.button.yes"
                                                                    defaultMessage='Confirm'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteInvitation(event,item.id)}/>
                                    </Modal.Actions>
                            </Modal>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    render() { 
        const { users,alert,dispatch,customer } = this.props;
        const { first_name,last_name,email,submitted,user_role,active_role,org_member,user,active_org} = this.state;
        let className = "floating-menu";
        let membership = null;

        if (customer){
            membership = customer.membership;
        }

        return ( 
            <div>
                <SideLeftUncoverProfile>
                <Grid stackable style={styles.FontLato12}>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h1'>
                                        <Icon name='users' color='blue'  size='huge' />
                                        <Header.Content>
                                            <FormattedMessage id="invitations.invitation.page.title"
                                                              defaultMessage='Users'/>
                                            <Header.Subheader><FormattedMessage id="invitations.invitations.page.subtitle"
                                                              defaultMessage='Invite teammates'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={1}/>
                    {!org_member && 
                       <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Message compact icon size='large'>
                                    <Icon name='lightbulb outline' color='grey'/>
                                    <Message.Content>
                                        <Message.Header>
                                            <FormattedMessage id="invitations.message.title"
                                                                defaultMessage='Invite user(s) to join your team'/>
                                        </Message.Header>
                                        
                                        <FormattedMessage id="invitations.message.details"
                                                          defaultMessage='You should have an organization first before inviting users.' />
                                        <br/> <br/>
                                            <Link to={'/organisation/'}>
                                                <FormattedMessage id="invitations.message.link"
                                                                  defaultMessage='Create my organization'/>
                                            </Link>
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                    </Grid.Row>}
                    {org_member && active_role>=ADMIN &&
                       <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Message compact icon size='large'>
                                    <Icon name='lightbulb outline' color='teal'/>
                                    <Message.Content>
                                        <Message.Header>
                                            <FormattedMessage id="invitations.message.title"
                                                                defaultMessage='Invite user(s) to join your team'/>
                                        </Message.Header>
                                        <Icon name='building' color='teal' /* size='large' *//>
                                        <FormattedMessage id="invitations.message.details"
                                                          defaultMessage='You should have an organization first before inviting users.' />
                                        <br/> <br/>
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                    </Grid.Row>}
                    {org_member && active_role<ADMIN && 
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Message compact info icon size='large'>
                                    <Icon name='settings' color='teal'/>
                                    <Message.Content>
                                        <Message.Header>
                                            <FormattedMessage id="invitations.role.message.title"
                                                                defaultMessage='Settings'/>
                                        </Message.Header>
                                        <Icon name='lock' color='teal'/>
                                        <FormattedMessage id="invitations.role.message.details"
                                                          defaultMessage='You should have Administrator privilege.' />
                                        <br/> <br/>
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                    </Grid.Row>}
                    <Grid.Row columns={1} >
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                            <Popup trigger=
                                                    { <Button circular 
                                                            color='teal'
                                                            size='big' 
                                                            icon='user plus' 
                                                            onClick ={(event)=>this.createInvitation(event)}
                                                            disabled ={!org_member || (org_member && active_role<ADMIN)}
                                                            ></Button>}
                                                    content={<FormattedMessage id="invitations.floatingmenu.item1.title"
                                                                                defaultMessage='Invite user'/>}
                                                    position='left center'
                                                    inverted
                                            />
                                    </div>
                                </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column mobile={18} tablet={16} computer={14}> 
                           <Dimmer.Dimmable dimmed={org_member &&  active_role<ADMIN} blurring>
                                    <Dimmer active={org_member &&  active_role<ADMIN} blurring inverted>
                                         {/*  <Loader inverted content='No Privilege' /> */}
                                    </Dimmer>
                            <Table unstackable
                                   compact 
                                   striped 
                                   singleLine 
                                   style={styles.FontLato13Border}>
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell width={3}>
                                             <FormattedMessage id="invitations.invitation.table.row.name"
                                                              defaultMessage='Member' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                             <FormattedMessage id="invitations.invitation.table.row.email"
                                                              defaultMessage='Email' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={2}>
                                             <FormattedMessage id="invitation.invitation.table.row.date_sent"
                                                              defaultMessage='Sent' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={1}>
                                             <FormattedMessage id="invitation.invitation.table.row.accepted"
                                                              defaultMessage='Accepted' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={2}>
                                             <FormattedMessage id="invitation.invitation.table.row.active"
                                                              defaultMessage='Active' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={3}>
                                             <FormattedMessage id="invitation.invitation.table.row.rights"
                                                              defaultMessage='Permissions' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {active_org &&
                                  <Table.Row >
                                    <Table.Cell >
                                       {active_org.owner.first_name}{' '}{active_org.owner.last_name}
                                    </Table.Cell>
                                    <Table.Cell>{active_org.owner.email}&nbsp;&nbsp;
                                        {(active_org.owner.email == user.email) && <Label color='blue' size='tiny'>
                                            <FormattedMessage id="invitations.table.row.you.title"
                                                              defaultMessage='You'/></Label>} </Table.Cell>
                                    <Table.Cell textAlign='center'></Table.Cell>
                                    <Table.Cell textAlign='center'></Table.Cell>
                                    <Table.Cell textAlign='center'></Table.Cell>
                                    <Table.Cell textAlign='center'><Label color='lightgrey'>{extractRoleFromCode(3)}</Label></Table.Cell>
                                    <Table.Cell textAlign='right'/>
                                </Table.Row>}
                                {users && users.invite_items && users.invite_items.length == 0 &&
                                    <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="invitation.table.header.row.noitems"
                                                                            defaultMessage='No ongoing invitations'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                    </Table.Row>}
                                {users && users.invite_items && 
                                      this.addInvitationRows(users.invite_items)}
                                </Table.Body>
                            </Table>
                           </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                <Modal open={this.state.activeModalCreate === true}
                       dimmer='default' 
                       size='tiny'
                       style={styles.Modal} 
                       onClose={this.hideModal}>
                       <Header icon='building outline' content={<FormattedMessage id="invitations.modal.create.title"
                                                                                defaultMessage='Invite a teammate'/>} />
                        <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                             <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.first_name"
                                                                              defaultMessage='First Name *'/></label>
                                                    <FormattedMessage id="invitations.modal.form.input.first_name.placeholder"
                                                                    defaultMessage='first name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="first_name" 
                                                                        icon='user'
                                                                        iconPosition='left'
                                                                        value={first_name}
                                                                        onChange={this.OnFirstNameChange}
                                                                        error= {submitted && !this.handleFirstNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.last_name"
                                                                              defaultMessage='Last Name *'/></label>
                                                    <FormattedMessage id="invitations.modal.form.input.last_name.placeholder"
                                                                    defaultMessage='last name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="last_name" 
                                                                        icon='user'
                                                                        iconPosition='left'
                                                                        value={last_name}
                                                                        onChange={this.OnLastNameChange}
                                                                        error= {submitted && !this.handleLastNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field /* width='eight' */>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.form.input.email"
                                                                                defaultMessage='Email *'/></label>
                                                    <FormattedMessage id="invitations.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        name='email'
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        error={submitted && !this.handleEmailValidation()}
                                                                        />}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                               <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="invitations.modal.select.permission.title"
                                                                            defaultMessage='Permissions'/>
                                                    </label>
                                                    <FormattedMessage id="invitations.modal.select.permission.placeholder"
                                                                    defaultMessage='Role'>
                                                        {placeholder =>   
                                                                <Form.Select
                                                                        options={ [{key:'guest',text:ROLE_GUEST,value:"0",icon:'lock'},
                                                                                   {key:'user',text:ROLE_USER,value:"1",icon:'lock open'},
                                                                                   {key:'admin',text:ROLE_ADMIN,value:"2",icon:'lock open'}] } 
                                                                        value = {user_role} 
                                                                        placeholder={placeholder} 
                                                                        onChange={this.onUserRoleChange}
                                                                        //style={{minWidth:'10em',maxWidth:'30em'}}
                                                                        //error={submitted && !this.assertSelectedWeight()}
                                                                  /> }
                                                    </FormattedMessage>    
                                              </Form.Field> 
                                            <br/>
                                        </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button style={styles.ButtonRed} labelPosition='right' icon='cancel' onClick={this.hideModal}
                                            content={<FormattedMessage id="invitations.invitation.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>}/>
                                    <Button style={styles.ButtonGreen} 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="invitations.invitation.modal.create.button.send"
                                                                    defaultMessage='Send'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.inviteUser(event)}
                                        />
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="invitations.header.title"
                                                    defaultMessage='Invite and manage users'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="invitations.premium.message.upgradeplan"
                                                                defaultMessage='ENTERPRISE Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="invitations.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="invitations.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="invitations.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="invitations.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Grid>
                </SideLeftUncoverProfile>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { users,alert,dispatch } = state;
    const { customer } = state.payment;
    return {
        users,
        customer,
        alert,
        dispatch
    };
}

const connectedInvitation = injectIntl(connect(mapStateToProps)(Invitation));
export { connectedInvitation as Invitation };
 