import React, { Component } from 'react';
import { recipesActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid, Header, Image,Icon,Modal,Form,Checkbox,Segment,Breadcrumb,Label} from 'semantic-ui-react';
import SideLeftUncoverRecipe from './SidebarRecipe';
import {Capitalize,history,isNumeric,toaster_info,toaster_dispatch,optimizeDecimalPart,trim,typeYield} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import {uniqueId} from "lodash";
import img_antique from '../Assets/checklist.png'
import img_performance from '../Assets/performance.png'



const PDF_MESSAGE_TITLE = <FormattedMessage id="downloadrecipe.toaster.message.title"
                                                  defaultMessage='PDF Export/Create'/>
         
const PDF_MESSAGE_CONTENT = <FormattedMessage id="downloadrecipe.toaster.message.content"
                                                  defaultMessage='Document creation ongoing ...'/>


const options = [
    { key: 1, 
      text: <FormattedMessage id="downloadrecipe.data.choice.elem1"
                             defaultMessage='Recipe Cost Breakdown'/>,
     value: 'breakdown' },
    //{ key: 2, text: 'Recipe Preparation', value: 'instructions' },
]

const optionsExport = [
    { key: 1, 
      text: <FormattedMessage id="downloadrecipe.format.choice.elem1"
                              defaultMessage='PDF File'/>,
      icon: 'file pdf',
      value: 'pdf' },
    //{ key: 2, text: 'Export CSV', value: 'csv' },
]


class DownloadRecipe extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            selected: null,
            format: 'pdf',
            data: 'breakdown',
            units: '1',
            yield: "",
            withCostBreakdown: true,
            withPieChart: false,
            withMargin: true,
            withPrepInstructions: false,
            withBreakpage: false,
            withSubRecipes: false,
            withSuppliers: true,
            withOrder: false,
            isInstructionsOnly:false,
            chart_data: [],
            activeModalPremium: false,
            submitted: false,
            unit_type:'recipe',
            orderName :  uniqueId(new Date().toLocaleDateString(props.intl.locale).split('T')[0]+'_REF_'),
         }
        props.dispatch(recipesActions.get(props.match.params.recipeId));
        props.dispatch(paymentActions.getCustomerMembership());
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if((nextProps.recipes!==prevState.recipes) && (nextProps.recipes.selected) 
            /*&&(nextProps.recipes.selected.ingredients  && nextProps.recipes.selected.ingredients.length>0
                || nextProps.recipes.selected.subrecipes && nextProps.recipes.selected.subrecipes.length>0 )*/) {
            const selected = nextProps.recipes.selected;
            const values = [];        
            selected.subrecipes.map((s,index) => {
                if (s.subrecipe){
                    values.push({id:s.subrecipe.name, 
                        label: s.subrecipe.name , 
                        value: s.item_cost,
                        percent: (s.item_cost/selected.cost_total*100).toFixed(2)+'%'})
                }

            });
            selected.ingredients.map((i,index) => {
                if (i.ingredient){
                    values.push({id:i.ingredient.name, 
                        label: i.ingredient.name, 
                        value: i.item_cost,
                        percent: (i.item_cost/selected.cost_total*100).toFixed(2)+'%'})
                }

            });

            return {
                selected: selected,
                chart_data: values
            }
        }
        return null;
    }    

  hideModal() {
    this.setState({ activeModalPremium: false });
  }

  onFormatChange = (e,data) => {
    e.preventDefault();
    this.setState({ format: data.value });
  }

  onDataChange = (e,data) => {
    e.preventDefault();
    this.setState({ data: data.value });    
  }

  onUnitsChange = (e,data) => {
    e.preventDefault();
    this.setState({ submitted: false });
    this.setState({ units: data.value });    
  }

  onCheckboxChange = (e, data) => {
    //console.log(data);
    if (data.id == 'cost')
        this.setState({ withCostBreakdown: data.checked});
    else if (data.id  == 'chart')
        this.setState({ withPieChart: data.checked});
    else if (data.id  == 'margin')
        this.setState({ withMargin: data.checked});
    else if (data.id  == 'instruct'){
        this.setState({ withPrepInstructions: data.checked});
        //this.setState({ withBreakpage: data.checked});
    }
    else if (data.id  == 'sub')
        this.setState({ withSubRecipes: data.checked});
    else if (data.id  == 'break')
        this.setState({ withBreakpage: data.checked});
    else if (data.id  == 'order')
        this.setState({ withOrder: data.checked});
    else if (data.id  == 'supplier')
        this.setState({ withSuppliers: data.checked});
  }

  assertUnitsField(){
    const {units} = this.state;
    let nunits = units.replace(/,/g, '.')
    if (isNumeric(nunits)
          && (parseFloat(nunits) > 0)
          && (nunits !=="")
          && (nunits.length<5))
      return true;
    return false;
  }

  downloadRecipeBackend () {
    const { selected,withCostBreakdown,
            withPieChart,withMargin, withPrepInstructions,withSubRecipes,withBreakpage,units,unit_type,withOrder,withSuppliers} = this.state;
    const {customer,intl} = this.props;
    let user = null;
    let membership = null;
        
    if (customer){
        membership = customer.membership;
        user = customer.user;
        //console.log(user);
        //console.log(membership);
    }

    this.setState({ submitted: true });

    if (!this.assertUnitsField()){
        //dispatch(alertActions.error('yield count is alphanumeric'));
        //toaster_error('Submit Error','Yield Count field should be numeric and 3 digits max');
        return;
    }

    if (membership && user &&
        ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
    {
        if (selected){
            let properties = {
                name: selected.name,
                id: selected.id,
                pie: (withPieChart?1:0),
                cost:(withCostBreakdown?1:0),
                text: (withPrepInstructions?1:0),
                margin: (withMargin?1:0),
                subrecipes : (withSubRecipes?1:0),
                breakpage : (withBreakpage?1:0),
                units:units.replace(/,/g, '.'),
                portions :  (unit_type === 'recipe'?0:1),
                supplier : (withSuppliers?1:0),

            }
            this.props.dispatch(recipesActions.renderPdf(properties));  
            toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                         intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))
            //toaster_info('PDF Export/Create','Document creation ongoing');

            if (withOrder) {
                let unitsRecipe =  [{   id: selected.id, 
                                        value: units.replace(/,/g, '.'), 
                                        quantity_type:typeYield(selected.yield_units),
                                        is_nb_recipes: (unit_type === 'recipe'?true:false),
                                        unit: selected.yield_units.toLowerCase() }];
                let properties = { ref_name: trim(this.state.orderName).substring(0,80),
                                   recipesUnits:unitsRecipe}
                this.props.dispatch(recipesActions.saveOrder(properties, true));  
            }
        }
    }
    else {
        this.setState({ activeModalPremium: true});
    }
    
    //this.resetState();
  }
 
  onSubmit = (e) => {
        //e.preventDefault();
        this.downloadRecipeBackend();
  }

  resetState = () => {
    this.setState({ units: "1"});
    this.setState({ withCostBreakdown: true});
    this.setState({ withPieChart: false});
    this.setState({ withMargin: true});
    this.setState({ withPrepInstructions: false});
    this.setState({ withBreakpage: false});
    this.setState({ withSubRecipes: false});
    this.setState({ withOrder: false});
    this.setState({ unit_type: 'recipe'});
    this.setState({ withSuppliers: true});
    this.setState({ submitted: false});
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }


  getRelevantOptions = (unit) => {
    let options = []

    unit =  unit.toLowerCase()
    options= [{key:unit,text:unit,value:unit},
              {key:'recipe',text:<FormattedMessage id="downloadrecipe.quantity.recipe.text"
                                                   defaultMessage='recipe(s)'/>,value:'recipe'}] 

    return options;
  }

  onUnitChange = (e,data) => {
    e.preventDefault();
    this.setState({ unit_type: data.value });
  }



  render() {
    const { selected ,submitted,unit_type} = this.state;
    const { customer,alert,intl,dispatch } = this.props;
    //let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      //user = customer.user;
    }

    return (
      <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
         <Grid stackable style={styles.FontLato12}>
         <Grid.Row columns={1}>
                  <Breadcrumb style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="downloadrecipe.link.dashboard"
                                                                  defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="downloadrecipe.link.recipes"
                                                                defaultMessage='Recipes'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{selected && selected.name}</Breadcrumb.Section>   
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="downloadrecipe.link.export"
                                                                  defaultMessage='Export'/></Breadcrumb.Section>               
                  </Breadcrumb>
          </Grid.Row>
           <Grid.Row>
               <Grid.Column width={10}>
                    <Header as='h2'>
                              <Icon name='download' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="downloadrecipe.header.title"
                                                    defaultMessage='Export recipe form'/>
                                  <Header.Subheader><FormattedMessage id="downloadrecipe.header.subtitle"
                                                                      defaultMessage='or create a production form'/></Header.Subheader>
                                </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue' >
                    {selected && selected.name}
                    </Header> */}
               </Grid.Column>
           </Grid.Row>
           {alert.message && toaster_dispatch(dispatch,alert,intl)}
           <Grid.Row columns={2}>
              <Grid.Column /*width={5}*/ mobile={10} tablet={7} computer={6}>
                  {selected &&
                     <Form fluid>
                        <label><FormattedMessage id="downloadrecipe.form.format.select.title"
                                                         defaultMessage='Format'/>
                        </label>
                        <Form.Group widths='equal'>
                          <Form.Field style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                <FormattedMessage id="downloadrecipe.form.format.select.placeholder"
                                                defaultMessage='Format'>
                                    {placeholder =>   
                                            <Form.Select 
                                                    options={optionsExport}
                                                    placeholder={placeholder} 
                                                    value={this.state.format}
                                                    onChange={this.onFormatChange}
                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                            />}
                                </FormattedMessage>  
                            </Form.Field>
                        </Form.Group>
{/*                         <Form.Group widths='equal'>
                            <Form.Field>
                                <label><FormattedMessage id="downloadrecipe.form.data.select.title"
                                                         defaultMessage='Data'/>
                                </label>
                                <FormattedMessage id="downloadrecipe.form.data.select.placeholder"
                                                  defaultMessage='Data'>
                                    {placeholder =>   
                                            <Form.Select 
                                                    options={options}
                                                    placeholder={placeholder} 
                                                    value={this.state.data}
                                                    onChange={this.onDataChange}
                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                            />}
                                </FormattedMessage>  
                            </Form.Field>
                        </Form.Group> */}
                        <br/>
                        <Label color='##EEEEEE' /* style={{color:'black'}} */ size='large'>
                                <FormattedMessage id="downloadrecipe.form.units.title"
                                                  defaultMessage='Enter quantity and custom options'/>
                        </Label>
{/*                         <p>
                            <FormattedMessage id="downloadrecipe.form.units.subtitle"
                                                    defaultMessage='Create your production form'/>
                        </p> */}
                        <br/><br/>

                       {/*  <Segment> */}
                        <label><FormattedMessage id="downloadrecipe.form.units.imput.quantity.title"
                                                            defaultMessage='Quantity'/>
                        </label> 
                        <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormattedMessage id="downloadrecipe.form.units.imput.units.placeholder"
                                                    defaultMessage='units'>
                                        {placeholder =>   
                                                <Form.Input 
                                                        placeholder={placeholder} 
                                                        value={this.state.units}
                                                        onChange={this.onUnitsChange}
                                                        error={submitted && !this.assertUnitsField()}
                                                />}
                                    </FormattedMessage>
                                    {submitted && !this.assertUnitsField()  && 
                                            <label style={{color:'red'}}><i>
                                            <FormattedMessage id="downloadrecipe.form.input.units.error.message"
                                                                defaultMessage='value is numeric'/>
                                            </i></label>
                                    }  
                                </Form.Field>
                                <Form.Field>
                                   <Form.Select
                                        options={this.getRelevantOptions(selected.yield_units)}
                                        onChange={(event,data)=>this.onUnitChange(event,data)}
                                        value ={unit_type}
                                        style={styles.ButtonGreenNoMarginOrder}/>

                                </Form.Field>
{/*                                  <Form.Field>
                                    <label><FormattedMessage id="downloadrecipe.form.units.imput.yield.title"
                                                            defaultMessage='Yield'/>
                                    </label>
                                    <FormattedMessage id="downloadrecipe.form.units.imput.yield.placeholder"
                                                    defaultMessage='yield'>
                                        {placeholder =>   
                                                <Form.Input
                                                        readOnly
                                                        placeholder={placeholder} 
                                                        value={this.state.selected.yield_count + ' ' + this.state.selected.yield_units  }
                                                />}
                                    </FormattedMessage>  
                                </Form.Field> */}
                            </Form.Group>
                            <label><FormattedMessage id="downloadrecipe.form.units.imput.yield.title"
                                                            defaultMessage='Yield'/>
                            </label>
                             <Form.Group widths='equal'> 
                                <Form.Field>
{/*                                     <label><FormattedMessage id="downloadrecipe.form.units.imput.yield.title"
                                                            defaultMessage='Yield'/>
                                    </label> */}
                                    {/*<p>{this.state.selected.yield_count + ' ' + this.state.selected.yield_units}</p> */}
                                    <div style={{  'pointer-events': 'none', opacity: '0.6'}}>
                                     <FormattedMessage id="downloadrecipe.form.units.imput.yield.placeholder"
                                                    defaultMessage='yield'>
                                        {placeholder =>   
                                                <Form.Input 
                                                        readOnly
                                                        placeholder={placeholder} 
                                                        value={optimizeDecimalPart(Number(this.state.selected.yield_count)) + ' ' + this.state.selected.yield_units  }
                                                />}
                                    </FormattedMessage></div>
                                </Form.Field>
                            </Form.Group>
                        {/* </Segment> */}
                        <br/>
                        <label>
                                <FormattedMessage id="downloadrecipe.form.options.title"
                                                  defaultMessage='Include in my document'/>
                        </label>
                        {/* <br/> */}
                        <Segment secondary color='teal'>
                        <Form.Group style={styles.FontLato12} grouped >
                           <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}> 
                                <FormattedMessage id="downloadrecipe.form.options.option.cost"
                                                 defaultMessage='Cost summary'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='cost'
                                                checked = {this.state.withCostBreakdown}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                            </Form.Field>
                            <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.margin"
                                                 defaultMessage='Margin evaluation'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='margin'
                                                checked = {this.state.withMargin}
                                                onChange={this.onCheckboxChange} />}
                                </FormattedMessage>
                            </Form.Field>
                            <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.supplier"
                                                 defaultMessage='Suppliers'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='supplier'                                       
                                                checked = {this.state.withSuppliers}
                                                onChange={this.onCheckboxChange} />}
                                </FormattedMessage>
                            </Form.Field>
                            <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.chart"
                                                 defaultMessage='Pie chart'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='chart'
                                                checked = {this.state.withPieChart}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                            </Form.Field>
                            <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.instructions"
                                                 defaultMessage='Preparation instructions'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='instruct'
                                                checked = {this.state.withPrepInstructions}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                                <br/> 
                                <span style={{marginLeft:'3em'}}>           
                                    <FormattedMessage id="downloadrecipe.form.options.option.breakpage"
                                                    defaultMessage='Start on a new page'>
                                        {label => 
                                            <Checkbox label={label}
                                                    id='break'
                                                    checked = {this.state.withBreakpage}
                                                    onChange={this.onCheckboxChange} />}
                                    </FormattedMessage>
                                </span>
                            </Form.Field>
                            <Form.Field style={{transform: 'scale(1.1)',marginLeft:'2em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.subrecipes"
                                                defaultMessage='Sub-recipes'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='sub'
                                                checked = {this.state.withSubRecipes}
                                                onChange={this.onCheckboxChange} />}
                                </FormattedMessage>
                            </Form.Field>
                        </Form.Group>
                        </Segment>
                        <Form.Group >
                            <Form.Field style={{transform: 'scale(1.1)', marginLeft:'1.5em'}}>
                                <FormattedMessage id="downloadrecipe.form.options.option.order"
                                                defaultMessage='Create the associated ingredient order (menu ORDERS)'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='order'
                                                style={{'font-size':'14px','font-family':'Lato'}}
                                                checked = {this.state.withOrder}
                                                onChange={this.onCheckboxChange} />}
                                </FormattedMessage>
                            </Form.Field>
                        </Form.Group>
                        <br/>
                        <Form.Group widths='equal'>
                            <Form.Button style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="downloadrecipe.form.submit.ok"
                                                        defaultMessage="Create document"/>}
                            </Form.Button>
                            <Form.Button style={styles.ButtonGrey} //basic color='grey'
                                    fluid  onClick={this.cancelAction}>
                                    {<FormattedMessage id="downloadrecipe.form.submit.cancel"
                                                       defaultMessage="Cancel"/>}
                            </Form.Button>
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="downloadrecipe.header.title"
                                                    defaultMessage='Export recipe form'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="downloadrecipe.premium.message.upgradeplan"
                                                                defaultMessage='Premium Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="downloadrecipe.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="downloadrecipe.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="downloadrecipe.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="downloadrecipe.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Form.Group>
                    </Form>}
             </Grid.Column>
             <Grid.Column width={5}>
                 <img alt='antique' id='test' src={img_antique}/>
             </Grid.Column> 
          </Grid.Row>
        </Grid>
      </SideLeftUncoverRecipe>
    );
  }
}

function mapStateToProps(state) {
  const { recipes,dispatch,alert } = state;
  const { customer } = state.payment;
  return {
        recipes,
        customer,
        dispatch,
        alert
  };
}

const connectedDownloadRecipe = injectIntl(connect(mapStateToProps)(DownloadRecipe));
export { connectedDownloadRecipe as DownloadRecipe };
