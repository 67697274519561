export const saleConstants = {
    GETALL_REQUEST: 'SALES_GETALL_REQUEST',
    GETALL_SUCCESS: 'SALES_GETALL_SUCCESS',
    GETALL_FAILURE: 'SALES_GETALL_FAILURE',

    RETRIEVEALL_POS_REQUEST:'SALES_RETRIEVEALL_POS_REQUEST',
    RETRIEVEALL_POS_SUCCESS:'SALES_RETRIEVEALL_POS_REQUEST',
    RETRIEVEALL_POS_FAILURE:'SALES_RETRIEVEALL_POS_REQUEST',

    
};