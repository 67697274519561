import React from 'react'
import {blogActions} from '../_actions';
import {connect} from 'react-redux';
import {MainFooter,ResponsiveContainer} from './Segments';
import { Segment,Grid,Card,Header,Comment,Form,Button,Item,Menu,Icon,Image} from 'semantic-ui-react';
import {FormattedMessage,defineMessages,injectIntl} from 'react-intl';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {styles} from './Styles';
//import Carousel from 'react-bootstrap/lib/Carousel';
import Likes from './Likes';
import img_account from '../Assets/accounting-analytics.jpg'
/*import img_citron  from '../Assets/categoriesRecipes.jpeg'
import img_recipesDashboard from '../Assets/recipes_dashboard.jpeg'
import img_dressing from '../Assets/dressing-cooking-olive.jpg'
 */
const inlineImageStyle = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(70%)',
        '-moz-filter': 'brightness(70%)',
/*         '-o-filter': 'brightness(50%)',
        '-ms-filter': 'brightness(50%)' */
  },  
};

const inlineImageStyle2 = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(97%)',
        '-moz-filter': 'brightness(97%)',
/*         '-o-filter': 'brightness(50%)',
        '-ms-filter': 'brightness(50%)' */
  },  
};



const html_tags = defineMessages({
  html_title: {
       id: 'blog.html.title.tag',
       defaultMessage: "Blog: Recipe cost software, recipe food cost calculator, restaurant software solution",
  },
  meta_name_description: {
       id: 'blog.meta.name.description.tag',
       defaultMessage: "Last posts and profitability advices for your restaurant management costing solution and day to day work with recipe cost tool, recipe forms, suppliers orders",
  }
});

class Blog extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {posts: []}
    //this.state = { activeItem: 'closest' }
    props.dispatch(blogActions.getPosts(props.intl.locale));
 }
 
// handleItemClick = (e, { name }) => this.setState({ activeItem: name })

render() {
  const {intl} =  this.props;
  const {posts} = this.props.blog;

  //const { activeItem } = this.state
  return (
    <ResponsiveContainer>
       <Helmet>
{/*           <title>Blog Ratatool - Les dernières infos de gestion de coûts de revient, fiches techniques recettes, gestion de bon de commandes et le bonnes pratiques pour votre productivité.</title>
          <meta name="description" content="Calculez votre coût de revient avec le mercuriale d'ingrédients de vos recettes, estimez vos marges et ratios, réduisez vos pertes, éditez vos fiches techniques de cuisine et vos étiquettes nutritionnelles INCO pour votre restaurant."/> */}
          <title>{intl.formatMessage(html_tags.html_title)}</title>
          <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
          { intl.locale == 'fr' &&
            <link rel="canonical" href="https://fr.ratatool.com/blog" />}
          { intl.locale == 'en' &&
            <link rel="canonical" href="https://www.ratatool.com/blog" />}
          <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/blog" />
          <link rel="alternate" hreflang="en" href="https://www.ratatool.com/blog" />
          <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/blog" />
       </Helmet>
           {/*<Segment vertical style={{border:'none'}}>
              <HomepageHeading/>  
            </Segment>*/}
       <Segment vertical style={{border:'none',padding: '5em 0em 0em 0em','font-family': 'Lato'}}>
         <Grid container stackable style={styles.FontLato16}/*divided verticalAlign='middle' columns={2}*/>
          <Grid.Row>
            <Grid.Column width={16}>
                <Header as='h1' textAlign='center'>
                          <FormattedMessage id="blog.segment.top.title"
                                            defaultMessage="Blog"/>
                </Header>
            </Grid.Column> 
          </Grid.Row> 
          <Grid.Row>
           <Grid.Column width={16}>
                <Card.Group  style={{marginLeft:'0.5em'}} doubling stackable itemsPerRow={3}>
                {posts &&  posts.map(post => {
                  return(
                        <Card as={Link} to={'/article/'+post.slug}
                              //textAlign='justified'
                              style={{border:'none',backgroundColor: '#fcfcfc','box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                           <Image src={post.cover} centered fluid style={{height:'50%',marginTop:'0em'}}/>
                           <Card.Content style={{border:'none'}}>
                              <Card.Meta  content={post.category} /*as={Link} to={'/category/'+post.category}*/ />
                              <Card.Header as={Link} to={'/article/'+post.slug}>{post.title}</Card.Header>
                              <br/> 
{/*                               <Card.Meta style={{color:'#1765af'}}> <FormattedDate value={new Date(post.updated_at)}
                                                              year='numeric'
                                                              month='long'
                                                              day='2-digit'/></Card.Meta> */}
                             {/* <Card.Description>{post.content.slice(0,130)}...</Card.Description> */}
                           </Card.Content>
                           <Card.Content extra>
                              <p style={{'text-align':'left'}}>
                               {/*  <Icon name='user' size='small'/>Ratatool &emsp;&emsp; */}
                                <Icon name='eye' size='small'/>{post.nb_views}{' '}{' '}
                               {/*  <Link to='#' /*style={{color:'#1765af'}}> */}
                                          <FormattedMessage id="blog.card.link.views"
                                                            defaultMessage="views"/>
                                {/* </Link> */}
                                {/* <Icon name='user' size='small'/>{post.author && post.author.username} */}
  {/*                                &emsp;{post.nb_views}{' '}{' '}
                                   <Link to='#' style={{color:'#1765af'}}>
                                          <FormattedMessage id="blog.card.link.views"
                                                            defaultMessage="views"/>
                                   </Link>
                                   {' '}{' '}<Icon name='heart outline' size='small'/>{this.state.likes} likes */}
                                 <span style={{float:'right'}}>
                                   <Link to={'/article/'+post.slug} style={{color:'#1765af'}}>
                                          <FormattedMessage id="blog.card.link.read"
                                                            defaultMessage="read more"/> &#8594;
                                   </Link>
                                </span>
                              </p>
                          </Card.Content>
                      </Card>
                  )})}
                  </Card.Group>
                 {/*  <CommentExampleComment/> */}
            </Grid.Column>
{/*             <Grid.Column width={3}>
                 <Card.Group>
                    <Card fluid color='blue'>
                          <Card.Content>
                              <Card.Header as='h5' textAlign='left'>
                                Article
                              </Card.Header>
                              <Menu text vertical>
                                <Menu.Item header>Sort By</Menu.Item>
                                <Menu.Item
                                  name='closest'
                                  active={activeItem === 'closest'}
                                  onClick={this.handleItemClick}
                                />
                                <Menu.Item
                                  name='mostComments'
                                  active={activeItem === 'mostComments'}
                                  onClick={this.handleItemClick}
                                />
                                <Menu.Item
                                  name='mostPopular'
                                  active={activeItem === 'mostPopular'}
                                  onClick={this.handleItemClick}
                                />
                              </Menu>
                          </Card.Content>
                        </Card>
                        <br/>
                        <Card fluid color='blue'>
                          <Card.Content>
                              <Card.Header as='h5' textAlign='left'>
                                Tags
                              </Card.Header>
                              <Menu text vertical>
                                <Menu.Item
                                  name='Coût matière'
                                  active={activeItem === 'Coût matière'}
                                  onClick={this.handleItemClick}
                                />
                                <Menu.Item
                                  name='Marge brute'
                                  active={activeItem === 'Marge brute'}
                                  onClick={this.handleItemClick}
                                />
                                <Menu.Item
                                  name='Coût revient recette'
                                  active={activeItem === 'Coût revient recette'}
                                  onClick={this.handleItemClick}
                                />
                              </Menu>
                          </Card.Content>
                        </Card>
                    </Card.Group>
                </Grid.Column>*/}
                </Grid.Row>
            </Grid>
         </Segment>
        <br/> <br/> <br/> <br/> <br/> 
        <MainFooter/>
    </ResponsiveContainer>
  )
 }
}


function mapStateToProps(state) {
  const { blog,alert,dispatch } = state;
  return {
      blog,
      alert,
      dispatch,
  };
}

const connectedBlog = injectIntl(connect(mapStateToProps)(Blog));
export { connectedBlog as Blog };