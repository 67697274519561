import React from 'react'
import {blogActions} from '../_actions';
import {connect} from 'react-redux';
import {MainFooter,ResponsiveContainer} from './Segments';
import { Grid,Header,Segment,Button,Label,Icon,Image} from 'semantic-ui-react';
import {FormattedMessage,FormattedDate,injectIntl,defineMessages} from 'react-intl';
import ReactHtmlParser from 'react-html-parser'; 
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {styles} from './Styles';
import "./BlogPage.scss";

import img_charts from '../Assets/recipes_dashboard.jpeg'

const inlineImageStyle = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(70%)',
        '-moz-filter': 'brightness(70%)',
/*         '-o-filter': 'brightness(50%)',
        '-ms-filter': 'brightness(50%)' */
  },  
};

const inlineImageStyle2 = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(97%)',
        '-moz-filter': 'brightness(97%)',
/*         '-o-filter': 'brightness(50%)',
        '-ms-filter': 'brightness(50%)' */
  },  
};


const html_tags_blog = defineMessages({
/*   html_title: {
       id: 'blogpage.html.title.tag',
       defaultMessage: "Ratatool Blog : last posts and profitability advices for your restaurant management and day to day work with recipe cost tool, recipe forms, suppliers orders",
  }, */
  meta_name_description_blog: {
       id: 'blogpage.meta.name.description.tag',
       defaultMessage: "Ratatool: recipe cost software for restaurant, caterer, bakery, pastry shop, hotel, dark kitchen calculate menu food costs, estimate margin, reduce waste, recipe costing template, orders,food calculator, nutrition USDA",
  }
});




class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {post: []}
    props.dispatch(blogActions.getPost(props.match.params.slug,props.intl.locale));
    props.dispatch(blogActions.getPosts(props.intl.locale,true));
    this.state = {
      shareOpen: "closeShare",
      toggleButtonText: <FormattedMessage id="blogpage.share.button.close.text"
                                          defaultMessage="Share this post"/>
    };

    this.shareOpenToggle = this.shareOpenToggle.bind(this);
    this.shareIconClicked = this.shareIconClicked.bind(this);
  } 

  componentDidMount(){
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozkitUserSelect = 'none';
    document.body.style.MsUserSelect = 'none';
}

  componentWillUnmount(){
    document.body.style.userSelect = 'text';
    document.body.style.WebkitUserSelect = 'text';
    document.body.style.MozkitUserSelect = 'text';
    document.body.style.MsUserSelect = 'text';
  }

  shareOpenToggle() {
    if (this.state.shareOpen==="closeShare") {
        this.setState({
            shareOpen: "openShare",
            toggleButtonText: <FormattedMessage id="blogpage.share.button.open.text"
                                                defaultMessage="Hide sharing options"/>
        });
    }else {
        this.setState({
            shareOpen: "closeShare",
            toggleButtonText: <FormattedMessage id="blogpage.share.button.close.text"
                                                defaultMessage="Share this post"/>
        });
    }   
   }

  shareIconClicked(event,url) {
      event.preventDefault(); 
      //const url = 'somesite.com&data=yourDataToSend';
      var win=window.open(url, '_blank');
      win.focus();
   }

   addRelatedUrlsRows(current, posts) {
    let lasts = posts.slice(-7)
    return lasts.map((p,index) => {
           if (p.id != current.id){
                  return ( 
                  // <p><Link to={"https://fr.ratatool.com/article/"+p.slug}></Link></p> 
                    <div> 
                     
                      <li>&nbsp;<span style={styles.FontLato18}>{' '}{p.category}:</span> 
                        <a href={'/article/'+p.slug}>&nbsp; {p.title}</a>
                        <br/>
                     </li>
                    </div>
                    );
           }
    });
   }


 render() {
     const {post,posts} = this.props.blog;
     const {intl} = this.props;


    //URL from current page
    const url = window.location.href;

    //URL patterns for Social media sites share functionalities
    const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u='+url;
    //const twitterUrl = 'https://twitter.com/share?url='+url;
    const twitterUrl = 'https://twitter.com/intent/tweet?url='+url+'&text=';
    //const linkedinUrl = 'https://www.linkedin.com/shareArticle?mini=true&url='+url;
    const linkedinUrl = 'https://www.linkedin.com/shareArticle?mini=true&url='+url+'&title=';
    const pinterestUrl ='http://pinterest.com/pin/create/link/?url='+url;
    const mailUrl = 'mailto:?body=Check out this interesting site: '+ url + '&subject=Ratatool - ';

    //let mailUrl = ''+ <FormattedMessage id="blogpage.share.email.body.text"
    //                                defaultMessage="mailto:?body=Check out this interesting site: {url_link}&subject=Ratatool - "
    //                                values={{url_link:url}}/>

    //console.log(intl.formatMessage({id:'blogpage.share.email.body.text'}))

    return (
    <ResponsiveContainer>
      { post &&
      <Segment vertical style={{border:'none',padding: '5em 0em 0em 0em',/*'font-family': 'Lato'*/}}>
          <Helmet>
            <title>{post.title} - Ratatool</title>
            {post.tag_title && <title>{post.tag_title}</title>}
            {/* <meta name="description" content={'Calculez votre prix de revient recette, editez vos fiches techniques de cuisine, vos bons de commandes fournisseurs et vos étiquettes nutritionnelles INCO pour votre restaurant'}/> */}
            <meta name="description" content={intl.formatMessage(html_tags_blog.meta_name_description_blog)}/>

            { intl.locale == 'fr' &&
            <link rel="canonical" href={"https://fr.ratatool.com/article/"+post.slug}/>}

             { intl.locale == 'en' &&
            <link rel="canonical" href={"https://www.ratatool.com/article/"+post.slug} />}
            
{/*             <link rel="alternate" hreflang="fr" href={"https://fr.ratatool.com/article/"+post.slug} />
            <link rel="alternate" hreflang="en" href={"https://www.ratatool.com/article/"+post.slug} />
            <link rel="alternate" hreflang="x-default" href={"https://www.ratatool.com/article/"+post.slug} /> */}

         </Helmet> 
         <Grid  stackable container centered /*verticalAlign='middle'*/ columns={1} >
          <Grid.Row>
            <Grid.Column width={16}>
                <Header as='h3' textAlign='center' style={{color:'#bfbfbf'}}>
                  {post.category}
                </Header>
                <Header as='h1' textAlign='center'>
                  {post.title}
                </Header>
                <p style={{textAlign:'center', fontSize: '14px' ,'font-family': 'Lato'}}>
                {/* <Header as='h3' textAlign='center'  color='blue'> */}
                  {/*  <Icon name='clock' fitted size='small'/> */}  
                   {' '}<FormattedDate value={new Date(post.created_at)}
                                                                  year='numeric'
                                                                  month='long'
                                                                  day='2-digit'/>  
                    &emsp;{'  '} &emsp;<Icon name='eye' fitted size='small'/>{' '}{post.nb_views}{' '}{' '}
                    <FormattedMessage id="blogpage.title.views.text"
                                      defaultMessage="views"/>
                {/* </Header> */}
                </p>
                 <p style={{textAlign:'center', fontSize: '14px' ,'font-family': 'Lato'}}>
                  <Button as={Link} to="/register" style={styles.ButtonBlueAsIconSmall} >
                        <FormattedMessage id="blogpage.segment.try.button.top.register"
                                          defaultMessage="Create your recipe costing templates"/>
                  </Button>
                </p>
                <p style={{textAlign:'center', fontSize: '14px' ,'font-family': 'Lato', 'font-weight':'600', color:'#63a4dd'}}>
                  <FormattedMessage id="blogpage.segment.try.info.text"
                                   defaultMessage="100% online restaurant management and recipe costing"/></p>
            </Grid.Column> 
          </Grid.Row> 
         {/*  <Grid.Row> */}
            {/*  <Grid.Column> */}
            <div>
                  <Image centered image src={post.cover} size='large'/*  style={inlineImageStyle2.img} *//>
            </div>
             {/* </Grid.Column> */}
 {/*          <Grid.Column width={16}>   
                 <Card as={Link} to='#'  
                                style={{border:'none',backgroundColor: '#fcfcfc','box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}} fluid>
                        <Card.Content style={{border:'none'}}>
                        <Image centered fluid src={post.cover} style={inlineImageStyle2.img}/>
                          <Card.Meta style={{color:'#1765af'}}> <FormattedDate value={new Date(post.created_at)}
                                                              year='numeric'
                                                              month='long'
                                                              day='2-digit'/>
                         </Card.Meta>
                         </Card.Content>
                         <Card.Content style={{border:'none'}}>
                              <p style={{'text-align':'left', color:'#1765af'}}>
                                <Icon name='user' />{post.author.username}

                                 <span style={{float:'right'}}>
                                 {post.nb_views}{' '}{' '}
                                   <Link to="/dashboard" style={{color:'#1765af'}}>
                                          <FormattedMessage id="blog.card.link.views"
                                                            defaultMessage="views"/>
                                   </Link>
                                </span>
                              </p>
                          </Card.Content>
                          <Card.Content style={{border:'none'}}>
                              <Card.Header>{post.title}</Card.Header>
                              <br/> 

                             <Card.Description>{post.content}</Card.Description>
                          </Card.Content>
                </Card> 
             </Grid.Column> */}
           {/*  </Grid.Row> */}
            <Grid.Row  columns={1}>
              <Grid.Column width={14}>
              {/*  <Container content fluid textAlign='left'> */}
                {/* <div style={styles.FontLato14Black} dangerouslySetInnerHTML={{__html: post.content}} /> */}
                <div style={styles.FontLato14} style={{ 'user-select': 'none;'}}> { ReactHtmlParser(post.content) } </div>
                <Button as={Link} to="/register" style={styles.ButtonBlueAsIcon}>
                      <FormattedMessage id="blogpage.segment.try.button.text"
                                        defaultMessage="Try now for free"/>
                </Button>
{/*                 <CommentExampleComment/>
                <Statistic.Group>
                  <Statistic>
                    <Statistic.Value>{post.nb_views}</Statistic.Value>
                    <Statistic.Label>Views</Statistic.Label>
                  </Statistic>
                </Statistic.Group> */}
                {/* </Container> */}
              </Grid.Column> 
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column width={12} textAlign='right'>
                <div className="socialShareContainer">
                  <div>
                        <Button as='div' labelPosition='right' className="socialShareButton" onClick={this.shareOpenToggle}>
                          <Button basic color='blue'>
                            <Icon name='share alternate' size='large' color='blue'/>
                            {this.shareOpenToggle}
                          </Button>
                          <Label as='a' basic color='blue' /*pointing='right'*/>
{/*                               <FormattedMessage id="blogpage.share.button.text"
                                                defaultMessage="Share this post"/> */}
                                  {this.state.toggleButtonText}
                          </Label>
                        </Button>
                  </div>
                  <div className={this.state.shareOpen}>
{/*                       <a href={facebookUrl} target="_blank"> <Icon name='facebook official' color='facebook' size='big'/></a>
                      <a href={linkedinUrl} target="_blank"><Icon name='linkedin official' color='linkedin' size='big'/></a>
                      <a href={twitterUrl} target="_blank"> <Icon name='twitter official' color='twitter' size='big'/></a>
                            <a href={pinterestUrl} target="_blank"> <Icon name='pinterest official'  color='pinterest' size='big'/></a> */}
                      <br/>      
                      <Button circular color='facebook' icon='facebook' onClick={(event) => this.shareIconClicked(event,facebookUrl)}/>
                      <Button circular color='twitter' icon='twitter'  onClick={(event) => this.shareIconClicked(event,twitterUrl+post.title)}/>
                      <Button circular color='linkedin' icon='linkedin'  onClick={(event) => this.shareIconClicked(event,linkedinUrl+post.title)}/>
                      {/* <Button circular color='pinterest' icon='pinterest'  onClick={(event) => this.shareIconClicked(event,pinterestUrl)}/> */}
                      <Button circular 
                              color='grey' 
                              icon='mail'  
                              onClick={(event) => this.shareIconClicked(event,mailUrl+post.title)}/>
                  </div>       
                </div>      
                </Grid.Column>
              </Grid.Row>
              <br/>
              <Grid.Row  columns={2}>
              <Grid.Column width={3}>
                  <br/>  <br/>  <br/> 
                  <Image centered image src={img_charts} size='large'/*  style={inlineImageStyle2.img} *//>
              </Grid.Column>
              <Grid.Column width={11}>
                <br/> 
                <Header as='h3' textAlign='left'>
                     <FormattedMessage id="blogpage.segment.others.articles.text"
                                       defaultMessage="Articles that might interest you:"/>
                </Header>  
                    { post && posts &&
                       <ol style={styles.FontLato18}> 
                        {this.addRelatedUrlsRows(post, posts)}
                       </ol>
                    }
              </Grid.Column> 
            </Grid.Row>
          </Grid>
          </Segment>
         }
        <br/> <br/> <br/> <br/> <br/> 
        <MainFooter/>
    </ResponsiveContainer>
  )
 }
}


function mapStateToProps(state) {
  const { blog,alert,dispatch } = state;
  return {
      blog,
      alert,
      dispatch,
  };
}

const connectedBlogPage = injectIntl(connect(mapStateToProps)(BlogPage));
export { connectedBlogPage as BlogPage };