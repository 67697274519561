import React from 'react'
import { Button, Form, Grid, Header,Dropdown,Icon,Flag } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions,alertActions } from '../_actions';
import {FormattedMessage,defineMessages,injectIntl} from 'react-intl';
import {serverConstants } from '../_constants';
import {Helmet} from "react-helmet";
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import TagManager from 'react-gtm-module'
import {toaster_dispatch} from '../_helpers';
import ButterToast  from 'butter-toast';

const html_tags = defineMessages({
  html_title: {
       id: 'login.html.title.tag',
       defaultMessage: "Log in",
  },
  meta_name_description: {
       id: 'login.meta.name.description.tag',
       defaultMessage: "Recipe cost software to calculate food costs of your restaurant recipes, estimate your margin, reduce your waste, edit and print your professional recipe forms, your shopping orders and your nutrition facts labels from USDA data",
  }
});

class LoginLayout extends React.Component {
    constructor(props) {
        super(props);

        /// reset login status
/*         let user = JSON.parse(localStorage.getItem('userIn'));
        if (user && user.token)
        { */
        this.props.dispatch(userActions.logout());
/*         }   */
       
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { dispatch,location } = this.props;
        const { username, password } = this.state;

        dispatch(alertActions.clear());
        this.setState({ submitted: true });
        
        if (username && password) {
            if (location && location.state && location.state.from){
              //console.log(location.state.from.state)
              dispatch(userActions.login(username, password,location.state.from.pathname,location.state.from.state));
            }else{
              dispatch(userActions.login(username, password));  
            }

            let tagManagerArgs = {
              dataLayer: {
                  username: username,
                  page: 'LoginLayout'
              },
              dataLayerName: 'PageDataLayer'
            }
  
            TagManager.dataLayer(tagManagerArgs)
        }
    }


    render() {
        const { alert,intl,dispatch } = this.props;
        const { username, password ,submitted } = this.state;

        return (
              <Grid
                textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                 <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0,'padding-left':10,'padding-right':10}}>
                  <Grid.Column  style={{ top: "5%", maxWidth: 450, minHeight:'100vh' }} >
                    <br/>
                    <Header as='h4' align='left'>
                     <Icon name="world" style={{color:'#4286e3'}} size='large'/>{' '}
                     <Header.Content>
                     <Dropdown text={intl.locale}
                        inline
                        upward={true}
                       //pointing ='right'
                        //compact
                        style={{color:'#4286e3'}}>
                        <Dropdown.Menu>
                        <Dropdown.Item text='Français' icon={<Flag name='fr' />} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR + '/login'} size='large'/>
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                         selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN + '/login'}  size='large'/>
                        </Dropdown.Menu>
                      </Dropdown>
                     </Header.Content>
                   </Header><br/><br/>
                    <img alt='logo' src={img_logo} style={{width: '168px', height: '49px' }} />
                    <Header as='h3' color='grey' textAlign='center'>
                      {' '}<FormattedMessage id="login.form.title"
                                             defaultMessage='Log in to your account'/>
                    </Header>
{/*                      {alert.message && alert.type == alertConstants.ERROR && 
                      <Header as='h4' color='red' textAlign='center'>
                          <div className={`alert ${alert.type}`}>{alert.message}</div>
                      </Header>
                     } */}
{/*                     {alert.message && alert.type == alertConstants.WARN && 
                      <Header as='h4' color='orange' textAlign='center'>
                          <div className={`alert ${alert.type}`}>
                            {intl.formatMessage({ id: alert.message.title })} {' : '}
                            {intl.formatMessage({ id: alert.message.content })}</div>
                      </Header>
                    } */}
                    <Helmet>
                      {/* <title>Bienvenue sur Ratatool - Calculer vos coûts de revient et editez vos fiches techniques de cuisine </title>
                      <meta name="description" content="Calculez votre coût revient avec le mercuriale d'ingrédients de vos recettes, estimez vos marges, réduisez vos pertes, éditez vos fiches techniques et vos étiquettes nutritionnelles."/> */}
                      <title>{intl.formatMessage(html_tags.html_title)}</title>
                      <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
                      { intl.locale == 'fr' &&
                          <link rel="canonical" href="https://fr.ratatool.com/login" />}

                      { intl.locale == 'en' &&
                            <link rel="canonical" href="https://www.ratatool.com/login" />}
                      <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/login" />
                      <link rel="alternate" hreflang="en" href="https://www.ratatool.com/login" />
                      <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/login" />
                     
                      contact
                    </Helmet>
                    <Form size='large' onSubmit={this.handleSubmit}>
                    {/*   <Segment stacked> */}
                        <FormattedMessage id="login.form.email.placeholder"
                                            defaultMessage='Username or Email'>
                            {placeholder => 
                                      <Form.Input
                                        fluid
                                        icon='user'
                                        iconPosition='left'
                                        placeholder={placeholder}
                                        type='text'
                                        name='username'
                                        value={username}
                                        onChange={this.handleChange} 
                                        error={submitted && !username && 
                                          { content: <FormattedMessage id="login.form.email.error.message"
                                                                       defaultMessage='Username/Email is required'/> }}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !username &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="login.form.email.error.message"
                                                defaultMessage='Username/Email is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="login.form.password.placeholder"
                                            defaultMessage='Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='password'
                                          value={password}
                                          onChange={this.handleChange} 
                                          error={submitted && !password && 
                                            { content: <FormattedMessage id="login.form.password.error.message"
                                                                         defaultMessage='Password is required'/> }}
                                        />}
                        </FormattedMessage>
{/*                         {submitted && !password &&
                          <label style={{color:'red'}}><i>
                            <FormattedMessage id="login.form.password.error.message"
                                              defaultMessage='Password is required'/> 
                            </i></label>
                        } */}
                        <Button color='blue' 
                                fluid size='large' 
                                type='submit'>
                          <FormattedMessage id="login.form.button.continue"
                                            defaultMessage='Continue'/>
                        </Button>
                        <br/><Link to="/pwdreset">
                            <FormattedMessage id="login.form.button.forgotten"
                                              defaultMessage='Forgotten password ?'/>
                            </Link><br/><br/>
                        <Button color='grey' 
                                fluid 
                                size='large' 
                                onClick={()=> {this.props.history.replace('/register')}}>
                              <FormattedMessage id="login.form.button.new"
                                                defaultMessage="I'm new here"/>
                        </Button>
                        <br/><Link to="/">
                                &#8592;  <FormattedMessage id="login.form.button.back"
                                                            defaultMessage="Back"/>
                            </Link>
                    {/*   </Segment> */}
                    </Form>
                    <br/><br/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 700,  height: '100vh'}}> 
                   <img src={img_window} alt='window' style={{width: '100vh',height: '100vh'}}/>
                   </Grid.Column>
                  <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <br/>
                    <Header as='h4' align='left'>
                     <Icon name="world" style={{color:'#4286e3'}} size='large'/>{' '}
                     <Header.Content>
                     <Dropdown text={intl.locale}
                        inline
                        upward={true}
                       //pointing ='right'
                        //compact
                        style={{color:'#4286e3'}}>
                        <Dropdown.Menu>
                        <Dropdown.Item text='Français' icon={<Flag name='fr' />} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR + '/login'} size='large'/>
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                         selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN + '/login'}  size='large'/>
                        </Dropdown.Menu>
                      </Dropdown>
                     </Header.Content>
                   </Header><br/><br/>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {' '}<FormattedMessage id="login.form.title"
                                            defaultMessage='Log in to your account'/>
                    </Header>
{/*                      {alert.message && alert.type == alertConstants.ERROR && 
                      <Header as='h4' color='red' textAlign='center'>
                          <div className={`alert ${alert.type}`}>{alert.message}</div>
                      </Header>
                     } */}
{/*                     {alert.message && alert.type == alertConstants.WARN && 
                      <Header as='h4' color='orange' textAlign='center'>
                          <div className={`alert ${alert.type}`}>
                            {intl.formatMessage({ id: alert.message.title })} {' : '}
                            {intl.formatMessage({ id: alert.message.content })}</div>
                      </Header>
                    } */}
                    <Form size='large' onSubmit={this.handleSubmit}>
                     {/*  <Segment stacked> */}
                      <FormattedMessage id="login.form.email.placeholder"
                                            defaultMessage='Username or Email'>
                            {placeholder => 
                                      <Form.Input
                                        fluid
                                        icon='user'
                                        iconPosition='left'
                                        placeholder={placeholder}
                                        type='text'
                                        name='username'
                                        value={username}
                                        onChange={this.handleChange} 
                                        error={submitted && !username && 
                                          { content: <FormattedMessage id="login.form.email.error.message"
                                                                       defaultMessage='Username/Email is required'/> }}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !username &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="login.form.email.error.message"
                                                defaultMessage='Username/Email is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="login.form.password.placeholder"
                                            defaultMessage='Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='password'
                                          value={password}
                                          onChange={this.handleChange} 
                                          error={submitted && !password && 
                                            { content: <FormattedMessage id="login.form.password.error.message"
                                                                         defaultMessage='Password is required'/> }}
                                        />}
                        </FormattedMessage>
{/*                         {submitted && !password &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="login.form.password.error.message"
                                                defaultMessage='Password is required'/> 
                            </i></label>
                        } */}
                        <Button color='blue' 
                                fluid size='large' 
                                type='submit'>
                            <FormattedMessage id="login.form.button.continue"
                                              defaultMessage='Continue'/>
                        </Button>
                        <br/><Link to="/pwdreset">
                              <FormattedMessage id="login.form.button.forgotten"
                                              defaultMessage='Forgotten password ?'/>
                            </Link>
                        <br/><br/>
                        <Button color='grey' 
                                fluid 
                                size='large' 
                                onClick={()=> {this.props.history.replace('/register')}}>
                              <FormattedMessage id="login.form.button.new"
                                                defaultMessage="I'm new here"/>
                        </Button>
                        <br/><Link to="/">
                                &#8592;  <FormattedMessage id="login.form.button.back"
                                                            defaultMessage="Back"/>
                            </Link>
                     {/*  </Segment> */}
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_TOP' }}/>
                {alert.message && toaster_dispatch(dispatch,alert,intl)}
              </Grid>
          );
    }
}

function mapStateToProps(state) {
    const { loggingIn,loggedIn } = state.authentication;
    const { alert ,dispatch} = state;
    return {
        loggingIn,
        loggedIn,
        alert,
        dispatch
    };
}

const connectedLoginPage = injectIntl(connect(mapStateToProps)(LoginLayout));
export { connectedLoginPage as LoginLayout };