import { authHeader,authHeaderImage,isEmpty } from '../_helpers';
import { serverConstants } from '../_constants';
import { saveAs } from 'file-saver';

export const ingredientService = {
    getAll,
    add,
    get,
    update,
    updateBatch,
    delete: _delete,
    deleteSelect,
    categorySelect,
    stockAreaSelect,
    count,
    price_evolutions,
    history_ingredient,
    recipes,
    update_nutrition,
    export_csv,
    export_xlsx,
    update_stock,
    reset_stock,
    inventory,
    ingredient_weight_variation,
    updateImage,
    getImage,
    deleteImage,
    ingredient_add_extra_supplier,
    ingredient_edit_extra_supplier,
    ingredient_delete_extra_supplier,
    //ingredient_default_supplier,
    getExtraSuppliers,
    ingredient_update_stock_area,
    updatePriceDefaultSupplier,
};


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
/*             const error = new Error(json.message)
            return Promise.reject(Object.assign(error, { response })) */
/*             const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
            }); 
            return Promise.reject(error);*/

            if (!isEmpty(json.message)){
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
               });
               return Promise.reject(error);
           }else{
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
                   title: json.detail,
               });
               return Promise.reject(error);
           }   
        })
    }

    return response.json();
}

function getAll(short) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (short == true){
        //Speed flat request (not nested)
        return fetch(serverConstants.BACKEND_URL+'/ingredients/get_list/', requestOptions).then(handleResponse);
    }else{
        return fetch(serverConstants.BACKEND_URL+'/ingredients/', requestOptions).then(handleResponse);
    }
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        //headers: {
        //    'Access-Control-Allow-Origin': '*',
        //    'Content-Type': 'application/json',
        //    'Accept': 'application/json',                  
        //}
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+id+'/', requestOptions).then(handleResponse);
}

function add(ingredient) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/', requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/' + id +'/', requestOptions).then(handleResponse);
}

function deleteSelect(ingredients) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'ids':ingredients})
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/delete_select/', requestOptions).then(handleResponse);
}

function stockAreaSelect(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/stockarea_select/', requestOptions).then(handleResponse);
}

function categorySelect(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/category_select/', requestOptions).then(handleResponse);
}


function count() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/count/', requestOptions).then(handleResponse);
}

function update(id,ingredient) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/', requestOptions).then(handleResponse);
}

function updateBatch(ingredient) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/update_bulk/', requestOptions).then(handleResponse);
}

function updatePriceDefaultSupplier(id,ingredient) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/price_default_supplier/', requestOptions).then(handleResponse);
}

function recipes(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/recipes/', requestOptions).then(handleResponse);
}


function price_evolutions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/price_evolutions/', requestOptions).then(handleResponse);
}


function history_ingredient(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+id+'/history/', requestOptions).then(handleResponse);
}


function update_nutrition(id,nutrition) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(nutrition)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/', requestOptions).then(handleResponse);
}


function export_csv(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/export_csv/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ingredients.csv'))
}


function export_xlsx(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/export_xlsx/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ingredients.xlsx'))
}


function inventory(date,supplier,storageArea) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'supplier':supplier,
                              'storageArea':storageArea}),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genInventory/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'stock_'+date+'.pdf'))
/*     .then(response => {
            var blob=response
            var reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                var base64data = reader.result;
                window.open(base64data);
            }
          })
    .catch(error => {
            console.error(error);
          }); */
}


function update_stock(id,quantity) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({'stock_quantity':quantity})
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/stock/', requestOptions).then(handleResponse);
}


function reset_stock() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/reset_stock/', requestOptions).then(handleResponse);
}


function ingredient_weight_variation(id,params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/apply_weight_variation/', requestOptions).then(handleResponse);
}

function updateImage(id,image) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeaderImage(),
        body: image
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/image/', requestOptions).then(handleResponse);
}

function getImage(id,image) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/getimage/', requestOptions).then(handleResponse);
}


function deleteImage(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeaderImage(),
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/deleteimage/', requestOptions).then(handleResponse);
}


function ingredient_add_extra_supplier(id,params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/add_extra_supplier/', requestOptions).then(handleResponse);
}

function ingredient_edit_extra_supplier(id,params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/edit_extra_supplier/', requestOptions).then(handleResponse);
}

function ingredient_delete_extra_supplier(id,params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/delete_extra_supplier/', requestOptions).then(handleResponse);
}


/* function ingredient_default_supplier(id,params) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/default_supplier/', requestOptions).then(handleResponse);
} */


function getExtraSuppliers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(serverConstants.BACKEND_URL+'/ingredientsuppliers/', requestOptions).then(handleResponse);

}


function ingredient_update_stock_area(id,params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/ingredients/'+ id +'/update_storage_area/', requestOptions).then(handleResponse);
}