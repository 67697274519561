export const categoryRecipeConstants = {
    GETALL_REQUEST: 'CATEGORYRECIPE_GETALL_REQUEST',
    GETALL_SUCCESS: 'CATEGORYRECIPE_GETALL_SUCCESS',
    GETALL_FAILURE: 'CATEGORYRECIPE_GETALL_FAILURE',

    GET_REQUEST: 'CATEGORYRECIPE_GET_REQUEST',
    GET_SUCCESS: 'CATEGORYRECIPE_GET_SUCCESS',
    GET_FAILURE: 'CATEGORYRECIPE_GET_FAILURE',

    ADD_REQUEST: 'CATEGORYRECIPE_ADD_REQUEST',
    ADD_SUCCESS: 'CATEGORYRECIPE_ADD_SUCCESS',
    ADD_FAILURE: 'CATEGORYRECIPE_ADD_FAILURE',

    UPDATE_REQUEST: 'CATEGORYRECIPE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CATEGORYRECIPE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CATEGORYRECIPE_UPDATE_FAILURE',

    DELETE_REQUEST: 'CATEGORYRECIPE_DELETE_REQUEST',
    DELETE_SUCCESS: 'CATEGORYRECIPE_DELETE_SUCCESS',
    DELETE_FAILURE: 'CATEGORYRECIPE_DELETE_FAILURE',
    
    COUNT_REQUEST: 'CATEGORYRECIPE_COUNT_REQUEST',
    COUNT_SUCCESS: 'CATEGORYRECIPE_COUNT_SUCCESS',
    COUNT_FAILURE: 'CATEGORYRECIPE_COUNT_FAILURE',

    COUNT_RECIPES_REQUEST: 'CATEGORYRECIPE_COUNT_RECIPES_REQUEST',
    COUNT_RECIPES_SUCCESS: 'CATEGORYRECIPE_COUNT_RECIPES_SUCCESS',
    COUNT_RECIPES_FAILURE: 'CATEGORYRECIPE_COUNT_RECIPES_FAILURE',

/*     SEND_POS_REQUEST: 'CATEGORYRECIPE_SEND_POS_REQUEST',
    SEND_POS_SUCCESS: 'CATEGORYRECIPE_SEND_POS_SUCCESS',
    SEND_POS_FAILURE: 'CATEGORYRECIPE_SEND_POS_FAILURE', */
};