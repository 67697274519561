import React, { Component } from 'react';
import { recipesActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid,Icon,Header,Loader,Dimmer,Breadcrumb} from 'semantic-ui-react';
import SideLeftUncoverMenu from './SidebarMenu';
import { history,toaster_dispatch,Capitalize} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';

class DeleteMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    props.dispatch(recipesActions.getMenu(props.match.params.menuId));
    //console.log(props)
 }

 deleteMenu (event,index) {
    event.preventDefault();
    this.props.dispatch(recipesActions.deleteMenu(index));
    
 }

 editMenu (event,index) {
    event.preventDefault();
    //this.props.dispatch(recipesActions.get(index,'/recipedetails'));
    history.push('/editmenu/'+index);
 }


  render() {
    const { dispatch,alert,menu,loading,intl } = this.props;
    return (
      <div>
      { loading &&
        <SideLeftUncoverMenu>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverMenu>
      }
      { !loading && menu &&
      <SideLeftUncoverMenu id={this.props.match.params.menuId}>
         <Grid stackable style={{border:'none','background-color':'#fcfcfc','font-family': 'Lato', 'font-size': '12px','color': 'grey'}}>
            <Grid.Row columns={1}>
                                <Breadcrumb  style={{background:'#fcfcfc'}}>
                                    <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="deletemenu.link.dashboard"
                                                                defaultMessage='Dashboard'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/menus/'}><FormattedMessage id="deletemenu.link.menus"
                                                                defaultMessage='Menus'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/editmenu/'+this.props.match.params.menuId}>{menu && menu.name}</Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section active><FormattedMessage id="deletemenu.link.delete"
                                                                defaultMessage='Delete'/></Breadcrumb.Section>
                                </Breadcrumb>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={10}>
                        <Header as='h2'>
                              <Icon name='trash alternate outline' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="deletemenu.header.title"
                                                    defaultMessage='Delete Menu'/>
                                  <Header.Subheader>{menu && menu.name}</Header.Subheader>
                                </Header.Content>
                        </Header>
                        <br/>
                </Grid.Column>
           </Grid.Row>
           <Grid.Row columns={1}>
              <Grid.Column width={10}>
                  {menu &&
                 <Message style={styles.FontLato13}>
                    <Message.Header> 
                          <FormattedMessage id="deletemenu.card.header.title"
                                            defaultMessage='Deletion of menu  : {name}'
                                            values={{name:menu.name}}/>
                    </Message.Header>
                    <br/>
                    <p> 
                          <FormattedMessage id="deletemenu.card.header.message"
                                            defaultMessage='Are you really sure to complete the deletion ?'/>
                    </p>
                    <br/>
                    <Button style={styles.ButtonGreen} //basic bsstyle="primary" color='teal'
                            onClick={(event) =>this.deleteMenu(event,menu.id)}>
                            <i className="trash alternate outline icon"></i>
                            <FormattedMessage id="deletemenu.card.button.delete"
                                            defaultMessage='Delete'/>
                    </Button>
                    <Button style={styles.ButtonGrey} //basic bsstyle="primary" color='grey'
                            onClick={(event) =>this.editMenu(event,menu.id)}>
                            <i className="cancel icon"></i>
                            <FormattedMessage id="deletemenu.card.button.cancel"
                                            defaultMessage='Cancel'/>
                    </Button>
                    <br/>
                </Message>}
             </Grid.Column>
          </Grid.Row >
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </Grid>
      </SideLeftUncoverMenu>}
     </div> 
    );
  }
}

function mapStateToProps(state) {
  const { dispatch,alert } = state;
  const {loading,menu} = state.recipes;
  return {
        menu,
        dispatch,
        alert,
        loading
  };
}

const connectedDeleteMenu = injectIntl(connect(mapStateToProps)(DeleteMenu));
export { connectedDeleteMenu as DeleteMenu };
