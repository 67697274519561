import { authHeader,nonAuthHeader,authHeaderImage,authHeaderMultipart,TOKEN_EXPIRATION_DURATION_HOURS} from '../_helpers';
import { serverConstants } from '../_constants';


export const userService = {
    login,
    logout,
    register,
    registerConfirm,
    verify,
    renew,
    passwordReset,
    passwordConfirm,
    passwordChange,
    user,
    update,
    addLead,
    getOrganisation,
    addOrganisation,
    editOrganisation,
    getInvitation,
    sendInvitation,
    updateInvitation,
    deleteInvitation,
    updateLogo,
    editOrganisationLogo,
/*     connectPOS,
    getPOS,
    deletePOS,
    updatePOS, */
    //acceptInvitation,
    /*getAll,
    getById,
    update,
    delete: _delete*/
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify({ username, password })
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/login/', requestOptions)
        .then(response => {
            //console.log(response)
            if (!response.ok) { 
                 //return Promise.reject("User or Password is wrong");
                 return response.json().then(json => {
                    const error = new Error(json.non_field_errors[0])
                    return Promise.reject(Object.assign(error, { response }))
                })
            }
            return response.json();
        })
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('userIn', JSON.stringify(user));
                var d = new Date();
                localStorage.setItem('expire_at', d.setHours(d.getHours()+TOKEN_EXPIRATION_DURATION_HOURS));
            }
            return user;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('userIn');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('hideInstructions');
}

function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
            //const error = new Error(json)
            //return Promise.reject(Object.assign(error, { response }))

            const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
            });
            return Promise.reject(error);
        })
    }    
    return response.json();
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify(user)
    };
    return fetch(serverConstants.BACKEND_URL+'/rest-auth/registration/', requestOptions).then(handleResponse);
}

function registerConfirm(key) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify({key:key})
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/registration/verify-email/', requestOptions).then(handleResponse);
}

function passwordReset(email) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify({email:email})
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/password/reset/', requestOptions).then(handleResponse);
}

function passwordConfirm(infos) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify(infos)
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/password/reset/confirm/', requestOptions).then(handleResponse);
}


function passwordChange(infos) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        headers: authHeader(),
        body: JSON.stringify(infos)
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/password/change/', requestOptions).then(handleResponse);
}

/*
/rest-auth/user/ (GET, PUT, PATCH)
        username
        first_name
        last_name
    Returns pk, username, email, first_name, last_name
*/
function user() {
    const requestOptions = {
        method: 'GET',
        //headers: { 'Content-Type': 'application/json' },
        //body: JSON.stringify(infos)
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/user/', requestOptions).then(handleResponse);        

}

function update(user_infos) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:  JSON.stringify(user_infos)
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/user/', requestOptions).then(handleResponse)
            .then(user => {
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    let userCurrent = JSON.parse(localStorage.getItem('userIn'));
                    userCurrent.user = user;
                    //localStorage.removeItem('userIn');
                    localStorage.setItem('userIn', JSON.stringify(userCurrent));
                }
                return user;
            })
}

function updateLogo(user_infos,file_data) {
    var formdata = new FormData();
    formdata.append("username", user_infos.username);
    formdata.append("is_active", true);

    if (user_infos.logo_delete){
      formdata.append("logo_delete", user_infos.logo_delete);
    }
    if (user_infos.currency){
        formdata.append("currency", user_infos.currency);
    }
    if (user_infos.use_logo){
        formdata.append("use_logo", user_infos.use_logo);
    }
    if (user_infos.use_custom_logo){
        formdata.append("use_custom_logo", user_infos.use_custom_logo);
    }
    if (file_data){
        formdata.append('image_logo', file_data,file_data.name);
    }
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeaderImage(),
        body: formdata,
    };

    return fetch(serverConstants.BACKEND_URL+'/rest-auth/user/', requestOptions).then(handleResponse)
            .then(user => {
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    let userCurrent = JSON.parse(localStorage.getItem('userIn'));
                    userCurrent.user = user;
                    //localStorage.removeItem('userIn');
                    localStorage.setItem('userIn', JSON.stringify(userCurrent));
                }
                return user;
            })
}


function verify(token) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify({'token':token})
    };

    return fetch(serverConstants.BACKEND_URL+'/verify-token/', requestOptions)
        .then(response => {
            //console.log(response)
            if (!response.ok) { 
                return Promise.reject("Authentication token has expired");
            }
            return response.json();
        })
/*         .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('userIn', JSON.stringify(user));
            }
            return user;
        }) */
}

function renew(token) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify({'token':token})
    };

    return fetch(serverConstants.BACKEND_URL+'/renew-token/', requestOptions)
        .then(response => {
            //console.log(response)
            if (!response.ok) { 
                return Promise.reject("Authentication token is no longer valid");
            }
            return response.json();
        })
    /*         .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('userIn', JSON.stringify(user));
            }
            return user;
        }) */
}


function addLead(lead) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify(lead)
    };

    return fetch(serverConstants.BACKEND_URL+'/leads/', requestOptions).then(handleResponse);
}


function getOrganisation(user) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(serverConstants.BACKEND_URL+'/orgs/', requestOptions).then(handleResponse);
}


function addOrganisation(infos) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/orgs/', requestOptions).then(handleResponse);
}

function editOrganisation(id,infos) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/orgs/'+ id +'/', requestOptions).then(handleResponse);
}

function editOrganisationLogo(id,infos,file_data) {
    var formdata = new FormData();
    formdata.append("name", infos.name);

    if (infos.logo_delete){
        formdata.append("logo_delete", infos.logo_delete);
      }
      if (infos.use_logo){
          formdata.append("use_logo", infos.use_logo);
      }
      if (infos.use_custom_logo){
          formdata.append("use_custom_logo", infos.use_custom_logo);
      }
      if (file_data){
          formdata.append('image_logo', file_data,file_data.name);
      }

    const requestOptions = {
        method: 'PUT',
        headers: authHeaderImage(),
        body: formdata,
    };
    return fetch(serverConstants.BACKEND_URL+'/orgs/'+ id +'/', requestOptions).then(handleResponse);
}


function getInvitation() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(serverConstants.BACKEND_URL+'/cinvitations/', requestOptions).then(handleResponse);
}

function sendInvitation(infos) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/send-invite/', requestOptions).then(handleResponse);
}

function updateInvitation(id,infos) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/cinvitations/'+ id +'/', requestOptions).then(handleResponse);
}

function deleteInvitation(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        //body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/cinvitations/'+ id +'/', requestOptions).then(handleResponse);
}


/* function acceptInvitation(key) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
    };
    return fetch(serverConstants.BACKEND_URL+'/accept-invite/'+key, requestOptions).then(handleResponse);
} */


/* function connectPOS(infos) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/pos/', requestOptions).then(handleResponse);
}


function getPOS(user) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(serverConstants.BACKEND_URL+'/pos/', requestOptions).then(handleResponse);
}


function deletePOS(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(serverConstants.BACKEND_URL+'/pos/'+ id +'/', requestOptions).then(handleResponse);
}


function updatePOS(id,infos) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(infos)
    };
    return fetch(serverConstants.BACKEND_URL+'/pos/'+ id +'/', requestOptions).then(handleResponse);
} */

