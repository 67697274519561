import React from 'react';
import { connect } from 'react-redux';
import {Elements} from 'react-stripe-elements';
import {Grid} from 'semantic-ui-react';
import SideLeftUncoverPayment from './SidebarPayment';
import {SCAForm} from './Payment/SCAForm';
import {injectIntl} from 'react-intl';


//StripeProvider gives us access to the Stripe Object
//i.e Stripe.createToken, stripe.elements() etc
//App loads the stripe script asynchronously in CDM
import {StripeProvider} from 'react-stripe-elements';

//Wrap <Elements /> around your checkout form to group the set
// of Stripe Elements you're using together, so Stripe can pull
// data from groups of Elements when tokenizing

import { stripeConstants } from '../_constants';


class SCACheck extends React.Component {
  constructor(props) {
        super(props);
        //const {match} = props;
        //this.state = {client_secret: match.params.client_secret,
        //             };
    }


  render() {
    //const {client_secret} = this.state;
    const {intl} = this.props;
    const {client_secret,last_intent_infos} = this.props.location.state;

    return (
        <SideLeftUncoverPayment >
            <Grid stackable>
                <Grid.Row columns={1} >
                    <Grid.Column  width={8}>
                         <StripeProvider apiKey={''+stripeConstants.STRIPE_PUBLIC_KEY}>
                            <Elements locale={intl.locale}>
                                <SCAForm /*callback={(is_loading) => this.updateLoadingState(is_loading) } */
                                         client_secret={client_secret} 
                                         last_intent_infos={last_intent_infos}/>
                            </Elements>
                        </StripeProvider>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SideLeftUncoverPayment>
    );
  }
}
function mapStateToProps(state) {
    const { alert,dispatch,payment} = state;
    return {
        payment,
        alert,
        dispatch
    };
}

const connectedSCACheck = injectIntl(connect(mapStateToProps)(SCACheck));
export { connectedSCACheck as SCACheck };
