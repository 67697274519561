import React, { Component } from 'react';
import { ingredientsActions,paymentActions, recipesActions,supplierActions,storageAreaActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid, Header, Image,Icon,Modal,Form,Breadcrumb,Checkbox,Segment,Dropdown} from 'semantic-ui-react';
import {history,toaster_info} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
//import img_inventory from '../Assets/stock-boxes.jpg'
import img_antique from '../Assets/checklist.png'
import img_performance from '../Assets/performance.png'
import SideLeftUncoverInventory from './SidebarInventory';


/* 
const optionsExport = [
    { key: 1, 
        text: <FormattedMessage id="inventoryPDF.data.choice.elem1"
                               defaultMessage='PDF File'/>,
       icon: 'file pdf',
       value: 'pdf' },
     { key: 2, 
      text: <FormattedMessage id="exportlist.recipes.format.choice.elem2"
                              defaultMessage='Comma Separated Value (CSV)'/>,
      value: 'csv' },
] */

const optionsTypeStock = [
  { key: 1, text: <FormattedMessage id="downloadrecipe.options.ingredients"
                                              defaultMessage='Ingredients'/>, value: 'ingredient'},
  { key: 2, text: <FormattedMessage id="downloadrecipe.options.recipes"
                                              defaultMessage='Recipes'/>, value: 'recipe'}]


class InventoryPDF extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            //format: 'pdf',
            stockType: 'ingredient',
            activeModalPremium: false,
            filterSupplier: false,
            filterStorageArea: false,
            selectSupplier: null,
            selectStorageArea: null,
         }
        this.selectSupplier=this.selectSupplier.bind(this);
        this.selectStorageArea=this.selectStorageArea.bind(this);

        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(supplierActions.getAll());
        props.dispatch(storageAreaActions.getAll());

    }

  hideModal() {
    this.setState({ activeModalPremium: false });
  }

  onStockTypeChange = (e,data) => {
    e.preventDefault();
    //this.setState({ stockType: data.value });
    const {customer} = this.props;
    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
    }

    if (membership && user &&
        ((membership.membership_type=="Entreprise") || (user.bypass==true)))
    {
        this.setState({stockType:data.value});
    }else{
        this.setState({ activeModalPremium: true});
    }
  }

  exportInventory () {
    const { /* format */ stockType,supplierSelected,storageAreSelected } = this.state;
    const {customer,intl} = this.props;
    let user = null;
    let membership = null;

    if (customer){
        membership = customer.membership;
        user = customer.user;
    }

    if (membership && user &&
        ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
    {
        if (stockType == 'ingredient'){
            this.props.dispatch(ingredientsActions.inventory(new Date().toLocaleDateString(intl.locale).split('T')[0],
                                                             supplierSelected,
                                                             storageAreSelected ));  
        }else{
            this.props.dispatch(recipesActions.inventory(new Date().toLocaleDateString(intl.locale).split('T')[0],
                                                         null,
                                                         null ));  
        }
        toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                     intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))  
    }
    else {
        this.setState({ activeModalPremium: true});
    }  
  }
 
  onSubmit = (e) => {
        //e.preventDefault();
        this.exportInventory();
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  selectSupplier(event,data){
    event.preventDefault();
    this.setState({supplierSelected: data.value})
  }

  selectStorageArea(event,data){
    event.preventDefault();
    this.setState({ storageAreSelected: data.value})
  }


  onCheckboxChange = (e, data) => {
    //console.log(data);
    if (data.id === 'supplier')
        this.setState({ filterSupplier: data.checked});
    if (data.id === 'storagearea')
        this.setState({ filterStorageArea: data.checked});
  }

  render() {
    const { customer,exporting,supplier,storageAreas,loading_s,loading_storage } = this.props;
    const { stockType,filterSupplier,filterStorageArea,supplierSelected,storageAreaSelected } = this.state;

    return (
      <SideLeftUncoverInventory>
         <Grid stackable style={styles.FontLato12}>
              <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="inventoryPDF.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={{pathname:'/inventory',state:{startPage:1}}}><FormattedMessage id="inventoryPDF.link.stock"
                                                              defaultMessage='Inventory'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="inventoryPDF.link.inventory"
                                                              defaultMessage='Export'/></Breadcrumb.Section>  
{/*                                 <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="inventoryPDF.link.create"
                                                              defaultMessage='Create'/></Breadcrumb.Section> */}
                            </Breadcrumb>
                </Grid.Row>
                <Grid.Row>
               <Grid.Column width={8}>
                    <Header as='h2'>
                              <Icon name='download' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="inventoryPDF.header.title"
                                                    defaultMessage='Inventory'/>
                                   <Header.Subheader><FormattedMessage id="inventoryPDF.subheader.title"
                                                                       defaultMessage='Export your stock status'/></Header.Subheader>
                                </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue' >
                    {selected && selected.name}
                    </Header> */}
               </Grid.Column>
           </Grid.Row>
           <Grid.Row/>
           <Grid.Row columns={2}>
              <Grid.Column /*width={5}*/ mobile={10} tablet={7} computer={6}>
                     <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label><FormattedMessage id="inventoryPDF.form.type.select.title"
                                                         defaultMessage='Type of inventory'/>
                                </label>
                                <FormattedMessage id="inventoryPDF.form.type.select.placeholder"
                                                defaultMessage='Type'>
                                    {placeholder =>   
                                            <Form.Select 
                                                    options={optionsTypeStock}
                                                    placeholder={placeholder} 
                                                    value={stockType}
                                                    onChange={this.onStockTypeChange}
                                                    style={{'font-size': '16px','font-weight': '500','color': 'black'}}
                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                            />}
                                </FormattedMessage>  
                            </Form.Field>
                        </Form.Group>
                        {/* <br/> */}
                        {stockType == 'ingredient' && <Segment secondary color='teal' raised>
                          <p style={styles.FontLato13}>
                           <Icon color='teal' name='info circle' size='large'/>
                                         <FormattedMessage id="inventoryPDF.categories.message.title"
                                                                defaultMessage='Select your file export '/>{' '}
                                        {stockType === 'ingredient' &&                       
                                        <Link to={'/categoriesingredient/'}>
                                                <FormattedMessage id="inventoryPDF.categories.message.recipes.link"
                                                                  defaultMessage='active categories'/>
                                        </Link>}
                                        {stockType === 'recipe' &&                       
                                        <Link to={'/categoriesrecipe/'}>
                                                <FormattedMessage id="inventoryPDF.categories.message.ingredients.link"
                                                                  defaultMessage='active categories'/>
                                        </Link>}
                                        {' '}<FormattedMessage id="inventoryPDF.categories.message.details"
                                                                defaultMessage=' if necessary.' />
                        </p>
                        <Form.Field>
                                <FormattedMessage id="inventoryPDF.filter.supplier"
                                                 defaultMessage='Include only one supplier'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='supplier'
                                                defaultChecked = {this.state.filterSupplier}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>
                        {filterSupplier && !loading_s &&
                            <Form.Field  width={10}>
                                <FormattedMessage id="inventoryPDF.form.supplier.select.placeholder"
                                                defaultMessage='Supplier'>
                                    {placeholder =>   
                                            <Dropdown selection search 
                                                    options= {supplier && supplier.options}  
                                                    value= {supplierSelected && supplierSelected.id}
                                                    placeholder={placeholder}
                                                    onChange={this.selectSupplier}
                                                    clearable
                                                    disabled = {loading_s}
                                                    style={{'marginLeft': '2em','font-size': '13px','font-weight': '400','color': 'grey'}}
                                            />}
                                </FormattedMessage>  
                            </Form.Field>}
                        <Form.Field>
                                <FormattedMessage id="inventoryPDF.filter.storage"
                                                 defaultMessage='Include only one storage area'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='storagearea'
                                                defaultChecked = {this.state.filterStorageArea}
                                                onChange={this.onCheckboxChange}
                                                />}
                                </FormattedMessage>
                        </Form.Field>
                        {filterStorageArea && !loading_storage &&
                            <Form.Field  width={10}>
                                <FormattedMessage id="inventoryPDF.form.storagearea.select.placeholder"
                                                defaultMessage='Storage Area'>
                                    {placeholder =>   
                                            <Dropdown selection search 
                                                    options= {storageAreas && storageAreas.options}  
                                                    value= {storageAreaSelected && storageAreaSelected.id}
                                                    placeholder={placeholder}
                                                    onChange={this.selectStorageArea}
                                                    clearable
                                                    disabled = {loading_storage}
                                                    style={{'marginLeft': '2em','font-size': '13px','font-weight': '400','color': 'grey'}}
                                            />}
                                </FormattedMessage>  
                            </Form.Field>}
                        </Segment>}
                        <br/><br/>
                        <Form.Group widths='equal'>
                           {exporting &&
                            <Form.Button loading style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="inventoryPDF.form.submit.ok"
                                                        defaultMessage="Download"/>}
                            </Form.Button>}
                            {!exporting &&
                            <Form.Button style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="inventoryPDF.form.submit.ok"
                                                        defaultMessage="Download"/>}
                            </Form.Button>}
                            <Form.Button style={styles.ButtonGrey} //basic color='grey'
                                    fluid  onClick={this.cancelAction}>
                                    {<FormattedMessage id="inventoryPDF.form.submit.cancel"
                                                       defaultMessage="Cancel"/>}
                            </Form.Button>
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="inventoryPDF.premium.header.title"
                                                    defaultMessage='Recipes Inventory'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' /* style={styles.FontLato12Bold} */ color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="inventoryPDF.premium.message.upgradeplan"
                                                                defaultMessage='PREMIUM Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="inventoryPDF.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{customer && customer.membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="inventoryPDF.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="inventoryPDF.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="inventoryPDF.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Form.Group>
                    </Form>
             </Grid.Column>
             <Grid.Column width={5}>
                 <img alt='antique' id='test' src={img_antique}/>
             </Grid.Column>
          </Grid.Row>
        </Grid>
      </SideLeftUncoverInventory>
    );
  }
}

function mapStateToProps(state) {
  const { dispatch,alert,supplier,storageAreas } = state;
  const { customer } = state.payment;
  const {exporting} = state.ingredients;
  const { loading_storage} = state.storageAreas;
  const { loading_s} = state.supplier;
  return {
        exporting,
        customer,
        dispatch,
        alert,
        supplier,
        storageAreas,
        loading_storage,
        loading_s
  };
}

const connectedInventoryPDF = injectIntl(connect(mapStateToProps)(InventoryPDF));
export { connectedInventoryPDF as InventoryPDF };
