import React, { Component } from 'react';
import { recipesActions,alertActions,ingredientsActions,categoryRecipeActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form,Button,Header,Grid,Search,Message,Popup,Icon,Loader,Dimmer,Breadcrumb} from 'semantic-ui-react'
import SideLeftUncoverRecipe from './SidebarRecipe';
import {NewIngredientNewRecipe} from './NewIngredientNewRecipe';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { history,toaster_info,toaster_dispatch,Capitalize,isNumeric,trim,unitVolume,unitWeight,typeYield } from '../_helpers';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
  WEIGHT_INSTRUCTION_LINE_KG,
  WEIGHT_INSTRUCTION_LINE_G,
  WEIGHT_INSTRUCTION_LINE_MG,
  WEIGHT_INSTRUCTION_LINE_OZ,
  WEIGHT_INSTRUCTION_LINE_LB,
  WEIGHT_INSTRUCTION_LINE_EX,
  VOLUME_INSTRUCTION_LINE_TITLE,
  VOLUME_INSTRUCTION_LINE_L,
  VOLUME_INSTRUCTION_LINE_ML,
  VOLUME_INSTRUCTION_LINE_CL,
  VOLUME_INSTRUCTION_LINE_FL,
  VOLUME_INSTRUCTION_LINE_GAL,
  VOLUME_INSTRUCTION_LINE_TSP,
  VOLUME_INSTRUCTION_LINE_TBSP,
  VOLUME_INSTRUCTION_LINE_EX_RECIPE,
  QUANTITY_INSTRUCTION_LINE_TITLE,
  QUANTITY_INSTRUCTION_LINE_NUMBER,
  QUANTITY_INSTRUCTION_LINE_EX,
  RECIPE_QUANTITY_INSTRUCTION_LINE_TITLE,
  RECIPE_QUANTITY_INSTRUCTION_LINE_NUMBER,
  RECIPE_QUANTITY_INSTRUCTION_LINE_EX
} from '../_helpers';
import {FormattedMessage,FormattedNumber, injectIntl} from 'react-intl';
import { alertConstants } from '../_constants';
import {styles} from './Styles';
import img_bag from '../Assets/shopping-bag.png'
import img_pan from '../Assets/pan.png'
import './Search.css';

const INGREDIENT_I18N =<FormattedMessage id="copyrecipe.search.ingredient.tag"
                                    defaultMessage="'Ingredient"/>;
const RECIPE_I18N =<FormattedMessage id="copyrecipe.search.recipe.tag"
                                defaultMessage="Recipe"/>;


const INIT_COPY_MESSAGE_TITLE = <FormattedMessage id="copyrecipe.toaster.message.title"
                                                  defaultMessage='Copy ready'/>
         
const INIT_COPY_MESSAGE_CONTENT = <FormattedMessage id="copyrecipe.toaster.message.content"
                                                  defaultMessage='Just need to edit and save it !!!!'/>

class CopyRecipe extends Component {
  constructor(props) {
    super(props);
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state = {
      prevRecipeSelected : null,
      title: "",
      name: "",
      yield_units: "",
      yield_count: "",
      ingredQuantities:[],
      recipeQuantities:[],
      category: null,
      ref_internal: "",
      addFormShown:false,
      addedIngredient:"",
      sourceIngredients:[],
      sourceRecipes:[],
      currency : userIn.user.currency,
      //isSubRecipe: false,
    }

     props.dispatch(recipesActions.get(props.match.params.recipeId));
     setTimeout(() => {
      props.dispatch(categoryRecipeActions.getAll());
      props.dispatch(ingredientsActions.getAll(true));
      props.dispatch(recipesActions.getAll(true));
      },200);
      this.resetComponent();
  }


  static getDerivedStateFromProps(props, state) {
    if(props.recipes && (props.recipes.selected!==state.prevRecipeSelected)){
        let result = {};
/*         if (nextProps.recipes.searchList){
          Object.assign(result,{ sourceRecipes: nextProps.recipes.searchList });
        }
        if ((nextProps.ingredients!==prevState.ingredients)&&(nextProps.ingredients.searchList)){
          Object.assign(result,{ sourceIngredients: nextProps.ingredients.searchList });
        } */

        if (props.recipes.selected){
          const selected = props.recipes.selected;
          let ingredQuantities = [];
          let recipeQuantities = [];
  
          for (let i of selected.ingredients){
            ingredQuantities.push(i);
          }
          for (let r of selected.subrecipes){
            recipeQuantities.push(r);
          }

          let yield_optimized = Number(selected.yield_count)
          yield_optimized = Number.isInteger(yield_optimized) ? yield_optimized.toFixed(0) : yield_optimized.toFixed(2)

          Object.assign(result,{
            prevRecipeSelected : selected,
            selected: selected,
            title: selected.name, 
            name: selected.name,
            yield_count: yield_optimized,
            yield_units: selected.yield_units,
            ingredQuantities: ingredQuantities,
            recipeQuantities: recipeQuantities,
            isSubRecipe: selected.is_subrecipe,
            ref_internal:selected.ref_internal
            //instructions:selected.instructions            
          });

          if (selected.category){
            Object.assign(result,{
              category: {id: selected.category.id,
                         name: selected.category.name}
            });
          }
        }
        return result;
    }
    return null;
  }

  computeSearchList(){
    const {sourceIngredients,sourceRecipes, INGREDIENT,RECIPE,currency } = this.state;
    const {intl} = this.props;
    const {ingredients,recipes} = this.props;
    const INGREDIENT_TAG = intl.formatMessage({ id: 'copyrecipe.search.ingredient.tag' });
    const RECIPE_TAG = intl.formatMessage({ id: 'copyrecipe.search.recipe.tag' });

    if (!INGREDIENT || !RECIPE){
      this.setState({ INGREDIENT:  INGREDIENT_TAG});
      this.setState({ RECIPE:  RECIPE_TAG});
    }

    if (ingredients && ingredients.items && ingredients.items.length>0 && sourceIngredients.length == 0){
      let srcIngredients = [];
      ingredients.items.map((item,index)=>{
          let supplier_name = ""
          if (item.supplier__name) {
            supplier_name = item.supplier__name
          }
          srcIngredients.push({
            id: item.id,
            name: INGREDIENT_TAG,
            title: item.name,
            price: <p  style={{'marginLeft':'0em', 'color':'#009b9b'}}>
                      <FormattedNumber value={item.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}
                      {item.purchase_quantity !== "" &&
                                        <FormattedMessage id="copyrecipe.search.quantity.unit.text"
                                                          defaultMessage='unit'/>}
                      {((item.purchase_volume !== "" || item.purchase_weight !== "")) && 
                        <FormattedMessage id="copyrecipe.search.quantity.unit.other.text"
                                                          defaultMessage='{unit}'
                                                          values={{unit:item.unit}}/>}
                        <span style={{'marginLeft':'2em','marginRight':'2em','color':'#969696'}}>{/* {'  -  '} */}<FormattedNumber value={item.stock_quantity} maximumFractionDigits="3"/>{' '}{item.unit}{' '} 
                                        <FormattedMessage id="copyrecipe.search.stock.unit.stock.text"
                                                          defaultMessage='in stock'/></span>
                        <img src={img_bag} style={{width:'32px'}}/> 
                   </p> ,
            description: supplier_name,
            //image: <Button icon='shopping basket' circular size='large' color='teal'/>
          });
      });

      this.setState({ sourceIngredients:  srcIngredients});
    }

    if (recipes && recipes.items && recipes.items.length>0 && sourceRecipes.length == 0){
      let srcRecipes = [];
      recipes.items.map((item,index)=>{
        srcRecipes.push({
            id: item.id,
            name: RECIPE_TAG,
            title: item.name,
            price: <p style={{'marginLeft':'0em', 'color':'#1353ba'}}>
                    <FormattedNumber value={item.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' '}/{' '}{item.yield_units}
                    <span style={{'marginLeft':'2em'}}><img src={img_pan} style={{width:'32px'}}/></span>
                  </p> ,
            //description: RECIPE_TAG,
            //image: <Button icon='book' circular size='large' color='blue'/>
          });
      });

      this.setState({ sourceRecipes:  srcRecipes});
    }
  }


  componentDidMount(){
    const {intl} = this.props;
    toaster_info(intl.formatMessage({ id: 'copyrecipe.toaster.message.title' }),
                 intl.formatMessage({ id: 'copyrecipe.toaster.message.content' }))
  }

  onNameChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  }

  onYieldUnitsChange = (e) => {
    e.preventDefault();
    this.setState({ yield_units: e.target.value });
  }

  onYieldCountChange = (e) => {
    e.preventDefault();
    this.setState({ yield_count: e.target.value });
  }

  onCategoryChange = (e,data) => {
    e.preventDefault();
    const {categoriesRecipe} = this.props;
    if (data.value==""){
      this.setState({ category: null });
    }else{
      let category_id = data.value;
      let elem = categoriesRecipe.options.find(category => category.value == category_id)
      this.setState({ category: {id:category_id,
                                 name:elem.text} });
    }
  }

  onRefInternalChange = (e) => {
    e.preventDefault();
    this.setState({ ref_internal: e.target.value });
  }

/*   onCheckSubRecipe = (e,data) => {
    e.preventDefault();
    this.setState({ isSubRecipe: data.checked });
  } */

  onQuantityChange (e,index,type) {
    e.preventDefault();
    const { INGREDIENT,RECIPE } = this.state;
    if (type == INGREDIENT){
        const updates = this.state.ingredQuantities.map((ingredient, idx) => {
            if (index === idx) {
                ingredient.quantity = e.target.value;
                return ingredient;
            }else {
                return ingredient;
            }
          });
          this.setState({ ingredQuantities: updates });
    }
    if (type == RECIPE){
        const updates = this.state.recipeQuantities.map((recipe, idx) => {
            if (index === idx) {
                recipe.quantity = e.target.value;
                return recipe;
            }else {
                return recipe;
            }
          });
          this.setState({ recipeQuantities: updates });
    }
  }

  onQuantityDelete(e,index,type) {
    e.preventDefault();
    const { INGREDIENT,RECIPE } = this.state;
    if (type == INGREDIENT){
      var updates = [...this.state.ingredQuantities];
      updates.splice(index, 1);
      this.setState({ ingredQuantities: updates });
    }
    if (type == RECIPE){
      var updates = [...this.state.recipeQuantities];
      updates.splice(index, 1);
      this.setState({ recipeQuantities: updates });
    }
  }

  onFocusElsewhere(e) {
    e.preventDefault();
    this.setState({ highlighted: null });
  }

  onFocusSearch(e) {
    e.preventDefault();
    this.handleSearchChange(e, {value:' '})
    this.setState({ highlighted: null });
  }

  onFocusExtraField(e,index,type) {
    e.preventDefault();
    this.setState({ highlighted: {index,type} });
  }

  addExtraFields(fields,type){ 
    const { highlighted,submitted,INGREDIENT,RECIPE} = this.state;

    let sorted_items  = []

    if (type == INGREDIENT){
      sorted_items  = fields.sort(function (a, b) {
        return a.ingredient.name.toLowerCase() > b.ingredient.name.toLowerCase();
      })
    }
    else if (type == RECIPE){
      sorted_items  = fields.sort(function (a, b) {
        return a.subrecipe.name.toLowerCase() > b.subrecipe.name.toLowerCase();
      })
    }

    return sorted_items.map((field,index) => {
      return (
                <div style={{margin:'0em 4em 0em 3em'}}>
                  <Form.Field>
                    {type == INGREDIENT && field.ingredient &&
                    <label style={{color:'#498caa' , fontSize: '14px'}}>{field.ingredient.name}</label>}
                    {type == RECIPE && field.subrecipe &&
                    <label style={{color:'#498caa',  fontSize: '14px'}}>{field.subrecipe.name}</label>}
                    <FormattedMessage id="copyrecipe.extra.input.quantity.placeholder"
                                       defaultMessage='Fill net quantity following instructions (mandatory)'>
                         {placeholder => 
                            <Form.Input key={index}
                                  type="text"
                                  placeholder={placeholder} 
                                  value={field.quantity}
                                  //autoFocus
                                  onChange={(e)=>this.onQuantityChange(e,index,type)}
                                  action={{ color: 'white',icon: 'trash alternate outline', 
                                  onClick: (e)=>this.onQuantityDelete(e,index,type)}}
                                  onFocus={(e)=>this.onFocusExtraField(e,index,type)}
                                  error={submitted && !this.assertQuantityFieldsValidityPerType(index,type)}/>}
                    </FormattedMessage>
                    {submitted && !this.assertQuantityFieldsValidityPerType(index,type)  && 
                            <label style={{color:'red'}}><i>
                                 <FormattedMessage id="copyrecipe.extra.input.quantity.message.error"
                                                   defaultMessage='quantity format invalid (follow instructions)'/>
                              </i></label>
                    }
                  </Form.Field>
                  { highlighted 
                    && highlighted.index == index
                    && highlighted.type == INGREDIENT  
                    && field.quantity_type == 'weight' 
                    && field.ingredient
/*                     &&  <Message>  
                        <p>{Capitalize(field.ingredient.name)}</p>
                        <p>
                            <FormattedMessage id="copyrecipe.extra.input.quantity.ingredient.weight.instructions"
                                              defaultMessage='Quantity with weight: kg, kilogram, kilograms, g, gram, grams or mg (ie 3kg)'/>
                         </p></Message> */
                  &&  <Message size='tiny' 
                         icon='balance'>       
                        <Icon name='balance' color='teal'/> 
                        <Message.Content>
                         {/*  <h4>{field.ingredient.name}</h4> */}
                          <p>{field.ingredient.name} - {WEIGHT_INSTRUCTION_LINE_TITLE}</p>
                          <ul>
                            <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                            <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                            <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                            <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                            <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                          </ul> 
                          {/* {WEIGHT_INSTRUCTION_LINE_EX} */}
                        </Message.Content>                                     
                    </Message>
                  }
                 {  highlighted 
                    && highlighted.index == index
                    && highlighted.type == INGREDIENT
                    && field.quantity_type == 'volume'   
                    && field.ingredient
/*                     && <Message >  
                         <p>{Capitalize(field.ingredient.name)}</p>
                         <p>
                           <FormattedMessage id="copyrecipe.extra.input.quantity.ingredient.volume.instructions"
                                              defaultMessage='Quantity volume:  l, liter, liters, ml, milliliter, milliliters (ex: 2l)'/>
                         </p></Message> */
                    &&  <Message size='tiny' 
                              icon='flask' >       
                        <Icon name='flask' color='teal'/> 
                        <Message.Content>
                         {/*  <h4>{field.ingredient.name}</h4> */}
                          <p>{field.ingredient.name} - {VOLUME_INSTRUCTION_LINE_TITLE}</p>
                          <ul>
                            <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_TSP}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_TBSP}</li>
                          </ul> 
                          {VOLUME_INSTRUCTION_LINE_EX_RECIPE}
                        </Message.Content>                                     
                      </Message>
                    }
                 { highlighted 
                   && highlighted.index == index
                   && highlighted.type == INGREDIENT
                   && field.quantity_type == 'quantity' 
                   && field.ingredient
/*                    && <Message > 
                     <p>{Capitalize(field.ingredient.name)}</p>
                     <p>
                           <FormattedMessage id="copyrecipe.extra.input.quantity.ingredient.quantity.instructions"
                                              defaultMessage='Quantity : number of units (integer) '/>
                    </p></Message> */
                    &&  <Message size='tiny' 
                                  icon='copy'>       
                        <Icon name='copy' color='teal'/> 
                        <Message.Content>
                          {/* <h4>{field.ingredient.name}</h4> */}
                          <p>{field.ingredient.name} - {QUANTITY_INSTRUCTION_LINE_TITLE}</p>
                          <ul>
                            <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                          </ul> 
                          {QUANTITY_INSTRUCTION_LINE_EX}
                        </Message.Content>                                     
                    </Message>
                  }
                  { highlighted 
                    && highlighted.index == index
                    && highlighted.type == RECIPE  
                    && field.quantity_type == 'weight' 
                    && field.subrecipe
/*                     &&  <Message > 
                        <p>{Capitalize(field.subrecipe.name)}</p>
                        <p>
                          <FormattedMessage id="copyrecipe.extra.input.quantity.recipe.weight.instructions"
                                            defaultMessage='Quantity with weight: kg, kilogram, kilograms, g, gram, grams or mg (ie 3kg)'/>
                        </p></Message> */
                      &&  <Message size='tiny' 
                                icon='balance'>       
                          <Icon name='balance' color='teal'/> 
                          <Message.Content>
                           {/*  <h4>{field.subrecipe.name}</h4> */}
                            <p>{field.subrecipe.name} - {WEIGHT_INSTRUCTION_LINE_TITLE}</p>
                            <ul>
                              <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                              <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                              <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                              <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                              <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                            </ul> 
                            {WEIGHT_INSTRUCTION_LINE_EX}
                          </Message.Content>                                     
                      </Message>
                  }
                 {  highlighted 
                    && highlighted.index == index
                    && highlighted.type == RECIPE
                    && field.quantity_type == 'volume'   
                    && field.subrecipe
/*                     && <Message > 
                         <p>{Capitalize(field.subrecipe.name)}</p>
                         <p> 
                            <FormattedMessage id="copyrecipe.extra.input.quantity.recipe.volume.instructions"
                                              defaultMessage='Quantity volume:  l, liter, liters, ml, milliliter, milliliters (ex: 2l)'/>
                          </p></Message> */
                    &&  <Message size='tiny' 
                                icon='flask' >       
                      <Icon name='flask' color='teal'/> 
                      <Message.Content>
                        {/* <h4>{field.subrecipe.name}</h4> */}
                        <p>{field.subrecipe.name} - {VOLUME_INSTRUCTION_LINE_TITLE}</p>
                        <ul>
                          <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                          <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                          <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                          <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                          <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                        </ul> 
                        {VOLUME_INSTRUCTION_LINE_EX_RECIPE}
                      </Message.Content>                                     
                    </Message>
                    }
                 { highlighted 
                   && highlighted.index == index
                   && highlighted.type == RECIPE
                   && field.quantity_type == 'quantity' 
                   && field.subrecipe
/*                    && <Message > 
                    <p>{Capitalize(field.subrecipe.name)}</p>
                    <p> 
                        <FormattedMessage id="copyrecipe.extra.input.quantity.recipe.quantity.instructions"
                                              defaultMessage='Quantity : number of units (numeric)'/>
                    </p></Message> */
                    &&  <Message size='tiny' 
                              icon='copy'>       
                          <Icon name='copy' color='teal'/> 
                          <Message.Content>
                          {/* <h4>{field.subrecipe.name}</h4> */}
                            <p>{field.subrecipe.name} - {RECIPE_QUANTITY_INSTRUCTION_LINE_TITLE}</p>
                            <ul>
                              <li>{RECIPE_QUANTITY_INSTRUCTION_LINE_NUMBER} {field.subrecipe.yield_units}</li>
                              <li>{RECIPE_QUANTITY_INSTRUCTION_LINE_EX} <FormattedNumber value={field.subrecipe.yield_count} /> {field.subrecipe.yield_units}</li>
                            </ul> 
                          </Message.Content>                                     
                      </Message>
                  }
                {/* </Form.Group> */}
                </div>
         );
      });
  }

  isAllExtraFields(){
    for (let elem of this.state.ingredQuantities){
      if ((elem.quantity == 0)||(elem.quantity == "")){
          return false;
      }
    }
    for (let elem of this.state.recipeQuantities){
      if ((elem.quantity == 0)||(elem.quantity == "")){
          return false;
      }
    }
    return true;
  }

  assertYieldCountField(){
    const {yield_count} = this.state;
    let yield_value = yield_count.toLowerCase().replace(/,/g, '.')
    if (isNumeric(yield_value)
          && (parseFloat(yield_value) > 0)
          && (yield_value !=="")
          && (yield_value.length<12))
      return true;
    return false;
  }

  assertNameField(){
    if ((this.state.name != "")
          &&(trim(this.state.name).length>0)
          &&(trim(this.state.name).length<100))
      return true;
    return false;
  }

  assertYieldField(){
    if ((this.state.yield_units != "")
        && (trim(this.state.yield_units).length>0)
        && !isNumeric(this.state.yield_units) )
      return true;
    return false;
  }

  assertQuantityPermittedIncluded(elem){
    let isFound = false;
    let unitPart = "";
    let inputs= elem.quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);
    //console.log(inputs);
    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (elem.quantity_type == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
        if (inputs <=0){
              return false;
        }
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          //console.log(unitPart);
          if (isNumeric(i) && i<=0 ) {
            return false;
          }
        }
      }

    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
        if (isNumeric(i) && i<=0 ) {
          return false;
        }   
      }
    }

    if (elem.quantity_type == 'volume'){
      for (let unit of unitVolume ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (elem.quantity_type == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          //console.log('isFound');
          break;
        }
     }
    }else {
        if (inputs.length > 1)
          return false
        isFound = true;
    }
    return isFound;
  }

  assertQuantityFieldsValidityPerType(index,type){
    const { INGREDIENT,RECIPE } = this.state;
    if (type == INGREDIENT){
      for (let [idx,elem] of this.state.ingredQuantities.entries()){
        if (index == idx) {
          elem.quantity = trim(elem.quantity);
          elem.quantity = elem.quantity.toLowerCase();
          if (!this.assertQuantityPermittedIncluded(elem)) return false;
        }
      } 
    }

    if (type == RECIPE){
      for (let [idx,elem] of this.state.recipeQuantities.entries()){
        if (index == idx) {
          elem.quantity = trim(elem.quantity);
          elem.quantity = elem.quantity.toLowerCase();
          if (!this.assertQuantityPermittedIncluded(elem)) return false;
        }
      } 
    }
    return true;
  }

  assertQuantityFieldsValidity(){
    for (let elem of this.state.ingredQuantities){
      elem.quantity = trim(elem.quantity);
      elem.quantity = elem.quantity.toLowerCase();
        if (!this.assertQuantityPermittedIncluded(elem)) return false;
    }  
      
    for (let elem of this.state.recipeQuantities){
      elem.quantity = trim(elem.quantity);
      elem.quantity = elem.quantity.toLowerCase();
        if (!this.assertQuantityPermittedIncluded(elem)) return false;
    }
    return true;
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }

  cleanI18nDecimals(){
    const {ingredQuantities,recipeQuantities} = this.state;

      for (let ingredient of ingredQuantities){
        ingredient.quantity= trim(ingredient.quantity).toLowerCase().replace(/,/g, '.');
      }

      for (let recipe of recipeQuantities){
        recipe.quantity= trim(recipe.quantity).toLowerCase().replace(/,/g, '.');
      }
  }
 
  onSubmit = (e) => {
    e.preventDefault();
    const {selected,name,yield_units,yield_count,
      ingredQuantities,recipeQuantities,category,ref_internal/*,isSubRecipe*/} = this.state;
    const { recipes, dispatch} = this.props;
    
    this.cleanI18nDecimals();

    dispatch(alertActions.clear());
    this.setState({ submitted: true });

    if (!this.assertNameField()){
      //dispatch(alertActions.error('name field should be filled'));
      //toaster_error('Submit Error','Name field should be filled');
      return;
    }

    if (!this.assertYieldField()){
      //dispatch(alertActions.error('yield count is numeric(3 digits max)'));
      //toaster_error('Submit Error','Yield Count field should be numeric and 3 digits max');
      return;
    }

    if (!this.assertYieldCountField()){
      //dispatch(alertActions.error('yield count is alphanumeric'));
      //toaster_error('Submit Error','Yield Count field should be numeric and 3 digits max');
      return;
    }

    if (!this.isAllExtraFields()){
      //dispatch(alertActions.error('quantity of all ingredients/recipes should be filled'));
      //toaster_error('Submit Error','Quantity of all ingredients/recipes should be filled');
      return;
    }

    if (!this.assertQuantityFieldsValidity()){
      //dispatch(alertActions.error('some quantity fields format are invalid (follow instructions)'));
      //toaster_error('Submit Error','Some quantity fields format are invalid  (follow instructions)');
      return;
    }

    if (name && yield_count && yield_units && recipes && selected) {
        const recipe = {
                 name:trim(name),
                 yield_count: trim(yield_count).toLowerCase().replace(/,/g, '.'),
                 yield_units:trim(yield_units),
                 ingredients:ingredQuantities,
                 subrecipes:recipeQuantities,
                 category:category,
                 instructions:selected.instructions,
                 ref_internal: ref_internal
                 //is_subrecipe: isSubRecipe
         }
         dispatch(recipesActions.add(recipe));
     }

  }

   resetComponent = () => {
     this.setState({ isLoading: false, results: [], value: '' })
   }

   getQuantityType = (component,type) => {
    const { INGREDIENT,RECIPE } = this.state;
    let typeQuantity = "quantity";

     if (type === INGREDIENT){
      if (component.purchase_weight !== "")
         typeQuantity = 'weight';
      else if (component.purchase_volume !== "")
         typeQuantity = 'volume';
      else if (component.purchase_quantity !== "")
         typeQuantity = 'quantity';
     }

     if (type === RECIPE){
      typeQuantity = typeYield(component.yield_units)
/*       if (component.yield_units != ""){
        let isFound = false;
         for (let unit of unitWeight ){
               if (component.yield_units.includes(unit)){
                typeQuantity = "weight";
                 isFound = true;
                 break;
               }
         }
         if (!isFound) {
           for (let unit of unitVolume ){
             if (component.yield_units.includes(unit)){
              typeQuantity = "volume";
               break;
             }
           }
         }
     } */
    }
    return typeQuantity;
  }

   handleResultSelect = (e, { result }) => {
     const {recipes,ingredients} = this.props;
     const { selected,INGREDIENT,RECIPE } = this.state;
     this.setState({ value: "" })
     if (result.name == INGREDIENT){
      for (let i of ingredients.items){
        if (i.id == result.id){
            this.setState({ ingredQuantities: 
              [...this.state.ingredQuantities, {ingredient:i,
                                                item_cost:1.0,
                                                quantity:"",
                                                quantity_type:this.getQuantityType(i,INGREDIENT)}]});
            break;
        }
       }
      }else if (result.name == RECIPE){
        for (let r of recipes.items){
          if (result.title == selected.name){
            return
          }
          if (r.id == result.id){
            this.setState({ recipeQuantities: 
              [...this.state.recipeQuantities, {subrecipe:r,  
                                                item_cost:1.0,
                                                quantity:"",
                                                quantity_type:this.getQuantityType(r,RECIPE)}]});
            break;
          }
        }
     }
   }

   popupElement(element,text,position){
    return(
       <Popup
       trigger={element}
       content= {text}
       position= {position}
       flowing
       on='hover'
       />
     )
   }
 
   handleSearchChange = (e, { value }) => {
    const { sourceIngredients, sourceRecipes} = this.state;
     this.setState({ isLoading: true, value })
     this.setState({results: []})
 
     setTimeout(() => {
       if (this.state.value.length < 1) return this.resetComponent()
 
       const re = new RegExp(escapeRegExp(this.state.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()), 'i')
       const isMatch = result => re.test(result.title.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase())
 
       this.setState({
         isLoading: false,
         results: filter([...sourceIngredients, ...sourceRecipes], isMatch),
       })
     }, 300)
   }
   
   updateAddFormState = (name) => {
    this.setState({ addFormShown: !this.state.addFormShown });
    this.setState({ addedIngredient: name });
  }

  addFormToggle = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    this.setState({ addFormShown: !this.state.addFormShown });
    dispatch(alertActions.clear());
  }

  computeNewIngredient = (name) => {
    const {ingredients} = this.props;
    const { INGREDIENT,currency } = this.state;
    if (name.length > 0){
      for (let i of ingredients.items){
        if (i.name == name){
            let supplier_name = ""
            if (i.supplier__name) {
              supplier_name = i.supplier__name
            }

            this.setState({ ingredQuantities: 
              [...this.state.ingredQuantities, {ingredient:i,
                                                item_cost:1.0,
                                                quantity:"",
                                                quantity_type:this.getQuantityType(i,INGREDIENT)}]});
            this.setState({ sourceIngredients: [...this.state.sourceIngredients,{
                 id: i.id,
                 name: INGREDIENT,
                 title: i.name,
                 price: <p style={{'marginLeft':'0em', 'color':'#009b9b'}}>
                 <FormattedNumber value={i.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}
                 {i.purchase_quantity !== "" &&
                                   <FormattedMessage id="copyrecipe.search.quantity.unit.text"
                                                     defaultMessage='unit'/>}
                 {((i.purchase_volume !== "" || i.purchase_weight !== "")) && 
                   <FormattedMessage id="copyrecipe.search.quantity.unit.other.text"
                                                     defaultMessage='{unit}'
                                                     values={{unit:i.unit}}/>}
                   <span style={{'marginLeft':'2em','marginRight':'2em','color':'#969696'}}>{/* {'  -  '} */}<FormattedNumber value={i.stock_quantity} maximumFractionDigits="3"/>{' '}{i.unit}{' '} 
                                   <FormattedMessage id="copyrecipe.search.stock.unit.stock.text"
                                                     defaultMessage='in stock'/></span>
                  <img src={img_bag} style={{width:'32px'}}/> 
                </p> ,
                 description: supplier_name}] 
                 //image: <Button icon="shopping basket" size='large' color='teal'/>}] 
                  });
            this.setState({ addedIngredient: "" });
            break;
        }
      }
    }
  }

  render() {
    const {ingredQuantities,recipeQuantities,addFormShown,addedIngredient,submitted/*,isSubRecipe*/ } = this.state;
    const { isLoading, value, results,INGREDIENT,RECIPE } = this.state;
    const { alert,dispatch,categoriesRecipe,intl,loading } = this.props;
    const addForm = addFormShown?<NewIngredientNewRecipe callback={(name) => this.updateAddFormState(name) }/>:null;
    this.computeNewIngredient(addedIngredient);
    this.computeSearchList();

    return (
    <div>
    { loading &&
        <SideLeftUncoverRecipe>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverRecipe>
    }
    {!loading &&  
    <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
      <Grid stackable style={styles.FontLato12}> 
        <Grid.Row columns={1}>
                  <Breadcrumb style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="copyrecipe.link.dashboard"
                                                                  defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="copyrecipe.link.recipes"
                                                                defaultMessage='Recipes'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{this.state.title}</Breadcrumb.Section>   
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="copyrecipe.link.copy"
                                                                  defaultMessage='Copy'/></Breadcrumb.Section>               
                  </Breadcrumb>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column /*width={6}*/  mobile={12} tablet={10} computer={7}>
              <Header as='h2'>
                              <Icon name='copy' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="copyrecipe.header.title"
                                                    defaultMessage='Copy recipe (new based on existing)'/>
                                   <Header.Subheader>{this.state.title}</Header.Subheader>
                                </Header.Content>
              </Header>
              {!addFormShown && submitted  && alert.message  &&  alert.type == alertConstants.ERROR &&
                  <Message  color='red' size='large' >
                       <FormattedMessage id="copyrecipe.alert.messge.error"
                                        defaultMessage='Problem(s) below needs review'/>
                  </Message>
               }
              <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                <Form.Field>
                  <label style={{color:'#60a0d1',fontSize:14}}>
                        <FormattedMessage id="copyrecipe.form.input.name"
                                          defaultMessage='Name * '/>
                  </label>
                  <FormattedMessage id="copyrecipe.form.input.name.placeholder"
                                      defaultMessage='Enter a name'>
                         {placeholder =>   
                                <Form.Input
                                      type="text"
                                      placeholder={placeholder}
                                      id="name"
                                      value={this.state.name}
                                      onChange={this.onNameChange}
                                      //tyle={{minWidth:'10em',maxWidth:'30em'}}
                                      onFocus={(e)=>this.onFocusElsewhere(e)}
                                      error={submitted && !this.assertNameField()}
                                      />}
                    </FormattedMessage>
                    {submitted && !this.assertNameField()  &&
                      <label style={{color:'red', }}><i>
                          <FormattedMessage id="copyrecipe.form.input.name.error.message"
                                                defaultMessage='name is empty'/>
                       </i></label> 
                    }
                </Form.Field>
                <Form.Group>
                  <Form.Field width={12}>
                    <label><FormattedMessage id="copyrecipe.form.category.name"
                                              defaultMessage='Category '/>
                    </label>
                    <FormattedMessage id="copyrecipe.form.category.placeholder"
                                      defaultMessage='Select a category'>
                              {placeholder =>   
                                        <Form.Select search
                                                  options={categoriesRecipe && categoriesRecipe.options} 
                                                  placeholder={placeholder} 
                                                  onChange={this.onCategoryChange}
                                                  value= {this.state.category && this.state.category.id}
                                                  clearable
                                                  //style={{minWidth:'10em',maxWidth:'30em'}} 
                                                  />}
                    </FormattedMessage>  
                  </Form.Field>
                  <Form.Field width={4}>
                      <label><FormattedMessage id="copyrecipe.form.code.title"
                                              defaultMessage='Internal code'/>
                      </label>
                      <FormattedMessage id="copyrecipe.form.code.placeholder"
                                        defaultMessage='ref'>
                          {placeholder =>     
                                <Form.Input
                                    type="text"
                                    placeholder={placeholder}
                                    id="code"
                                    value={this.state.ref_internal}
                                    onChange={this.onRefInternalChange}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                  />}
                        </FormattedMessage>
                   </Form.Field>   
                </Form.Group>
                <Form.Group>
                 <Form.Field width={8}>
                  <label style={{color:'#60a0d1',fontSize:14}}>
                      <FormattedMessage id="copyrecipe.form.input.yield"
                                             defaultMessage='Yield Value *'/>
                  </label>
                  <FormattedMessage id="copyrecipe.form.input.yield.placeholder"
                                    defaultMessage="Enter yield number : 1, 4, 8 ..">
                         {placeholder => 
                                  <Form.Input
                                    type="text"
                                    focus
                                    icon = { <Popup trigger={<Icon name='info' link />}
                                                    on='hover'
                                                    position = 'top right'
                                                    flowing>
                                                <Icon name='info'/>
                                                <span><FormattedMessage id="copyrecipe.form.input.yield.units.help.prefix"
                                                                  defaultMessage="Enter" />
                                                <span style={{'fontWeight':800,'font-style':'italic'}} > 8 </span>
                                                <FormattedMessage id="copyrecipe.form.input.yield.units.help.suffix"
                                                                  defaultMessage="if you wish to create 8 servings recipe" /></span>
                                            </Popup>}
                                    placeholder={placeholder}
                                    id="yield_count"
                                    value={this.state.yield_count}
                                    onChange={this.onYieldCountChange}
                                    //style={{minWidth:'10em',maxWidth:'30em'}}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                    error={submitted && !this.assertYieldCountField()}
                                    />}
                   </FormattedMessage> 
                     {submitted && !this.assertYieldCountField()  && 
                        <label style={{color:'red'}}><i>
                           <FormattedMessage id="copyrecipe.form.input.yield.error.message"
                                             defaultMessage='yield value is a number'/>
                        </i></label>
                     }
                </Form.Field>
                 <Form.Field width={8}>
                  <label style={{color:'#60a0d1',fontSize:14}}>
                      <FormattedMessage id="copyrecipe.form.input.yield.units"
                                             defaultMessage='Yield Units * (servings, kg, L ..)'/>
                  </label>
                  <FormattedMessage id="copyrecipe.form.input.yield.units.placeholder"
                                    defaultMessage="Enter yield units">
                         {placeholder => 
                                  <Form.Input
                                    focus
                                    type="text"
                                    icon = { <Popup trigger={<Icon name='info' link />}
                                            on='hover'
                                            position = 'top right'
                                            flowing>
                                      <Icon name='info'/> 
                                      <span><FormattedMessage id="copyrecipe.form.input.yield.units.help.prefix"
                                                            defaultMessage="Enter" />{' '}
                                        <span style={{'fontWeight':800,'font-style':'italic'}} > 
                                        <FormattedMessage id="copyrecipe.form.input.yield.units.help.bold"
                                                        defaultMessage="servings" />{' '} </span>
                                      <FormattedMessage id="copyrecipe.form.input.yield.units.help.suffix"
                                                        defaultMessage="if you wish to create 8 servings recipe" /></span>
                                      <br/>
                                      <Icon name='right arrow'/> 
                                      <span><FormattedMessage id="copyrecipe.form.input.yield.units.help.postfix"
                                                                      defaultMessage="You can write at your ease your unit : servings, parts ...." /></span>
                                      <br/>
                                      <Icon name='right arrow'/> 
                                      <span><FormattedMessage id="copyrecipe.form.input.yield.units.help.postfix2"
                                                                      defaultMessage="You can also choose standard units : Kg, L, oz, pound ..." /></span>
                                      </Popup>}
                                    id="yield_units"
                                    placeholder={placeholder}
                                    value={this.state.yield_units}
                                    onChange={this.onYieldUnitsChange}
                                    //style={{minWidth:'10em',maxWidth:'30em'}}
                                    onFocus={(e)=>this.onFocusElsewhere(e)}
                                    error={submitted && !this.assertYieldField()}
                                    />}
                    </FormattedMessage>  
                     {submitted && !this.assertYieldField()  && 
                        <label style={{color:'red'}}><i>
                           <FormattedMessage id="copyrecipe.form.input.yield.units.error.message"
                                             defaultMessage='yield units is alphanumeric'/>
                        </i></label>
                      }
                 </Form.Field>
                </Form.Group>
{/*                 <Form.Field>
                  <FormattedMessage id="copyrecipe.form.input.subrecipe"
                                          defaultMessage="used as Sub-Recipe only (no selling price)">
                    {text => 
                       <Checkbox label={text}
                                  onChange={this.onCheckSubRecipe}
                                  checked={isSubRecipe}  
                                 style={{'marginLeft': '1.5em'}}/>
                      }
                    </FormattedMessage>
                </Form.Field> */}
                <br/>
                <Form.Field>
                    <label style={styles.FontLato14BoldTeal}>
                          <FormattedMessage id="copyrecipe.form.search.title"
                                             defaultMessage='Search Ingredients / Sub-Recipes'/>
                    </label>
                    <FormattedMessage id="copyrecipe.form.input.search.placeholder"
                                    defaultMessage="Type first letters ...">
                        {placeholder => 
                                  <Search size='large'
                                      fluid
                                      loading={isLoading}
                                      icon = { <Popup trigger={<Icon name='search' link size='large' />}
                                                      on='hover'
                                                      position = 'top right'
                                                      flowing
                                                      >
                                                  <Icon name='info' size='large' /><FormattedMessage id="copyrecipe.form.search.popup.message.search"
                                                                    defaultMessage="Select an ingredient or subrecipe from list to add to your recipe" />
                                              </Popup>}
                                      onResultSelect={this.handleResultSelect}
                                      onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                                      results={results}
                                      value={value}
                                      placeholder={placeholder}
                                      selectFirstResult = {true}
                                      showNoResults ={true}
                                      noResultsMessage = {<FormattedMessage id="copyrecipe.form.search.nomessage"
                                                                            defaultMessage='No results'/>}
                                      //style={{minWidth:'10em',maxWidth:'30em'}}
                                      onFocus={(e)=>this.onFocusSearch(e)}
                                  />
                        }  
                    </FormattedMessage> 
                </Form.Field>
                {INGREDIENT && ingredQuantities && ingredQuantities.length>0 && this.addExtraFields(ingredQuantities,INGREDIENT)}
                {RECIPE && recipeQuantities && recipeQuantities.length>0 && this.addExtraFields(recipeQuantities,RECIPE)}
                <br/>
                  {!addFormShown  && alert.message && alert.type == alertConstants.SUCCESS &&
                         <Message fluid
                                  color='red'
                                  size='large'>
                                  {alert.message.content}</Message>
                    }
                    {alert.message  && toaster_dispatch(dispatch,alert,intl)}
                 <br/>
                 {addFormShown &&
                      <Button basic circular color='grey' onClick={this.addFormToggle}>
                           <FormattedMessage id="copyrecipe.form.button.cancel.ingredient"
                                             defaultMessage='Cancel New Ingredient'/>
                      </Button>}
                    {!addFormShown &&
                      this.popupElement(
                         <Button basic circular color='grey' onClick={this.addFormToggle}>
                             <FormattedMessage id="copyrecipe.form.button.add.ingredient"
                                                defaultMessage='Add New ingredient'/>
                         </Button>,
                         <span><Icon name='info'/><FormattedMessage id="copyrecipe.form.search.popup.message.button.ingredient"
                                                                   defaultMessage='You can create a new ingredient on the fly (optional)' /></span>,
                        'bottom left')
                    }
                  <br/>
                  {addForm}
                  <br/><br/>
                  {!addFormShown &&
                      <Form.Field>
                        <Button style={styles.ButtonGreen} 
                         //basic color='teal'
                          onClick={this.onSubmit}>
                              <FormattedMessage id="copyrecipe.form.button.submit.recipe"
                                                defaultMessage='Create Recipe'/>
                        </Button>
                        <Button style={styles.ButtonGrey}//basic 
                               onClick={this.cancelAction}>
                              <FormattedMessage id="copyrecipe.form.button.cancel.recipe"
                                                defaultMessage='Cancel'/>
                        </Button>
                      </Form.Field>
                }
              </Form>
              </Grid.Column>
{/*               <Grid.Column width={5}>
                  <br/><br/><br/><br/><br/>
                  <img src={img_soup_pot}/>
              </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </SideLeftUncoverRecipe>}
     </div>
    );
  }
}

function mapStateToProps(state) {
  const { recipes,ingredients,categoriesRecipe,alert,dispatch } = state;
  const {loading} = state.recipes;
  return {
      recipes,
      ingredients,
      loading,
      categoriesRecipe,
      dispatch,
      alert
  };
}

const connectedCopyRecipe = injectIntl(connect(mapStateToProps)(CopyRecipe));
export { connectedCopyRecipe as CopyRecipe };
