import React, { Component } from 'react';
import { Table, Menu, Header, Grid, Button,Pagination,Segment,Modal,Input,Dropdown,Dimmer,Loader,Icon,Message,Image,Breadcrumb} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ingredientsActions,categoryIngredientActions,supplierActions,alertActions,paymentActions, recipesActions, categoryRecipeActions,storageAreaActions} from '../_actions'
import './Ingredients.css';
import {history,toaster_dispatch,trim,isNumeric,isFloat,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,GUEST,typeYield, MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,grams,
        mgrams, cliters,mliters} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,injectIntl} from 'react-intl';
import {isEmpty} from "lodash"
import {styles} from './Styles';
import img_box from '../Assets/boxes.png'
import OutsideClickHandler from 'react-outside-click-handler';
import img_performance from '../Assets/performance.png'
import SideLeftUncoverInventory from './SidebarInventory';


const options = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

  const optionsTypeStock = [
    { key: 1, text: <FormattedMessage id="inventory.options.ingredients"
                                                defaultMessage='Ingredients'/>, value: 'ingredient'},
    { key: 2, text: <FormattedMessage id="inventory.options.recipes"
                                                defaultMessage='Recipes'/>, value: 'recipe'}]

  const filterOptions = [
    {
      key: 'Category',
      text: <FormattedMessage id="inventory.filter.type.category.text"
                              defaultMessage="Category"/>,
      value: 'Category',
        // label: { color: 'yellow', empty: true, circular: true },
        icon: {name:'sitemap',color:'yellow'}
    },
    {
        key: 'InternalRef',
        text: <FormattedMessage id="inventory.filter.type.internalref.text"
                                defaultMessage="Internal code"/>,
        value: 'InternalRef',
        //label: { color: 'blue', empty: true, circular: true },
        icon: {name:'tag',color:'blue'}
    },
    {
      key: 'Supplier',
      text: <FormattedMessage id="inventory.filter.type.supplier.text"
                              defaultMessage="Supplier"/>,
      value: 'Supplier',
      //label: { color: 'black', empty: true, circular: true },
      icon: {name:'shipping fast',color:'black'}
    },
    {
        key: 'SupplierRef',
        text: <FormattedMessage id="inventory.filter.type.supplierref.text"
                                defaultMessage="Supplier code"/>,
        value: 'SupplierRef',
        //label: { color: 'red', empty: true, circular: true },
        icon: {name:'tag',color:'red'}
    },
    {
        key: 'StorageArea',
        text: <FormattedMessage id="inventory.filter.type.storageArea.text"
                                defaultMessage="Storage area"/>,
        value: 'StorageArea',
        //label: { color: 'red', empty: true, circular: true },
        icon: {name:'warehouse',color:'grey'}
      }
]


const filterRecipeOptions = [
    {
      key: 'Category',
      text: <FormattedMessage id="inventory.filter.type.category.text"
                              defaultMessage="Category"/>,
      value: 'Category',
        // label: { color: 'yellow', empty: true, circular: true },
        icon: {name:'sitemap',color:'yellow'}
    },
    {
        key: 'InternalRef',
        text: <FormattedMessage id="inventory.filter.type.internalref.text"
                                defaultMessage="Internal code"/>,
        value: 'InternalRef',
        //label: { color: 'blue', empty: true, circular: true },
        icon: {name:'tag',color:'blue'}
    }
]


class Inventory extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location} = props;

        this.state = { prevIngredients : props.ingredients,
                       prevRecipes : props.recipes,
                       activeModal: null,
                       activeModalPremium: false,
                       errorNotification: false,
                       filtering: false,
                       currency : userIn.user.currency ,
                       isMobile: false,
                       page: 1,
                       itemsPerPage: 10,
                       newQuantity:0,
                       submitted: false,
                       editCell: false,
                       startPage: location.state.startPage,
                       stockValue: 0,
                       typeSelected:'Category',
                       stockType:'ingredient',
                       active_role: userIn.user.role
                    }
        this.hideModal = this.hideModal.bind(this);
        this.hideErrorNotification = this.hideErrorNotification.bind(this);
        this.showErrorNotification = this.showErrorNotification.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);
        this.filterSupplierRefList=this.filterSupplierRefList.bind(this);
        this.filterInternalRefList=this.filterInternalRefList.bind(this);
        this.filterTypeList=this.filterTypeList.bind(this);
        this.filterSupplierList=this.filterSupplierList.bind(this);
        this.filterStorageAreaList=this.filterStorageAreaList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        this.toggleStockTypeReset = this.toggleStockTypeReset.bind(this);
        props.dispatch(ingredientsActions.getAll(true));
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(categoryIngredientActions.getAll());
        props.dispatch(supplierActions.getAll());
        props.dispatch(recipesActions.getAll(true));
        props.dispatch(categoryRecipeActions.getAll());
        props.dispatch(storageAreaActions.getAll());
    }

    static getDerivedStateFromProps(props, state) {
         if((props.ingredients!==state.prevIngredients) && (props.ingredients.items)) {
            const ingredients = props.ingredients;
            let stockValue = 0; 
             ingredients.items.map((i,index) => {
                if (i && i.stock_price){
                    stockValue+=Number(i.stock_price);
                }
            });
            return {prevIngredients : props.ingredients,
                    stockValue:Math.round(stockValue * 100) / 100, 
                    ingredientsList:ingredients.items}
        }
        if((props.recipes !==state.prevRecipes) && (props.recipes.items)) {
            const recipes = props.recipes;
            let stockValue = 0; 
            recipes.items.map((i,index) => {
                if (i && i.stock_price){
                    stockValue+=Number(i.stock_price);
                }
            });
            return {prevRecipe : props.recipes,
                    stockRecipeValue:Math.round(stockValue * 100) / 100,
                    recipesList:recipes.items}
        }
        return null;
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        const { startPage} = this.state;
        if (startPage){
            this.setState({ page: startPage });
        }
        
    }

    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({supplierSelected: null});
        this.setState({nameSelected: ""});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterNameList(event){
        event.preventDefault();
        const {stockType,ingredientsList,recipesList} = this.state;

        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList =[]

        if (stockType == 'ingredient'){
            if (ingredientsList && ingredientsList.length >0){
            
/*             updatedList = this.state.ingredientsList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                          .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              }); */
              let words = (event.target.value).normalize('NFKD').toLowerCase().split(' ')
              words = words.filter(item => item);
              updatedList = this.state.ingredientsList.filter((item) => {
                  return words.some(word => (item.name).normalize('NFKD').toLowerCase().includes(word))
              });

            }
        }else{
            if (recipesList && recipesList.length >0){
/*             updatedList = recipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                          .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              }); */
              let words = (event.target.value).normalize('NFKD').toLowerCase().split(' ')
              words = words.filter(item => item);
              updatedList = recipesList.filter((item) => {
                 return words.some(word => (item.name).normalize('NFKD').toLowerCase().includes(word))
             });
            }
        }


        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: event.target.value});
        this.setState({ supplierSelected:null})
        this.setState({categorySelected: ""});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe,categoriesIngredient } = this.props;
        const {stockType,ingredientsList,recipesList} = this.state;
        let category_id = data.value;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let elem = []
        if (stockType == 'ingredient'){
            elem = categoriesIngredient.options.find(category => category.value == category_id)
        }else{
            elem = categoriesRecipe.options.find(category => category.value == category_id)
        }

       
        if (elem){
            let updatedList = []
            if (stockType == 'ingredient'){
                if (ingredientsList && ingredientsList.length >0){
                updatedList = ingredientsList.filter((item) => {
                    /*                 if (item.category__name){
                                     return item.category__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                                    } */
                                    if (item.category__id){
                                        return item.category__id == elem.value
                                    }
                                    return null;
                                  });
                }
            }else{
                if (recipesList && recipesList.length >0){
                updatedList = recipesList.filter((item) => {
                    /*                 if (item.category__name){
                                     return item.category__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                                    } */
                                    if (item.category__id){
                                        return item.category__id == elem.value
                                    }
                                    return null;
                                  });
                }
            }

              //console.log(updatedList)
              this.setState({newItems: updatedList});
              this.setState({filtering: true});
              this.setState({ categorySelected: {id:category_id,
                                                 name: elem.text } });
        }else{
            this.setState({ categorySelected:null})
            this.setState({filtering: false});
        }

        this.setState({ supplierSelected:null})
        this.setState({nameSelected: ""});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterSupplierList(event,data){
        event.preventDefault();
        const {supplier} = this.props;
        let supplier_id = data.value;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let elem = supplier.options.find(supplier => supplier.value == supplier_id) 

        if (elem){
            let updatedList = this.state.ingredientsList.filter((item) => {
/*                 if (item.supplier__name){
                 return item.supplier__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                } */
                if (item.supplier__id){
                    return item.supplier__id == elem.value;
                }
                return null;
              });
      
              this.setState({newItems: updatedList});
              this.setState({ supplierSelected: {id:supplier_id,
                                                 name: elem.text } });
              this.setState({filtering: true});
        }else{
            this.setState({ supplierSelected:null})
            this.setState({filtering: false});
        }
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }

    filterInternalRefList(event,data){
        event.preventDefault();
        const {stockType,ingredientsList,recipesList} = this.state;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = []
        if (stockType == 'ingredient'){
             updatedList = ingredientsList.filter((item) => {
                if (item.ref_internal){
                 return item.ref_internal.toLowerCase().search(data.value.toLowerCase()) !== -1;
                }
                return null;
              });
        }else{
            updatedList = recipesList.filter((item) => {
                if (item.ref_internal){
                 return item.ref_internal.toLowerCase().search(data.value.toLowerCase()) !== -1;
                }
                return null;
              });
        }

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: ""});
        this.setState({supplierSelected:null})
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: data.value});
        this.setState({storageAreaSelected: ""});
    }
    
    filterSupplierRefList(event,data){
        event.preventDefault();

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.state.ingredientsList.filter((item) => {
          if (item.ref_supplier){
           return item.ref_supplier.toLowerCase().search(data.value.toLowerCase()) !== -1;
          }
          return null;
        });

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: ""});
        this.setState({ supplierSelected:null})
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: data.value});
        this.setState({codeSelected: ""});
        this.setState({storageAreaSelected: ""});
    }


    filterStorageAreaList(event,data){
        event.preventDefault();
        const {storageAreas} = this.props;
        let storage_area_id = data.value;

        if (data.value === ""){
            this.removeFilters(event);
            return;
        }

        let elem = storageAreas.options.find(s => s.value === storage_area_id) 

        if (elem){
            console.log(elem)
            let updatedList = this.state.ingredientsList.filter((item) => {
/*                 if (item.supplier__name){
                 return item.supplier__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                } */
                if (item.stock_storage__id){
                    console.log(item.stock_storage__id)
                    return item.stock_storage__id === elem.value;
                }
                return null;
              });
      
              this.setState({newItems: updatedList});
              this.setState({ storageAreaSelected: {id:storage_area_id,
                                                    name: elem.text } });
              this.setState({filtering: true});
        }else{
            this.setState({ storageAreaSelected:null})
            this.setState({filtering: false});
        }
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({supplierCodeSelected: ""});
        this.setState({codeSelected: ""});
        this.setState({supplierSelected: ""});
        this.setState({categorySelected: null});
    }

    filterTypeList(event,data){
        event.preventDefault();
        this.setState({typeSelected: data.value});
        this.removeFilters(event);
    }


    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ activeModalPremium: false });
    }

    toggleStockTypeReset(event, data){
        const {customer} = this.props;
        let user = null;
        let membership = null;
    
        if (customer){
          membership = customer.membership;
          user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true)))
        {
            this.setState({stockType:data.value});
            this.setState({typeSelected: 'Category'});
            this.removeFilters(event);

        }else{
            this.setState({ activeModalPremium: true});
        }
        
    }

    hideErrorNotification() {
        const { dispatch } = this.props;
        this.setState({ errorNotification: false })
        this.setState({ activeModal: null })
        dispatch(alertActions.clear());
        window.location.reload();
    }

    showErrorNotification() {
        this.setState({ errorNotification: true })
    }

   seeIngredient (event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
        history.push('/ingredientdetails/'+index);
    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    seeSupplier (event,index) {
        event.preventDefault();
        history.push('/ingredientssuppliers/');
    }

    editStockQuantityHandlerInput (event,index,quantity) {
        event.preventDefault();
        this.setState({ editCell: true});
        this.setState({ editCellIndex: index});
        this.setState({ newQuantity: Math.round(quantity * 100) / 100});
    }; 

    onNewQuantity = (e) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity >=0)
          && Math.trunc(quantity).toString().length<10)
            return true
        return false;
    }

    onIncreaseDecreaseQuantity (event,ingredient,increase) {
        event.preventDefault();
        const {newQuantity,ingredientsList,active_role} = this.state;
        const {dispatch} = this.props;

        let quantity = Number(trim( newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        if (increase){
            quantity++;
        }else{
            if (quantity > 0) quantity--;
        } 
        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>

        if (this.assertQuantityField(quantity) /*&& quantity!=ingredient.stock_quantity*/){
            if (active_role!= GUEST){
                //dispatch(ingredientsActions.update_stock(ingredient.id,quantity,page));
                let stockValue = 0; 
                let listUpdated = ingredientsList.map((item,index) => {
                    if (item.id == ingredient.id){
                        item.stock_quantity = Number(quantity).toFixed(3);
                        item.stock_price = Number(quantity) * Number(item.price_per_unit)
                    }
                    if (item && item.stock_price){
                        stockValue+=Number(item.stock_price);
                    }
                    return item;
                });
                this.setState({ingredientsList:listUpdated})
                this.setState({ newQuantity:quantity});
                this.setState({stockValue:Math.round(stockValue * 100) / 100})
                this.setState({ editCell: false });
            }else{
                this.setState({ editCell: false });
                const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                    defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                             content:'inventory.stock_quantity.check.error.title'}));  
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                             content:'inventory.stock_quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }

    updateStockQuantityOnBlur (event,ingredient) {
        event.preventDefault();
        const {newQuantity,page,ingredientsList,active_role} = this.state;
        const {dispatch} = this.props;
        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);

        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>
        if (this.assertQuantityField(quantity) /* && quantity!=ingredient.stock_quantity  */){

            if (active_role!= GUEST){
                dispatch(ingredientsActions.update_stock(ingredient.id,quantity,page));
                let stockValue = 0; 
                let listUpdated = ingredientsList.map((item,index) => {
                    if (item.id == ingredient.id){
                        item.stock_quantity = quantity
                        item.stock_price = Number(quantity) * Number(item.price_per_unit)
                    }
                    if (item && item.stock_price){
                        stockValue+=Number(item.stock_price);
                    }
                    return item;
                });
                this.setState({ingredientsList:listUpdated})
                this.setState({stockValue:Math.round(stockValue * 100) / 100})
                this.setState({ editCell: false });
            }else{
                this.setState({ editCell: false });
                const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                    defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                             content:'inventory.stock_quantity.check.error.title'}));  
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                             content:'inventory.stock_quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }

    updateStockQuantityOnEnter (event,ingredient) {
        const {newQuantity,page,ingredientsList,active_role} = this.state;
        const {dispatch} = this.props;
        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                if (this.assertQuantityField(quantity) /* && quantity!=ingredient.stock_quantity */){

                    if (active_role!= GUEST){
                        dispatch(ingredientsActions.update_stock(ingredient.id,quantity,page));
                        let stockValue = 0; 
                        let listUpdated = ingredientsList.map((item,index) => {
                            if (item.id == ingredient.id){
                                item.stock_quantity = Number(quantity).toFixed(3);
                                item.stock_price = Number(quantity) * Number(item.price_per_unit)
                            }
                            if (item && item.stock_price){
                                stockValue+=Number(item.stock_price);
                            }
                            return item;
                        });
                        this.setState({ingredientsList:listUpdated})
                        this.setState({stockValue:Math.round(stockValue * 100) / 100})
                        this.setState({ editCell: false });
                    }else{
                        this.setState({ editCell: false });
                        const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                            defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                                    content:'inventory.stock_quantity.check.error.title'}));  
                    }

                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                                    content:'inventory.stock_quantity.check.error.message'}));   
                    }
                    this.setState({ editCell: false });
                }
        }
    }


    onRecipeIncreaseDecreaseQuantity (event,recipe,increase) {
        event.preventDefault();
        const {newQuantity,recipesList,active_role} = this.state;
        const {dispatch} = this.props;

        let quantity = Number(trim( newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        if (increase){
            quantity++;
        }else{
            if (quantity > 0) quantity--;
        } 
        
        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>

        if (this.assertQuantityField(quantity) /*&& quantity!=recipe.stock_quantity*/){
            if (active_role!= GUEST){
                //dispatch(recipesActions.update_stock(recipe.id,quantity,page));
                let stockValue = 0; 
                let listUpdated = recipesList.map((item,index) => {
                    if (item.id == recipe.id){
                        item.stock_quantity = Number(quantity).toFixed(3);
                        item.stock_price = Number(quantity) * Number(item.cost_unit)
                    }
                    if (item && item.stock_price){
                        stockValue+=Number(item.stock_price);
                    }
                    return item;
                });
                this.setState({recipesList:listUpdated})
                this.setState({ newQuantity:quantity});
                this.setState({stockRecipeValue:Math.round(stockValue * 100) / 100})
                this.setState({ editCell: false });
            }else{
                this.setState({ editCell: false });
                const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                    defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                             content:'inventory.stock_quantity.check.error.title'}));  
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                             content:'inventory.stock_quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }

    updateRecipeStockQuantityOnEnter (event,recipe) {
        const {newQuantity,page,recipesList,active_role} = this.state;
        const {dispatch} = this.props;
        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                if (this.assertQuantityField(quantity) /* && quantity!=recipe.stock_quantity */){
                    if (active_role!= GUEST){
                        dispatch(recipesActions.update_stock(recipe.id,quantity,page));
                        let stockValue = 0; 
                        let listUpdated = recipesList.map((item,index) => {
                            if (item.id == recipe.id){
                                item.stock_quantity = Number(quantity).toFixed(3);
                                item.stock_price = Number(quantity) * Number(item.cost_unit)
                            }
                            if (item && item.stock_price){
                                stockValue+=Number(item.stock_price);
                            }
                            return item;
                        });
                        this.setState({recipesList:listUpdated})
                        this.setState({stockRecipeValue:Math.round(stockValue * 100) / 100})
                        this.setState({ editCell: false });
                    }else{
                        this.setState({ editCell: false });
                        const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                            defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                                    content:'inventory.stock_quantity.check.error.title'}));  
                    }

                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                                    content:'inventory.stock_quantity.check.error.message'}));   
                    }
                    this.setState({ editCell: false });
                }
        }
    }


    updateRecipeStockQuantityOnBlur (event,recipe) {
        event.preventDefault();
        const {newQuantity,page,recipesList,active_role} = this.state;
        const {dispatch} = this.props;
        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);

        const title = <FormattedMessage id="inventory.stock_quantity.check.error.title"
                                        defaultMessage='Stock Update Error'/>

        if (this.assertQuantityField(quantity) /* && quantity!=recipe.stock_quantity */){

            if (active_role!= GUEST){
                dispatch(recipesActions.update_stock(recipe.id,quantity,page));
                let stockValue = 0; 
                let listUpdated = recipesList.map((item,index) => {
                    if (item.id == recipe.id){
                        item.stock_quantity = quantity
                        item.stock_price = Number(quantity) * Number(item.cost_unit)
                    }
                    if (item && item.stock_price){
                        stockValue+=Number(item.stock_price);
                    }
                    return item;
                });
                this.setState({recipesList:listUpdated})
                this.setState({stockRecipeValue:Math.round(stockValue * 100) / 100})
                this.setState({ editCell: false });
            }else{
                this.setState({ editCell: false });
                const content =  <FormattedMessage id="inventory.stock_quantity.privilege.error.message"
                                                    defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'inventory.stock_quantity.privilege.error.message',
                                             content:'inventory.stock_quantity.check.error.title'}));  
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const content =  <FormattedMessage id="inventory.stock_quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'inventory.stock_quantity.check.error.title',
                                             content:'inventory.stock_quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }

    toaster(dispatch,alert){
        const { intl } = this.props;
        //this.setState({ errorNotification: false })
        this.setState({ activeModal: null });
        toaster_dispatch(dispatch,alert,intl);
    }

    addIngredientsRows(ingredients){
        const {currency,newQuantity,submitted,editCell,editCellIndex} = this.state;
        return ingredients.map((ingredient,index) => {
                return (
                    <Table.Row selectable key={index} onClick={(event) => {this.setState({ editCell: true})}}>
                        <Table.Cell width={3} >
                            <Icon name='shopping basket' color='teal'/>
                            <a href={"/ingredientdetails/"+ingredient.id}  
                               onClick={(event) => this.seeIngredient(event,ingredient.id)}
                               >{ingredient.name && ingredient.name.length >= 45 && 
                                  ingredient.name.substring(0,45) + '..'}
                                {ingredient.name && ingredient.name.length < 45 && 
                                    ingredient.name}
                            </a>
                            <p style={{'color':'#b5b1b1',marginLeft:'2em',fontSize:'10px'}}><i>{ingredient.ref_internal}</i></p>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {ingredient.category__name && ingredient.category__name.length >= 18 && 
                                  ingredient.category__name.substring(0,18) + '..'}
                            {ingredient.category__name && ingredient.category__name.length < 18 && 
                                    ingredient.category__name}
                            {/* {ingredient.category__name}  */}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            <a href={"/ingredientssuppliers/"+ingredient.supplier__id} 
                               onClick={(event) => this.seeSupplier(event,ingredient.supplier__id)}
                               >{ingredient && ingredient.supplier__name && ingredient.supplier__name}
                            </a>
                        </Table.Cell>
                        {(ingredient.purchase_quantity == '') &&
                        <Table.Cell width={1} textAlign='right'> 
                             { grams.includes(ingredient.unit) && 
                            <span>
                            <FormattedNumber value={Number(ingredient.price_per_unit)*1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                            {' / kg'}
                            </span>}
                            { mgrams.includes(ingredient.unit) && 
                            <span>
                            <FormattedNumber value={Number(ingredient.price_per_unit)* 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                            {' / kg'}
                            </span>}
                            { cliters.includes(ingredient.unit) && 
                            <span>
                            <FormattedNumber value={Number(ingredient.price_per_unit)*100} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                            {' / L'}
                            </span>}
                            { mliters.includes(ingredient.unit) && 
                            <span>
                            <FormattedNumber value={Number(ingredient.price_per_unit)*1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                            {' / L'}
                            </span>}
                            {  !grams.includes(ingredient.unit) &&
                                 !mgrams.includes(ingredient.unit) && 
                                 !cliters.includes(ingredient.unit) &&
                                 !mliters.includes(ingredient.unit) &&
                             <span>
                            <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}{ingredient.unit} 
                            </span>}
                        </Table.Cell>}
                        {(ingredient.purchase_quantity != '') &&
                        <Table.Cell width={1} textAlign='right'> 
                            <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                            {' / '}<FormattedMessage id="inventory.quantity.unit.text"
                                                     defaultMessage='unit'/>
                        </Table.Cell>}
                        <Table.Cell /*selectable active*/
                                    width={2} 
                                    textAlign='right'
                                    //style={{'background-color':'#f7f7f7'}}
                                    //style={{'background-color':'#e8f3fa'}}
                                    onClick={(event) => this.editStockQuantityHandlerInput(event,index,ingredient.stock_quantity)}
                                    >
                           
                           {(!editCell || (editCell && index != editCellIndex) ) &&
                            <a href='#'>
                                <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="2"/>{' '}{ingredient.stock_unit}
                                {/* <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="2"/>{' '}{ingredient.unit} */}
                            </a>
                           }
                           {editCell && index == editCellIndex &&
                           <OutsideClickHandler onOutsideClick={(event) => this.updateStockQuantityOnBlur(event,ingredient)}>
                                <div>
                                    <Icon name='minus circle' size='large'
                                              style={{cursor:'pointer'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,ingredient,false)} /> 
                                    <Icon name='plus circle'  size='large'
                                              style={{cursor:'pointer',padding:'0em 1em 0em 0em'}} 
                                              onClick={(event) => this.onIncreaseDecreaseQuantity(event,ingredient,true)}/>
                                    {/* <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/> 
                                            {value => */} 
                                                <Input type="text"
                                                    //placeholder="units"
                                                    id="stockQuantity"
                                                    value={newQuantity}
                                                    onChange={this.onNewQuantity}
                                                    //onBlur={(event) => this.updateStockQuantityOnBlur(event,ingredient)} 
                                                    onKeyPress={(event) => this.updateStockQuantityOnEnter(event,ingredient)} 
                                                    autoFocus
                                                    style={{height:'2.6em',minWidth:'7em',maxWidth:'7em', padding:'0em 0.5em 0em 0.5em'}}
                                                    label={{ basic: false, content: ingredient.unit }}
                                                    labelPosition='right'
                                                    error={submitted && (!this.assertQuantityField())}/>
    {/*                                     }
                                    </FormattedNumber> */}
                                {/*  {' '}{ingredient.unit} */}
                                </div>
                            </OutsideClickHandler>
                           } 
                        </Table.Cell>
                        <Table.Cell width={1}
                                     //style={{'background-color':'#fcf6e3'}} 
                                    textAlign='right'>
                            <FormattedNumber value={ingredient.stock_price} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                        </Table.Cell>
                        {ingredient.date_expire &&
                        <Table.Cell width={1} textAlign='right'>
                            <span style={{/* color:'#63a4dd' , */fontSize:'13px'/*  ,fontWeight: 'bold' */}}><i>
                                                     <FormattedDate value={new Date(ingredient.date_expire)}
                                                            year='numeric'
                                                            month='short'
                                                            day='2-digit'/></i></span>
                        </Table.Cell>}
                        {!ingredient.date_expire &&
                        <Table.Cell width={1} textAlign='right'>---</Table.Cell>}
                      {/*  <Table.Cell width={1} textAlign='center'> */}
{/*                             <Popup trigger=
                                    {<Button basic id={index} className="ui small icon button"  color='blue'
                                            onClick={(event) => this.editStockQuantityHandler(event,index,ingredient.stock_quantity)}>
                                            <Icon className="box"></Icon>
                                    </Button>}
                                    content={<FormattedMessage id="inventory.popup.add.text"
                                                                     defaultMessage='Edit quantity'/>}
                                    inverted
                                    size='mini'/>   */}                         
                            <Modal id={index}
                                   open={this.state.activeModal === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}
                                   closeIcon>
                                <Header icon='box' content={<FormattedMessage id="inventory.modal.update.title"
                                                                                defaultMessage='Update stock quantity'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="inventory.modal.update.message"
                                                         defaultMessage='{name} :'
                                                         values={{name:ingredient.name}} />{'  '}
                                       <FormattedNumber value={newQuantity} maximumFractionDigits="3">
                                        {value =>  
                                            <Input
                                                type="text"
                                                placeholder="units"
                                                id="stockQuantity"
                                                value={newQuantity}
                                                onChange={this.onNewQuantity}
                                                style={{minWidth:'4em',maxWidth:'6em', padding:'0em 0.5em 0em 0.5em'}}
                                                error={submitted && (!this.assertQuantityField())}/>}
                                        </FormattedNumber>{'  '}{ingredient.unit}
                                        {submitted && (!this.assertQuantityField()) &&
                                            <label style={{color:'red', textAlign:'center' }}>{' '}<i>
                                                <FormattedMessage id="inventory.form.modal.error.message"
                                                                 defaultMessage='quantity format invalid'/>
                                            </i></label>}
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="inventory.modal.deletion.button.cancel"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="inventory.modal.deletion.button.update"
                                                                    defaultMessage='Update'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.updateStockQuantity(event,ingredient.id)}/>
                                    </Modal.Actions>
                            </Modal>
                       {/*  </Table.Cell>  */}
                     </Table.Row>
                );
        });
    }

    addRecipesRows(recipes){
        const {currency,newQuantity,submitted,editCell,editCellIndex} = this.state;
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row selectable key={index} onClick={(event) => {this.setState({ editCell: true})}}>
                        <Table.Cell width={3} >
                            <Icon name='food' color='blue'/>
                            <a href={"/recipedetails/"+recipe.id}  
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               >{recipe.name && recipe.name.length >= 45 && 
                                recipe.name.substring(0,45) + '..'}
                                {recipe.name && recipe.name.length < 45 && 
                                    recipe.name}
                            </a>
                            <p style={{'color':'#b5b1b1',marginLeft:'1.5em'}}><i>{recipe.ref_internal}</i></p>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {recipe.category__name && recipe.category__name.length >= 18 && 
                                  recipe.category__name.substring(0,18) + '..'}
                            {recipe.category__name && recipe.category__name.length < 18 && 
                                    recipe.category__name}
                        </Table.Cell>
                 {/*       <Table.Cell width={1} textAlign='center'>
                             <a href={"/ingredientssuppliers/"+ingredient.supplier__id} 
                               onClick={(event) => this.seeSupplier(event,ingredient.supplier__id)}
                               >{ingredient && ingredient.supplier__name && ingredient.supplier__name}
                            </a> 
                        </Table.Cell>*/}
                        <Table.Cell width={1} textAlign='right'> 
                           {/*  <FormattedNumber value={recipe.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}{recipe.yield_units}  */}
                            {recipe && typeYield(recipe.yield_units) == 'quantity' &&
                            <span><FormattedNumber value={recipe.cost_unit} 
                                                style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}{recipe.yield_units}</span>}
                            {recipe && typeYield(recipe.yield_units) != 'quantity' &&
                                <span><FormattedNumber value={recipe.cost_total} 
                                                style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>{' / '}<FormattedMessage id="inventory.quantity.recipe.singular.text"
                                                                                                                                                defaultMessage='recipe'/></span> }
                        </Table.Cell>
                        <Table.Cell /*selectable active*/
                                    width={2} 
                                    textAlign='right'
                                    //style={{'background-color':'#f7f7f7'}}
                                    //style={{'background-color':'#e8f3fa'}}
                                    onClick={(event) => this.editStockQuantityHandlerInput(event,index,recipe.stock_quantity)}
                                    >
                           
                           {(!editCell || (editCell && index != editCellIndex) ) &&
                            <a href='#'>
                                <FormattedNumber value={recipe.stock_quantity} maximumFractionDigits="2"/>{' '}{recipe.yield_units}
                                {/* <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="2"/>{' '}{ingredient.unit} */}
                            </a>
                           }
                           {editCell && index == editCellIndex &&
                           <OutsideClickHandler onOutsideClick={(event) => this.updateRecipeStockQuantityOnBlur(event,recipe)} >
                                <div>
                                <Icon name='minus circle' size='large'
                                              style={{cursor:'pointer'}} 
                                              onClick={(event) =>this.onRecipeIncreaseDecreaseQuantity(event,recipe,false)} /> 
                                    <Icon name='plus circle'  size='large'
                                              style={{cursor:'pointer',padding:'0em 1em 0em 0em'}} 
                                              onClick={(event) => this.onRecipeIncreaseDecreaseQuantity(event,recipe,true)}/>
                                    {/* <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/> 
                                            {value => */} 
                                                <Input type="text"
                                                    //placeholder="units"
                                                    id="stockQuantity"
                                                    value={newQuantity}
                                                    onChange={this.onNewQuantity}
                                                    //onBlur={(event) => this.updateRecipeStockQuantityOnBlur(event,recipe)} 
                                                    onKeyPress={(event) => this.updateRecipeStockQuantityOnEnter(event,recipe)} 
                                                    autoFocus
                                                    //style={{height:'2.5em',minWidth:'5em',maxWidth:'8em', padding:'0em 0.5em 0em 0.5em'}}
                                                    style={{height:'2.6em',minWidth:'7em',maxWidth:'7em', padding:'0em 0.5em 0em 0.5em'}}
                                                    label={{ basic: false, content: recipe.yield_units}}
                                                    labelPosition='right'
                                                    error={submitted && (!this.assertQuantityField())}/>
    {/*                                     }
                                    </FormattedNumber> */}
                               {/*  {' '}{recipe.yield_units} */}
                                </div>
                            </OutsideClickHandler>
                           } 
                        </Table.Cell>
                        
                        <Table.Cell width={1}
                                     //style={{'background-color':'#fcf6e3'}} 
                                    textAlign='right'>
                            <FormattedNumber value={recipe.stock_price} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                        </Table.Cell>
                      {/*  <Table.Cell width={1} textAlign='center'> */}
{/*                             <Popup trigger=
                                    {<Button basic id={index} className="ui small icon button"  color='blue'
                                            onClick={(event) => this.editStockQuantityHandler(event,index,ingredient.stock_quantity)}>
                                            <Icon className="box"></Icon>
                                    </Button>}
                                    content={<FormattedMessage id="inventory.popup.add.text"
                                                                     defaultMessage='Edit quantity'/>}
                                    inverted
                                    size='mini'/>   */}                         
                            <Modal id={index}
                                   open={this.state.activeModal === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}
                                   closeIcon>
                                <Header icon='box' content={<FormattedMessage id="inventory.modal.update.title"
                                                                                defaultMessage='Update stock quantity'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="inventory.modal.update.message"
                                                         defaultMessage='{name} :'
                                                         values={{name:recipe.name}} />{'  '}
                                       <FormattedNumber value={newQuantity} maximumFractionDigits="3">
                                        {value =>  
                                            <Input
                                                type="text"
                                                placeholder="units"
                                                id="stockQuantity"
                                                value={newQuantity}
                                                onChange={this.onNewQuantity}
                                                style={{minWidth:'4em',maxWidth:'6em', padding:'0em 0.5em 0em 0.5em'}}
                                                error={submitted && (!this.assertQuantityField())}/>}
                                        </FormattedNumber>{'  '}{recipe.unit}
                                        {submitted && (!this.assertQuantityField()) &&
                                            <label style={{color:'red', textAlign:'center' }}>{' '}<i>
                                                <FormattedMessage id="inventory.form.modal.error.message"
                                                                 defaultMessage='quantity format invalid'/>
                                            </i></label>}
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="inventory.modal.deletion.button.cancel"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="inventory.modal.deletion.button.update"
                                                                    defaultMessage='Update'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.updateStockQuantity(event,recipe.id)}/>
                                    </Modal.Actions>
                            </Modal>
                       {/*  </Table.Cell>  */}
                     </Table.Row>
                );
        });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false)&& (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }


    render() { 
        const { categoriesIngredient,categoriesRecipe,alert,dispatch,loading_i,loading_r ,updating,supplier,customer,storageAreas} = this.props;
        const { newItems,filtering,categorySelected,supplierSelected,typeSelected,nameSelected,isMobile,page,itemsPerPage,
            submitted,stockValue,stockRecipeValue,currency,ingredientsList,recipesList,supplierCodeSelected,codeSelected, stockType,storageAreaSelected } = this.state;

        //let user = null;
         let membership = null;

         if (customer){
            membership = customer.membership;
            //user = customer.user;
         }

        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (stockType =='ingredient' && !filtering && ingredientsList){
            length = ingredientsList.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = ingredientsList.slice(begin,end);
        }

        if (stockType =='recipe' && !filtering && recipesList){
            length = recipesList.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = recipesList.slice(begin,end);
        }

        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = newItems.slice(begin,end);
        }

        //Filter and notify restrictions if user unsubscribed
        if (stockType =='ingredient' && this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
        }

        if (stockType =='recipe' && this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }


        return ( 
            <div>
                 <SideLeftUncoverInventory>
                        <Grid stackable style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="inventory.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="inventory.link.inventory"
                                                              defaultMessage='Inventory'/></Breadcrumb.Section>
                            </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_box} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="inventory.page.title"
                                                              defaultMessage='Inventory'/>
                                            <Header.Subheader><FormattedMessage id="inventory.page.subtitle"
                                                              defaultMessage='Manage your food goods'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                             {alert.message && this.toaster(dispatch,alert)}
                             <Grid.Row columns={1}/>
                             <Grid.Row columns={1}>
                                <Grid.Column width={4} align='right'>
                                        <Dropdown selection
                                                placeholder={'Type'} 
                                                value={stockType}
                                                //icon='edit' 
                                                options={optionsTypeStock}
                                                fluid
                                                onChange={this.toggleStockTypeReset}
                                                style={{'background-color':'#e8f3fa','font-size': '18px','font-weight': '600','color': 'black'}}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} width='equals' stretched>
                                <Grid.Column mobile={6} tablet={6} computer={6}/> 
                                <Grid.Column mobile={6} tablet={5} computer={5}/> 
                                <Grid.Column mobile={4} tablet={5} computer={3}> 
                                    <Segment style={{'background-color':'#e8f3fa', textAlign:'right'}} >
                                        <Header as='h4' color='black'>
                                                <FormattedMessage id="inventory.page.stock.value.title"
                                                              defaultMessage='VALUE IN STOCK'/>
                                        </Header>
                                        {/* <Header as='h2' color='blue'>{stockValue} €</Header> */}
                                        <Header as='h2' color='blue'>
                                             {stockType =='ingredient' &&
                                             <FormattedNumber value={stockValue} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>}
                                             {stockType =='recipe' &&
                                             <FormattedNumber value={stockRecipeValue} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>}
                                        </Header>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                           {/*  <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column width={6}> 
                                    <p style={styles.FontLato13}><FormattedMessage id="inventory.filter.name.title"
                                                         defaultMessage='Search (one or multiple words)'/></p>
                                    <FormattedMessage id="inventory.filter.byname.placeholder"
                                                        defaultMessage='Type a word (or multiple with spaces)'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                disabled={this.isUnSubscribedUser()}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}} ><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column width={1}/>
                                <Grid.Column width={8}> 
                                   <p style={styles.FontLato13}><FormattedMessage id="inventory.filter.title"
                                                         defaultMessage='Filter by'/></p>
                                    <Dropdown
                                        button basic
                                        className='icon'
                                        labeled
                                        icon={{name:'filter', color:'teal'}}
                                        options={stockType == 'ingredient' ? filterOptions: filterRecipeOptions}
                                        value = {typeSelected}
                                        onChange={this.filterTypeList}
                                        style={{'font-size':'14px'}}/>
                                    {typeSelected == 'Category' && 
                                        <FormattedMessage id="inventory.filter.category.placeholder"
                                                        defaultMessage='Select'>
                                        {placeholder =>  
                                                <Dropdown selection search
                                                        options= { (stockType == 'ingredient' && categoriesIngredient) ? categoriesIngredient.options : categoriesRecipe.options} 
                                                        value= {categorySelected && categorySelected.id}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{'font-size':'14px'}}
                                                        //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                        />}
                                    </FormattedMessage>}
                                    {typeSelected == 'Supplier' && 
                                        <FormattedMessage id="inventory.filter.supplier.placeholder"
                                                         defaultMessage='Select'>
                                        {placeholder =>  
                                                    <Dropdown selection search
                                                            options= {supplier && supplier.options}  
                                                            value= {supplierSelected && supplierSelected.id}
                                                            placeholder={placeholder}
                                                            onChange={this.filterSupplierList}
                                                            clearable
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}
                                                            //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                            />}
                                    </FormattedMessage>}
                                    {typeSelected == 'SupplierRef' && 
                                        <FormattedMessage id="inventory.filter.internalref.placeholder"
                                                         defaultMessage='Search'>
                                        {placeholder =>  
                                                    <Input placeholder={placeholder}
                                                            value= {supplierCodeSelected} 
                                                            onChange={this.filterSupplierRefList}
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}/>}
                                    </FormattedMessage>}
                                    {typeSelected == 'InternalRef' && 
                                        <FormattedMessage id="inventory.filter.supplierref.placeholder"
                                                         defaultMessage='Search'>
                                        {placeholder =>  
                                                    <Input placeholder={placeholder}
                                                            value={codeSelected}
                                                            onChange={this.filterInternalRefList}
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}/>}
                                    </FormattedMessage>}
                                    {typeSelected == 'StorageArea' && 
                                        <FormattedMessage id="inventory.filter.storageArea.placeholder"
                                                         defaultMessage='Select'>
                                        {placeholder =>  
                                                    <Dropdown selection search
                                                            options= {storageAreas && storageAreas.options}  
                                                            value= {storageAreaSelected && storageAreaSelected.id}
                                                            placeholder={placeholder}
                                                            onChange={this.filterStorageAreaList}
                                                            clearable
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}
                                                            //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                            />}
                                    </FormattedMessage>}
                                </Grid.Column>
{/*                                 <Grid.Column width='2'/> 
                                <Grid.Column> 
                                   <p style={styles.FontLato14Grey}><FormattedMessage id="inventory.filter.category.title"
                                                         defaultMessage='Filter by category'/></p>
                                    <FormattedMessage id="inventory.filter.category.placeholder"
                                                        defaultMessage='Select a category'>
                                        {placeholder =>  
                                                <Dropdown selection
                                                        options= {categoriesIngredient && categoriesIngredient.options} 
                                                        value= {categorySelected}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}></Dropdown>}
                                    </FormattedMessage>
                                </Grid.Column>  */}
                            </Grid.Row>                            
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                <Dimmer.Dimmable> 
                                    <Dimmer active = {(loading_i || loading_r || updating)} inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer>
                                {/* <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}> */}
                                <Table selectable  
                                          definition
                                           unstackable
                                           //compact 
                                           //striped 
                                           singleLine 
                                           //celled
                                           style={styles.FontLato13Border} >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={3}>
                                                    <FormattedMessage id="inventory.table.header.column.name"
                                                                          defaultMessage='Name'/>
                                                    <p style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'1em'}}><i><FormattedMessage id="inventory.table.header.column.reference"
                                                                          defaultMessage='Reference code'/></i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="inventory.table.header.column.category"
                                                                          defaultMessage='Category'/>
                                                </Table.HeaderCell>
                                                {stockType =='ingredient' && 
                                                   <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="inventory.table.header.column.supplier"
                                                                          defaultMessage='Supplier'/>
                                                </Table.HeaderCell>}
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="inventory.table.header.column.purchase"
                                                                          defaultMessage='Unit Price'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="inventory.table.header.column.stock_quantity"
                                                                          defaultMessage='Stock Quantity'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="inventory.table.header.column.stock_value"
                                                                          defaultMessage='Stock value'/>
                                                </Table.HeaderCell>
                                                {stockType =='ingredient' && 
                                                  <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="inventory.table.header.column.expire"
                                                                          defaultMessage='Use By'/>
                                                </Table.HeaderCell>}
{/*                                                 <Table.HeaderCell width={2} textAlign='right'>
                                                </Table.HeaderCell>  */}
                                            </Table.Row>
                                            
                                        </Table.Header>     
                                        <Table.Body>  
                                        {stockType =='ingredient' && items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="inventory.table.header.row.noitems"
                                                                            defaultMessage='No ingredients'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                        {stockType =='ingredient' && items && items.length > 0 &&
                                            this.addIngredientsRows(items)
                                        }
                                        {stockType =='recipe' && items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="inventory.table.header.row.noitems.recipes"
                                                                            defaultMessage='No recipes'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                        {stockType =='recipe' && items && items.length > 0 &&
                                            this.addRecipesRows(items)
                                        }
                                        </Table.Body>
                                        {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="inventory.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Subscription limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {stockType =='ingredient' && ingredientsList && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="inventory.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{ingredientsList.length}{' '}
                                                                <FormattedMessage id="inventory.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            {stockType =='recipe' && recipesList && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="inventory.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{recipesList.length}{' '}
                                                                <FormattedMessage id="inventory.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="inventory.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                </Table>
                                </Dimmer.Dimmable>  
                                {/* </OutsideClickHandler> */}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={options} 
                                                  //simple 
                                                  item
                                                  inline
                                                  //icon='list'
                                                  //button 
                                                  fluid
                                                  //search
                                                  //header='Page'
                                                  //text='ingredients'
                                                  onChange={this.onElemPerPageChange}
                                                  disabled={this.isUnSubscribedUser()}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="inventory.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="inventory.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={1}
                                        //ellipsisItem={null}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                        disabled={this.isUnSubscribedUser()}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Modal open={this.state.activeModalPremium}
                            dimmer='true' 
                            style={styles.Modal} 
                            onClose={this.hideModal}
                            closeIcon>
                        <Modal.Header>
                           <FormattedMessage id="inventory.modal.header"
                                              defaultMessage='Recipes Inventory'/>
                        </Modal.Header>
                        <Modal.Content>
                            <Message fluid icon size='big' color='blue'>
                              <Image src={img_performance} size ='small' rounded/>
                              <br/>
                              <Message.Content style={{marginLeft:'1em'}}>
                                  <Message.Header color='black'>
                                      <FormattedMessage id="inventory.modal.premium.message.upgradeplan"
                                                          defaultMessage='ENTERPRISE Feature'/>
                                  </Message.Header>
                                  <br/>
                                  <FormattedMessage id="inventory.modal.premium.title.message.upgradeplan"
                                                  defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                  <br/><br/>
                                  <Button basic color='blue' onClick={(e) => this.upgradeSubscription()}>
                                      <FormattedMessage id="inventory.premium.button.chooseplan"
                                                  defaultMessage='Choose premium plan'/>
                                  </Button>                                      
                              </Message.Content>
                          </Message>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                               {/* <Icon name='exit' /> */}
                              <FormattedMessage id="inventory.modal.premium.button.cancel"
                                              defaultMessage='Cancel'/>
                            </Button>
                            <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                               <FormattedMessage id="inventory.modal.premium.button.chooseplan"
                                                  defaultMessage='Choose premium plan'/>
                            </Button>
                          </Modal.Actions>
                      </Modal> 
                </SideLeftUncoverInventory>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { ingredients,recipes, categoriesIngredient,categoriesRecipe,supplier,storageAreas,dispatch, alert } = state;
    const { loading_r } = state.recipes;
    const { loading_i,updating } = state.ingredients;
    const { subscription,loading,customer } = state.payment;
    return {
        ingredients,
        recipes,
        categoriesIngredient,
        categoriesRecipe,
        supplier,
        storageAreas,
        updating,
        dispatch,
        alert,
        loading_i,
        loading_r,
        subscription,
        customer,
        loading
    };
}

const connectedInventory = injectIntl(connect(mapStateToProps)(Inventory));
export { connectedInventory as Inventory };
 