import { categoryIngredientConstants } from '../_constants';
import { categoryIngredientService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const categoryIngredientActions = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count,
    do_count_ingredients
};

function getAll() {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.getAll()
            .then(
                categoriesIngredient => dispatch(success(categoriesIngredient)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryIngredientConstants.GETALL_REQUEST } }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.GETALL_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.GETALL_FAILURE, error } }
}

function get(id) {
    return dispatch => {
        dispatch(request(id));

        categoryIngredientService.get(id)
            .then(
                categoriesIngredient => {
                    dispatch(success(categoriesIngredient));
                    //history.push('/ingredientdetails');
                },
                error => dispatch(failure(error))
            );
    };

    function request(id) { return { type: categoryIngredientConstants.GET_REQUEST ,id} }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.GET_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.GET_FAILURE, error } }
}

function add(category,link) {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.add(category)
            .then(
                categoriesIngredient =>{
                    dispatch(success(categoriesIngredient));
                    let title_text = <FormattedMessage id="categoryingredient.actions.category.add.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryingredient.actions.category.add.success.content"
                                                         defaultMessage="Category Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "categoryingredient.actions.category.add.success.title",
                                                   content:"categoryingredient.actions.category.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/categoriesingredient');
                } 
            );
    };

    function request() { return { type: categoryIngredientConstants.ADD_REQUEST } }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.ADD_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.ADD_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        categoryIngredientService.delete(id)
            .then(
                categoriesIngredient => { 
                    dispatch(success(id,categoriesIngredient));
                    let title_text = <FormattedMessage id="categoryingredient.actions.category.delete.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryingredient.actions.category.delete.success.content"
                                                         defaultMessage="Category Deleted Successfully"/>
                                
                    dispatch(alertActions.success({title: "categoryingredient.actions.category.delete.success.title",
                                                   content:"categoryingredient.actions.category.delete.success.content"}));
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/categoriesingredient');
                }
            );
    };

    function request() { return { type: categoryIngredientConstants.DELETE_REQUEST } }
    function success(id,categoriesIngredient) { return { type: categoryIngredientConstants.DELETE_SUCCESS,id:id, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.count()
            .then(
                categoriesIngredient => dispatch(success(categoriesIngredient)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryIngredientConstants.COUNT_REQUEST } }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.COUNT_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.COUNT_FAILURE, error } }
}

function update(id,name,is_active,notify=true) {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.update(id,name,is_active)
            .then(
                categoriesIngredient =>{
                    dispatch(success(id,categoriesIngredient));
                    let title_text = <FormattedMessage id="categoryingredient.actions.category.update.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryingredient.actions.category.update.success.content"
                                                         defaultMessage="Category Updated Successfully"/>
                                
                    if (notify){
                        dispatch(alertActions.success({title: "categoryingredient.actions.category.update.success.title",
                        content:"categoryingredient.actions.category.update.success.content"}));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/categoriesingredient');
                }
            );
    };

    function request() { return { type: categoryIngredientConstants.UPDATE_REQUEST } }
    function success(id,categoriesIngredient) { return { type: categoryIngredientConstants.UPDATE_SUCCESS, id:id, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.UPDATE_FAILURE, error } }
}

function do_count_ingredients() {
    return dispatch => {
        dispatch(request());

        categoryIngredientService.do_count_ingredients()
            .then(
                categoriesIngredient => dispatch(success(categoriesIngredient)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_REQUEST } }
    function success(categoriesIngredient) { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_SUCCESS, categoriesIngredient } }
    function failure(error) { return { type: categoryIngredientConstants.COUNT_INGREDIENTS_FAILURE, error } }
}
