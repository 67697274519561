import React, { Component } from 'react';
import { Button,Header,Divider,Segment,Grid,Message,Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {paymentActions} from '../_actions';
import {history,toaster_dispatch} from '../_helpers';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,FormattedNumber,FormattedDate,injectIntl} from 'react-intl';
import {styles} from './Styles.jsx';
import img_logo from '../Assets/ratatool-logo.png'



class InvoiceDetails extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { 
            invoice:  props.location.state.invoice,
            username : userIn.user.username ,
            email: userIn.user.email
         }
        this.generatePDFBackend=this.generatePDFBackend.bind(this);    
    }

    backInvoices (event) {
        event.preventDefault();
        history.push('/invoices/');
    }

    generatePDFBackend (event) {
        event.preventDefault();
        const { invoice } = this.state;
    
        if (invoice){
            let properties = {
                id: invoice.invoice_number
            }
        
            this.props.dispatch(paymentActions.renderInvoice(properties));  
        } 
    }

    render() { 
        const { alert,dispatch,intl } = this.props;
        const { invoice,username,email } = this.state;

        return ( 
                <SidebarProfile >
                        <Grid stackable >
                            <Grid.Row columns={1} >
                                <Grid.Column width={12}>
                                    {/* <br/> */}
                                    <Message size='huge' style={styles.PageTitleBlack}>
                                           <Message.Header>
                                                <Icon name='settings' color='teal'  size='huge'/>  
                                                <FormattedMessage id="invoice.top.title.header"
                                                                  defaultMessage='Invoice'/>
                                            </Message.Header>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1}>
                                <Grid.Column width={12}>
                                        <br/>
                                        <Header as='h3' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                             <FormattedMessage id="invoice.segment.title"
                                                               defaultMessage='Invoice details'/>
                                        </Header>
                                        {!invoice && <p style={styles.LabelGrey}>
                                            <FormattedMessage id="invoice.segment.message.no.data"
                                                              defaultMessage='No available data'/>
                                        </p>}
{/*                                         {invoice && 
                                          <Header as='h2' color='teal' textAlign='left'>
                                            <FormattedMessage id="invoice.segment.message.branding"
                                                              defaultMessage='Ratatool'/>
                                         </Header>} */}
                                         <br/>
                                         <Link to='/'><img alt='logo' src={img_logo} style={{ width: '150px', height: '43px' }} /></Link>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={12}> 
                                        <br/>
                                        <Segment style={{padding:'1em 2em 1em 2em' }}>
                                            <Grid stackable style={{padding:'0em 0em 0em 2em' }} columns={4}>
                                                <Grid.Row columns={4} fluid>
                                                    <Grid.Column fluid>
                                                            <br/>
                                                            <Header as='h3' color='grey' textAlign='left'>
                                                                <FormattedMessage id="invoice.segment.bill.title"
                                                                                  defaultMessage='Bill to '/>
                                                            </Header>
                                                            <Header as='h4' color='grey' textAlign='left'>
                                                                {username}
                                                            </Header>
                                                            <p> {email}</p>
                                                    </Grid.Column>
                                                    <Grid.Column fluid>
                                                            <br/>
                                                            <Header as='h3' color='grey' textAlign='left'>
                                                               <FormattedMessage id="invoice.segment.invoice.number.title"
                                                                                  defaultMessage=' Invoice number'/>
                                                            </Header>
                                                            <p>{invoice && invoice.invoice_number }</p>
                                                    </Grid.Column>
                                                    <Grid.Column fluid >
                                                            <br/>
                                                            <Header as='h3' color='grey' textAlign='left'>
                                                                <FormattedMessage id="invoice.segment.invoice.issue.date.title"
                                                                                  defaultMessage='Issue date'/>
                                                            </Header>
                                                            <p> {invoice 
                                                                && invoice.finalized_at && 
                                                                <FormattedDate value={new Date(invoice.finalized_at)}
                                                                                year='numeric'
                                                                                month='long'
                                                                                day='2-digit'/>}
                                                            </p>
                                                    </Grid.Column>
                                                    <Grid.Column floated='right' fluid>
                                                            <br/>
                                                            <Header as='h3' color='grey' textAlign='left'>
                                                                <FormattedMessage id="invoice.segment.invoice.period.title"
                                                                                  defaultMessage='Period'/>
                                                            </Header>
                                                            <p>{invoice 
                                                                && invoice.finalized_at && 
                                                                <FormattedDate value={new Date(invoice.finalized_at)}
                                                                                year='numeric'
                                                                                month='long'/>}
                                                             </p>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <br/><br/> <br/><br/>
                                            <Header as='h3' floated='left'>
                                                <FormattedMessage id="invoice.segment.invoice.summary.title"
                                                                  defaultMessage='Summary'/>
                                            </Header>
                                            <br/><br/> 
                                            <Divider clearing />
                                            <Header as='h4' floated='left'>
                                                <FormattedMessage id="invoice.segment.invoice.subtotal.title"
                                                                   defaultMessage='Subtotal'/>
                                            </Header>
                                            <Header as='h4' floated='right'>
                                             <FormattedNumber value={invoice.subtotal/100}/>
                                             {intl.formatMessage({id:'currency.symbol'})}
                                            </Header>
                                            <Divider clearing />
                                            <Header as='h4' floated='left'>
                                                <FormattedMessage id="invoice.segment.invoice.vat.title"
                                                                  defaultMessage='VAT'/>
                                            </Header>
                                            <Header as='h4' floated='right'>
                                             <FormattedMessage id="invoice.segment.invoice.vat.value"
                                                                defaultMessage='-'/>
                                            </Header>
                                            <Divider clearing />
                                            <Header as='h4' floated='left'>
                                             <FormattedMessage id="invoice.segment.invoice.total.title"
                                                                defaultMessage='Total'/>
                                            </Header>
                                            <Header as='h4' floated='right'>
                                                <FormattedNumber value={invoice.total/100}/>
                                                {intl.formatMessage({id:'currency.symbol'})}
                                            </Header>
                                            <br/><br/> 
                                        </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12}  floated='left'>
                                    <Button style={styles.ButtonGreen} //basic 
                                        icon
                                        labelPosition='left' 
                                        size='medium'
                                        color='teal'
                                        onClick={this.backInvoices}><Icon name='left arrow'/>
                                        <FormattedMessage id="invoice.segment.invoice.button.back"
                                                          defaultMessage='Back to Invoices history'/>
                                    </Button>
                                    <Button style={styles.ButtonGreenInvoice} //basic
                                        icon
                                        labelPosition='left' 
                                        size='medium'
                                        color='teal'
                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                        <FormattedMessage id="invoice.segment.invoice.button.print"
                                                          defaultMessage='Print invoice'/>
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                </SidebarProfile>
        )
    }
}

function mapStateToProps(state) {
    const { payment,alert,dispatch } = state;
    return {
        payment,
        alert,
        dispatch,
    };
}

const connectedInvoiceDetails = injectIntl(connect(mapStateToProps)(InvoiceDetails));
export { connectedInvoiceDetails as InvoiceDetails };
 