import React from 'react'
import { Button, Form, Grid, Header, Message, Dropdown, Flag, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {FormattedMessage,defineMessages,injectIntl} from 'react-intl';
import { serverConstants } from '../_constants';
import {Helmet} from "react-helmet";
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import TagManager from 'react-gtm-module'
import {toaster_dispatch} from '../_helpers';
import ButterToast  from 'butter-toast';
import ReCAPTCHA from "react-google-recaptcha";

const html_tags = defineMessages({
  html_title: {
       id: 'register.html.title.tag',
       defaultMessage: " Ratatool recipe cost calculator - Register now and gain in profitability and productivity",
  },
  meta_name_description: {
       id: 'register.meta.name.description.tag',
       defaultMessage: "Restaurant software calculator for your food costs ingredients and recipes, estimate your margin, reduce your waste, edit and print your professional recipe forms, your shopping orders and your nutrition facts labels from USDA data",
  }
});



class RegisterLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                username: '',
                password1: '',
                password2: '',
                email: ''
            },
            submitted: false,
            is_invited: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmitWithReCAPTCHA = this.onSubmitWithReCAPTCHA.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.recaptchaRef = React.createRef();



        if (props.match && props.match.params && props.match.params.invitee)
        {
            this.state.user.email = props.match.params.invitee
            this.state.is_invited = true
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

/*     isValidUrl(urlString) {
      var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
                          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
                          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
                          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
                          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
                          '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !urlPattern.test(urlString);
    } */

    isValidURL(input) {
      let pattern = '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
          '[a-zA-Z]{2,13})' + // extension
          '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
          '|localhost)' + // OR localhost
          '(\\:\\d{1,5})?' + // port
          '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
          '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
          '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
      let regex = new RegExp(pattern);
      return regex.test(input);
  }


    onSubmitWithReCAPTCHA() {
      const { user } = this.state;
      this.setState({ submitted: true });
      //console.log('onSubmitWithReCAPTCHA')
      if ( user.username 
            && !this.isValidURL(user.username) 
            && user.password1 
            && user.password2 
            && user.email) {
          this.recaptchaRef.current.reset();
          this.recaptchaRef.current.execute();    
      }
    }
                    

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (/*user.firstName && user.lastName &&*/ user.username 
              && user.password1 
              && user.password2 
              && user.email) {
                
            dispatch(userActions.register(user));

            let tagManagerArgs = {
              dataLayer: {
                  username: user.username,
                  email: user.email,
                  page: 'RegisterAsked'
              },
              dataLayerName: 'PageDataLayer'
            }

            TagManager.dataLayer(tagManagerArgs)
/*             ReactGA.event({
              category: 'User',
              action: 'Created User'
            }); */
        }
    }


    onCaptchaChange(value) {
      //console.log("Captcha value:", value);

      //if (value){
        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;
  
        if (  user.username
              && !this.isValidURL(user.username) 
              && user.password1 
              && user.password2 
              && user.email) {
            dispatch(userActions.register(user));
  
            let tagManagerArgs = {
              dataLayer: {
                  username: user.username,
                  email: user.email,
                  page: 'RegisterAsked'
              },
              dataLayerName: 'PageDataLayer'
            }
  
            TagManager.dataLayer(tagManagerArgs)
  /*             ReactGA.event({
              category: 'User',
              action: 'Created User'
            }); */
        }
      //}

    }


    render() {
        const { registering,alert,intl,dispatch } = this.props;
        const { user, submitted, is_invited } = this.state;
        return (
              <Grid
                textAlign='center'
                style={{ height: '100%'}}
                verticalAlign='middle'>
                <Grid.Row columns={1} only='tablet mobile' style={{'padding-bottom':0, 'padding-left':30,'padding-right':10}} >
                  <Grid.Column style={{ top: "5%", maxWidth: 450,minHeight:'100vh' }}>
                  <br/>
                    <Header as='h4' align='left'>
                     <Icon name="world" style={{color:'#4286e3'}} size='large'/>{' '}
                     <Header.Content>
                     <Dropdown text={intl.locale}
                        inline
                        upward={true}
                        style={{color:'#4286e3'}}>
                        <Dropdown.Menu>
                        <Dropdown.Item text='Français' icon={<Flag name='fr' />} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR + '/register'} size='large'/>
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                         selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN + '/register'}  size='large'/>
                        </Dropdown.Menu>
                      </Dropdown>
                     </Header.Content>
                   </Header><br/><br/>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {' '}{!is_invited && <FormattedMessage id="register.form.title"
                                             defaultMessage='Register and start for free'/>}
                           {is_invited && <FormattedMessage id="register.form.invitation.title"
                                                           defaultMessage='Register to join your team'/>}
                    </Header>
{/*                     {alert.message && alert.type == alertConstants.ERROR && 
                      <Header as='h4' color='red' textAlign='center'>
                          <div className={`alert ${alert.type}`}>{alert.message}</div>
                      </Header>
                     } */}
                    <Helmet>
{/*                       <title>Inscrivez-vous sur Ratatool et gagnez en rentabilité et productivité</title>
                      <meta name="description" content="Calculez votre coût matière avec le mercuriale d'ingrédients de vos recettes, estimez vos marges, réduisez vos pertes, éditez vos fiches techniques et vos étiquettes nutritionnelles."/> */}
                      <title>{intl.formatMessage(html_tags.html_title)}</title>
                      <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
                      { intl.locale == 'fr' &&
                          <link rel="canonical" href="https://fr.ratatool.com/register" />}

                      { intl.locale == 'en' &&
                            <link rel="canonical" href="https://www.ratatool.com/register" />}
                            
                      <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/register" />
                      <link rel="alternate" hreflang="en" href="https://www.ratatool.com/register" />
                      <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/register" />
                     
                    </Helmet>
                    <Form size='large' onSubmit={this.onSubmitWithReCAPTCHA} /*onSubmit={this.handleSubmit}*/>
                     {/*  <Segment stacked> */}
   {/*                      <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Firstname (optional)'
                          type='text'
                          name='firstname'
                          value={user.firstname}
                          onChange={this.handleChange} 
                        />
                      {submitted && !user.firstname &&
                          <div className="help-block">Firstname is required</div>
                        } 
                        <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Lastname (optional)'
                          type='text'
                          name='lastname'
                          value={user.lastname}
                          onChange={this.handleChange} 
                        />
                       {submitted && !user.lastname &&
                          <div className="help-block">Lastname is required</div>
                        } */}
                        <FormattedMessage id="register.form.email.placeholder"
                                            defaultMessage='Email'>
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='email'
                                    name='email'
                                    value={user.email}
                                    onChange={this.handleChange} 
                                    error={submitted && !user.email && 
                                      { content: <FormattedMessage id="register.form.email.error.message"
                                                                   defaultMessage='Email is required'/>}}
                                    />}
                         </FormattedMessage>
{/*                         {submitted && !user.email &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.email.error.message"
                                                defaultMessage='Email is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="register.form.password.placeholder"
                                            defaultMessage='Password'>
                            {placeholder => 
                                    <Form.Input
                                      fluid
                                      icon='lock'
                                      iconPosition='left'
                                      placeholder={placeholder}
                                      type='password'
                                      name='password1'
                                      value={user.password1}
                                      onChange={this.handleChange} 
                                      error={submitted && (!user.password1 || user.password1.length>100) &&
                                        { content: <FormattedMessage id="register.form.password.error.message"
                                                                     defaultMessage='Password is required (or invalid)'/>}}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !user.password1 &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.password.error.message"
                                                defaultMessage='Password is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="register.form.password2.placeholder"
                                            defaultMessage='Confirm Password'>
                            {placeholder => 
                                    <Form.Input
                                      fluid
                                      icon='lock'
                                      iconPosition='left'
                                      placeholder={placeholder}
                                      type='password'
                                      name='password2'
                                      value={user.password2}
                                      onChange={this.handleChange} 
                                      error={submitted && (!user.password2 || user.password2.length>100) &&
                                        { content: <FormattedMessage id="register.form.password2.error.message"
                                                                     defaultMessage='Password is required (or invalid)'/>}}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !user.password2 &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.password2.error.message"
                                                defaultMessage='Password is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="register.form.username.placeholder"
                                            defaultMessage='Username'>
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='user circle'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='text'
                                    name='username'
                                    value={user.username}
                                    onChange={this.handleChange} 
                                    error={submitted && (!user.username || this.isValidURL(user.username)) &&
                                      { content: <FormattedMessage id="register.form.username.error.message"
                                                                   defaultMessage='Username is required (or invalid)'/>}}
                                    />}
                        </FormattedMessage>
{/*                         {submitted && !user.username &&
                         <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.username.error.message"
                                                defaultMessage='Username is required'/> 
                           </i></label>
                        } */}
                        <br/>
                        <Button color='blue' fluid size='large'>
                          <FormattedMessage id="register.form.button.register"
                                             defaultMessage='Register'/> 
                        </Button>
                        {registering && 
                              <img alt='load' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          }
                          <Link to="/login" className="btn btn-link">
                              <FormattedMessage id="register.form.button.cancel"
                                                defaultMessage='Cancel'/> 
                          </Link>
                      {/* </Segment> */}
                    </Form>
                    <Message>
                        <FormattedMessage id="register.form.bottom.already.registered.text"
                                           defaultMessage='Already registered ?'/><Link to="/login" style={{marginLeft:"0.5em"}}>
                                                             <FormattedMessage id="register.form.bottom.log.text"
                                                                               defaultMessage='Log here'/> </Link>
                    </Message>
  {/*                   <span>
                    <FormattedMessage id="register.form.bottom.discover.premium.text"
                                           defaultMessage='Want to become premium ?'/><Link to="/pricing" className="btn btn-link">
                                                             <FormattedMessage id="register.form.bottom.discover.premium.link"
                                                                               defaultMessage='See benefits ...'/> </Link></span> */}
                    <br/><br/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 700,  height: '100vh'}}> 
                     <img src={img_window} alt='window' style={{width: '100vh',height: '100vh'}}/>
                   </Grid.Column>
                  <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                   <br/>
                    <Header as='h4' align='left'>
                     <Icon name="world" style={{color:'#4286e3'}} size='large'/>{' '}
                     <Header.Content>
                     <Dropdown text={intl.locale}
                        inline
                        upward={true}
                        style={{color:'#4286e3'}}>
                        <Dropdown.Menu>
                        <Dropdown.Item text='Français' icon={<Flag name='fr' />} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR + '/register'} size='large'/>
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                         selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN + '/register'}  size='large'/>
                        </Dropdown.Menu>
                      </Dropdown>
                     </Header.Content>
                   </Header><br/><br/>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {' '}{!is_invited && <FormattedMessage id="register.form.title"
                                             defaultMessage='Register and start for free'/>}
                           {is_invited && <FormattedMessage id="register.form.invitation.title"
                                                           defaultMessage='Register to join your team'/>}
                    </Header>
{/*                     {alert.message && alert.type == alertConstants.ERROR && 
                      <Header as='h4' color='red' textAlign='center'>
                          <div className={`alert ${alert.type}`}>{alert.message}</div>
                      </Header>
                     } */}
                      <Form size='large' onSubmit={this.onSubmitWithReCAPTCHA} /*onSubmit={this.handleSubmit}*/>
                      {/* <Segment stacked> */}
 {/*                     <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Firstname (optional)'
                          type='text'
                          name='firstname'
                          value={user.firstname}
                          onChange={this.handleChange} 
                        />
                       {submitted && !user.firstname &&
                          <div className="help-block">Firstname is required</div>
                        }
                        <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Lastname (optional)'
                          type='text'
                          name='lastname'
                          value={user.lastname}
                          onChange={this.handleChange} 
                        />
                         {submitted && !user.lastname &&
                          <div className="help-block">Lastname is required</div>
                        } */}
                        <FormattedMessage id="register.form.email.placeholder"
                                            defaultMessage='Email'>
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='email'
                                    name='email'
                                    value={user.email}
                                    onChange={this.handleChange}
                                    error={submitted && !user.email && 
                                           { content: <FormattedMessage id="register.form.email.error.message"
                                                                        defaultMessage='Email is required'/>}}
                                    />}
                         </FormattedMessage>
{/*                          {submitted && !user.email &&
                          <label pointing style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.email.error.message"
                                                defaultMessage='Email is required'/> 
                            </i></label> */}
                        <FormattedMessage id="register.form.password.placeholder"
                                            defaultMessage='Password'>
                            {placeholder => 
                                    <Form.Input
                                      fluid
                                      icon='lock'
                                      iconPosition='left'
                                      placeholder={placeholder}
                                      type='password'
                                      name='password1'
                                      value={user.password1}
                                      onChange={this.handleChange} 
                                      error={submitted && (!user.password1 || user.password1.length>100) &&
                                        { content: <FormattedMessage id="register.form.password.error.message"
                                                                     defaultMessage='Password is required (or invalid)'/>}}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !user.password1 &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.password.error.message"
                                                defaultMessage='Password is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="register.form.password2.placeholder"
                                            defaultMessage='Confirm Password'>
                            {placeholder => 
                                    <Form.Input
                                      fluid
                                      icon='lock'
                                      iconPosition='left'
                                      placeholder={placeholder}
                                      type='password'
                                      name='password2'
                                      value={user.password2}
                                      onChange={this.handleChange} 
                                      error={submitted && (!user.password2 || user.password2.length>100) &&
                                        { content: <FormattedMessage id="register.form.password2.error.message"
                                                                     defaultMessage='Password is required (or invalid)'/> }}
                                      />}
                        </FormattedMessage>
{/*                         {submitted && !user.password2 &&
                          <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.password2.error.message"
                                                defaultMessage='Password2 is required'/> 
                            </i></label>
                        } */}
                        <FormattedMessage id="register.form.username.placeholder"
                                            defaultMessage='Username'>
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='user circle'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='text'
                                    name='username'
                                    value={user.username}
                                    onChange={this.handleChange} 
                                    error={submitted && (!user.username || this.isValidURL(user.username)) &&
                                      { content: <FormattedMessage id="register.form.username.error.message"
                                                                   defaultMessage='Username is required (or invalid)'/> }}
                                  />}
                        </FormattedMessage>
{/*                        {submitted && !user.username &&
                         <label style={{color:'red'}}><i>
                              <FormattedMessage id="register.form.username.error.message"
                                                defaultMessage='Username is required'/> 
                           </i></label>
                        } */}
                        <br/>
                        <Button color='blue' fluid size='large'>
                            <FormattedMessage id="register.form.button.register"
                                              defaultMessage='Register'/> 
                        </Button>
                        {registering && 
                              <img alt='load' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          }
                          <Link to="/login" className="btn btn-link">
                              <FormattedMessage id="register.form.button.cancel"
                                                defaultMessage='Cancel'/> 
                          </Link>
                     {/*  </Segment> */}
                    </Form>

                    <Message >
                        <FormattedMessage id="register.form.bottom.already.registered.text"
                                           defaultMessage='Already registered ?'/><Link to="/login" style={{marginLeft:"0.5em"}}>
                                                             <FormattedMessage id="register.form.bottom.log.text"
                                                                               defaultMessage='Log here'/> </Link>
                    </Message>
{/*                     <FormattedMessage id="register.form.bottom.discover.premium.text"
                                           defaultMessage='Want to become premium ?'/><Link to="/pricing" className="btn btn-link">
                                                             <FormattedMessage id="register.form.bottom.discover.premium.link"
                                                                               defaultMessage='See benefits ...'/> </Link> */}
                  </Grid.Column>
                </Grid.Row>
                <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_TOP' }}/>
                {alert.message && toaster_dispatch(dispatch,alert,intl)}
                <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey="6LctR-IZAAAAAMCPh8ZLJhoFNTuMLwbojYu7wENj"
                      size="invisible"
                      onChange={this.onCaptchaChange}
                      hl={intl.locale}
                  />
              </Grid>
          );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    const { alert ,dispatch} = state;
    return {
        registering,
        alert,
        dispatch,
    };
}

const connectedRegisterLayout = injectIntl(connect(mapStateToProps)(RegisterLayout));
export { connectedRegisterLayout as RegisterLayout };