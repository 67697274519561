export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_CONFIRM_REQUEST: 'USERS_REGISTER_CONFIRM_REQUEST',
    REGISTER_CONFIRM_SUCCESS: 'USERS_REGISTER_CONFIRM_SUCCESS',
    REGISTER_CONFIRM_FAILURE: 'USERS_REGISTER_CONFIRM_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    VERIFY_REQUEST: 'USERS_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'USERS_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'USERS_VERIFY_FAILURE',

    RENEW_REQUEST: 'USERS_RENEW_REQUEST',
    RENEW_SUCCESS: 'USERS_RENEW_SUCCESS',
    RENEW_FAILURE: 'USERS_RENEW_FAILURE',

    PWD_RESET_REQUEST: 'USERS_PWD_RESET_REQUEST',
    PWD_RESET_SUCCESS: 'USERS_PWD_RESET_SUCCESS',
    PWD_RESET_FAILURE: 'USERS_PWD_RESET_FAILURE',

    PWD_CONFIRM_REQUEST: 'USERS_PWD_CONFIRM_REQUEST',
    PWD_CONFIRM_SUCCESS: 'USERS_PWD_CONFIRM_SUCCESS',
    PWD_CONFIRM_FAILURE: 'USERS_PWD_CONFIRM_FAILURE',

    PWD_CHANGE_REQUEST: 'USERS_PWD_CHANGE_REQUEST',
    PWD_CHANGE_SUCCESS: 'USERS_PWD_CHANGE_SUCCESS',
    PWD_CHANGE_FAILURE: 'USERS_PWD_CHANGE_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    ADD_LEAD_REQUEST: 'ADD_LEAD_REQUEST',
    ADD_LEAD_SUCCESS: 'ADD_LEAD_SUCCESS',
    ADD_LEAD_FAILURE: 'ADD_LEAD_FAILURE',

    GET_ORGANISATION_REQUEST: 'GET_ORGANISATION_REQUEST',
    GET_ORGANISATION_SUCCESS: 'GET_ORGANISATION_SUCCESS',
    GET_ORGANISATION_FAILURE: 'GET_ORGANISATION_FAILURE',

    ADD_ORGANISATION_REQUEST: 'ADD_ORGANISATION_REQUEST',
    ADD_ORGANISATION_SUCCESS: 'ADD_ORGANISATION_SUCCESS',
    ADD_ORGANISATION_FAILURE: 'ADD_ORGANISATION_FAILURE',

    EDIT_ORGANISATION_REQUEST: 'EDIT_ORGANISATION_REQUEST',
    EDIT_ORGANISATION_SUCCESS: 'EDIT_ORGANISATION_SUCCESS',
    EDIT_ORGANISATION_FAILURE: 'EDIT_ORGANISATION_FAILURE',

    GET_INVITATION_REQUEST: 'GET_INVITATION_REQUEST',
    GET_INVITATION_SUCCESS: 'GET_INVITATION_SUCCESS',
    GET_INVITATION_FAILURE: 'GET_INVITATION_FAILURE',

    SEND_INVITATION_REQUEST: 'SEND_INVITATION_REQUEST',
    SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
    SEND_INVITATION_FAILURE: 'SEND_INVITATION_FAILURE',

    UPDATE_INVITATION_REQUEST: 'UPDATE_INVITATION_REQUEST',
    UPDATE_INVITATION_SUCCESS: 'UPDATE_INVITATION_SUCCESS',
    UPDATE_INVITATION_FAILURE: 'UPDATE_INVITATION_FAILURE',

    DELETE_INVITATION_REQUEST: 'DELETE_INVITATION_REQUEST',
    DELETE_INVITATION_SUCCESS: 'DELETE_INVITATION_SUCCESS',
    DELETE_INVITATION_FAILURE: 'DELETE_INVITATION_FAILURE',

/*     CONNECT_POS_REQUEST: "CONNECT_POS_REQUEST",
    CONNECT_POS_SUCCESS: "CONNECT_POS_SUCCESS",
    CONNECT_POS_FAILURE: "CONNECT_POS_FAILURE",

    GET_POS_REQUEST: "GET_POS_REQUEST",
    GET_POS_SUCCESS: "GET_POS_SUCCESS",
    GET_POS_FAILURE: "GET_POS_FAILURE",

    DELETE_POS_REQUEST: "DELETE_POS_REQUEST",
    DELETE_POS_SUCCESS: "DELETE_POS_SUCCESS",
    DELETE_POS_FAILURE: "DELETE_POS_FAILURE",

    UPDATE_POS_REQUEST: "UPDATE_POS_REQUEST",
    UPDATE_POS_SUCCESS: "UPDATE_POS_SUCCESS",
    UPDATE_POS_FAILURE: "UPDATE_POS_FAILURE", */

};