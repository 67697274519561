import React, { Component } from 'react';
import { ingredientsActions,paymentActions } from '../_actions';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Header,Message,Grid,Image,Table,Icon,Dimmer,Loader,Breadcrumb} from 'semantic-ui-react'
import SideLeftUncoverIngredient from './SidebarIngredient';
import {ResponsiveLine} from '@nivo/line';
import sortBy from 'lodash/sortBy'
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime, injectIntl} from 'react-intl';
import { history} from '../_helpers';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'


class HistoryIngredient extends Component {

    constructor(props) {
        super(props);    
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state={
                prevIngredients : props.ingredients,
                selected:null,
                chart_data:[],
                currency : userIn.user.currency
             };
        props.dispatch(ingredientsActions.get(props.match.params.ingredientId));
        props.dispatch(ingredientsActions.history_ingredient(props.match.params.ingredientId));
        props.dispatch(paymentActions.getCustomerMembership());
    }

    static getDerivedStateFromProps(props, state) {
        if(props.ingredients!==state.prevIngredients){
            if (props.ingredients.selected && props.ingredients.history){
                const selected = props.ingredients.selected;
                const history = props.ingredients.history;
                const points = [];     
                history.map((h,index) => {
                    points.push( {x: h.history_date, y: h.price})   
                });
                var sorted_points = sortBy(points,['x']); 
                sorted_points.map((p,index) => {
                     p.x = new Date(p.x.replace(/ /g, "T")).toLocaleDateString('en-GB', {  
                        day : 'numeric',
                        month : 'numeric',
                        //year : 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                });
                const values = [{ id: selected.name,
                                //color: "hsl(318, 70%, 50%)",
                                color: "hsl(337, 70%, 50%)",
                                data: sorted_points.slice(-15)}];  
                
                return { prevIngredients : props.ingredients, 
                        selected: selected,
                        history: history,
                        chart_data: values };
            }
        }
        return null;
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModal: index});
    };

    deleteClickHandlerRecipes (event,index) {
        event.preventDefault();
        this.setState({ activeModalRecipes: index});
    }; 

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ activeModalRecipes: null })
    }

    editRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/recipedetails'));
    }

    deleteRecipe (event,index) {
        event.preventDefault();
        //const { selected } = this.state;
        // -- Implementation with modals for deletion
        //this.props.dispatch(recipesActions.deletesubrecipe(selected.id,index))
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
        
    }

    editIngredient (event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
    }

    deleteIngredient (event,index) {
        event.preventDefault();
        //const { selected } = this.state;
        //this.props.dispatch(recipesActions.deleteingredient(selected.id,index))
        //history.push('/deleterecipe')
        
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

  addHistoryRows(){
    const {currency,history} = this.state;
    return history.map((i,index) => {
            return (
                <Table.Row key={index} >
                    <Table.Cell><Icon name='history' color='teal'/>
                            <FormattedMessage id="historyingredient.table.column1.row.update"
                                                         defaultMessage='Update'/>  
                    </Table.Cell>
                    <Table.Cell>
{/*                                 {new Date(i.history_date).toLocaleDateString('en-GB', {  
	                                    day : 'numeric',
	                                    month : 'short',
                                        year : 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                })} */}
                                <FormattedDate
                                               value={new Date(i.history_date.replace(/ /g, "T"))}
                                               year='numeric'
                                               month='long'
                                               day='2-digit'/> -  
                               <FormattedTime
                                               value={new Date(i.history_date.replace(/ /g, "T"))}/>
                    </Table.Cell>
{/*                     <Table.Cell >
                        <a href="#" >{Capitalize(i.name)}
                        </a>
                    </Table.Cell> */}
{/*                     <Table.Cell >
                        {i.supplier.name}
                    </Table.Cell> */}
                    <Table.Cell>
                            <FormattedNumber value={i.price} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                            {/* {intl.formatMessage({id:'currency.symbol'})} */}
                    </Table.Cell>
                    <Table.Cell>
                            {i.purchase_weight !== '' && i.purchase_weight}
                            {i.purchase_volume !== '' && i.purchase_volume}
                            {i.purchase_quantity !== '' && i.purchase_quantity}
                    </Table.Cell>
 {/*                    <Table.Cell>
                        <Popup trigger=
                                {<Button className="ui small icon button" 
                                        onClick={(event)=>this.editRecipe(event,i.id)}>
                                        <i className="edit icon"></i>
                                </Button>}
                                //header='Edit Recipe details'
                                content='Edit'
                                inverted/> 
                        <Popup trigger=
                                {<Button className="ui small icon button"><i className="download icon"></i></Button>}
                                //header='Edit Recipe details'
                                content='Download'
                                inverted/>
                        <Popup trigger=
                                {<Button id={index} className="ui small icon button" 
                                         onClick={(event) => this.deleteClickHandlerRecipes(event,index)}>
                                         <i className="trash icon"></i>
                                 </Button>}
                                //header='Delete Recipe details'
                                content='Delete'
                                inverted/> 
                        <Modal id={index}
                               open={this.state.activeModalRecipes === index}
                               dimmer='default' 
                               size='tiny' 
                               //style={inlineStyle.modal} 
                               onClose={this.hideModal}>
                            <Header icon='trash' content='Deletion confirmation' />
                            <Modal.Content>
                                <p>Really sure to delete the subrecipe "{i.name}" ? </p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={this.hideModal}>No</Button>
                                <Button positive 
                                    labelPosition='right' 
                                    content='Yes' 
                                    icon='checkmark' 
                                    onClick={(event) =>this.deleteRecipe(event,i.id)}/>
                                </Modal.Actions>
                        </Modal> 
                    </Table.Cell>*/}
                 </Table.Row>
            );
    });
}

  
  render() {
    const {loading,intl ,customer} = this.props;
    const { chart_data,isMobile,history,selected} = this.state;
    let membership = null;
    let user = null;

    const axisBottom_props={
        "orient": "bottom",
        "tickSize": 5,
        "tickPadding": 10,
        "tickRotation": 10,
        "legend": intl.formatMessage({id:"historyingredient.table.column2"}),
        "legendOffset": 45,
        "legendPosition": "center"
    };
    
    const axisBottom_props_mobile={
        "orient": "up",
        "tickSize": 5,
        "tickPadding": 10,
        "tickRotation": 45,
        "legend": "",
        "legendOffset": 5,
        "legendPosition": "left"
    };

    if (customer){
        membership = customer.membership;
        user = customer.user;
        //console.log(user);
        //console.log(membership);
    } 

    return (
    <div>
      { loading &&
        <SideLeftUncoverIngredient>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverIngredient>
      }
      { membership && user && 
            ((membership.membership_type!="Entreprise") && (user.bypass==false)) && !loading && selected && 
        <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
        <Grid stackable  /* style={styles.FontLato12} */>
            <Grid.Row columns={1}>
                    <Breadcrumb style={{background:'#fcfcfc'}}>
                              <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="historyingredient.link.dashboard"
                                                                    defaultMessage='Dashboard'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="historyingredient.link.ingredients"
                                                                  defaultMessage='Ingredients'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{selected && selected.name}</Breadcrumb.Section>   
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section active><FormattedMessage id="historyingredient.link.history"
                                                                    defaultMessage='History'/></Breadcrumb.Section>               
                    </Breadcrumb>
           </Grid.Row>
           <Grid.Row>
                <Grid.Column width={8}>
                       <Header as='h2'>
                              <Icon name='history' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="historyingredient.header.title"
                                                    defaultMessage='Ingredient changes history'/>
                                  <Header.Subheader>{selected && selected.name}</Header.Subheader>
                                </Header.Content>
                        </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                <Grid.Column /*width={8}*/  mobile={14} tablet={12} computer={8}>
                    <br/>
                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                        <Image src={img_performance} size ='small' rounded/>
                        <br/>
                        <Message.Content style={{marginLeft:'1em'}}>
                            <Message.Header color='black'>
                                <FormattedMessage id="historyingredient.premium.message.upgradeplan"
                                                    defaultMessage='ENTERPRISE Feature'/>
                            </Message.Header>
                            <br/>
                            <FormattedMessage id="historyingredient.premium.title.message.upgradeplan"
                                            defaultMessage='Your current plan: '/>{' '}{membership.name}   
                             <br/><br/>
                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                <FormattedMessage id="historyingredient.premium.button.chooseplan"
                                            defaultMessage='Choose premium plan'/>
                            </Button>                                      
                        </Message.Content>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </SideLeftUncoverIngredient>
      }   
      { membership && user && 
            (membership.membership_type=="Entreprise" || (user.bypass==true)) && !loading && selected && 
     /*  { !loading && ingredients && ingredients.selected &&  */
      <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
      <Grid stackable  style={styles.FontLato12}>
        <Grid.Row columns={1}>
                    <Breadcrumb style={{background:'#fcfcfc'}}>
                              <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="historyingredient.link.dashboard"
                                                                    defaultMessage='Dashboard'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="historyingredient.link.ingredients"
                                                                  defaultMessage='Ingredients'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{selected && selected.name}</Breadcrumb.Section>   
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section active><FormattedMessage id="historyingredient.link.history"
                                                                    defaultMessage='History'/></Breadcrumb.Section>               
                    </Breadcrumb>
           </Grid.Row>
         <Grid.Row>
          <Grid.Column>
                <Header as='h1'>
                              <Icon name='history' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="historyingredient.header.title"
                                                    defaultMessage='Ingredient changes history'/>
                                  <Header.Subheader>{selected && selected.name}</Header.Subheader>
                                </Header.Content>
                </Header>
{/*                 <Header as='h2' color='grey' textAlign='left'>
                    <FormattedMessage id="historyingredient.header.title"
                                      defaultMessage='Ingredient changes history'/>
                </Header>
                <Header as='h3' color='blue' textAlign='left'>
                    {ingredients.selected && Capitalize(ingredients.selected.name)}
                </Header> */}
{/*                 <br/>
                    <Image src="assets/red-paprika-organic-vegetable-53162.jpeg" size='medium' rounded />
                <br/> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <br/><p  style={styles.FontLato14Bold}>
                            <FormattedMessage id="historyingredient.chart.title"
                                              defaultMessage='History price chart'/></p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
             <Grid.Column style={{width:950,height:550}}>
                <ResponsiveLine
                data={chart_data}
                margin={{
                    "top": 20,
                    "right": 110,
                    "bottom": 50,
                    "left": 60
                }}
/*                 xScale={{
                    "type": "point"
                }} 
                yScale={{
                    "type": "linear",
                    "stacked": true,
                    "min": "auto",
                    "max": "auto"
                }}*/
                minY="auto"
                maxY="auto"
                stacked={true}
/*                 axisBottom={{
                    "orient": "bottom",
                    "tickSize": 5,
                    "tickPadding": 10,
                    "tickRotation": 5,
                    "legend": "date",
                    "legendOffset": 36,
                    "legendPosition": "center"
                }} */
                axisBottom={isMobile?axisBottom_props_mobile:axisBottom_props}
                axisLeft={{
                    "orient": "left",
                    "tickSize": 5,
                    "tickPadding": 5,
                    "tickRotation": 0,
                    "legend": intl.formatMessage({id:"historyingredient.table.column4"}),
                    "legendOffset": -40,
                    "legendPosition": "center"
                }}
                colors="accent"
                dotSize={10}
                dotColor="inherit:darker(0.3)"
                dotBorderWidth={2}
                dotBorderColor="#ffffff"
                enableDotLabel={true}
                dotLabel="y"
                dotLabelYOffset={-12}
                enableArea={true}
                areaOpacity={0.10}
                //areaBaselineValue={10}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        "anchor": "top-left",
                        "direction": "column",
                        "justify": false,
                        "translateX": 100,
                        "translateY": 0,
                        "itemsSpacing": 0,
                        "itemDirection": "left-to-right",
                        "itemWidth": 95,
                        "itemHeight": 20,
                        "itemOpacity": 0.75,
                        "symbolSize": 12,
                        "symbolShape": "circle",
                        "symbolBorderColor": "rgba(0, 0, 0, .5)",
                        "effects": [
                            {
                                "on": "hover",
                                "style": {
                                    "itemBackground": "rgba(0, 0, 0, .03)",
                                    "itemOpacity": 1
                                }
                            }
                        ]
                    }
                ]}
             />
           </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <p style={styles.FontLato14Bold}>
                            <FormattedMessage id="historyingredient.table.title"
                                              defaultMessage='History by updates'/>    
                </p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{'overflow-x': 'auto'}}> 
                <Grid.Column /*width={12}*/  mobile={18} tablet={16} computer={12}> 
                { selected && history && 
                    <Table selectable 
                           stackable
                           compact 
                           striped 
                           style={styles.FontLato12}
                           color='green'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <FormattedMessage id="historyingredient.table.column1"
                                                         defaultMessage='Entry'/>  
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                     <FormattedMessage id="historyingredient.table.column2"
                                                         defaultMessage='Date'/>  
                                </Table.HeaderCell>
{/*                                 <Table.HeaderCell>
                                    <FormattedMessage id="historyingredient.table.column3"
                                                            defaultMessage='Name'/>  
                                </Table.HeaderCell> */}
{/*                                 <Table.HeaderCell>
                                    <FormattedMessage id="historyingredient.table.column6"
                                                            defaultMessage='Supplier'/>  
                                </Table.HeaderCell> */}
                                <Table.HeaderCell>
                                    <FormattedMessage id="historyingredient.table.column4"
                                                            defaultMessage='Price'/>  
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage id="historyingredient.table.column5"
                                                            defaultMessage='Purchase'/>  
                                </Table.HeaderCell>
{/*                                 <Table.HeaderCell>
                                   {ingredients.selected && 
                                    <Label  color='teal'>
                                    {ingredients.selected.history.length}  update(s)
                                    </Label>}
                                </Table.HeaderCell> */}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.addHistoryRows()}
                        </Table.Body>
                    </Table>}
                </Grid.Column>
        </Grid.Row>
      </Grid>
     </SideLeftUncoverIngredient>}
    </div> 
    );
   }
}

function mapStateToProps(state) {
    const { ingredients,alert,dispatch } = state;
    const { customer } = state.payment;
    const {loading} = state.ingredients;
    return {
        ingredients,
        customer,
        loading,
        alert,
        dispatch
    };
}


const connectedHistoryIngredient = injectIntl(connect(mapStateToProps)(HistoryIngredient));
export { connectedHistoryIngredient as HistoryIngredient };
