import { supplierConstants } from '../_constants';

export function supplier(state = {}, action) {
  switch (action.type) {
    case supplierConstants.GETALL_REQUEST:
      return {
        loading_s: true
      };
    case supplierConstants.GETALL_SUCCESS:
      let options=[];
      let sorted_suppliers = action.supplier;
      if (sorted_suppliers){
        sorted_suppliers = sorted_suppliers.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }
      action.supplier.map((item,index)=>
            options[index]= {key:index,text:item.name,value:item.id});
      return {
        loading_s: false,
        items: sorted_suppliers,
        options: options

      };
    case supplierConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case supplierConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case supplierConstants.GET_SUCCESS:
      return {
        ...state,
        selected: action.supplier
      };
    case supplierConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case supplierConstants.ADD_REQUEST:
      return {
        ...state,
        adding: true
      };
    case supplierConstants.ADD_SUCCESS:

      let newItems = [...state.items, action.supplier]
      newItems  =  newItems.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      let newOptions=[];
      newItems.map((item,index)=>
        newOptions[index]= {key:index,text:item.name,value:item.id});

      return {
          ...state,
          adding: false,
          added: true,
          items: newItems,
          options: newOptions
      };
    case supplierConstants.ADD_FAILURE:
      return { 
        error: action.error
      };

    case supplierConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case supplierConstants.DELETE_SUCCESS:
      newItems = state.items.filter(supplier => supplier.id != action.id);
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: newItems,
        options: newOptions
      };
    case supplierConstants.DELETE_FAILURE:
      return {
        error: action.error
/*         ...state,
        items: state.items.map(recipe => {
          if (recipe.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...recipeCopy } = recipe;
            // return copy of user with 'deleteError:[error]' property
            return { ...recipeCopy, deleteError: action.error };
          }
          return recipe;
        }) */
      };
    case supplierConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case supplierConstants.UPDATE_SUCCESS:
      newItems = state.items.map(supplier =>  {     
            if (supplier.id === action.id) {
              return action.supplier;
            }
            return supplier;
        });
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        updating: false,
        updated: true,
        items: newItems,
        options: newOptions
      };
    case supplierConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case supplierConstants.COUNT_REQUEST:
      return {
        counting: true
      };
    case supplierConstants.COUNT_SUCCESS:
      return {
        counting: false,
        count: action.supplier

      };
    case supplierConstants.COUNT_FAILURE:
      return { 
        error: action.error
      };
  
    default:
      return state
  }
}