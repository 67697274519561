import React, { Component } from 'react';
import { ingredientsActions, paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid, Header, Image,Icon,Modal,Form,Checkbox,Breadcrumb, Segment,Label} from 'semantic-ui-react';
import SideLeftUncoverIngredients from './SidebarIngredients';
import {history} from '../_helpers';
import {FormattedMessage} from 'react-intl';
import {styles} from './Styles';
import img_antique from '../Assets/checklist.png'
import img_performance from '../Assets/performance.png'



const optionsExport = [
    { key: 1, 
        text: <FormattedMessage id="exportlist.ingredients.data.choice.elem1"
                               defaultMessage='Microsoft Excel (XLSX)'/>,
        icon: 'file excel',
       value: 'xlsx' },
    { key: 2, 
      text: <FormattedMessage id="exportlist.ingredients.format.choice.elem2"
                              defaultMessage='Comma Separated Value (CSV)'/>,
      icon: 'file alternate',
      value: 'csv' },
]


class ExportListIngredients extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            format: 'xlsx',
            activeModalPremium: false,
            withNutrition: false,   
            withAllergens: false,
            withExtraSuppliers: false  
         }

        props.dispatch(paymentActions.getCustomerMembership());
    }

  hideModal() {
    this.setState({ activeModalPremium: false });
  }

  onFormatChange = (e,data) => {
    e.preventDefault();
    this.setState({ format: data.value });
  }

  onCheckboxChange = (e, data) => {
    //console.log(data);
    if (data.id === 'nutrition')
        this.setState({ withNutrition: data.checked});
    
    if (data.id === 'allergens')
        this.setState({ withAllergens: data.checked});

    if (data.id === 'suppliers')
        this.setState({ withExtraSuppliers: data.checked});
  }

  exportIngredients () {
    const { format,withNutrition,withAllergens,withExtraSuppliers } = this.state;
    const {customer} = this.props;
    let user = null;
    let membership = null;
        
    if (customer){
        membership = customer.membership;
        user = customer.user;
        //console.log(user);
        //console.log(membership);
    }

    if (membership && user &&
        ((membership.membership_type==="Entreprise") || (membership.membership_type==="Professional")|| (user.bypass===true))) 
    {

        if (format == 'xlsx'){
            this.props.dispatch(ingredientsActions.export_xlsx({nutrition:(withNutrition?1:0),
                                                                allergen:(withAllergens?1:0),
                                                                supplier:(withExtraSuppliers?1:0)}));  
        }else{
            this.props.dispatch(ingredientsActions.export_csv({nutrition:(withNutrition?1:0),
                                                               allergen:(withAllergens?1:0),
                                                               supplier:(withExtraSuppliers?1:0)}));  
        }
       
    
    }
    else {
        this.setState({ activeModalPremium: true});
    }  
  }
 
  onSubmit = (e) => {
        //e.preventDefault();
        this.exportIngredients();
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  render() {
    const { customer,exporting } = this.props;
    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
    }

    return (
      <SideLeftUncoverIngredients>
         <Grid stackable style={styles.FontLato12}>
         <Grid.Row columns={1}>
           <Breadcrumb  style={{background:'#fcfcfc'}}>
                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="exportlist.ingredients.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron'/>
                <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="exportlist.ingredients.link.ingredients"
                                                              defaultMessage='Ingredients'/></Breadcrumb.Section>
                 <Breadcrumb.Divider icon='right chevron'/>
                <Breadcrumb.Section active><FormattedMessage id="exportlist.ingredients.link.export"
                                                              defaultMessage='Export'/></Breadcrumb.Section>
           </Breadcrumb>
          </Grid.Row>
           <Grid.Row>
               <Grid.Column width={8}>
                    <Header as='h2'>
                              <Icon name='download' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="exportlist.ingredients.header.title"
                                                    defaultMessage='Export all ingredients'/>
                                  <Header.Subheader><FormattedMessage id="exportlist.ingredients.subheader.title"
                                                    defaultMessage='Ingredients'/></Header.Subheader>
                                </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue' >
                    {selected && selected.name}
                    </Header> */}
               </Grid.Column>
           </Grid.Row>
           <Grid.Row/>
           <Grid.Row columns={2}>
              <Grid.Column /*width={5}*/ mobile={10} tablet={7} computer={5}>
                     <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label><FormattedMessage id="exportlist.ingredients.form.format.select.title"
                                                         defaultMessage='Format'/>
                                </label>
                                <FormattedMessage id="exportlist.ingredients.form.format.select.placeholder"
                                                defaultMessage='Format'>
                                    {placeholder =>   
                                            <Form.Select 
                                                    options={optionsExport}
                                                    placeholder={placeholder} 
                                                    value={this.state.format}
                                                    onChange={this.onFormatChange}
                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                            />}
                                </FormattedMessage>  
                            </Form.Field>
                        </Form.Group>
                        <p style={styles.FontLato14}>
                           <Icon color='teal' name='info circle'/>
                                         <FormattedMessage id="exportlist.ingredients.categories.message.title"
                                                                defaultMessage='Select your file export '/>
                                        <Link to={'/categoriesingredient/'}>{' '}
                                                <FormattedMessage id="exportlist.ingredients.categories.message.link"
                                                                  defaultMessage='active categories'/>
                                        </Link>{' '}
                                        <FormattedMessage id="exportlist.ingredients.categories.message.details"
                                                                defaultMessage=' if necessary.' />
                        </p>
                        <Segment secondary color='teal' raised>
                        {(membership && user && ((membership.membership_type=="Entreprise") || (user.bypass==true))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.allergens"
                                                 defaultMessage='Include allergens'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='allergens'
                                                defaultChecked = {this.state.withAllergens}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>}
                        {(membership && user && ((membership.membership_type!="Entreprise") && (user.bypass==false))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.allergens.premium"
                                                 defaultMessage='Include allergens (upgrade your plan)'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='allergens'
                                                disabled
                                                defaultChecked = {this.state.withAllergens}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>}
                        {(membership && user && ((membership.membership_type=="Entreprise") || (user.bypass==true))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.nutrition"
                                                 defaultMessage='Include nutrition values'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='nutrition'
                                                defaultChecked = {this.state.withNutrition}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>}
                        {(membership && user && ((membership.membership_type!="Entreprise") && (user.bypass==false))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.nutrition.premium"
                                                 defaultMessage='Include nutrition values (upgrade your plan)'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='nutrition'
                                                disabled
                                                defaultChecked = {this.state.withNutrition}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>}
{/*                         {(membership && user && ((membership.membership_type=="Entreprise") || (user.bypass==true))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.suppliers"
                                                 defaultMessage='Include extra suppliers'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='suppliers'
                                                defaultChecked = {this.state.withExtraSuppliers}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>}
                        {(membership && user && ((membership.membership_type!="Entreprise") && (user.bypass==false))) &&
                        <Form.Field>
                                <FormattedMessage id="exportlist.ingredients.form.options.option.suppliers.premium"
                                                 defaultMessage='Include extra suppliers (upgrade your plan)'>
                                    {label => 
                                        <Checkbox label={label}
                                                id='suppliers'
                                                disabled
                                                defaultChecked = {this.state.withExtraSuppliers}
                                                onChange={this.onCheckboxChange}/>}
                                </FormattedMessage>
                        </Form.Field>} */}
                        {(membership && user && ((membership.membership_type!="Entreprise") && (user.bypass==false))) &&
                          <Form.Field align='right'>
                              <Label color='blue' onClick={(e) => this.upgradeSubscription()} style={{'cursor': 'pointer'}}>
                              <FormattedMessage id="exportlist.ingredients.form.options.option.list.premium"
                                                 defaultMessage='ENTERPRISE options'/>
                              </Label>
                          </Form.Field>}
                        </Segment>
                        <Form.Group widths='equal'>
                          <Form.Field>
                                  <FormattedMessage id="exportlist.ingredients.form.options.option.suppliers"
                                                  defaultMessage='Include extra suppliers'>
                                      {label => 
                                          <Checkbox label={label}
                                                  id='suppliers'
                                                  defaultChecked = {this.state.withExtraSuppliers}
                                                  onChange={this.onCheckboxChange}/>}
                                  </FormattedMessage>
                          </Form.Field>
                        </Form.Group>
                        <br/><br/>
                        <Form.Group widths='equal'>
                            {exporting &&
                            <Form.Button loading style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="exportlist.ingredients.form.submit.ok"
                                                        defaultMessage="Let's go"/>}
                            </Form.Button>}
                            {!exporting &&
                            <Form.Button style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="exportlist.ingredients.form.submit.ok"
                                                        defaultMessage="Let's go"/>}
                            </Form.Button>}
                            <Form.Button style={styles.ButtonGrey} //basic color='grey'
                                    fluid  onClick={this.cancelAction}>
                                    {<FormattedMessage id="exportlist.ingredients.form.submit.cancel"
                                                       defaultMessage="Cancel"/>}
                            </Form.Button>
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="exportlist.ingredients.header.title"
                                                    defaultMessage='Export all ingredients'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="exportlist.ingredients.premium.message.upgradeplan"
                                                                defaultMessage='PREMIUM Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="exportlist.ingredients.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{customer && customer.membership && customer.membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="exportlist.ingredients.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="exportlist.ingredients.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button  color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="exportlist.ingredients.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Form.Group>
                    </Form>
             </Grid.Column>
             <Grid.Column width={5}>
                 <img alt='antique' id='test' src={img_antique}/>
             </Grid.Column>
          </Grid.Row>
        </Grid>
      </SideLeftUncoverIngredients>
    );
  }
}

function mapStateToProps(state) {
  const { dispatch,alert } = state;
  const { customer } = state.payment;
  const {exporting} = state.ingredients;
  return {
        customer,
        exporting,
        dispatch,
        alert
  };
}

const connectedExportListIngredients = connect(mapStateToProps)(ExportListIngredients);
export { connectedExportListIngredients as ExportListIngredients };
