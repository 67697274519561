import { storageAreaConstants } from '../_constants';

export function storageAreas(state = {}, action) {
  switch (action.type) {
    case storageAreaConstants.GETALL_REQUEST:
      return {
        loading_storage: true
      };
    case storageAreaConstants.GETALL_SUCCESS:
      let options=[];
      let sorted_areas = action.storage;
      if (sorted_areas){
        sorted_areas = sorted_areas.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }
      action.storage.map((item,index)=>
            options[index]= {key:index,text:item.name,value:item.id});
      return {
        loading_storage: false,
        items: sorted_areas,
        options: options

      };
    case storageAreaConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case storageAreaConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case storageAreaConstants.GET_SUCCESS:
      return {
        ...state,
        selected: action.supplier
      };
    case storageAreaConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case storageAreaConstants.ADD_REQUEST:
      return {
        ...state,
        adding: true
      };
    case storageAreaConstants.ADD_SUCCESS:

      let newItems = [...state.items, action.storage]
      newItems  =  newItems.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      let newOptions=[];
      newItems.map((item,index)=>
        newOptions[index]= {key:index,text:item.name,value:item.id});

      return {
          ...state,
          adding: false,
          added: true,
          items: newItems,
          options: newOptions
      };
    case storageAreaConstants.ADD_FAILURE:
      return { 
        error: action.error
      };

    case storageAreaConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case storageAreaConstants.DELETE_SUCCESS:
      newItems = state.items.filter(areas => areas.id != action.id);
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: newItems,
        options: newOptions
      };
    case storageAreaConstants.DELETE_FAILURE:
      return {
        error: action.error
/*         ...state,
        items: state.items.map(recipe => {
          if (recipe.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...recipeCopy } = recipe;
            // return copy of user with 'deleteError:[error]' property
            return { ...recipeCopy, deleteError: action.error };
          }
          return recipe;
        }) */
      };
    case storageAreaConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case storageAreaConstants.UPDATE_SUCCESS:
      newItems = state.items.map(areas =>  {     
            if (areas.id === action.id) {
              return action.storage;
            }
            return areas;
        });
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        updating: false,
        updated: true,
        items: newItems,
        options: newOptions
      };
    case storageAreaConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case storageAreaConstants.COUNT_REQUEST:
      return {
        counting: true
      };
    case storageAreaConstants.COUNT_SUCCESS:
      return {
        counting: false,
        count: action.areas

      };
    case storageAreaConstants.COUNT_FAILURE:
      return { 
        error: action.error
      };
  
    default:
      return state
  }
}