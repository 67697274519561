import { categoryIngredientConstants } from '../_constants';

export function categoriesIngredient(state = {}, action) {
  switch (action.type) {
    case categoryIngredientConstants.GETALL_REQUEST:
      return {
        loading_ci: true
      };
    case categoryIngredientConstants.GETALL_SUCCESS:
      let options=[];

      let sorted_categories = action.categoriesIngredient;
      if (sorted_categories){
        sorted_categories = sorted_categories.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }

      sorted_categories.map((item,index)=>
            options[index]= {key:index,text:item.name,value:item.id});
/*       options.push({key:options.length,
                    icon:{name:'add',color:'blue'},
                    text:'Add Category',
                    value:'add'}) */
      return {
        loading_ci: false,
        items: sorted_categories,
        options: options

      };
    case categoryIngredientConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case categoryIngredientConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryIngredientConstants.GET_SUCCESS:
      return {
        ...state,
        selected: action.ingredients
      };
    case categoryIngredientConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case categoryIngredientConstants.ADD_REQUEST:
      return {
        ...state,
        adding: true
      };
    case categoryIngredientConstants.ADD_SUCCESS:

      let newItems = [...state.items, action.categoriesIngredient]
      newItems  =  newItems.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })

      let newOptions=[];
      newItems.map((item,index)=>
        newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
          ...state,
          adding: false,
          added: true,
          items: newItems,
          options: newOptions
      };
    case categoryIngredientConstants.ADD_FAILURE:
      return { 
        error: action.error
      };

    case categoryIngredientConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case categoryIngredientConstants.DELETE_SUCCESS:
      newItems = state.items.filter(category => category.id != action.id);
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: newItems,
        options: newOptions
      };
    case categoryIngredientConstants.DELETE_FAILURE:
      return {
        error: action.error
/*         ...state,
        items: state.items.map(recipe => {
          if (recipe.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...recipeCopy } = recipe;
            // return copy of user with 'deleteError:[error]' property
            return { ...recipeCopy, deleteError: action.error };
          }
          return recipe;
        }) */
      };
    case categoryIngredientConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case categoryIngredientConstants.UPDATE_SUCCESS:
      newItems = state.items.map(category =>  {     
            if (category.id === action.id) {
              return action.categoriesIngredient;
            }
            return category;
        });
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        updating: false,
        updated: true,
        items: newItems,
        options: newOptions
      };
    case categoryIngredientConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case categoryIngredientConstants.COUNT_REQUEST:
      return {
        counting: true
      };
    case categoryIngredientConstants.COUNT_SUCCESS:
      return {
        counting: false,
        count: action.categoriesIngredient

      };
    case categoryIngredientConstants.COUNT_FAILURE:
      return { 
        error: action.error
      };
  
    default:
      return state
  }
}