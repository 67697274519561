
import React, { Component } from 'react'
import {
  Button,
  Grid,
  Header,
  Icon,
  Segment,
  Card,
  Label,
  Checkbox
} from 'semantic-ui-react';

import { connect } from 'react-redux';
import { paymentActions } from '../_actions';
import {HashLink as Link} from 'react-router-hash-link';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';




class PremiumLayout extends Component {

  constructor(props) {
      super(props);   
      let userIn = JSON.parse(localStorage.getItem('userIn'));
      const {location} = props;
      //console.log(location)
      this.state={
            //labelAnnually : <FormattedMessage id="premiumlayout.segment.billing.type.annually"
            //                                  defaultMessage="Annual billing"/>,
            //labelMonthly : <FormattedMessage id="premiumlayout.segment.billing.type.monthly"
            //                                  defaultMessage="Monthly billing"/>,
            toggled : location.state.toggled,
            userIn : userIn,
            org_member: userIn.user.active_org?true:false, 
            isMobile: false,
      };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    //const { userIn } = this.state;
     //let user = JSON.parse(localStorage.getItem('userIn'));
/*      if (userIn && userIn.token){
        dispatch(userActions.verify(userIn.token));
     } */

     dispatch(paymentActions.getAvailableMemberships());
     dispatch(paymentActions.getCustomerMembership());
     dispatch(paymentActions.getAvailableExtraMemberships());
     //dispatch(paymentActions.getSubscription());
     dispatch(paymentActions.getCustomerCoupons());
     window.addEventListener("resize", this.resize.bind(this));
     this.resize();
  }

  resize() {
    this.setState({isMobile: window.innerWidth <= 1024});
  }

/*   toggleCheckBox= (e, data) => {
    //console.log(data);
    this.setState({toggled:data.checked});
  } */

  togglePeriodicity= (e) => {
    const {toggled} = this.state;
    this.setState({toggled:!toggled});
  }

  render() {
    const {memberships,extra_memberships} = this.props.payment;
    const {intl,coupons,payment,isMobile,customer} =  this.props;
    const {toggled,userIn,org_member} = this.state;
    //let toggle_label =toggled ?intl.formatMessage({id:'premiumlayout.segment.billing.type.annually'})
    //                          :intl.formatMessage({id:'premiumlayout.segment.billing.type.monthly'});

    let pricingBoxHeight = isMobile?'720px':'720px';
    let pricingBoxHeightStandard = isMobile?'600px':'500px';
    let pricingBoxHeightExtra = isMobile?'100px':'100px';
    let consistMemberships =[];
    let consistExtraMemberships =[];
    let used_locale = intl.locale;
    let membership = null;

    if (customer){
      membership = customer.membership;
    }

    if (memberships){

      consistMemberships =  memberships.filter( m => {
              return m.interval == (toggled?'Annually':'Monthly');
      });

      if (userIn && payment && payment.customer){
         consistMemberships =  consistMemberships.filter( m => {
                  used_locale = (payment.customer.currency == 'EUR'?'fr':'en');
                  return m.country == used_locale;
          });
      }else{
          consistMemberships =  consistMemberships.filter( m => {
                return m.country == used_locale;
        });
      }
      //console.log(consistMemberships)
    }

    if (extra_memberships){

      consistExtraMemberships =  extra_memberships.filter( m => {
              return m.interval == (toggled?'Annually':'Monthly');
      });

      if (userIn && payment && payment.customer){
        consistExtraMemberships =  consistExtraMemberships.filter( m => {
                  used_locale = (payment.customer.currency == 'EUR'?'fr':'en');
                  return m.country == used_locale;
          });
      }else{
        consistExtraMemberships =  consistExtraMemberships.filter( m => {
                return m.country == used_locale;
        });
      }
      //console.log(consistMemberships)
    }

    return (
        <SidebarProfile >
          <Segment vertical compact>
            <Grid  container stackable verticalAlign='middle' textAlign='center' columns={3} >
                 <br/><br/><br/>
                    <Header  as='h1' textAlign='center'>
                          <FormattedMessage id="premiumlayout.segment.top.title"
                                            defaultMessage="Plans & Pricing"/>
                    </Header>
              <br/><br/><br/>
              <Grid.Row/>
              { (consistMemberships.length > 0) &&
              <Grid.Row>
                <Grid.Column /*width={6}*/  mobile={10} tablet={8} computer={6} fluid >
                 <Button.Group >
                  <Button primary={!toggled} 
                            onClick={this.togglePeriodicity}>
                      <FormattedMessage id="premiumlayout.segment.billing.type.monthly"
                                          defaultMessage="Monthly billing"/></Button>
                      <FormattedMessage id="premiumlayout.segment.billing.separate.or"
                                        defaultMessage="or">
                        {inside => <Button.Or text={inside}/>}
                      </FormattedMessage>
                    <Button primary={toggled} 
                            onClick= {this.togglePeriodicity}> 
                        <FormattedMessage id="premiumlayout.segment.billing.type.annually"
                            defaultMessage="Annual billing"/></Button>
                  </Button.Group>
{/*                    <Segment style={{ fontSize: '1.2em', backgroundColor: '#f5f5f5' }}>
                           <Checkbox  toggle
                                      label={toggle_label}
                                      //defaultChecked='false'
                                      checked={toggled}
                                      style={{ fontSize: '1em', fontWeight:'700'}}
                                      onChange= {this.toggleCheckBox}/> 
                       
                    </Segment> */}
                    <br/> <br/>
                    {toggled && <p>
                    <span style={{ fontSize: '1.33em',color:'#fffff'}}>
                            <FormattedMessage id="premiumlayout.segment.billing.offer.annually.title"
                                              defaultMessage="Available immediately with"/>{' '}
                    </span>
                    <span style={{ fontSize: '1.33em',color:'#f58d37'}}>
                            <FormattedMessage id="premiumlayout.segment.billing.offer.annually.message"
                                              defaultMessage="2 months FREE"/>
                    </span></p>}
                    {!toggled && <p>
                    <span style={{ fontSize: '1.33em',color:'#fffff'}}>
                            <FormattedMessage id="premiumlayout.segment.billing.offer.monthly.title"
                                              defaultMessage="Available immediately"/></span>
                    </p>}
                    { coupons && coupons.length == 1 && <p>
                    <span style={{ fontSize: '1.33em',color:'#528deb'}}>
                            <FormattedMessage id="premiumlayout.segment.billing.offer.coupon.title"
                                              defaultMessage="Coupon discount applied"/></span>
                    </p>}
{/*                     <p><span style={{ fontSize: '1.2em',color:'#fffff', fontWeight:'800'}}>
                          <FormattedMessage id="premiumlayout.segment.billing.description"
                                            defaultMessage="No contract, cancel anytime"/>
                    </span></p> */}
                </Grid.Column>
              </Grid.Row>}
              {intl.locale=='fr' && <Grid.Row>
                  <p style={{ fontSize: '1.13em' }}>
                      {'Financer intégralement votre abonnement avec le chèque numérique en France ('}
                   <a href='https://www.iledefrance.fr/un-cheque-numerique-pour-des-commerces-connectes' target="_blank" >
                       en île de France
                   </a>{' et '}
                   <a href='https://www.francenum.gouv.fr/comprendre-le-numerique/quelles-sont-les-aides-financieres-pour-la-numerisation-de-votre-entreprise' 
                      target="_blank">
                       autres régions
                   </a>{')'}</p> 
              </Grid.Row>}
              { (consistMemberships.length > 0) &&
              <Grid.Row columns={3} style={{ padding: '4em 0em 4em 0em' }}>
{/*                   <Grid.Column mobile={6} tablet={5} computer={4} fluid>
                      <Card as={Link}

                            to={{pathname: '/storeplan', state: {type: consistMemberships[0].membership_type,
                                                                name: consistMemberships[0].name,
                                                                interval: consistMemberships[0].interval,
                                                                price : consistMemberships[0].price,
                                                                bill_locale : used_locale
                            }}}
                           style={{ backgroundColor: '#fcfcfc'}} fluid > 
                              <Card.Content>
                                  <Label color='grey' align='center' size='huge' fluid>{consistMemberships[0].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.2em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="premiumlayout.plan.one.summary"
                                                        defaultMessage="Gives you a free trial"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                  <Card.Header>
                                      <FormattedMessage id="premiumlayout.plan.one.line.amount"
                                                        defaultMessage="Free"/>
                                      <p style={{ fontSize: '0.5em' }}>
                                                <FormattedMessage id="premiumlayout.plan.one.line.info"
                                                      defaultMessage="No credit card required"/>
                                      </p>
                                  </Card.Header>
                              </Card.Content>
                              <Card.Content textAlign='left' style={{ fontSize: '1em',height:pricingBoxHeight}}>
                                  <p><Icon name='check' color='grey'/>
                                      <FormattedMessage id="premiumlayout.plan.one.line.feature1.text"
                                                        defaultMessage="3 Recipes & 10 Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                     <FormattedMessage id="premiumlayout.plan.one.line.feature8.text"
                                                        defaultMessage="Professional recipe forms"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature4.text"
                                                        defaultMessage="Sub-Recipes"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature3.text"
                                                        defaultMessage="Detailed Cost Overviews"/>
                                  </p> 
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature5.text"
                                                        defaultMessage="Margin calculations"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p><Icon name='check' color='grey'/>
                                    <FormattedMessage id="premiumlayout.plan.one.line.feature13.text"
                                                        defaultMessage="Inventory management"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                  <Button fluid color='grey'>
                                      <FormattedMessage id="premiumlayout.plan.one.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column> */}
                                     <Grid.Column /*width={5}*/    mobile={7} tablet={6} computer={5} fluid>
                      <Card as={Link} 
                            to={{pathname: '/storeplan', state: {type: consistMemberships[2].membership_type,
                                                                name: consistMemberships[2].name,
                                                                interval: consistMemberships[2].interval,
                                                                price : consistMemberships[2].price,
                                                                bill_locale : used_locale
                              }}}
                            /*to={'/storeplan/'+ consistMemberships[2].membership_type +'/'
                                            + consistMemberships[2].name +'/'
                                            + consistMemberships[2].interval +'/'
                                            + consistMemberships[2].price} */
                            style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='orange' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[2].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="premiumlayout.plan.three.summary"
                                                        defaultMessage="Further with production planning, menus, tracking and nutrition. Manage multiple companies, work with your teammates"/>
                                  </span></p>
                                  <Label color='teal' size='large' style={{ fontSize: '0.8em', marginBottom: '0.4em' }}>
                                              <Icon name='bolt' color='yellow'/>
                                              <FormattedMessage id="premiumlayout.plan.three.summary.popular"
                                                                defaultMessage="POPULAR"/>
                                  </Label>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        { !toggled &&
                                             <div>
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                               <FormattedNumber value={consistMemberships[2].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>
                                               {/*  <FormattedNumber value={consistMemberships[2].price}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
{/*                                               <span style={{ fontSize: '0.65em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                            
                                        }
                                       {  toggled &&
                                          <div>
                                             {(!coupons || coupons.length == 0) &&
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                                  <FormattedNumber value={consistMemberships[2].price/12} 
                                                  style="currency" 
                                                  minimumFractionDigits={(consistMemberships[2].price/12) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(consistMemberships[2].price/12 )% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={(used_locale == "fr"?'EUR':'USD')}/>
                                                </span>
                                               }
                                            {coupons && coupons.length == 1 && 
                                              <span style={{ fontSize: '1em', color:'#5BA3FF' }}>
                                               <strike><FormattedNumber value={consistMemberships[2].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[2].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[2].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/></strike><br/>
                                               <FormattedNumber value={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount))/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>}
                                              {/* <FormattedNumber value={consistMemberships[1].price/12}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                              </p>
{/*                                               <span style={{ fontSize: '0.65em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="premiumlayout.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                        }
                                    </Card.Header>
                                     { !toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="premiumlayout.plan.monthly.three.line.coupon.message"
                                                        defaultMessage="First month at"/>{' '}
                                          <FormattedNumber value={Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                                    { toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="premiumlayout.plan.annually.three.line.coupon.message"
                                                        defaultMessage="Price discount of"/>{' '}
                                          <FormattedNumber value={Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                              </Card.Content>
{/*                               <Card.Content >
{/*                                   <p style={{ fontSize: '1.13em' }}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.description"
                                                        defaultMessage="Fits growing businesses"/>
                                  </p>
                                  <br/>
                                <Button basic color='black'>
                                      <FormattedMessage id="pricing.plan.three.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button>
                              </Card.Content> */}
                               <Card.Content textAlign='left' style={{ height:pricingBoxHeight}}>
                                <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes, Sub-Recipes & Ingredients"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                     <FormattedMessage id="premiumlayout.plan.three.line.feature11.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
{/*                                   <p style={{fontSize:'14px', 'fontWeight':'600'}}><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature4.text"
                                                        defaultMessage="Sub-Recipes"/>
                                  </p> */}
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature2.text"
                                                          defaultMessage="Loss and Cooking Variation"/></p> 
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature14.text"
                                                        defaultMessage="Menus and Combos Unlimited"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature8.text"
                                                        defaultMessage="History Tracking"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title1"
                                                        defaultMessage="PRODUCTION & ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="premiumlayout.plan.three.line.feature23.text"
                                                        defaultMessage="Production Planning"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature21.text"
                                                        defaultMessage="Recipes Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="premiumlayout.plan.three.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="premiumlayout.plan.three.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature9.text"
                                                        defaultMessage="Nutrition Calculator & Facts"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title4"
                                                        defaultMessage="TEAMWORK AND SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature19.text"
                                                        defaultMessage="Multi-organization(s)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature20.text"
                                                        defaultMessage="Team work with user permissions"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature22.text"
                                                        defaultMessage="Advanced customize options (brand logo)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature10.text"
                                                        defaultMessage="Exports PDF, CSV, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature17.text"
                                                        defaultMessage="Phone Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='orange'>
                                      <FormattedMessage id="premiumlayout.plan.three.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  <Grid.Column /*width={5}*/  mobile={7} tablet={6} computer={5} fluid>
                      <Card as={Link} 
                            to={{pathname: '/storeplan', state: {type: consistMemberships[1].membership_type,
                                                                  name: consistMemberships[1].name,
                                                                  interval: consistMemberships[1].interval,
                                                                  price : consistMemberships[1].price,
                                                                  bill_locale : used_locale
                             }}}
/*                           to={'/storeplan/'+ consistMemberships[1].membership_type +'/'
                              + consistMemberships[1].name +'/'
                              + consistMemberships[1].interval +'/'
                              + consistMemberships[1].price} */
                            style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='black' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[1].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="premiumlayout.plan.two.summary"
                                                        defaultMessage="Essentials to manage your food costs and recipes"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        {  !toggled &&
                                             <div>
                                               <span style={{ fontSize: '1.3em' , color:'#5BA3FF'}}>
                                               <FormattedNumber value={consistMemberships[1].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>
                                               {/*  <FormattedNumber value={consistMemberships[1].price}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="premiumlayout.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                                </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="premiumlayout.plan.two.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
                                            </div>
                                            
                                        }
                                        {  toggled &&
                                          <div>
                                              {(!coupons || coupons.length == 0) &&
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                                  <FormattedNumber value={consistMemberships[1].price/12} 
                                                  style="currency" 
                                                  minimumFractionDigits={(consistMemberships[1].price/12) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(consistMemberships[1].price/12 )% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={(used_locale == "fr"?'EUR':'USD')}/>
                                                </span>
                                              }
                                              {coupons && coupons.length == 1 && 
                                              <span style={{ fontSize: '1em' , color:'#5BA3FF'}}>
                                               <strike><FormattedNumber value={consistMemberships[1].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[1].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[1].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/></strike><br/>
                                               <FormattedNumber value={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount))/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>}
                                              {/* <FormattedNumber value={consistMemberships[1].price/12}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em' , color:'#5BA3FF'}}>
                                                <FormattedMessage id="premiumlayout.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="premiumlayout.plan.two.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                              </p>
                                            </div>
                                        }
                                    </Card.Header>
                                    { !toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="premiumlayout.plan.monthly.two.line.coupon.message"
                                                        defaultMessage="First month at"/>{' '}
                                          <FormattedNumber value={Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                                    { toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="premiumlayout.plan.annually.two.line.coupon.message"
                                                        defaultMessage="Price discount of"/>{' '}
                                          <FormattedNumber value={Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }                                    
                              </Card.Content>
 {/*                              <Card.Content >
                                  <p style={{ fontSize: '1.13em' }}>
                                      <FormattedMessage id="premiumlayout.plan.two.line.description"
                                                        defaultMessage="Fits all businesses"/>
                                  </p>
                                  <br/>
                                  <Button basic color='orange'  as={Link} to='/register'>
                                      <FormattedMessage id="pricing.plan.two.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button> 
                              </Card.Content>*/}
                               <Card.Content textAlign='left' style={{ height:pricingBoxHeightStandard}}>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes & Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="premiumlayout.plan.two.line.feature8.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="premiumlayout.plan.two.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>                 
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="premiumlayout.plan.two.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="premiumlayout.plan.two.line.feature14.text"
                                                        defaultMessage="Menus and Combos (2 max)"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature.title6"
                                                        defaultMessage="ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="premiumlayout.plan.two.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="premiumlayout.plan.two.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="premiumlayout.plan.two.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature.title5"
                                                        defaultMessage="SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature10.text"
                                                        defaultMessage="Exports PDF, CSV, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="premiumlayout.plan.two.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='black'>
                                      <FormattedMessage id="premiumlayout.plan.two.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  { (consistExtraMemberships.length > 0) && (membership && membership.membership_type=="Entreprise") && org_member &&

                    <Grid.Column /*width={5}*/  mobile={7} tablet={6} computer={5} fluid>
                    <Card as={Link} 
                          to={{pathname: '/storeplan', state: {type: consistExtraMemberships[0].membership_type,
                                                                name: consistExtraMemberships[0].name,
                                                                interval: consistExtraMemberships[0].interval,
                                                                price : consistExtraMemberships[0].price,
                                                                bill_locale : used_locale
                          }}}
                          style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                            <Card.Content>
                                <Label color='blue' style={{ fontSize: '1.5em' }} fluid>+ EXTRA option</Label>
                                <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                    <FormattedMessage id="premiumlayout.plan.extra.summary"
                                                      defaultMessage="Add extra users on your organization to extend your team"/>
                                </span></p>
                            </Card.Content>
                            <Card.Content style={{ fontSize: '1.33em' }}>
                                  <Card.Header>
                                      {  !toggled &&
                                          <div>
                                            <span style={{ fontSize: '1.2em' , color:'#5BA3FF'}}>
                                            <FormattedNumber value={consistExtraMemberships[0].price} 
                                                              style="currency"
                                                              minimumFractionDigits={consistExtraMemberships[0].price % 1 === 0 ? 0 : 2}
                                                              maximumFractionDigits={consistExtraMemberships[0].price % 1 === 0 ? 0 : 2}
                                                              currencyDisplay="symbol" 
                                                              currency={(used_locale == "fr"?'EUR':'USD')}/>
                                            </span>
                                            {' '}
                                            <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                              <FormattedMessage id="premiumlayout.plan.extra.line.amount.mode.month"
                                                    defaultMessage="per month per 3 users"/>
                                              </span>
                                            <p style={{ fontSize: '0.55em' }}>
                                              <FormattedMessage id="premiumlayout.plan.extra.line.billing.mode.month"
                                                    defaultMessage="billed monthly"/>
                                            </p>
                                          </div>
                                      }
                                      {  toggled &&
                                        <div>
                                          <span style={{ fontSize: '1.2em', color:'#5BA3FF' }}>
                                                  <FormattedNumber value={consistExtraMemberships[0].price/12} 
                                                  style="currency" 
                                                  minimumFractionDigits={(consistExtraMemberships[0].price/12) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(consistExtraMemberships[0].price/12 )% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={(used_locale == "fr"?'EUR':'USD')}/>
                                                </span>
                                            {' '}
                                            <span style={{ fontSize: '0.6em' , color:'#5BA3FF'}}>
                                              <FormattedMessage id="premiumlayout.plan.extra.line.amount.mode.month"
                                                    defaultMessage="per month per 3 users"/>
                                            </span>
                                            <p style={{ fontSize: '0.55em' }}>
                                              <FormattedMessage id="premiumlayout.plan.extra.line.billing.mode.annual"
                                                    defaultMessage="billed annually"/>
                                            </p>
                                          </div>
                                      }
                                  </Card.Header>                                  
                            </Card.Content>
                            <Card.Content textAlign='left' style={{ height:pricingBoxHeightExtra}}>
                                <p style={{color:'black',fontSize:'14px', 'fontWeight':'900'}}>
                                    <FormattedMessage id="premiumlayout.plan.extra.line.feature.title0"
                                                      defaultMessage="Extend your team members"/>
                                </p>
                                <p><Icon name='check' color='blue'/>
                                    <FormattedMessage id="premiumlayout.plan.extra.line.feature1.text"
                                                      defaultMessage="Select the amount of extra users"/>
                                </p>
{/*                                 <p><Icon name='check' color='blue'/>
                                  <FormattedMessage id="premiumlayout.plan.extra.line.feature8.text"
                                                      defaultMessage="Professional Recipe Forms"/>
                                </p> */}
                            </Card.Content>
                            <Card.Content>
                              <Button fluid color='blue'>
                                    <FormattedMessage id="premiumlayout.plan.extra.line.button.select"
                                                      defaultMessage="Select"/>
                                </Button>
                            </Card.Content>
                    </Card>
                    </Grid.Column>
                  }
              </Grid.Row>}
              <br/><br/> <br/>
            </Grid>
          </Segment>
     </SidebarProfile>
    )
  }
}

function mapStateToProps(state) {
    const { alert,dispatch,payment} = state;
    const { coupons,customer } = state.payment;
    return {
        payment,
        customer,
        coupons,
        alert,
        dispatch
    };
}

const connectedPremiumLayout = injectIntl(connect(mapStateToProps)(PremiumLayout));
export { connectedPremiumLayout as PremiumLayout };