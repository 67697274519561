import { paymentConstants } from '../_constants';
import { paymentService } from '../_api';
import { alertActions } from './';
import { history } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const paymentActions = {
    getSubscription,
    getSubscriptionState,
    getSubscriptionExtraUsers,
    subscribe,
    unsubscribe,
    getAvailableMemberships,
    getAvailableExtraMemberships,
    getCustomerMembership,
    getCustomerInvoices,
    getCustomerCharges,
    renderInvoice,
    updateCard,
    sendVoucher,
    validateVoucher,
    getCustomerCoupons,
    activateOfferVoucher
};


function getSubscription() {
    return dispatch => {
        dispatch(request());

        paymentService.getSubscription()
            .then(
                subscription => dispatch(success(subscription)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_REQUEST } }
    function success(subscription) { return { type: paymentConstants.GET_SUCCESS, subscription } }
    function failure(error) { return { type: paymentConstants.GET_FAILURE, error } }
}


function getSubscriptionState() {
    return dispatch => {
        dispatch(request());

        paymentService.getSubscriptionState()
            .then(
                subscription => dispatch(success(subscription)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_REQUEST } }
    function success(subscription) { return { type: paymentConstants.GET_SUCCESS, subscription } }
    function failure(error) { return { type: paymentConstants.GET_FAILURE, error } }
}

function getSubscriptionExtraUsers() {
    return dispatch => {
        dispatch(request());

        paymentService.getSubscriptionExtraUsers()
            .then(
                subscription => dispatch(success(subscription)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_EXTRA_REQUEST } }
    function success(subscription) { return { type: paymentConstants.GET_EXTRA_SUCCESS, subscription } }
    function failure(error) { return { type: paymentConstants.GET_EXTRA_FAILURE, error } }
}

function getCustomerMembership() {
    return dispatch => {
        dispatch(request());

        paymentService.getCustomerMembership()
            .then(
                customer => dispatch(success(customer)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_MEMBERSHIP_REQUEST } }
    function success(customer) { return { type: paymentConstants.GET_MEMBERSHIP_SUCCESS, customer } }
    function failure(error) { return { type: paymentConstants.GET_MEMBERSHIP_FAILURE, error } }
}


function subscribe(user_infos) {
    return dispatch => {
        dispatch(request());

        paymentService.subscribe(user_infos)
            .then(
                subscription => {
                    dispatch(success(subscription));
                    let title_text = <FormattedMessage id="payment.actions.subscribe.success.title"
                                                    defaultMessage="Subscription Action"/>
                    let content_text = <FormattedMessage id="payment.actions.subscribe.success.content"
                                                        defaultMessage="Subscription Created Successfully"/>

                  

                    if (subscription.status == 'active'){

                        dispatch(alertActions.success({title: "payment.actions.subscribe.success.title",
                                                      content:"payment.actions.subscribe.success.content"}));
                        //history.push('/');
                        console.log('payment_action success >> status active')
                        history.push('/subscriptions');

                    }else if (subscription.status == 'incomplete' ){
                        if (subscription.payment_intent_status == 'requires_action'){
                            //Manage 3D secure page    
                            console.log('SUBSCRIPTION >> requires_action') 
                            //console.log(user_infos) 
                            history.push('/scacheck',{client_secret:subscription.payment_intent_client_secret,
                                                      last_intent_infos:user_infos});
                            
                        }else if (subscription.payment_intent_status == 'requires_payment_method'){
                                //Enter new payment method
                                console.log('SUBSCRIPTION >> requires_payment_method') 
                                dispatch(alertActions.error({title:'Payment Error',//title: error.statusText,
                                                            content:"New payment method required",
                                                            stringMode: true })); 
                            } 
                    }else if (subscription.status == 'past_due'){
                        console.log('SUBSCRIPTION >> past_due') 
                        // To be checked 
                        history.push('/scacheck',{client_secret:subscription.payment_intent_client_secret,
                                                  last_intent_infos:user_infos});
                    }

                },
                error => {
                    dispatch(failure(error))
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title:'Payment Error',//title: error.statusText,
                                                content:error.message,
                                                stringMode: true })); 
                    //dispatch(alertActions.error({title:'Payment Action',content:'Something went wrong with payment (contact us)'}));
                }
            );
    };

    function request() { return { type: paymentConstants.PUT_REQUEST } }
    function success(subscription) { return { type: paymentConstants.PUT_SUCCESS, subscription } }
    function failure(error) { return { type: paymentConstants.PUT_FAILURE, error } }
}


// prefixed function name with underscore because delete is a reserved word in javascript
function unsubscribe() {
    return dispatch => {
        dispatch(request());

        paymentService.unsubscribe()
            .then(
                subscription => { 
                    dispatch(success(subscription));
                    let title_text = <FormattedMessage id="payment.actions.unsubscribe.success.title"
                                                       defaultMessage="Subscription Action"/>
                    let content_text = <FormattedMessage id="payment.actions.unsubscribe.success.content"
                                                         defaultMessage="Subscription Canceled Successfully"/>

                    dispatch(alertActions.success({title: "payment.actions.unsubscribe.success.title",
                                                   content:"payment.actions.unsubscribe.success.content"}));
                    history.push('/');
                    history.push('/subscriptions');
                },
                error => {
                    dispatch(failure(error.response.statusText));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: error.statusText,
                                                content:error.message,
                                                stringMode: true }));
                }
            );
    };

    function request() { return { type: paymentConstants.DELETE_REQUEST } }
    function success(subscription) { return { type: paymentConstants.DELETE_SUCCESS, subscription } }
    function failure(error) { return { type: paymentConstants.DELETE_FAILURE, error } }
}


function updateCard(pay_infos) {
    return dispatch => {
        dispatch(request());

        paymentService.updateCard(pay_infos)
            .then(
                customer => {
                    dispatch(success(customer));
                    let title_text = <FormattedMessage id="payment.actions.customer.success.title"
                                                    defaultMessage="Customer Action"/>
                    let content_text = <FormattedMessage id="payment.actions.customer.success.content"
                                                        defaultMessage="Customer Created Successfully"/>

                    dispatch(alertActions.success({title: "payment.actions.customer.success.title",
                                                   content:"payment.actions.customer.success.content"}));
                    history.push('/');
                    history.push('/profile');
                },
                error => {
                    dispatch(failure(error))
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: error.statusText,
                                                content:error.message,
                                                stringMode: true }));
                    //dispatch(alertActions.error({title:'Payment Action',content:'Something went wrong with payment (contact us)'}));
                }
            );
    };

    function request() { return { type: paymentConstants.CUSTOMER_UPDATE_REQUEST } }
    function success(customer) { return { type: paymentConstants.CUSTOMER_UPDATE_SUCCESS, customer } }
    function failure(error) { return { type: paymentConstants.CUSTOMER_UPDATE_FAILURE, error } }
}

function getAvailableMemberships() {
    return dispatch => {
        dispatch(request());

        paymentService.getAvailableMemberships()
            .then(
                memberships => dispatch(success(memberships)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_MEMBERSHIPS_REQUEST } }
    function success(memberships) { return { type: paymentConstants.GET_MEMBERSHIPS_SUCCESS, memberships } }
    function failure(error) { return { type: paymentConstants.GET_MEMBERSHIPS_FAILURE, error } }
}


function getAvailableExtraMemberships() {
    return dispatch => {
        dispatch(request());

        paymentService.getAvailableExtraMemberships()
            .then(
                memberships => dispatch(success(memberships)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_EXTRA_MEMBERSHIPS_REQUEST } }
    function success(memberships) { return { type: paymentConstants.GET_EXTRA_MEMBERSHIPS_SUCCESS, memberships } }
    function failure(error) { return { type: paymentConstants.GET_EXTRA_MEMBERSHIPS_FAILURE, error } }
}


function getCustomerInvoices() {
    return dispatch => {
        dispatch(request());

        paymentService.getCustomerInvoices()
            .then(
                invoices => dispatch(success(invoices)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_INVOICES_REQUEST } }
    function success(invoices) { return { type: paymentConstants.GET_INVOICES_SUCCESS, invoices } }
    function failure(error) { return { type: paymentConstants.GET_INVOICES_FAILURE, error } }
}



function getCustomerCharges() {
    return dispatch => {
        dispatch(request());

        paymentService.getCustomerCharges()
            .then(
                charges => dispatch(success(charges)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_CHARGES_REQUEST } }
    function success(charges) { return { type: paymentConstants.GET_CHARGES_SUCCESS, charges } }
    function failure(error) { return { type: paymentConstants.GET_CHARGES_FAILURE, error } }
}


function renderInvoice(properties) {
    return dispatch => {
        dispatch(request());

        paymentService.renderInvoice(properties)
            .then(
                invoice => {
                    dispatch(success(invoice));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.RENDER_INVOICE_REQUEST } }
    function success(invoice) { return { type: paymentConstants.RENDER_INVOICE_SUCCESS, invoice } }
    function failure(error) { return { type: paymentConstants.RENDER_INVOICE_FAILURE, error } }
}


function sendVoucher(params) {
    return dispatch => {
        dispatch(request());

        paymentService.sendVoucher(params)
            .then(
                voucher => dispatch(success(voucher)),
                error => {
                    //console.log(error.message)
                    dispatch(failure(error.message));
/*                     dispatch(alertActions.error({title: error.statusText,
                                                content:error.message.message,
                                                stringMode: true })); */
                }
            );
    };

    function request() { return { type: paymentConstants.SEND_VOUCHER_REQUEST } }
    function success(voucher) { return { type: paymentConstants.SEND_VOUCHER_SUCCESS, voucher } }
    function failure(error) { return { type: paymentConstants.SEND_VOUCHER_FAILURE, error } }
}


function validateVoucher(params) {
    return dispatch => {
        dispatch(request());

        paymentService.validateVoucher(params)
            .then(
                voucher => dispatch(success(voucher)),
                error => {
                    //console.log(error.message)
                    dispatch(failure(error.message));
/*                     dispatch(alertActions.error({title: error.statusText,
                                                 content:error.message,
                                                 stringMode: true })); */
                }
            );
    };

    function request() { return { type: paymentConstants.VALIDATE_VOUCHER_REQUEST } }
    function success(voucher) { return { type: paymentConstants.VALIDATE_VOUCHER_SUCCESS, voucher } }
    function failure(error) { return { type: paymentConstants.VALIDATE_VOUCHER_FAILURE, error } }
}


function activateOfferVoucher(params) {
    return dispatch => {
        dispatch(request());

        paymentService.activateOfferVoucher(params)
            .then(
                voucher => {
                    dispatch(success(voucher));
                    console.log(params)
                },
                error => {
                    //console.log(error.message)
                    dispatch(failure(error.message));
                
                     if ("message" in error) {
                           dispatch(alertActions.error(<FormattedMessage id="payment.actions.activatevoucher.error.message.text"
                                                                         defaultMessage="{message}"
                                                                         values={{message:error.message}}/>));
                     }else{
                           dispatch(alertActions.error(<FormattedMessage id="payment.actions.activatevoucher.error.message.default"
                                                                         defaultMessage="Unable validate this voucher"/>));
                     }

                }
            );
    };

    function request() { return { type: paymentConstants.ACTIVATE_VOUCHER_REQUEST } }
    function success(voucher) { return { type: paymentConstants.ACTIVATE_VOUCHER_SUCCESS, voucher } }
    function failure(error) { return { type: paymentConstants.ACTIVATE_VOUCHER_FAILURE, error } }
}


function getCustomerCoupons() {
    return dispatch => {
        dispatch(request());

        paymentService.getCustomerCoupons()
            .then(
                coupons => dispatch(success(coupons)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: paymentConstants.GET_COUPONS_REQUEST } }
    function success(coupons) { return { type: paymentConstants.GET_COUPONS_SUCCESS, coupons } }
    function failure(error) { return { type: paymentConstants.GET_COUPONS_FAILURE, error } }
}