import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Button,Container,Grid,Header,Icon,Image,List,Menu,Responsive,Segment,Sidebar,Visibility,Dropdown,Flag,Step} from 'semantic-ui-react';
import Carousel from 'react-bootstrap/lib/Carousel';
import { connect } from 'react-redux';
import { alertActions,blogActions} from '../_actions';
import { serverConstants} from '../_constants';
import ButterToast from 'butter-toast';
import {toaster_dispatch} from '../_helpers';
//import {Contact} from './Contact';
import {HashLink as Link} from 'react-router-hash-link';
import {FormattedMessage,injectIntl} from 'react-intl';
import {Helmet} from "react-helmet";

import img_ipad from '../Assets/ipad.jpg'
import img_macbook_fr from '../Assets/macbook_ratatool_fr.png'
import img_macbook_en from '../Assets/macbook_ratatool_en.png'
import img_logo from '../Assets/ratatool-logo.png'
import img_chef from '../Assets/chef2.png'
import img_graph from '../Assets/graph2.png'
import img_nutrition from '../Assets/NutritionComplete.png'
import img_nutrition_fr from '../Assets/NutritionComplete_fr.png'
import img_heartbeat from '../Assets/heartbeat2.png'
import img_clipboard from '../Assets/clipboard2.png'
import img_printerIcon from '../Assets/printerIcon2.png'
import img_antique from '../Assets/checklist.png'

import img_costchart_fr from '../Assets/CostChart_fr.png'
import img_costchart_en from '../Assets/CostChart_en.png'
import img_tracking_fr from '../Assets/Tracking_fr.png'
import img_tracking_en from '../Assets/Tracking_en.png'

import img_contact from '../Assets/growth.png'
import img_timemanagement from '../Assets/time-management2.png'
import img_storage from '../Assets/storage.png'
import img_growth2 from '../Assets/growth2.png'
//import img_speech from '../Assets/speech.png'
import img_gluten from '../Assets/gluten.png'

import img_order from '../Assets/receipt.png'
import img_box from '../Assets/boxes.png'

import img_menu from '../Assets/menu.png'
import img_settings from '../Assets/setting.png'
import img_stats from '../Assets/stats.png'
import img_business from '../Assets/business.png'
import img_help from '../Assets/message.png'
import img_upload from '../Assets/upload.png'
import img_devices from '../Assets/devices2.png'
import img_mobileDevice_fr from '../Assets/mobileDevice_fr.png'
import img_mobileDevice_en from '../Assets/mobileDevice_en.png'
import img_globe from '../Assets/globe.png'
import img_cloud from '../Assets/cloud-computing.png'
import img_planningFull from '../Assets/planningFull.jpg'
import img_planning from '../Assets/planning.png'

import {styles} from './Styles';
//import Redirect from 'react-router-dom/Redirect';
import './General.css';

const inlineImageStyle = {
  img:{
    /*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(40%)',
        '-moz-filter': 'brightness(40%)',
  },  
};

const inlineImageStyle2 = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(97%)',
        '-moz-filter': 'brightness(97%)',
  },  
};

const inlineImageStyle3 = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(92%)',
        '-moz-filter': 'brightness(92%)',
  },  
};

const inlineImageStyle4 = {
  img:{
/*         'filter': 'brightness(50%)', */
        '-webkit-filter': 'brightness(85%)',
        '-moz-filter': 'brightness(85%)',
  },  
};

/* var textStyle = {
  position: 'absolute', 
  top: '50%', 
  left: '50%'
}

const HomepageHeading = ({ mobile }) => (
        <div>
             <img style={{'-webkit-filter': 'brightness(70%)','-moz-filter': 'brightness(70%)',}}
                  width={'100%'} height={'100%'} src={img_ipad} alt='ratatool rentabilité coût matière commande'/>
             <div   style={{  height: '100%',
                              display: 'flex',
                              'flex-direction': 'column',
                              'justify-content': 'flex-start',
                              'align-items': 'center',
                              //padding: '40px'
                              }}>
               <h1 style={{color:'white',
                          fontSize: mobile ? '1.4em' : '3em',
                          fontWeight: 'normal',
                          position: 'absolute',
                          top: mobile ? '1%':'20%', 
                        // 'z-index': '999',
                        // 'margin': '0 auto',
                         //</div></div>left:  mobile ? '20%':'20%'
                      }}>
                    <FormattedMessage id="homepage.carousel.title.image1"
                        defaultMessage="Bring productivity and profitability to your cuisine"/>
              </h1>
              <h2 style={{color:'#a4b9db',
                        fontSize: mobile ? '1em' : '1.9em',
                        fontWeight: 'normal',
                        position: 'absolute', 
                        top: mobile ? '5%':'30%', 
                        left: '20%'}}>
                    <FormattedMessage id="homepage.carousel.title2.image1"
                        defaultMessage="Create your recipes professional forms, your suppliers orders and calculate your recipe costs"/>

              </h2>
              <p style={{color:'white',
                        fontSize: mobile ? '0.75em' : '1.6em',
                        position: 'absolute', 
                        top: mobile ? '10%':'50%', 
                        left: '20%'}}>
                    <FormattedMessage id="homepage.carousel.subtitle.image1"
                        defaultMessage="Grow easy and fast, keep focus on business creativity"/>
              </p>
              </div>
        </div>
) */


const textArray = [ <FormattedMessage id="homepage.carousel.title.list.text1"
                                      defaultMessage="restaurant cuisine"/> ,
                    <FormattedMessage id="homepage.carousel.title.list.text2"
                                      defaultMessage="caterer service"/> ,
                    <FormattedMessage id="homepage.carousel.title.list.text3"
                                      defaultMessage="bakery"/> ,
                    <FormattedMessage id="homepage.carousel.title.list.text4"
                                      defaultMessage="pastry shop"/> ,
                    <FormattedMessage id="homepage.carousel.title.list.text5"
                                      defaultMessage="dark kitchen"/> ,
                    <FormattedMessage id="homepage.carousel.title.list.text6"
                                      defaultMessage="hotel restaurant"/>/* ,
                    <FormattedMessage id="homepage.carousel.title.list.text7"
                                      defaultMessage="culinary school"/> */ ]

const HomepageHeading = ({ mobile, title }) => (
    <Carousel controls={false} indicators={false} slide={false} >
          <Carousel.Item indicators={false}>
          {!mobile && 
            <div style={inlineImageStyle.img}>  
              <img width={'100%'} height={'100%'} src={img_ipad} alt='ratatool rentabilité coût matière commande'/>
            </div>}
            {mobile && 
            <div style={inlineImageStyle.img}>  
              <img width={'200%'} height={'200%'} src={img_ipad} alt='ratatool rentabilité coût matière commande'/>
            </div>}
            {!mobile && <Carousel.Caption style={{top: '1%'}}>
              <Header as='h1' style={{color:'white',
                        fontSize: '2.9vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '2em'
                        }}>
                    <FormattedMessage id="homepage.carousel.title.start.image1"
                        defaultMessage="Productivity and profitability for your"/>{' '}<br/>
                    <span style={{color:'#63a4dd',
                        fontSize: '3.2vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '2em'
                        }}>{title}{' '}{/* <FormattedMessage id="homepage.carousel.title.end.image1"
                        defaultMessage="restaurant cuisine"/> */}</span>
              </Header>
              <Header as='h2' style={{color:'white',
                        fontSize: '1.5vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '1.8em'
                        }}>
                    <FormattedMessage id="homepage.carousel.title2.image1"
                        defaultMessage="Digitize your recipes professional forms"/>
                    <br/>
                    <FormattedMessage id="homepage.carousel.title3.image1"
                        defaultMessage="Drive your costs calculations and your performance"/>
                    <br/>
                    <FormattedMessage id="homepage.carousel.title4.image1"
                        defaultMessage="Manage your productions, supplies, inventory and food traceability"/>
              </Header>
             {/*  <div><br/><br/></div> */}
              <Header as='h3'
                      style={{color:'white',
                        fontSize: '1.1vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '2em'}}>
             <img width={'50px'} height={'auto'} src={img_business} style={inlineImageStyle2.img}/><br/>
                    <FormattedMessage id="homepage.carousel.title4.image1.start"
                        defaultMessage="Efficiency and collaborative multi-site work"/>
                     <br/>
                     {' '}
                      <FormattedMessage id="homepage.carousel.title4.image1.end"
                        defaultMessage="to make you save money"/>
              </Header>
              <br/><br/>
              <Button primary as={Link} to="/register" style={styles.ButtonHomeCarousel}>
                 <FormattedMessage id="homepage.segment.one.button.text"
                                   defaultMessage="Start for free"/>
              </Button>
            </Carousel.Caption>}
            {mobile && <Carousel.Caption style={{top: '2%',paddingTop: '2em'/*,width:'100%','max-width': '100%',left:0, 'background-color': '#ffb81c;'*/}}>
              <Header as='h1' style={{color:'white',
                        fontSize: '6.2vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        paddingTop: '0em'
                        }}>
                    <FormattedMessage id="homepage.carousel.title.start.image1"
                        defaultMessage="Productivity and profitability for your"/>{' '}
                    <span style={{color:'#63a4dd',
                        fontSize: '7vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '0em'
                        }}>{title}{' '}{/* <FormattedMessage id="homepage.carousel.title.end.image1"
                        defaultMessage="restaurant cuisine"/> */}</span>
              </Header>
              <Header as='h2' style={{color:'white',
                        fontSize: '3.4vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '0.8em'
                        }}>
                    <FormattedMessage id="homepage.carousel.title2.image1"
                        defaultMessage="Digitize your recipes professional forms"/>
                    <br/>
                    <FormattedMessage id="homepage.carousel.title3.image1"
                        defaultMessage="Drive your costs calculations and your performance"/>
                    <br/>
                    <FormattedMessage id="homepage.carousel.title4.image1"
                        defaultMessage="Manage your productions, supplies, inventory and food traceability"/>
              </Header>
              <Header as='h3'
                      style={{color:'white',
                        fontSize: '2.8vw',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: '0.8em'}}>
             <img width={'25px'} height={'auto'} src={img_business} style={inlineImageStyle2.img}/><br/>
                    <FormattedMessage id="homepage.carousel.title4.image1.start"
                        defaultMessage="Efficiency and collaborative multi-site work"/>
                     <br/>
                     {' '}
                      <FormattedMessage id="homepage.carousel.title4.image1.end"
                        defaultMessage="to make you save money"/>
              </Header>
              {/* <br/> */}
              <Button primary as={Link} to="/register" style={styles.ButtonHomeCarouselMobile}>
                 <FormattedMessage id="homepage.segment.one.button.text"
                                   defaultMessage="Start for free"/>
              </Button>
            </Carousel.Caption>}
          </Carousel.Item>
    </Carousel>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}


/* const localeOptions = [
  {
    key: 'fr',
    text: 'Français',
    value: 'fr',
    image: <Flag name='fr'/>,
  },
  {
    key: 'en',
    text: 'English',
    value: 'en',
    image: <Flag name='us'/>,
  },
] */




class DesktopContainer extends Component {
  constructor(props) {
    super(props);
    this.state= {titleIndex: 0}
    //props.dispatch(blogActions.getHomepagePosts(props.intl.locale));
 }

  componentDidMount() {
/*      let user = JSON.parse(localStorage.getItem('userIn'));
     if (user && user.token){
        this.props.dispatch(userActions.verify(user.token));
     } */

     window.addEventListener("resize", this.resize.bind(this));
     this.resize();

     document.body.style.userSelect = 'none';
     document.body.style.WebkitUserSelect = 'none';
     document.body.style.MozUserSelect = 'none';
     document.body.style.MsUserSelect = 'none';

     this.timeout = setInterval(() => {
      let currentIndex = this.state.titleIndex;
      this.setState({ titleIndex: currentIndex + 1 });
    }, 1700);

  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }

  componentWillUnmount(){
    document.body.style.userSelect = 'text';
    document.body.style.WebkitUserSelect = 'text';
    document.body.style.MozUserSelect = 'text';
    document.body.style.MsUserSelect = 'text';
  }

  resize() {
    this.setState({mobile: window.innerWidth <= 500});
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })


  render() {

    const { children,loggedIn,alert,dispatch,intl } = this.props;
    const {mobile} = this.state;
    const { fixed } = this.state;
    let textThatChanges = textArray[this.state.titleIndex % textArray.length];

    return (
      <Responsive minWidth={1150/*Responsive.onlyComputer.minWidth*/}>
        <Visibility once={false} 
                    onBottomPassed={this.showFixedMenu} 
                    onBottomPassedReverse={this.hideFixedMenu}
                    >
          <Segment textAlign='center' 
                    style={{ height:85, width:'100vw', padding: '1em 0em 1em 0em', border:'none' }} 
                    vertical> 
              <Menu borderless 
                attached={!fixed}
                fixed={fixed ? 'top' : null}
                //inverted={fixed}
                pointing={!fixed}
                secondary={!fixed}
                size='huge'
                style={{border:'none'}}
                >
                <Container style={{width: '1400px',border:'none'}}>
                  {/* <Menu.Item header > */}
                  <a href="/">
                     {/*  <img alt='ratatool logo' src={img_logo} style={{ marginRight: '1.5em' , width: '168px', height: '49px'  }}/> */}
                      <img alt='ratatool logo' src={img_logo} style={{ marginRight: '1.5em' , width: '150px', height: '43px'  }}/>
                 </a>
                 {/*  </Menu.Item> */}
                  <Menu.Item/>
{/*                    <Menu.Item as={Link} to='/' >
                      <FormattedMessage id="homepage.top.menu.home"
                                        defaultMessage="Home"/>
                  </Menu.Item> */}
                  <Menu.Item as={Link} to='/overview' >                      
                          <FormattedMessage id="homepage.top.menu.overview"
                                            defaultMessage="Features"/>
                  </Menu.Item>
{/*                    <Menu.Item as={Link} to='/dashboard' >                      
                          <FormattedMessage id="homepage.top.menu.dashboard"
                                            defaultMessage="Dashboard"/>
                  </Menu.Item> */}
                  <Menu.Item as={Link} to='/pricing' >                      
                          <FormattedMessage id="homepage.top.menu.pricing"
                                            defaultMessage="Plans & Pricing"/>
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown inline
                              pointing ='top left'
                              compact
                              style={{color:'black'}}
                              text={<FormattedMessage id="homepage.top.menu.resource"
                                                      defaultMessage="Resources"
                                    />}
                              >
                      <Dropdown.Menu >
                      {/* <Dropdown.Header>{'        '}</Dropdown.Header> */}
                      <Dropdown.Item  as={Link} to='/blog'> <Icon name='blogger b' color='blue'/> <FormattedMessage id="homepage.top.menu.blog"
                                                defaultMessage="Blog"/></Dropdown.Item>
                      <Dropdown.Item  as={Link} to='/contact'><Icon name='comment alternate outline' color='blue'/><FormattedMessage id="homepage.top.menu.about"
                                                defaultMessage="Contact"/></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
{/*                   <Menu.Item as={Link} to='/blog' >                      
                            <FormattedMessage id="homepage.top.menu.blog"
                                              defaultMessage="Blog"/>
                  </Menu.Item>
                  <Menu.Item as={Link} to='/contact' >                      
                            <FormattedMessage id="homepage.top.menu.about"
                                              defaultMessage="Contact"/>
                  </Menu.Item> */}
                  <Menu.Item>
                    <span>
                      <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                      <Dropdown text={intl.locale}
                          inline
                          //upward={true}
                          pointing ='top left'
                          compact
                          style={{color:'#4286e3'}}
                          //value = {intl.locale}
                          //options ={localeOptions}
                          >
                          <Dropdown.Menu>
                          <Dropdown.Item text='Français' icon={<Flag name='fr'/>} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR} />
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>} 
                                        selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN}/>
                          </Dropdown.Menu>
                        </Dropdown>
                    </span>
                  </Menu.Item>
                  <Menu.Item position='right'>
                  {loggedIn && <Link to="/dashboard">
                                <FormattedMessage id="homepage.top.menu.dashboard"
                                                                  defaultMessage="Dashboard"/> &#8594;
                              </Link>}
                  {!loggedIn && <Button basic compact  as={Link} to="/login" >
                                    <FormattedMessage id="homepage.top.menu.login"
                                                      defaultMessage="Log In"/>
                                </Button>}
                  {!loggedIn && <Button as={Link} to="/register" compact secondary={true} style={{ marginLeft: '0.5em', backgroundColor:'#63a4dd' }}>

                                <FormattedMessage id="homepage.top.menu.signup"
                                                      defaultMessage="Sign Up Free"/>
                                </Button>}
                  </Menu.Item>
                </Container>
              </Menu>
            </Segment>
            {/*  <Segment inverted textAlign='center' style={{ minHeight:700, padding: '1em 0em' }} vertical> */}
            { mobile && <HomepageHeading  mobile title={textThatChanges}/>}
            { !mobile && <HomepageHeading title={textThatChanges}/>}
             {/* </Segment> */}
        </Visibility> 
        {alert.message && toaster_dispatch(dispatch,alert,intl)}
        {children}
      </Responsive>
    )
  }
}


DesktopContainer.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.bool
}



const ConnectedDesktopContainer = injectIntl(connect(mapStateToProps)(DesktopContainer));




class MobileContainer extends Component {
  state = {titleIndex: 0}

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozkitUserSelect = 'none';
    document.body.style.MsUserSelect = 'none';

    this.timeout = setInterval(() => {
      let currentIndex = this.state.titleIndex;
      this.setState({ titleIndex: currentIndex + 1 });
    }, 1700);
 }

 componentWillUnmount(){
  document.body.style.userSelect = null;
  document.body.style.WebkitUserSelect = null;
  document.body.style.MozkitUserSelect = null;
  document.body.style.MsUserSelect = null;
 }

 componentDidUnmount() {
  clearInterval(this.timeout);
}

 resize() {
   this.setState({mobile: window.innerWidth <= 500});
 }

  handlePusherClick = () => {
    const { sidebarOpened } = this.state

    if (sidebarOpened) this.setState({ sidebarOpened: false })
  }

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })


  render() {
    const { children,loggedIn,intl } = this.props
    const { sidebarOpened,mobile } = this.state
    let textThatChanges = textArray[this.state.titleIndex % textArray.length];

    return (
      <Responsive maxWidth={1149/*Responsive.onlyTablet.maxWidth*/}>
        <Sidebar.Pushable>
          <Sidebar as={Menu} secondary vertical  size='big' animation='uncover' visible={sidebarOpened}>
               <Menu.Item as={Link} to='/'>
                      <FormattedMessage id="homepage.left.menu.mobile.home"
                                         defaultMessage="Home"/>
                </Menu.Item>
                <Menu.Item as={Link} to='/overview' >
                      <FormattedMessage id="homepage.left.menu.mobile.overview"
                                         defaultMessage="Features"/>
               </Menu.Item>
                {/* <Menu.Item as={Link} to='/dashboard' >
                      <FormattedMessage id="homepage.left.menu.mobile.dashboard"
                                         defaultMessage="Dashboard"/>
                </Menu.Item> */}
                <Menu.Item as={Link} to='/pricing'>
                      <FormattedMessage id="homepage.left.menu.mobile.pricing"
                                         defaultMessage="Plans & Pricing"/>
                </Menu.Item>
                <Menu.Item>
                    <Dropdown inline
                              pointing ='top left'
                              compact
                              style={{color:'black'}}
                              text={<FormattedMessage id="homepage.top.menu.resource"
                                                      defaultMessage="Resources"
                                    />} >
                      <Dropdown.Menu >
                      {/* <Dropdown.Header>{'        '}</Dropdown.Header> */}
                      <Dropdown.Item  as={Link} to='/blog'> <Icon name='blogger b' color='blue'/> <FormattedMessage id="homepage.top.menu.blog"
                                                defaultMessage="Blog"/></Dropdown.Item>
                      <Dropdown.Item  as={Link} to='/contact'><Icon name='comment alternate outline' color='blue'/><FormattedMessage id="homepage.top.menu.about"
                                                defaultMessage="Contact"/></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
{/*                 <Menu.Item as='a' href='/blog'>
                  <FormattedMessage id="homepage.left.menu.mobile.blog"
                                         defaultMessage="Blog"/>
                </Menu.Item>
                <Menu.Item as='a' href='/contact'>
                    <FormattedMessage id="homepage.left.menu.mobile.about"
                                         defaultMessage="Contact"/>
                </Menu.Item> */}
                <Menu.Item>
                    <span>
                      <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                      <Dropdown text={intl.locale}
                          inline
                          //upward={true}
                          pointing ='top left'
                          compact
                          style={{color:'#4286e3'}}
                          //value = {intl.locale}
                          //options ={localeOptions}
                          >
                          <Dropdown.Menu>
                            <Dropdown.Item text='Français' icon={<Flag name='fr'/>} 
                                          selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR}/>
                            <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                          selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN}/>
                          </Dropdown.Menu>
                        </Dropdown>
                    </span>
                </Menu.Item>
                <Menu.Item position='right'>
                {
                   loggedIn && <Link to="/dashboard">
                                    <FormattedMessage id="homepage.left.menu.dashboard"
                                                      defaultMessage="Dashboard"/> &#8594;
                                </Link>}
                              {/* <Button basic compact as='a'  href="/login" >
                                  <FormattedMessage id="homepage.left.menu.mobile.logout"
                                                    defaultMessage="Log Out"/>
                              </Button>} */}
                {
                  !loggedIn && <Button basic as='a'  href="/login" >
                                  <FormattedMessage id="homepage.left.menu.mobile.login"
                                                    defaultMessage="Log In"/>
                              </Button>
                }
                {
                  !loggedIn && 
                      <div>
                        <br/>
                        <Button as='a'  href="/register" secondary={true}  style={{ marginLeft: '0.5em', backgroundColor:'#63a4dd'  }}>
                                  <FormattedMessage id="homepage.left.menu.mobile.signup"
                                                  defaultMessage="Sign Up Free"/>
                        </Button>
                      </div>
                }
                </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh',width: '100%' } }>
            <Segment textAlign='center' style={{ minHeight: 50, padding: '1em 0em' }} vertical>
              <Container  style={{ width: '100%' } }>
                <Menu secondary size='big'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' size='large' />
                  </Menu.Item>
                  <Menu.Item />
                  <Menu.Item position='right' as={Link} to='/'>
                    {/* <img alt='ratatool logo' src={img_logo} style={{ width: '150px', height: '43px'  }}/> */}
                    <img alt='ratatool logo' src={img_logo} style={{ width: '135px', height: '38px'  }}/>
{/*                      {
                    loggedIn && <Button basic as='a'  href="/login" >
                                  <FormattedMessage id="homepage.top.menu.mobile.logout"
                                                 defaultMessage="Log Out"/>
                                </Button>
                    }
                    {
                    !loggedIn && <Button basic as='a'  href="/login" >
                                    <FormattedMessage id="homepage.top.menu.mobile.login"
                                                 defaultMessage="Log In"/>
                                  </Button>
                    }
                    {
                    !loggedIn && <Button as='a'  href="#" secondary={true} style={{ marginLeft: '0.5em' }}>
                                     <FormattedMessage id="homepage.top.menu.mobile.signup"
                                                 defaultMessage="Sign Up"/>
                                </Button>
                    } */}
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            {/* <Segment inverted textAlign='center' style={{ minHeight: 350, padding: '1em 0em' }} vertical> */}
              { mobile && <HomepageHeading mobile title={textThatChanges}/>}
              { !mobile && <HomepageHeading title={textThatChanges}/>}
           {/*  </Segment> */}
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.bool
}


const ConnectedMobileContainer = injectIntl(connect(mapStateToProps)(MobileContainer));

const ResponsiveContainer = ({ children }) => (
  <div>
    <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_BOTTOM'}}/> 
    <ConnectedDesktopContainer>{children}</ConnectedDesktopContainer>
    <ConnectedMobileContainer>{children}</ConnectedMobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.bool
}

//const HomepageLayout = ({intl,loggedIn,blog,dispatch}) => (
class HomepageLayout extends Component { 
  state= {}

  render() {
    const { intl,loggedIn, dispatch } = this.props;
    //const {posts} = this.props.blog;

  return (
   <ResponsiveContainer>
     <Helmet>
        { intl.locale == 'fr' &&
          <link rel="canonical" href="https://fr.ratatool.com" />}

        { intl.locale == 'en' &&
          <link rel="canonical" href="https://www.ratatool.com" />}


        <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com" />
        <link rel="alternate" hreflang="en" href="https://www.ratatool.com" />
        <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com" />
    </Helmet>
    <Segment secondary style={{ padding: '4em 0em 4em 0em',border: 'none'}} vertical>
            <Grid container stackable verticalAlign='top'>
              <Grid.Row centered>
                <Header as='h2' centered>
                        <FormattedMessage id="homepage.segment.simplify.text.title"
                                          defaultMessage="Simplify your daily work with our software"/>
                </Header>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column stretched centered floated='left' /*textAlign='justified'*/ width={5}>
                      {/*<Image centered size='tiny' src={img_timemanagement} style={inlineImageStyle3.img} 
                               alt={intl.locale == 'en'?'recipe cost profit margin tool':'fiche technique de recette'}/> */}
                      <div style={{'text-align':'center'}}>
                          <img width={'50px'} height={'auto'} src={img_timemanagement} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe cost tool and margin soft':'fiche technique de recette'}/>
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                         {/*  <Icon size='mini' name='check circle outline' color='blue'/> */}
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.simplify.description.three.text.title"
                                              defaultMessage="Time saving and modernity"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em'}}>
                        <FormattedMessage id="homepage.plan.desciption.three.text.content.line.one"
                                          defaultMessage="Forget your excel sheets for our professional software solution. Create your recipes forms, your orders lists, manage your inventory, visualize your costs, margins and ratios with our optimized recipe calculation tool."/>
                      </p>
                      
                      <a href='/overview' style={{ fontSize: '16px' }}>
                              <FormattedMessage id="homepage.plan.desciption.three.text.content.line.two"
                                                defaultMessage="Overview of the features"/>    
                      </a>
                    </Grid.Column>
                <Grid.Column stretched centered  /*textAlign='justified'*/ width={5}>
                       {/*<Image centered size='tiny' src={img_contact} style={inlineImageStyle3.img} 
                               alt={intl.locale == 'en'?'ratatool recipe calculation cost pricing':'ratatool calcul coût de revient matière recette'}/> */}
                       <div style={{'text-align':'center'}}>
                          <img width={'50px'} height={'auto'} src={img_contact} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe calculation cost pricing':'calcul coût de revient matière recette'}/>
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                        {/* <Icon size='tiny' name='check circle outline' color='blue'/> */}
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.simplify.description.two.text.title"
                                              defaultMessage="Margin and profits optimized"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em' }}>
                      <FormattedMessage id="homepage.plan.desciption.two.text.content.line.one"
                                          defaultMessage="Recipe and menu costing. Target your unprofitable recipes and menus, track your wastes and losses. Update dynamically your recipe costs for best results. Adjust to reach your profit objectives."/>
                    </p>
                    </Grid.Column>
                  <Grid.Column stretched centered floated='right'  /*textAlign='justified'*/  width={5}>
                     {/*<Image centered size='tiny' src={img_storage} style={inlineImageStyle3.img}
                              alt={intl.locale == 'en'?'ratatool recipe calculation cost pricing':'ratatool sécurité stockage données calculs'}/> */}
                      <div style={{'text-align':'center'}}>
                          <img width={'50px'} height={'auto'} src={img_storage} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe calculation cost pricing':'sécurité stockage données calculs'}/>
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                          {/* <Icon size='tiny' name='check circle outline' color='blue'/> */}
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.simplify.description.one.text.title"
                                              defaultMessage="Data availability and security"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em' }}>
                          <FormattedMessage id="homepage.plan.desciption.one.text.content.line.one"
                                            defaultMessage="Your data is available on all your devices (mobile, tablet/ipad, pc/mac). Your recipes are secured, exportable and printable, ready to share with your teams for better performance."/>
                      </p>
                      <a href='/contact' style={{ fontSize: '16px' }}>
                          <FormattedMessage id="homepage.segment.simplify.description.one.text.link"
                                                defaultMessage="Any question? Need more datails"/> 
                      </a>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
    </Segment>
    <Segment style={{ padding: '4em 0em 6em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle' >
        <Grid.Row>
          <Grid.Column stretched centered /*textAlign='justified'*/  width={7}>
{/*               <Image centered size='tiny' src={img_chef} style={inlineImageStyle3.img}
                     alt={intl.locale == 'en'?'ratatool recipe calculation cost pricing':'ratatool fiche technique recette cuisine'}/> */}
              <div style={{'text-align':'center'}}>
                      <img width={'45px'} height={'auto'} src={img_chef} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'ratatool recipe calculation cost pricing':'ratatool fiche technique recette cuisine'}/>
              </div>
              <Header as='h2' >
                    <FormattedMessage id="homepage.segment.one.left.title"
                                      defaultMessage="COMPOSE your recipes forms for detailed recipe and menu costing"/>
              </Header>
              <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.one.left.description"
                                  defaultMessage="Create your recipes based on your suppliers ingredients. Fill all details (quantities, price, instructions, allergens, weight variation ...).
                                                  Create customized categories.
                                                  Create a recipe based on another (ie sub-recipes). Edit on the fly any element to see the impact on your work."/>
              </p>
          </Grid.Column>
           <Grid.Column floated='right' width={6}>
            <div style={inlineImageStyle2.img}>  
              <Image
                rounded
                centered
                size='huge'
                src={intl.locale == 'en'?img_macbook_en:img_macbook_fr}
                alt='recipe cost tool'
              />
            </div>
          </Grid.Column>
        </Grid.Row>
         
{/*          <Grid.Row>
          <Grid.Column textAlign='center'>
            <Button size='huge' as={Link} to="/register" style={styles.ButtonBlueAsIcon}>
                 <FormattedMessage id="homepage.segment.one.button.text"
                                   defaultMessage="Let's try now"/>
            </Button>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </Segment>
    <Segment secondary style={{ padding: '4em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='left' width={7}>
          <div style={inlineImageStyle2.img}>  
            <Image
              rounded
              centered
              size='large'
              alt={intl.locale == 'en'?'restaurant recipe cost tool margin':'logiciel de fiche technique recette professionnelle'}
              src={intl.locale == 'en'?img_costchart_en:img_costchart_fr}
            />
          </div>
          </Grid.Column>
          <Grid.Column stretched floated='right'  /*textAlign='justified'*/   width={7}>
{/*             <Image centered size='tiny' src={img_growth2} style={inlineImageStyle3.img}
                    alt={intl.locale == 'en'?'ratatool recipe productivity cost pricing':'ratatool productivité commande fournisseur recette'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'45px'} height={'auto'} src={img_growth2} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'ratatool recipe productivity cost pricing':'logiciel productivité commande fournisseur recette'}/>
            </div>
            <Header as='h2'>
                    <FormattedMessage id="homepage.segment.two.right.title"
                                      defaultMessage="ANALYZE and enhance your productivity"/>
            </Header>
            <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                <FormattedMessage id="homepage.segment.two.right.description.one"
                                defaultMessage="Get your cost breakdown: the cost per ingredient of your recipe with our integrated recipe cost calculator tool. 
                                                See easily your recipes ingredients and sub-recipes list with associated cost per unit (or per yield). "/>
            </p>
            <p style={{ fontSize: '18px' }}>  
               <FormattedMessage id="homepage.segment.two.right.description.two"
                                defaultMessage="A pie chart summarize your recipe costs."/>
            </p>
           {/*  <Image centered size='tiny' src={img_growth}/> */}
             <Header as='h2' >
                  <FormattedMessage id="homepage.segment.seven.right.title"
                                    defaultMessage="DRIVE your profitability"/>
              </Header>
              <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                        <FormattedMessage id="homepage.segment.seven.right.description.one"
                                          defaultMessage="Fill in your desired selling price and visualize automatically your detailed profit margins and your raw food cost per recipe. Identify your unprofitable recipes, your wastes and loss." />
              </p>
              <a href='/overview' style={{ fontSize: '18px' }}>
                             <FormattedMessage id="homepage.segment.seven.right.description.two"
                                               defaultMessage="Overview of the features"/>
              </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
{/*     <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
            <Grid container stackable  verticalAlign='middle' textAlign='center' >
                <Grid.Row>
                    <Grid.Column floated='left' width={7}>
                       <div>  
                            <img
                            rounded
                            //centered
                            //size='huge'
                            style={{width:'600px'}}
                            src={img_dashboard_marge}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched textAlign='justified' width={6}>
                        <Image size='tiny' src={img_growth}/>
                        <Header as='h3'>
                                <FormattedMessage id="homepage.segment.seven.right.title"
                                                defaultMessage="Evaluez votre rentabilité"/>
                        </Header>
                        <p style={{ fontSize: '1.1em' }}>
                            <FormattedMessage id="homepage.segment.seven.right.description.one"
                                            defaultMessage="Renseignez le prix de vente envisagé et visualisez pour chaque cas vos taux de marge, votre coût matière et votre coût aliments" />
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
         </Segment> */}
    <Segment style={{padding: '4em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column stretched  floated='right'  /*textAlign='justified'*/ width={7}>
{/*             <Image centered size='tiny' src={img_graph} style={inlineImageStyle3.img}
                    alt={intl.locale == 'en'?'ratatool tracking price cost history':'ratatool historique graphique des prix ingrédients'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'45px'} height={'auto'} src={img_graph} style={inlineImageStyle3.img} 
                            alt={intl.locale == 'en'?'ratatool tracking price cost history':'ratatool historique graphique des prix ingrédients'}/>
            </div>                    
            <Header as='h2'>
                   <FormattedMessage id="homepage.segment.three.left.title"
                                      defaultMessage="TRACK your history"/>
            </Header>
            <p style={{ fontSize: '18px' }}>
                    <FormattedMessage id="homepage.segment.three.left.description"
                                      defaultMessage="Track your supplier price cost and take the right decision in case of seasonal price variations or in times of inflation.
                                                      Be notified when it reaches a critical threshold."/>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' style={{ padding: '0em 0em 0em 6em' }}  width={8}>
            <Image
              rounded
              centered
              size='large'
              alt={intl.locale == 'en'?'ratatool tracking price cost history':'ratatool historique graphique des prix ingrédients'}
              src={intl.locale == 'en'?img_tracking_en:img_tracking_fr}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment secondary style={{ padding: '4em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='right' width={3}>
            <div style={inlineImageStyle2.img}>  
              <Image
                rounded
                centered
                size='medium'
                src={img_nutrition}
                alt='ratatool recette valeurs nutritionnelles INCO'
              /></div>
          </Grid.Column>
          <Grid.Column floated='left' width={3}>
            <div style={inlineImageStyle2.img}>  
              <Image
              rounded
              centered
              size='medium'
              alt='ratatool recipe nutrition tags labels USDA'
              src={img_nutrition_fr}
            /></div>
          </Grid.Column>
          <Grid.Column stretched  /*textAlign='justified'*/  floated='right' width={6}>
 {/*           <Grid.Row style={{ padding: '0em 0em 0em 8em' }} >
               <Image centered size='tiny' src={img_heartbeat} style={inlineImageStyle3.img}
                      alt={intl.locale == 'en'?'ratatool recipe nutrition tags labels USDA':'ratatool recette valeurs nutritionnelles INCO'} />
              <Image centered size='tiny' src={img_clipboard} style={inlineImageStyle3.img}
                      alt={intl.locale == 'en'?'ratatool recipe nutrition tags labels USDA':'ratatool recette nutrition traçabilité'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'45px'} height={'auto'} src={img_heartbeat} style={inlineImageStyle3.img} 
                            alt={intl.locale == 'en'?'ratatool recipe nutrition tags labels USDA':'ratatool recette valeurs nutritionnelles INCO'}/>
                      <img width={'45px'} height={'auto'} src={img_clipboard} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe nutrition tags labels USDA':'ratatool recette nutrition traçabilité'}/>
                      <img width={'45px'} height={'auto'} src={img_gluten} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe nutrition allergens tags USDA':'ratatool recette nutrition allergènes traçabilité'}/>
            </div>                        
            {/* </Grid.Row> */}
            {/* <Grid.Row> */}
              <Header as='h2' >
                      <FormattedMessage id="homepage.segment.four.right.title"
                                        defaultMessage="TRACEABILITY : allergens, nutrition, expiration dates, batch number"/>
              </Header>
              <br/>
              <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                <FormattedMessage id="homepage.segment.four.right.description0"
                                        defaultMessage="Use our software restaurant solution to be compliant with labeling rules, nutrition facts tags or allergens and enhance your transparency."/>
              </p>
              <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                <FormattedMessage id="homepage.segment.four.right.description"
                                        defaultMessage="Add and edit nutrition facts labels (based on USDA official data) per ingredient or per new recipe."/>
              </p>
              
              <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.four.right.sub.description"
                                        defaultMessage="Get the list of official allergens and associate them to ingredients and automatically to recipes."/>
              </p>
            {/* </Grid.Row> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column floated='right' width={8}>
          <div /* style={inlineImageStyle2.img} */>  
            <Image
              //bordered
              centered
              rounded
              size='large'
              src={img_planningFull}
              alt={intl.locale == 'en'?'recipe forms order export print':'imprimer pdf bon commande fournisseur'}/>
            </div>
          </Grid.Column>
          <Grid.Column stretched floated='right'  /*textAlign='justified'*/ width={7}>
{/*             <Image centered size='tiny' src={img_printerIcon} style={inlineImageStyle3.img}
                    alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'45px'} height={'auto'} src={img_planning} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/>
            </div>  
            <Header as='h2'>
                <FormattedMessage id="homepage.segment.eight.left.title"
                                  defaultMessage="PRODUCTION : Plan precisely your production needs for coming days,weeks, months"/>
            </Header>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.eight.left.description2"
                                  defaultMessage="Use our planning tools to adjust or extract all your needs from your recipes quantities"/>
            </p>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.eight.left.description"
                                  defaultMessage="Print, export all your productions notes per events at anytime, convert to supplier orders in one click."/>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column stretched floated='right'  /*textAlign='justified'*/ width={8}>
            <div style={{'text-align':'center'}}>
                        <img width={'45px'} height={'auto'} src={img_order} //style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/>
                          <img width={'45px'} height={'auto'} src={img_box} //style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/>
            </div>  
            <Header as='h2'>
                <FormattedMessage id="homepage.segment.seven.left.title"
                                  defaultMessage="ORDERS : Manage your orders and stock inventory"/>
            </Header>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.seven.left.description.line1"
                                  defaultMessage="Plan your needs and create your orders based on your recipes quantities."/>
            </p>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.seven.left.description.line2"
                                  defaultMessage="Adjust and generate your orders using your food inventory: reduce your needed items based on stock availability or destock. Check your deliveries and identify anomalies. Send your orders directly to your suppliers by email."/>
            </p>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.seven.left.description.line3"
                                  defaultMessage="Optimize and evaluate your stock value for your ingedients or your recipes. Generate your categorized inventory lists."/>
            </p>
            <a href='/overview' style={{ fontSize: '18px' }}>
                              <FormattedMessage id="homepage.segment.seven.left.description.line4"
                                                defaultMessage="Overview of the features"/>    
            </a>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <div style={{'text-align':'left', 'display': 'inline-block','justify-content': 'center'}}> 
                <Step.Group vertical size='medium'>
                  <Step>
                    <Icon name='tasks' />
                    <Step.Content>
                      <Step.Title><FormattedMessage id="homepage.segment.seven.step.one.title"
                                                    defaultMessage="Create your order"/>
                      </Step.Title>
                      <Step.Description><FormattedMessage id="homepage.segment.seven.step.one.subtitle"
                                                          defaultMessage="based on recipes quantities"/>
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step active>
                    <Icon name='warehouse' />
                    <Step.Content>
                      <Step.Title><FormattedMessage id="homepage.segment.seven.step.two.title"
                                                    defaultMessage="Inventory adjustment"/>
                      </Step.Title>
                      <Step.Description><FormattedMessage id="homepage.segment.seven.step.two.subtitle"
                                                          defaultMessage="reduce available or destock"/>
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Icon name='shipping fast' />
                    <Step.Content>
                      <Step.Title><FormattedMessage id="homepage.segment.seven.step.three.title"
                                                    defaultMessage="Order sent"/>
                      </Step.Title>
                      <Step.Description><FormattedMessage id="homepage.segment.seven.step.three.subtitle"
                                                          defaultMessage="supplier contacted"/>
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step completed>
                    <Icon name='check' />
                    <Step.Content>
                      <Step.Title><FormattedMessage id="homepage.segment.seven.step.four.title"
                                                    defaultMessage="Received order"/>
                      </Step.Title>
                      <Step.Description><FormattedMessage id="homepage.segment.seven.step.four.subtitle"
                                                          defaultMessage="update inventory stock"/>
                      </Step.Description>
                    </Step.Content>
                  </Step>
                </Step.Group>
              </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>
    <Segment style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column floated='right' width={7}>
          <div style={inlineImageStyle2.img}>  
            <Image
              //bordered
              centered
              rounded
              size='large'
              src={img_antique}
              alt={intl.locale == 'en'?'recipe forms order export print':'imprimer pdf bon commande fournisseur'}/>
            </div>
          </Grid.Column>
          <Grid.Column stretched floated='right'  /*textAlign='justified'*/ width={7}>
{/*             <Image centered size='tiny' src={img_printerIcon} style={inlineImageStyle3.img}
                    alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'40px'} height={'auto'} src={img_printerIcon} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/>
            </div>  
            <Header as='h2'>
                <FormattedMessage id="homepage.segment.five.left.title"
                                  defaultMessage="EXPORT your technical personalized documents : recipes forms, menus costing, orders, inventory lists"/>
            </Header>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.five.left.description"
                                  defaultMessage="You can export your recipes forms with customized options  (costs details, margin details, pie chart, prepare instructions), menu costing, orders and also inventory lists to PDF."/>
            </p>
{/*             <div style={{ padding: '2em' }}>
              <Icon name='file pdf' size='large' alt={intl.locale == 'en'?'recipe forms order pdf print':'imprimer pdf bon commande fournisseur'}/>
              <Icon name='file excel' size='large' alt={intl.locale == 'en'?'recipe forms order excel print':'imprimer excel fiche technique recette'}/>
              <Icon name='download' size='large' alt={intl.locale == 'en'?'recipe forms order  print':'imprimer fiche technique commande fournisseur'}/>
            </div> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column stretched floated='right'  /*textAlign='justified'*/ width={8}>
{/*             <Image centered size='tiny' src={img_printerIcon} style={inlineImageStyle3.img}
                    alt={intl.locale == 'en'?'ratatool recipe order shopping list supplier':'ratatool recette bon commande fournisseur grossiste'}/> */}
            <div style={{'text-align':'center'}}>
                      <img width={'40px'} height={'auto'} src={img_globe} //style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping cloud supplier':'ratatool recette bon commande fournisseur grossiste'}/>
                      <img width={'45px'} height={'auto'} src={img_cloud} //style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe order shopping cloud supplier':'ratatool recette bon commande fournisseur grossiste'}/>
            </div>  
            <Header as='h2'>
                <FormattedMessage id="homepage.segment.six.left.title"
                                  defaultMessage="ACCESSIBILITY : Unleash any link, work from anywhere on any device"/>
            </Header>
            <p style={{ fontSize: '18px' }}>
                <FormattedMessage id="homepage.segment.six.left.description"
                                  defaultMessage="Our cloud application is multi-device : you can work from your mobile, your tablet or your personal computer. Access, update, export, print securely your data from your business, within your stock inventory or from your home desk."/>
            </p>
          </Grid.Column>
         <Grid.Column floated='right' width={2}>
           <div >  
            <Image
              //bordered
              centered
              rounded
              size='medium'
              src={img_devices}
              alt={intl.locale == 'en'?'recipe forms order export print':'imprimer pdf bon commande fournisseur'}/>
            </div>
            </Grid.Column>
            <Grid.Column /* floated='right' */ width={4}>
            <Image
              //bordered
              centered
              rounded
              size='small'
              src={intl.locale == 'en'?img_mobileDevice_en:img_mobileDevice_fr}
              alt={intl.locale == 'en'?'recipe forms order export print':'imprimer pdf bon commande fournisseur'}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: '4em 0em 4em 0em',border: 'none'}} vertical>
            <Grid container stackable verticalAlign='top'>
              <Grid.Row centered>
                <Header as='h2' centered>
                        <FormattedMessage id="homepage.segment.features.text.title"
                                          defaultMessage="And also some other features and benefits ..."/>
                </Header>
              </Grid.Row>
              <Grid.Row style={{ padding: '3em 0em' }} >
                <Grid.Column stretched centered  /*floated='left'textAlign='justified'*/ width={5}>
                      <div style={{'text-align':'center'}}>
                          <img width={'55px'} height={'auto'} src={img_menu} style={inlineImageStyle4.img}/>
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.features.description.line1.one.text.title"
                                              defaultMessage="Menu costing"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em'}}>
                        <FormattedMessage id="homepage.segment.features.desciption.three.text.content.line.one"
                                          defaultMessage="Compose your day or season menus, evaluate your combos food costs and margins."/>
                      </p>
                    </Grid.Column>
                    <Grid.Column stretched centered /*floated='left' textAlign='justified'*/ width={5}>
                      <div style={{'text-align':'center'}}>
                          <img width={'55px'} height={'auto'} src={img_settings} style={inlineImageStyle4.img} />
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.features.description..line1.two.text.title"
                                              defaultMessage="Customization"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em'}}>
                        <FormattedMessage id="homepage.segment.features.desciption..line1.two.text.content.line.one"
                                          defaultMessage="Advanced data adjustements (apply loss variation ...). User permissions. Automatic international units conversion. Set your preferred currency (120 available). Plenty of custom options."/>
                      </p>
                    </Grid.Column>
                    <Grid.Column stretched centered /*floated='left' textAlign='justified'*/ width={5}>
                      <div style={{'text-align':'center'}}>
                          <img width={'55px'} height={'auto'} src={img_upload} style={inlineImageStyle4.img} />
                      </div>
                      <Header as='h3' style={{'text-align':'center'}}>
                          <Header.Content>
                            <FormattedMessage id="homepage.segment.features.description..line1.three.text.title"
                                              defaultMessage="Onboarding"/>
                          </Header.Content>
                      </Header>
                      <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em'}}>
                        <FormattedMessage id="homepage.segment.features.desciption..line1.three.text.content.line.one"
                                          defaultMessage="Your first data loading made easy. Load multiple ingredients at one time or import from a CSV template file (excel)."/>
                      </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column stretched centered  /*textAlign='justified'*/ width={5}>
                        <div style={{'text-align':'center'}}>
                          {/* <Icon name='cloud upload' color='blue' size='huge'/> */}
                          <img width={'55px'} height={'auto'} src={img_business} style={inlineImageStyle4.img}/>
                        </div>
                        <Header as='h3' style={{'text-align':'center'}}>
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.features.description.line2.one.text.title"
                                                defaultMessage="Multi-site and sharing"/>
                            </Header.Content>
                        </Header>
                        <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em' }}>
                        <FormattedMessage id="homepage.segment.features.desciption..line2.one.text.content.line.one"
                                            defaultMessage="Better accessibility and consistency across your locations (multi-site). More efficient team collaboration with all your technical documents available, ready for export."/>
                      </p>
                      </Grid.Column>
                      <Grid.Column stretched centered  /*textAlign='justified'*/ width={5}>
                        <div style={{'text-align':'center'}}>
                        <img width={'55px'} height={'auto'} src={img_stats} style={inlineImageStyle4.img} />
                        </div>
                        <Header as='h3' style={{'text-align':'center'}}>
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.features.description..line2.two.text.title"
                                                defaultMessage="Improvements"/>
                            </Header.Content>
                        </Header>
                        <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em' }}>
                        <FormattedMessage id="homepage.segment.features.desciption..line2.two.text.content.line.one"
                                            defaultMessage="Our application evolves regularly with your view and your needs. Updates are included in continous delivery process."/>
                      </p>
                      </Grid.Column>
                      <Grid.Column stretched centered  /*textAlign='justified'*/ width={5}>
                        <div style={{'text-align':'center'}}>
                         <img width={'55px'} height={'auto'} src={img_help} style={inlineImageStyle4.img} />
                        </div>
                        <Header as='h3' style={{'text-align':'center'}}>
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.features.description.line2.three.text.title"
                                                defaultMessage="Support"/>
                            </Header.Content>
                        </Header>
                        <p style={{ fontSize: '16px', padding:'0em 0em 0.5em 0em' }}>
                        <FormattedMessage id="homepage.segment.features.desciption.line2.three.text.content.line.one"
                                            defaultMessage="Send us your questions, issues or needs. The support team is reactive."/>
                      </p>
                      </Grid.Column>
                  </Grid.Row>
            </Grid>
    </Segment>
{/*     <Segment secondary style={{ padding: '2em 0em 4em 0em',border: 'none' }} vertical>
      <Grid celled='internally' container columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column stretched style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <div style={{'text-align':'center'}}>
                      <img width={'50px'} height={'auto'} src={img_speech} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe cost tool':'approvisionnement recette fiche technique'}/>
            </div>  
            <Header as='h3' icon>
                &laquo;{' '}
                <FormattedMessage id="homepage.segment.six.left.title"
                                  defaultMessage=' Makes recipes adjustement very easy !!!'/>{' '}&raquo;
            </Header>
            <p style={{ fontSize: '18px' }}> 
                <FormattedMessage id="homepage.segment.six.left.description"
                                  defaultMessage="That is what they all say about us"/>
            </p>
          </Grid.Column>
          <Grid.Column stretched style={{ paddingBottom: '5em', paddingTop: '5em' }}>

            <div style={{'text-align':'center'}}>
                      <img width={'50px'} height={'auto'} src={img_speech} style={inlineImageStyle3.img} 
                           alt={intl.locale == 'en'?'ratatool recipe cost tool':'approvisionnement recette fiche technique'}/>
            </div>  
            <Header as='h3' >
              &laquo;{' '}<FormattedMessage id="homepage.segment.six.right.title"
                                  defaultMessage="Easy and promising tool"/>{' '}&raquo;
            </Header>
            <p style={{ fontSize: '18px' }}>
             <b>Amjad</b> - Rawcoco CEO
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment> */}
{/*     <Segment secondary style={{ padding: '4em 0em 4em 0em',border: 'none' }} vertical>
        <Grid container stackable verticalAlign='middle' centered>
              <br/>
              <Header as='h2' centered>
                       <FormattedMessage id="homepage.segment.blog.text.title"
                                         defaultMessage="Take a look at our blog suggestions"/> 
              </Header>
              <br/>
              <Grid.Row>
                  <Grid.Column >
                        <Card.Group  style={{marginLeft:'0.5em'}} doubling stackable itemsPerRow={3}>
                        {posts && posts.map(post => {
                          return(
                                <Card as={Link} to={'/article/'+post.slug}
                                      //textAlign='justified'
                                      style={{border:'none',backgroundColor: '#fcfcfc','box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                  <Image image src={post.cover} centered fluid style={{height:'50%',marginTop:'0em'}}
                                          alt={intl.locale == 'en'?'ratatool blog recipe tool':'software blog logiciel recette'}/>
                                  <Card.Content style={{border:'none'}}>
                                      <Card.Meta  content={post.category} />
                                      <Card.Header as={Link} to={'/article/'+post.slug}>{post.title}</Card.Header>
                                      <br/> 
                                  </Card.Content>
                                  <Card.Content extra>
                                      <p style={{'text-align':'left'}}>
                                        <Icon name='eye' size='small'/>{post.nb_views}{' '}{' '}
                                                  <FormattedMessage id="homepage.blog.card.link.views"
                                                                    defaultMessage="views"/>
                                        <span style={{float:'right'}}>
                                          <Link to={'/article/'+post.slug} style={{color:'#1765af'}}>
                                                  <FormattedMessage id="homepage.blog.card.link.read"
                                                                    defaultMessage="read more"/> &#8594;
                                          </Link>
                                        </span>
                                      </p>
                                  </Card.Content>
                              </Card>
                          )})}
                          </Card.Group>
                    </Grid.Column>
          </Grid.Row>
          <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button basic color="blue" size='large'  as={Link} to="/blog">
                      <FormattedMessage id="homepage.segment.blog.button.open.text"
                                         defaultMessage="See more articles"/>
                  </Button>
                </Grid.Column>
              </Grid.Row>
        </Grid>
    </Segment> */}
    <Segment secondary style={{ padding: '4em 0em 4em 0em',border: 'none' }} vertical>
        <Grid container stackable verticalAlign='middle' centered>
              <Grid.Row>
                <Header as='h2' centered>
                       {/*  <FormattedMessage id="homepage.segment.try.text.title"
                                          defaultMessage="Try Ratatool software for free and enhance your productivity"/>  */}
                        <FormattedMessage id="homepage.segment.try.text.begin.title"
                                          defaultMessage="Try our software"/>{' '}
                        <span /*style={{color:'#63a4dd'}}*/><FormattedMessage id="homepage.segment.try.text.middle.title"
                                          defaultMessage="now"/></span>{' '}
                        <FormattedMessage id="homepage.segment.try.text.end.title"
                                          defaultMessage="and enhance your productivity"/>
                </Header>
              </Grid.Row>
              <br/>
              <Grid.Row  style={{ padding: '1.5em 0em 0em 0em' }}>
                <List divided relaxed style={{ marginLeft: '1em'/*, 'font-style':'italic'*/,textAlign:'left' }}>
                    <Header as='h3' centered style={{color:'#63a4dd'}}>
                           <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.try.text5.title"
                                              defaultMessage="Get your actual costs dynamically"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.try.text3.title"
                                              defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.try.text6.title"
                                              defaultMessage="Plan your productions tasks and food needs"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.try.text4.title"
                                              defaultMessage="Secure your essential business data"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="homepage.segment.try.text2.title"
                                              defaultMessage="Save hours of work on your daily repetitive tasks"/> 
                            </Header.Content><br/>
                    </Header>
                </List>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button size='huge' as={Link} to="/register" style={styles.ButtonBlueAsIconUpper}>
                      <FormattedMessage id="homepage.segment.try.button.text"
                                         defaultMessage="Start for free"/>
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <p><FormattedMessage id="homepage.segment.try.info.text"
                                   defaultMessage="No credit card required"/></p>
        </Grid>
    </Segment>
{/*     <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row >
          <Grid.Column width={8} id='contact'>
              <Contact />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment> */}
    <Segment inverted vertical style={{ padding: '7em 0em',border: 'none' }}>
      <Container>
        <Grid divided stackable>   
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted  
                      as='h4' 
                      content={<FormattedMessage id="homepage.bottom.menu.header.about"
                                                  defaultMessage="About"/>}
              />
              <List inverted link size='large'>
                <List.Item as={Link} to='/'>
                      <FormattedMessage id="homepage.bottom.menu.item.about"
                                         defaultMessage="Home"/>
                </List.Item>
                <List.Item/>
                <List.Item as={Link} to='/overview'>
                      <FormattedMessage id="homepage.bottom.menu.item.overview"
                                        defaultMessage="Features"/>
                </List.Item>
                <List.Item/>
                <List.Item as={Link} to='/pricing'>
                      <FormattedMessage id="homepage.bottom.menu.item.pricing"
                                        defaultMessage="Plans & Pricing"/>
                </List.Item>
              </List>
              <List size='large'>
                <List.Item/>
                <List.Item>
                <span>
                     <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                     <Dropdown text={intl.locale}
                        inline
                        upward={true}
                        pointing ='top left'
                        compact
                        style={{color:'#4286e3'}}
                        //value = {intl.locale}
                        //options ={localeOptions}
                        //onChange = {(e,data)=>dispatch(localeActions.changeLocale(data.value))}/
                        >
                        <Dropdown.Menu>
                        <Dropdown.Item text='Français' icon={<Flag name='fr'/>} 
                                        selected={intl.locale=='fr'} as='a' href={serverConstants.FRONTEND_URL_FR}/>
                          <Dropdown.Item text='English'  icon={<Flag name='us'/>}  
                                         selected={intl.locale=='en'} as='a' href={serverConstants.FRONTEND_URL_EN}/>
                        </Dropdown.Menu>
                      </Dropdown>
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
            <Header inverted  
                      as='h4' 
                      content={<FormattedMessage id="homepage.bottom.menu.header.services"
                                                  defaultMessage="Services"/>}
              />
              <List inverted link size='large'>
              {!loggedIn &&
                <List.Item as={Link} to='/register'>
                    <FormattedMessage id="homepage.bottom.menu.item.signup"
                                      defaultMessage="Sign Up"/>
                </List.Item>}
                <List.Item/>
              {!loggedIn &&
                <List.Item as={Link} to='/login'>
                    <FormattedMessage id="homepage.bottom.menu.item.login"
                                       defaultMessage="Login"/>
                </List.Item>}
                {loggedIn &&
                <List.Item as={Link} to='/dashboard'>
                    <FormattedMessage id="homepage.bottom.menu.item.dashboard"
                                      defaultMessage="Dashboard"/>
                </List.Item>}
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
            <Header inverted  
                      as='h4' 
                      content={<FormattedMessage id="homepage.bottom.menu.header.resource"
                                                  defaultMessage="Resources"/>}
              />
              <List inverted link size='large'>
                <List.Item as={Link} to='/blog'>
                    <FormattedMessage id="homepage.bottom.menu.item.blog"
                                      defaultMessage="Blog"/>
                </List.Item>
                <List.Item/>
                <List.Item as={Link} to='/contact'>
                    < FormattedMessage id="homepage.bottom.menu.item.contact"
                                       defaultMessage="Contact Us"/>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
            <Header inverted  
                      as='h4' 
                      content={<FormattedMessage id="homepage.bottom.menu.header.business"
                                                  defaultMessage="Recipe Costing Software"/>}
              />
              <List inverted link size='large'>
                <List.Item as={Link} to='/recipe-sofware-restaurant'>
                    <FormattedMessage id="homepage.bottom.menu.item.restaurant"
                                      defaultMessage="Restaurant"/>
                </List.Item>
                <List.Item as={Link} to='/recipe-sofware-bakery'>
                    < FormattedMessage id="homepage.bottom.menu.item.bakery"
                                       defaultMessage="Bakery Pastry shop"/>
                </List.Item>
                <List.Item as={Link} to='/recipe-sofware-caterer'>
                    < FormattedMessage id="homepage.bottom.menu.item.caterer"
                                       defaultMessage="Caterer"/>
                </List.Item>
                <List.Item as={Link} to='/recipe-sofware-hotel'>
                    < FormattedMessage id="homepage.bottom.menu.item.hotel"
                                       defaultMessage="Hotel Restaurant"/>
                </List.Item>
                <List.Item as={Link} to='/recipe-sofware-kitchen'>
                    < FormattedMessage id="homepage.bottom.menu.item.kitchen"
                                       defaultMessage="Kitchen - Dark kitchen"/>
                </List.Item>
                <List.Item as={Link} to='/restaurant-management-software'>
                    <FormattedMessage id="homepage.bottom.menu.item.management"
                                      defaultMessage="Restaurant Management"/>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
            <Header inverted  
                      as='h4' 
                      content={<FormattedMessage id="homepage.bottom.menu.header.copyright"
                                                  defaultMessage="Legal"/>}
              />
              <List inverted link size='large'>
              <List.Item as={Link} to='/termsconditions'>
                      <FormattedMessage id="homepage.bottom.menu.item.terms"
                                        defaultMessage="Terms and Conditions"/>
              </List.Item>
              <List.Item/>
              <List.Item as={Link} to='/privacypolicy'>
                      <FormattedMessage id="homepage.bottom.menu.item.privacy"
                                        defaultMessage="Privacy Policy"/>
              </List.Item>
              <List.Item/>
              <List.Item/>
              <List.Item/>
              <List.Item/>
              <List.Item/>
              <List.Item><Icon name='copyright outline'/>Ratatool 2024</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>)}}
//)

function mapStateToProps(state) {
    const { loggedIn} = state.authentication;
    const { /*blog,*/alert,dispatch} = state;
    return {
        //blog,
        loggedIn,
        alert,
        dispatch,
    };
}

const connectedHomepageLayout = injectIntl(connect(mapStateToProps)(HomepageLayout));
export { connectedHomepageLayout as HomepageLayout };
