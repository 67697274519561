
import { authHeader } from '../_helpers';
import { serverConstants } from '../_constants';

//DRAFT IMPLEMENTATION BASED ON USDA REST API WITH registered API_KEY
const API_KEY = 'Y1xdk9UkHYeUlEpWhLxD37iriPjBSEerM179sD1c';  
const NEW_API_KEY = 'TVTuLa6R7pwvWn82pStPfhORjQRXMtIX6HylXYKC';  

export const nutritionService = {
    search,
    getNutrition,
    searchUSDA,
    searchCiqual,
    getNutritionUSDA,
    getNutritionCiqual
};


function search(item,locale) {
    if (locale == 'fr'){
        return searchCiqual(item);
    }else {
        return searchUSDANew(item);
    }
}

function getNutrition(ndbno,locale) {
    if (locale == 'fr'){
        return getNutritionCiqual(ndbno);
    }else {
        return getNutritionUSDANew(ndbno);
    }
}


function searchUSDA(item) {
    const requestOptions = {
        method: 'GET',
        //headers: authHeader(),
        //body: JSON.stringify({instructions:instructions})
    };

    return fetch('https://api.nal.usda.gov/ndb/search/?format=json&q='+item+'&sort=r&max=25&offset=0&api_key='+API_KEY, requestOptions).then(handleResponse);
}

function getNutritionUSDA(ndbno) {
    const requestOptions = {
        method: 'GET',
        //headers: authHeader(),
        //body: JSON.stringify({instructions:instructions})
    };

    return fetch('https://api.nal.usda.gov/ndb/V2/reports?ndbno='+ndbno+'&type=b&format=json&api_key='+API_KEY, requestOptions).then(handleResponse);
}


//https://fdc.nal.usda.gov/api-guide.html#food-search-endpoint
function searchUSDANew(item) {
    const requestOptions = {
        method: 'GET',
        header : {'Content-Type': 'application/json'},
    };

    //return fetch("https://api.nal.usda.gov/fdc/v1/search?generalSearchInput="+item+"&api_key="+NEW_API_KEY+"&includeDataTypeList=SR Legacy,Branded", requestOptions).then(handleResponse)
    return fetch("https://api.nal.usda.gov/fdc/v1/foods/search?api_key="+NEW_API_KEY+"&query="+item+"&dataType=SR Legacy,Foundation", requestOptions).then(handleResponse)
}

//https://fdc.nal.usda.gov/api-guide.html#food-detail-endpoint
function getNutritionUSDANew(fdcId) {
    const requestOptions = {
        method: 'GET',
        header : {'Content-Type': 'application/json'},
    };

    //return fetch('https://api.nal.usda.gov/fdc/v1/'+fdcId+'?api_key='+NEW_API_KEY, requestOptions).then(handleResponse);
    return fetch('https://api.nal.usda.gov/fdc/v1/food/'+fdcId+'?api_key='+NEW_API_KEY, requestOptions).then(handleResponse);
}


function searchCiqual(name) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/nutritions/search/?name=' + name, requestOptions).then(handleResponse);
}

function getNutritionCiqual(alim_code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/nutritions/' + alim_code +'/', requestOptions).then(handleResponse);
}



function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
            const error = new Error(json.message)
            return Promise.reject(Object.assign(error, { response }))
        })
    }

    return response.json();
}