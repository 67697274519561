import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, List, Menu, Container, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';


const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};


class SideLeftUncoverPayment extends Component {
  state = { visible: false }
  
  render() {

    return (
      <div>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Sidebar.Pushable as={Container} style={containerStyle}>
{/*             <Sidebar as={Menu}  fixed='left' selection  animation='uncover' width='thin' visible={true} icon='labeled' vertical >
                <Menu.Item>Recipe actions</Menu.Item>
                <Menu.Item name='Add Recipe' as={Link} to='/newrecipe' >
                  <Icon name='add' size='mini'/>
                  Add Recipe
                </Menu.Item>
                <Menu.Item name='Download Recipe'>
                  <Icon name='download' size='mini'/>
                  Download Recipe
                </Menu.Item>  */}
             <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical='true' size='large'>
                <br/>
                <List.Item disabled>
                        <FormattedMessage id="payment.computer.sidemenu.menu.top.title"
                                          defaultMessage='Payment'/>
                </List.Item>
                <List.Item name='offers' as={Link} to={{pathname: '/profilepremium', state: {toggled: true}}}>
                  <Icon name='gift' color='violet'/>
                  <List.Content>
                    <List.Header>
                        <FormattedMessage id="payment.computer.sidemenu.menu.elem1.title"
                                          defaultMessage='Premium Offers'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
              </Sidebar> 
              <Sidebar.Pusher style={{ 'min-height':'100vh', width: '100%', padding: '2em 2em',border:'none','background-color':'#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/>
                    <FormattedMessage id="payment.mobile.sidemenu.menu.title"
                                          defaultMessage='Payment'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='offers' as={Link} to={{pathname: '/profilepremium', state: {toggled: true}}} >
                      <Icon name='gift'/>
                        <FormattedMessage id="payment.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Offers'/>
                    </Menu.Item>
                  {/* </Container>   */}
                 </Sidebar>
                <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 2em' }}>
                    {/* <Segment style={{ minHeight: 500, padding: '1em 0em' }}> */}
                      {this.props.children}
                    {/* </Segment>     */} 
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
      </div>
    )
  }
}

export default SideLeftUncoverPayment
