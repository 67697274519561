export const categoryIngredientConstants = {
    GETALL_REQUEST: 'CATEGORYINGREDIENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CATEGORYINGREDIENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CATEGORYINGREDIENT_GETALL_FAILURE',

    GET_REQUEST: 'CATEGORYINGREDIENT_GET_REQUEST',
    GET_SUCCESS: 'CATEGORYINGREDIENT_GET_SUCCESS',
    GET_FAILURE: 'CATEGORYINGREDIENT_GET_FAILURE',

    ADD_REQUEST: 'CATEGORYINGREDIENT_ADD_REQUEST',
    ADD_SUCCESS: 'CATEGORYINGREDIENT_ADD_SUCCESS',
    ADD_FAILURE: 'CATEGORYINGREDIENT_ADD_FAILURE',

    UPDATE_REQUEST: 'CATEGORYINGREDIENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CATEGORYINGREDIENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CATEGORYINGREDIENT_UPDATE_FAILURE',

    DELETE_REQUEST: 'CATEGORYINGREDIENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'CATEGORYINGREDIENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'CATEGORYINGREDIENT_DELETE_FAILURE',
    
    COUNT_REQUEST: 'CATEGORYINGREDIENT_COUNT_REQUEST',
    COUNT_SUCCESS: 'CATEGORYINGREDIENT_COUNT_SUCCESS',
    COUNT_FAILURE: 'CATEGORYINGREDIENT_COUNT_FAILURE',

    COUNT_INGREDIENTS_REQUEST: 'CATEGORYINGREDIENT_COUNT_INGREDIENTS_REQUEST',
    COUNT_INGREDIENTS_SUCCESS: 'CATEGORYINGREDIENT_COUNT_INGREDIENTS_SUCCESS',
    COUNT_INGREDIENTS_FAILURE: 'CATEGORYINGREDIENT_COUNT_INGREDIENTS_FAILURE',
};