import { saleConstants } from '../_constants';


export function sales(state = {}, action) {
  switch (action.type) {
    case saleConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case saleConstants.GETALL_SUCCESS:
      let sorted_items = action.sales
      if (sorted_items){
        sorted_items = sorted_items.sort((a,b) => Date.parse(b.sale_date) - Date.parse(a.sale_date))
      } 
      return {
        loading: false,
        items: sorted_items,
      };
    case saleConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      
      case saleConstants.RETRIEVEALL_POS_REQUEST:
        return {
          loading_s: true
        };
      case saleConstants.RETRIEVEALL_POS_SUCCESS:
        let sorted_positems = action.sales
        if (sorted_positems){
          sorted_positems = sorted_positems.sort((a,b) => Date.parse(b.sale_date) - Date.parse(a.sale_date))
        } 
        return {
          loading_s: false,
          items: sorted_positems,
        };
      case saleConstants.RETRIEVEALL_POS_FAILURE:
        return { 
          error: action.error
        };

    default:
      return state
  }
}

