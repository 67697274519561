import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, Menu, List, Container, Icon,Button} from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import {history} from '../_helpers';
import {styles} from './Styles';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};

class SideLeftUncoverIngredients extends Component {
  state = { visible: false }

  isActive(match1){
    //return this.props.location.pathname.indexOf(match1)!=-1
    return this.props.location.pathname === match1
  }

  onSubmit = (e) => {
    e.preventDefault();
    history.push({pathname: '/profilepremium', state: {toggled: true}})
  }

  render() {
    const {subscription} = this.props;
    return (
      <div>
      <Responsive minWidth={Responsive.onlyComputer.minWidth} >
        {/* <Segment textAlign='left' basic vertical> */}
          <Sidebar.Pushable as={Container} style={containerStyle}>
            <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical size='large'>
{/*               <Menu.Item name='Add Ingredient' as={Link} to='/newingredient' >
                <Icon name='add'/>
                Add Ingredient
              </Menu.Item>
              <Menu.Item name='Download Recipe'>
                <Icon name='download' />
                Download Recipe
              </Menu.Item> */}
              <br/>
               <List.Item disabled>
                       <FormattedMessage id="ingredients.computer.sidemenu.menu.top.title"
                                         defaultMessage='Ingredients Actions'/>
               </List.Item>
               <List.Item name='View Ingredients' as={Link} to='/ingredients' >
                  <Icon name='home' color={this.isActive('/ingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/ingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="ingredients.computer.sidemenu.menu.elem1.title"
                                          defaultMessage='Ingredients'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='New Ingredient' as={Link} to={'/newingredient'}>
                  <Icon name='add' color={this.isActive('/newingredient')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/newingredient')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="ingredients.computer.sidemenu.menu.elem2.title"
                                          defaultMessage='New'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Add Many Ingredient' as={Link} to={'/newingredients'}>
                  <Icon name='th' color={this.isActive('/newingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/newingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="ingredients.computer.sidemenu.menu.elem6.title"
                                          defaultMessage='Add several'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
               <List.Item name='Import Ingredient' as={Link} to={'/importingredients'}>
                  <Icon name='cloud upload' color={this.isActive('/importingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/importingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="ingredients.computer.sidemenu.menu.elem7.title"
                                          defaultMessage='Initial import'/>
                    </List.Header>
                    <List.Description style={this.isActive('/importingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                    <FormattedMessage id="ingredients.computer.sidemenu.menu.elem7.subtitle"
                                          defaultMessage='or add new items'/>
                  </List.Description>
                  </List.Content>
                </List.Item>
               <List.Item name='Update Ingredient' as={Link} to={'/updateingredients'}>
                  <Icon name='sync alternate' color={this.isActive('/updateingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/updateingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="ingredients.computer.sidemenu.menu.elem8.title"
                                          defaultMessage='Bulk update'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Download Ingredients' as={Link} to='/exportingredients'>
                  <Icon name='download' color={this.isActive('/exportingredients')?'blue': 'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/exportingredients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                         <FormattedMessage id="ingredients.computer.sidemenu.menu.elem3.title"
                                          defaultMessage='Export'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as={Link} to='/categoriesingredient'>
                  <Icon name='sitemap' color={this.isActive('/categoriesingredient')?'blue': 'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/categoriesingredient')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="ingredients.computer.sidemenu.menu.elem4.title"
                                          defaultMessage='Categories'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as={Link} to='/ingredientssuppliers'>
                  <Icon name='shipping fast' color={this.isActive('/ingredientssuppliers')?'blue': 'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/ingredientssuppliers')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="ingredients.computer.sidemenu.menu.elem5.title"
                                          defaultMessage='Suppliers'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                {subscription && (!subscription.customer__membership__membership_type || subscription.customer__membership__membership_type=="Free") && this.isActive('/ingredients') &&
                    <div style={{'text-align':'center', 'paddingTop' : '22em'}}>
                      <a style={{color:'grey'}}>
                            <FormattedMessage id="ingredients.computer.sidemenu.premium.title.message.subscribe"
                                              defaultMessage='Plan: Basic'/>
                      </a>
                      <Button style={styles.ButtonBluePremium}
                        onClick={(e) => {this.onSubmit(e)}}>
                        <FormattedMessage id="ingredients.button.premium"
                                          defaultMessage='Subscribe'/>
                      </Button>
                </div>}
            </Sidebar>
            <Sidebar.Pusher style={{ 'min-height': '100vh',overflowY: 'auto', width: '100%', padding: '2em 2em 20em 3em','background-color':'#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
          </Sidebar.Pushable>
        {/* </Segment> */}
      </Responsive>
      <Responsive minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="ingredients.mobile.sidemenu.menu.top.title"
                                                          defaultMessage='Ingredients'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='View'as={Link} to='/ingredients'>
                        <Icon name='home' color={this.isActive('/ingredients')?'blue': 'black'}/>
                          <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                      </Menu.Item>
                    <Menu.Item name='New' as={Link} to='/newingredient' >
                      <Icon name='add' color={this.isActive('/newingredient')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='New'/>
                    </Menu.Item>
                    <Menu.Item name='Multiple' as={Link} to='/newingredients' >
                        <Icon name='th' color={this.isActive('/newingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='Add several'/>
                    </Menu.Item>
                    <Menu.Item name='Import' as={Link} to='/importingredients' >
                        <Icon name='cloud upload' color={this.isActive('/importingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Initial import'/>
                         <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem7.subtitle"
                                          defaultMessage='or add new items'/>
                    </Menu.Item>
                    <Menu.Item name='Update' as={Link} to='/updateingredients' >
                        <Icon name='sync alternate' color={this.isActive('/updateingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem8.title"
                                          defaultMessage='Bulk update'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to='/exportingredients'>
                      <Icon name='download' color={this.isActive('/exportingredients')?'blue': 'black'} />
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Export'/>
                    </Menu.Item>
                    <Menu.Item name='Categories' as={Link} to='/categoriesingredient'>
                      <Icon name='sitemap' color={this.isActive('/categoriesingredient')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Categories'/>
                    </Menu.Item>
                    <Menu.Item name='Suppliers' as={Link} to='/ingredientssuppliers'>
                      <Icon name='shipping fast' color={this.isActive('/ingredientssuppliers')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Suppliers'/>
                    </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 2em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
       </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu secondary>
              <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                  <Icon name="sidebar"/><FormattedMessage id="ingredients.mobile.sidemenu.menu.top.title"
                                                          defaultMessage='Ingredients'/>
              </Menu.Item>          
          </Menu>
          <Sidebar.Pushable as={Segment}>
              <Sidebar 
                  width='thin'
                  as={Menu} 
                  animation="push" 
                  direction="top" 
                  visible={this.state.visible} 
                  icon="labeled" 
                  horizontal
                  size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='View'as={Link} to='/ingredients'>
                        <Icon name='home' color={this.isActive('/ingredients')?'blue': 'black'}/>
                          <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                      </Menu.Item>
                    <Menu.Item name='New' as={Link} to='/newingredient' >
                      <Icon name='add' color={this.isActive('/newingredient')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='New'/>
                    </Menu.Item>
                    <Menu.Item name='Multiple' as={Link} to='/newingredients' >
                      <Icon name='th' color={this.isActive('/newingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='Add several'/>
                    </Menu.Item>
                    <Menu.Item name='Import' as={Link} to='/importingredients' >
                        <Icon name='cloud upload' color={this.isActive('/importingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Initial import'/>
                         <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem7.subtitle"
                                          defaultMessage='or add new items'/>
                    </Menu.Item>
                    <Menu.Item name='Update' as={Link} to='/updateingredients' >
                        <Icon name='sync alternate' color={this.isActive('/updateingredients')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem8.title"
                                          defaultMessage='Bulk update'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to='/exportingredients'>
                      <Icon name='download' color={this.isActive('/exportingredients')?'blue': 'black'} />
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Export'/>
                    </Menu.Item>
                    <Menu.Item name='Categories' as={Link} to='/categoriesingredient'>
                      <Icon name='sitemap' color={this.isActive('/categoriesingredient')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Categories'/>
                    </Menu.Item>
                    <Menu.Item name='Suppliers' as={Link} to='/ingredientssuppliers'>
                      <Icon name='shipping fast' color={this.isActive('/ingredientssuppliers')?'blue': 'black'}/>
                        <FormattedMessage id="ingredients.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Suppliers'/>
                    </Menu.Item>
                {/* </Container>   */}
              </Sidebar>
              <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 0em' }}>
                    {this.props.children}
              </Sidebar.Pusher>
          </Sidebar.Pushable>
       </Responsive>
    </div>
    )
  }
}

//export default withRouter(SideLeftUncoverIngredients)

function mapStateToProps(state) {
  const { subscription } = state.payment;
  return {
      subscription,
  };
}
export default connect(mapStateToProps)(withRouter(SideLeftUncoverIngredients))
