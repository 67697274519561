import { localConstants } from '../_constants';
import localeData from '../../public/locales/data.json';

let host = window.location.host;
//let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
let languageFromDomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 3) {
  subdomain = parts[0];
  // Remove the subdomain from the parts list
  //parts.splice(0, 1);
  // Set the location to the new url
  //window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
  if (subdomain =='fr'){
    languageFromDomain ='fr'
  }else if (subdomain =='en') {
    languageFromDomain ='en'
  }
}

// Try local from subdomain, try locale without region code,try full locale, fallback to 'fr'
let selected = languageFromDomain || 
               //languageWithoutRegionCode ||
               //language ||
              'en' 


export function locale(state = { locale: selected, messages: localeData[selected] }, action){
  switch (action.type) {
    case localConstants.CHANGE_LOCALE_LANGUAGE_SUCCESS:
      return { ...state,
               locale: action.locale,
               messages: localeData[action.locale] };
    default:
      return state;
  }
};