//import { currentLocale } from '../_helpers';
export const  TOKEN_EXPIRATION_DURATION_HOURS = 24

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('userIn'));
    const currentLocale = localStorage.getItem('locale');
    if (user && user.token) {
            return { 'Authorization': 'JWT ' + user.token,
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLocale + ',en' };
    } else {
        return {'Content-Type': 'application/json',
                'Accept-Language': currentLocale + ',en' };
    }
}


export function nonAuthHeader() {
    const currentLocale = localStorage.getItem('locale');
    return {'Content-Type': 'application/json',
            'Accept-Language': currentLocale + ',en' };
}


export function authHeaderImage() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('userIn'));
    const currentLocale = localStorage.getItem('locale');
    if (user && user.token) {
            return { 'Authorization': 'JWT ' + user.token,
                    //'Content-Type': 'multipart/form-data',
                    'Accept-Language': currentLocale + ',en' };
    } else {
        return {//'Content-Type': 'multipart/form-data',
                'Accept-Language': currentLocale + ',en' };
    }
}



export function checkTokenExpirationValid() {
    // check token time validity
    let user = JSON.parse(localStorage.getItem('userIn'));
    let expire_at = localStorage.getItem('expire_at');
    var d = new Date();
    if (user && user.token && user && d.getTime() > expire_at) {
        localStorage.removeItem('userIn');
        localStorage.removeItem('expire_at');
        return false;
    }
    return true;
}


export function authHeaderMultipart() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('userIn'));
    const currentLocale = localStorage.getItem('locale');
    if (user && user.token) {
            return { 'Authorization': 'JWT ' + user.token,
                    'Content-Type': 'multipart/form-data',
                    'Accept-Language': currentLocale + ',en' };
    } else {
        return {'Content-Type': 'multipart/form-data',
                'Accept-Language': currentLocale + ',en' };
    }
}
