import { authHeader, isEmpty } from '../_helpers';
import { serverConstants } from '../_constants';

export const categoryIngredientService = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count,
    do_count_ingredients
};


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
            //const error = new Error(json.message)
            //return Promise.reject(Object.assign(error, { response }))

            if (!isEmpty(json.message)){
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
               });
               return Promise.reject(error);
           }else{
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
                   title: json.detail,
               });

               return Promise.reject(error);
           }
        })
    }

    return response.json();
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/', requestOptions).then(handleResponse);
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        //headers: {
        //    'Access-Control-Allow-Origin': '*',
        //    'Content-Type': 'application/json',
        //    'Accept': 'application/json',                  
        //}
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/'+id+'/', requestOptions).then(handleResponse);
}

function add(ingredient) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({name:ingredient})
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/', requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/' + id +'/', requestOptions).then(handleResponse);;
}


function count() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/count/', requestOptions).then(handleResponse);
}


function update(id,name,is_active= true) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(
            {   id:id,
                name:name,
                is_active:is_active
            })
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/'+ id +'/', requestOptions).then(handleResponse);
}


function do_count_ingredients() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/categoriesIngredient/do_count_ingredients/', requestOptions).then(handleResponse);
}





