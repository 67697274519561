import React, { Component } from 'react';
import { Card, Image, Grid,Message,Icon,Label,Button,Popup,Modal,Header,Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {recipesActions,ingredientsActions, categoryIngredientActions, categoryRecipeActions,paymentActions,supplierActions, userActions} from '../_actions'
import './Recipes.css';
import {toaster_dispatch,trim,isInteger,isNumeric,OWNER,ADMIN,
       UNDEFINED,RESTAURANT,RESTAURANT_CORPORATE,RESTAURANT_CHAIN,CATERING,CATERING_CHAIN,BAKER_PASTRY,BAKER_PASTRY_CHAIN,
       KITCHEN,FASTFOOD,FOODTRUCK,BAR,HOTEL,HOTEL_CHAIN,MULTISITE,OTHERS,CompanyTypeOptions} from '../_helpers';
import {SideLeftUncoverDashboard} from './SidebarDashboard';
import {FormattedMessage,FormattedDate,FormattedNumber,injectIntl} from 'react-intl';
import {isEmpty} from "lodash"
import {styles} from './Styles';
import {Helmet} from "react-helmet";
//import img_recipesDashboard from '../Assets/recipes_dashboard.jpeg'
//import img_dressing from '../Assets/dressing-cooking-olive.jpg'

import img_list from '../Assets/clipboard_dashboard.png'
import img_pan from '../Assets/pan.png'
import img_bag from '../Assets/shopping-bag.png'
import img_order from '../Assets/receipt.png'
import img_gift from '../Assets/gift-box.png'
import img_help from '../Assets/help.png'
import img_box from '../Assets/boxes.png'
import img_menu from '../Assets/menuDashboard.png'
import img_news from '../Assets/newspaper.png'
import img_planning from '../Assets/planning.png'
import img_update from '../Assets/update.png'

/* var style = {
    'font-family': 'Lato', 
    fontWeight: 'italic',
    fontSize: '20px',
    color:'black',
  }; */

  var styleNumbersNumber = {
    'font-family': 'Lato', 
    fontWeight: 'italic',
    fontSize: '26px',
    color:'#f5a51b',
};

var styleNumbers = {
    'font-family': 'Lato', 
    fontWeight: 'italic',
    fontSize: '18px',
    color:'#f5a51b',
  };

  var styleNumbers2 = {
    'font-family': 'Lato', 
    fontWeight: 'italic',
    fontSize: '15px',
    color:'#f5a51b',
  };


class Dashboard extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        let hideInstructions = localStorage.getItem('hideInstructions')
        this.state = {  prevIngredients : props.ingredients,
                        prevRecipes : props.recipes,
                        currency : userIn.user.currency,
                        username : userIn.user.username ,
                        email: userIn.user.email,
                        active_role:  userIn.user.role,
                        stockValue: 0,
                        stockRecipeValue: 0,
                        nb_items_in_stock: 0,
                        nb_items_recipes_in_stock:0,
                        activeModalInfo: false,
                        companySize: userIn.user.company_size,
                        companyType: (userIn.user.company_type!==undefined)?userIn.user.company_type:UNDEFINED,
                        posnow:  userIn.user.pos_now,
                        posnext:  userIn.user.pos_next,
                        hideInstructions: (!hideInstructions || Number(hideInstructions) == 0) ? false : true
                      }

       this.hideModal = this.hideModal.bind(this);
        props.dispatch(paymentActions.getCustomerCoupons());
        props.dispatch(recipesActions.stats());
        //props.dispatch(ingredientsActions.count());
        props.dispatch(ingredientsActions.getAll(true));
        props.dispatch(categoryIngredientActions.count());
        props.dispatch(categoryRecipeActions.count());
        props.dispatch(recipesActions.countOrders());
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(supplierActions.count())   
        props.dispatch(recipesActions.countMenus());
        props.dispatch(recipesActions.getAll(true));
    }

    componentDidMount() {
/*         let user = JSON.parse(localStorage.getItem('userIn'));
        if (user && user.token){
           this.props.dispatch(userActions.verify(user.token));
        } */
        let hideInstructions = localStorage.getItem('hideInstructions')
        if ((this.state.active_role >= ADMIN) &&
        ((this.state.companySize === undefined)
             || (this.state.companySize === 0)
             || (this.state.companyType === undefined)
             || (this.state.companyType === "0")
             || (this.state.posnow === undefined) 
             || (this.state.posnow === ""))
        )
        {
            setTimeout(() => {
                this.setState({ activeModalInfo: true });
            }, 2200);
        }
    }


    static getDerivedStateFromProps(props, state) {
        if((props.ingredients!==state.prevIngredients) && (props.ingredients.items)) {
            const ingredients = props.ingredients;
            let stockValue = 0; 
            let nb_items_in_stock = 0;
             ingredients.items.map((i,index) => {
                if (i && i.stock_price){
                    if (i.stock_price > 0) nb_items_in_stock +=1
                    stockValue+=Number(i.stock_price);
                }
            });
            return {prevIngredients : props.ingredients,
                    stockValue:Math.round(stockValue * 100) / 100,
                    nb_items_in_stock:nb_items_in_stock}
        }
        if((props.recipes !==state.prevRecipes) && (props.recipes.items)) {
            const recipes = props.recipes;
            let stockValue = 0; 
            let nb_items_in_stock = 0;
            recipes.items.map((i,index) => {
                if (i && i.stock_price){
                    if (i.stock_price > 0) nb_items_in_stock +=1
                    stockValue+=Number(i.stock_price);
                }
            });
            return {prevRecipe : props.recipes,
                    stockRecipeValue:Math.round(stockValue * 100) / 100,
                    nb_items_recipes_in_stock:nb_items_in_stock}
        }
        return null;
    }


    addCouponsNotificationSubscribe(coupons){
        return coupons.map((coupon,index) => {
            return(    
            <Grid.Row columns={2} >
               <Grid.Column width={10}>
                    <Card fluid as={Link} to={{pathname: '/profilepremium', state: {toggled: false}}} style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)','background-color': '#ffb86b'}}>
                       <Card.Content> 
                          <Image src={img_gift} style={{width:'50px'}}/>
                           <div style={{marginLeft:'2em','font-family': 'Lato','font-size': '14px','color': 'black'}}>
                              <br/>
                              <p style={{'font-weight':'600','font-size': '16px','color': 'black'}}>
                                        <FormattedMessage id="dashboard.cardCouponSubscribe.title"
                                                         defaultMessage='Benefit from your coupon'/>
                              </p>
                              <p style={{'font-weight':'800'}}><Link to={{pathname: '/profilepremium', state: {toggled: false}}}>

                                   <FormattedMessage id="dashboard.cardCouponSubscribe.elem1.content.title.begin"
                                                     defaultMessage="Subscription PROFESSIONAL first month for"/>{' '} 
                                    <FormattedNumber value={coupon.paid_amount} 
                                                  style="currency" 
                                                  minimumFractionDigits={(coupon.paid_amount) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(coupon.paid_amount)% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={coupon.currency}/>{' '}
                                   <FormattedMessage id="dashboard.cardCouponSubscribe.elem1.content.title.end"
                                                     defaultMessage="before "/>{' '}            
                                   <FormattedDate value={new Date(coupon.expiration_at)}
                                                  year='numeric'
                                                  month='long'
                                                  day='2-digit'/>
                               </Link></p>
                               <Button compact floated='right' style={styles.ButtonBluePremium}>
                                    <FormattedMessage id="dashboard.cardCouponSubscribe.elem1.content.bouton.benefit.text"
                                                      defaultMessage="Click here to benefit"/></Button>
                           </div>
                       </Card.Content>
                    </Card>
               </Grid.Column>
           </Grid.Row>
           )
        }
    )}

    addCouponsNotificationNoSubscribe(coupons){
        return coupons.map((coupon,index) => {
            return(    
            <Grid.Row columns={2} >
               <Grid.Column width={10}>
                    <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)','background-color': '#ffb86b'}}>
                       <Card.Content> 
                          <Image src={img_gift} style={{width:'50px'}}/>
                           <div style={{marginLeft:'2em','font-family': 'Lato','font-size': '14px','color': 'black'}}>
                              <br/>
                              <p style={{'font-weight':'600','font-size': '16px','color': 'black'}}><FormattedMessage id="dashboard.cardCouponNoSubscribe.title"
                                                   defaultMessage='Congratulation for your discount !!'/></p>
                              <p style={{'font-weight':'800'}}>
                                   <FormattedMessage id="dashboard.cardCouponNoSubscribe.elem1.content.title.begin"
                                                     defaultMessage="Discount"/> {' '}
                                   <FormattedNumber value={coupon.discount_amount} 
                                                  style="currency" 
                                                  minimumFractionDigits={(coupon.discount_amount) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(coupon.discount_amount)% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={coupon.currency}/>{' '}
                                    <FormattedMessage id="dashboard.cardCouponNoSubscribe.elem1.content.title.end"
                                                     defaultMessage="applied on your next invoice"/> {' '}
                               </p>
                           </div>
                       </Card.Content>
                    </Card>
               </Grid.Column>
           </Grid.Row>
           )
        }
    )}

    OnTypeChange = (e, data) => {
        this.setState({ companyType: data.value});
    }

    OnSizeChange = (e) => {
        this.setState({ companySize: e.target.value});
    }

    OnPosNowChange = (e) => {
        this.setState({ posnow: e.target.value });
    }

    OnPosNextChange = (e) => {
        this.setState({ posnext: e.target.value });
    }

    hideModal() {
        this.setState({ activeModalInfo: false });
    }

    assertPosnowField(){
        if ((this.state.posnow !== "")
              &&(trim(this.state.posnow).length>0)
              &&(trim(this.state.posnow).length<80))
          return true;
        return false;
    }

    assertSizeField(){
        let size = this.state.companySize
        if ((isInteger(size) || isNumeric(size))
          && (size !== "")
          && (size !== 0)
          && Math.trunc(size).toString().length<5)
          return true;
        return false;
    }

    updateSettings = (e) => {
        e.preventDefault();
        const { companySize,companyType,posnow,posnext,username,email} = this.state;
        const { dispatch} = this.props;
        

        this.setState({ submitted: true });

        if (!this.assertSizeField()){
            return;
        }

        if (!this.assertPosnowField()){
            return;
        }

        if (companySize!==0 && companyType!=="0" && posnow) {
            let s_posnext = posnext
            if (posnext === undefined){
                s_posnext = ""
            }
            const user_infos = {
                     username:trim(username),
                     email:trim(email),
                     company_size:companySize,
                     company_type:companyType,
                     pos_now:trim(posnow),
                     pos_next:trim(s_posnext)
             }
            dispatch(userActions.update(user_infos,'/dashboard'));
            this.hideModal()
        }
    }

    toggleHidePreference= (e) => {
        const {hideInstructions} = this.state;
        if (hideInstructions){
            localStorage.setItem('hideInstructions', 0);
        }
        else{
            localStorage.setItem('hideInstructions', 1);
        }
        this.setState({hideInstructions:!hideInstructions});
    }

    render() { 
        const { recipes,ingredients,categoriesRecipe,categoriesIngredient,alert,dispatch,intl,coupons,payment,supplier } = this.props;
        const {stockValue,stockRecipeValue,nb_items_in_stock,nb_items_recipes_in_stock, currency,submitted,hideInstructions} = this.state;

        return ( 
                <SideLeftUncoverDashboard >
                        <Helmet>
                          <meta name="robots" content="noindex"/>
                        </Helmet>
                        <Grid stackable style={{ padding: '0em 0em 0em 2em','font-family': 'Lato','font-size': '12px','color': 'grey'}}>

                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {/* <Grid.Row columns={2} /> */}
                            {payment && payment.subscription && payment.subscription.active == true && 
                               payment.subscription.status=='past_due' &&
                               //payment.subscription.status=='trialing' &&
                               //(payment.subscription.status=='unpaid' || payment.subscription.status=='past_due') &&
                            <Grid.Row columns={1}>
                                <Grid.Column width={12}>
                                   <FormattedMessage id="dashboard.top.title.warning.content"
                                                     defaultMessage='Renew bill UNPAID: Click to update your payment card in Profile > Account at bottom page (card expired ...)'>
                                        { content => 
                                            <Message negative  as={Link} to='/profile'
                                                icon='credit card outline'
                                                header={<FormattedMessage id="dashboard.top.title.warning.title"
                                                                        defaultMessage='ACTION NEEDED before subscription SUSPENSION'/>}
                                                content={content}
                                                size='tiny'>
                                        </Message>}
                                    </FormattedMessage>
                                </Grid.Column>
                            </Grid.Row>}
                            <Grid.Row columns={1}>
                                <Grid.Column width={12}>
                                {hideInstructions && 
                                     <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}
                                            onClick= {this.toggleHidePreference} size='small' >
                                        <Card.Content style={{ paddingRight:'2px', paddingBottom:'2px'}}>
                                            <Image floated='left' src={img_list}   style={{width:'38px'}} />
                                            <Card.Header><br/>
                                                   <FormattedMessage id="dashboard.card.hide.title"
                                                                      defaultMessage='Start well your activity'/> {'  '}&nbsp;&nbsp;
                                                     <Popup content= {<FormattedMessage id="dashboard.card.hide.title.news.popup"
                                                                                        defaultMessage="Quick steps to follow ..."/>}
                                                           trigger={<Label color='orange' size='tiny' onClick={''}>
                                                                           <FormattedMessage id="dashboard.card.hide.title.news"
                                                                                              defaultMessage="See main instructions"/>
                                                                    </Label>}
                                                            position='right center' 
                                                            size='tiny'       
                                                            />
                                           <div style={{textAlign:'right','vertical-align': 'bottom', paddingRight:'1px', paddingBottom:'1px'}}>
                                            <Button basic size='tiny' compact  onClick= {this.toggleHidePreference}> 
                                                    <FormattedMessage id="dashboard.card.show"
                                                                      defaultMessage="Show"/></Button></div>
                                            </Card.Header>
                                        </Card.Content>
                                     </Card>}
                                     {!hideInstructions &&
                                     <Card fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                        <Card.Content>
                                            <Card.Header color='green' size='tiny'>
                                                    <FormattedMessage id="dashboard.card0.title"
                                                                    defaultMessage='Start well your activity'/> {'  '}&nbsp;&nbsp;
                                                     <Popup content= {<FormattedMessage id="dashboard.card0.title.news.popup"
                                                                                              defaultMessage="Create your organization(s) - Invite teammate(s)"/>}
                                                           trigger={<Label color='orange' size='tiny' as={Link} to='/organisation'>
                                                                           <FormattedMessage id="dashboard.card0.title.news"
                                                                                              defaultMessage="Create one (or several) company(s) - Work with your team(s)"/>
                                                                    </Label>}
                                                            position='right center' 
                                                            size='tiny'       
                                                            /> 
                                            </Card.Header>
                                            {/* <br/> */}
                                            <Image floated='left' size='tiny' src={img_list} style={{width:'40px'}}/>
                                            <div  style={{'marginLeft': '20px','float':'left','width':'80%','font-family': 'Lato','font-size': '12px','color': 'grey'}}>
                                            {/* <br/> */}
                                                    <p>
                                                        <Icon as='i' name='address card' style={{'-webkit-text-fill-color':'#f5a51b'}}/>{' '}
                                                        <Link to='/profile' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem11.content.title"
                                                                            defaultMessage="I fill my company details (address, currency, organization)"/>
                                                        </Link>
                                                    </p>
                                                    <p>
                                                        <Icon as='i' name='group' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                        <Link to='/invitation' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem15.content.title"
                                                                            defaultMessage="I invite my teammates with appropriate permissions"/>
                                                        </Link>
                                                    </p>
                                                    <p>
                                                        <Icon as='i' name='shipping fast' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                        <Link to='/ingredientssuppliers' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem14.content.title"
                                                                            defaultMessage="I add my suppliers contact details (automatic email)"/>
                                                        </Link>
                                                    </p>
                                                    <p>
                                                        <Icon as='i' name='sitemap' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                        <Link to='/categoriesingredient' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem12.content.title"
                                                                            defaultMessage="I add my ingredients categories"/>
                                                        </Link>{' '}
                                                        <Link to='/categoriesrecipe' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem13.content.title"
                                                                            defaultMessage="and my recipes customized categories"/>
                                                        </Link>
                                                    </p>
                                                    <p>
                                                        <Icon as='i' name='shopping basket' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                        <Link to='/ingredients' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem1.content.title"
                                                                            defaultMessage="I create my ingredients (or items)"/>
                                                        </Link>{'   '}
                                                        <Label color='blue' size='tiny' tag as={Link} to='/ingredients'>
                                                                <FormattedMessage id="dashboard.card0.elem1.content.new"
                                                                                  defaultMessage="Fill multiple or import from file"/>
                                                        </Label>
                                                    </p>
                                                    <p><Icon name='food' style={{'-webkit-text-fill-color':'#f5a51b'}}/>{' '}
                                                        <Link  to='/recipes' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem2.content.title"
                                                                            defaultMessage="I create my recipes and technical forms"/>
                                                        </Link>{/* {'   '}
                                                        <Label color='green' size='tiny' tag as={Link} to='/ingredients'>
                                                                <FormattedMessage id="dashboard.card0.elem9.content.new"
                                                                                  defaultMessage="New automatic composition label"/>
                                                        </Label> */}
                                                    </p>
                                                    <p ><Icon name='block layout' style={{'-webkit-text-fill-color':'#f5a51b'}}/>{' '}
                                                        <Link  to='/menus' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem8.content.title"
                                                                              defaultMessage="I can compose and evaluate my menus"/>
                                                        </Link>{'   '}
                                                        <Label color='green' size='tiny' tag as={Link} to='/ingredients'>
                                                                <FormattedMessage id="dashboard.card0.elem11.content.new"
                                                                                  defaultMessage="Export your menus allergens matrix"/>
                                                        </Label>
                                                    </p>
                                                    <p ><Icon name='calendar alternate outline' style={{'-webkit-text-fill-color':'#f5a51b'}}/>{' '}
                                                        <Link  to='/productions' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem9.content.title"
                                                                              defaultMessage="I plan my production for coming days"/>
                                                        </Link>
                                                    </p>
                                                    <p><Icon name='cart' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                    <Link  to='/orders' style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem3.content.title"
                                                                            defaultMessage="I create my orders lists and verify deliveries compliance"/>
                                                    </Link>{/* {'   '}
                                                    <Label color='orange' size='tiny' tag as={Link} to='/orders'>
                                                                <FormattedMessage id="dashboard.card0.elem10.content.new"
                                                                                  defaultMessage="Global or split in suppliers orders"/>
                                                    </Label> */}
                                                    </p>
                                                     <p><Icon name='warehouse' style={{'-webkit-text-fill-color':'#f5a51b'}} />{' '}
                                                     <Link  to={{pathname:'/inventory',state:{startPage:1}}} style={{ color: 'grey' }}>
                                                            <FormattedMessage id="dashboard.card0.elem7.content.title"
                                                                            defaultMessage="I check my inventory status (storage areas, stock value)"/>
                                                    </Link>
                                                    </p>
                                                {/*   </ol> */}
                                                 {/*  <br/><br/> */}
                                                  {/* <Icon name='settings' color='grey'/>
                                                  <FormattedMessage id="dashboard.card0.elem9.content.title"
                                                                                defaultMessage="I customize:"/>  */}
                                                {/*  <br/> */}
{/*                                                   <ol>
                                                    <li>
                                                    <FormattedMessage id="dashboard.card0.elem8.content.title"
                                                                                    defaultMessage="I fill my categories "/> 
                                                    <ul>
                                                        <li><Link to='/categoriesrecipe'>
                                                            <FormattedMessage id="dashboard.card0.elem4.content.title"
                                                                                    defaultMessage="Recipes categories"/>
                                                        </Link>
                                                        </li><li>
                                                        <Link to='/categoriesingredient'>
                                                            <FormattedMessage id="dashboard.card0.elem6.content.title"
                                                                            defaultMessage="Ingredients categories"/>
                                                        </Link>
                                                        </li>
                                                        </ul>
                                                        </li>
                                                        <li>
                                                            <Link to='/ingredientssuppliers'>
                                                                <FormattedMessage id="dashboard.card0.elem5.content.title"
                                                                                defaultMessage="I fill my suppliers"/>
                                                        </Link>
                                                        </li>
                                                  </ol> */}
                                               {/* </ul> */}
                                            </div>
                                           </Card.Content>
                                           <span style={{textAlign:'right','vertical-align': 'bottom', paddingRight:'4px', paddingBottom:'4px'}}>
                                                        <Button basic size='tiny' compact onClick= {this.toggleHidePreference}> 
                                                                <FormattedMessage id="dashboard.card.hide"
                                                                                defaultMessage="Hide"/></Button></span>  
                                     </Card>}
                                </Grid.Column>
                            </Grid.Row>
{/*                             {payment && payment.subscription && 
                                    (payment.subscription.active == true) && 
                                    coupons && coupons.length == 1 &&
                                this.addCouponsNotificationNoSubscribe(coupons)
                            } */}
                            {payment && payment.subscription && 
                                (isEmpty(payment.subscription) || payment.subscription.active == false) &&
                                coupons && coupons.length == 1 /*&& coupons[0].used == false*/ &&
                                this.addCouponsNotificationSubscribe(coupons)
                            }
                            <Grid.Row columns={2} style={{paddingTop:'1px'}}>
                                <Grid.Column width={6}>
                                    <Card as={Link} to='/recipes' fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                         <Card.Content> 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card1.title"
                                                                defaultMessage='RECIPES'/>
                                            </Card.Header>
                                        </Card.Content>     */}                       
                                        <Card.Content> 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card1.title"
                                                                defaultMessage='RECIPES'/>
                                            </Card.Header>
                                            <Image floated='left' size='tiny' src={img_pan} style={{width:'55px'}}/>
                                            <div style={{'font-family': 'Lato','font-size': '14px','color': 'grey'}}>
                                                <br/> 
                                                <p /* style={{'font-family': 'Lato','font-size': '16px','color': 'grey'}} */>{/*  <FormattedMessage id="dashboard.card1.elem1.content.title"
                                                                                    defaultMessage='Number :'/> */}
                                                    {recipes && !recipes.retrieving && recipes.stats && <h style={styleNumbersNumber}>{' '}{recipes.stats.count}</h>}
                                                </p>
                                                <p> <FormattedMessage id="dashboard.card1.elem3.content.title"
                                                                                    defaultMessage='Average gross margin'/>
                                                    {recipes && !recipes.retrieving && recipes.stats && <h style={styleNumbers}>
                                                        {' '}<FormattedNumber value={recipes.stats.average_margin} maximumFractionDigits="0" style="percent"/></h>}
                                                </p>
                                                <p><FormattedMessage id="dashboard.card1.elem2.content.title"
                                                                    defaultMessage='Categories'/>
                                                        {categoriesRecipe && !categoriesRecipe.counting && 
                                                            <h style={styleNumbers}>{' '}{categoriesRecipe.count}</h>}
                                                </p>
                                            </div>
                                        </Card.Content>
                                    </Card>
{/*                                     <Card as={Link} to='/recipes' fluid> 
                                        <Image centered fluid size='large' src={img_recipesDashboard}/>
                                        <Card.Content style={style} header={<FormattedMessage id="dashboard.card1.title"
                                                                                defaultMessage='RECIPES'/>} />
                                        <Card.Content >
                                            <p>
                                                {<FormattedMessage id="dashboard.card1.elem1.content.title"
                                                                                defaultMessage='Number:'/>}
                                            </p>
                                            <p align='center'>{recipes && !recipes.counting && <h style={style}>{recipes.count}</h>}</p>
                                        </Card.Content>
                                        <Card.Content >
                                            <p> 
                                                {<FormattedMessage id="dashboard.card1.elem2.content.title"
                                                                   defaultMessage='Categories :'/>}
                                            </p>
                                            <p align='center'>{categoriesRecipe && !categoriesRecipe.counting && 
                                                        <h style={style}>{categoriesRecipe.count}</h>}</p>
                                        </Card.Content>
                                    </Card> */}
                                    </Grid.Column>
                                   <Grid.Column width={6}>
                                    <Card as={Link} to='/ingredients' fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                             <Card.Content> 
                                                <Card.Header color='blue'>
                                                    <FormattedMessage id="dashboard.card2.title"
                                                                    defaultMessage='INGREDIENTS'/>
                                                </Card.Header>
                                            </Card.Content>      */}                      
                                            <Card.Content> 
                                                <Card.Header color='blue'>
                                                    <FormattedMessage id="dashboard.card2.title"
                                                                    defaultMessage='INGREDIENTS - Items'/>
                                                </Card.Header>
                                                <Image floated='left' size='tiny' src={img_bag} style={{width:'55px'}}/>
                                                <div style={{'font-family': 'Lato','font-size': '14px','color': 'grey'}}>
                                                    <br/> 
                                                    <p /* style={{'font-family': 'Lato','font-size': '16px','color': 'grey'}} */> {/* <FormattedMessage id="dashboard.card2.elem1.content.title"
                                                                                        defaultMessage='Number :'/> */}
                                                        {/* {ingredients && !ingredients.counting && <h style={styleNumbers}>{' '}{ingredients.count}</h>} */}
                                                        {ingredients && !ingredients.loading && ingredients.items && <h style={styleNumbersNumber}>{' '}{ingredients.items.length}</h>}
                                                    </p>
                                                    <p><FormattedMessage id="dashboard.card2.elem3.content.title"
                                                                        defaultMessage='Suppliers'/>
                                                            {supplier && !supplier.counting && 
                                                                <h style={styleNumbers}>{' '}{supplier.count}</h>}
                                                    </p>
                                                    <p ><FormattedMessage id="dashboard.card2.elem2.content.title"
                                                                        defaultMessage='Categories'/>
                                                            {categoriesIngredient && !categoriesIngredient.counting && 
                                                                <h style={styleNumbers}>{' '}{categoriesIngredient.count}</h>}
                                                    </p>
                                                </div>
                                            </Card.Content>
                                        </Card>
{/*                                     <Card as={Link} to='/ingredients' fluid> 
                                        <Image centered fluid size='large' src={img_dressing} />
                                        <Card.Content style={style} header={<FormattedMessage id="dashboard.card2.title"
                                                                                defaultMessage='INGREDIENTS'/>} />
                                        <Card.Content >
                                            <p>
                                                {<FormattedMessage id="dashboard.card2.elem1.content.title"
                                                                                defaultMessage='Number:'/>}
                                            </p>
                                            <p align='center'>{ingredients && !ingredients.counting && <h style={style}>{ingredients.count}</h>} </p>
                                        </Card.Content>
                                        <Card.Content >
                                            <p> 
                                                {<FormattedMessage id="dashboard.card2.elem2.content.title"
                                                                   defaultMessage='Categories :'/>}
                                            </p>
                                            <p align='center'>{categoriesIngredient && !categoriesIngredient.counting && 
                                                    <h style={style}>{categoriesIngredient.count}</h>}</p>
                                        </Card.Content>
                                    </Card> */}
                                  </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4} style={{paddingTop:'1px'}}>
                                <Grid.Column width={3}>
                                    <Card as={Link} to='/menus' fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                         <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card6.title"
                                                                defaultMessage='MENUS'/>
                                            </Card.Header>
                                        </Card.Content>    */}                        
                                        <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card6.title"
                                                                defaultMessage='MENUS'/>
                                            </Card.Header>
                                            <Image floated='left' size='tiny' src={img_menu} style={{width:'45px'}}/>
                                            <div style={{'font-family': 'Lato','font-size': '12px','color': 'grey'}}>
                                                <br/> 
                                                {recipes && recipes.menu_stats &&
                                                <div>
                                                <p >{/* <FormattedMessage id="dashboard.card6.elem1.content.title"
                                                                    defaultMessage='Number :'/> */}<h style={styleNumbersNumber}>{' '}{recipes.menu_stats.all}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card6.elem2.content.title"
                                                                    defaultMessage='Combos'/><h style={styleNumbers}>{' '}{recipes.menu_stats.combos}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card6.elem3.content.title"
                                                                    defaultMessage='Menus'/><h style={styleNumbers}>{' '}{recipes.menu_stats.menus}</h>
                                                </p>
                                                </div>}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                 </Grid.Column>
                                 <Grid.Column width={3} >
                                    <Card as={Link} to='/productions' fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>                   
                                        <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card8.title"
                                                                defaultMessage='PRODUCTIONS'/>
                                            </Card.Header>
                                            <Image floated='left' size='tiny' src={img_planning} style={{width:'45px'}}/>
                                            <div style={{'font-family': 'Lato','font-size': '12px','color': 'grey'}}>
                                                <br/> 
                                                {recipes && recipes.order_count &&
                                                <div>
                                                <p >{/* <FormattedMessage id="dashboard.card6.elem1.content.title"
                                                                    defaultMessage='Number :'/> */}<h style={styleNumbersNumber}>{' '}{recipes.order_count[3]}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card8.elem2.content.title"
                                                                    defaultMessage='Today'/><h style={styleNumbers}>{' '}{recipes.order_count[4]}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card8.elem3.content.title"
                                                                    defaultMessage='Next days'/><h style={styleNumbers}>{' '}{recipes.order_count[5]}</h>
                                                </p>
                                                </div>}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                 </Grid.Column>
                                <Grid.Column width={3}>
                                    <Card as={Link} to='/orderslist' fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                         <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card3.title"
                                                                defaultMessage='ORDERS'/>
                                            </Card.Header>
                                        </Card.Content>   */}                         
                                        <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card3.title"
                                                                defaultMessage='ORDERS'/>
                                            </Card.Header>
                                            <Image floated='left' size='tiny' src={img_order} style={{width:'45px'}}/>
                                            <div style={{'font-family': 'Lato','font-size': '12px','color': 'grey'}}>
                                                <br/> 
        {/*                                         <p> <FormattedMessage id="dashboard.card3.elem1.content.title"
                                                                                    defaultMessage='Number:'/>
                                                    {recipes && !recipes.counting && <h style={styleNumbers}>{' '}0</h>}
                                                </p> */}
                                                {recipes && recipes.order_count &&
                                                <div>
                                                <p ><FormattedMessage id="dashboard.card3.elem2.content.title"
                                                                    defaultMessage='Ongoing'/><h style={styleNumbers}>{' '}{recipes.order_count[0]}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card3.elem3.content.title"
                                                                    defaultMessage='Sent'/><h style={styleNumbers}>{' '}{recipes.order_count[1]}</h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card3.elem4.content.title"
                                                                    defaultMessage='Received'/><h style={styleNumbers}>{' '}{recipes.order_count[2]}</h>
                                                </p></div>}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                 </Grid.Column>
                                 <Grid.Column width={3}>
                                    <Card as={Link} to={{pathname:'/inventory',state:{startPage:1}}} fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                         <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card5.title"
                                                                defaultMessage='INVENTORY'/>
                                            </Card.Header>
                                        </Card.Content>   */}                       
                                        <Card.Content > 
                                            <Card.Header color='blue'>
                                                <FormattedMessage id="dashboard.card5.title"
                                                                defaultMessage='INVENTORY'/>
                                            </Card.Header>  
                                            <Image floated='left' size='tiny' src={img_box} style={{width:'43px'}}/>
                                            <div style={{'font-family': 'Lato','font-size': '12px','color': 'grey'}}>
                                                <div>
                                                <p><FormattedMessage id="dashboard.card5.elem2.content.title"
                                                                    defaultMessage='Ingredients'/><h style={styleNumbers}>
                                                    {' '}{nb_items_in_stock}</h>
                                                </p>
                                                <p style={{'font-size': '11px','color': 'grey'}}>&nbsp;&nbsp;<FormattedMessage id="dashboard.card5.elem1.content.title"
                                                                    defaultMessage='value :'/><h style={styleNumbers2}>
                                                    {' '}<FormattedNumber value={stockValue} style="currency" maximumFractionDigits="1" currencyDisplay="symbol" currency={currency}/></h>
                                                </p>
                                                <p><FormattedMessage id="dashboard.card5.elem3.content.title"
                                                                    defaultMessage='Recipes'/><h style={styleNumbers}>
                                                    {' '}{nb_items_recipes_in_stock}</h>
                                                </p>
                                                <p style={{'font-size': '11px','color': 'grey'}}>&nbsp;&nbsp;<FormattedMessage id="dashboard.card5.elem4.content.title"
                                                                    defaultMessage='value :'/><h style={styleNumbers2}>
                                                      {' '}<FormattedNumber value={stockRecipeValue} style="currency" maximumFractionDigits="1" currencyDisplay="symbol" currency={currency}/></h>
                                                </p>
                                                </div>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                 </Grid.Column>                                      
                            </Grid.Row>
                            <Grid.Row columns={2} style={{paddingTop:'1px'}}>
                            <Grid.Column width={6}>
                                    <Card /* as={Link} to='/contact' */ fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
{/*                                                 <Card.Content > 
                                                    <Card.Header color='blue'>
                                                        <FormattedMessage id="dashboard.card4.title"
                                                                        defaultMessage='Need Help'/>
                                                    </Card.Header>
                                                </Card.Content>    */}                        
                                                <Card.Content > {/* <br/> */}
                                                    <Card.Header color='blue'>
                                                        <FormattedMessage id="dashboard.card4.title"
                                                                        defaultMessage='Need Help'/>
                                                    </Card.Header>
                                                    <Image /*floated='left'*/ size='tiny' src={img_help} style={{width:'50px'}}/>
                                                    <div style={{marginLeft:'5em','font-family': 'Lato','font-size': '13px','color': 'grey'}}>
                                                        <br/>
                                                        <p style={{marginLeft:'4em'}}><FormattedMessage id="dashboard.card4.help.line0"
                                                                        defaultMessage='Our application improves regularly with your views.'/></p> 
                                                        <ul style={{marginLeft:'3em'}}>
                                                            <li><FormattedMessage id="dashboard.card4.help.line1"
                                                                        defaultMessage='You face an issue ?'/></li>
                                                            <li><FormattedMessage id="dashboard.card4.help.line2"
                                                                        defaultMessage='You wish to submit a feature ?'/></li>
                                                        </ul>
                                                        <a style={{marginLeft:'2em','font-size': '13px'}} href='/contact'>
                                                            <FormattedMessage id="dashboard.card4.contact.title"
                                                                        defaultMessage='Contact us'/></a>
                                                    </div>
                                                </Card.Content>
                                      </Card>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                    <Card /* as={Link} to='/contact' */ fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>                      
                                                <Card.Content > {/* <br/> */}
                                                    <Card.Header color='black'>
                                                        <FormattedMessage id="dashboard.card7.title"
                                                                        defaultMessage='Resources'/>
                                                    </Card.Header>
                                                    <Image /*floated='left'*/ size='tiny' src={img_news} style={{width:'50px'}}/>
                                                    <div style={{marginLeft:'5em','font-family': 'Lato','font-size': '13px','color': 'grey'}}>
                                                        {/* <br/> */}
                                                        <p style={{marginLeft:'4em'}}><FormattedMessage id="dashboard.card7.help.line0"
                                                                        defaultMessage='Popular articles.'/></p> 
                                                        <ul style={{marginLeft:'3em'}}>
                                                            <li>
                                                            { intl.locale == 'fr' &&
                                                                <a href='/article/la-marge-et-les-ratios-de-rentabilite' target="_blank">
                                                                      <FormattedMessage id="dashboard.card7.help.line1"
                                                                        defaultMessage='Restaurant profitability ratios and margins'/></a>}
                                                             { intl.locale == 'en' &&
                                                                <a href='/article/restaurant-margin-and-profitability-ratios' target="_blank"><FormattedMessage id="dashboard.card7.help.line1"
                                                                        defaultMessage='Restaurant profitability ratios and margins'/></a>}
                                                            </li>
                                                            <li>
                                                            { intl.locale == 'fr' &&
                                                                <a href='/article/fiche-technique-cuisine-recette-base' target="_blank"><FormattedMessage id="dashboard.card7.help.line2"
                                                                        defaultMessage='Recipe costing forms for restaurant'/></a>}
                                                            { intl.locale == 'en' &&                                                       
                                                                <a href='/article/recipe-professional-form-template' target="_blank"><FormattedMessage id="dashboard.card7.help.line2"
                                                                        defaultMessage='Recipe costing forms for restaurant'/></a>}
                                                            </li>
                                                            <li>
                                                            { intl.locale == 'fr' &&
                                                                <a href='/article/planifier-production-restaurant-recette' target="_blank"><FormattedMessage id="dashboard.card8.help.line2"
                                                                        defaultMessage='Restaurant production planning'/></a>}
                                                            { intl.locale == 'en' &&                                                       
                                                                <a href='/article/restaurant-production-planning-recipe' target="_blank"><FormattedMessage id="dashboard.card8.help.line2"
                                                                        defaultMessage='Restaurant production planning'/></a>}
                                                            </li>
                                                        </ul>
                                                        { intl.locale == 'fr' &&
                                                            <br/>
                                                         }
                                                        <a style={{marginLeft:'1em','font-size': '13px'}} href='/blog' target="_blank">
                                                            <FormattedMessage id="dashboard.card7.contact.title"
                                                                        defaultMessage='All blog articles ...'/></a>
                                                    </div>
                                                </Card.Content>
                                      </Card>
                            </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{paddingTop:'1px'}}>
                                <Grid.Column width={12}>
                                    <Card /* as={Link} to='/contact' */ fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>                       
                                                        <Card.Content >
                                                            <Image /*floated='left'*/ src={img_update} style={{width:'65px'}}/>
                                                            <div style={{marginLeft:'5em','font-family': 'Lato','font-size': '13px','color': 'grey'}}>
                                                                <br/>
                                                                <p style={{marginLeft:'4em'}}><FormattedMessage id="dashboard.card9.help.line0"
                                                                                defaultMessage='Last updates on the current version'/></p> 
                                                                <ul style={{marginLeft:'3em'}}>
{/*                                                                     <li><FormattedMessage id="dashboard.card9.help.line1"
                                                                                defaultMessage='Plan your productions for coming days in a few clicks, export your food needs at anytime.'/></li> */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line2"
                                                                                defaultMessage='Need to add sales tax on your ingredients and supplier orders, its possible now, check ingredients creation.'/></li> */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line3"
                                                                                defaultMessage='New teaspoon (tsp) and tablespoon (tbsp) units are now available for recipe quantities for volume/liquids.'/></li> */}
                                                                     <li><FormattedMessage id="dashboard.card9.help.line9"
                                                                                defaultMessage='Add extra users (ie invitations) to your organization.'/></li> 
                                                                    <li><FormattedMessage id="dashboard.card9.help.line11"
                                                                                defaultMessage='Add recipes consumption duration and automatic expiration date on productions.'/></li>
                                                                    <li><FormattedMessage id="dashboard.card9.help.line7"
                                                                                defaultMessage='Add photos to your preparation instructions.'/></li> 
                                                                    <li><FormattedMessage id="dashboard.card9.help.line5"
                                                                                defaultMessage='Bulk update ingredients from CSV file.'/></li> 
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line9"
                                                                                defaultMessage='Filter orders and productions by date range.'/></li>  */}
{/*                                                                     <li><FormattedMessage id="dashboard.card9.help.line8"
                                                                                defaultMessage='Order ingredients by item or a box of items.'/></li>  */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line5"
                                                                                defaultMessage='Simplify ingredients unitary cost to main units (per L, Kg ..)'/></li> */}
                     {/*                                                <li><FormattedMessage id="dashboard.card9.help.line6"
                                                                                defaultMessage='Improve variation on ingredients/recipes using net weight.'/></li> */}
                                                                    <li><FormattedMessage id="dashboard.card9.help.line10"
                                                                                defaultMessage='Traceability: expiration date and batch number added for all products.'/></li>
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line9"
                                                                                defaultMessage='Round your order to upper unit or supplier package weight.'/></li> */}
                                                                   {/* <li><FormattedMessage id="dashboard.card9.help.line8"
                                                                                defaultMessage='New pack management for purchased items.'/></li> */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line7"
                                                                                defaultMessage='Added multiple words search.'/></li> */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line4"
                                                                                defaultMessage='Added internal reference code for recipes and finished goods.'/></li> */}
                                                                    {/* <li><FormattedMessage id="dashboard.card9.help.line5"
                                                                                defaultMessage='Print your nutrition tags as a PNG image.'/></li>
                                                                    <li><FormattedMessage id="dashboard.card9.help.line6"
                                                                                defaultMessage='Energy (ie calories) info added to recipe templates.'/></li> */}
                                                                </ul>
                                                            </div>
                                                        </Card.Content>
                                            </Card>
                                </Grid.Column>
                            </Grid.Row>
                            <br/><br/>
                        </Grid>
                        <Modal open={this.state.activeModalInfo === true}
                               dimmer='blurring' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}>
                                <Header as='h3' icon='building outline' content={<FormattedMessage id="dashboard.modal.select.update.title"
                                                                                           defaultMessage="Please update your company details"/>}  />
                                <Modal.Content style={{paddingBottom:'17em'}}>
                                    <br/>
                                    <Form style={{paddingLeft:'0em'}} size='small' centered>
                                           
                                           <Label color='teal'><Icon name='building outline'/><FormattedMessage id="dashboard.modal.form.main.part1.title"
                                                                              defaultMessage='Company'/></Label>
                                           <br/> <br/>
                                           <Form.Field width='10' fluid> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="dashboard.modal.form.type.title"
                                                                              defaultMessage='Type of activity'/></label>
                                                    <FormattedMessage id="dashboard.modal.form.input.name.placeholder"
                                                                    defaultMessage='Select a type'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Select type="text" 
                                                                        placeholder={placeholder}
                                                                        id="companytype" 
                                                                        //fluid
                                                                        value={this.state.companyType}
                                                                        options={CompanyTypeOptions} 
                                                                        onChange={this.OnTypeChange}
                                                                        error={submitted &&  this.state.companyType === "0"}
                                                                        />}
                                                    </FormattedMessage>
                                                   {submitted && this.state.companyType === "0"  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="dashboard.modal.type.error.message"
                                                                                defaultMessage='select a type'/>
                                                        </i></label> 
                                                    }
                                            </Form.Field>
                                            <Form.Field width='10' fluid> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="dashboard.modal.form.size.title"
                                                                              defaultMessage='Number of employees (owner included)'/></label>
                                                    <FormattedMessage id="dashboard.modal.form.size.name.placeholder"
                                                                    defaultMessage='Enter a number'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input fluid type="text" 
                                                                        placeholder={placeholder}
                                                                        id="companysize" 
                                                                        value={this.state.companySize}
                                                                        onChange={this.OnSizeChange}
                                                                        error={submitted && !this.assertSizeField()}
                                                                        focus />} 
                                                    </FormattedMessage>
                                                    {submitted &&  !this.assertSizeField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="dashboard.modal.size.error.message"
                                                                                defaultMessage='Enter a number'/>
                                                        </i></label> 
                                                    }
                                            </Form.Field>
                                            <br/> <br/>
                                           
                                            <Label color='teal'> <Icon name='tv'/><FormattedMessage id="dashboard.modal.form.main.part2.title"
                                                                              defaultMessage='Point of sale'/></Label>
                                            <br/><br/>
                                            <Form.Field width='10' fluid> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="dashboard.modal.form.input.posnow"
                                                                              defaultMessage='Point of Sale (POS) currently in use'/></label>
                                                    <FormattedMessage id="dashboard.modal.form.input.posnow.placeholder"
                                                                    defaultMessage='POS name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input fluid type="text" 
                                                                        placeholder={placeholder}
                                                                        id="posnow" 
                                                                        value={this.state.posnow}
                                                                        onChange={this.OnPosNowChange}
                                                                        error={submitted && !this.assertPosnowField()}
                                                                        focus />}
                                                    </FormattedMessage>
                                                   {submitted && !this.assertPosnowField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="dashboard.modal.input.posnow.error.message"
                                                                                defaultMessage='Enter pos name (or "None")'/>
                                                        </i></label> 
                                                    }
                                            </Form.Field>
                                            <Form.Field width='10' fluid> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="dashboard.modal.form.input.posnext"
                                                                                                      defaultMessage='POS you wish to integrate soon'/></label>
                                                    <FormattedMessage id="dashboard.modal.form.input.posnext.placeholder"
                                                                defaultMessage='POS name'>
                                                        {placeholder =>                                      
                                                                <Form.Input
                                                                    fluid
                                                                    type="text"
                                                                    placeholder={placeholder}
                                                                    id="posnext"
                                                                    value={this.state.posnext}
                                                                    onChange={this.OnPosNextChange}
                                                                    //error={submitted && !this.assertPriceField()}
                                                                    focus/>}
                                                 </FormattedMessage> 
                                            </Form.Field>
                                    </Form>
                                </Modal.Content>
                                <Modal.Actions style={{margin:'0 0 0 0'}}>
                                    <Button style={styles.ButtonGreen} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="dashboard.modal.update.button.yes"
                                                                    defaultMessage='Update'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.updateSettings(event)}
                                        />
                                </Modal.Actions>
                        </Modal>
                        
                </SideLeftUncoverDashboard>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,ingredients,categoriesRecipe,categoriesIngredient,alert,dispatch,payment,supplier } = state;
    const { coupons } = state.payment;
    return {
        recipes,
        ingredients,
        categoriesRecipe,
        categoriesIngredient,
        payment,
        supplier,
        coupons,
        alert,
        dispatch,
    };
}

const connectedDashboard = injectIntl(connect(mapStateToProps)(Dashboard));
export { connectedDashboard as Dashboard };
 