import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {FormattedMessage,injectIntl} from 'react-intl';
import img_logo from '../Assets/ratatool-logo.png';
import img_window from '../Assets/windows.jpg';

class ConfirmDone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          username: '',
          password: '',
          submitted: false
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   }

    handleSubmit(e) {
        e.preventDefault();
    
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { username, password } = this.state;
        
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }


    render() {
        const { intl} = this.props;
        const {message,title,login} = this.props.location.state;
        const { username, password ,submitted } = this.state;
        return (
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
               <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}}>
                  <Grid.Column style={{ maxWidth: 450 }}>
                    <img src={img_logo} alt="logo" style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {' '}{intl.formatMessage({id:title})}
                    </Header>
                    <Message>
                      {intl.formatMessage({id:message})}
                    </Message>
                    {/*<Message>
                        <Link to="/login">Return to Login</Link> 
                    </Message>*/}
                  {login && 
                    <Form size='large' onSubmit={this.handleSubmit}>
                      <Segment stacked>
                        <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Username or Email'
                          type='text'
                          name='username'
                          value={username}
                          onChange={this.handleChange} 
                        />
                        {submitted && !username &&
                          <div className="help-block">Username is required</div>
                        }
                        <Form.Input
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='Password'
                          type='password'
                          name='password'
                          value={password}
                          onChange={this.handleChange} 
                        />
                        {submitted && !password &&
                          <div className="help-block">Password is required</div>
                        }
                        <Button color='grey' fluid size='large'>Login</Button>
                        <br/>
                        <Link to="/"><FormattedMessage id="confirmDone.form.button.backHome"
                                                            defaultMessage="Back to home"/></Link>
                      </Segment>
                    </Form>}
                    {!login && <Message>
                        <Link to="/login">&#8592;  <FormattedMessage id="confirmDone.form.button.backLogin"
                                                            defaultMessage="Back to Log in"/></Link> 
                    </Message>}
                    <br/><br/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 800,  height: '100vh'}}> 
                     <img src={img_window} alt="window" style={{width: '100vh',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <img src={img_logo} alt="logo" style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                      <Header as='h3' color='grey' textAlign='center'>
                        {' '}{intl.formatMessage({id:title})}
                      </Header>
                      <Message  size='large'>
                        {intl.formatMessage({id:message})}
                      </Message>
                      {/*<Message>
                          <Link to="/login">Return to Login</Link> 
                      </Message>*/}
                    {login && 
                      <Form size='large' onSubmit={this.handleSubmit}>
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Username or Email'
                            type='text'
                            name='username'
                            value={username}
                            onChange={this.handleChange} 
                          />
                          {submitted && !username &&
                            <div className="help-block">Username is required</div>
                          }
                          <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            type='password'
                            name='password'
                            value={password}
                            onChange={this.handleChange} 
                          />
                          {submitted && !password &&
                            <div className="help-block">Password is required</div>
                          }
                          <Button color='grey' fluid size='large'>Login</Button>
                          <br/>
                          <Link to="/"><FormattedMessage id="confirmDone.form.button.backHome"
                                                            defaultMessage="Back to home"/></Link>
                        </Segment>
                      </Form>}
                      {!login && <Message>
                          <Link to="/login">&#8592;  <FormattedMessage id="confirmDone.form.button.backLogin"
                                                            defaultMessage="Back to Log in"/></Link> 
                      </Message>}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
          );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedConfirmDone = injectIntl(connect(mapStateToProps)(ConfirmDone));
export { connectedConfirmDone as ConfirmDone };