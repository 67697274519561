import React, { Component } from 'react';
import { ingredientsActions,categoryIngredientActions,supplierActions,alertActions } from '../_actions';
import { connect } from 'react-redux';
import { Label,Button, Form, Header, Message,Icon,Segment,Select } from 'semantic-ui-react'
import {isNumeric,isFloat,trim,unitWeight,unitVolume,tablespoons,teaspoons,isInteger,dateWithoutTimezone} from '../_helpers';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
  WEIGHT_INSTRUCTION_LINE_KG,
  WEIGHT_INSTRUCTION_LINE_G,
  WEIGHT_INSTRUCTION_LINE_MG,
  WEIGHT_INSTRUCTION_LINE_OZ,
  WEIGHT_INSTRUCTION_LINE_LB,
  WEIGHT_INSTRUCTION_LINE_EX,
  VOLUME_INSTRUCTION_LINE_TITLE,
  VOLUME_INSTRUCTION_LINE_L,
  VOLUME_INSTRUCTION_LINE_ML,
  VOLUME_INSTRUCTION_LINE_CL,
  VOLUME_INSTRUCTION_LINE_FL,
  VOLUME_INSTRUCTION_LINE_GAL,
  VOLUME_INSTRUCTION_LINE_EX,
  QUANTITY_INSTRUCTION_LINE_TITLE,
  QUANTITY_INSTRUCTION_LINE_NUMBER,
  QUANTITY_INSTRUCTION_LINE_EX,
  allergens,
  VAToptions
} from '../_helpers';

import {FormattedMessage,injectIntl} from 'react-intl';
import { alertConstants } from '../_constants';
import {styles} from './Styles';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

const  WEIGHT = <FormattedMessage id="newingredient.newrecipe.quantity.select.weight"
                                    defaultMessage="Weight"/>;
const  VOLUME = <FormattedMessage id="newingredient.newrecipe.quantity.select.volume"
                                    defaultMessage="Volume"/>;
const  QUANTITY = <FormattedMessage id="newingredient.newrecipe.quantity.select.quantity"
                                    defaultMessage="Quantity"/>;

class NewIngredientNewRecipe extends Component {
  constructor(props) {
    super(props);
    const {intl} = props;
    this.state = {
      prevCategoriesIngredient : props.categoriesIngredient,
      name: "",
      ref_internal:"",
      price: "",
      note: "",    
      purchase_weight: "",
      purchase_volume: "",
      purchase_quantity: "",
      type_quantity: "",
      quantity: "",
      pack_units: 1,
      //category: {name:""},
      categorySelected : null,
      allergensSelected : null,
      supplierSelected : null,
      ref_supplier:"",
      highlighted: false,
      tax : 0.00,
      needDLC: false,
      batch_number: "",
      selectedDate : ""
    }
    props.dispatch(categoryIngredientActions.getAll());
    props.dispatch(supplierActions.getAll());


    registerLocale('en', en)
    registerLocale('fr', fr)
    if(intl.locale == 'fr'){
        setDefaultLocale('fr',fr)
    }else{
        setDefaultLocale('en',en)
    }

  }
/*   static getDerivedStateFromProps(props, state) {
    if ((props.categoriesIngredient!==state.prevCategoriesIngredient) && (props.categoriesIngredient.items)) {
      return {category: {name:props.categoriesIngredient.items[0].name}}
    }
  } */
  
  onNameChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  }

  onPriceChange = (e) => {
    e.preventDefault();
    this.setState({ price: e.target.value });
  }
  onNoteChange = (e) => {
    e.preventDefault();
    this.setState({ note: e.target.value });
  }

  resetPurchaseQuantities () {
    this.setState({ purchase_weight: "" });
    this.setState({ purchase_volume: ""});
    this.setState({ purchase_quantity: ""});
  }


  onCategoryChange = (e,data) => {
    e.preventDefault();
    const {categoriesIngredient} = this.props;
    if (data.value==""){
      this.setState({ categorySelected: null });
    }else{
      let category_id = data.value;
      let elem = categoriesIngredient.options.find(category => category.value == category_id)
      this.setState({ categorySelected: {id:category_id,
                                         name:elem.text} });
    }
  }

  onSupplierChange = (e,data) => {
    e.preventDefault();
    const {supplier} = this.props;
    if (data.value==""){
      this.setState({ supplierSelected: null });
    }else{
      let supplier_id = data.value;
      let elem = supplier.options.find(supplier => supplier.value == supplier_id)
      this.setState({ supplierSelected: {id:supplier_id,
                                         name: elem.text } });
    }
  }
  
  onAllergensChange = (e,data) => {
    e.preventDefault();
    //console.log(data)
    this.setState({ allergensSelected: data.value });
  }

  createAllergensList = (allergens) => {
    let listAllergens = [];  
    if (allergens){
      for (let elem of allergens){
        listAllergens.push({id:0,name:elem})
      }     
    }
    return listAllergens;  
  }

  onTypeQuantityChange = (e,data) => {
    e.preventDefault();
    const {quantity} = this.state;
    this.setState({ type_quantity: data.value });
    this.resetPurchaseQuantities();

    let type =  data.value
    if ( type === 'volume')
      this.setState({ purchase_volume: quantity });
    else if ( type === 'weight')
      this.setState({ purchase_weight:quantity});
    else if  ( type === 'quantity')
      this.setState({ purchase_quantity:quantity });
  }

  onUnitsPackagingChange = (e,data) => {
    e.preventDefault();
    this.setState({ pack_units: data.value });
  }

  onQuantityChange = (e) => {
    e.preventDefault();
    const {type_quantity} = this.state;
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    if (type_quantity === 'volume')
      this.setState({ purchase_volume: e.target.value.toLowerCase() });
    else if (type_quantity === 'weight')
      this.setState({ purchase_weight: e.target.value.toLowerCase() });
    else if  (type_quantity === 'quantity')
      this.setState({ purchase_quantity: e.target.value });
  }

  onRefInternalChange = (e) => {
    e.preventDefault();
    this.setState({ ref_internal: e.target.value });
  }

  onRefSupplierChange = (e) => {
    e.preventDefault();
    this.setState({ ref_supplier: e.target.value });
  }

  dlcClickHandler (event) {
    event.preventDefault();
/*     if (this.state.needDLC){
      this.setState({ note: " " });
    } */
    this.setState({ needDLC: !this.state.needDLC });
  };

  onBatchNumberChange = (e) => {
    e.preventDefault();
    this.setState({batch_number: e.target.value });
  }

  setExpirationDate (date){
    this.setState({ selectedDate: date})
  }

  onFocusQuantityField = (e,quantity) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  assertNameField(){
    if ((this.state.name != "")
          &&(trim(this.state.name).length>0))
      return true;
    return false;
  }

  assertSelectedWeight(){
    if (this.state.type_quantity != "")
      return true;
    return false;
  }

  assertQuantityField(){
    if (this.state.quantity != "")
      return true;
    return false;
  }

  assertPackField(){
    let units = Number(this.state.pack_units)
    if (isInteger(units)
        && (units > 0)
        && Math.trunc(units).toString().length<7)
      return true;
    return false;
  }

  assertPriceField(){
    let price = trim(this.state.price).replace(/,/g, '.')
    if ((isFloat(price) || isNumeric(price))
      && (this.state.price != "")
      && Math.trunc(this.state.price).toString().length<7)
      return true;
    return false;
  }

  assertQuantityPermittedIncluded(quantity){
    let isFound = false;
    let unitPart = "";
    // Filter some units not available for ingredients
    var volumes = unitVolume.filter((item) => !teaspoons.includes(item));
    volumes = volumes.filter((item) => !tablespoons.includes(item));
    const {type_quantity} = this.state;
    let inputs= quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);
    
    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (type_quantity == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          console.log(unitPart);
        }
      }

    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
      }
    }

    if (type_quantity == 'volume'){
      for (let unit of volumes ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (type_quantity == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          break;
        }
     }
    }else {
        if (inputs.length > 1)
          return false
        isFound = true;
    }
    return isFound;
  }

  assertQuantityFieldsValidity(){
    const {quantity} = this.state;
    let clean_quantity = trim(quantity).toLowerCase();
    //this.setState({ quantity: clean_quantity});
    if (!this.assertQuantityPermittedIncluded(clean_quantity)) return false;
    return true;
  }




  onSubmit = (e) => {
    e.preventDefault();
    const {name,price,note,purchase_weight,purchase_volume,purchase_quantity,categorySelected,allergensSelected,
            supplierSelected,ref_internal,ref_supplier,tax,pack_units,selectedDate,batch_number} = this.state;
    const { dispatch } = this.props;
 
    dispatch(alertActions.clear());
    this.setState({ submitted: true });

    if (!this.assertNameField()){
      //dispatch(alertActions.error('name field should be filled'));
      //toaster_error('Submit Error','name field should be filled');
      return;
    }

    if (!this.assertPriceField()){
      //dispatch(alertActions.error('price field should be numeric (<1000)'));
      //toaster_error('Submit Error','price field should be numeric (<1000)');
      return;
    }

    if (!this.assertSelectedWeight()){
      //dispatch(alertActions.error('weight type should be selected'));
      //toaster_error('Submit Error','weight type should be selected');
      return;
    }


    if (!this.assertQuantityField()){
      //dispatch(alertActions.error('Quantity field should be filled'));
      //toaster_error('Submit Error','Quantity field should be filled');
      return;
    }

    if (!this.assertQuantityFieldsValidity()){
      //dispatch(alertActions.error('Quantity field format invalid (follow instructions)'));
      //toaster_error('Submit Error','Quantity field format invalid (follow instructions)');
      return;
    }

    if (!this.assertPackField()){
      return;
    }

    if (name && price) {
        const ingredient = {
                name:trim(name.replace(/\n|\r|\t/g, " ")),
                price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                note:note,
                purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
                purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
                purchase_quantity:trim(purchase_quantity),
                category:categorySelected,
                supplier:supplierSelected,
                allergens:this.createAllergensList(allergensSelected),
                ref_internal:ref_internal,
                ref_supplier:ref_supplier,
                tax: tax,
                pack_units:pack_units,
                date_expire:(selectedDate && selectedDate != "" ) ? dateWithoutTimezone(selectedDate):null,
                batch_number:batch_number
        }
        dispatch(ingredientsActions.add(ingredient));
        this.props.callback(name);
    }
    
  }


  onTaxChange = (e,data) => {
    e.preventDefault();
    //console.log(data.value)
    this.setState({ tax: data.value});
  }


  render() {
    const { adding ,categoriesIngredient,supplier,alert,intl} = this.props;
    const {categorySelected,allergensSelected,supplierSelected,quantity,type_quantity,highlighted,submitted,pack_units,needDLC,batch_number,selectedDate} = this.state;

    const renderLabel = (option) => ({
      color: option.color,
      content: option.text,
    })

    return (
        <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
        <br/>
        <Header as='h3' /*color='grey' textAlign='left'*/>
                  <FormattedMessage id="newingredient.newrecipe.header.title"
                                    defaultMessage=' Add a new ingredient   '/>
        </Header>
         {alert.message  &&  alert.type == alertConstants.ERROR &&
             <Message  error size='small' > {alert.message}</Message>
          }
          <Form.Group>
          <Form.Field width={12}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.newrecipe.form.input.name"
                                             defaultMessage='Name * '/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.input.name.placeholder"
                                      defaultMessage='Enter a name'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="name"
                                  value={this.state.name}
                                  onChange={this.onNameChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  error={submitted && !this.assertNameField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertNameField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.newrecipe.form.name.error.message"
                                                defaultMessage='name is empty'/>
                        </i></label> 
                      }
          </Form.Field>
          <Form.Field width={3}>
                    <label><FormattedMessage id="newingredient.newrecipe.form.code.title"
                                             defaultMessage='Internal code'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.code.placeholder"
                                      defaultMessage='ref'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="code"
                                  value={this.state.ref_internal}
                                  onChange={this.onRefInternalChange}
                                  //onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
          </Form.Field>
          <Form.Field width={3}>
                      <label><FormattedMessage id="newingredient.newrecipe.form.tax.title"
                                              defaultMessage='Sales Tax'/>
                      </label>
                      <FormattedMessage id="newingredient.newrecipe.form.tax.placeholder"
                                        defaultMessage='Tax'>
                          {placeholder =>     
                                <Select compact
                                  options={VAToptions} 
                                  type="text"
                                  placeholder={placeholder}
                                  id="VAT"
                                  value={this.state.tax}
                                  onChange={this.onTaxChange}
                                  //clearable
                                  />}
                  </FormattedMessage>
                  </Form.Field>
          </Form.Group>
          <Form.Group>
          <Form.Field width={6}>
                    <label><FormattedMessage id="newingredient.newrecipe.form.category.name"
                                              defaultMessage='Category'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.category.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection search  
                                          options={categoriesIngredient && categoriesIngredient.options} 
                                          value = {categorySelected && categorySelected.id} 
                                          placeholder={placeholder} 
                                          onChange={this.onCategoryChange}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage>    
          </Form.Field>
          <Form.Field width={10}>
                    <label><FormattedMessage id="newingredient.newrecipe.form.allergens.name"
                                                defaultMessage='Allergens'/>
                      </label>
                      <FormattedMessage id="newingredient.newrecipe.form.allergens.placeholder"
                                        defaultMessage='Add allergens'>
                            {placeholder =>   
                                    <Form.Dropdown selection     
                                            multiple
                                            search
                                            options={ [{key:'peanuts',text:intl.formatMessage(allergens.peanuts),value:'peanuts', color:'red'},
                                                       {key:'celery',text:intl.formatMessage(allergens.celery),value:'celery',color:'olive'},
                                                       {key:'crustaceans',text:intl.formatMessage(allergens.crustaceans),value:'crustaceans',color:'pink'},
                                                       {key:'egg',text:intl.formatMessage(allergens.egg),value:'egg',color:'yellow'},
                                                       {key:'fish',text:intl.formatMessage(allergens.fish),value:'fish',color:'teal'},
                                                       {key:'gluten',text:intl.formatMessage(allergens.gluten),value:'gluten',color:'brown'},
                                                       {key:'lupin',text:intl.formatMessage(allergens.lupin),value:'lupin',color:'grey'},
                                                       {key:'milk',text:intl.formatMessage(allergens.milk),value:'milk',color:'blue'},
                                                       {key:'molluscs',text:intl.formatMessage(allergens.molluscs),value:'molluscs',color:'purple'},
                                                       {key:'mustard',text:intl.formatMessage(allergens.mustard),value:'mustard',color:'brown'},
                                                       {key:'nuts',text:intl.formatMessage(allergens.nuts),value:'nuts',color:'violet'},
                                                       {key:'sesame',text:intl.formatMessage(allergens.sesame),value:'sesame',color:'yellow'},
                                                       {key:'soya',text:intl.formatMessage(allergens.soya),value:'soya',color:'green'},
                                                       {key:'sulphites',text:intl.formatMessage(allergens.sulphites),value:'sulphites',color:'orange'}] } 
                                            value = {allergensSelected} 
                                            placeholder={placeholder} 
                                            onChange={this.onAllergensChange}
                                            renderLabel={renderLabel}
                                            //style={styles.FontLato12}
                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                      />}
                      </FormattedMessage> 
          </Form.Field>
          </Form.Group>
          <Segment  style={{background:'#fafafa'}}>
          <Form.Group >
            <Form.Field width={7}>
                    <label><FormattedMessage id="newingredient.newrecipe.form.supplier.name"
                                              defaultMessage='Supplier '/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.supplier.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection search 
                                          options={supplier && supplier.options} 
                                          value = {supplierSelected && supplierSelected.id} 
                                          placeholder={placeholder} 
                                          onChange={this.onSupplierChange}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage>    
                  </Form.Field>    
          <Form.Field width={5}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.newrecipe.form.input.price"
                                             defaultMessage='Price (Purchase Cost) *'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.input.price.placeholder"
                                      defaultMessage='Add a purchase price'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="price"
                                  value={this.state.price}
                                  onChange={this.onPriceChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  error={submitted && !this.assertPriceField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertPriceField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.newrecipe.form.price.error.message"
                                                defaultMessage='price is numeric'/>
                        </i></label> 
                      }
          </Form.Field>
          <Form.Field width={4}>
                    <label><FormattedMessage id="newingredient.newrecipe.form.supplier.code.title"
                                             defaultMessage='Supplier code'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.supplier.code.placeholder"
                                      defaultMessage='reference'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="suppliercode"
                                  value={this.state.ref_supplier}
                                  onChange={this.onRefSupplierChange}
                                  //onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
                 </Form.Field>
        </Form.Group>
          <Label size='small'>
                <FormattedMessage id="newingredient.newrecipe.form.purchase.type.title"
                                  defaultMessage='Select purchase type (amount). Then fill as advised '/>
          </Label>
        <br/><br/>
        {pack_units >= 1 && quantity && 
                    <p style={{textAlign:'right',color:'black',fontSize:'13px'}}>
                       <FormattedMessage id="newingredient.newrecipe.form.purchase.text1"
                                          defaultMessage='I buy'/>  <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}</span>{' '}
                       <FormattedMessage id="newingredient.newrecipe.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                         <span style={{color:'#00CC99',fontSize:'14px'}}> {pack_units}</span>
                    </p>}
        <Form.Group>
        <Form.Field width={6}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.newrecipe.form.select.purchase.type"
                                              defaultMessage='Type of purchase *'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.select.purchase.placeholder"
                                      defaultMessage='Select a type of quantity'>
                          {placeholder =>   
                                  <Form.Select  
                                  options={ [{key:'volume',text:<span><Icon name='flask' color='teal'/>{VOLUME}</span>,value:'volume'},
                                              {key:'weight',text:<span><Icon name='balance' color='teal'/>{WEIGHT}</span>,value:'weight'},
                                            {key:'quantity',text:<span><Icon name='copy' color='teal'/>{QUANTITY}</span>,value:'quantity'}] } 
                                          value = {type_quantity} 
                                          placeholder={placeholder} 
                                          onChange={this.onTypeQuantityChange}
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                          error={submitted && !this.assertSelectedWeight()}/>}
                    </FormattedMessage>    
                    {submitted && !this.assertSelectedWeight()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.newrecipe.form.select.purchase.error.message"
                                                defaultMessage='purchase type to select'/>
                        </i></label> 
                      }
          </Form.Field>
          <Form.Field width={6}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.newrecipe.form.input.quantity"
                                             defaultMessage='Quantity *'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.input.quantity.placeholder"
                                      defaultMessage='Enter a quantity'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="quantity"
                                  value={quantity}
                                  onChange={this.onQuantityChange}
                                  onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                />}
                      </FormattedMessage>
                      {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.newrecipe.form.quantity.error.message"
                                                defaultMessage='quantity format invalid (follow instructions)'/>
                        </i></label> 
                      }
          </Form.Field>
          <Form.Field width={4}>
                        <label><FormattedMessage id="newingredient.newrecipe.form.input.packaging"
                                                defaultMessage='Case contains'/>
                        </label>
                        <FormattedMessage id="newingredient.newrecipe.form.input.packaging.units.placeholder"
                                          defaultMessage='Number units'>
                            {placeholder =>     
                                  <Form.Input
                                      type="number"
                                      placeholder={placeholder}
                                      id="units"
                                      min="1"
                                      value={pack_units}
                                      onChange={this.onUnitsPackagingChange}
                                      //onFocus={(e)=>this.onFocusPackField(e)}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      //style={{minWidth:'10em',maxWidth:'30em'}}
                                      error={submitted && !this.assertPackField()}
                                      icon={pack_units == 1 ? <Icon name='tag' color="grey"/>: <Icon name='tags' color="teal"/>}
                                      iconPosition='left'
                                    />}
                          </FormattedMessage>
                          {submitted && !this.assertPackField() &&
                            <label style={{color:'red', }}><i>
                                  <FormattedMessage id="newingredient.newrecipe.form.pack.error.message"
                                                    defaultMessage='units number invalid'/>
                            </i></label> 
                          }
                </Form.Field>  
          </Form.Group>
          { highlighted 
            && type_quantity == 'weight' 
/*             &&  <Message> 
                <p>
                    <FormattedMessage id="newingredient.newrecipe.form.weight.instructions.message"
                                      defaultMessage='Quantity weight: kg, kilogram, kilograms, g, gram, grams, mg (ex: 3kg)'/>
                </p></Message> */
                &&  <Message size='tiny' 
                      icon='balance'>       
                <Icon name='balance' color='teal'/> 
                <Message.Content>
                  <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                  <ul>
                    <br/>
                    <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                    <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                    <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                    <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                    <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                  </ul> 
                  {WEIGHT_INSTRUCTION_LINE_EX}
                </Message.Content>                                     
            </Message>
          }
          {  highlighted 
              && type_quantity == 'volume'   
/*               && <Message> 
              <p>
                    <FormattedMessage id="newingredient.newrecipe.form.volume.instructions.message"
                                      defaultMessage='Quantity volume: l, liter, liters, ml, milliliter, milliliters (ex: 2l)'/>
              </p></Message> */
              &&  <Message size='tiny' 
                      icon='flask' >       
                <Icon name='flask' color='teal'/> 
                <Message.Content>
                  <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                  <ul>
                    <br/>
                    <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                    <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                    <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                    <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                    <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                  </ul> 
                  {VOLUME_INSTRUCTION_LINE_EX}
                </Message.Content>                                     
            </Message>
          }
          { highlighted 
             && type_quantity == 'quantity' 
/*              && <Message> 
            <p>
                    <FormattedMessage id="newingredient.newrecipe.form.quantity.instructions.message"
                                      defaultMessage='Quantity : number of units (numeric)'/>
            </p></Message> */
            &&  <Message size='tiny' 
                          icon='copy'>       
                <Icon name='copy' color='teal'/> 
                <Message.Content>
                  <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                  <ul>
                    <br/>
                    <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                  </ul> 
                  {QUANTITY_INSTRUCTION_LINE_EX}
                </Message.Content>                                     
              </Message>
            }
          {!needDLC && <span style={{paddingTop:'0em'}}><Icon link circular basic
                                      name='add' 
                                      color='grey'
                                      onClick={(event)=>this.dlcClickHandler(event)}/>
                                    {' '}<FormattedMessage id="newingredient.newrecipe.form.dlc.button"
                                                           defaultMessage='Traceability : expiration date, batch'/>
                                </span>}        
          {needDLC && 
                   <Form.Field /* width={16} */ style={{paddingTop:'0em'}}>
                    <label style={{color:'grey'}}>
                    <Icon link circular basic
                                      name='minus' 
                                      color='grey'
                                      onClick={(event)=>this.dlcClickHandler(event)}/>
                                       {' '}<FormattedMessage id="newingredient.newrecipe.form.dlc.button"
                                             defaultMessage='Traceability : expiration date, batch'/></label>
                    <Form.Group style={{paddingTop:'0.5em', paddingLeft:'1.5em'}}> 
                          <Form.Field>
                                <label><Icon name='calendar alternate outline'/>
                                        {' '}<FormattedMessage id="newingredient.newrecipe.form.dlc.title"
                                                              defaultMessage='Use by'/>
                                 </label>
                                 <FormattedMessage id="newingredient.newrecipe.form.dlc.placeholder"
                                                        defaultMessage='---'>
                                          {placeholder =>     
                                                  <DatePicker selected={selectedDate} 
                                                              onChange={(date) => this.setExpirationDate(date)}
                                                              placeholderText = {placeholder} 
                                                              locale={intl.locale}
                                                              dateFormat="P"/>}
                                 </FormattedMessage>
                          </Form.Field>
                          <Form.Field>
                                 <label><FormattedMessage id="newingredient.newrecipe.form.extrasupplier.batchnumber.code.title"
                                                                    defaultMessage='Batch number'/>
                                 </label>
                                 <FormattedMessage id="newingredient.newrecipe.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='XXX_1234'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={batch_number}
                                                          onChange={this.onBatchNumberChange}
                                                          //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                 </FormattedMessage>
                          </Form.Field>                  
                    </Form.Group>
              </Form.Field>}  
              </Segment>
{/*           <Form.Field>
                    <label><FormattedMessage id="newingredient.newrecipe.form.note.title"
                                             defaultMessage='Note (Optional)'/>
                    </label>
                    <FormattedMessage id="newingredient.newrecipe.form.note.placeholder"
                                      defaultMessage='Note'>
                         {placeholder =>     
                              <Form.TextArea
                                  type="text"
                                  placeholder={placeholder}
                                  id="note"
                                  value={this.state.note}
                                  onChange={this.onNoteChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                />}
                      </FormattedMessage>
          </Form.Field> */}
        <br/>
        <Button style={styles.ButtonGreen} //basic color='teal' 
/*                 disabled={this.state.name.length === 0 
                                  || this.state.price.length === 0 
                                  ||(this.state.purchase_quantity.length===0 
                                       && this.state.purchase_volume.length=== 0 
                                       && this.state.purchase_weight.length=== 0)} */
                  onClick={this.onSubmit}>
          <FormattedMessage id="newingredient.newrecipe.button.submit"
                            defaultMessage='Submit New Ingredient'/>
        </Button>
       {/* {alert.message && toaster_dispatch(dispatch,alert,intl)} */}
        {adding && 
           <img alt='load' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        }
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { categoriesIngredient,supplier, dispatch,adding,alert } = state;
  return {
      adding,
      supplier,
      categoriesIngredient,
      dispatch,
      alert
  };
}

const connectedNewIngredientNewRecipe = injectIntl(connect(mapStateToProps)(NewIngredientNewRecipe));
export { connectedNewIngredientNewRecipe as NewIngredientNewRecipe };
