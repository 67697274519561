import { blogConstants } from '../_constants';

export function blog(state = {}, action) {
  switch (action.type) {

    case blogConstants.GETALL_REQUEST:
      return { 
        retrieving: true 
      };
    case blogConstants.GETALL_SUCCESS:
      return  { 
        ...state,
        retrieving: false,
        posts: action.blog,
      };
    case blogConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    case blogConstants.GET_HOME_POSTS_REQUEST:
        return { 
          retrieving: true 
        };
    case blogConstants.GET_HOME_POSTS_SUCCESS:
        return  { 
          retrieving: false,
          posts: action.blog,
        };
    case blogConstants.GET_HOME_POSTS_FAILURE:
        return { 
          error: action.error
        };

    case blogConstants.GET_REQUEST:
        return { 
          retrieving: true 
        };
    case blogConstants.GET_SUCCESS:
        return  { 
          ...state,
          retrieving: false,
          post: action.blog,
        };
    case blogConstants.GET_FAILURE:
        return { 
          error: action.error
        };

    default:
      return state
  }
}