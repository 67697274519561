import { ingredientConstants } from '../_constants';
import { ingredientService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const ingredientsActions = {
    getAll,
    add,
    addFromFile,
    updateFromFile,
    get,
    update,
    delete: _delete,
    deleteSelect,
    categorySelect,
    stockAreaSelect,
    count,
    price_evolutions,
    history_ingredient,
    recipes,
    update_nutrition,
    export_csv,
    export_xlsx,
    update_stock,
    reset_stock,
    inventory,
    ingredient_weight_variation,
    updateImage,
    getImage,
    deleteImage,
    ingredient_add_extra_supplier,
    ingredient_edit_extra_supplier,
    ingredient_delete_extra_supplier,
    //ingredient_default_supplier,
    getExtraSuppliers,
    ingredient_update_stock_area,
    updatePriceDefaultSupplier,
};

function getAll(short=false) {
    return dispatch => {
        dispatch(request());

        ingredientService.getAll(short)
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.GETALL_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.GETALL_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.GETALL_FAILURE, error } }
}

function get(id,link) {
    return dispatch => {
        dispatch(request());

        ingredientService.get(id)
            .then(
                ingredients => {
                    dispatch(success(ingredients));
                    //history.push('/ingredientdetails');
                    if (link){
                        history.push(link);
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.GET_REQUEST} }
    function success(ingredients) { return { type: ingredientConstants.GET_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.GET_FAILURE, error } }
}

function add(ingredient,link) {
    return dispatch => {
        dispatch(request());

        ingredientService.add(ingredient)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.add.success.title"
                                                    defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.success.content"
                                                        defaultMessage="Ingredient Added Successfully"/>

                    dispatch(alertActions.success({title: "ingredient.actions.ingredient.add.success.title",
                                                   content:"ingredient.actions.ingredient.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.title"
                                                       defaultMessage="Ingredient Action Error"/>

                    if ("name" in error) {
                        //dispatch(alertActions.error("name: " + error.name[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.name"
                                                            defaultMessage="name:  {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.name"}));
                     }else if ("price" in error) {
                        //dispatch(alertActions.error("price: " +error.price[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.price"
                                                            defaultMessage="price: {message}"
                                                            values={{message:error.price[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.price"}));
                    }else if ("category" in error) {
                        //dispatch(alertActions.error("category: " + error.category[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        //dispatch(alertActions.error(error.non_field_errors[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.non_field_errors"}));
                    }else {
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }
                } 
            );
    };

    function request() { return { type: ingredientConstants.ADD_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.ADD_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.ADD_FAILURE, error } }
}



function addFromFile(ingredient,link) {
    return dispatch => {
        dispatch(request());

        ingredientService.add(ingredient)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.add.success.title"
                                                    defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.success.content"
                                                        defaultMessage="Ingredient Added Successfully"/>

                    dispatch(alertActions.success({title: "ingredient.actions.ingredient.add.success.title",
                                                   content:"ingredient.actions.ingredient.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.title"
                                                       defaultMessage="Ingredient Action Error"/>

                    if ("name" in error) {
                        //dispatch(alertActions.error("name: " + error.name[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.name"
                                                            defaultMessage="name:  {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.name"}));
                     }else if ("price" in error) {
                        //dispatch(alertActions.error("price: " +error.price[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.price"
                                                            defaultMessage="price: {message}"
                                                            values={{message:error.price[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.price"}));
                    }else if ("category" in error) {
                        //dispatch(alertActions.error("category: " + error.category[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        //dispatch(alertActions.error(error.non_field_errors[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.non_field_errors"}));
                    }else {
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }
                } 
            );
    };

    function request() { return { type: ingredientConstants.ADD_FROM_FILE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.ADD_FROM_FILE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.ADD_FROM_FILE_FAILURE, error } }
}


function updateFromFile(ingredient,link) {
    return dispatch => {
        dispatch(request());

        ingredientService.updateBatch(ingredient)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.update.success.title"
                                                    defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.update.success.content"
                                                        defaultMessage="Ingredient Updated Successfully"/>

                    dispatch(alertActions.success({title: "ingredient.actions.ingredient.update.success.title",
                                                   content:"ingredient.actions.ingredient.update.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.title"
                                                       defaultMessage="Ingredient Action Error"/>

                    if ("name" in error) {
                        //dispatch(alertActions.error("name: " + error.name[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.name"
                                                            defaultMessage="name:  {message}"
                                                            values={{message:error.name[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.name"}));
                     }else if ("price" in error) {
                        //dispatch(alertActions.error("price: " +error.price[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.price"
                                                            defaultMessage="price: {message}"
                                                            values={{message:error.price[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.price"}));
                    }else if ("category" in error) {
                        //dispatch(alertActions.error("category: " + error.category[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.category"
                                                            defaultMessage="category: {message}"
                                                            values={{message:error.category[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.category"}));
                    }else if ("non_field_errors" in error) {
                        //dispatch(alertActions.error(error.non_field_errors[0]));
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.add.error.non_field_errors"
                                                            defaultMessage="{message}"
                                                            values={{message:error.non_field_errors[0]}}/>
                        dispatch(alertActions.error({title: "ingredient.actions.ingredient.add.error.title",
                                                     content:"ingredient.actions.ingredient.add.error.non_field_errors"}));
                    }else {
                        //dispatch(alertActions.error(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                    content:error.message,
                                                    stringMode: true }));
                    }
                } 
            );
    };

    function request() { return { type: ingredientConstants.ADD_FROM_FILE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.ADD_FROM_FILE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.ADD_FROM_FILE_FAILURE, error } }
}
 

function _delete(id,link) {
    return dispatch => {
        dispatch(request(id));

        ingredientService.delete(id)
            .then(
                ingredients => { 
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.delete.success.title"
                                                       defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.delete.success.content"
                                                          defaultMessage="Ingredient(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.ingredient.delete.success.title',
                                                   content:'ingredient.actions.ingredient.delete.success.content'}));
                     if (link){
                           history.push('/');
                           history.push(link);
                     }
                },
                error => {
                    dispatch(failure(error))
                    //dispatch(alertActions.error(error));
                    
/*                     if (error.statusText == "Unauthorized")
                    {
                        let title_text = <FormattedMessage id="ingredient.actions.ingredient.delete.error.title"
                                                            defaultMessage="Ingredient Action Error"/>
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.delete.error.content"
                                                            defaultMessage="Remove any recipe using this ingredient before (see ingredient details for relations)"/>
                        dispatch(alertActions.error({title:'ingredient.actions.ingredient.delete.error.title',
                                                     content:'ingredient.actions.ingredient.delete.error.content'}));
                    }else{ */
                         dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                      content:error.message,
                                                      stringMode: true }));
/*                     } */

                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                }
            );
    };

    function request() { return { type: ingredientConstants.DELETE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.DELETE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.DELETE_FAILURE, error } }
}


function deleteSelect(id,link) {
    return dispatch => {
        dispatch(request(id));

        ingredientService.deleteSelect(id)
            .then(
                ingredients => { 
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.delete.success.title"
                                                       defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.delete.success.content"
                                                          defaultMessage="Ingredient(s) Deleted Successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.ingredient.delete.success.title',
                                                   content:'ingredient.actions.ingredient.delete.success.content'}));
                     if (link){
                           history.push('/');
                           history.push(link);
                     }
                },
                error => {


                    if (error.status = 401)
                    {
                        //Case HTTP_401_UNAUTHORIZED, only warn some not deleted 
                        dispatch(warn(error))
                        let title_text = <FormattedMessage id="ingredient.actions.ingredient.delete.warn.title"
                                                defaultMessage="Ingredient Action Warning"/>
                        let content_text = <FormattedMessage id="ingredient.actions.ingredient.delete.warn.content"
                                                defaultMessage="Some ingredients not deleted (used by recipes or not permitted)"/>
                        dispatch(alertActions.warn({title:'ingredient.actions.ingredient.delete.warn.title',
                                                    content:'ingredient.actions.ingredient.delete.warn.content'}));
                    }else{
                        
                        dispatch(failure(error))
                        //dispatch(alertActions.error(error));
                        
    /*                     if (error.statusText == "Unauthorized")
                        {
                            let title_text = <FormattedMessage id="ingredient.actions.ingredient.delete.error.title"
                                                                defaultMessage="Ingredient Action Error"/>
                            let content_text = <FormattedMessage id="ingredient.actions.ingredient.delete.error.content"
                                                                defaultMessage="Remove any recipe using this ingredient before (see ingredient details for relations)"/>
                            dispatch(alertActions.error({title:'ingredient.actions.ingredient.delete.error.title',
                                                        content:'ingredient.actions.ingredient.delete.error.content'}));
                        }else{ */
                            dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                        content:error.message,
                                                        stringMode: true }));
    /*                     } */
                    }
                    
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                }
            );
    };

    function request() { return { type: ingredientConstants.DELETE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.DELETE_SUCCESS, ingredients } }
    function warn(ingredients) { return { type: ingredientConstants.DELETE_WARN, ingredients } }
    function failure(error) { return { type: ingredientConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        ingredientService.count()
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.COUNT_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.COUNT_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.COUNT_FAILURE, error } }
}

function update(id,ingredient,link,isSameRoute=0) {
    return dispatch => {
        dispatch(request());

        ingredientService.update(id,ingredient)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
/*                      let title_text = <FormattedMessage id="ingredient.actions.ingredient.update.success.title"
                                                         defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.update.success.content"
                                                          defaultMessage="Ingredient Updated Successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.ingredient.update.success.title',
                                                  content:'ingredient.actions.ingredient.update.success.content'})); */
                    if (link){
                        if (isSameRoute) history.push('/');
                        history.push(link);
                    }else{
                        //history.push('/');
                        //history.push('/ingredients');
                    }
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/ingredientdetails/'+id)
                }
            );
    };

    function request() { return { type: ingredientConstants.UPDATE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.UPDATE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.UPDATE_FAILURE, error } }
}


function updatePriceDefaultSupplier(id,ingredient,link,isSameRoute=0) {
    return dispatch => {
        dispatch(request());

        ingredientService.updatePriceDefaultSupplier(id,ingredient)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/ingredientdetails/'+id)
                }
            );
    };

    function request() { return { type: ingredientConstants.UPDATE_PRICE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.UPDATE_PRICE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.UPDATE_PRICE_FAILURE, error } }
}


function recipes(id) {
    return dispatch => {
        dispatch(request());

        ingredientService.recipes(id)
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.RECIPES_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.RECIPES_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.RECIPES_FAILURE, error } }
}



function price_evolutions() {
    return dispatch => {
        dispatch(request());

        ingredientService.price_evolutions()
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.PRICE_EVOLUTIONS_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.PRICE_EVOLUTIONS_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.PRICE_EVOLUTIONS_FAILURE, error } }
}


function history_ingredient(id) {
    return dispatch => {
        dispatch(request());

        ingredientService.history_ingredient(id)
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.HISTORY_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.HISTORY_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.HISTORY_FAILURE, error } }
}


function update_nutrition(id,nutrition) {
    return dispatch => {
        dispatch(request());

        ingredientService.update_nutrition(id,nutrition)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.nutrition.update.success.title"
                                                       defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.nutrition.update.success.content"
                                                        defaultMessage="Nutrition Added Successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.nutrition.update.success.title',
                                                    content:'ingredient.actions.nutrition.update.success.content'}));
                    history.push('/');
                    history.push('/ingredients');
                },
                error => {
                    dispatch(failure(error.response.statusText));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/ingredients');
                }
            );
    };

    function request() { return { type: ingredientConstants.UPDATE_NUTRITION_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.UPDATE_NUTRITION_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.UPDATE_NUTRITION_FAILURE, error } }
}


function export_csv(parameters) {
    return dispatch => {
        dispatch(request());

        ingredientService.export_csv(parameters)
            .then(
                ingredients => {
                    dispatch(success(ingredients));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.EXPORT_CSV_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.EXPORT_CSV_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.EXPORT_CSV_FAILURE, error } }
}


function export_xlsx(parameters) {
    return dispatch => {
        dispatch(request());

        ingredientService.export_xlsx(parameters)
            .then(
                ingredients => {
                    dispatch(success(ingredients));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.EXPORT_XLSX_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.EXPORT_XLSX_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.EXPORT_XLSX_FAILURE, error } }
}


function inventory(date,supplier,storageArea) {
    return dispatch => {
        dispatch(request());

        ingredientService.inventory(date,supplier,storageArea)
            .then(
                ingredients => {
                    dispatch(success(ingredients));
                    history.push({pathname:'/inventory',state:{startPage:1}});
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.INVENTORY_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.INVENTORY_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.INVENTORY_FAILURE, error } }
}


function update_stock(id,quantity,page) {
    return dispatch => {
        dispatch(request());

        ingredientService.update_stock(id,quantity)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:page}});
                }
            );
    };

    function request() { return { type: ingredientConstants.UPDATE_STOCK_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.UPDATE_STOCK_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.UPDATE_STOCK_FAILURE, error } }
}


function reset_stock() {
    return dispatch => {
        dispatch(request());

        ingredientService.reset_stock()
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    history.push('/');
                    history.push({pathname:'/inventory',state:{startPage:1}});
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    //history.push('/');
                    //history.push({pathname:'/inventory',state:{startPage:1}});
                }
            );
    };

    function request() { return { type: ingredientConstants.RESET_STOCK_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.RESET_STOCK_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.RESET_STOCK_FAILURE, error } }
}


function ingredient_weight_variation(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_weight_variation(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.recipe.weight.variation.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.recipe.weight.variation.success.content"
                                                         defaultMessage="Updated weight variation"/>
                    dispatch(alertActions.success({title:'ingredient.actions.recipe.weight.variation.success.title',
                                                   content:'ingredient.actions.recipe.weight.variation.success.content'}));
                    history.push('/');
                    history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/'); 
                    history.push('/ingredientdetails/'+id);   
                }
            );
    };

    function request() { return { type: ingredientConstants.WEIGHT_VARIATION_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.WEIGHT_VARIATION_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.WEIGHT_VARIATION_FAILURE, error } }
}


function updateImage(id,image) {
    return dispatch => {
        dispatch(request());

        ingredientService.updateImage(id,image)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    //history.push('/ingredientdetails/'+id);   
                }
            );
    };

    function request() { return { type: ingredientConstants.UPDATE_IMAGE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.UPDATE_IMAGE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.UPDATE_IMAGE_FAILURE, error } }
}


function getImage(id) {
    return dispatch => {
        dispatch(request());

        ingredientService.getImage(id)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true })); 
                }
            );
    };

    function request() { return { type: ingredientConstants.GET_IMAGE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.GET_IMAGE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.GET_IMAGE_FAILURE, error } }
}


function deleteImage(id) {
    return dispatch => {
        dispatch(request());

        ingredientService.deleteImage(id)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                },
                error => {
                    dispatch(failure(error)); 
                }
            );
    };

    function request() { return { type: ingredientConstants.DELETE_IMAGE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.DELETE_IMAGE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.DELETE_IMAGE_FAILURE, error } }
}


function ingredient_add_extra_supplier(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_add_extra_supplier(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
/*                     let title_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.content"
                                                         defaultMessage="Updated weight variation"/>
                    dispatch(alertActions.success({title:'ingredient.actions.recipe.extra.supplier.success.title',
                                                   content:'ingredient.actions.recipe.extra.supplier.success.content'})); */
                    history.push('/');
                    history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/'); 
                    history.push('/ingredientdetails/'+id);     
                }
            );
    };

    function request() { return { type: ingredientConstants.EXTRA_SUPPLIER_ADD_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.EXTRA_SUPPLIER_ADD_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.EXTRA_SUPPLIER_ADD_FAILURE, error } }
}

function ingredient_edit_extra_supplier(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_edit_extra_supplier(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
/*                     let title_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.content"
                                                         defaultMessage="Updated weight variation"/>
                    dispatch(alertActions.success({title:'ingredient.actions.recipe.extra.supplier.success.title',
                                                   content:'ingredient.actions.recipe.extra.supplier.success.content'})); */
                    //history.push('/');
                    //history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/'); 
                    history.push('/ingredientdetails/'+id);      
                }
            );
    };

    function request() { return { type: ingredientConstants.EXTRA_SUPPLIER_EDIT_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.EXTRA_SUPPLIER_EDIT_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.EXTRA_SUPPLIER_EDIT_FAILURE, error } }
}


function ingredient_delete_extra_supplier(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_delete_extra_supplier(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
/*                     let title_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.recipe.extra.supplier.success.content"
                                                         defaultMessage="Updated weight variation"/>
                    dispatch(alertActions.success({title:'ingredient.actions.recipe.extra.supplier.success.title',
                                                   content:'ingredient.actions.recipe.extra.supplier.success.content'})); */
                    history.push('/');
                    history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/'); 
                    history.push('/ingredientdetails/'+id);    
                }
            );
    };

    function request() { return { type: ingredientConstants.EXTRA_SUPPLIER_DELETE_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.EXTRA_SUPPLIER_DELETE_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.EXTRA_SUPPLIER_DELETE_FAILURE, error } }
}



/* function ingredient_default_supplier(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_default_supplier(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    //history.push('/');
                    //history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: ingredientConstants.DEFAULT_SUPPLIER_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.DEFAULT_SUPPLIER_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.DEFAULT_SUPPLIER_FAILURE, error } }
} */


 function getExtraSuppliers() {
    return dispatch => {
        dispatch(request());

        ingredientService.getExtraSuppliers()
            .then(
                ingredients => dispatch(success(ingredients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: ingredientConstants.GETALL_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.GETALL_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.GETALL_FAILURE, error } }
}




function ingredient_update_stock_area(id,params) {
    return dispatch => {
        dispatch(request());

        ingredientService.ingredient_update_stock_area(id,params)
            .then(
                ingredients =>{
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.recipe.weight.stockarea.success.title"
                                                       defaultMessage="Recipe Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.recipe.weight.stockarea.success.content"
                                                         defaultMessage="Updated stock area"/>
                    dispatch(alertActions.success({title:'ingredient.actions.recipe.weight.stockarea.success.title',
                                                   content:'ingredient.actions.recipe.weight.stockarea.success.content'}));
                    history.push('/');
                    history.push('/ingredientdetails/'+id);
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/'); 
                    history.push('/ingredientdetails/'+id);   
                }
            );
    };

    function request() { return { type: ingredientConstants.STOCK_AREA_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.STOCK_AREA_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.STOCK_AREA_FAILURE, error } }
}



function categorySelect(id,link) {
    return dispatch => {
        dispatch(request(id));

        ingredientService.categorySelect(id)
            .then(
                ingredients => { 
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.category.success.title"
                                                       defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.category.success.content"
                                                          defaultMessage="Ingredient(s) updated successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.ingredient.delete.success.title',
                                                   content:'ingredient.actions.ingredient.category.success.content'}));
                     if (link){
                           history.push('/');
                           history.push(link);
                     }
                },
                error => {

                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                        content:error.message,
                                                        stringMode: true }));
                    
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                }
            );
    };

    function request() { return { type: ingredientConstants.CATEGORY_ALL_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.CATEGORY_ALL_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.CATEGORY_ALL_FAILURE, error } }
}


function stockAreaSelect(id,link) {
    return dispatch => {
        dispatch(request(id));

        ingredientService.stockAreaSelect(id)
            .then(
                ingredients => { 
                    dispatch(success(ingredients));
                    let title_text = <FormattedMessage id="ingredient.actions.ingredient.stockarea.success.title"
                                                       defaultMessage="Ingredient Action"/>
                    let content_text = <FormattedMessage id="ingredient.actions.ingredient.stockarea.success.content"
                                                          defaultMessage="Ingredient(s) updated successfully"/>
                    dispatch(alertActions.success({title:'ingredient.actions.ingredient.stockarea.success.title',
                                                   content:'ingredient.actions.ingredient.stockarea.success.content'}));
                     if (link){
                           history.push('/');
                           history.push(link);
                     }
                },
                error => {

                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                        content:error.message,
                                                        stringMode: true }));
                    
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                }
            );
    };

    function request() { return { type: ingredientConstants.STOCKAREA_ALL_REQUEST } }
    function success(ingredients) { return { type: ingredientConstants.STOCKAREA_ALL_SUCCESS, ingredients } }
    function failure(error) { return { type: ingredientConstants.STOCKAREA_ALL_FAILURE, error } }
}