
import React, { Component } from 'react'
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Card,
  Label,
  Checkbox,
  List,
} from 'semantic-ui-react';

import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import {HashLink as Link} from 'react-router-hash-link';
import {FormattedMessage,FormattedNumber,injectIntl,defineMessages} from 'react-intl';
import {MainFooter,ResponsiveContainer} from './Segments';
import { paymentActions } from '../_actions';

import img_contact from '../Assets/contract.png'
import img_timemanagement from '../Assets/time-management.png'
import img_briefcase from '../Assets/briefcase.png'
import {styles} from './Styles';

const  CURRENCY_SYMBOL = <FormattedMessage id="currency.symbol"
                                            defaultMessage="$"/>;


const html_tags = defineMessages({
   html_title: {
        id: 'pricing.html.title.tag',
        defaultMessage: "Pricing: Recipe cost software, recipe food cost calculator, restaurant software solution, food stock and inventory software",
   },
   meta_name_description: {
        id: 'pricing.meta.name.description.tag',
        defaultMessage: "A pricing plan for each need, depending on your type of restaurant (multi-site) or cuisine",
   }
});

class PricingLayout extends Component {

  constructor(props) {
      super(props);    
      let userIn = JSON.parse(localStorage.getItem('userIn'));
      this.state={
            prevPayment : props.payment,
            //labelAnnually : <FormattedMessage id="pricing.segment.billing.type.annually"
            //                                  defaultMessage="Annual billing"/>,
            //labelMonthly : <FormattedMessage id="pricing.segment.billing.type.monthly"
            //                                  defaultMessage="Monthly billing"/>,
            toggled : true,
            userIn : userIn,
            isMobile: false,
      };

      this.props.dispatch(paymentActions.getAvailableMemberships());
      
      if (userIn){
        this.props.dispatch(paymentActions.getCustomerMembership());
        this.props.dispatch(paymentActions.getCustomerCoupons());
        //this.props.dispatch(paymentActions.getSubscription());
      }
      
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozUserSelect = 'none';
    document.body.style.MsUserSelect = 'none';
  }

  componentWillUnmount(){
    document.body.style.userSelect = 'text';
    document.body.style.WebkitUserSelect = 'text';
    document.body.style.MozUserSelect = 'text';
    document.body.style.MsUserSelect = 'text';
  }

  resize() {
    this.setState({isMobile: window.innerWidth <= 1024});
  }

/*   toggleCheckBox= (e, data) => {
    //console.log(data);
    this.setState({toggled:data.checked});
  } */

  togglePeriodicity= (e) => {
    const {toggled} = this.state;
    this.setState({toggled:!toggled});
  }

  render() {
    const {memberships} = this.props.payment;
    const {intl,coupons,payment} =  this.props;
    const {toggled,userIn,isMobile} = this.state;
    //let toggle_label =toggled ?intl.formatMessage({id:'pricing.segment.billing.type.annually'})
    //                          :intl.formatMessage({id:'pricing.segment.billing.type.monthly'});

    let pricingBoxHeight = isMobile?'730px':'740px';
    let pricingBoxHeightStandard = isMobile?'560px':'500px';
    let pricingBoxHeightFree = isMobile?'380px':'350px';

                            
    let used_locale = intl.locale;
    let consistMemberships =[];
    if (memberships){

        consistMemberships =  memberships.filter( m => {
                return m.interval == (toggled?'Annually':'Monthly');
        });

        if (userIn && payment && payment.customer){
           consistMemberships =  consistMemberships.filter( m => {
                    used_locale = (payment.customer.currency == 'EUR'?'fr':'en');
                    return m.country == used_locale;
            });
        }else{
            consistMemberships =  consistMemberships.filter( m => {
                  return m.country == used_locale;
          });
        }
        //console.log(consistMemberships)
    }

    return (
        <ResponsiveContainer>
          <Helmet>
{/*             <title>Découvrez les tarifs Ratatool : des formules premium adaptées à chaque besoin et type de cuisine ou restaurant</title>
            <meta name="description" content="Ratatool permet de calculer le coût matière de vos recettes, d'estimer vos marges et ratios de rentablilité, d'éditer vos fiches techniques recettes et vos étiquettes nutritionnelles INCO"/> */}
            <title>{intl.formatMessage(html_tags.html_title)}</title>
            <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
            { intl.locale == 'fr' &&
                          <link rel="canonical" href="https://fr.ratatool.com/pricing" />}

             { intl.locale == 'en' &&
                            <link rel="canonical" href="https://www.ratatool.com/pricing" />}

            <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/pricing" />
            <link rel="alternate" hreflang="en" href="https://www.ratatool.com/pricing" />
            <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/pricing" />
            
          </Helmet>
          <Segment vertical style={{ border: 'none', padding: '5em 0em 5em 0em' }}>
            <Grid  container stackable verticalAlign='middle' textAlign='center' columns={3} >
              <Grid.Row columns={1}> 
                <Grid.Column width={12}>
                 <Grid.Row>
                    <Header as='h1' textAlign='center'>
                          <FormattedMessage id="pricing.segment.top.title"
                                            defaultMessage="Plans & Pricing"/>
                    </Header>
                    </Grid.Row>
                    <br/><br/>
                    <Grid.Row>
                    <p><span style={{ fontSize: '1.6em',color:'#fffff'}}>
                          <FormattedMessage id="pricing.segment.top.description"
                                            defaultMessage="A plan adapted to each need for maximum gain"/>
                    </span></p>
                    </Grid.Row>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row/>
              { (consistMemberships.length > 0) &&
              <Grid.Row>
                <Grid.Column width={8} fluid>
                  <Button.Group >
                    <Button primary={!toggled} 
                            onClick={this.togglePeriodicity}>
                      <FormattedMessage id="pricing.segment.billing.type.monthly"
                                          defaultMessage="MONTHLY billing"/></Button>
                      <FormattedMessage id="pricing.segment.billing.separate.or"
                                        defaultMessage="or">
                        {inside => <Button.Or text={inside}/>}
                      </FormattedMessage>
                    <Button primary={toggled} 
                            onClick= {this.togglePeriodicity}> 
                        <FormattedMessage id="pricing.segment.billing.type.annually"
                            defaultMessage="ANNUAL billing"/></Button>
                  </Button.Group>
                  {/* <Segment style={{ fontSize: '1.2em', backgroundColor: '#f5f5f5' }} > 
                                    <Checkbox  toggle
                                      //label={toggle_label}
                                      //defaultChecked='false'
                                      checked={toggled}
                                      //style={{ fontSize: '1em', fontWeight:'700'}}
                                      onChange= {this.toggleCheckBox}/>
                    {/* </Segment> */}
                    <br/> <br/>
                    {toggled && <p>
                    <span style={{ fontSize: '1.2em',color:'#fffff'}}>
                            <FormattedMessage id="pricing.segment.billing.offer.annually.title"
                                              defaultMessage="Available immediately with"/>{' '}
                    </span>
                    <span style={{ fontSize: '1.33em',color:'#f58d37'}}>
                            <FormattedMessage id="pricing.segment.billing.offer.annually.message"
                                              defaultMessage="2 months FREE"/>
                    </span></p>}
                    {!toggled && <p>
                      <span style={{ fontSize: '1.2em',color:'#fffff'}}>
                            <FormattedMessage id="pricing.segment.billing.offer.monthly.title"
                                              defaultMessage="Available immediately"/>
                      </span>
                    </p>}
                    { coupons && coupons.length == 1 && <p>
                    <span style={{ fontSize: '1.33em',color:'#528deb'}}>
                            <FormattedMessage id="pricing.segment.billing.offer.coupon.title"
                                              defaultMessage="Coupon discount applied"/></span>
                    </p>}
{/*                     {!toggled &&<p><span style={{ fontSize: '1.2em',color:'#fffff', fontWeight:'800', marginTop:'1em'}}>
                          <FormattedMessage id="pricing.segment.billing.description"
                                            defaultMessage="No contract, cancel anytime"/>
                    </span></p>} */}
                </Grid.Column>
              </Grid.Row>}
              {intl.locale=='fr' && <Grid.Row>
                  <p style={{ fontSize: '1.13em' }}>
                      {'Financer intégralement votre abonnement avec le chèque numérique en France ('}
                   <a href='https://www.iledefrance.fr/un-cheque-numerique-pour-des-commerces-connectes' target="_blank" >
                       en île de France
                   </a>{' et '}
                   <a href='https://www.francenum.gouv.fr/comprendre-le-numerique/quelles-sont-les-aides-financieres-pour-la-numerisation-de-votre-entreprise' 
                      target="_blank">
                       autres régions
                   </a>{')'}</p> 
              </Grid.Row>}
              { userIn && (consistMemberships.length > 0) &&
              <Grid.Row columns={3} style={{ padding: '4em 0em 4em 0em' }}>
                    <Grid.Column width={5}>
                      <Card as={Link} raised
                            to={{pathname: '/storeplan', state: {type: consistMemberships[2].membership_type,
                                                                name: consistMemberships[2].name,
                                                                interval: consistMemberships[2].interval,
                                                                price : consistMemberships[2].price,
                                                                bill_locale : used_locale
                                }}}
/*                             to={'/storeplan/'+ consistMemberships[2].membership_type +'/'
                                             + consistMemberships[2].name +'/'
                                             + consistMemberships[2].interval +'/'
                                             + consistMemberships[2].price} */
                            style={{ backgroundColor: '#fcfcfc','border': 'none;'}} 
                            fluid raised>
                             <Card.Content>
                              <Label color='orange' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[2].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.three.summary"
                                                        defaultMessage="Further with production planning, menus, tracking and nutrition. Manage multiple companies, work with your teammates"/>
                                  </span></p>
                                  <Label color='teal' size='large' style={{ fontSize: '0.8em', marginBottom: '0.4em' }}>
                                              <Icon name='bolt' color='yellow'/>
                                              <FormattedMessage id="pricing.plan.three.summary.popular"
                                                                defaultMessage="POPULAR"/>
                                  </Label>
                             </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        { !toggled &&
                                             <div>
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                               <FormattedNumber value={consistMemberships[2].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>
                                               {/*  <FormattedNumber value={consistMemberships[2].price}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="pricing.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
{/*                                               <span style={{ fontSize: '0.60em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                            
                                        }
                                       {  toggled &&
                                          <div>
                                             {(!coupons || coupons.length == 0) &&
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                                  <FormattedNumber value={consistMemberships[2].price/12} 
                                                  style="currency" 
                                                  minimumFractionDigits={(consistMemberships[2].price/12) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(consistMemberships[2].price/12 )% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={(used_locale == "fr"?'EUR':'USD')}/>
                                                </span>
                                               }
                                            {coupons && coupons.length == 1 && 
                                              <span style={{ fontSize: '1em', color:'#5BA3FF' }}>
                                               <strike><FormattedNumber value={consistMemberships[2].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[2].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[2].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/></strike><br/>
                                               <FormattedNumber value={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount))/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>}
                                              {/* <FormattedNumber value={consistMemberships[1].price/12}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="pricing.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                              </p>
{/*                                               <span style={{ fontSize: '0.60em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                        }
                                    </Card.Header>
                                     { !toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="pricing.plan.monthly.three.line.coupon.message"
                                                        defaultMessage="First month at"/>{' '}
                                          <FormattedNumber value={Number(consistMemberships[2].price) - Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                                    { toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="pricing.plan.annually.three.line.coupon.message"
                                                        defaultMessage="Price discount of"/>{' '}
                                          <FormattedNumber value={Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                              </Card.Content>
{/*                               <Card.Content >
                                  <p style={{ fontSize: '1.13em' }}>
                                      <FormattedMessage id="pricing.plan.three.line.description"
                                                        defaultMessage="Fits growing businesses"/>
                                  </p> */}
                                  {/* <br/> */}
{/*                                   <Button basic color='black' as={Link} to='/register'>
                                      <FormattedMessage id="pricing.plan.three.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button> 
                              </Card.Content>*/}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeight}}>
                                <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes, Sub-Recipes & Ingredients"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature11.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
{/*                                   <p style={{fontSize:'14px', 'fontWeight':'600'}}><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature4.text"
                                                        defaultMessage="Sub-Recipes"/>
                                  </p> */}
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature2.text"
                                                          defaultMessage="Loss and Cooking Variation"/></p> 
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature14.text"
                                                        defaultMessage="Menus and Combos Unlimited"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature8.text"
                                                        defaultMessage="History Tracking"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title1"
                                                        defaultMessage="PRODUCTION & ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature23.text"
                                                        defaultMessage="Production Planning"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature21.text"
                                                        defaultMessage="Recipes Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature9.text"
                                                        defaultMessage="Nutrition Calculator & Facts"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title4"
                                                        defaultMessage="TEAMWORK AND SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature19.text"
                                                        defaultMessage="Multi-organization(s)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature20.text"
                                                        defaultMessage="Team work with user permissions"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature22.text"
                                                        defaultMessage="Advanced customize options (brand logo)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature10.text"
                                                        defaultMessage="Customizable exports Pdf, Csv, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature17.text"
                                                        defaultMessage="Phone Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='orange' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.three.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  <Grid.Column width={5}>
                      <Card as={Link} 
                            to={{pathname: '/storeplan', state: {type: consistMemberships[1].membership_type,
                                                                 name: consistMemberships[1].name,
                                                                 interval: consistMemberships[1].interval,
                                                                 price : consistMemberships[1].price,
                                                                 bill_locale : used_locale
                                                                }}}
/*                             to={'/storeplan/'+ consistMemberships[1].membership_type +'/'
                                              + consistMemberships[1].name +'/'
                                              + consistMemberships[1].interval +'/'
                                              + consistMemberships[1].price} */
                            style={{ backgroundColor: '#fcfcfc'}}
                             fluid raised> 
                              <Card.Content>
                                  <Label color='black' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[1].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.two.summary"
                                                        defaultMessage="Essentials to manage your food costs and recipes"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        {  !toggled &&
                                             <div>
                                               <span style={{ fontSize: '1.3em' , color:'#5BA3FF'}}>
                                               <FormattedNumber value={consistMemberships[1].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>
                                               {/*  <FormattedNumber value={consistMemberships[1].price}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="pricing.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                                </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.two.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
                                            </div>
                                            
                                        }
                                        {  toggled &&
                                          <div>
                                              {(!coupons || coupons.length == 0) &&
                                                <span style={{ fontSize: '1.3em', color:'#5BA3FF' }}>
                                                  <FormattedNumber value={consistMemberships[1].price/12} 
                                                  style="currency" 
                                                  minimumFractionDigits={(consistMemberships[1].price/12) % 1 === 0 ? 0 : 2}
                                                  maximumFractionDigits={(consistMemberships[1].price/12 )% 1 === 0 ? 0 : 2} 
                                                  currencyDisplay="symbol" 
                                                  currency={(used_locale == "fr"?'EUR':'USD')}/>
                                                </span>
                                              }
                                              {coupons && coupons.length == 1 && 
                                              <span style={{ fontSize: '1em' , color:'#5BA3FF'}}>
                                               <strike><FormattedNumber value={consistMemberships[1].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[1].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[1].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/></strike><br/>
                                               <FormattedNumber value={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount))/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>}
                                              {/* <FormattedNumber value={consistMemberships[1].price/12}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em' , color:'#5BA3FF'}}>
                                                <FormattedMessage id="pricing.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.two.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                              </p>
                                            </div>
                                        }
                                    </Card.Header>
                                    { !toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="pricing.plan.monthly.two.line.coupon.message"
                                                        defaultMessage="First month at"/>{' '}
                                          <FormattedNumber value={Number(consistMemberships[1].price) - Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }
                                    { toggled && coupons && coupons.length == 1 && 
                                          <div>
                                          <FormattedMessage id="pricing.plan.annually.two.line.coupon.message"
                                                        defaultMessage="Price discount of"/>{' '}
                                          <FormattedNumber value={Number(coupons[0].discount_amount)} 
                                                        style="currency" 
                                                        minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                        maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                        currencyDisplay="symbol" 
                                                        currency={coupons[0].currency}/>
                                         </div>
                                    }                                    
                              </Card.Content>
{/*                              <Card.Content >
                                   <p style={{ fontSize: '1.13em' }}>
                                      <FormattedMessage id="pricing.plan.two.line.description"
                                                        defaultMessage="Fits all businesses"/>
                                  </p> */}
                                 {/*  <br/> */}
{/*                                   <Button basic color='orange'  as={Link} to='/register'>
                                      <FormattedMessage id="pricing.plan.two.line.button.signup"
                                                        defaultMessage="Signup"/> 
                                  </Button>
                              </Card.Content>*/}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeightStandard}}>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes & Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="pricing.plan.two.line.feature8.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>                 
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature14.text"
                                                        defaultMessage="Menus and Combos (2 max)"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.two.line.feature.title6"
                                                        defaultMessage="ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="pricing.plan.two.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.two.line.feature.title5"
                                                        defaultMessage="SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature10.text"
                                                        defaultMessage="Exports PDF, CSV, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='black' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.two.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  <Grid.Column width={5}>
                      <Card as={Link}
                            to={{pathname: '/storeplan', state: {type: consistMemberships[0].membership_type,
                                                                name: consistMemberships[0].name,
                                                                interval: consistMemberships[0].interval,
                                                                price : consistMemberships[0].price,
                                                                bill_locale : used_locale
                                }}}
/*                            to={'/storeplan/'+ consistMemberships[0].membership_type +'/'
                                            + consistMemberships[0].name +'/'
                                            + consistMemberships[0].interval +'/'
                                            + consistMemberships[0].price}  */      
                           style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='lightgrey' align='center' style={{ fontSize: '1.3em' }} fluid>{consistMemberships[0].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.one.summary"
                                                        defaultMessage="Gives you a free trial"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.15em' }}>
                                  <Card.Header>
                                      <FormattedMessage id="pricing.plan.one.line.amount"
                                                        defaultMessage="Free"/>
                                      <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.one.line.info"
                                                      defaultMessage="No credit card required"/>
                                      </p>
                                  </Card.Header>
                              </Card.Content>
                                 {/*  <br/> */}
{/*                                   <Button basic color='grey' as={Link} to='/register'>
                                      <FormattedMessage id="pricing.plan.one.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button> 
                              </Card.Content>*/}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeightFree}}>
                                 <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                      <FormattedMessage id="pricing.plan.one.line.feature1.text"
                                                        defaultMessage="4 Recipes & 15 Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                     <FormattedMessage id="pricing.plan.one.line.feature8.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                      <FormattedMessage id="pricing.plan.one.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature3.text"
                                                        defaultMessage="Detailed Cost and Margin Calculations"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
                              </Card.Content>
                              <Card.Content>
                                  <Button fluid color='lightgrey' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.one.line.button.select"
                                                        defaultMessage="Select"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
              </Grid.Row>}
              { !userIn && (consistMemberships.length > 0) &&
              <Grid.Row columns={3} style={{ padding: '4em 0em 4em 0em' }}>
                                     <Grid.Column width={5}>
                      <Card as={Link} 
                            to='/register'
                            style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='orange' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[2].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.three.summary"
                                                        defaultMessage="Further with production planning, menus, tracking and nutrition. Manage multiple companies, work with your teammates"/>
                                  </span></p>
                                  <Label color='teal' size='large' style={{ fontSize: '0.8em', marginBottom: '0.4em' }}>
                                              <Icon name='bolt' color='yellow'/>
                                              <FormattedMessage id="pricing.plan.three.summary.popular"
                                                                defaultMessage="POPULAR"/>
                                  </Label>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        { !toggled &&
                                             <div>
                                               <span style={{ fontSize: '1.3em', color:'#5BA3FF'}} >
                                                <FormattedNumber value={consistMemberships[2].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[2].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                               </span>
                                               {' '}
                                               <span style={{ fontSize: '0.6em' , color:'#5BA3FF' }} >
                                                <FormattedMessage id="pricing.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
 {/*                                              <span style={{ fontSize: '0.65em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                            
                                        }
                                        { toggled &&
                                          <div>
                                             <span style={{ fontSize: '1.3em' , color:'#5BA3FF'}}>
                                               <FormattedNumber value={consistMemberships[2].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[2].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[2].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>{' '}
                                              <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="pricing.plan.three.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                              </p>
{/*                                               <span style={{ fontSize: '0.65em', fontWeight:'700', color:'black' }}>
                                                <FormattedMessage id="pricing.plan.three.line.billing.more"
                                                      defaultMessage="Multi-site (price per location)"/>
                                              </span> */}
                                            </div>
                                        }
                                    </Card.Header>
                                    {/* {coupons && coupons.length == 1 && 
                                            <FormattedMessage id="pricing.plan.three.line.coupon.message"
                                                          defaultMessage="Coupon applied first month at 21€"/>
                                    } */}
                              </Card.Content>
{/*                               <Card.Content >
                                   <Button basic color='orange' as={Link} to='/register' style={{ fontSize: '1.0em' }}>
                                      <FormattedMessage id="pricing.plan.three.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button>
                              </Card.Content> */}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeight}}>
                                <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes, Sub-Recipes & Ingredients"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature11.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p style={{fontSize:'14px', 'fontWeight':'500'}}><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature2.text"
                                                          defaultMessage="Loss and Cooking Variation"/></p> 
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature14.text"
                                                        defaultMessage="Menus and Combos Unlimited"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature8.text"
                                                        defaultMessage="History Tracking"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title1"
                                                        defaultMessage="PRODUCTION & ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature23.text"
                                                        defaultMessage="Production Planning"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature21.text"
                                                        defaultMessage="Recipes Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                    <FormattedMessage id="pricing.plan.three.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                     <FormattedMessage id="pricing.plan.three.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature9.text"
                                                        defaultMessage="Nutrition Calculator & Facts"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title4"
                                                        defaultMessage="TEAMWORK AND SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature19.text"
                                                        defaultMessage="Multi-organization(s)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature20.text"
                                                        defaultMessage="Team work with user permissions"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature22.text"
                                                        defaultMessage="Advanced customize options (brand logo)"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature10.text"
                                                        defaultMessage="Customizable exports Pdf, Csv, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                                  <p><Icon name='check' color='orange'/>
                                      <FormattedMessage id="pricing.plan.three.line.feature17.text"
                                                        defaultMessage="Phone Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='orange' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.three.line.button.start"
                                                        defaultMessage="Get started"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  <Grid.Column width={5}>
                      <Card as={Link} 
                            to='/register'
                            style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='black' style={{ fontSize: '1.5em' }} fluid>{consistMemberships[1].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.two.summary"
                                                        defaultMessage="Essentials to manage your food costs and recipes"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.33em' }}>
                                    <Card.Header>
                                        {  !toggled &&
                                             <div>
                                               <span style={{ fontSize: '1.3em' , color:'#5BA3FF'}}>
                                               <FormattedNumber value={consistMemberships[1].price} 
                                                                style="currency"
                                                                minimumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={consistMemberships[1].price % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                               </span>
                                               {/*  <FormattedNumber value={consistMemberships[1].price}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                               <span style={{ fontSize: '0.6em' , color:'#5BA3FF'}}>
                                                <FormattedMessage id="pricing.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                              </span>
                                              <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.two.line.billing.mode.month"
                                                      defaultMessage="billed monthly"/>
                                              </p>
                                            </div>
                                            
                                        }
                                        {  toggled &&
                                          <div>
                                              <span style={{ fontSize: '1.3em' , color:'#5BA3FF'}}>
                                               <FormattedNumber value={consistMemberships[1].price/12} 
                                                                style="currency" 
                                                                minimumFractionDigits={(consistMemberships[1].price/12) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={(consistMemberships[1].price/12 )% 1 === 0 ? 0 : 2} 
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                              </span>
                                              {/* <FormattedNumber value={consistMemberships[1].price/12}/>
                                               { used_locale == "fr" && <span>€</span>}
                                               { used_locale == "en" && <span>$</span>} */}{' '}
                                                <span style={{ fontSize: '0.6em', color:'#5BA3FF' }}>
                                                <FormattedMessage id="pricing.plan.two.line.amount.mode.month"
                                                      defaultMessage="per month"/>
                                                </span>
                                                <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.two.line.billing.mode.annual"
                                                      defaultMessage="billed annually"/>
                                               </p>
                                            </div>
                                        }
                                    </Card.Header>
                                    {!toggled && coupons && coupons.length == 1 && 
                                          <div>
                                            <FormattedMessage id="pricing.plan.two.line.coupon.message"
                                                          defaultMessage="Coupon applied first month at"/>{' '}
                                            <FormattedNumber value={coupons[0].paid_amount} 
                                                          style="currency" 
                                                          minimumFractionDigits={(coupons[0].paid_amount) % 1 === 0 ? 0 : 2}
                                                          maximumFractionDigits={(coupons[0].paid_amount)% 1 === 0 ? 0 : 2} 
                                                          currencyDisplay="symbol" 
                                                          currency={coupons[0].currency}/>
                                          </div>
                                    }
                              </Card.Content>
{/*                               <Card.Content >
                                   <Button basic color='black'  as={Link} to='/register' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.two.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button>
                              </Card.Content> */}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeightStandard}}>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature1.text"
                                                        defaultMessage="Unlimited Recipes & Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="pricing.plan.two.line.feature8.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature3.text"
                                                          defaultMessage="Detailed Cost and Margin Calculations"/>                 
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature14.text"
                                                        defaultMessage="Menus and Combos (2 max)"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.two.line.feature.title6"
                                                        defaultMessage="ORDERS"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature6.text"
                                                        defaultMessage="Order Lists & Status"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title2"
                                                        defaultMessage="STOCK AND INVENTORY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature13.text"
                                                        defaultMessage="Ingredients Inventory Management"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title3"
                                                        defaultMessage="TRACEABILITY"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                    <FormattedMessage id="pricing.plan.two.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature24.text"
                                                        defaultMessage="Expiration Dates and Batch tracking"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                     <FormattedMessage id="pricing.plan.two.line.feature15.text"
                                                        defaultMessage="Auto Food Composition Label"/>
                                  </p>
                                  <p style={{'color':'black',fontSize:'14px', 'fontWeight':'700'}}>
                                      <FormattedMessage id="pricing.plan.two.line.feature.title5"
                                                        defaultMessage="SUPPORT"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature10.text"
                                                        defaultMessage="Exports PDF, CSV, Excel"/>
                                  </p>
                                  <p><Icon name='check' color='black'/>
                                      <FormattedMessage id="pricing.plan.two.line.feature16.text"
                                                        defaultMessage="Email & Chat Support"/>
                                  </p>
                              </Card.Content>
                              <Card.Content>
                                 <Button fluid color='black' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.two.line.button.start"
                                                        defaultMessage="Get started"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
                  <Grid.Column width={5}>
                      <Card as={Link}
                            to='/register'    
                           style={{ backgroundColor: '#fcfcfc'}} fluid raised> 
                              <Card.Content>
                                  <Label color='lightgrey' align='center' style={{ fontSize: '1.3em' }} fluid>{consistMemberships[0].name.toUpperCase()}</Label>
                                  <p style={{ fontSize: '1.1em',color:'#fffff', paddingTop:'0.5em'}}><span>
                                      <FormattedMessage id="pricing.plan.one.summary"
                                                        defaultMessage="Gives you a free trial"/>
                                  </span></p>
                              </Card.Content>
                              <Card.Content style={{ fontSize: '1.15em' }}>
                                  <Card.Header>
                                      <FormattedMessage id="pricing.plan.one.line.amount"
                                                        defaultMessage="Free"/>
                                     <p style={{ fontSize: '0.55em' }}>
                                                <FormattedMessage id="pricing.plan.one.line.info"
                                                      defaultMessage="No credit card required"/>
                                      </p>
                                  </Card.Header>
                              </Card.Content>
{/*                               <Card.Content >
                                   <Button basic color='lightgrey' as={Link} to='/register' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.one.line.button.signup"
                                                        defaultMessage="Signup"/>
                                  </Button>
                              </Card.Content> */}
                              <Card.Content textAlign='left' style={{ height:pricingBoxHeightFree}}>
                                 <p style={{'color':'black',fontSize:'14px', 'fontWeight':'900'}}>
                                      <FormattedMessage id="pricing.plan.three.line.feature.title0"
                                                        defaultMessage="RECIPES, MENUS and COSTS"/>
                                  </p>
                                 <p><Icon name='check' color='lightgrey'/>
                                      <FormattedMessage id="pricing.plan.one.line.feature1.text"
                                                        defaultMessage="4 Recipes & 15 Ingredients"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                     <FormattedMessage id="pricing.plan.one.line.feature8.text"
                                                        defaultMessage="Professional Recipe Forms"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature4.text"
                                                        defaultMessage="Sub-Recipes"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                      <FormattedMessage id="pricing.plan.one.line.feature5.text"
                                                        defaultMessage="Multi Suppliers Management"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature3.text"
                                                        defaultMessage="Detailed Cost and Margin Calculations"/>
                                  </p>
                                  <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature2.text"
                                                        defaultMessage="Loss and Cooking Variation"/>
                                  </p> 
{/*                                   <p><Icon name='check' color='lightgrey'/>
                                    <FormattedMessage id="pricing.plan.one.line.feature12.text"
                                                        defaultMessage="Food Allergens Calculation"/>
                                  </p> */}
                              </Card.Content>
                              <Card.Content>
                                  <Button fluid color='lightgrey' style={{ fontSize: '1em' }}>
                                      <FormattedMessage id="pricing.plan.one.line.button.start"
                                                        defaultMessage="Get started"/>
                                  </Button>
                              </Card.Content>
                      </Card>
                  </Grid.Column>
              </Grid.Row>}
              <br/> 
              <Grid.Row>
                    <Button size='big' color='blue' as={Link} to="/overview" style={styles.ButtonNoColor}>
                          <FormattedMessage id="pricing.segment.features.overview.button.text"
                                            defaultMessage="Discover all features"/>
                    </Button>
              </Grid.Row>
              <br/><br/> <br/>
{/*               <Grid.Row>
                <Grid.Column width={6} fluid >
                   <Segment style={{ fontSize: '1.13em', backgroundColor: '#fcfcfc' }} compact>
                           <Checkbox  color='orange'
                                      toggle
                                      label={toggle_label}
                                      //defaultChecked='false'
                                      checked={toggled}
                                      style={{ fontSize: '1.13em'}}
                                      onChange= {this.toggleCheckBox}
                                      /> 
                       
                    </Segment>    
                </Grid.Column>
              </Grid.Row> */}
            </Grid>
          </Segment>
          <Segment secondary  style={{ padding: '4em 0em 4em 0em',border:'none' }} vertical>
            <Grid container stackable verticalAlign='top'>
              <Grid.Row>
                <Grid.Column stretched floated='right' /*textAlign='justified'*/  width={5}>
                    <Image centered size='tiny' src={img_contact} style={{width:'65px'}}/>
                    <Header as='h3' >
                        <Icon size='mini' name='check circle outline'/>
                        <Header.Content>
                           <FormattedMessage id="pricing.plan.desciption.one.text.title"
                                            defaultMessage=" Cancel Anytime"/>
                        </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>
                        <FormattedMessage id="pricing.plan.desciption.one.text.content.line.one"
                                          defaultMessage="Ratatool is a recurring subscription service and will continue until you cancel. 
                                          It auto-renews at the end of each term and you will be charged for the subsequent term. "/>
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                       <FormattedMessage id="pricing.plan.desciption.one.text.content.line.two"
                                         defaultMessage="You may cancel, upgrade or downgrade your plan at any time directly under your account."/>
                    </p>
                  </Grid.Column>
                  <Grid.Column stretched /*textAlign='justified'*/  width={5}>
                    <Image centered size='tiny' src={img_timemanagement}  style={{width:'65px'}}/>
                    <Header as='h3'>
                       <Icon size='mini' name='check circle outline'/>
                        <Header.Content>
                           <FormattedMessage id="pricing.plan.desciption.two.text.title"
                                            defaultMessage="Money-Back Guarantee"/>
                        </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>
                    <FormattedMessage id="pricing.plan.desciption.two.text.content.line.one"
                                         defaultMessage="Within first days of your subscription, we’ll issue a refund if for any reason you decide you want to cancel your account.  "/>
                    
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                    <FormattedMessage id="pricing.plan.desciption.two.text.content.line.two"
                                      defaultMessage="Simply drop us an email at support@ratatool.com"/>
                    
                    </p>
                    <a href='/contact' style={{ fontSize: '1.13em' }}>
                         <FormattedMessage id="pricing.plan.desciption.help.link"
                                            defaultMessage="Any question? Need more datails"/> 
                   </a>
                  </Grid.Column>
                  <Grid.Column stretched floated='left' /*textAlign='justified'*/  width={5}>
                    <Image centered size='tiny' src={img_briefcase}  style={{width:'65px'}}/>
                    <Header as='h3'>
                        <Icon size='mini' name='check circle outline'/>
                        <Header.Content>
                           <FormattedMessage id="pricing.plan.desciption.three.text.title"
                                            defaultMessage="Secure payment and data"/>
                        </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>
                    <FormattedMessage id="pricing.plan.desciption.three.text.content.line.one"
                                         defaultMessage="All your data is secured. 
                                         We take online security very seriously."/>
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                    <FormattedMessage id="pricing.plan.desciption.three.text.content.line.two"
                                         defaultMessage="Payment is managed and secured by Stripe tunnels.
                                         No credit card information is stored locally."/>
                    </p>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{padding: '6em 0em 6em 0em',border:'none' }}  vertical>
            <Grid container stackable centered>
              <br/>
              <Header as='h2' centered>
                    <FormattedMessage id="pricing.segment.frequently.title"
                                      defaultMessage="Frequently asked questions"/>
              </Header>
              <br/> <br/>  <br/><br/> <br/>  <br/>
              <Grid.Row />
              <Grid.Row textAlign='center'>
              <Grid.Column stretched floated='right' /*textAlign='justified'*/  width={5}>
                    <Header as='h4'>
                        <Icon size='mini' name='question circle'/>
                        <Header.Content>
                           <FormattedMessage id="pricing.frequently.desciption.one.text.title"
                                            defaultMessage="Is there a company invoice generated ?"/>
                        </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>
                      <FormattedMessage id="pricing.frequently.desciption.one.text.content.one"
                                        defaultMessage="Yes. Any payment that succeeds generate in a few minutes a corresponding invoice."/>
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                      <FormattedMessage id="pricing.frequently.desciption.one.text.content.two"
                                        defaultMessage="You can download PDF or print it at anytime from you profil page."/>
                    </p>
                  </Grid.Column>
                  <Grid.Column stretched /*textAlign='justified'*/  width={5}>
                    <Header as='h4'>
                        <Icon size='mini' name='question circle'/>
                        <Header.Content>
                           <FormattedMessage id="pricing.frequently.desciption.two.text.title"
                                            defaultMessage="Can i change my plan during period ?"/>
                        </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>
                     <FormattedMessage id="pricing.frequently.desciption.two.text.content.one"
                                        defaultMessage=" Of course. You can upgrade or downgrade you premium plan at any moment."/>
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                     <FormattedMessage id="pricing.frequently.desciption.two.text.content.two"
                                        defaultMessage=" Your change is effective immediately. Your extra money paid is not lost and used for next payments"/>
                    </p>
                  </Grid.Column>
                  <Grid.Column stretched floated='left' /*textAlign='justified'*/  width={5}>
                    <Header as='h4'>
                          <Icon size='mini' name='question circle'/>
                          <Header.Content>
                            <FormattedMessage id="pricing.frequently.desciption.three.text.title"
                                              defaultMessage="Can i pay with paypal ?"/>
                          </Header.Content>
                    </Header>
                    <p style={{ fontSize: '1.13em' }}>

                    <FormattedMessage id="pricing.frequently.desciption.three.text.content.one"
                                        defaultMessage="No, for the moment we propose only credit cards (Visa, Amex, Mastercard, ...)"/>
                    </p><br/><br/><br/><br/>
                    <p style={{ fontSize: '1.13em' }}>  
                    <FormattedMessage id="pricing.frequently.desciption.three.text.content.two"
                                        defaultMessage="Payment are secured by Stripe tunnels.
                                        No credit card information is stored locally."/>
                    </p>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment secondary style={{ padding: '4em 0em 4em 0em',border:'none' }} vertical>
            <Grid container stackable verticalAlign='middle' centered>
              <br/>
              <Header as='h2' centered>
                      {/*  <FormattedMessage id="pricing.segment.try.text.title"
                                         defaultMessage="Try Ratatool for free and make up your mind in a few minutes ..."/> */}
                        <FormattedMessage id="pricing.segment.try.text.begin.title"
                                          defaultMessage="Try"/>{' '}
                        <span /* style={{color:'#63a4dd'}} */><FormattedMessage id="pricing.segment.try.text.middle.title"
                                          defaultMessage="in a few clicks"/></span>{' '}
                        <FormattedMessage id="pricing.segment.try.text.end.title"
                                          defaultMessage="and make up your mind"/>
                        
              </Header>
              <Grid.Row>
                <List divided relaxed style={{ marginLeft: '2em'/* ,'font-style':'italic' */, textAlign:'left' }}>
                    <Header as='h3' centered style={{color:'#63a4dd'}}>
                        <Icon name='check' size='tiny' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="pricing.segment.try.text5.title"
                                              defaultMessage="Get your actual costs dynamically"/> 
                            </Header.Content><br/>
                          <Icon name='check' size='tiny' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="pricing.segment.try.text3.title"
                                              defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                            </Header.Content><br/>
                          <Icon name='check' size='tiny' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="pricing.segment.try.text6.title"
                                              defaultMessage="Plan your productions tasks and food needs"/> 
                            </Header.Content><br/>
                          <Icon name='check' size='tiny' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="pricing.segment.try.text4.title"
                                              defaultMessage="Secure your essential business data"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='tiny' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="pricing.segment.try.text2.title"
                                              defaultMessage="Save hours of work on your daily repetitive tasks"/> 
                            </Header.Content>
                    </Header>
                </List>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button size='huge' as={Link} to="/register" style={styles.ButtonBlueAsIconUpper}>
                      <FormattedMessage id="pricing.segment.try.button.text"
                                         defaultMessage="Start for free"/>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <MainFooter/>
        </ResponsiveContainer>
    )
  }
}

function mapStateToProps(state) {
    const { alert,dispatch,payment} = state;
    const { coupons } = state.payment;
    return {
        payment,
        coupons,
        alert,
        dispatch
    };
}

const connectedPricingLayout = injectIntl(connect(mapStateToProps)(PricingLayout));
export { connectedPricingLayout as PricingLayout };