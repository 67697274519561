
import React, { Component } from 'react'
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List
} from 'semantic-ui-react';

import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import {HashLink as Link} from 'react-router-hash-link';
import {FormattedMessage,injectIntl,defineMessages} from 'react-intl';
import {MainFooter,ResponsiveContainer} from './Segments';
import {styles} from './Styles';

import img_dashboard_simple_fr from '../Assets/dashboardSimple_fr.png'
import img_dashboard_simple_en from '../Assets/dashboardSimple_en.png'
import img_dashboard_details_fr from '../Assets/dashboardDetails_fr.png'
import img_dashboard_details_en from '../Assets/dashboardDetails_en.png'
import img_dashboard_marge_fr from '../Assets/dashboardMarge_fr.png'
import img_dashboard_marge_en from '../Assets/dashboardMarge_en.png'
import img_dashboard_ingredient_fr from '../Assets/Ingredient2024.png'
import img_dashboard_ingredient_en from '../Assets/Ingredient2024_en.png'
import img_dashboard_nutrition_fr from '../Assets/Nutrition2024.png'
import img_dashboard_nutrition_en from '../Assets/Nutrition2024_en.png'
import img_dashboard_order_fr from '../Assets/dashboardOrder_fr.png'
import img_dashboard_order_en from '../Assets/dashboardOrder_en.png'
import img_dashboard_recipe_summary_fr from '../Assets/FicheTechnique2024.png'
import img_dashboard_recipe_summary_en from '../Assets/FicheTechnique2024_en.png'
import img_dashboard_ingred_evol_fr from '../Assets/dashboardEvol_fr.png'
import img_dashboard_ingred_evol_en from '../Assets/dashboardEvol_en.png'
import img_dashboard_ingred_history_fr from '../Assets/dashboardHistory_fr.png'
import img_dashboard_ingred_history_en from '../Assets/dashboardHistory_en.png'
import img_dashboard_suppliers_fr from '../Assets/ratatool_supplier_fr.png'
import img_dashboard_suppliers_en from '../Assets/ratatool_supplier_en.png'
import img_dashboard_inventory_fr from '../Assets/dashboardInventory_fr.png'
import img_dashboard_inventory_en from '../Assets/dashboardInventory_en.png'
import img_dashboard_menu_fr from '../Assets/dashboard_menu_fr.png'
import img_dashboard_menu_en from '../Assets/dashboard_menu_en.png'

import img_order from '../Assets/receipt.png'
import img_ingredient_fr from '../Assets/NewIngredient2024.png'
import img_ingredient_en from '../Assets/NewIngredient2024_en.png'
import img_groceries from '../Assets/shopping-bag.png'
import img_pan from '../Assets/pan.png'
import img_growth from '../Assets/growth.png'
import img_printerIcon from '../Assets/printerIcon2.png'
import img_dasboard from '../Assets/dashboard.png'
import img_suppliers from '../Assets/cargo-truck.png'
import img_box from '../Assets/boxes.png'
import img_menu from '../Assets/menu.png'

import img_restaurant from '../Assets/hotel-business.jpg'
import img_bakery from '../Assets/bakery-business.jpg'
import img_hotel from '../Assets/hotel-business.jpg'
import img_caterer from '../Assets/caterer-business.jpg'
import img_kitchen from '../Assets/kitchen-business.jpg'
import img_organization from '../Assets/OrganizationWork.jpg'
import img_business from '../Assets/business.png'
import img_planningFull from '../Assets/planningFull.jpg'
import img_planning from '../Assets/planning.png'


const inlineImageStyle = {
    img:{
  /*         'filter': 'brightness(50%)', */
          '-webkit-filter': 'brightness(70%)',
          '-moz-filter': 'brightness(70%)',
  /*         '-o-filter': 'brightness(50%)',
          '-ms-filter': 'brightness(50%)' */
    },  
  };
  
  const inlineImageStyle2 = {
    img:{
  /*         'filter': 'brightness(50%)', */
          '-webkit-filter': 'brightness(97%)',
          '-moz-filter': 'brightness(97%)',
    },  
  };

  const inlineImageStyle3 = {
    img:{
  /*         'filter': 'brightness(50%)', */
          '-webkit-filter': 'brightness(92%)',
          '-moz-filter': 'brightness(92%)',
    },  
  };


  const html_tags = defineMessages({
    html_title: {
      id: 'overview.html.title.tag',
      defaultMessage: "Features: Recipe cost software, recipe food cost calculator, restaurant software solution, restaurant stock and inventory solution",
    },
    meta_name_description: {
      id: 'overview.meta.name.description.tag',
      defaultMessage: "Recipe cost software, recipe food cost calculator for restaurant, caterer, bakery, pastry, hotel, dark kitchen calculate recipe menu food costs from ingredients, manage stock and inventory, estimate margin, reduce waste, recipe costing templates, orders, food calculator, nutrition USDA",
    },
    html_title_business_restaurant: {
        id: 'overview.html.title.tag.business_restaurant',
        defaultMessage: "Restaurant and Recipe Food Cost Calculator - Ratatool",
    },
    html_title_business_bakery: {
        id: 'overview.html.title.tag.business_bakery',
        defaultMessage: "Bakery Recipe Costing Software (Pastry) - Ratatool",
    },
    html_title_business_caterer: {
        id: 'overview.html.title.tag.business_caterer',
        defaultMessage: "Caterer Recipe Costing Software - Ratatool",
    },
    html_title_business_hotel: {
        id: 'overview.html.title.tag.business_hotel',
        defaultMessage: "Hotel Recipe Costing Software - Ratatool",
    },
    html_title_business_kitchen: {
        id: 'overview.html.title.tag.business_kitchen',
        defaultMessage: "Dark kitchen Recipe Costing Software - Ratatool",
    },
    html_title_business_management: {
        id: 'overview.html.title.tag.business_management',
        defaultMessage: "Restaurant Management Software (Recipe cost software) - Ratatool",
    }
  });

class Overview extends Component {

  constructor(props) {
      super(props);    
      this.state={
      };
  }

  componentDidMount(){
    document.body.style.userSelect = 'none';
    document.body.style.WebkitUserSelect = 'none';
    document.body.style.MozUserSelect = 'none';
    document.body.style.MsUserSelect = 'none';
}

  componentWillUnmount(){
    document.body.style.userSelect = 'text';
    document.body.style.WebkitUserSelect = 'text';
    document.body.style.MozUserSelect = 'text';
    document.body.style.MsUserSelect = 'text';
  }

  render() {
    const {intl,loggedIn,business} =  this.props;

    return (
        <ResponsiveContainer>
          <Helmet>
            {/* <title>Découvrez les fonctionnalités Ratatool : votre tableau de bord, vos achats d'ingrédients, votre gestion de recettes, vos marges, vos bons de commandes fournisseurs et vos fiches techniques recettes</title> 
            <meta name="description" content="Ratatool permet de calculer le coût de revient de vos recettes, d'estimer vos marges et ratios, d'éditer vos fiches techniques recettes, vos bons de commandes fournisseurs et vos étiquettes nutritionnelles INCO."/>*/}
            { !business && <title>{intl.formatMessage(html_tags.html_title)}</title>}
            { business == 'restaurant' && <title>{intl.formatMessage(html_tags.html_title_business_restaurant)}</title>}
            { business == 'bakery' && <title>{intl.formatMessage(html_tags.html_title_business_bakery)}</title>}
            { business == 'caterer' && <title>{intl.formatMessage(html_tags.html_title_business_caterer)}</title>}
            { business == 'hotel' && <title>{intl.formatMessage(html_tags.html_title_business_hotel)}</title>}
            { business == 'kitchen' && <title>{intl.formatMessage(html_tags.html_title_business_kitchen)}</title>}
            { business == 'management' && <title>{intl.formatMessage(html_tags.html_title_business_management)}</title>}

             <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
            
            {!business && intl.locale == 'fr' &&
                          <link rel="canonical" href="https://fr.ratatool.com/overview" />}

            {!business && intl.locale == 'en' &&
                            <link rel="canonical" href="https://www.ratatool.com/overview" />}
            {!business &&
               <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/overview" />}
            {!business && <link rel="alternate" hreflang="en" href="https://www.ratatool.com/overview" />}
            {!business && <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/overview" />}

            { business  && intl.locale == 'fr' &&
                          <link rel="canonical" href={"https://fr.ratatool.com/recipe-sofware-"+business} />}
             { business && intl.locale == 'en' &&
                            <link rel="canonical" href={"https://www.ratatool.com/recipe-sofware-"+business} />}
            { business  &&
               <link rel="alternate" hreflang="fr" href={"https://fr.ratatool.com/recipe-sofware-"+business} />}
            { business  && <link rel="alternate" hreflang="en" href={"https://www.ratatool.com/recipe-sofware-"+business} />}
            { business  && <link rel="alternate" hreflang="x-default" href={"https://www.ratatool.com/recipe-sofware-"+business} />}

            { business  && business =='management' && intl.locale == 'fr' &&
                             <link rel="canonical" href={"https://fr.ratatool.com/restaurant-management-software"} />}
            { business  && business =='management' && intl.locale == 'en' &&
                             <link rel="canonical" href={"https://en.ratatool.com/restaurant-management-software"} />}
            
            { business  && business =='management' &&
               <link rel="alternate" hreflang="fr" href={"https://fr.ratatool.com/restaurant-management-software"} />}
            { business  && business =='management' && <link rel="alternate" hreflang="en" href={"https://www.ratatool.com/restaurant-management-software"} />}
            { business  && business =='management' && <link rel="alternate" hreflang="x-default" href={"https://www.ratatool.com/restaurant-management-software"} />}

           
          </Helmet>
          {!business && <Segment vertical style={{ border: 'none', padding: '5em' }}>
            <Grid  container stackable verticalAlign='middle' textAlign='center' columns={3} /*  style={styles.FontLato14} */>
                 <br/><br/><br/>
                    <Header  as='h1' textAlign='center'>
                          <FormattedMessage id="owerview.segment.top.title"
                                            defaultMessage="Your day-to-day features"/>
                    </Header>
                    <br/><br/><br/>
            </Grid>
          </Segment>}
          {business && <Segment vertical style={{ border: 'none', padding: '4em' }}>
            <Grid  container stackable verticalAlign='middle' textAlign='center' columns={3} /*  style={styles.FontLato14} */>
                 <br/><br/><br/>
                    <Header  as='h1' textAlign='center'>
                        {business == 'bakery' &&
                          <FormattedMessage id="owerview.segment.top.title.bakery"
                                            defaultMessage="Bakery - Pastry shop recipe cost software"/>}
                        {business == 'caterer' &&
                          <FormattedMessage id="owerview.segment.top.title.caterer"
                                            defaultMessage="Caterer recipe software features"/>}
                        {business == 'restaurant' &&
                          <FormattedMessage id="owerview.segment.top.title.restaurant"
                                            defaultMessage="Recipe costing software for Restaurant"/>}
                        {business == 'kitchen' &&
                          <FormattedMessage id="owerview.segment.top.title.kitchen"
                                            defaultMessage="Cuisine and Dark kitchen software features"/>}
                        {business == 'hotel' &&
                          <FormattedMessage id="owerview.segment.top.title.hotel"
                                            defaultMessage="Hotel Restaurant software features"/>}
                        {business == 'management' &&
                          <FormattedMessage id="owerview.segment.top.title.management"
                                            defaultMessage="Restaurant management software features"/>}
                    </Header>
                    <br/><br/> <br/>
            </Grid>
          </Segment>}
          {business && 
          <Segment vertical 
                   style={{ padding: '0em 0em 3em 0em',border: 'none'}}>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row style={{ padding: '0em 0em 0em 0em'}}>
                  {business == 'bakery' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.bakery"
                                                defaultMessage="Baker - Pastry chef manage your business"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item><FormattedMessage id="owerview.segment.one.left.line1.bakery"
                                                         defaultMessage="Focus on your work and your creative recipes and pastries."/>
                            </List.Item>
                            <List.Item><FormattedMessage id="owerview.segment.one.left.line2.bakery"
                                                         defaultMessage="Quickly calculate food costs in details to drive your bakery or pastry shop."/>    
                            </List.Item>
                            <List.Item><FormattedMessage id="owerview.segment.one.left.line3.bakery"
                                                         defaultMessage="Easily fix selling prices with ratios and margin calculators to increase your profitability."/>
                            </List.Item>
                            <List.Item><FormattedMessage id="owerview.segment.one.left.line4.bakery"
                                                         defaultMessage="Manage production and food needs with accurate ingredient costing."/>
                            </List.Item>
                            <List.Item><FormattedMessage id="owerview.segment.one.left.line5.bakery"
                                                         defaultMessage="Provide allergens traceability and nutrition detailed information (food labelling tags)."/>
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'bakery' &&
                        <Grid.Column floated='right' width={6}>
                        <Image  size='large' src={img_bakery}/>
                    </Grid.Column>}
                    {business == 'caterer' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.caterer"
                                                defaultMessage="Manage you catering day-to-day activity"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item>
                               <FormattedMessage id="owerview.segment.one.left.line1.caterer"
                                                 defaultMessage="Save time every day and focus on your business."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line2.caterer"
                                                 defaultMessage="Easily calculate recipes and menus food costs in detail to drive your caterer activity."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line3.caterer"
                                                 defaultMessage="Fix your selling prices with ratios and margin calculators to increase effectively your profitability."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line4.caterer"
                                                 defaultMessage="Drive production and food needs with accurate ingredient costing."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line5.caterer"
                                                 defaultMessage="Share your data with your team to be responsive and flexible."/>   
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'caterer' &&
                        <Grid.Column floated='right' width={7}>
                        <Image  size='huge' src={img_caterer} />
                    </Grid.Column>}
                    {business == 'restaurant' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.restaurant"
                                                defaultMessage="Manage and secure your restaurant data"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line1.restaurant"
                                                 defaultMessage="Focus on your recipes and menus creative work."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line2.restaurant"
                                                 defaultMessage="Create and share your recipes professional templates."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line3.restaurant"
                                                 defaultMessage="Calculate food costs in few clicks to drive your restaurant."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line4.restaurant"
                                                 defaultMessage="Easily fix your product prices with ratios and margin calculators for best profitability."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line5.restaurant"
                                                 defaultMessage="Manage production and food needs with detailed ingredient costing."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line6.restaurant"
                                                 defaultMessage="Provide allergens and nutrition information to your customers."/>
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'restaurant' &&
                        <Grid.Column floated='right' width={6}>
                        <Image  size='large' src={img_restaurant}/>
                    </Grid.Column>}
                    {business == 'hotel' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.hotel"
                                                defaultMessage="Manage your Hotel Restaurant recipe costs"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item>
                              <FormattedMessage id="owerview.segment.one.left.line1.hotel"
                                                 defaultMessage="Focus on your business and your cutomers satisfaction."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line2.hotel"
                                                 defaultMessage="Create and share your recipes detailed forms."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line3.hotel"
                                                 defaultMessage="Calculate your recipes food costs to drive your restaurant hotel."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line4.hotel"
                                                 defaultMessage="Fix selling prices easily with ratios and margin calculators to increase your profitability."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line5.hotel"
                                                 defaultMessage="Secure your essentiel data and be responsive to any change needed."/>
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'hotel' &&
                        <Grid.Column floated='right' width={6}>
                        <Image size='large' src={img_hotel}/>
                    </Grid.Column>}
                    {business == 'kitchen' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.kitchen"
                                                defaultMessage="Manage your cuisine or dark kitchen"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line1.kitchen"
                                                 defaultMessage="Focus on your kitchen work and we manage your costs."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line2.kitchen"
                                                 defaultMessage="Quickly calculate food costs in details to drive your cuisine or your dark kitchen."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line3.kitchen"
                                                 defaultMessage="Easily fix your selling prices with ratios and margin calculators for best profitability."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line4.kitchen"
                                                 defaultMessage="Manage production and daily food needs with accurate ingredient costing."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line5.kitchen"
                                                 defaultMessage="Provide allergens, nutrition information, food labelling to your customers."/>
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'kitchen' &&
                        <Grid.Column floated='right' width={7}>
                        <Image  size='massive' src={img_kitchen}/>
                    </Grid.Column>}
                    {business == 'management' &&
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={9}>
                      <Header  as='h2'>
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title.management"
                                                defaultMessage="Manage and drive your restaurant business"/></Header.Content>
                        </Header>
                        <List as='p' bulleted style={{ fontSize: '20px'}}>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line1.management"
                                                 defaultMessage="Build and share your recipes professional templates."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line2.management"
                                                 defaultMessage="Calculate food costs in few clicks to drive your restaurant."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line3.management"
                                                 defaultMessage="Fix your product costs with ratios and margin calculators for best profitability."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line4.management"
                                                 defaultMessage="Optimize production and food needs with detailed ingredient costing."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line5.management"
                                                 defaultMessage="Monitor your inventory and export your stock detailed reports."/>
                            </List.Item>
                            <List.Item>
                            <FormattedMessage id="owerview.segment.one.left.line6.management"
                                                 defaultMessage="Work with your teammates by invitating with the appropriate permissions."/>
                            </List.Item>
                        </List>
                    </Grid.Column>}
                    {business == 'management' &&
                        <Grid.Column floated='right' width={6}>
                        <Image  size='large' src={img_kitchen}/>
                    </Grid.Column>}
                </Grid.Row>
                <Grid.Row style={{ padding: '0em 0em 0em 0em',border: 'none'}} >
                        <Grid.Column textAlign='center'>
                        <Button size='medium' as={Link} to="/register" style={styles.ButtonBlueAsIcon}>
                            <FormattedMessage id="owerview.segment.try.button.business.text"
                                                defaultMessage="Try ratatool free"/>
                        </Button>
                        </Grid.Column>
                    </Grid.Row>
            </Grid>
          </Segment>}
          {!business && <Segment style={{ padding: '2em 0em 5em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                      {/*  <Icon name='dashboard' size='huge' color='blue'/> */}
                        <Header  as='h1'>
                           {/*   <Image size='tiny'  src={img_dasboard} style={inlineImageStyle3.img}/> */}
                           <img src={img_dasboard} style={{width:'37px','-webkit-filter': 'brightness(92%)','-moz-filter': 'brightness(92%)',}}/> 
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.one.left.title"
                                                defaultMessage="Technical dashboard"/></Header.Content>
                        </Header>
                        <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.one.left.description.one.begin"
                                            defaultMessage="Visualize in one look your"/>{' '}
                            <span style={{color:'#63a4dd','fontWeight':700}}><FormattedMessage id="owerview.segment.one.left.description.one.h1"
                                            defaultMessage="technical synthesis"/></span>{' '}
                             <FormattedMessage id="owerview.segment.one.left.description.one.end"
                                            defaultMessage=": your ingredients, your recipes, your menus, your orders count per status type and your current stock value of ingredients or recipes."/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <div /*style={inlineImageStyle.img}*/>  
                        <Image
                            rounded
                            //centered
                            size='huge'
                            src={intl.locale == 'en'?img_dashboard_simple_en:img_dashboard_simple_fr}
                        />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </Segment>}
          <Segment secondary style={{ padding: '2em 0em',border: 'none' }} vertical>
            <Grid container stackable  verticalAlign='middle' /*textAlign='center'  style={styles.FontLato14} */>
                <Grid.Row>
                    <Grid.Column floated='right' width={7}>
                        <div style={inlineImageStyle2.img}>  
                            <Image
                            rounded
                            centered
                            size='large'
                            src={intl.locale == 'en'?img_ingredient_en:img_ingredient_fr}
                            style={{ marginTop: '6em'}}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched floated='left' /*textAlign='justified'*/  width={7}>
                       <Header as='h1'>
                          <span><img width={'45px'} height={'auto'} src={img_groceries} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe list shopping order tool':'bon de commandes fournisseurs ingredients'}/>{' '}
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.two.right.title0"
                                                defaultMessage="Manage your PURCHASES"/>
                            </Header.Content></span>
                        </Header>
                        <Header as='h2'  style={{ marginTop: '3em'}} >
                            <Header.Content>
                                <FormattedMessage id="owerview.segment.two.right.title"
                                                defaultMessage="Your ingredients in a few clicks"/>
                            </Header.Content>
                        </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.right.description.one"
                                            defaultMessage="Fill your ingredient category, supplier, price and type of quantity (volume, weight, number)." />
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            {/* <FormattedMessage id="owerview.segment.two.right.description.four"
                                            defaultMessage="Create your customized categories." /> */}
                            <FormattedMessage id="owerview.segment.two.right.description.four.begin"
                                            defaultMessage="Create your" />{' '}
                            <span style={{color:'#63a4dd','fontWeight':700}}>
                            <FormattedMessage id="owerview.segment.two.right.description.four.h1"
                                            defaultMessage="customized categories." /></span>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                           {/*  <FormattedMessage id="owerview.segment.two.right.description.two"
                                            defaultMessage="All units conversions automatically managed, no conversion needed, it's simple." /> */}
                            <FormattedMessage id="owerview.segment.two.right.description.two.begin"
                                              defaultMessage="All" />{' '}
                            <span style={{color:'#63a4dd','fontWeight':700}}><FormattedMessage id="owerview.segment.two.right.description.two.h1"
                                              defaultMessage="units conversions" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.right.description.two.end"
                                              defaultMessage="automatically managed, no conversion needed, it's simple." />
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.right.description.three.begin"
                                            defaultMessage="You can also tag with all 14 known" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.right.description.three.h1"
                                            defaultMessage="allergens" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.right.description.three.end"
                                            defaultMessage="to inform your customers." />
                        </p>
                        <p style={{ fontSize: '18px' , padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.right.description.five.begin"
                                            defaultMessage="Easy initial ingredients loading : fill " />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.right.description.five.h1"
                                            defaultMessage="multiple ingredients" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.right.description.five.middle"
                                            defaultMessage=" at one time from a dedicated screen or import from a" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.right.description.five.h2"
                                            defaultMessage="CSV file (excel)" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.right.description.five.end"
                                            defaultMessage="." />{' '}
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column floated='right' width={7}>
                        <div style={inlineImageStyle2.img}>  
                            <Image
                            rounded
                            centered
                            size='large'
                            src={intl.locale == 'en'?img_dashboard_ingredient_en:img_dashboard_ingredient_fr}
                            style={{ marginTop: '1em'}}
                            />
                            {intl.locale == 'fr' &&
                             <Image
                            rounded
                            centered
                            size='large'
                            src={img_dashboard_nutrition_fr}
                            style={{ marginTop: '0em'}}
                            />}
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched floated='left' /*textAlign='justified'*/  width={7}>
                        {/* <Image size='tiny' src={img_box}/>
                        <Image size='tiny' src={img_heartbeat}/> */}
                        <Header as='h2'>
                                {/* <Icon name ='arrow alternate circle right outline' size='tiny' color='blue'/> */}
                                <Header.Content>
                                    <FormattedMessage id="owerview.segment.two.second.right.title"
                                                    defaultMessage="Adjust your ingredients in detail"/>
                                </Header.Content>
                        </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                            <FormattedMessage id="owerview.segment.two.second.right.description.one.begin"
                                            defaultMessage="Edit, adjust your details after creation : internal code,"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.second.right.description.one.h1"
                                            defaultMessage="supplier"/></span>{' '}
                            <FormattedMessage id="owerview.segment.two.second.right.description.one.end"
                                            defaultMessage=", reference, production note, category."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' }}>
                            <FormattedMessage id="owerview.segment.two.second.right.description.two"
                                            defaultMessage="Visualize your ingredients relations. " />
                        </p>
                        <br/>
                        <p style={{ fontSize: '18px' , padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.second.right.description.four.begin"
                                            defaultMessage="Manage your treacability with" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.second.right.description.four.h1"
                                            defaultMessage="Expiration dates and batch numbers." /></span>{' '}
                        </p>
                        <br/>
                        <p style={{ fontSize: '18px' , padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.second.right.description.three.begin"
                                            defaultMessage="Add your" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.second.right.description.three.h1"
                                            defaultMessage="nutrition label" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.second.right.description.three.end"
                                            defaultMessage="tags (based on USDA database)." />
                        </p>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column floated='right' width={8}>
                        <div style={inlineImageStyle2.img}>  
                            <Image
                            rounded
                            centered
                            size='large'
                            src={intl.locale == 'en'?img_dashboard_ingred_history_en:img_dashboard_ingred_history_fr}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched floated='left' /*textAlign='justified'*/  width={6}>
                        <Header as='h2'>
                                {/* <Icon name ='arrow alternate circle right outline' size='tiny' color='blue'/> */}
                                <Header.Content>
                                    <FormattedMessage id="owerview.segment.two.third.right.title"
                                                    defaultMessage="Track your price history"/>
                                </Header.Content>
                        </Header>
                        
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.third.right.description.one.begin"
                                            defaultMessage="Visualize the" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.two.third.right.description.one.h1"
                                            defaultMessage="price evolution chart" /></span>{' '}
                            <FormattedMessage id="owerview.segment.two.third.right.description.one.end"
                                            defaultMessage="and modifications of your ingredients." />
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.two.third.right.description.two"
                                            defaultMessage="Visualize the last price variation of your ingredient directly in ingredients list table." />
                        </p>
                        <Image //size='small' 
                              style={inlineImageStyle2.img} 
                              src={intl.locale == 'en'?img_dashboard_ingred_evol_en:img_dashboard_ingred_evol_fr}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
         </Segment>
         
         <Segment style={{ padding: '4em 0em 4em 0em ',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                       <Header as='h1'>
                          <span><img width={'45px'} height={'auto'} src={img_pan} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe cost tool and margin soft':'coûts rentabilité logiciel'}/>&nbsp;
                           <FormattedMessage id="owerview.segment.three.left.title0"
                                                defaultMessage="Manage your RECIPES"/></span>
                       </Header>
                        <Header as='h2' style={{ marginTop: '3em'}}>                 
                                <FormattedMessage id="owerview.segment.three.left.title"
                                                defaultMessage="Operate your recipes with no limit"/>
                        </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.one.begin"
                                            defaultMessage="Get you recipe synthesis:"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.one.h1"
                                            defaultMessage="cost breakdown"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.one.middle"
                                            defaultMessage="per ingredient or subrecipe, graphical"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.one.h2"
                                            defaultMessage="cost pie chart"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.one.end"
                                            defaultMessage=", allergens involved if any."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.six.begin"
                                            defaultMessage="Obtain automatically your"/>{' '}
                             <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.six.h1"
                                            defaultMessage=" food composition label"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.six.end"
                                            defaultMessage="with allergens after each ingredients update."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.two.begin"
                                            defaultMessage="Add"/>{' '}
                             <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.two.h1"
                                            defaultMessage="prepare instruction"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.two.middle"
                                            defaultMessage="text and images in html editor, get average"/>{' '}
                             <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.two.h2"
                                            defaultMessage="nutrient metrics"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.two.end"
                                            defaultMessage=", print your technical recipe synthesis document."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.four.begin"
                                            defaultMessage="Create a recipe based on"/>{' '}
                             <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.four.h1"
                                            defaultMessage="sub-recipes"/></span>{' '}
                             <FormattedMessage id="owerview.segment.three.left.description.four.end"
                                            defaultMessage="and/or ingredients, easier to create complex recipes."/>
                        </p>                         
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.three"
                                            defaultMessage="Duplicate a recipe from existing one to save time, adjust and save."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.three.left.description.five.begin"
                                            defaultMessage="You recipe weight has changed during cooking : add"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.three.left.description.five.h1"
                                            defaultMessage="loss/gain percentage variation ratio"/></span>{' '}
                            <FormattedMessage id="owerview.segment.three.left.description.five.end"
                                            defaultMessage="on your recipe."/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' width={7}>
                        <div /*style={inlineImageStyle2.img}*/>  
                        <Image
                            rounded
                            centered
                            size='huge'
                            src={intl.locale == 'en'?img_dashboard_details_en:img_dashboard_details_fr}
                            style={{ marginTop: '8em'}}/>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </Segment>
          <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
            <Grid container stackable  verticalAlign='middle' /*textAlign='center'*/ /*  style={styles.FontLato14} */>
                <Grid.Row>
                    <Grid.Column floated='left' width={8}>
                       <div>  
                            <Image
                            rounded
                            //centered
                            size='huge'
                            style={{/*width:'600px',*/'-webkit-filter': 'brightness(97%)','-moz-filter': 'brightness(97%)', marginTop: '3em' }}
                            src={intl.locale == 'en'?img_dashboard_marge_en:img_dashboard_marge_fr}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched /*floated='left'*/ /*textAlign='justified'*/ width={6}>
                       <Header as='h1'>
                          <span><img width={'40px'} height={'auto'} src={img_growth} style={inlineImageStyle3.img} 
                             alt={intl.locale == 'en'?'recipe cost margin profitability':'coûts matière revient rentabilité'}/>&nbsp;
                            <FormattedMessage id="owerview.segment.four.right.title"
                                                defaultMessage="Evaluate your profitability"/></span>
                       </Header>
                         <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                         <FormattedMessage id="owerview.segment.four.right.description.one.zero"
                                            defaultMessage="From real time updated recipe, get your recipe food cost, identify your expensive ingredients to adjust quantities if necessary." />{' '}
                         </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.four.right.description.one.begin"
                                            defaultMessage="Fill in your desired selling price and visualize your" />{' '}
                           <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.four.right.description.one.h1"
                                             defaultMessage="gross margin" /></span>{' '}
                          <FormattedMessage id="owerview.segment.four.right.description.one.middle"
                                            defaultMessage="and" />{' '}
                           <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.four.right.description.one.h2"
                                             defaultMessage="food cost " /></span>{' '}
                            <FormattedMessage id="owerview.segment.four.right.description.one.end"
                                             defaultMessage="per recipe." />
                        </p>
                        <br/>
                        <a href='/article/la-marge-et-les-ratios-de-rentabilite' style={{ fontSize: '18px'}}>
                          <FormattedMessage id="owerview.segment.four.right.description.one.more"
                                                defaultMessage="More about margins and profitability percentages"/> 
                       </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
         </Segment>
         <Segment style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
            <Grid.Column floated='right' width={8}>
            <div /* style={inlineImageStyle2.img} */>  
                <Image
                //bordered
                centered
                rounded
                size='large'
                src={img_planningFull}
                alt={intl.locale == 'en'?'recipe forms order export print':'imprimer pdf bon commande fournisseur'}/>
                </div>
            </Grid.Column>
            <Grid.Column stretched floated='right'  /*textAlign='justified'*/ width={7}>
                <Header as='h1'>
                     <span><img width={'38px'} height={'auto'} src={img_planning} style={inlineImageStyle3.img} 
                      alt={intl.locale == 'en'?'recipe list shopping order calendar production':'bon de commandes fournisseurs planning production'}/>&nbsp;
                    <FormattedMessage id="owerview.segment.eleven.left.title"
                                    defaultMessage="Plan your production needs for coming days"/></span>
                </Header>
                <p style={{ fontSize: '18px' }}>
                    <FormattedMessage id="owerview.segment.eleven.left.description2"
                                    defaultMessage="Use our planning tools to adjust or extract all your needs from your recipes quantities"/>
                </p>
                <p style={{ fontSize: '18px' }}>
                    <FormattedMessage id="owerview.segment.eleven.left.description"
                                    defaultMessage="Print, export and share all your productions notes per events at anytime, convert to supplier orders in one click."/>
                </p>
            </Grid.Column>
            </Grid.Row>
        </Grid>
        </Segment>
         <Segment style={{ padding: '4em 0em 4em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'40px'} height={'auto'} src={img_order} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'recipe list shopping order tool':'bon de commandes fournisseurs ingredients'}/>&nbsp;
                              <FormattedMessage id="owerview.segment.five.left.title"
                                                defaultMessage="Edit your orders lists"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.five.left.description.one.begin"
                                            defaultMessage="Export your next"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.five.left.description.one.h1"
                                            defaultMessage="order"/></span>{' '}
                            <FormattedMessage id="owerview.segment.five.left.description.one.end"
                                            defaultMessage="of ingredients automatically from your custom recipes and production units needed."/>
                        </p>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                            <FormattedMessage id="owerview.segment.five.left.description.three"
                                            defaultMessage="Create also orders manually directly from your suppliers ingredients lists. All orders are editable at any time."/>
                        </p>
                        <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.five.left.description.two"
                                            defaultMessage="Set your supplier email and automatically send your order to your supplier. Otherwise print it, that's it."/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right'  width={7}>
{/*                         <div /*style={inlineImageStyle2.img}>  
                        <Image
                            rounded
                            centered
                            size='huge'
                            src={intl.locale == 'en'?img_dashboard_order_en:img_dashboard_order_fr}/>
                        </div> */}
                        <div style={{'text-align':'center'}}>
                          <img width={'600px'} height={'auto'} src={intl.locale == 'en'?img_dashboard_order_en:img_dashboard_order_fr}
                             alt={intl.locale == 'en'?'recipe list shopping order tool':'bon de commandes fournisseurs ingredients'}/>
                       </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </Segment>
          <Segment secondary style={{ padding: '4em 0em 4em 0em',border: 'none' }} vertical>
            <Grid container stackable  verticalAlign='middle' /*textAlign='center'  style={styles.FontLato14} */>
                <Grid.Row>
                    <Grid.Column floated='right' width={6}>
                       <div>  
                            <Image
                            rounded
                            centered
                            size='large'
                            src={intl.locale == 'en'?img_dashboard_recipe_summary_en:img_dashboard_recipe_summary_fr}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column  stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'40px'} height={'auto'} src={img_printerIcon} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'recipe cost profit margin print tool':'imprimer vos fiches techniques recettes'}/>&nbsp;
                              <FormattedMessage id="owerview.segment.six.right.title"
                                                defaultMessage="Export your recipe documents"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em' , marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.six.right.description.one.begin"
                                            defaultMessage="Print your" />{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.six.right.description.one.h1"
                                            defaultMessage="recipe technical files" /></span>{' '}
                            <FormattedMessage id="owerview.segment.six.right.description.one.end"
                                            defaultMessage="with your selected options : number of recipes, costs details, margin details, each sub-recipe, allergens, ingredients pie chart, preparation instructions ..." />
                        </p>
                        <a href='/article/La-fiche-technique-de-recette-de-cuisine' style={{ fontSize: '18px'}}>
                          <FormattedMessage id="owerview.segment.six.right.description.one.more"
                                                defaultMessage="More about professional recipe forms"/> 
                       </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
         </Segment>
         <Segment style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'40px'} height={'auto'} src={img_suppliers} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'recipe cost profit margin supplier tool':'gestion de fournisseurs commandes recettes'}/>&nbsp;
                             <FormattedMessage id="owerview.segment.seven.left.title"
                                                defaultMessage="Manage your suppliers"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.seven.left.description.one.begin"
                                            defaultMessage="Fill your"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.seven.left.description.one.h1"
                                            defaultMessage="supplier contact"/></span>{' '}
                           <FormattedMessage id="owerview.segment.seven.left.description.one.end"
                                            defaultMessage="and supplementary details to be able to order quickly based on your ingredients shopping lists automatically created from your recipes quantities."/>
                        </p>
                        <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.seven.left.description.two"
                                            defaultMessage="Your suppliers informations are available in your shopping list ready to print, or to be emailed automatically."/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        <div /*style={inlineImageStyle2.img}*/>  
                        <Image
                            rounded
                            centered
                            size='medium'
                            src={intl.locale == 'en'?img_dashboard_suppliers_en:img_dashboard_suppliers_fr}/>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </Segment>
          <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column floated='right'  width={7}>
                        <div style={{'text-align':'center'}}>
                          <img width={'500px'} height={'auto'} style={inlineImageStyle2.img}  src={intl.locale == 'en'?img_dashboard_inventory_en:img_dashboard_inventory_fr}
                             alt={intl.locale == 'en'?'recipe list shopping order tool':'bon de commandes fournisseurs ingredients'}/>
                       </div>
                    </Grid.Column>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'40px'} height={'auto'} src={img_box} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'recipe stock inventory shopping order tool':'gestion de stock inventaire ingredients articles'}/>&nbsp;
                              <FormattedMessage id="owerview.segment.eight.left.title"
                                                defaultMessage="Food goods and recipes inventory"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.eight.left.description.one.begin"
                                            defaultMessage="Set each ingredient stock quantity. Use"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.eight.left.description.one.h1"
                                            defaultMessage="order states (ongoing, sent, received)"/></span>{' '}
                            <FormattedMessage id="owerview.segment.eight.left.description.one.end"
                                            defaultMessage="to increase/decrease automatically your quantities from your orders."/>
                        </p>
                       <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.eight.left.description.two.begin"
                                            defaultMessage="Check your"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.eight.left.description.two.h1"
                                            defaultMessage="stock value"/></span>{' '}
                            <FormattedMessage id="owerview.segment.eight.left.description.two.end"
                                            defaultMessage="in real time."/>
                      </p> 
                      <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.eight.left.description.four.begin"
                                            defaultMessage="Organize your inventory according to your"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.eight.left.description.four.h1"
                                            defaultMessage="storage areas"/></span>{' '}
                            <FormattedMessage id="owerview.segment.eight.left.description.four.end"
                                            defaultMessage="personalized."/>
                      </p> 
                      <br/>
                      <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.eight.left.description.three.begin"
                                              defaultMessage="Create your"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.eight.left.description.three.h1"
                                              defaultMessage="inventory list per category"/></span>{' '}
                            <FormattedMessage id="owerview.segment.eight.left.description.three.end"
                                              defaultMessage="at anytime (PDF document)."/>
                                              
                      </p> 
                    </Grid.Column>

                </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column  stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'45px'} height={'auto'} src={img_menu} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'recipe cost profit manu margin supplier tool':'gestion de fournisseurs commandes recettess'}/>&nbsp;
                             <FormattedMessage id="owerview.segment.nine.left.title"
                                                defaultMessage="Compose your menus or combos"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.nine.left.description.one.begin"
                                            defaultMessage="Arrange your menus based on recipes (or ingredients) per season, per day or per specific service."/>
                         </p>
                         <br/>
                         <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em'}}>
                           <FormattedMessage id="owerview.segment.nine.left.description.two.begin"
                                            defaultMessage="Associate a selling price and"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.nine.left.description.two.h1"
                                            defaultMessage="evaluate your margin and food costs."/></span>{' '}
                           <FormattedMessage id="owerview.segment.nine.left.description.two.end"
                                            defaultMessage="Extract your allergens matrix per menu."/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' stretched width={6}>
                       {/*  <div style={inlineImageStyle3.img}>  */} 
                        <Image
                            rounded
                            //centered
                            size='massive'
                            src={intl.locale == 'en'?img_dashboard_menu_en:img_dashboard_menu_fr}/>
                       {/*  </div> */}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </Segment>
          <Segment secondary style={{ padding: '6em 0em 6em 0em',border: 'none' }} vertical /* style={styles.FontLato14} */>
            <Grid container stackable verticalAlign='middle' /*textAlign='center'*/>
                <Grid.Row>
                    <Grid.Column floated='right'  width={7}>
                        <div style={{'text-align':'center'}}>
                          <img width={'400px'} height={'auto'} style={inlineImageStyle2.img}  src={img_organization}
                             alt={intl.locale == 'en'?'multisite organization team work':'multi etablissement organisation invitation'}/>
                       </div>
                    </Grid.Column>
                    <Grid.Column stretched floated='right' /*textAlign='justified'*/ width={6}>
                        <Header as='h1'>
                          <span><img width={'45px'} height={'auto'} src={img_business} style={inlineImageStyle3.img} 
                              alt={intl.locale == 'en'?'venue organization stock inventory':'gestion site equipe ingredients articles'}/>&nbsp;
                            <FormattedMessage id="owerview.segment.ten.left.title"
                                                defaultMessage="Multisite and team work"/></span>
                       </Header>
                        <p style={{ fontSize: '18px', padding:'0em 0em 0.5em 0em', marginTop: '2em'}}>
                            <FormattedMessage id="owerview.segment.ten.left.description.one.begin"
                                            defaultMessage="Simplify the management of your organization by managing several establishments/locations"/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.ten.left.description.one.h1"
                                            defaultMessage="from a single account"/></span>{' '}
                            <FormattedMessage id="owerview.segment.ten.left.description.one.end"
                                            defaultMessage="while separating your data (recipes, menus, orders, invoicing, etc ...)"/>
                        </p>
                       <p style={{ fontSize: '18px'}}>
                            <FormattedMessage id="owerview.segment.ten.left.description.two.begin"
                                            defaultMessage="Easily invite your employees "/>{' '}
                            <span style={{color:'#63a4dd', 'fontWeight':700}}><FormattedMessage id="owerview.segment.ten.left.description.two.h1"
                                            defaultMessage=" to join"/></span>{' '}
                            <FormattedMessage id="owerview.segment.ten.left.description.two.end"
                                            defaultMessage="by giving them with the appropriate rights in your structure."/>
                      </p> 
                    </Grid.Column>

                </Grid.Row>
            </Grid>
          </Segment>
          <Segment style={{ padding: '4em 0em 4em 0em',border: 'none' }} vertical>
            <Grid container stackable verticalAlign='middle' centered>
              <br/>
              <Header as='h2' centered>
{/*                        <FormattedMessage id="owerview.segment.try.text.title"
                                         defaultMessage="Try Ratatool for free and improve your profitability"/> */}
                        <FormattedMessage id="owerview.segment.try.text.begin.title"
                                          defaultMessage="Try our solution"/>{' '}
                        <span /*style={{color:'#63a4dd'}}*/><FormattedMessage id="owerview.segment.try.text.middle.title"
                                          defaultMessage="now"/></span>{' '}
                        <FormattedMessage id="owerview.segment.try.text.end.title"
                                          defaultMessage="and improve your profitability"/>
              </Header>
              <Grid.Row>
{/*                 <Header as='h3' centered style={{color:'#83d980'}}>
                        <Icon name='chart line'/> 
                        <Header.Content>
                            <FormattedMessage id="owerview.segment.try.text2.title"
                                          defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                        </Header.Content>
                </Header> */}
                <List divided relaxed style={{ marginLeft: '2em'/*, 'font-style':'italic'*/,textAlign:'left'  }}>
                    <Header as='h3' centered style={{color:'#63a4dd'}}>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="owerview.segment.try.text5.title"
                                              defaultMessage="Get your actual costs dynamically"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="owerview.segment.try.text2.title"
                                              defaultMessage="Earn margin points by targeting your unprofitable recipes"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green' /> 
                            <Header.Content>
                              <FormattedMessage id="owerview.segment.try.text6.title"
                                              defaultMessage="Plan your productions tasks and food needs"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="owerview.segment.try.text4.title"
                                              defaultMessage="Secure your essential business data"/> 
                            </Header.Content><br/>
                            <Icon name='check' size='small' color='green'/> 
                            <Header.Content>
                              <FormattedMessage id="owerview.segment.try.text3.title"
                                              defaultMessage="Save hours of work on your daily repetitive tasks"/> 
                            </Header.Content>
                            <br/>
                    </Header>
                </List>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Button size='huge' as={Link} to="/register" style={styles.ButtonBlueAsIconUpper}>
                      <FormattedMessage id="owerview.segment.try.button.text"
                                         defaultMessage="Start for free"/>
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <p><FormattedMessage id="owerview.segment.try.info.text"
                                   defaultMessage="No credit card required"/></p>
            </Grid>
          </Segment>
          <MainFooter loggedIn/>
        </ResponsiveContainer>
    )
  }
}

function mapStateToProps(state) {
    const { alert,dispatch} = state;
    const { loggedIn} = state.authentication;
    return {
        loggedIn,
        alert,
        dispatch
    };
}

const connectedOverview = injectIntl(connect(mapStateToProps)(Overview));
export { connectedOverview as Overview };