import { authHeader,isEmpty } from '../_helpers';
import { serverConstants } from '../_constants';

export const saleService = {
    getAll,
    add,
    retrieveFromPos,
};


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
/*             const error = new Error(json.message)
            return Promise.reject(Object.assign(error, { response })) */
/*             const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
            }); 
            return Promise.reject(error);*/

            if (!isEmpty(json.message)){
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
               });
               return Promise.reject(error);
           }else{
                const error = Object.assign({}, json, {
                   status: response.status,
                   statusText: response.statusText,
                   title: json.detail,
               });
               return Promise.reject(error);
           }   
        })
    }

    return response.json();
}

function getAll(short) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (short == true){
        //Speed flat request (not nested)
        return fetch(serverConstants.BACKEND_URL+'/sales/get_list/', requestOptions).then(handleResponse);
    }else{
        return fetch(serverConstants.BACKEND_URL+'/sales/', requestOptions).then(handleResponse);
    }
}


function add(recipe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/sales/', requestOptions).then(handleResponse);
}


function retrieveFromPos() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/pos_sales/', requestOptions).then(handleResponse);
}


