import React from 'react';
import { connect } from 'react-redux';
import {Elements} from 'react-stripe-elements';
import {Grid,Button,Image,Message,Dimmer,Loader,Icon} from 'semantic-ui-react';
import SideLeftUncoverPayment from './SidebarPayment';
import {CheckoutForm} from './Payment/CheckoutForm';
import {injectIntl,FormattedMessage,FormattedNumber} from 'react-intl';
import { paymentActions } from '../_actions';
import {history,toaster_dispatch,OWNER} from '../_helpers';
import { alertConstants } from '../_constants';
import {styles} from './Styles';
import {Helmet} from "react-helmet";

//StripeProvider gives us access to the Stripe Object
//i.e Stripe.createToken, stripe.elements() etc
//App loads the stripe script asynchronously in CDM
import {StripeProvider} from 'react-stripe-elements';

//Wrap <Elements /> around your checkout form to group the set
// of Stripe Elements you're using together, so Stripe can pull
// data from groups of Elements when tokenizing

import img_performance from '../Assets/performance.png'
import { stripeConstants } from '../_constants';



class StorePlanCheckout extends React.Component {
  constructor(props) {
        super(props);
        const {location} = props;
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
                       name: location.state.name,
                       type: location.state.type,
                       mode: location.state.interval,
                       price: location.state.price,
                       bill_locale : location.state.bill_locale,
                       subscribing: false,
                       userIn : userIn,
                       active_role: userIn.user.role?userIn.user.role:OWNER,
                    };
        
        if (userIn)
        {
            props.dispatch(paymentActions.getSubscription());
            props.dispatch(paymentActions.getCustomerCoupons());
        }
        props.dispatch(paymentActions.getCustomerMembership());
       
    }

   upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    updateLoadingState = (loading) => {
        this.setState({ subscribing: loading });
    }


  render() {
    const {name,price,type,mode,bill_locale,subscribing,userIn,active_role} = this.state;
    const {intl,dispatch,alert,/*coupons*/} = this.props;
    const {subscription,customer} = this.props.payment;
    
    let membership = null;
    let used_locale = bill_locale;
    if (customer){
      membership = customer.membership;
/*       if (membership){
            used_locale = (customer.currency == 'EUR'?'fr':'en');
      }; */
    }

    if (userIn && customer ){
        used_locale = (customer.currency == 'EUR'?'fr':'en');
    }

    if (alert.message && alert.type === alertConstants.ERROR){
        //console.log(alert)
        this.setState({ subscribing: false });
        setTimeout(()=>{ window.location.reload(); }, 2000);
    }
       
    return (

        <SideLeftUncoverPayment >
            <Helmet>
                  <meta name="robots" content="noindex"/>
            </Helmet>
            {alert.message && toaster_dispatch(dispatch,alert,intl)}
            {subscribing &&
                <Dimmer active inverted>
                        <Loader inverted inline content='Loading' size='big' />
                </Dimmer> } 
            <Grid stackable>
                <Grid.Row columns={1} >
                    <Grid.Column  width={8}>
                    { (active_role == OWNER) &&
                        subscription && 
                        !subscription.customer && 
                         price>0 &&
                         <StripeProvider apiKey={''+stripeConstants.STRIPE_PUBLIC_KEY}>
                            <Elements locale={intl.locale}>
                                <CheckoutForm callback={(is_loading) => this.updateLoadingState(is_loading) } 
                                              name={name} 
                                              type={type} 
                                              mode={mode} 
                                              price={price}
                                              bill_locale = {used_locale} />
                            </Elements>
                        </StripeProvider>}
                    {  (active_role == OWNER) && 
                         subscription && 
                         subscription.customer &&
                         (subscription.cancel_period_end == true) && 
                         price>0 &&
                     <StripeProvider apiKey={''+stripeConstants.STRIPE_PUBLIC_KEY}>
                        <Elements locale={intl.locale}>
                                <CheckoutForm callback={(is_loading) => this.updateLoadingState(is_loading) } 
                                              name={name} 
                                              type={type} 
                                              mode={mode} 
                                              price={price}
                                              bill_locale = {used_locale} />
                         </Elements>
                     </StripeProvider>}
                   {  (active_role == OWNER) && 
                        subscription && 
                        subscription.customer &&
                         (subscription.cancel_period_end == false) &&
                         ((subscription.customer.membership.membership_type != type) 
                                ||  (subscription.customer.membership.interval != mode)
                                || ((subscription.customer.membership.membership_type == 'Entreprise')
                         )) &&
                         price>0 &&
                     <StripeProvider apiKey={''+stripeConstants.STRIPE_PUBLIC_KEY}>
                        <Elements locale={intl.locale}>
                                <CheckoutForm callback={(is_loading) => this.updateLoadingState(is_loading) } 
                                              name={name} 
                                              type={type} 
                                              mode={mode} 
                                              price={price}
                                              bill_locale = {used_locale} />
                        </Elements>
                     </StripeProvider>}
                    {subscription && 
                        subscription.customer && 
                            (subscription.customer.membership.membership_type == type) && 
                            (subscription.customer.membership.interval == mode) &&
                            (subscription.cancel_period_end == false) &&
                            (subscription.customer.membership.membership_type != 'Entreprise') &&
                    <div>
                        <br/><br/><br/> 
                        <Message fluid icon size='big' style={styles.FontLato12Bold}>
                            <Image src={img_performance} size ='small' rounded/>
                            <br/>
                            <Message.Content style={{marginLeft:'1em'}}>
                                <br/>
                                <Message.Header color='black'>
                                    <FormattedMessage id="storeplan.message.alreadysubscribed"
                                                      defaultMessage='You already subscribed this plan'/>
                                </Message.Header>
                                <br/>
                                <FormattedMessage id="storeplan.title.message.upgradeplan"
                                                defaultMessage='Your current plan: '/>
                                {subscription && subscription.customer &&
                                    <p>
                                      {subscription.customer.membership.name} {' '}
                                         <FormattedNumber value={Number(subscription.customer.membership.price * subscription.quantity)} 
                                                                style="currency"
                                                                minimumFractionDigits={Number(subscription.customer.membership.price * subscription.quantity) % 1 === 0 ? 0 : 2}
                                                                maximumFractionDigits={Number(subscription.customer.membership.price * subscription.quantity) % 1 === 0 ? 0 : 2}
                                                                currencyDisplay="symbol" 
                                                                currency={(used_locale == "fr"?'EUR':'USD')}/>
                                        { subscription.customer.membership.interval =='Annually' && 
                                            <FormattedMessage id="storeplan.card.message.plan.mode.year"
                                                              defaultMessage="/year"/>}
                                        { subscription.customer.membership.interval =='Monthly' && 
                                             <FormattedMessage id="storeplan.card.message.plan.mode.month"
                                                                defaultMessage="/month"/>}
                                
                                    </p>                                               
                                 } 
                                <br/>
                                <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="storeplan.button.chooseanotherplan"
                                                    defaultMessage='Choose another plan'/>
                                </Button>                                      
                            </Message.Content>
                        </Message>
                    </div>
                    }
                    { price<=0 && membership &&
                        <div>
                         <br/><br/><br/> 
                        <Message fluid icon size='big' style={styles.FontLato12Bold} >
                            <Image src={img_performance} size ='small' rounded/>
                            <br/>
                            <Message.Content style={{marginLeft:'1em'}}>
                                <br/>
                                <Message.Header color='black'>
                                    <FormattedMessage id="storeplan.message.upgradeplan"
                                                    defaultMessage='Upgrade your plan'/>
                                </Message.Header>
                                <br/>
                                <p>
                                    <FormattedMessage id="storeplan.title.message.upgradeplan"
                                                      defaultMessage='Your current plan: '/>{' '}{membership.name}
                                </p>
                                <br/><br/>
                                <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="storeplan.button.chooseplan"
                                                defaultMessage='Choose premium plan'/>
                                </Button>                                      
                            </Message.Content>
                        </Message>
                        </div>
                    }
                    {(active_role<OWNER) && 
                        <div>
                          <br/><br/><br/> 
                          <Message compact info icon size='large'>
                                <Icon name='settings' color='teal'/>
                                <Message.Content>
                                    <Message.Header>
                                            <FormattedMessage id="storeplan.message.title"
                                                                defaultMessage='Subscriptions'/>
                                    </Message.Header>
                                    <Icon name='lock' color='teal'/>
                                    <FormattedMessage id="storeplan.message.details"
                                                          defaultMessage='You should have Owner privilege.' />
                                    <br/> <br/>
                                </Message.Content>
                        </Message>
                        </div>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SideLeftUncoverPayment>
    );
  }
}
function mapStateToProps(state) {
    const { alert,dispatch,payment} = state;
    //const { coupons } = state.payment;
    return {
        payment,
        //coupons,
        alert,
        dispatch
    };
}

const connectedStorePlanCheckout = injectIntl(connect(mapStateToProps)(StorePlanCheckout));
export { connectedStorePlanCheckout as StorePlanCheckout };
