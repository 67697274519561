import { ingredientConstants } from '../_constants';
//import React, { Component } from 'react';
//import { Button} from 'semantic-ui-react';


//const INGREDIENT ='INGREDIENT';

export function ingredients(state = {}, action) {
  switch (action.type) {
    case ingredientConstants.GETALL_REQUEST:
      return {
        loading_i: true
      };
    case ingredientConstants.GETALL_SUCCESS:
/*       let options=[];
      let searchList=[];
      action.ingredients.map((item,index)=>{
        options[index]= {key:index,text:'Ingredient: '+item.name,value:item.name,type:'ingredient'};
        searchList.push({
          name: INGREDIENT,
          title: item.name,
          //price: 'price: ' + item.price + '€',
          price: item.price,
          description: INGREDIENT,
          image: <Button icon='apple' size='large'/>
        });
      }); */
      let sorted_items = action.ingredients
      if (sorted_items){
        sorted_items = sorted_items.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }
      return {
        loading_i: false,
        items: sorted_items,
        //options: options,
        //searchList: searchList
      };
    case ingredientConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      
    case ingredientConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ingredientConstants.GET_SUCCESS:
      let extra_suppliers = []
      if (action.ingredients.extra_suppliers && action.ingredients.extra_suppliers.length>0){
        for (let elem of action.ingredients.extra_suppliers){
              extra_suppliers.push(elem)
        }   
      }   
      
      let legacy_supplier = {
        price:action.ingredients.price,
        purchase_weight:action.ingredients.purchase_weight,
        purchase_volume:action.ingredients.purchase_volume,
        purchase_quantity:action.ingredients.purchase_quantity,
        supplier:action.ingredients.supplier,
        ref_supplier:action.ingredients.ref_supplier,
        default_supplier:action.ingredients.default_supplier,
       } 
       return {
        ...state,
        loading: false,
        selected: action.ingredients,
        extra_suppliers: extra_suppliers, 
        default_supplier: legacy_supplier, 
      };
    case ingredientConstants.GET_FAILURE:
      return { 
        error: action.error
      };

    case ingredientConstants.ADD_REQUEST:
      return {
        ...state,
        adding: true
      };
    case ingredientConstants.ADD_SUCCESS:

      let newItems = []
      if (state.items){
        newItems = [...state.items, action.ingredients]
      }else{
        newItems = [action.ingredients]
      }
      let newOptions=[];
      newItems.map((item,index)=>
        newOptions[index]= {key:index,text:'Ingredient: '+item.name,value:item.name,type:'ingredient'});

      return {
          ...state,
          adding: false,
          added: true,
          items: newItems,
          options: newOptions
      };

    case ingredientConstants.ADD_FAILURE:
      return { 
        error: action.error
      };
    
    case ingredientConstants.ADD_FROM_FILE_REQUEST:
        return {
          adding: true
        };
    
    case ingredientConstants.ADD_FROM_FILE_SUCCESS:
  
        return {
            ...state,
            adding: false,
            added: true,
            items: [action.ingredients],
        };
  
    case ingredientConstants.ADD_FROM_FILE_FAILURE:
        return { 
          error: action.error
        };

    
    case ingredientConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };

    case ingredientConstants.UPDATE_SUCCESS:
        let extras = []
        if (action.ingredients.extra_suppliers && action.ingredients.extra_suppliers.length>0){
          for (let elem of action.ingredients.extra_suppliers){
            extras.push(elem)
          }   
        }   
        
        legacy_supplier = {
          price:action.ingredients.price,
          purchase_weight:action.ingredients.purchase_weight,
          purchase_volume:action.ingredients.purchase_volume,
          purchase_quantity:action.ingredients.purchase_quantity,
          supplier:action.ingredients.supplier,
          ref_supplier:action.ingredients.ref_supplier,
          default_supplier:action.ingredients.default_supplier,
        }

        return {
          ...state,
          updating: false,
          updated: true,
          selected: action.ingredients,
          extra_suppliers: extras, 
          default_supplier: legacy_supplier, 
      };

    case ingredientConstants.UPDATE_FAILURE:
     return { 
        error: action.error
      };

    case ingredientConstants.EXTRA_SUPPLIER_ADD_REQUEST:
    case ingredientConstants.EXTRA_SUPPLIER_EDIT_REQUEST:
    case ingredientConstants.DEFAULT_SUPPLIER_REQUEST:
       return {
            ...state,
            updating: false,
          };
    case ingredientConstants.EXTRA_SUPPLIER_ADD_SUCCESS:
    case ingredientConstants.EXTRA_SUPPLIER_EDIT_SUCCESS:
    case ingredientConstants.DEFAULT_SUPPLIER_SUCCESS:
      let extras_again = []     
      if (action.ingredients.extra_suppliers 
            && action.ingredients.extra_suppliers.length>0){
            for (let elem of action.ingredients.extra_suppliers){
              extras_again.push(elem)
            }   
      }   
            
      legacy_supplier = {
              price:action.ingredients.price,
              purchase_weight:action.ingredients.purchase_weight,
              purchase_volume:action.ingredients.purchase_volume,
              purchase_quantity:action.ingredients.purchase_quantity,
              supplier:action.ingredients.supplier,
              ref_supplier:action.ingredients.ref_supplier,
              default_supplier:action.ingredients.default_supplier 
      }
            
      return {
               ...state,
               updating: false,
               extra_suppliers: extras_again, 
               default_supplier: legacy_supplier,
            };

    case ingredientConstants.EXTRA_SUPPLIER_ADD_FAILURE:
    case ingredientConstants.EXTRA_SUPPLIER_EDIT_FAILURE:
    case ingredientConstants.DEFAULT_SUPPLIER_FAILURE:
        return { 
                 error: action.error
               };

    case ingredientConstants.RECIPES_REQUEST:
      return {
        ...state,
        loading_recipes: true
      };
    case ingredientConstants.RECIPES_SUCCESS:
      if (action.ingredients.length>0){
        return {
          ...state,
          loading_recipes: false,
          recipes: action.ingredients
        };
      }else{
        return {
          ...state
        };
      }
    case ingredientConstants.RECIPES_FAILURE:
      return { 
        error: action.error
      };

    case ingredientConstants.COUNT_REQUEST:
      return {
        counting: true
      };
    case ingredientConstants.COUNT_SUCCESS:
      return {
        counting: false,
        count: action.ingredients

      };
    case ingredientConstants.COUNT_FAILURE:
      return { 
        error: action.error
      };
    case ingredientConstants.DELETE_REQUEST:
      return {
        deleting:true
      };
    case ingredientConstants.DELETE_SUCCESS:
      return {
        deleting: false,
        deleted:true
      };
    case ingredientConstants.DELETE_WARN:
      return {
        deleting: false,
        deleted:true
      };
    case ingredientConstants.DELETE_FAILURE:
      return {
        deleting: false,
        error: action.error
      };

    case ingredientConstants.EXPORT_CSV_REQUEST:
        return {
          exporting:true
        };
    case ingredientConstants.EXPORT_CSV_SUCCESS:
        return {
          exporting:false,
        };
    case ingredientConstants.EXPORT_CSV_FAILURE:
        return {
          error: action.error
        };

    case ingredientConstants.EXPORT_XLSX_REQUEST:
        return {
            exporting:true
          };
    case ingredientConstants.EXPORT_XLSX_SUCCESS:
        return {
            exporting:false,
          };
    case ingredientConstants.EXPORT_XLSX_FAILURE:
        return {
            error: action.error
          };

    case ingredientConstants.INVENTORY_REQUEST:
            return {
                exporting:true
              };
    case ingredientConstants.INVENTORY_SUCCESS:
            return {
                exporting:false,
              };
    case ingredientConstants.INVENTORY_FAILURE:
            return {
                error: action.error
              };

     case ingredientConstants.UPDATE_STOCK_REQUEST:
          return {
                ...state,
                updating:true
              };
     case ingredientConstants.UPDATE_STOCK_SUCCESS:
          return {
              ...state,
              updating:false,
              //items: action.ingredients,
              };
      case ingredientConstants.UPDATE_STOCK_FAILURE:
          return {
              error: action.error
              };
      case ingredientConstants.RESET_STOCK_REQUEST:
          return {
                    updating:true
                  };
      case ingredientConstants.RESET_STOCK_SUCCESS:
          return {
                  updating:false,
                  };
      case ingredientConstants.RESET_STOCK_FAILURE:
          return {
                  error: action.error
                  };
      case ingredientConstants.HISTORY_REQUEST:
          return {
                     ...state,
                    loading: true
                  };
      case ingredientConstants.HISTORY_SUCCESS:
            return {
                      ...state,
                      loading: false,
                      history: action.ingredients
              };
       case ingredientConstants.HISTORY_FAILURE:
            return { 
                    error: action.error
              };
              
      case ingredientConstants.WEIGHT_VARIATION_REQUEST:
            return {
                  //...state,
                  updating: true
                };
      case ingredientConstants.WEIGHT_VARIATION_SUCCESS:
            return {
                  //...state,
                  updating: false,
                  updated: true,
                  selected: action.ingredients,
                };
      case ingredientConstants.WEIGHT_VARIATION_FAILURE:
            return { 
                  error: action.error
                };

      case ingredientConstants.STOCK_AREA_REQUEST:
            return {
              //...state,
              updating: true
            };
      case ingredientConstants.STOCK_AREA_SUCCESS:
            return {
                //...state,
                updating: false,
                updated: true,
                selected: action.ingredients,
            };
      case ingredientConstants.STOCK_AREA_FAILURE:
            return { 
                    error: action.error
                   };

      case ingredientConstants.UPDATE_IMAGE_REQUEST:
              return {
                    ...state,
                    updating: true
                  };
       case ingredientConstants.UPDATE_IMAGE_SUCCESS:
              return {
                    ...state,
                    updating: false,
                    updated: true,
                  };
       case ingredientConstants.UPDATE_IMAGE_FAILURE:
              return { 
                    error: action.error
                  };
       case ingredientConstants.GET_IMAGE_REQUEST:
              return {
                      ...state,
                      loading_image: true
                    };
        case ingredientConstants.GET_IMAGE_SUCCESS:
              return {
                      ...state,
                      loading_image: false,
                      //loading: true,
                      image:  action.ingredients,
                    };
        case ingredientConstants.GET_IMAGE_FAILURE:
              return { 
                      error: action.error
                    };
        case ingredientConstants.CATEGORY_ALL_REQUEST:
              return {
                     updating:true
               };
        case ingredientConstants.CATEGORY_ALL_SUCCESS:
              return {
                     updating: false,
              };
        case ingredientConstants.CATEGORY_ALL_FAILURE:
             return {
                  error: action.error
            };
        case ingredientConstants.STOCKAREA_ALL_REQUEST:
              return {
                     updating:true
               };
        case ingredientConstants.STOCKAREA_ALL_SUCCESS:
              return {
                     updating: false,
              };
        case ingredientConstants.STOCKAREA_ALL_FAILURE:
             return {
                  error: action.error
            };
        case ingredientConstants.UPDATE_VALIDITY_REQUEST:
              return {
                    ...state,
                    updating:true
                  };
        case ingredientConstants.UPDATE_VALIDITY_SUCCESS:
              return {
                  ...state,
                  updating:false,
                  //items: action.ingredients,
                  };
        case ingredientConstants.UPDATE_VALIDITY_FAILURE:
              return {
                  error: action.error
                  };
    default:
      return state
  }
}

