import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, List, Menu, Container, Icon,Button } from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
//import backgroundImg from '../images/light-grey.jpg';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import {history} from '../_helpers';
import {styles} from './Styles';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};


//{height: 'auto', width:'1400px',margin: '0 auto' ,border:'none','background-color':'#fcfcfc'}

class SideLeftUncoverRecipes extends Component {
  state = { visible: false }

  isActive(match1){
    return this.props.location.pathname.indexOf(match1)!=-1
  }

  onSubmit = (e) => {
    e.preventDefault();
    history.push({pathname: '/profilepremium', state: {toggled: true}})
  }
  
  render() {
    const {subscription} = this.props;

    return (
      <div>
        <Responsive minWidth={Responsive.onlyComputer.minWidth} >
            <Sidebar.Pushable as={Container} style={containerStyle}>
{/*             <Sidebar as={Menu}  fixed='left' selection  animation='uncover' width='thin' visible={true} icon='labeled' vertical >
                <Menu.Item>Recipe actions</Menu.Item>
                <Menu.Item name='Add Recipe' as={Link} to='/newrecipe' >
                  <Icon name='add' size='mini'/>
                  Add Recipe
                </Menu.Item>
                <Menu.Item name='Download Recipe'>
                  <Icon name='download' size='mini'/>
                  Download Recipe
                </Menu.Item>  */}
             <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical='true' size='large'>
                <br/>
                <List.Item disabled><FormattedMessage id="recipes.computer.sidemenu.menu.top.title"
                                                       defaultMessage='Recipes Actions'/></List.Item>
                <List.Item name='View Recipes' as={Link} to='/recipes' >
                  <Icon name='home' color={this.isActive('/recipes')?'blue':'black'} />
                  <List.Content >
                    <List.Header style={this.isActive('/recipes')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipes.computer.sidemenu.menu.elem1.title"
                                                   defaultMessage='Recipes'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item  name='New Recipe' as={Link} to='/newrecipe'>
                  <Icon name='add' color={this.isActive('newrecipe')?'blue':'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('newrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipes.computer.sidemenu.menu.elem2.title"
                                                   defaultMessage='New'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='download' as={Link} to='/exportrecipes'> 
                  <Icon name='download' color={this.isActive('exportrecipes')?'blue':'black'} />
                  <List.Content>
                    <List.Header  style={this.isActive('exportrecipes')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipes.computer.sidemenu.menu.elem3.title"
                                                   defaultMessage='Export'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item  as={Link} to='/categoriesrecipe'>
                  <Icon name='sitemap' color={this.isActive('categoriesrecipe')?'blue':'black'} />
                  <List.Content>
                    <List.Header  style={this.isActive('categoriesrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipes.computer.sidemenu.menu.elem4.title"
                                                   defaultMessage='Categories'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as={Link} to='/allergensrecipe'>
                  <Icon name='table'  color={this.isActive('allergensrecipe')?'blue':'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('allergensrecipe')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="recipes.computer.sidemenu.menu.elem5.title"
                                                   defaultMessage='Allergens'/></List.Header>
                  </List.Content>
                </List.Item>
{/*                 <List.Item name='New Menu' as={Link} to='/menus'>
                  <Icon name='block layout' color='blue'/>
                  <List.Content>
                    <List.Header><FormattedMessage id="recipes.computer.sidemenu.menu.elem5.title"
                                                   defaultMessage='Menus'/></List.Header>
                  </List.Content>
                </List.Item> */}
                {subscription && ( !subscription.customer__membership__membership_type ||  subscription.customer__membership__membership_type=="Free") && this.isActive('/recipes') &&
                    <div style={{'text-align':'center', 'paddingTop' : '26em'}}>
                      <a style={{color:'grey'}}>
                            <FormattedMessage id="recipes.computer.sidemenu.premium.title.message.subscribe"
                                              defaultMessage='Plan: Basic'/>
                      </a>
                      <Button style={styles.ButtonBluePremium}
                        onClick={(e) => {this.onSubmit(e)}}>
                        <FormattedMessage id="recipes.button.premium"
                                          defaultMessage='Subscribe'/>
                      </Button>
                    </div>}
              </Sidebar> 
              <Sidebar.Pusher style={{ minHeight: '100vh',overflowY: 'auto','width':'100%', padding: '2em 2em 20em 3em', border: 'none','background-color': '#fcfcfc' }}>
                  {this.props.children} 
              </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Responsive>
        <Responsive minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/><FormattedMessage id="recipes.mobile.sidemenu.menu.top.title"
                                                            defaultMessage='Recipes'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  {/* <Container> */}
                   <Menu.Item name='View'as={Link} to='/recipes'>
                      <Icon name='home' color={this.isActive('/recipes')?'blue':'black'} />
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                    </Menu.Item>
                    <Menu.Item name='New' as={Link} to='/newrecipe' >
                      <Icon name='add' color={this.isActive('/newrecipe')?'blue':'black'}/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='New'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to='/exportrecipes'>
                      <Icon name='download' color={this.isActive('/exportrecipes')?'blue':'black'}/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Export'/>
                    </Menu.Item>
                    <Menu.Item name='Categories' as={Link} to='/categoriesrecipe'>
                      <Icon name='sitemap' color={this.isActive('/categoriesrecipe')?'blue':'black'}/>
                       <FormattedMessage id="recipes.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Categories'/>
                    </Menu.Item>
                    <Menu.Item name='Allergens' as={Link} to='/allergensrecipe'>
                      <Icon name='table' color={this.isActive('/allergensrecipe')?'blue':'black'}/>
                       <FormattedMessage id="recipes.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Allergens'/>
                    </Menu.Item>
{/*                      <Menu.Item name='New menu' as={Link} to='/menus' >
                      <Icon name='block layout' color='blue'/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Menus'/>
                    </Menu.Item> */}
                  {/* </Container> */}  
                 </Sidebar>
                <Sidebar.Pusher  dimmed={this.state.visible} style={{  minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 2em' }}>
                      {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
         <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/><FormattedMessage id="recipes.mobile.sidemenu.menu.top.title"
                                                            defaultMessage='Recipes'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  {/* <Container> */}
                   <Menu.Item name='View'as={Link} to='/recipes'>
                      <Icon name='home' color={this.isActive('/recipes')?'blue':'black'}/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='View'/>
                    </Menu.Item>
                    <Menu.Item name='New' as={Link} to='/newrecipe' >
                      <Icon name='add' color={this.isActive('/newrecipe')?'blue':'black'}/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='New'/>
                    </Menu.Item>
                    <Menu.Item name='Download' as={Link} to='/exportrecipes'>
                      <Icon name='download' color={this.isActive('/exportrecipes')?'blue':'black'}/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Export'/>
                    </Menu.Item>
                    <Menu.Item name='Categories' as={Link} to='/categoriesrecipe'>
                      <Icon name='sitemap' color={this.isActive('/categoriesrecipe')?'blue':'black'}/>
                       <FormattedMessage id="recipes.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Categories'/>
                    </Menu.Item>
                   <Menu.Item name='Allergens' as={Link} to='/allergensrecipe'>
                      <Icon name='table' color={this.isActive('/allergensrecipe')?'blue':'black'}/>
                       <FormattedMessage id="recipes.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Allergens'/>
                    </Menu.Item>
{/*                     <Menu.Item name='New menu' as={Link} to='/menus' >
                      <Icon name='block layout' color='blue'/>
                        <FormattedMessage id="recipes.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Menus'/>
                    </Menu.Item> */}
                  {/* </Container> */}  
                 </Sidebar>
                <Sidebar.Pusher  dimmed={this.state.visible} style={{  minHeight: '100vh',overflow: 'auto', padding: '2em 0em 20em 0em' }}>
                      {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
      </div>
    )
  }
}

//export default withRouter(SideLeftUncoverRecipes)

function mapStateToProps(state) {
  const { subscription } = state.payment;
  return {
      subscription,
  };
}
export default connect(mapStateToProps)(withRouter(SideLeftUncoverRecipes))

