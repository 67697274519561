import React, { Component } from 'react';
import { recipesActions,alertActions } from '../_actions';
import {Grid,Image,Header,Form, Button,Icon,Breadcrumb} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverRecipe from './SidebarRecipe';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import './PrepareInstructions.css';
import {toaster_dispatch,history,GUEST} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_instructions from '../Assets/blank_instructions.jpg';


import './hover.css';

window.Quill = Quill
Quill.register('modules/imageResize', ImageResize);

const DEFAULT_RECIPE_IMAGE = "http://127.0.0.1:8000/media/covers/book-bindings-composition-desk-891059.jpg"

let title = <FormattedMessage id="prepareinstructions.file.error.title"
                              defaultMessage="Error"/>
let content_err_file_size = <FormattedMessage id="prepareinstructions.file.size.error.content"
                                              defaultMessage="File size or format not supported (max 1 Mb)"/> 
let content_permission = <FormattedMessage id="prepareinstructions.file.permission.error.content"
                                              defaultMessage="You do not have Write privilege"/>  

                                              
class PrepareInstructions extends Component {
    constructor(props) {
        super(props)
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { prevRecipe : props.recipes, 
                       prevImage : null,
                       hasChanged:false,
                       //files: null,
                       file: DEFAULT_RECIPE_IMAGE,
                       active_role: userIn.user.role,
                      }  
        this.state = { htmlText: '' }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeImage = this.handleChangeImage.bind(this)
        this.triggerInputFile = this.triggerInputFile.bind(this);
        this.fileUpload = React.createRef();
        this.reactQuillRef = null;
        props.dispatch(recipesActions.get(props.match.params.recipeId));
    }

    modules = {
          toolbar:{
            container: [
                [{ 'header': '1'}, {'header': '2'}, /*{ 'font': [] }*/],
                [{size: []}],
                ['bold', 'italic', 'underline'/*,'strike', 'blockquote'*/],
                [{'list': 'ordered'}, {'list': 'bullet'}, 
                {'indent': '-1'}, {'indent': '+1'}],
                [/* 'link', */ 'image'/* , 'video' */],
                ['clean']
              ],
            handlers: {
              image:   () => quill_img_handler(this.reactQuillRef ,this.props,this.state),
            },
          },
          imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize'],
          },
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
      }


    static getDerivedStateFromProps(props, state) {

        if((props.recipes!==state.prevRecipe) && (props.recipes.selected)) {
            const selected = props.recipes.selected;
            if (selected.image && selected.image!=state.prevImage){
              return {
                prevRecipe : props.recipes,
                prevImage : selected.image,
                selected: selected,
                file: selected.image,
/*                 files: [{
                  source: selected.image,
                  options: {
                      type: 'local',
                  },
                 }], */
                htmlText: selected.instructions
              }
             }else{
              return {
                prevRecipe : props.recipes,
                selected: selected,
                //file : DEFAULT_RECIPE_IMAGE,
                htmlText: selected.instructions
              } 
            }

        }
        return null;
    }

    handleChange(content, delta, source, editor) {
        const { dispatch} = this.props;
        const {htmlText,active_role,selected} = this.state;
       if (active_role == GUEST){
           dispatch(alertActions.error({title: 'prepareinstructions.file.error.title',
                                     content:'prepareinstructions.file.permission.error.content'}));
          return
       }

       if (delta && 
          (delta.ops[0] && delta.ops[0].delete) || 
          (delta.ops[1] && delta.ops[1].delete))
        {
          let last_imgs = htmlText.match(/<img.*?src="(.*?)"/g);
          let new_imgs = content.match(/<img.*?src="(.*?)"/g);
          let imgs_delete = []
          if (last_imgs && last_imgs.length>0 ){
              if (new_imgs){
                imgs_delete = last_imgs.filter((element) => !new_imgs.includes(element));
              }else{
                imgs_delete = last_imgs
              }
          }

          if (imgs_delete.length > 0){
              let url = imgs_delete[0].match(/"([^"]+)"/g);
              //sample: "https://ratatool-media.s3.amazonaws.com/media/recipes-img/taxidriver7535%40gmail.com/blank_instructions_rYNrgPO.jpg"
              let absolute_path = decodeURIComponent(url[0]);
              //console.log(absolute_path.slice(41, -1))
              if (selected && url[0].indexOf('ratatool-media.s3.amazonaws.com/media/recipes-img/') !== -1){
                    let editorHtml = editor.getHTML()
                    dispatch(recipesActions.updateInstructions(selected.id,editorHtml,true));
                    dispatch(recipesActions.deleteImages(selected.id,absolute_path.slice(41, -1)));
                    this.setState({ htmlText: editorHtml })
              }
          }
       }
       this.setState({ htmlText: editor.getHTML() })
    }

    handleChangeImage(event) {
      /*         this.setState({
              file: URL.createObjectURL(event.target.files[0])
           })  */
      
      /*      if (event.target.files && event.target.files[0]) {
              let reader = new FileReader();
              reader.onload = (e) => {
                this.setState({file: e.target.result});
                console.log(e.target.result);
              };
              reader.readAsDataURL(event.target.files[0]);
            } */
            
            const {selected} = this.state;
            const { dispatch} = this.props;
      
            if (selected  && event.target.files && event.target.files[0]) {
      
             let current_file = event.target.files[0];
             let data = new FormData();  
             
             if(current_file.size > 2097152){
                  //alert("File is too big!");
/*                   toaster_error(<FormattedMessage id="prepareinstructions.image.update.error.title"
                                                    defaultMessage='Image Error'/>,
                                  <FormattedMessage id="prepareinstructions.image.update.error.message"
                                                   defaultMessage='File too big (max size 2Mb)'/>)*/
                   const title = <FormattedMessage id="prepareinstructions.image.update.error.title"
                                                    defaultMessage='Image Error'/>
                   const content = <FormattedMessage id="prepareinstructions.image.update.error.message"
                                                    defaultMessage='File too big (max size 2Mb)'/>
                   dispatch(alertActions.error({title:'prepareinstructions.image.update.error.title',
                                                content:'prepareinstructions.image.update.error.message'}));
                  return;
              };
      
      
              data.append('filetoupload', current_file,current_file.name);
              this.setState({
                file: URL.createObjectURL(event.target.files[0])
              });
              dispatch(recipesActions.updateImage(selected.id,data));
            }
          }


    onSubmit = (e) => {
        e.preventDefault();
  
        const {htmlText,selected/*, files*/} = this.state;
        const { dispatch} = this.props;

        if (htmlText && selected /*&& files*/) {
            this.setState({ hasChanged: false })
            dispatch(recipesActions.updateInstructions(selected.id,htmlText));

/*             if (files && files[0]) {
              let data = new FormData();
              data.append('image', files[0],files[0].name);
              dispatch(recipesActions.updateImage(selected.id,data));
            } */
            
        }else{
/*           toaster_info(<FormattedMessage id="prepareinstructions.instructions.update.warning.title"
                                          defaultMessage='Info'/>,
                          <FormattedMessage id="prepareinstructions.instructions.update.warning.message"
                                        defaultMessage='No update done in instructions'/>) */
          const title = <FormattedMessage id="prepareinstructions.instructions.update.warning.title"
                                        defaultMessage='Info'/>
          const content = <FormattedMessage id="prepareinstructions.instructions.update.warning.message"
                                        defaultMessage='No update done in instructions'/>
          dispatch(alertActions.error({title:'prepareinstructions.instructions.update.warning.title',
                                    content:'prepareinstructions.instructions.update.warning.message'}));
        }
    }

    triggerInputFile(){
      this.fileUpload.current.click();
    }

    cancelAction = (e) => {
        e.preventDefault();
        history.goBack();
    }

    render() { 
        const {hasChanged,htmlText,/*files,selected,*/file} = this.state;
        const {dispatch,alert,recipes,intl} = this.props;

/*         const serverConfig = {

          url: selected && serverConstants.BACKEND_URL+'/recipes/'+ selected.id,
          process: {
            url: '/image/',
            method: 'POST',
            headers: authHeaderImage(),
          },
          revert: {
            url: '/deleteimage/',
            method: 'DELETE',
            headers: authHeaderImage(),
          },
          restore: {
            url: '/deleteimage/',
            method: 'DELETE',
            headers: authHeaderImage(),
          },
          load: (uniqueFileId, load, error) => {
            console.log('Load uniqueFileId', uniqueFileId)
            fetch(uniqueFileId)
              .then(res => res.blob())
              .then(load)
              .catch(error)
          },
          remove: (uniqueFileId, load, error) => {
            console.log('Remove uniqueFileId', uniqueFileId)
            fetch(serverConstants.BACKEND_URL+'/recipes/'+ selected.id +'/deleteimage/', {
              method: 'DELETE',
              headers: authHeaderImage(),
            }).then(load)
              .catch(error)
          },
        }; */

        return (
            <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
            <Grid stackable style={styles.FontLato12}>
              <Grid.Row columns={1}>
                <Breadcrumb style={{background:'#fcfcfc'}}>
                          <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="prepareinstructions.link.dashboard"
                                                                defaultMessage='Dashboard'/></Breadcrumb.Section>
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="prepareinstructions.link.recipes"
                                                              defaultMessage='Recipes'/></Breadcrumb.Section>
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{recipes && recipes.selected && recipes.selected.name}</Breadcrumb.Section>   
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section active><FormattedMessage id="prepareinstructions.link.instructions"
                                                                defaultMessage='Instructions'/></Breadcrumb.Section>               
                </Breadcrumb>
             </Grid.Row>
              <Grid.Row>
                <Grid.Column width='10'>
                    <Header as='h2'>
                        <Icon name='book' size='mini' color='blue'/>
                        <Header.Content>
                            <FormattedMessage id="prepareinstructions.header.title"
                                              defaultMessage='Prepare instructions'/>
                            <Header.Subheader>{recipes && recipes.selected && recipes.selected.name}</Header.Subheader>
                          </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue' >
                    {recipes && recipes.selected && recipes.selected.name}
                    </Header> */}
                    <Image centered src={img_instructions} verticalAlign='middle' />
                    <br/>
                </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                    <Grid.Column /*width='14'*/  mobile={16} tablet={16} computer={14}>
                         <Form>
                             <FormattedMessage id="prepareinstructions.default.text"
                                              defaultMessage='Start your recipe instruction here ....'>
                                {placeholder =>   
                                        <ReactQuill ref={(el) => { this.reactQuillRef = el }}
                                                    value={htmlText}
                                                    onChange={this.handleChange}
                                                    placeholder={placeholder}
                                                    modules={this.modules}
                                                    formats={PrepareInstructions.formats}
                                                    theme="snow" 
                                                    style={{maxWidth:'60em'}}>
                                        </ReactQuill>
                                  }
                                </FormattedMessage>
                                <br/>
                                <Button style={styles.ButtonGreen} //basic color='teal' 
                                        onClick={this.onSubmit}>
                                  <FormattedMessage id="prepareinstructions.save.button"
                                                    defaultMessage='Save'/></Button>
                                <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.cancelAction}>
                                  <FormattedMessage id="prepareinstructions.cancel.button"
                                                    defaultMessage='Cancel'/></Button>
                         </Form>
                </Grid.Column>
               </Grid.Row>
            </Grid>
            {alert.message && toaster_dispatch(dispatch,alert,intl)}
            <Prompt
                key='block-nav'
                when={hasChanged==true}
                message='You have unsaved changes, are you sure you want to leave ?'
            />
            </SideLeftUncoverRecipe>
         )
    }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
function quill_img_handler(quillEditor,props,state) {
  const { dispatch} = props;
  //let fileInput = document.querySelector('input.ql-image[type=file]');
  //if (fileInput == null) {
      const fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
      //fileInput.classList.add('ql-image');
      fileInput.click();

      fileInput.onchange = async () => {  
        var file = fileInput.files[0];  
        var maxSize= 1048576; //1MB
        var fileSize = file.size;
        if (fileSize <= maxSize) {
          var formData = new FormData();  
          formData.append('img_prep', file, file.name.replace(/ /g, '-'));  
          const range = quillEditor.getEditor().getSelection();
          dispatch(recipesActions.updateInstructions(props.match.params.recipeId,quillEditor.getEditor().root.innerHTML,true));
          dispatch(recipesActions.updateImages(props.match.params.recipeId,formData,quillEditor, range));   
        }else{
          dispatch(alertActions.error({title: 'prepareinstructions.file.error.title',
                                       content:'prepareinstructions.file.size.error.content'}));
        }
      }; 
}

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
PrepareInstructions.formats = [
    'header'/*, 'font'*/, 'size','width',
    'bold', 'italic', 'underline',// 'strike', 'blockquote',
    'list', 'bullet', 'indent',
   /*  'link', */ 'image'/* , 'video' */
]
  
/* 
 * PropType validation
 */
PrepareInstructions.propTypes = {
    placeholder: PropTypes.string,
}
 

function mapStateToProps(state) {
    const { recipes,alert,dispatch } = state;
    return {
        recipes,
        dispatch,
        alert
    };
  }
  
  
const connectedPrepareInstructions = injectIntl(connect(mapStateToProps)(PrepareInstructions));
export { connectedPrepareInstructions as PrepareInstructions };
  

