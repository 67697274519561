import React, { Component } from 'react';
import { Table, Input,Dropdown, Grid, Menu,Pagination,Icon,Header,Loader,Dimmer,Popup,Modal,Button,Breadcrumb,Label,Checkbox } from 'semantic-ui-react';
import { recipesActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverProductions from './SidebarProductions';
import {toaster_dispatch,history} from '../_helpers';
import {FormattedMessage,FormattedDate,FormattedNumber,FormattedTime,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_planning from '../Assets/planning.png'
import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';

import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import Calendar from '@toast-ui/react-calendar';
import { TZDate } from '@toast-ui/calendar';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';


var HOUR_TIME=60*60*1000;

const optionsPage = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

const viewModeOptions = [
    { key: 'month',
      text: <FormattedMessage id="productions.calendar.view.month"
                              defaultMessage='Monthly'/>,
      value: 'month',
    },
    { key: 'week',
      text: <FormattedMessage id="productions.calendar.view.week"
                              defaultMessage='Weekly'/>,
      value: 'week',
    },
    { key: 'day',
      text: <FormattedMessage id="productions.calendar.view.day"
                              defaultMessage='Daily'/>,
      value: 'day',
    },
  ];


 function clone(date){
    return new TZDate(date);
  }
  
function addHours(d, step) {
    const date = clone(d);
    date.setHours(d.getHours() + step);
  
    return date;
  }
  
function addDate(d, step) {
    const date = clone(d);
    date.setDate(d.getDate() + step);
  
    return date;
}

/*function subtractDate(d, steps) {
    const date = clone(d);
    date.setDate(d.getDate() - steps);
  
    return date;
  } */
  
function generateRandomLightColor () {
    const red = Math.floor(Math.random() * 255)
    const green = Math.floor(Math.random() * 255)
    const blue = Math.floor(Math.random() * 255)
    const opacity = 0.5
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

  
class Productions extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location,intl} = props;
        this.state = {
                      prevOrders : props.orders,
                      currency : userIn.user.currency ,
                      toggled : false,
                      isMobile: false,
                      orderTypeSelected: 'ongoing',
                      filtering: false,
                      page: 1,
                      itemsPerPage: 10,
                      activeModalOrderDelete: false,
                      allowMultipleSelection:true,
                      selectionList:[],
                      actionModal: false,
                      selectedView: 'month',
                      calendarRef : React.createRef(),
                      calendarDate: new TZDate(),
                      planningLayout: location.state ? location.state.planning:false,
                      filterOldTasks: false,
                     }
        props.dispatch(recipesActions.getOrders(true,true));

        this.filterNameList=this.filterNameList.bind(this);
        this.setPlanningLayout = this.setPlanningLayout.bind(this);
        this.setListLayout = this.setListLayout.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        this.onChangeCalendarView = this.onChangeCalendarView.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.cancelAction=this.cancelAction.bind(this);

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    static getDerivedStateFromProps(props, state) {
        if((props.orders && props.orders!==state.prevOrders)) {

            let updatedList = props.orders.filter((item) => {
                if (item){
                    if (!(item.state)) item.state = 'ongoing'
                    return (item.state.toLowerCase() == 'ongoing')
                }
                return null;
            });

            let events = []
            let filteredEvents = []
            let date =  new Date()
            date.setHours(0,0,0,0)
            if (props.orders){
                for (let o of props.orders){
                        events.push({
                            id: o.id,
                            calendarId: '0',
                            title: o.ref_name,
                            category: 'time',
                            isReadOnly: true,
                            start: addDate(clone(o.prod_date), 0),
                            end: addHours(clone(o.prod_date), 1),
                            backgroundColor: generateRandomLightColor(),
                            //backgroundColor: '#60a0d1'
                        })
                }

                filteredEvents = events.filter((item) => {
                    let prodDate =  new Date(item.start)
                     return prodDate.getTime() >= date.getTime()
                });
            }
           
            return{newItems: updatedList,
                   filtering: true,
                   prevOrders: props.orders,
                   initialEvents:events,
                   filteredEvents:filteredEvents}
        }
        return null;
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: true});
        //this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});

         let updatedList = this.props.orders.filter((item) => {
            if (item){
                if (!item.state) item.state = 'ongoing'
                return (item.state.toLowerCase() == this.state.orderTypeSelected.toLowerCase())
            }
            return null;
        });
        this.setState({newItems: updatedList});
        this.setState({ selectedStartDate: null, selectedEndDate: null})
    }

    filterNameList(event){
        event.preventDefault();
        this.setState({ selectionList:[]});

        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }
        const {orders} = this.props;
        if (orders){
            let updatedList = this.props.orders.filter((item) => {
                return (item.ref_name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                        .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
      
               let updatedListOrderType = updatedList.filter((item) => {
                  if (item){
                      if (!item.state) item.state = 'ongoing'
                      return (item.state.toLowerCase() == this.state.orderTypeSelected.toLowerCase())
                  }
                  return null;
              });
      
              this.setState({newItems: updatedListOrderType});
              this.setState({filtering: true});
              this.setState({nameSelected: event.target.value});
              this.setState({ selectedStartDate: null, selectedEndDate: null})
        }
    }

    setDateRange (update){
        const {orders} = this.props;
        this.setState({ selectionList:[]});
        this.setState({ selectedStartDate: update[0], selectedEndDate: update[1]})

        //console.log(update)
        if (orders && update[0]!=null && update[1]!=null){
            let startDate = new Date(update[0])
            let endDate = new Date(update[1])
            endDate.setDate(endDate.getDate() + 1);
            let updatedList = this.props.orders.filter((item) => {
                    return new Date(item.prod_date) >= startDate && new Date(item.prod_date) <= endDate
                  });
                
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({nameSelected: ""});
            
        }else{
            this.setState({filtering: false});
            this.setState({nameSelected: ""});
        }

        return [update[0],update[1]]
    }

    seeOrder (event,index) {
        event.preventDefault();
        history.push('/productiondetails/'+index);
    }
    
    createOrder(event){
        history.push('/orders');
    }

    createRecipe(){
        history.push('/newrecipe');
    }

    createNewProduction(){
        history.push('/production/',{date:new Date()});
    }

    deleteOrderClickHandler (event,index,order) {
        event.preventDefault();
        this.setState({ activeModalOrderDelete: index});
        this.setState({ delete_order: order});
    };

    deleteOrder(event,id){
        event.preventDefault();
        this.props.dispatch(recipesActions.deleteOrder(id,true));  
    }

    hideModal() {
        this.setState({ activeModalOrderDelete: false });
        this.setState({ actionModal: false })
        this.setState({actionSelected: ""})
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(recipesActions.deleteSelectOrder(selectionList,'/orderslist'))
        this.setState({ selectionList:[]});
    }

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((order,index) => {
                allList.push(order.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && data.value === 'delete'){
            this.setState({actionModal: true});
        }
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }

    onChangeCalendarView(event,data){
        event.preventDefault();
        this.setState({selectedView: data.value});
        const calendarInstance = this.state.calendarRef.current.getInstance();
        this.setState({calendarDate:calendarInstance.getDate()});
    }

    handleClickNextButton = () => {
        const calendarInstance = this.state.calendarRef.current.getInstance();
        calendarInstance.next();
        this.setState({calendarDate:calendarInstance.getDate()});
    };

    handleClickPrevButton = () => {
        const calendarInstance = this.state.calendarRef.current.getInstance();
        calendarInstance.prev();
        this.setState({calendarDate:calendarInstance.getDate()});
    };

    handleClickTodayButton = () => {
        const calendarInstance = this.state.calendarRef.current.getInstance();
        calendarInstance.today();
        this.setState({calendarDate:calendarInstance.getDate()});
    };


    getCalendarDate = () => {
        const calendarInstance = this.state.calendarRef.current;
        return calendarInstance.getDateRangeStart().getTime()
    };

    addOrdersRows(orders){
        const {currency,allowMultipleSelection} = this.state;
        
        return orders.map((order,index) => {
                return (
                    <Table.Row  selectable key={index}>
                        <Table.Cell width={3} textAlign='left'>
                        {allowMultipleSelection && <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,order.id)}
                                   checked={this.isChecked(order.id)}
                                   style={{'transform': 'scale(1.0)'}}/>}
                            {order && order.created && 
                                            <span style={{marginLeft:'0.7em'}}>
                                                     <FormattedDate value={new Date(order.created)}
                                                                                   year='numeric'
                                                                                   month='long'/></span>}
                        </Table.Cell>                        
                        <Table.Cell width={5} 
                                    textAlign='left' 
                                    style={{cursor:'pointer'}}>
                                <a href={"/productiondetails/"+order.id} 
                                  onClick={(event) => this.seeOrder(event,order.id)}>
                                  {order.ref_name.length >= 45 && 
                                  order.ref_name.substring(0,45) + '..'}
                                {order.ref_name.length < 45 && 
                                    order.ref_name}
                                &nbsp; &nbsp;{((new Date() - new Date(order.created)) < HOUR_TIME) &&
                                <Label color='teal'><FormattedMessage id="productions.order.status.new"
                                                                      defaultMessage='New'/></Label>}</a>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='left' >
                        <span /* style={{marginLeft:'0.3em'}} */>
                        <a href={"/productiondetails/"+order.id} 
                                  onClick={(event) => this.seeOrder(event,order.id)}>
                                             <Icon color='grey'
                                                   name='clock outline'/>
                                                     <FormattedDate value={new Date(order.prod_date)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/>{' - '}
                                                     <FormattedTime value={new Date(order.prod_date)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/></a>
                        </span>
                        </Table.Cell>
                       {/*  <Table.Cell width={2} textAlign='center'>{order && (order.use_ingredients==true) && order.nb_ingredients}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                             {order && (order.use_ingredients==true) &&
                             <FormattedNumber value={order.value} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                  <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                        onClick={(event) => this.deleteOrderClickHandler(event,index,order)}/>
                                                 }
                                        content={<FormattedMessage id="productions.popup.delete.text"
                                                                   defaultMessage='Delete'/>}
                                        inverted
                                        size='small' 
                                        />   
                        </Table.Cell>
                        <Modal id={index}
                                   open={this.state.activeModalOrderDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="productions.modal.order.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="productions.modal.order.deletion.message"
                                                         defaultMessage='Really sure to delete the order {order} ?'
                                                         values={{order: order.ref_name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="productions.modal.order.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}
                                                labelPosition='right' 
                                                content={<FormattedMessage id="productions.modal.order.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteOrder(event,order.id)}/>
                         </Modal.Actions>
                        </Modal> 
                     </Table.Row>
                );
        });
    }

    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }

    onClickEvent(res) {
        //console.log('MouseEvent : ', res.nativeEvent);
        //console.log('Event Info : ', res.event);
        history.push('/productiondetails/'+res.event.id);
    }

    onClickDateEvent(res) {
        //console.log('MouseEvent : ', res.nativeEvent);
        console.log('Event Info : ', res);
        history.push('/production/',{date:res.start});
    }

    setListLayout(event){
        event.preventDefault();
        this.setState({planningLayout: false});
    }

    setPlanningLayout(event){
        event.preventDefault();
        this.setState({planningLayout: true});
    }

    onCheckboxChange = (e, data) => {
        this.setState({ filterOldTasks: data.checked});       
    }


    render() { 
        const { alert,dispatch, orders, intl,loading} = this.props;
        const { newItems,filtering,nameSelected,isMobile,page,itemsPerPage,
            allowMultipleSelection,isCheckedAll,selectionList,actionSelected,initialEvents,filteredEvents,planningLayout,selectedView,
            calendarDate,filterOldTasks,selectedStartDate,selectedEndDate } = this.state;

        let buttons = [];
		let className = "floating-menu";
		let icon = <Icon name='add'/>;
        let currentEvents = []
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;

            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="productions.floatingmenu.item2.title" defaultMessage= "Create a recipe"/>}  
                                                 icon={<Icon name='food'/>} 
                                                 action={this.createRecipe} 
                                                 mobile={isMobile}
                                                 key="r"/>);
            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="productions.floatingmenu.item3.title" defaultMessage= "Create an order"/>} 
                                                 icon={<Icon name='cart arrow down'/>} 
                                                 action={this.createOrder} 
                                                 mobile={isMobile}
                                                 key="s"/>); 
            buttons.push(<FloatingMenuItem label= {<FormattedMessage id="productions.floatingmenu.item1.title" defaultMessage= "New production"/>} 
                                           icon={<Icon name='calendar alternate outline'/>} 
                                           action={this.createNewProduction} 
                                           mobile={isMobile}
                                           key="o"/>); }
        buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 

        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering &&  orders){
            length = orders.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = orders.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = newItems.slice(begin,end);
        }

        if (filterOldTasks){
            currentEvents = filteredEvents;
        }else{
            currentEvents = initialEvents;
        }
        
        return ( 
                     <SideLeftUncoverProductions>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="productions.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active={!planningLayout} onClick={(e) => {this.setListLayout(e)}}>
                                            <FormattedMessage id="productions.link.production"
                                                              defaultMessage='Productions'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                 <Breadcrumb.Section active={planningLayout} onClick={(e) => {this.setPlanningLayout(e)}}>
                                              <FormattedMessage id="productions.link.planning"
                                                                defaultMessage='Planning'/></Breadcrumb.Section>                                     
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                            <Grid.Column  width={8}>
                                    <Header as='h2'>
                                        <img src={img_planning} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="productions.page.title"
                                                              defaultMessage='Production'/>
                                            <Header.Subheader><FormattedMessage id="productions.header.caption.title"
                                                                    defaultMessage='Manage your productions' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1}/> 
                            <Grid.Row columns={2}>
                                <Grid.Column width={4}> 
                                        <Button.Group basic fluid>
                                                <Button icon 
                                                        onClick={this.setListLayout} 
                                                        active={!planningLayout}> 
                                                    <span> 
                                                    <Icon name='list' color='blue'/>&nbsp; &nbsp; 
                                                       <FormattedMessage id="productions.tab.button.global.text"
                                                                          defaultMessage='List'/>  </span> 
                                                </Button>
                                                <Button icon 
                                                        onClick={this.setPlanningLayout} 
                                                        active={planningLayout}>
                                                     <span> 
                                                    <Icon name='calendar alternate outline' color='teal' />&nbsp;&nbsp; 
                                                            <FormattedMessage id="productions.tab.button.supplier.text"
                                                                              defaultMessage='Planning'/> </span>
                                                </Button>
                                        </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                            {!planningLayout &&  
                                <Grid.Row columns={3}>
                                    <Grid.Column  mobile={8} tablet={4} computer={4}> 
                                        <p style={styles.FontLato13}><FormattedMessage id="productions.filter.ref.title"
                                                            defaultMessage='Search by reference'/></p>
                                        <FormattedMessage id="productions.filter.byref.placeholder"
                                                            defaultMessage='Type a reference'>
                                            {placeholder =>                                                       
                                                <Input //size='large'
                                                    type="text"
                                                    icon fluid={!isMobile}
                                                    placeholder={placeholder}
                                                    value={nameSelected}
                                                    onChange={this.filterNameList}
                                                    style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}>
                                                <Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                        </FormattedMessage>
                                    </Grid.Column>
                                    <Grid.Column mobile={5} tablet={3} computer={1}/>   
                                    <Grid.Column mobile={5} tablet={3} computer={3}>   
                                     <div width={4} style={{paddingTop:'0.5em', fontSize:'18px'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="productions.form.production.title"
                                                                                            defaultMessage='Production date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="productions.form.dlc.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedStartDate}
                                                                                    endDate={selectedEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update);
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P"/>
                                                                        }
                                                            </FormattedMessage>
                                        </div>
                                </Grid.Column> 
                                <Grid.Column mobile={14} tablet={12} computer={6}>  
                                        <div className={className}>
                                            {buttons}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>}
                            {!planningLayout &&
                             <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                  {selectionList.length > 0 && 
                                     <FormattedMessage id="productions.action.main.placeholder"
                                                       defaultMessage='{number} selected'
                                                       values={{number:selectionList.length}}>
                                        {placeholder =>  
                                                <Dropdown selection floating labeled
                                                        options= {[{ key: 'none', text: ' ', value: 'none' },
                                                                  { key: 'delete', icon: {name:'trash alternate outline',color:'red'},
                                                                                  text: <FormattedMessage id="productions.action.delete.text"
                                                                                            defaultMessage='Delete'/>, value: 'delete' },]}
                                                        value= {actionSelected}
                                                        text={placeholder}
                                                        className='icon'
                                                        onChange={this.selectActionList}
                                                        style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                    </FormattedMessage>}
                                    {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="production.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}
                                    {(loading) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table unstackable 
                                           //compact
                                           singleline 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                  {allowMultipleSelection &&
                                                   <Input type='checkbox'
                                                          onChange={(e) => this.selectAllSelectList(e,items)}
                                                          style={{'transform': 'scale(1.0)'}}
                                                          checked={isCheckedAll}/>}
                                                    <span style={{marginLeft:'0.7em'}}>
                                                    <FormattedMessage id="productions.list.requested.table.column2"
                                                                     defaultMessage='Period' /></span>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5} textAlign='left'>{' '}
                                                   <FormattedMessage id="productions.list.requested.table.column1"
                                                                     defaultMessage='Reference' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <FormattedMessage id="productions.list.requested.table.column5"
                                                                     defaultMessage='Date of production' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="productions.list.requested.table.column4"
                                                                     defaultMessage='Total Cost' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='left'/>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="productions.list.requested.table.row.noitems"
                                                                            defaultMessage='No production planned'/></Table.Cell>
                                                <Table.Cell colspan='5'></Table.Cell>
                                            </Table.Row>}
                                        {items && items.length > 0 &&
                                            this.addOrdersRows(items)
                                        }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>         
                            </Grid.Row>}
                            {!planningLayout &&
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={optionsPage} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="productions.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="productions.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                    />
                                </Grid.Column>
                            </Grid.Row>}
                            <Grid.Row/>
                            {planningLayout &&
                             <Grid.Row columns={3} style={{'marginLeft':'1em'}}>
                                 <Grid.Column>
                                    <Dropdown selection
                                                value={selectedView}
                                                options={viewModeOptions}
                                                onChange={this.onChangeCalendarView}/>
                                    <FormattedMessage id="productions.calendar.checkbox.hidefinished.option"
                                                                defaultMessage='Filter ended'>
                                            {label => 
                                                    <Checkbox label={label}
                                                                    style={{margin:'0em 0em 0em 1.5em','font-size': '12px'}} 
                                                                    onChange={this.onCheckboxChange}
                                                                    checked={filterOldTasks}/>
                                            }
                                     </FormattedMessage>
                                 </Grid.Column>
                                 <Grid.Column textAlign='center'>
                                    <span style={{'font-size':'22px','font-weight': '700','color': '#60a0d1'}}>
                                    <FormattedDate value={calendarDate}
                                                    year='numeric'
                                                    month='long'/></span>
                                 </Grid.Column>
                                 <Grid.Column >
                                        <Button
                                            basic 
                                            circular
                                            color='white'
                                            size='medium'
                                            style={{'font-size':'14px','color': 'black'}}
                                            onClick={this.handleClickTodayButton}> 
                                             <span style={{'font-size':'14px','font-weight': '600','color': 'black'}}>
                                            <FormattedMessage id="productions.calendar.button.day"
                                                        defaultMessage='Today' /></span>
                                        </Button>
                                        <Button basic
                                            circular 
                                            icon 
                                            color='white'
                                            size='medium'
                                            style={{'font-size':'14px','font-weight': '600','color': 'black',marginRight:'0'}}
                                            onClick={this.handleClickPrevButton}>
                                                  <span style={{'font-size':'14px','font-weight': '600','color': 'black'}}>
                                            <Icon name='angle left'/></span>                                
                                        </Button>
                                        <Button basic
                                            circular 
                                            icon 
                                            color='white'
                                            size='medium'
                                            style={{'font-size':'14px','font-weight': '600','color': 'black',marginLeft:'0'}}
                                            onClick={this.handleClickNextButton}> 
                                                <span style={{'font-size':'14px','font-weight': '600','color': 'black'}}>
                                             <Icon name='angle right'/></span>    
                                        </Button>
                                 </Grid.Column>
                             </Grid.Row> }
                            {planningLayout && intl.locale === 'fr' &&
                            <Grid.Row>
                                <Grid.Column textAlign='left' >
                                    <Calendar 
                                        ref={this.state.calendarRef} 
                                        usageStatistics={false}
                                        height= "1100px"
                                        view = {selectedView}
                                        isReadOnly = {false}
                                        events={currentEvents}
                                        //theme={theme}
                                        useDetailPopup={true}
                                        useFormPopup={true}
                                        template = {{
                                            timegridDisplayPrimaryTime({time}) {
                                                return `${time.getHours()+':00'}`;
                                            },
                                        }}
                                        week={{
                                            eventView: ['time'],
                                            taskView: false,
                                            scheduleView : ['time'],
                                            startDayOfWeek: 1,
                                            gridSelection: false,
                                            //hourStart: 8,
                                            dayNames: [ 'Dim','Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                        }}
                                        month={{
                                            eventView: ['time'],
                                            taskView: false,
                                            startDayOfWeek: 1,
                                            //hourStart: 0,
                                            isAlways6Weeks: false,
                                            gridSelection: false,
                                            dayNames: ['Dim','Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                        }}
                                        gridSelection = {{
                                            enableDblClick: true,
                                            enableClick: true,
                                        }}
                                        onSelectDateTime={this.onClickDateEvent}
                                        onClickEvent={this.onClickEvent}
                                    />
                                </Grid.Column>
                                <Grid.Column/>
                            </Grid.Row>}
                            {planningLayout && intl.locale === 'en' &&
                            <Grid.Row>
                                <Grid.Column textAlign='left'>
                                    <Calendar 
                                        ref={this.state.calendarRef} 
                                        usageStatistics={false}
                                        height= "1100px"
                                        view = {selectedView}
                                        isReadOnly = {false}
                                        events={currentEvents}
                                        //theme={theme}
                                        useDetailPopup={true}
                                        useFormPopup={true}
                                        template = {{
                                            time(event) {
                                                return `<span style="color: black;">${new Intl.DateTimeFormat('default', 
                                                                                                  {  hour12: true,
                                                                                                     hour: 'numeric',
                                                                                                     minute: 'numeric'
                                                                                                  }).format(event.start)}
                                                ${event.title}</span>`;
                                              },
                                        }}
                                        week={{
                                            eventView: ['time'],
                                            taskView: false,
                                            scheduleView : ['time'],
                                            startDayOfWeek: 1,
                                            gridSelection: false,
                                            //hourStart: 8,
                                            daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                        }}
                                        month={{
                                            eventView: ['time'],
                                            taskView: false,
                                            startDayOfWeek: 1,
                                            //hourStart: 0,
                                            isAlways6Weeks: false,
                                            gridSelection: false,
                                            daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                        }}
                                        gridSelection = {{
                                            enableDblClick: true,
                                            enableClick: true,
                                        }}
                                        onSelectDateTime={this.onClickDateEvent}
                                        onClickEvent={this.onClickEvent}/>
                                </Grid.Column>
                                <Grid.Column/>
                            </Grid.Row>}
                            <Grid.Row/>
                            <Grid.Row/>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="productions.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="productions.modal.select.deletion"
                                                         defaultMessage='Really sure to delete definetely the selected order(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="productions.modal.select.deletion.button.no"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="productions.modal.select.deletion.button.yes"
                                                                    defaultMessage='Confirm'/> }
                                        icon='checkmark'
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                     </SideLeftUncoverProductions>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    const { orders } = state.recipes;
    const { loading } = state.recipes;
    return {
        loading,
        orders,
        alert,
        dispatch,
    };
}

const connectedProductions = injectIntl(connect(mapStateToProps)(Productions));
export { connectedProductions as Productions };
 
