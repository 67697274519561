import React, { Component } from 'react';
import { Table,Grid,Button,Header,Modal,Card,Input,Dimmer,Loader,Breadcrumb,Message,Image,Icon} from 'semantic-ui-react'
import {history} from '../_helpers';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverInventory from './SidebarInventory';
import { storageAreaActions,paymentActions } from '../_actions';
import {toaster_dispatch} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_categories from '../Assets/categories.png'
import img_performance from '../Assets/performance.png'

class StockAreas extends Component {
    constructor(props) {
        super(props);
        this.state = { newArea: '',
                       activeModalDelete: null,
                       activeModalEdit: null }
        this.hideModal = this.hideModal.bind(this);
        props.dispatch(storageAreaActions.getAll());
        props.dispatch(paymentActions.getCustomerMembership());
    }


    onNewAreaChange = (e) => {
        e.preventDefault();
        this.setState({ newArea: e.target.value });
    }

    onEditArea = (e) => {
        e.preventDefault();
        this.setState({ newArea: e.target.value });
    }

    addArea = (e) => {
        e.preventDefault();
        let newArea = this.state.newArea;

        if (newArea && newArea !=''){
            newArea = newArea.slice(0,50);
            this.props.dispatch(storageAreaActions.add(newArea));
        }
    }
    
    editArea (event,index) {
        event.preventDefault();
        let newArea = this.state.newArea;
        
        if (newArea !=''){
            newArea = newArea.slice(0,50);
            this.props.dispatch(storageAreaActions.update(index,newArea));
        }
    }

    deleteArea (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(storageAreaActions.delete(index))
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,name) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ newArea: name });
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ newArea: '' });
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }
    

    addAreasRows(storageAreas){
        return storageAreas.items.map((Area,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell >
                            <a href="#" 
                               onClick={(event)=>this.editClickHandler(event,index,Area.name)}>{Area.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{Area.nb_ingredients}</Table.Cell>
                        <Table.Cell textAlign='right'>
{/*                             <Button basic id={index} className="ui icon button circular" color='blue' 
                                onClick={(event)=>this.editClickHandler(event,index,Area.name)}>
                                <i className="edit icon"></i>
                                 </Button>
                            <Button basic id={index} className="ui icon button circular" color='grey' 
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash alternate outline icon"></i>
                            </Button> */}
                            <Icon name="edit outline" size='large' color='blue' style={{cursor:'pointer'}}
                                       onClick={(event)=>this.editClickHandler(event,index,Area.name)}/>&nbsp;
                            <Icon name="trash alternate outline" size='large' color='grey' style={{cursor:'pointer'}}
                                        onClick={(event) => this.deleteClickHandler(event,index)}/>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='edit' content={<FormattedMessage id="storagareas.modal.rename.title"
                                                                                defaultMessage='Rename Area'/>} />
                                <Modal.Content>
                                    <Input
                                        type="text"
                                        placeholder="Renaming"
                                        id="renameArea"
                                        value={this.state.newArea}
                                        onChange={this.onEditArea}
                                        style={{minWidth:'15em',maxWidth:'30em'}}/>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="storagareas.modal.rename.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="storagareas.modal.rename.button.yes"
                                                                    defaultMessage='Apply'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editArea(event,Area.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                             <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="storagareas.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="storagareas.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:Area.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="storagareas.modal.delete.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="storagareas.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteArea(event,Area.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
      }


    render() { 
        const { storageAreas,alert,dispatch,loading_storage,customer} = this.props;

        let user = null;
        let membership = null;
    
        if (customer){
          membership = customer.membership;
          user = customer.user;
          //console.log(user);
          //console.log(membership);
        } 

        return ( 
            <div>
           { loading_storage &&
                <SideLeftUncoverInventory>
                <Dimmer active={(loading_storage)} blurring inverted>
                        <Loader inverted content='Loading' />
                </Dimmer> 
                </SideLeftUncoverInventory>
            }
            { membership && user && 
               (((membership.membership_type=="Free")||(membership.membership_type=="Professional")) && (user.bypass==false)) &&
               <SideLeftUncoverInventory>
               <Grid stackable style={styles.FontLato13}>
                   <Grid.Row columns={1}>
                       <Breadcrumb  style={{background:'#fcfcfc'}}>
                               <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="storagareas.link.dashboard"
                                                                           defaultMessage='Dashboard'/></Breadcrumb.Section>
                               <Breadcrumb.Divider icon='right chevron'/>
                               <Breadcrumb.Section link as={Link} to={{pathname:'/inventory',state:{startPage:1}}}><FormattedMessage id="storagareas.link.inventory"
                                                                      defaultMessage='Inventory'/></Breadcrumb.Section>
                         <Breadcrumb.Divider icon='right chevron'/>
                         <Breadcrumb.Section active><FormattedMessage id="storagareas.link.storageareas"
                                                                     defaultMessage='Storage areas'/></Breadcrumb.Section>
                       </Breadcrumb>
                       </Grid.Row>
                       <Grid.Row>
                       <Grid.Column width={6}>
                                    <Header as='h2'>
                                        <img src={img_categories} style={{width:'40px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="storagareas.page.title"
                                                              defaultMessage='Storage areas'/>
                                            <Header.Subheader><FormattedMessage id="storagareas.page.subtitle"
                                                              defaultMessage='Add your storage rooms'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column /*width={8}*/  mobile={14} tablet={12} computer={8}>
                            <br/>
                            <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                <Image src={img_performance} size ='small' rounded/>
                                <br/>
                                <Message.Content style={{marginLeft:'1em'}}>
                                    <Message.Header color='black'>
                                        <FormattedMessage id="storagareas.premium.message.upgradeplan"
                                                            defaultMessage='ENTERPRISE Feature'/>
                                    </Message.Header>
                                    <br/>
                                    <FormattedMessage id="storagareas.premium.title.message.upgradeplan"
                                                    defaultMessage='Your current plan: '/>{' '}{membership.name}   
                                    <br/><br/>
                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                        <FormattedMessage id="storagareas.premium.button.chooseplan"
                                                    defaultMessage='Choose premium plan'/>
                                    </Button>                                      
                                </Message.Content>

                            </Message>
                        </Grid.Column>
                    </Grid.Row>
               </Grid>
               </SideLeftUncoverInventory>
               }
             { membership && user &&
                        ((membership.membership_type=="Entreprise") || (user.bypass==true)) &&  
                <SideLeftUncoverInventory>
                <Grid stackable style={styles.FontLato13}>
                    <Grid.Row columns={1}>
                        <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="storagareas.link.dashboard"
                                                                            defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={{pathname:'/inventory',state:{startPage:1}}}><FormattedMessage id="storagareas.link.inventory"
                                                                       defaultMessage='Inventory'/></Breadcrumb.Section>
                          <Breadcrumb.Divider icon='right chevron'/>
                          <Breadcrumb.Section active><FormattedMessage id="storagareas.link.storageareas"
                                                                      defaultMessage='Storage areas'/></Breadcrumb.Section>
                        </Breadcrumb>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h2'>
                                        <img src={img_categories} style={{width:'40px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="storagareas.page.title"
                                                              defaultMessage='Storage areas'/>
                                            <Header.Subheader><FormattedMessage id="storagareas.page.subtitle"
                                                              defaultMessage='Add your storage rooms'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={2}>
                        <Grid.Column width={5}> 
                            <p style={styles.FontLato13}>
                                <FormattedMessage id="storagareas.newrecipe.title"
                                                  defaultMessage='Create a new storage area' /> 
                            </p>
                            <Card color={'blue'} style={styles.FontLato13} fluid>
                                <Card.Content >
                                    <Header as='h5'>
                                        <FormattedMessage id="storagareas.card.newrecipe.title"
                                                          defaultMessage='Name' /> 
                                    </Header>
                                    <FormattedMessage id="storagareas.card.newrecipe.placeholder"
                                                        defaultMessage='Area name'>
                                        {placeholder =>  
                                                <Input fluid
                                                    type="text"
                                                    placeholder={placeholder}
                                                    id="newArea"
                                                    value={this.state.newArea}
                                                    onChange={this.onNewAreaChange}
                                                    //style={{minWidth:'15em',maxWidth:'30em'}}
                                                    />}
                                    </FormattedMessage>
                                    <br/><br/>
                                    <Button color='blue' floated='right'
                                        onClick={(event) =>this.addArea(event)}>
                                        <FormattedMessage id="storagareas.card.button.create"
                                                          defaultMessage='Create' /> 
                                    </Button>
                                </Card.Content> 
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={7}> 
                            <p style={styles.FontLato14Border}>
                                {storageAreas && storageAreas.items && 
                                  <span style={{'font-weight': 'bold;'}}>{storageAreas.items.length}{' '}</span>
                               }
                                <FormattedMessage id="storagareas.currentrecipe.title"
                                                  defaultMessage='areas available' /> 
                            </p>
                            <Dimmer.Dimmable>
                                        <Dimmer active={((loading_storage))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                            <Table selectable responsive color={'blue'} fluid unstackable singleLine>
                                <Table.Header style={styles.FontLato13}>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                             <FormattedMessage id="storagareas.currentrecipe.table.row.name"
                                                              defaultMessage='Name' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>
                                            <FormattedMessage id="storagareas.currentrecipe.table.row.nbareas"
                                                              defaultMessage='Nb Elements' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {storageAreas && storageAreas.items &&
                                      this.addAreasRows(storageAreas)}
                                </Table.Body>
                            </Table>
                          </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </SideLeftUncoverInventory>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { storageAreas,alert,dispatch } = state;
    const { loading_storage } = state.storageAreas;
    const { customer } = state.payment;
    return {
        storageAreas,
        loading_storage,
        customer,
        alert,
        dispatch
    };
}

const connectedStockAreas = injectIntl(connect(mapStateToProps)(StockAreas));
export { connectedStockAreas as StockAreas };
 