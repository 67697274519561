import React, { Component } from 'react';
import { ingredientsActions, categoryIngredientActions,supplierActions,nutritionActions,alertActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Header,Label,Grid,Table,Message,Modal,Input,Icon,Image,Segment,Breadcrumb,Select}  from 'semantic-ui-react'
import SideLeftUncoverIngredients from './SidebarIngredients';
import {history,toaster_dispatch,isNumeric,isFloat,trim,unitWeight,unitVolume,tablespoons,teaspoons,Capitalize, isInteger,dateWithoutTimezone} from '../_helpers';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
          WEIGHT_INSTRUCTION_LINE_KG,
          WEIGHT_INSTRUCTION_LINE_G,
          WEIGHT_INSTRUCTION_LINE_MG,
          WEIGHT_INSTRUCTION_LINE_OZ,
          WEIGHT_INSTRUCTION_LINE_LB,
          WEIGHT_INSTRUCTION_LINE_EX,
          VOLUME_INSTRUCTION_LINE_TITLE,
          VOLUME_INSTRUCTION_LINE_L,
          VOLUME_INSTRUCTION_LINE_ML,
          VOLUME_INSTRUCTION_LINE_CL,
          VOLUME_INSTRUCTION_LINE_FL,
          VOLUME_INSTRUCTION_LINE_GAL,
          VOLUME_INSTRUCTION_LINE_EX,
          QUANTITY_INSTRUCTION_LINE_TITLE,
          QUANTITY_INSTRUCTION_LINE_NUMBER,
          QUANTITY_INSTRUCTION_LINE_EX,
          allergens,
          VAToptions
        } from '../_helpers';

import "./NutritionFacts.scss";
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
//import img_vegetables from '../Assets/red-paprika-organic-vegetable-53162.jpeg'
import img_performance from '../Assets/performance.png'

const  WEIGHT = <FormattedMessage id="newingredient.quantity.select.weight"
                                    defaultMessage="Weight"/>;
const  VOLUME = <FormattedMessage id="newingredient.quantity.select.volume"
                                    defaultMessage="Volume"/>;
const  QUANTITY = <FormattedMessage id="newingredient.quantity.select.quantity"
                                    defaultMessage="Quantity"/>;



class NewIngredient extends Component {
  constructor(props) {
    super(props);
    const {intl} = props;
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state = {
      prevNutrition : props.nutrition,
      prevCategoriesIngredient : props.categoriesIngredient,
      name: "",
      ref_internal:"",
      price: "",
      note: " ",
      purchase_weight: "",
      purchase_volume: "",
      purchase_quantity: "",
      type_quantity: "",
      quantity: "",
      pack_units: 1,
      categorySelected : null,
      allergensSelected : null,
      supplierSelected : null,
      ref_supplier:"",
      //newCategory: '',
      //newSupplier: '',
      highlighted: false,
      activeModalNutrition: false,
      activeModalPremium: false,
      activeModalAddCategory: false,
      activeModalAddSupplier: false,
      inputText: '',
      nutrient_ready: false,
      nutrient_data : {},
      currency : userIn.user.currency,
      needNote: false,
      needDLC: false,
      tax : 0.00,
      batch_number: "",
      selectedDate : ""
    };
    this.hideModal = this.hideModal.bind(this);
    this.cancelNutrition = this.cancelNutrition.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    props.dispatch(categoryIngredientActions.getAll());
    props.dispatch(supplierActions.getAll());
    //props.dispatch(ingredientsActions.getAll());
    props.dispatch(paymentActions.getCustomerMembership());

    registerLocale('en', en)
    registerLocale('fr', fr)
    if(intl.locale == 'fr'){
        setDefaultLocale('fr',fr)
    }else{
        setDefaultLocale('en',en)
    }
  }

  static getDerivedStateFromProps(props, state) {
  
    let result= {};
    let nutrient_data = {};

    if((props.nutrition!==state.prevNutrition) && (props.nutrition.nutrients)) {
      const nutrients = props.nutrition.nutrients;
      Object.assign(result,{prevNutrition : props.nutrition});
      //Condition to be reworked

      if (nutrients.foodNutrients){
      //OLD API if needed
      //if (nutrients.foods){
        //USDA data 
        let calories = {value:0,unit:""};
        let protein = {value:0,unit:""};
        let fiber = {value:0,unit:""};
        let fatTotal = {value:0,unit:""};
        let sugar = {value:0,unit:""};
        let calcium = {value:0,unit:""};
        let potassium = {value:0,unit:""};
        let iron = {value:0,unit:""};
        let sodium = {value:0,unit:""};
        let vitaminC = {value:0,unit:""};
        let vitaminA = {value:0,unit:""};
        let saturedFat = {value:0,unit:""};
        let transFat = {value:0,unit:""};
        let cholesterol = {value:0,unit:""};
        let totalCarbohydrates = {value:0,unit:""};
        let usda_name = "";
        let usda_ndbno = "";

         //OLD API if needed
         //let elements = nutrients.foods[0].food.nutrients;
         //let description = nutrients.foods[0].food.desc;

        let elements = nutrients.foodNutrients;
        let description = nutrients.description;
        
          //OLD API if needed
 /*          elements.map((item,index) => {
            if (item.name == 'Energy') calories = {value:item.value,unit:item.unit};
            if (item.name == 'Protein') protein = {value:item.value,unit:item.unit};
            if (item.name == 'Fiber, total dietary') fiber = {value:item.value,unit:item.unit};
            if (item.name == 'Total lipid (fat)') fatTotal = {value:item.value,unit:item.unit};
            if (item.name == 'Sugars, total') sugar = {value:item.value,unit:item.unit}; 
            if (item.name == 'Calcium, Ca') calcium = {value:item.value,unit:item.unit};
            if (item.name == 'Iron, Fe') iron = {value:item.value,unit:item.unit};
            if (item.name == 'Sodium, Na') sodium = {value:item.value,unit:item.unit};
            if (item.name == 'Vitamin C, total ascorbic acid') vitaminC = {value:item.value,unit:item.unit};
            if (item.name == 'Vitamin A, RAE') vitaminA = {value:item.value,unit:item.unit};
            if (item.name == 'Fatty acids, total saturated') saturedFat = {value:item.value,unit:item.unit};
            if (item.name == 'Fatty acids, total trans') transFat = {value:item.value,unit:item.unit};
            if (item.name == 'Cholesterol') cholesterol = {value:item.value,unit:item.unit};
            if (item.name == 'Carbohydrate, by difference') totalCarbohydrates = {value:item.value,unit:item.unit};
          });*/

          elements.map((item,index) => {
            let nutrient = item.nutrient
            if ((nutrient.name == 'Energy') && (nutrient.unitName == 'kcal')) calories = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Protein') protein = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fiber, total dietary') fiber = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Total lipid (fat)') fatTotal = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Sugars, total including NLEA') sugar = {value:item.amount,unit:nutrient.unitName}; 
            if (nutrient.name == 'Calcium, Ca') calcium = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Iron, Fe') iron = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Sodium, Na') sodium = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Vitamin C, total ascorbic acid') vitaminC = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Vitamin A, RAE') vitaminA = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fatty acids, total saturated') saturedFat = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fatty acids, total trans') transFat = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Cholesterol') cholesterol = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Carbohydrate, by difference') totalCarbohydrates = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Potassium, K') potassium = {value:item.amount,unit:nutrient.unitName};
          });

        if (description){
            //OLD API if needed
            //usda_name = description.name;
            //usda_ndbno = description.ndbno;
            usda_name = description;
            usda_ndbno = nutrients.fdcId
        }


        nutrient_data = {
                      usda_name: usda_name,
                      usda_ndbno: usda_ndbno,
                      weight: 'NA',
                      calories : calories.value + calories.unit,
                      protein : protein.value + protein.unit,
                      fiber : fiber.value + fiber.unit,
                      fatTotal : fatTotal.value + fatTotal.unit,
                      sugar : sugar.value + sugar.unit,
                      calcium : calcium.value + calcium.unit,
                      potassium : potassium.value + potassium.unit,
                      iron : iron.value + iron.unit,
                      sodium : sodium.value + sodium.unit,
                      vitaminC :vitaminC.value + vitaminC.unit,
                      vitaminA :vitaminA.value + vitaminA.unit,
                      saturedFat : saturedFat.value + saturedFat.unit,
                      transFat : transFat.value + transFat.unit,
                      cholesterol : cholesterol.value + cholesterol.unit,
                      totalCarbohydrates: totalCarbohydrates.value + totalCarbohydrates.unit}

      }else{
          //Ciqual data 
          nutrient_data = {
            usda_name: nutrients.alim_nom_fr,
            usda_ndbno: nutrients.alim_code,
            weight: 'NA',
            calories : nutrients.energie_kcal.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'kcal',
            protein : nutrients.proteines.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            fiber : nutrients.fibres.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            fatTotal : nutrients.lipides.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            sugar : nutrients.sucres.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            calcium : nutrients.calcium.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'mg',
            potassium : nutrients.potassium.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'mg',
            iron : nutrients.fer.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'mg',
            sodium : nutrients.sel.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0')+ 'g',
            vitaminC :nutrients.vitaminec.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'mg',
            vitaminA :  'NA',
            saturedFat : nutrients.ag_satures.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            transFat : 'NA',
            cholesterol : nutrients.cholesterol.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g',
            totalCarbohydrates: nutrients.glucides.replace(/,/g, '.').replace(/</g,'').replace(/-/g,'0') + 'g'}
      }
      
      if ((props.categoriesIngredient!==state.prevCategoriesIngredient) && (props.categoriesIngredient.items)) {
          
          Object.assign(result,{
            nutrient_data:nutrient_data,
            //categorySelected: {name:props.categoriesIngredient.items[0].name}
            }
          );
         //Object.assign(result,{prevCategoriesIngredient: props.categoriesIngredient});
      }
      return result;
    }

    /*if ((props.categoriesIngredient!==state.prevCategoriesIngredient) && (props.categoriesIngredient.items)) {
      let result= {};
      Object.assign(result, {categorySelected: {name:props.categoriesIngredient.items[0].name}});
      //Object.assign(result,{prevCategoriesIngredient: props.categoriesIngredient});
      return result;
    }*/

    return null;
  }


  onNameChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  }

  onPriceChange = (e) => {
    e.preventDefault();
    this.setState({ price: e.target.value });
  }

  onNoteChange = (e) => {
    e.preventDefault();
    this.setState({ note: e.target.value });
  }

/*   onAddCategory = (e) => {
    e.preventDefault();
    this.setState({ newCategory: e.target.value });
  } */

/*   addCategory = (e) => {
    e.preventDefault();
    let newCategory = this.state.newCategory;

    if (newCategory && newCategory !=''){
        this.props.dispatch(categoryIngredientActions.add(newCategory));
        this.setState({ activeModalAddCategory: false });
    }
  } */

  resetPurchaseQuantities () {
    this.setState({ purchase_weight: "" });
    this.setState({ purchase_volume: ""});
    this.setState({ purchase_quantity: ""});
  }

  onCategoryChange = (e,data) => {
    e.preventDefault();
    const {categoriesIngredient} = this.props;
    if (data.value==""){
      this.setState({ categorySelected: null });
    }else{
      let category_id = data.value;
      let elem = categoriesIngredient.options.find(category => category.value == category_id)
      this.setState({ categorySelected: {id:category_id,
                                         name:elem.text} });
    }
  }

  onAllergensChange = (e,data) => {
    e.preventDefault(); 
    this.setState({ allergensSelected: data.value });
  }

  createAllergensList = (allergens) => {
    let listAllergens = [];  
    if (allergens){
      for (let elem of allergens){
        listAllergens.push({id:0,name:elem})
      }     
    }
    return listAllergens;  
  }


  onSupplierChange = (e,data) => {
    e.preventDefault();
    const {supplier} = this.props;
    if (data.value==""){
      this.setState({ supplierSelected: null });
    }else{
      let supplier_id = data.value;
      let elem = supplier.options.find(supplier => supplier.value == supplier_id)
      this.setState({ supplierSelected: {id:supplier_id,
                                         name: elem.text } });
    }
  }

  onTypeQuantityChange = (e,data) => {
    e.preventDefault();
    const {quantity} = this.state;
    this.setState({ type_quantity: data.value });
    this.resetPurchaseQuantities();

    let type =  data.value
    if ( type === 'volume')
      this.setState({ purchase_volume: quantity });
    else if ( type === 'weight')
      this.setState({ purchase_weight:quantity});
    else if  ( type === 'quantity')
      this.setState({ purchase_quantity:quantity });
  }
  
  onUnitsPackagingChange = (e,data) => {
    e.preventDefault();
    this.setState({ pack_units: data.value });
  }

  onQuantityChange = (e) => {
    const {type_quantity} = this.state;
    e.preventDefault();
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    if (type_quantity === 'volume')
      this.setState({ purchase_volume: e.target.value.toLowerCase() });
    else if (type_quantity === 'weight')
      this.setState({ purchase_weight: e.target.value.toLowerCase() });
    else if  (type_quantity === 'quantity')
      this.setState({ purchase_quantity: e.target.value });
  }

  onRefInternalChange = (e) => {
    e.preventDefault();
    this.setState({ ref_internal: e.target.value });
  }

  onRefSupplierChange = (e) => {
    e.preventDefault();
    this.setState({ ref_supplier: e.target.value });
  }

  onFocusElsewhere(e) {
    e.preventDefault();
    this.setState({ highlighted: null });
  }

  onFocusQuantityField = (e,quantity) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  onFocusPackField = (e) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  assertNameField(){
    if ((this.state.name !== "")
          &&(trim(this.state.name).length>0)
          &&(trim(this.state.name).length<100))
      return true;
    return false;
  }

  assertSelectedWeight(){
    if (this.state.type_quantity != "")
      return true;
    return false;
  }

  assertQuantityField(){
    if (this.state.quantity != "")
      return true;
    return false;
  }

  assertPackField(){
    let units = Number(this.state.pack_units)
    if (isInteger(units)
        && (units > 0)
        && Math.trunc(units).toString().length<7)
      return true;
    return false;
  }

  assertPriceField(){
    let price = trim(this.state.price).replace(/,/g, '.')
    if ((isFloat(price) || isNumeric(price))
      && (this.state.price != "")
      && Math.trunc(this.state.price).toString().length<7)
      return true;
    return false;
  }

  assertQuantityPermittedIncluded(quantity){
    let isFound = false;
    let unitPart = "";
    // Filter some units not available for ingredients
    var volumes = unitVolume.filter((item) => !teaspoons.includes(item));
    volumes = volumes.filter((item) => !tablespoons.includes(item));

    const {type_quantity} = this.state;
    let inputs= quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);

    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (type_quantity == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
        if (inputs <=0){
              return false;
        }
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          if (isNumeric(i) && i<=0 )
                return false;
          //console.log(unitPart);
        }
      }
    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
        if (isNumeric(i) && i<=0 )
              return false;
        }
    }

    if (type_quantity == 'volume'){
      for (let unit of volumes ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (type_quantity == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          break;
        }
     }
    }else {
      if (inputs.length > 1)
        return false
        isFound = true;
    }
    return isFound;
  }

  assertQuantityFieldsValidity(){
    const {quantity} = this.state;
    let clean_quantity = trim(quantity).toLowerCase();
    //this.setState({ quantity: clean_quantity});
    if (!this.assertQuantityPermittedIncluded(clean_quantity)) return false;
    return true;
  }


  onSubmit = (e,newroute) => {
    e.preventDefault();
    const {name,price,note,purchase_weight,purchase_volume,purchase_quantity,categorySelected,supplierSelected,allergensSelected,nutrient_data,nutrient_ready,ref_internal,ref_supplier ,tax,pack_units,batch_number,selectedDate} = this.state;
    const { dispatch } = this.props;
    let ingredient = {}

    dispatch(alertActions.clear());
    this.setState({ submitted: true });


    if (!this.assertNameField()){
      //dispatch(alertActions.error('name field should be filled'));
      //toaster_error('Submit Error','name field should be filled');
      return;
    }

    if (!this.assertPriceField()){
      //dispatch(alertActions.error('price field should be numeric (<1000)'));
      //toaster_error('Submit Error','price field should be numeric (<1000)');
      return;
    }

    if (!this.assertSelectedWeight()){
      //dispatch(alertActions.error('weight type should be selected'));
      //toaster_error('Submit Error','weight type should be selected');
      return;
    }


    if (!this.assertQuantityField()){
      //dispatch(alertActions.error('Quantity field should be filled'));
      //toaster_error('Submit Error','Quantity field should be filled');
      return;
    }

    if (!this.assertQuantityFieldsValidity()){
      //dispatch(alertActions.error('Quantity field format invalid (follow instructions)'));
      //toaster_error('Submit Error','Quantity field format invalid (follow instructions)');
      return;
    }

    if (!this.assertPackField()){
      return;
    }


    if (name && price/* && categorySelected && supplierSelected*/) {
        ingredient = {
                name:trim(name.replace(/\n|\r|\t/g, " ")),
                price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                note:note,
                purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
                purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
                purchase_quantity:trim(purchase_quantity).replace(/,/g, '.'),
                category:categorySelected,
                supplier:supplierSelected,
                allergens: this.createAllergensList(allergensSelected),
                ref_internal:ref_internal,
                ref_supplier:ref_supplier,
                tax: tax,
                pack_units:pack_units,
                date_expire:(selectedDate && selectedDate != "" )  ? dateWithoutTimezone(selectedDate):null,
                batch_number:batch_number
        }
        if (nutrient_ready && nutrient_data){
            Object.assign(nutrient_data,{ name:trim(name) });
            Object.assign(ingredient,{ metrics:nutrient_data });
        }
        
        dispatch(ingredientsActions.add(ingredient,newroute));
        
    }
  }


  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }


  cancelNutrition() {
    this.setState({ nutrient_ready: false});
  }

  hideModal() {
    this.setState({ activeModalNutrition: false });
    this.setState({ activeModalPremium: false });
    //this.setState({ activeModalAddCategory: false });
    //this.setState({ activeModalAddSupplier: false });
  }


  handleInputChange(e, {value}) {
    this.setState({ inputText: value });
  }

  handleSearchNutrition(e) {
    const {intl,customer} = this.props;
    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
      //console.log(user);
      //console.log(membership);
    }

    if (membership && user &&
          ((membership.membership_type=="Entreprise") || (user.bypass==true)))
    {
        this.props.dispatch(nutritionActions.search(this.state.inputText,intl.locale));
        this.setState({ activeModalNutrition: true});
    }
    else{
       this.setState({ activeModalPremium: true});
    }  

  }

  addItem (event,index) {
    event.preventDefault();
    //this.props.dispatch(recipesActions.get(index,'/recipedetails/'));
    //history.push('/recipedetails/'+index);
    this.props.dispatch(nutritionActions.getNutrition(index,this.props.intl.locale));
    this.setState({ activeModalNutrition: false});
    this.setState({ nutrient_ready: true});
  }

  addNutritionItemsList(){
    const {items} = this.props.nutrition;
    const {intl} = this.props;
    let choices;
    if (intl && intl.locale == 'fr'){
      choices = items;
    }else {
      //OLD API if needed
      //choices = items.list.item;
      choices = items.foods;
    }

    return choices.map((item,index) => {
        //Specific USDA Branded ingredients (not SR Legacy)
        let description =""
        if (intl && intl.locale == 'en'){
           description = item.description
           if (item.dataType == 'Branded'){
             description = item.description.toLowerCase() + ' (' + item.brandOwner + ')'
           }
        }

        return (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Icon name='food' color='teal'/>
                {intl && intl.locale == 'fr' &&
                  <a href="#" 
                    onClick={(event) => this.addItem(event,item.alim_code)}>{item.alim_nom_fr}
                  </a>
                }
                {intl && intl.locale == 'en' &&
                  <a href="#" 
                    //OLD API if needed
                    //onClick={(event) => this.addItem(event,item.ndbno)}>{Capitalize(item.name)}
                    onClick={(event) => this.addItem(event,item.fdcId)}>{Capitalize(description)}
                  </a>
                }
            </Grid.Column>
          </Grid.Row>
        )
      });
  }

  extractPayload(field, unit, average){
    let value = field.split(unit)[0] 
    if (value>0){
      return Number((value / average) * 100).toFixed(0)
    }
    return 0;
  }

  addFoodFactsFr(data){
    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

    return(
      <Table compact striped unstackable singleline color='grey'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>Pour 100g</Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>%VNR *</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell width={4}><b>Energie</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{data.calories} ({energyKJ}kJ)</Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayload(data.calories,"kcal",2000)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>Matières Grasses</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.fatTotal}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.fatTotal,"g",70)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>dont acides gras saturés</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.saturedFat}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.saturedFat,"g",20)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>Glucides</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.totalCarbohydrates}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.totalCarbohydrates,"g",260)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>dont sucres</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.sugar}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.sugar,"g",90)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>Fibres</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.fiber}</Table.Cell>
          <Table.Cell textAlign='right'>-</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>Protéines</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.protein}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.protein,"g",90)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><b>Sel</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.sodium}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.sodium,"g",6)}%</Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Footer>
          <Table.Row fullwidth width={8}>
             <Table.Cell textAlign='left' colSpan='3'>
              <p>* Pourcentage de la valeur quotidienne selon un régime alimentaire de 2000 calories. 
              Vos valeurs quotidiennes personnelles peuvent être plus ou moins élevées selon vos besoins énérgetiques</p>
            </Table.Cell>
          </Table.Row>
      </Table.Footer>
    </Table>
    )
  }

  addFoodFacts(data){
      const {intl} = this.props;

      var energyKJ = data.calories.split("kcal")
      energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

      if (intl.locale == 'fr'){
        return (
          
          <section class="performance-facts" style={{'font-family': 'verdana', 'font-size': '11px','color': 'grey'}}>
            <header class="performance-facts__header">
              <h1 class="performance-facts__title">Valeurs nutritives</h1>
            </header>
            <table class="performance-facts__table">
              <thead>
                <tr>
                  <th colspan="3" class="small-info">Valeurs pour 100g</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2">
                    <b>Energie</b> {data.calories} ({energyKJ}kJ)
                  </th>
                </tr>
                <tr class="thick-row">
                  <td colspan="3" class="small-info">
                    <b>% Valeur quotidienne*</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Matières Grasses</b>  {data.fatTotal}
                  </th>
                  <td >
                     <b>{this.extractPayload(data.fatTotal,"g",70)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    dont saturé {data.saturedFat}
                  </th>
                  <td class="blank-cell">
                      <b>{this.extractPayload(data.saturedFat,"g",20)}%</b>
                  </td>
                </tr>
                <tr > 
                  <th colspan="2">
                    <b>Glucides</b> {data.totalCarbohydrates}
                  </th>
                  <td> 
                      <b>{this.extractPayload(data.totalCarbohydrates,"g",260)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    sucres {data.sugar}
                  </th>
                  <td class="blank-cell">
                    <b>{this.extractPayload(data.sugar,"g",90)}%</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Fibres</b> {data.fiber}
                  </th>
                  <td >
                    <b>-</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Protéines</b> {data.protein}
                  </th>
                  <td >
                    <b>{this.extractPayload(data.protein,"g",90)}%</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Sel</b> {data.sodium}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.sodium,"g",6)}%</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="performance-facts__table--grid">
              <tbody>
                <tr>
                  <td colspan="2">
                    Vitamine A {data.vitaminA}
                  </td>
                  <td>
                    Vitamine C {data.vitaminC}
                  </td>
                </tr>
                <tr class="thin-end">
                  <td colspan="2">
                    Calcium {data.calcium}
                  </td>
                  <td>
                    Fer {data.iron}
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="small-info">* Pourcentage de la valeur quotidienne selon un régime alimentaire de 2000 calories. Vos valeurs quotidiennes personnelles peuvent être plus ou moins élevées selon vos besoins énérgetiques</p>
          </section>
        )
      }
      else{
        return (
          <section class="performance-facts" style={{'font-family': 'verdana', 'font-size': '11px','color': 'grey'}}>
            <header class="performance-facts__header">
              <h1 class="performance-facts__title">Nutrition Facts</h1>
{/*                 <p>Serving Size 1/2 cup (about 82g)</p>
              <p>Serving Per Container 8</p> */}
            </header>
            <table class="performance-facts__table">
              <thead>
                <tr>
                  <th colspan="3" class="small-info">Amount Per 100g</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2">
                    <b>Calories</b> {data.calories}
                  </th>
                  <td>
                    {/* Calories from Fat 0 */}
                  </td>
                </tr>
                <tr class="thick-row">
                  <td colspan="3" class="small-info">
                    <b>% Daily Value*</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Total Fat</b> {data.fatTotal}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.fatTotal,"g",65)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    Saturated Fat {data.saturedFat}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.saturedFat,"g",20)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell">
                  </td>
                  <th>
                    Trans Fat {data.transFat}
                  </th>
                  <td>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Cholesterol</b> {data.cholesterol}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.cholesterol,"g",300)}%</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Sodium</b> {data.sodium}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.sodium,"g",2.4)}%</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Total Carbohydrate</b> {data.totalCarbohydrates}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.totalCarbohydrates,"g",300)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell">
                  </td>
                  <th>
                    Dietary Fiber {data.fiber}
                  </th>
                  <td>
                    <b>{this.extractPayload(data.fiber,"g",25)}%</b>
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell">
                  </td>
                  <th>
                    Sugars {data.sugar}
                  </th>
                  <td>
                  </td>
                </tr>
                <tr class="thick-end">
                  <th colspan="2">
                    <b>Protein</b> {data.protein}
                  </th>
                  <td>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="performance-facts__table--grid">
              <tbody>
                <tr>
                  <td colspan="2">
                    Vitamin A {data.vitaminA}
                  </td>
                  <td>
                    Vitamin C {data.vitaminC}
                  </td>
                </tr>
                <tr class="thin-end">
                  <td colspan="2">
                    Calcium {data.calcium}
                  </td>
                  <td>
                    Iron {data.iron}
                  </td>
                </tr>
              </tbody>
            </table>
           <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs:</p>
          <table class="performance-facts__table--small small-info">
            <thead>
              <tr>
                <td colspan="2"></td>
                <th>Calories:</th>
                <th>2,000</th>
                <th>2,500</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">Total Fat</th>
                <td>Less than</td>
                <td>65g</td>
                <td>80g</td>
              </tr>
              <tr>
                <td class="blank-cell"></td>
                <th>Saturated Fat</th>
                <td>Less than</td>
                <td>20g</td>
                <td>25g</td>
              </tr>
              <tr>
                <th colspan="2">Cholesterol</th>
                <td>Less than</td>
                <td>300mg</td>
                <td>300 mg</td>
              </tr>
              <tr>
                <th colspan="2">Sodium</th>
                <td>Less than</td>
                <td>2,400mg</td>
                <td>2,400mg</td>
              </tr>
              <tr>
                <th colspan="3">Total Carbohydrate</th>
                <td>300g</td>
                <td>375g</td>
              </tr>
              <tr>
                <td class="blank-cell"></td>
                <th colspan="2">Dietary Fiber</th>
                <td>25g</td>
                <td>30g</td>
              </tr>
            </tbody>
          </table>
          <p class="small-info">
            Calories per gram:
          </p>
          <p class="small-info text-center">
            Fat 9
            &bull;
            Carbohydrate 4
            &bull;
            Protein 4
          </p>
        </section>
        )  
      }
 }

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  currencySymbol(){    
    const {currency} = this.state;
    const {intl} = this.props;
    let output_currency_symbol = ''

    if (currency == 'None') 
        return output_currency_symbol

    let value = Intl.NumberFormat(intl.locale,{
                                              style:'currency',
                                              currency: currency,
                                              currencyDisplay: 'symbol',
                                  }).format(0)
    value = value.replace(/,/g, '.');
    value +='' 
    value = value.replace('.','')

    let regexp = /\D+/g;
    let symbol = [...value.matchAll(regexp)];      
    
    if (symbol && symbol.length > 0)
      output_currency_symbol = symbol[0][0]
    
    return '  ('+output_currency_symbol+')'

/*     if (currency == 'EUR') return '(€)'
    else if (currency == 'GBP') return '(£)'
    else if (currency == 'CHF') return '(CHF)'
    else if (currency == 'None') return ''
    else return '($)' */
  }

  noteClickHandler (event) {
    event.preventDefault();
    if (this.state.needNote){
      this.setState({ note: " " });
    }
    this.setState({ needNote: !this.state.needNote });
  };


  dlcClickHandler (event) {
    event.preventDefault();
/*     if (this.state.needDLC){
      this.setState({ note: " " });
    } */
    this.setState({ needDLC: !this.state.needDLC });
  };

  onBatchNumberChange = (e) => {
    e.preventDefault();
    this.setState({batch_number: e.target.value });
  }

  setExpirationDate (date){
    this.setState({ selectedDate: date})
  }

  
  onTaxChange = (e,data) => {
    e.preventDefault();
    //console.log(data.value)
    this.setState({ tax: data.value});
  }


  render() {
    const { categoriesIngredient,supplier,alert,dispatch,nutrition,searching,retrieving,customer,intl} = this.props;
    const {categorySelected,supplierSelected,allergensSelected,quantity,type_quantity,pack_units,highlighted,
            nutrient_ready,nutrient_data,submitted,needNote,needDLC,batch_number,selectedDate} = this.state;

    //let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      //user = customer.user;
    }

    const renderLabel = (option) => ({
      color: option.color,
      content: option.text,
    })

    return (
    <SideLeftUncoverIngredients>
      <Grid stackable  style={styles.FontLato12}>
       <Grid.Row columns={1}>
           <Breadcrumb  style={{background:'#fcfcfc'}}>
                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="newingredient.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron'/>
                <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="newingredient.link.ingredients"
                                                              defaultMessage='Ingredients'/></Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron'/>
                <Breadcrumb.Section active><FormattedMessage id="newingredient.link.add"
                                                              defaultMessage='Add'/></Breadcrumb.Section>
           </Breadcrumb>
        </Grid.Row>
          <Grid.Row columns={1} >
              <Grid.Column width={8}>
                  <Header as='h2'>
                    <Icon name='add' size='mini' color='blue'/>
                    <Header.Content>
                        <FormattedMessage id="newingredient.header.title"
                                          defaultMessage=' Add a new ingredient'/>
                        {/*  <Header.Subheader>Manage here your list</Header.Subheader> */}
                      </Header.Content>
                </Header>
                 {/*  <br/> */}
  {/*                 {alert.message  &&  alert.type == alertConstants.ERROR &&
                      <Message  error size='small' > {alert.message}</Message>
                  } */}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
               <Grid.Column mobile={12} tablet={10} computer={7}>
                {/* <Segment container style={{'background': 0x0}} textAlign='left'> */}
              <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <Form.Group>
                <Form.Field width={12}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.form.input.name"
                                             defaultMessage='Name * '/>
                    </label>
                    <FormattedMessage id="newingredient.form.input.name.placeholder"
                                      defaultMessage='Enter ingredient name'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="name"
                                  value={this.state.name}
                                  onChange={this.onNameChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  onFocus={(e)=>this.onFocusElsewhere(e)} 
                                  error={submitted && !this.assertNameField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertNameField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.form.name.error.message"
                                                defaultMessage='name is empty'/>
                        </i></label> 
                      }
                </Form.Field> 
                <Form.Field width={3}>
                    <label><FormattedMessage id="newingredient.form.code.title"
                                             defaultMessage='Internal code'/>
                    </label>
                    <FormattedMessage id="newingredient.form.code.placeholder"
                                      defaultMessage='ref'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="code"
                                  value={this.state.ref_internal}
                                  onChange={this.onRefInternalChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
                 </Form.Field>
                  <Form.Field width={3}>
                      <label><FormattedMessage id="newingredient.form.tax.title"
                                              defaultMessage='Sales Tax'/>
                      </label>
                      <FormattedMessage id="newingredient.form.tax.placeholder"
                                        defaultMessage='Tax'>
                          {placeholder =>     
                                <Select compact
                                  options={VAToptions} 
                                  type="text"
                                  placeholder={placeholder}
                                  id="VAT"
                                  value={this.state.tax}
                                  onChange={this.onTaxChange}
                                  //clearable
                                  />}
                  </FormattedMessage>
                  </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={6}>
                    <label><FormattedMessage id="newingredient.form.category.name"
                                              defaultMessage='Category '/>
                    </label>
                    <FormattedMessage id="newingredient.form.category.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection search
                                          options={categoriesIngredient && categoriesIngredient.options} 
                                          value = {categorySelected && categorySelected.id} 
                                          placeholder={placeholder} 
                                          onChange={this.onCategoryChange}
                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage> 
{/*                     <Modal open={this.state.activeModalAddCategory }
                           dimmer='default' 
                           size='tiny'
                           style={styles.Modal} 
                           onClose={this.hideModal}>
                          <Header icon='add' content={<FormattedMessage id="newingredient.ingredients.modal.addcategory.title"
                                                                        defaultMessage='Add category'/>} />
                          <Modal.Content>
                                <Input type="text"
                                       placeholder="Add category"
                                       id="addCategory"
                                       value={this.state.newCategory}
                                       onChange={this.onAddCategory}
                                       style={{minWidth:'15em',maxWidth:'30em'}}/>
                          </Modal.Content>
                          <Modal.Actions>
                              <Button basic color='grey' onClick={this.hideModal}>
                                    <FormattedMessage id="newingredient.ingredients.modal.addcategory.button.cancel"
                                                          defaultMessage='Cancel'/>
                              </Button>
                              <Button basic color='teal' 
                                      labelPosition='right' 
                                      content={<FormattedMessage id="newingredient.ingredients.modal.addcategory.button.save"
                                                                    defaultMessage='Save'/>}
                                      icon='checkmark' 
                                      onClick={(event) =>this.addCategory(event)}/>
                              </Modal.Actions>
                    </Modal> */}    
                  </Form.Field>
                  <Form.Field width={10}>
                    <label><FormattedMessage id="newingredient.form.allergens.name"
                                                defaultMessage='Allergens'/>
                      </label>
                      <FormattedMessage id="newingredient.form.allergens.placeholder"
                                        defaultMessage='Add allergens'>
                            {placeholder =>   
                                    <Form.Dropdown selection     
                                            multiple
                                            search
                                            options={ [{key:'peanuts',text:intl.formatMessage(allergens.peanuts),value:'peanuts', color:'red'},
                                                       {key:'celery',text:intl.formatMessage(allergens.celery),value:'celery',color:'olive'},
                                                       {key:'crustaceans',text:intl.formatMessage(allergens.crustaceans),value:'crustaceans',color:'pink'},
                                                       {key:'egg',text:intl.formatMessage(allergens.egg),value:'egg',color:'yellow'},
                                                       {key:'fish',text:intl.formatMessage(allergens.fish),value:'fish',color:'teal'},
                                                       {key:'gluten',text:intl.formatMessage(allergens.gluten),value:'gluten',color:'brown'},
                                                       {key:'lupin',text:intl.formatMessage(allergens.lupin),value:'lupin',color:'grey'},
                                                       {key:'milk',text:intl.formatMessage(allergens.milk),value:'milk',color:'blue'},
                                                       {key:'molluscs',text:intl.formatMessage(allergens.molluscs),value:'molluscs',color:'purple'},
                                                       {key:'mustard',text:intl.formatMessage(allergens.mustard),value:'mustard',color:'brown'},
                                                       {key:'nuts',text:intl.formatMessage(allergens.nuts),value:'nuts',color:'violet'},
                                                       {key:'sesame',text:intl.formatMessage(allergens.sesame),value:'sesame',color:'yellow'},
                                                       {key:'soya',text:intl.formatMessage(allergens.soya),value:'soya',color:'green'},
                                                       {key:'sulphites',text:intl.formatMessage(allergens.sulphites),value:'sulphites',color:'orange'}] } 
                                            value = {allergensSelected} 
                                            placeholder={placeholder} 
                                            onChange={this.onAllergensChange}
                                            onFocus={(e)=>this.onFocusElsewhere(e)}
                                            renderLabel={renderLabel}
                                            //style={styles.FontLato12}
                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                      />}
                      </FormattedMessage> 
                  </Form.Field>
                  </Form.Group>
                  <Segment  style={{background:'#fafafa'}}>
                  <Form.Group >
                   <Form.Field width={7}>
                    <label><FormattedMessage id="newingredient.form.supplier.name"
                                              defaultMessage='Supplier '/>
                    </label>
                    <FormattedMessage id="newingredient.form.supplier.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection search 
                                          options={supplier && supplier.options} 
                                          value = {supplierSelected && supplierSelected.id} 
                                          placeholder={placeholder} 
                                          onChange={this.onSupplierChange}
                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage>    
                  </Form.Field>    
               <Form.Field width={5}>
                 <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.form.input.price"
                                             defaultMessage='Price (Cost) '/>
                         {' '}{this.currencySymbol()}{ ' *'}
                    </label>
                    <FormattedMessage id="newingredient.form.input.price.placeholder"
                                      defaultMessage='Add a purchase price'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="price"
                                  value={this.state.price}
                                  onChange={this.onPriceChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                  error={submitted && !this.assertPriceField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertPriceField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.form.price.error.message"
                                                defaultMessage='price is numeric'/>
                        </i></label> 
                      }
                 </Form.Field>
                 <Form.Field width={4}>
                    <label><FormattedMessage id="newingredient.form.supplier.code.title"
                                             defaultMessage='Supplier code'/>
                    </label>
                    <FormattedMessage id="newingredient.form.supplier.code.placeholder"
                                      defaultMessage='reference'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="suppliercode"
                                  value={this.state.ref_supplier}
                                  onChange={this.onRefSupplierChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
                 </Form.Field>
                 </Form.Group>
                {/* <br/> */}
                <Label size='small'>
                    <FormattedMessage id="newingredient.form.purchase.type.title"
                                      defaultMessage='Select purchase type (amount). Then fill as advised '/>
                </Label>
                <br/> <br/>
                {pack_units >= 1 && quantity && 
                    <p style={{textAlign:'right',color:'black',fontSize:'13px',paddingBottom:'0.4em'}}>
                        <FormattedMessage id="newingredient.form.purchase.text1"
                                          defaultMessage='I buy'/> <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}</span>{' '}
                        <FormattedMessage id="newingredient.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/>  
                         <span style={{color:'#00CC99',fontSize:'14px'}}>{'  '} {pack_units}</span>
                    </p>}
                <Form.Group>
                <Form.Field width={6}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.form.select.purchase.type"
                                              defaultMessage='Type of purchase *'/>
                    </label>
                    <FormattedMessage id="newingredient.form.select.purchase.placeholder"
                                      defaultMessage='Select a type of quantity'>
                          {placeholder =>   
                                  <Form.Select  
                                          options={ [{key:'volume',text:<span><Icon name='flask' color='teal'/>{VOLUME}</span>,value:'volume'},
                                                     {key:'weight',text:<span><Icon name='balance' color='teal'/>{WEIGHT}</span>,value:'weight'},
                                                    {key:'quantity',text:<span><Icon name='copy' color='teal'/>{QUANTITY}</span>,value:'quantity'}] } 
                                          value = {type_quantity} 
                                          placeholder={placeholder} 
                                          onChange={this.onTypeQuantityChange}
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                          error={submitted && !this.assertSelectedWeight()}/>}
                    </FormattedMessage>    
                    {submitted && !this.assertSelectedWeight()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="newingredient.form.select.purchase.error.message"
                                                defaultMessage='purchase type to select'/>
                        </i></label> 
                      }
                </Form.Field> 
                <Form.Field width={6}>
                        <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.form.input.quantity"
                                                defaultMessage='Quantity *'/>
                        </label>
                        <FormattedMessage id="newingredient.form.input.quantity.placeholder"
                                          defaultMessage='Quantity with unit'>
                            {placeholder =>     
                                  <Form.Input
                                      type="text"
                                      placeholder={placeholder}
                                      id="quantity"
                                      value={quantity}
                                      onChange={this.onQuantityChange}
                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      //style={{minWidth:'10em',maxWidth:'30em'}}
                                      error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                    />}
                          </FormattedMessage>
                          {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                            <label style={{color:'red', }}><i>
                                  <FormattedMessage id="newingredient.form.quantity.error.message"
                                                    defaultMessage='quantity format invalid (follow instructions)'/>
                            </i></label> 
                          }
                 </Form.Field>
                 <Form.Field width={4}>
                        <label><FormattedMessage id="newingredient.form.input.packaging"
                                                defaultMessage='Case unit(s)'/>
                        </label>
                        <FormattedMessage id="newingredient.form.input.packaging.units.placeholder"
                                          defaultMessage='Number units'>
                            {placeholder =>     
                                  <Form.Input
                                      type="number"
                                      placeholder={placeholder}
                                      id="units"
                                      min="1"
                                      value={pack_units}
                                      onChange={this.onUnitsPackagingChange}
                                      onFocus={(e)=>this.onFocusPackField(e)}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      //style={{minWidth:'10em',maxWidth:'30em'}}
                                      error={submitted && !this.assertPackField()}
                                      icon={pack_units == 1 ? <Icon name='tag' color="grey"/>: <Icon name='tags' color="teal"/>}
                                      iconPosition='left'
                                    />}
                          </FormattedMessage>
                          {submitted && !this.assertPackField() &&
                            <label style={{color:'red', }}><i>
                                  <FormattedMessage id="newingredient.form.pack.error.message"
                                                    defaultMessage='units number invalid'/>
                            </i></label> 
                          }
                </Form.Field>
                </Form.Group>
{/*                 <Form.Group>
                <Form.Field width={8}>
                        <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="newingredient.form.input.minimum"
                                                defaultMessage='Minimum purchase'/>
                        </label>
                        <FormattedMessage id="newingredient.form.input.quantity.placeholder"
                                          defaultMessage='Enter a quantity'>
                            {placeholder =>     
                                  <Form.Input
                                      type="text"
                                      placeholder={placeholder}
                                      id="quantity"
                                      value={quantity}
                                      onChange={this.onQuantityChange}
                                      onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      //style={{minWidth:'10em',maxWidth:'30em'}}
                                      error={submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity())}
                                    />}
                          </FormattedMessage>
                          {submitted && (!this.assertQuantityField() || !this.assertQuantityFieldsValidity()) &&
                            <label style={{color:'red', }}><i>
                                  <FormattedMessage id="newingredient.form.quantity.error.message"
                                                    defaultMessage='quantity format invalid (follow instructions)'/>
                            </i></label> 
                          }
                 </Form.Field>
                 </Form.Group> */}
                 { highlighted 
                    && type_quantity == 'weight' 
                    &&  <Message size='tiny' 
                                 icon='balance'>       
                            <Icon name='balance' color='teal'/> 
                            <Message.Content>
                              <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                              <ul>
                                <br/>
                                <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                              </ul> 
                              {WEIGHT_INSTRUCTION_LINE_EX}
                            </Message.Content>                                     
                        </Message>
                  }
                 {  highlighted 
                    && type_quantity == 'volume'   
                    &&  <Message size='tiny' 
                              icon='flask' >       
                        <Icon name='flask' color='teal'/> 
                        <Message.Content>
                          <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                          <ul>
                            <br/>
                            <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                            <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                          </ul> 
                          {VOLUME_INSTRUCTION_LINE_EX}
                        </Message.Content>                                     
                    </Message>
                  }
                 { highlighted 
                   && type_quantity == 'quantity' 
                    &&  <Message size='tiny' 
                                 icon='copy'>       
                        <Icon name='copy' color='teal'/> 
                        <Message.Content>
                          <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                          <ul>
                            <br/>
                            <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                          </ul> 
                          {QUANTITY_INSTRUCTION_LINE_EX}
                        </Message.Content>                                     
                    </Message>
                  }
                   {!needDLC && <span style={{paddingTop:'0em'}}><Icon link circular basic
                                      name='add' 
                                      color='grey'
                                      onClick={(event)=>this.dlcClickHandler(event)}/>
                                    {' '}<FormattedMessage id="newingredient.form.dlc.button"
                                                           defaultMessage='Traceability : expiration date, batch'/>
                                </span>}        
                  {needDLC && 
                   <Form.Field /* width={16} */ style={{paddingTop:'0em'}}>
                    <label style={{color:'grey'}}>
                    <Icon link circular basic
                                      name='minus' 
                                      color='grey'
                                      onClick={(event)=>this.dlcClickHandler(event)}/>
                                       {' '}<FormattedMessage id="newingredient.form.dlc.button"
                                             defaultMessage='Traceability : expiration date, batch'/></label>
                    <Form.Group style={{paddingTop:'0.5em', paddingLeft:'1.5em'}}> 
                          <Form.Field>
                                <label><Icon name='calendar alternate outline'/>
                                        {' '}<FormattedMessage id="newingredient.form.dlc.title"
                                                              defaultMessage='Use by'/>
                                 </label>
                                 <FormattedMessage id="newingredient.form.dlc.placeholder"
                                                        defaultMessage='---'>
                                          {placeholder =>     
                                                  <DatePicker selected={selectedDate} 
                                                              onChange={(date) => this.setExpirationDate(date)}
                                                              placeholderText = {placeholder} 
                                                              locale={intl.locale}
                                                              onFocus={(e)=>this.onFocusElsewhere(e)}
                                                              dateFormat="P"/>}
                                 </FormattedMessage>
                          </Form.Field>
                          <Form.Field>
                                 <label><FormattedMessage id="newingredient.form.extrasupplier.batchnumber.code.title"
                                                                    defaultMessage='Batch number'/>
                                 </label>
                                 <FormattedMessage id="newingredient.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='XXX_1234'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={batch_number}
                                                          onChange={this.onBatchNumberChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                 </FormattedMessage>
                          </Form.Field>                  
                    </Form.Group>
                  </Form.Field>}                 
                  </Segment>
                  {!needNote && <span><Icon link circular basic
                                      name='add' 
                                      color='grey'
                                      onClick={(event)=>this.noteClickHandler(event)}/>
                                    {' '}<FormattedMessage id="newingredient.form.note.placeholder"
                                                           defaultMessage="Note"/>
                                </span>}       
                  {needNote && 
                   <Form.Field width={16} style={{paddingLeft:'0.5em'}}>
                    <label style={{color:'grey'}}>
                      <Icon link circular basic
                                      name='minus' 
                                      color='grey'
                                      onClick={(event)=>this.noteClickHandler(event)}/>
                        {' '}<FormattedMessage id="newingredient.form.note.title"
                                             defaultMessage='Note'/></label>
                    <FormattedMessage id="newingredient.form.note.placeholder"
                                      defaultMessage='Note'>
                         {placeholder =>     
                              <Form.TextArea
                                  type="text"
                                  placeholder={placeholder}
                                  id="note"
                                  value={this.state.note}
                                  onChange={this.onNoteChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)} 
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                />}
                      </FormattedMessage>
                  </Form.Field>}
                  <br/><br/>
                  <Form.Field>
                    <label><FormattedMessage id="newingredient.form.nutrition.label"
                                            defaultMessage='Retrieve nutrition data (optional)'/></label>
                    <FormattedMessage id="newingredient.form.input.nutrition.placeholder"
                                          defaultMessage='Search in ingredients database'>
                            {placeholder =>     
                              <Input //defaultValue={this.state.value} 
                                      //size='large'
                                      onChange={this.handleInputChange}
                                      action={{ color: 'grey', icon: 'search', onClick: (e) => {this.handleSearchNutrition()} }}
                                      placeholder={placeholder}
                                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      onFocus={(e)=>this.onFocusElsewhere(e)}/>}
                     </FormattedMessage>                
                    <Modal open={this.state.activeModalNutrition}
                            dimmer='true' 
                            //size='small' 
                            style={styles.Modal} 
                            onClose={this.hideModal}
                            closeIcon>
                        <Modal.Header>
                           <FormattedMessage id="newingredient.form.nutrition.modal.header"
                                              defaultMessage='Searching Nutrient Database'/>
                        </Modal.Header>
                        <Modal.Content /*image*/ scrolling>
                          {/*  <Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' wrapped /> */}
                          <Modal.Description>
                            <Header>
                                  <FormattedMessage id="newingredient.form.nutrition.modal.content.header"
                                                    defaultMessage='From USDA Database source'/>
                            </Header>
                            <p>
                                  <FormattedMessage id="newingredient.form.nutrition.modal.content.subheader"
                                                    defaultMessage='Select the item that match your ingredient'/>
                            </p>
                            <br/>
                            <Grid stackable style={styles.FontLato14}>
                            { !searching && nutrition && nutrition.items && this.addNutritionItemsList()}
                            </Grid>
  {/*                       {_.times(8, i => (
                              <Image key={i} src='https://react.semantic-ui.com/images/wireframe/paragraph.png' style={{ paddingBottom: 5 }} />
                            ))} */}
                          </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                               {/* <Icon name='exit' /> */}
                              <FormattedMessage id="newingredient.form.nutrition.modal.button.cancel"
                                              defaultMessage='Cancel'/>
                            </Button>
                          </Modal.Actions>
                      </Modal> 
                      <Modal open={this.state.activeModalPremium}
                            dimmer='true' 
                            style={styles.Modal} 
                            onClose={this.hideModal}
                            closeIcon>
                        <Modal.Header>
                           <FormattedMessage id="newingredient.form.nutrition.modal.header"
                                              defaultMessage='Searching Nutrient Database'/>
                        </Modal.Header>
                        <Modal.Content>
                            <Message fluid icon size='big' color='blue'>
                              <Image src={img_performance} size ='small' rounded/>
                              <br/>
                              <Message.Content style={{marginLeft:'1em'}}>
                                  <Message.Header color='black'>
                                      <FormattedMessage id="newingredient.premium.message.upgradeplan"
                                                          defaultMessage='ENTERPRISE Feature'/>
                                  </Message.Header>
                                  <br/>
                                  <FormattedMessage id="newingredient.premium.title.message.upgradeplan"
                                                  defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                  <br/><br/>
                                  <Button basic color='blue' onClick={(e) => this.upgradeSubscription()}>
                                      <FormattedMessage id="newingredient.premium.button.chooseplan"
                                                  defaultMessage='Choose premium plan'/>
                                  </Button>                                      
                              </Message.Content>
                          </Message>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                               {/* <Icon name='exit' /> */}
                              <FormattedMessage id="newingredient.premium.button.cancel"
                                              defaultMessage='Cancel'/>
                            </Button>
                            <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                               <FormattedMessage id="newingredient.premium.button.chooseplan"
                                                  defaultMessage='Choose premium plan'/>
                            </Button>
                          </Modal.Actions>
                      </Modal> 
                    </Form.Field>
                {!retrieving && nutrient_ready && nutrition && nutrition.nutrients &&
                 <div style={{paddingLeft:'2em'}}>
                    <Form.Field style={{'font-family': 'lato', 'font-size': '12px','color': 'grey'}}>
                      <br/>
                      {intl.locale == 'fr' && this.addFoodFactsFr(nutrient_data)}
                      {intl.locale == 'en' && this.addFoodFacts(nutrient_data)}
                    </Form.Field>
                    <Form.Field style={{paddingLeft:'3em'}}>
                      <br/>
                        <Button basic color='grey' 
                                style={{minWidth:'15em',maxWidth:'25em'}} 
                                onClick= {this.cancelNutrition}>
                                <FormattedMessage id="newingredient.form.nutrition.object.remove.choice"
                                                  defaultMessage='Remove Nutrition'/>
                        </Button>
                    </Form.Field>
                  </div>}
                <br/>
                <Form.Field>
                <Button  /*disabled={this.state.name.length === 0 
                                  || this.state.price.length === 0 
                                  ||(this.state.purchase_quantity.length===0 
                                       && this.state.purchase_volume.length=== 0 
                                       && this.state.purchase_weight.length=== 0)}*/
                        onClick={(e) => {this.onSubmit(e,'/ingredients/')}} 
                        style={styles.ButtonGreen}
                        //color='teal' basic
                        >
                    <FormattedMessage id="newingredient.button.submit"
                                    defaultMessage='Submit New Ingredient'/>
                </Button>
                <Button style={styles.ButtonBlue} //basic color='blue' 
                    onClick={(e) => {this.onSubmit(e,'/newingredient/')}}>
                    <FormattedMessage id="newingredient.button.createandnext"
                                      defaultMessage='Create and Next'/>
                </Button>
                <Button style={styles.ButtonGrey}//basic color='grey' 
                      onClick={this.cancelAction}>
                      <FormattedMessage id="newingredient.button.cancel"
                                        defaultMessage='Cancel'/>
                </Button>
                </Form.Field>
                {alert.message && toaster_dispatch(dispatch,alert,intl)}
               {/*  </Segment>  */}
              </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
     </SideLeftUncoverIngredients>
    );
  }
}






function mapStateToProps(state) {
  const { categoriesIngredient, dispatch,supplier, alert, nutrition } = state;
  const { searching,retrieving} = state.nutrition;
  const { customer } = state.payment;
  return {
      supplier,
      categoriesIngredient,
      customer,
      dispatch,
      alert,
      nutrition,
      searching,
      retrieving,
  };
}


const connectedNewIngredient = injectIntl(connect(mapStateToProps)(NewIngredient));
export { connectedNewIngredient as NewIngredient };
