import React from 'react'
import { Grid, Header, Message,Button,Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import * as qs from 'query-string';
import {FormattedMessage,injectIntl} from 'react-intl';
import { paymentActions } from '../_actions';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {styles} from './Styles.jsx';

class SendVoucher extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.sendvoucher = this.sendvoucher.bind(this);
        this.state = {
          no_parameters : false,
          phone:'',
        };
    }
    
    componentDidMount(){
      
      if (this.props.location && this.props.location.search){
        console.log(this.props.location.search);
        const parsed = qs.parse(this.props.location.search);
        console.log(parsed);
        this.setState({ code: parsed.code });
        this.setState({ email: parsed.email });
        //this.props.dispatch(paymentActions.sendVoucher(parsed));
      }else{
        this.setState({ no_parameters: true });
      }
    }

    handleOnChange(value) {
      this.setState({ phone: value })
    }

    sendvoucher() {
      const { phone,email,code } = this.state;
      console.log(phone)
      if (phone && email && code){
        const params ={
          phone: this.state.phone,
          email: this.state.email,
          code: this.state.code
        }
        this.setState({ no_parameters: false })
        this.props.dispatch(paymentActions.sendVoucher(params));
      }else{
        this.setState({ no_parameters: true })
      }

    }

    render() {
        const { alert,loading_voucher,sent_voucher,error_voucher,error,intl } = this.props;
        const { no_parameters } = this.state;

        return (
            <div className='login-form'>
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
                <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}}>
                  <Grid.Column style={{ top: "25%", maxWidth: 450 }}>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
                      {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      }
                      {' '}<FormattedMessage id="sendVoucher.form.title"
                                             defaultMessage='Sending voucher for confirmation'/>
                    </Header>
                    {loading_voucher && 
                        <img alt="load" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <br/>
                    {!sent_voucher && 
                      <div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                          <FormattedMessage id="sendVoucher.phone.text"
                                             defaultMessage='Enter you phone number'/>
                          <PhoneInput defaultCountry={intl.locale=='fr'?intl.locale:'us'} disableAreaCodes={true} value={this.state.phone} onChange={this.handleOnChange} />
                          <br/>
                          <Button style={styles.ButtonBlue}
                                      onClick={(e) => this.sendvoucher()}>
                                      <FormattedMessage id="sendVoucher.send"
                                                        defaultMessage='Receive my code'/>
                          </Button>
                         </div>
                         <br/><br/>
                      </div>}
                    {sent_voucher && 
                        <span style={{color:'green'}}>
                        <FormattedMessage id="sendVoucher.form.ok"
                                          defaultMessage='Voucher sent by SMS, check and confirm'/>
                        </span>
                    }
                    {error_voucher && 
                        <span style={{color:'red'}}>
                          {error}
{/*                         <FormattedMessage id="sendVoucher.form.ko"
                                          defaultMessage='Invalid voucher'/> */}
                        </span>
                    }
                    {no_parameters && 
                        <span style={{color:'red'}}>
                        <FormattedMessage id="sendVoucher.form.invalid"
                                          defaultMessage='Invalid request'/>
                        </span>
                    }
                    <Message>
                      <Link to="/login"><FormattedMessage id="sendVoucher.form.link.back"
                                                           defaultMessage='Return to log in'/></Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 800,  height: '100vh'}}> 
                     <img src={img_window}  alt='window' style={{width: '100v',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <img src={img_logo}  alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                      <Header as='h3' color='grey' textAlign='center'>
                        {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }
                        {' '}<FormattedMessage id="sendVoucher.form.title"
                                             defaultMessage='Sending voucher for confirmation'/>
                      </Header>
                      {loading_voucher && 
                          <img  alt='wait' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      }
                      <br/>
                      {!sent_voucher && 
                      <div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                         <FormattedMessage id="sendVoucher.phone.text"
                                             defaultMessage='Enter you phone number'/>
                          <PhoneInput defaultCountry={intl.locale=='fr'?intl.locale:'us'} disableAreaCodes={true} value={this.state.phone} onChange={this.handleOnChange} />
                          <br/>
                          <Button style={styles.ButtonBlue}
                                      onClick={(e) => this.sendvoucher()}>
                                      <FormattedMessage id="sendVoucher.send"
                                                        defaultMessage='Receive my code'/>
                          </Button>
                         </div>
                         <br/><br/>
                      </div>}
                      {sent_voucher && 
                          <span style={{color:'green'}}>
                          <FormattedMessage id="sendVoucher.form.ok"
                                            defaultMessage='Voucher sent by SMS, check and confirm'/>
                          </span>
                      }
                      {error_voucher && 
                          <span style={{color:'red'}}>
                             {error}
{/*                           <FormattedMessage id="sendVoucher.form.ko"
                                            defaultMessage='Invalid voucher'/> */}
                          </span>
                      }
                      {no_parameters && 
                        <span style={{color:'red'}}>
                        <FormattedMessage id="sendVoucher.form.invalid"
                                          defaultMessage='Invalid request'/>
                        </span>
                      }
                      <br/>
                      <Message>
                              <Link to="/login"><FormattedMessage id="sendVoucher.form.link.back"
                                                                  defaultMessage='Return to log in'/></Link>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
            </div>
          );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { loading_voucher,sent_voucher,error_voucher,error } = state.payment;
    return {
        alert,
        loading_voucher,
        sent_voucher,
        error_voucher,
        error
    };
}

const connectedSendVoucher = injectIntl(connect(mapStateToProps)(SendVoucher));
export { connectedSendVoucher as SendVoucher };