import React, { Component } from 'react';
import { Table,Grid,Image,Button,Message,Header,Modal,Card,Form,Popup,Icon,Input,Label,Select,Loader,Dimmer,Breadcrumb,Checkbox,TextArea} from 'semantic-ui-react'
import {connect} from 'react-redux';
import { Link,BrowserRouter } from 'react-router-dom';
import {Capitalize,history,toaster_dispatch,isInteger,isFloat,isNumeric,trim, allergens,isQuantity
      ,VAToptions,optimizeDecimalPart,unitWeight,unitVolume,grams,mgrams,kgrams,liters,mliters,cliters,flounces,pounds,ounces,GUEST, typeYield} from '../_helpers';
import SideLeftUncoverRecipe from './SidebarRecipe';
import { recipesActions,paymentActions,alertActions } from '../_actions';
import {ResponsivePie} from '@nivo/pie';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {styles} from './Styles';
import _ from 'lodash';
import {Helmet} from "react-helmet";

import img_moneybag from '../Assets/label.png'
import img_selection from '../Assets/pie-chart.png'
import img_list from '../Assets/categories.png'
//import img_rising from '../Assets/bar-chart-rise.png'
//import img_coins from '../Assets/tax.png'
//import img_percentage from '../Assets/document.png'
//import img_groceries from '../Assets/shopping-bag.png'
import img_gluten from '../Assets/gluten.png'
import img_composition from '../Assets/composition.png'
//import img_calendar from '../Assets/calendar.png'
import img_performance from '../Assets/performance.png'
//import img_expiration from '../Assets/wall-clock.png'

import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';

import './hover.css';
import Dropzone from 'react-dropzone';
import DEFAULT_RECIPE_IMAGE from '../Assets/recipe_blank.png'

import ReactToPrint from 'react-to-print';
import * as htmlToImage from 'html-to-image';

import OutsideClickHandler from 'react-outside-click-handler';

let title = <FormattedMessage id="recipedetails.file.error.title"
                              defaultMessage="Upload File Error"/>
let content_err_file_size = <FormattedMessage id="recipedetails.file.size.error.content"
                                              defaultMessage="File size or format not supported"/>
let content_permission = <FormattedMessage id="recipedetails.file.permission.error.content"
                                              defaultMessage="You do not have Write privilege"/>  

const legend_props = [
    {
        "anchor": "left",
        "direction": "column",
        "translateY": 10,
        "itemWidth": 100,
        "itemHeight": 18,
        "itemTextColor": "#999",
        "symbolSize": 18,
        "symbolShape": "circle",
        "effects": [
            {
                "on": "hover",
                "style": {
                    "itemTextColor": "#000"
                }
            }
        ]
    }
];

const variationTypeOptions = [
    { key: 'loss', value: 'loss', text: <FormattedMessage id="recipedetails.recipe.variation.option.loss"
                                                          defaultMessage='Loss'/> },
    { key: 'gain', value: 'gain', text: <FormattedMessage id="recipedetails.recipe.variation.option.more"
                                                          defaultMessage='Gain'/> }
    ];

class RecipeDetails extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { 
                prevRecipe : props.recipes,
                prevImage : null,
                activeModal: null,
                activeModalRecipes: null,
                activeModalVariation: false,
                activeModalPremium: false,
                activeModalLabel: false,
                //selected: null,
                toggled: false,
                quantityparts : 1,
                sellingprice : 0.00,
                grossmargin : 0.00,
                markup : 0.00,
                sellingVAT : 0.00,
                currency : userIn.user.currency,
                //files: null, 
                file: null,
                submitted: false,
                typeVariation: null,
                valueVariation: null,
                valueGrossWeight: null,
                valueNetWeight: null,
                activeVariation: false,
                active_role: userIn.user.role,
                toggleEditionMode: false,
                daysValidity:1,
             }
        this.hideModal = this.hideModal.bind(this);
        this.editCurrentRecipe = this.editCurrentRecipe.bind(this);
        //this.handleChangeImage = this.handleChangeImage.bind(this);
        //this.fileUpload = React.createRef();
        this.triggerInputFile = this.triggerInputFile.bind(this);
        this.props.dispatch(recipesActions.getImage(props.match.params.recipeId));
        this.props.dispatch(recipesActions.get(props.match.params.recipeId));
        this.props.dispatch(paymentActions.getCustomerMembership());
        this.getDataTimeout = null;
        
    }

    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe) && (props.recipes.selected)) {
            const selected = props.recipes.selected;
            const image = props.recipes.image;
            const values = []; 
            const allergens = [];  
                 
            selected.subrecipes.map((s,index) => {
                if (s.subrecipe){
                    values.push({id:s.subrecipe.name, 
                        label: s.subrecipe.name , 
                        value: s.item_cost,
                        percent: (s.item_cost/selected.cost_total*100).toFixed(2)+'%'})
                }
            });
            selected.ingredients.map((i,index) => {
                if (i.ingredient){
                    values.push({id:i.ingredient.name, 
                        label: i.ingredient.name, 
                        value: i.item_cost,
                        percent: (i.item_cost/selected.cost_total*100).toFixed(2)+'%'})
                }

            });

            if (selected.allergens){
                for (let elem of selected.allergens){
                     allergens.push(elem.name)
                }       
            }

             if (image && image!=state.prevImage){
                return {
                    prevRecipe : props.recipes,
                    prevImage : image,
                    selected: selected, 
/*                     files: [{
                        source: selected.image,
                        options: {
                            type: 'local',
                        },
                    }], */
                    file: image ,
                    chart_data: values,
                    quantityparts: selected.quantity_parts,
                    sellingprice: selected.selling_price,
                    grossmargin: selected.gross_margin,
                    foodcost: selected.food_cost,
                    markup: selected.markup,
                    sellingVAT: Number(selected.selling_vat),
                    allergens:allergens,
                    //typeVariation: selected.weight_variation_type,
                    //valueVariation: Number(selected.weight_variation_value),
                    activeVariation: selected.weight_variation_active,
                    isSubRecipe:selected.is_subrecipe,
                    metrics:selected.metrics,
                    toggleEditionMode : selected.manual_label,
                    daysValidity: selected.days_validity
                }
            }else{
                return {
                    prevRecipe : props.recipes,
                    selected: selected, 
                    //file : DEFAULT_RECIPE_IMAGE,
                    chart_data: values,
                    quantityparts: selected.quantity_parts,
                    sellingprice: selected.selling_price,
                    grossmargin: selected.gross_margin,
                    foodcost: selected.food_cost,
                    markup: selected.markup,
                    sellingVAT: Number(selected.selling_vat),
                    allergens:allergens,
                    //typeVariation: selected.weight_variation_type,
                    //valueVariation: Number(selected.weight_variation_value),
                    activeVariation: selected.weight_variation_active,
                    isSubRecipe:selected.is_subrecipe,
                    metrics:selected.metrics,
                    toggleEditionMode : selected.manual_label,
                    daysValidity: selected.days_validity
                }
            }
        }
        return null;
    }


    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }


    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
        this.setState({isTablet: (window.innerWidth >= 760 && window.innerWidth < 900)});
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModal: index});
    };
    
    deleteClickHandlerRecipes (event,index) {
        event.preventDefault();
        this.setState({ activeModalRecipes: index});
    }; 

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ activeModalRecipes: null })
        this.setState({ activeModalVariation: false })
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalLabel: false });

        this.setState({ typeVariation: null });
        this.setState({ valueVariation: null });
        this.setState({ valueGrossWeight: null });
        this.setState({ valueNetWeight: null });
        this.setState({ submitted: false });
    }


    addVariationHandler (event) {
        event.preventDefault();
        this.setState({ activeModalVariation: true});
    };


    onTypeVariationChange = (e,data) => {
        e.preventDefault();
        this.setState({ typeVariation: data.value });
        this.setState({ submitted: false });
    }

    onValueVariationChange = (e) => {
        e.preventDefault();
        this.setState({ valueVariation: e.target.value });
        this.setState({ submitted: false });
    }

    removeVariation (event) {
        event.preventDefault();
        const {selected} = this.state;
        const {dispatch} = this.props;

        this.setState({ activeVariation: false });

        if (selected){
            const params = {
                value: Number(0),
                type: "remove",
                active: false
             }
            dispatch(alertActions.clear());
            dispatch(recipesActions.recipe_weight_variation(selected.id,params));
        }
    };

    onApplyVariation = (e) => {
        const {valueVariation,typeVariation,selected} = this.state;
        const {dispatch} = this.props;
        this.setState({ submitted: true });
        this.setState({ activeVariation: true });

        if (!this.assertTypeField()){
            return;
        }

        if (!this.assertPercentField()){
            return;
         }

        if (selected && valueVariation && typeVariation){
            const params = {
                value: Number(valueVariation.toString().replace(/,/g, '.')/100).toFixed(4),
                type: typeVariation,
                active: true
             }
            dispatch(alertActions.clear());
            dispatch(recipesActions.recipe_weight_variation(selected.id,params));
            this.setState({ activeModalVariation: false })
        }
    }

    onValueGrossChange= (e) => {
        e.preventDefault();
        let currentGrossValue = e.target.value;
        currentGrossValue = currentGrossValue.toString().replace(/,/g, '.') 
        this.setState({ onValueGrossChange: currentGrossValue });
        this.setState({ submitted: false });
    
        const {onValueNetChange} = this.state;
        let currentVariation = Number(0)
    
        if  (onValueNetChange && currentGrossValue){
          currentVariation = Number(currentGrossValue) - Number(onValueNetChange)
          let variation =  Number(currentVariation/Number(currentGrossValue)) * 100;
          if (!isNaN(variation) && _.isFinite(variation)){
            if (Math.sign(variation) >= 0){
              this.setState({ typeVariation: 'loss' });
            }else{
              this.setState({ typeVariation: 'gain' });
            }
            this.setState({ valueVariation: Math.abs(optimizeDecimalPart(variation))});
          }
        }
      }
    
      onValueNetChange= (e) => {
        e.preventDefault();
        let currentNetValue = e.target.value;
        currentNetValue = currentNetValue.toString().replace(/,/g, '.')
        this.setState({ onValueNetChange: currentNetValue });
        this.setState({ submitted: false });
    
        const {onValueGrossChange} = this.state;
        let currentVariation = Number(0)
    
        if  (onValueGrossChange && currentNetValue){
          currentVariation = Number(onValueGrossChange) - Number(currentNetValue)
          let variation = Number(currentVariation/Number(onValueGrossChange)) * 100
          if (!isNaN(variation) && _.isFinite(variation)){
            if (Math.sign(variation) >= 0){
              this.setState({ typeVariation: 'loss' });
            }else{
              this.setState({ typeVariation: 'gain' });
            }
            this.setState({ valueVariation: Math.abs(optimizeDecimalPart(variation))});
          }
        }
      }

    seeRecipe (event,index) {
        event.preventDefault();
        this.props.dispatch(recipesActions.get(index,'/recipedetails/'+index));
    }

    editRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/recipedetails'));
        history.push('/editrecipe/'+index);
    }

    deleteRecipe (event,index) {
        event.preventDefault();
        const { selected } = this.props;
        // -- Implementation with modals for deletion
        if (selected){
            this.props.dispatch(recipesActions.deletesubrecipe(selected.id,index))
        }
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
    }

    seeIngredient (event,index) {
        event.preventDefault();
        history.push('/ingredientdetails/'+index);
    }


    editIngredient (event,index) {
        event.preventDefault();
        //this.props.dispatch(ingredientsActions.get(index,'/ingredientdetails'));
        history.push('/editingredient/'+index);
    }

    deleteIngredient (event,index) {
        event.preventDefault();
        const { selected } = this.props;
        if (selected){
            this.props.dispatch(recipesActions.deleteingredient(selected.id,index))
        }
        //history.push('/deleterecipe')
    }

    editCurrentRecipe(event){
        //event.preventDefault();
        const {selected} = this.props;
        if (selected){
            history.push('/editrecipe/'+selected.id);
        }
        
    }

    recipeLabelTag(event) {
        event.preventDefault();
        const { selected } = this.props;
        this.props.dispatch(recipesActions.labelTag(selected.id));
    }

    recipeLabelFormatAllergens() {
        const { selected } = this.props;
        if (selected.label_text.length > 0){
            //console.log( selected.label_text)
            const words = selected.label_text.trim().split(',');
            //console.log(words)
            const lastIndex = words.length - 1;
            return words.map((s,i) => {
                if (s == s.toUpperCase()){
                    if (i === lastIndex) {
                        return (<span style={{'font-weight':'900','font-family': 'Lato'/*,'font-style': 'italic'*/,'font-size': '14px','color': 'grey'}}>
                                    {s.toLowerCase()}
                                </span>);
                    }else{
                        return (<span style={{'font-weight':'900','font-family': 'Lato'/*,'font-style': 'italic'*/,'font-size': '14px','color': 'grey'}}>
                                    {s.toLowerCase()}{' , '}
                                </span>);
                    }
                }else{
                    if (i === lastIndex){
                        return (<span style={{'font-weight':'200','font-family': 'Lato', 'font-size': '14px','color': 'grey'}}>
                                {s.toLowerCase()}
                                </span>);
                    }
                    else{
                        return (<span style={{'font-weight':'200','font-family': 'Lato', 'font-size': '14px','color': 'grey'}}>
                                {s.toLowerCase()}{' , '}
                                </span>);
                    }
                }
            });
        }  
        return ""
    }

    assertPercentField(){
        const {valueVariation/*,typeVariation*/} = this.state;
/*      let maxValueVariation = 100
         if (typeVariation == 'gain'){
            maxValueVariation = 400
        } */
        if (!valueVariation) return false;
        let value = valueVariation.toString().replace(/,/g, '.')
        if (isNumeric(value)
              && (value > 0)
              && (value < 100))
              //&& (value < maxValueVariation))
          return true;
        return false;
    }

    assertTypeField(){
        if ((this.state.typeVariation)
              && ((this.state.typeVariation == 'gain')
              || (this.state.typeVariation == 'loss')))
          return true;
        return false;
    }

    getActiveExtraSupplier(ingredient){
        if (ingredient && ingredient.extra_suppliers && ingredient.extra_suppliers.length > 0){
            let extras = ingredient.extra_suppliers
            for (let s of extras){
                if (s.default_supplier){
                    return s.supplier.name
                }
            }
        }
    }


    addRecipesRows(selected){
        const {currency} = this.state;
        let initial_recipes =  selected.subrecipes;
        let sorted_recipes  = initial_recipes.sort(function (a, b) {
            return a.subrecipe.name > b.subrecipe.name;
        })
        return sorted_recipes.map((s,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={2}><Icon name='food' color='blue'/>
                                                <FormattedMessage id="recipedetails.recipe.row.elem.recipe"
                                                                    defaultMessage='Recipe'/></Table.Cell>
                        <Table.Cell width={3} textAlign='left'>
{/*                            <a href="#" 
                                style={{'color': 'black'}}
                               onClick={(event) => this.seeRecipe(event,s.subrecipe.id)}>{s.subrecipe && s.subrecipe.name}
                            </a> */}
                             <BrowserRouter forceRefresh={true}>
                            <Link to={'/recipedetails/' + s.subrecipe.id } style={{'color': 'black'}}>{s.subrecipe && s.subrecipe.name}</Link>
                            </BrowserRouter>
                            { s.subrecipe &&  s.subrecipe.weight_variation_active &&
                                <Popup trigger={<Button compact floated='right' basic  
                                                        size='tiny'
                                                        color='teal'>
                                                        {( s.subrecipe.weight_variation_type == 'loss') && ' - '}
                                                        {( s.subrecipe.weight_variation_type == 'gain') && ' + '}
                                                        {Number(s.subrecipe.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                    </Button>}
                                        content={<FormattedMessage id="recipedetails.popup.view.weight.info.text"
                                                                    defaultMessage='Variation applied'/>}
                                        inverted
                                        size='tiny'
                                        position='bottom left'/>
                            }
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>
                        {s.quantity_type == 'quantity' && s.subrecipe && Number(s.subrecipe.weight_unit_value)>0 &&
                            <div>
                                <FormattedNumber value={s.quantity_value}/>{s.quantity_unit} {' '}:{' '}  
                                <FormattedNumber value={Number(s.quantity_value) * Number(s.subrecipe.weight_unit_value)}/>{' '}{s.subrecipe.weight_unit}
                            </div>}
                        {s.quantity_type == 'quantity' && s.subrecipe  && !(s.subrecipe.weight_unit_value) &&
                            <div>
                                <FormattedNumber value={s.quantity_value}/>{s.quantity_unit}
                            </div>}
                         {s.quantity_type != 'quantity' && 
                            <div>
                                <FormattedNumber value={s.quantity_value}/>{s.quantity_unit}
                            </div>}
{/*                         {s.subrecipe && s.subrecipe.weight_variation_active && 
                            <div style={{'color': 'green'}}>
                                {(s.subrecipe.weight_variation_type == 'loss') && ' (- '}
                                {(s.subrecipe.weight_variation_type == 'gain') && ' (+ '}
                                {Number(s.subrecipe.weight_variation_value*100).toFixed(0)+'%)'} 
                            </div>} */}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>
                        {s.quantity_type == 'quantity' && s.subrecipe && Number(s.subrecipe.weight_unit_value)>0 &&
                            <div>
                                 {!s.subrecipe.weight_variation_active &&
                                    <FormattedNumber value={s.quantity_value}/>}
                                 {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'loss' &&
                                     <FormattedNumber value={s.quantity_value/(1 - Number(s.subrecipe.weight_variation_value))}/> }
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'gain' &&
                                    <FormattedNumber value={s.quantity_value/(1 + Number(s.subrecipe.weight_variation_value))}/> }
                                {s.quantity_unit} {' '}:{' '} 
                                {!s.subrecipe.weight_variation_active &&
                                    <FormattedNumber value={Number(s.quantity_value) * Number(s.subrecipe.weight_unit_value)}/>}
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'loss' &&
                                    <FormattedNumber value={Number(s.quantity_value/(1 - Number(s.subrecipe.weight_variation_value))) * Number(s.subrecipe.weight_unit_value)}/>}
                                 {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'gain' &&
                                   <FormattedNumber value={Number(s.quantity_value/(1 + Number(s.subrecipe.weight_variation_value))) * Number(s.subrecipe.weight_unit_value)}/>}
                                {' '}{s.subrecipe.weight_unit}
                            </div>
                        }
                        {s.quantity_type == 'quantity' && s.subrecipe  && !(s.subrecipe.weight_unit_value) &&
                            <div>
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'loss' &&
                                   <FormattedNumber value={s.quantity_value/(1 - Number(s.subrecipe.weight_variation_value))}/>}
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'gain' &&
                                   <FormattedNumber value={s.quantity_value/(1 + Number(s.subrecipe.weight_variation_value))}/>}
                                {!s.subrecipe.weight_variation_active &&
                                <FormattedNumber value={s.quantity_value}/>}
                                {' '}{s.quantity_unit}
                            </div>}
                         {s.quantity_type != 'quantity' && 
                            <div>
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'loss' &&
                                   <FormattedNumber value={s.quantity_value/(1 - Number(s.subrecipe.weight_variation_value))}/>}
                                {s.subrecipe.weight_variation_active && s.subrecipe.weight_variation_type == 'gain' &&
                                   <FormattedNumber value={s.quantity_value/(1 + Number(s.subrecipe.weight_variation_value))}/>}
                                {!s.subrecipe.weight_variation_active &&
                                <FormattedNumber value={s.quantity_value}/>}
                                {' '}{s.quantity_unit}
                            </div>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                <FormattedNumber value={s.item_cost} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                               {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'><FormattedNumber value={(s.item_cost/selected.cost_total)} maximumFractionDigits="2" style="percent"/></Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                           <Popup trigger=
/*                                     {<Button basic  className="ui icon button circular" 
                                             onClick={(event)=>this.seeRecipe(event,s.subrecipe.id)}>
                                            <i className="eye icon"></i>
                                     </Button>} */
/*                                     {<Icon color='blue' id={index} name='folder open outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.seeRecipe(event,s.subrecipe.id)}/>
                                     } */
                                    {<BrowserRouter forceRefresh={true}>
                                     <Link to={'/recipedetails/' + s.subrecipe.id } style={{'color': 'black'}}>
                                         <Icon color='blue' id={index} name='folder open outline' style={{cursor:'pointer'}} size='large'/>
                                    </Link>
                                     </BrowserRouter>}
                                    //header='See Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.view.text"
                                                                     defaultMessage='View'/>}
                                    inverted/>
                            <Popup trigger=
/*                                     {<Button basic className="ui icon button circular" 
                                            onClick={(event)=>this.editRecipe(event,s.subrecipe.id)}>
                                            <i className="edit icon"></i>
                                    </Button>} */
                                     {<Icon color='blue' id={index} name='edit outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.editRecipe(event,s.subrecipe.id)}/>
                                     }                                    
                                    //header='Edit Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.edit.text"
                                                                    defaultMessage='Edit'/>}
                                    inverted/>
{/*                            <Popup trigger=
                                    {<Button basic id={index} className="ui small icon button" 
                                             onClick={(event) => this.deleteClickHandlerRecipes(event,index)}>
                                             <i className="trash icon"></i>
                                     </Button>}
                                    //header='Delete Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.delete.text"
                                                               defaultMessage='Delete'/>}
                                    inverted/> */}
{/*                             <Button id={index} className="ui small icon button" 
                                onClick={(event) => this.deleteRecipe(event,recipe.id)}>
                                <i className="trash icon"></i>
                            </Button> */}
{/*                             <Modal id={index}
                                   open={this.state.activeModalRecipes === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash' content={<FormattedMessage id="recipedetails.modal.recipe.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="recipedetails.modal.recipe.deletion.message"
                                                         defaultMessage='Really sure to delete the subrecipe "{name}" from recipe?'
                                                         values={{name:s.subrecipe.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                         onClick={this.hideModal}>
                                        <FormattedMessage id="recipedetails.modal.recipe.deletion.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen} 
                                        //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="recipedetails.modal.recipe.deletion.button.yes"
                                                                   defaultMessage='Yes'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteRecipe(event,s.subrecipe.id)}/>
                                    </Modal.Actions>
                            </Modal>  */}
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addIngredientsRows(selected){
        const {currency} = this.state;
        let initial_ingredients =  selected.ingredients;
        let sorted_ingredients  = initial_ingredients.sort(function (a, b) {
            return a.ingredient.name.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase() > b.ingredient.name.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase();
        })
        return sorted_ingredients.map((i,index) => {
                return (
                    <Table.Row key={index}>
                        <Table.Cell width={2}><Icon name='shopping basket' color='teal'/>
                                <FormattedMessage id="recipedetails.ingredient.row.elem.ingredient"
                                                  defaultMessage='Ingredient'/>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='left'>
                            <a href="#" 
                                style={{'color': 'black'}}
                                onClick={(event) => this.seeIngredient(event,i.ingredient.id)}>
                                {i.ingredient && i.ingredient.name}
                            </a>
                            {i.ingredient && i.ingredient.weight_variation_active &&
                                <Popup trigger={<Button compact floated='right' basic 
                                                        size='tiny'
                                                        color='teal'>
                                                        {(i.ingredient.weight_variation_type == 'loss') && ' - '}
                                                        {(i.ingredient.weight_variation_type == 'gain') && ' + '}
                                                        {Number(i.ingredient.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                    </Button>}
                                        content={<FormattedMessage id="recipedetails.popup.view.weight.info.text"
                                                                    defaultMessage='Variation applied'/>}
                                        inverted
                                        size='tiny'
                                        position='bottom left'/>
                            }
                            {i.ingredient.default_supplier  && i.ingredient.supplier &&
                             <p style={{'font-style': 'italic', 'font-size': '11px','color': 'orange'}}>{i.ingredient.supplier.name}</p>}
                             {i.ingredient.default_supplier  && !i.ingredient.supplier &&
                             <p style={{'font-style': 'italic', 'font-size': '11px','color': 'orange'}}>
                                <FormattedMessage id="recipedetails.ingredient.nosupplier.text"
                                                  defaultMessage='No supplier'/></p>}
                            {!i.ingredient.default_supplier  && i.ingredient.extra_suppliers && i.ingredient.extra_suppliers.length > 0 &&
                             <p style={{'font-style': 'italic', 'font-size': '11px','color': 'orange'}}>{this.getActiveExtraSupplier(i.ingredient)}</p>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'><FormattedNumber value={i.quantity_value}/>{' '}{i.quantity_unit}</Table.Cell>
                        {(i.ingredient &&  !i.ingredient.weight_variation_active) &&
                        <Table.Cell width={2} textAlign='center'><FormattedNumber value={i.quantity_value}/>{' '}{i.quantity_unit}</Table.Cell>}
                        {(i.ingredient &&  i.ingredient.weight_variation_active && i.ingredient.weight_variation_type == 'loss') &&
                        <Table.Cell width={2} textAlign='center'><FormattedNumber value={i.quantity_value/(1 - Number(i.ingredient.weight_variation_value))}/>{' '}{i.quantity_unit}
                        </Table.Cell>}
                        {i.ingredient &&  i.ingredient.weight_variation_active && (i.ingredient.weight_variation_type == 'gain') &&
                        <Table.Cell width={2} textAlign='center'><FormattedNumber value={i.quantity_value/(1 + Number(i.ingredient.weight_variation_value))}/>{' '}{i.quantity_unit}
                        </Table.Cell>}
{/*                         {i.ingredient &&  i.ingredient.weight_variation_active && 
                            <Table.Cell width={2} textAlign='center'>
                                <FormattedNumber value={i.quantity_value}/>{' '}{i.quantity_unit}
                                <div style={{'color': 'green'}}>
                                {(i.ingredient.weight_variation_type == 'loss') && ' (- '}
                                {(i.ingredient.weight_variation_type == 'gain') && ' (+ '}
                                {Number(i.ingredient.weight_variation_value*100).toFixed(0)+'%)'} </div>
                            </Table.Cell>} */}
                        <Table.Cell width={2} textAlign='right'>
                                <FormattedNumber value={i.item_cost}  style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                {/* <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'><FormattedNumber value={(i.item_cost/selected.cost_total)} maximumFractionDigits="2" style="percent"/></Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                            <Popup trigger=
/*                                     {<Button basic className="ui icon button circular" 
                                             onClick={(event)=>this.seeIngredient(event,i.ingredient.id)}>
                                            <i className="eye icon"></i>
                                     </Button>} */
                                     {<Icon color='blue' id={index} name='folder open outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.seeIngredient(event,i.ingredient.id)}/>
                                    }
                                    //header='See Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.view.text"
                                                                defaultMessage='View'/>}
                                    inverted/>
                            <Popup trigger=
/*                                     {<Button basic className="ui icon button circular" 
                                             onClick={(event) => this.editIngredient(event,i.ingredient.id)}>
                                             <i className="edit icon"></i>
                                    </Button>} */
                                    {<Icon color='blue' id={index} name='edit outline' style={{cursor:'pointer'}} size='large'
                                            onClick={(event) => this.editIngredient(event,i.ingredient.id)}/>
                                    }    
                                    //header='Edit Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.edit.text"
                                                                    defaultMessage='Edit'/>}
                                    inverted/>
{/*                             <Popup trigger=
                                    {<Button basic id={index} className="ui small icon button" 
                                             onClick={(event) => this.deleteClickHandler(event,index)}>
                                             <i className="trash icon"></i>
                                    </Button>}
                                    //header='Delete Recipe details'
                                    content={<FormattedMessage id="recipedetails.popup.delete.text"
                                                               defaultMessage='Delete'/>}
                                    inverted/> */}
{/*                             <Modal id={index}
                                   open={this.state.activeModal === index}
                                   dimmer='default' 
                                   style={styles.Modal} 
                                   size='tiny' 
                                   onClose={this.hideModal}>
                                <Header icon='trash' content={<FormattedMessage id="recipedetails.modal.ingredient.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="recipedetails.modal.ingredient.deletion.message"
                                                         defaultMessage='Really sure to delete subingredient "{name}" from recipe ?'
                                                         values={{name:i.ingredient && i.ingredient.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                            onClick={this.hideModal}>
                                            <FormattedMessage id="recipedetails.modal.ingredient.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen} 
                                        //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="recipedetails.modal.ingredient.deletion.button.yes"
                                                                   defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) => this.deleteIngredient(event,i.ingredient.id)}/>
                                    </Modal.Actions>
                            </Modal> */}
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    localizeAllergensNames(allergens_list){
        const { intl} = this.props;
        allergens_list = allergens_list.map((a,index) => {
            if (a == "gluten") return intl.formatMessage(allergens.gluten);
            if (a == "crustaceans") return intl.formatMessage(allergens.crustaceans);
            if (a == "egg") return intl.formatMessage(allergens.egg);
            if (a == "fish") return intl.formatMessage(allergens.fish);
            if (a == "peanuts") return intl.formatMessage(allergens.peanuts);
            if (a == "soya") return intl.formatMessage(allergens.soya);
            if (a == "milk") return intl.formatMessage(allergens.milk);
            if (a == "nuts") return intl.formatMessage(allergens.nuts);
            if (a == "egg") return intl.formatMessage(allergens.egg);
            if (a == "celery") return intl.formatMessage(allergens.celery);
            if (a == "mustard") return intl.formatMessage(allergens.mustard);
            if (a == "sesame") return intl.formatMessage(allergens.sesame);
            if (a == "sulphites") return intl.formatMessage(allergens.sulphites);
            if (a == "lupin") return intl.formatMessage(allergens.lupin);
            if (a == "molluscs") return intl.formatMessage(allergens.molluscs);
        });
        return allergens_list;  
    }

    addAllergensList(allergens){
        const { isTablet} = this.state;
        let i18n_allergens = this.localizeAllergensNames(allergens)
        return i18n_allergens.map((a,index) => {
                if (!isTablet){
                    return (<Label size= 'small' basic>{a}</Label>);
                }else{
                    return (<Label size='tiny'  basic >{a}{' '}</Label>);
                }
                
        });
    } 
     
    toggleMargin = (e) => {
        e.preventDefault();
        this.setState({ toggled: !this.state.toggled });
    }

    onSellingPriceChange = (e) => {
        e.preventDefault();
        let selling = e.target.value;
        this.setState({ sellingprice: selling});
    }

    onVATPriceChange = (e,data) => {
        e.preventDefault();
        //console.log(data.value)
        this.setState({ sellingVAT: data.value});

    }

    onQuantityChange = (e) => {
        e.preventDefault();
        let quantityparts = e.target.value;
        this.setState({ quantityparts: quantityparts});
        //console.log(quantityparts)
    }


/*     computeMarginValues(){
        const {recipes} = this.props;
        const {sellingprice,quantityparts, sellingVAT} = this.state;
        let selling = sellingprice;
        let selling_no_VAT = 0;
        
        if (recipes && recipes.selected){

            selling_no_VAT = sellingprice / (1 + sellingVAT)
            selling = selling_no_VAT

            if (Number(quantityparts) > Number(1)) {
                selling = selling_no_VAT/quantityparts;
            }

            if (selling > 0){
                let grossmargin = ((selling - recipes.selected.cost_unit)/selling).toFixed(3);
                this.setState({ grossmargin: grossmargin});
                
                let foodcost = (recipes.selected.cost_unit/selling).toFixed(3);
                this.setState({ foodcost: foodcost });
        
                let markup = ((selling - recipes.selected.cost_unit)/recipes.selected.cost_unit).toFixed(3);
                this.setState({ markup: markup });
            }
        }


    } */


/*      handleChangeImage(event) {
      
      const {selected} = this.state;
      const { dispatch} = this.props;


      if (selected  && event.target.files && event.target.files[0]) {

       let current_file = event.target.files[0];
       let data = new FormData();  
       
       if(current_file.size > 2097152){
              const title = <FormattedMessage id="recipedetails.image.update.error.title"
                                             defaultMessage='Image Error'/>
              const content = <FormattedMessage id="recipedetails.image.update.error.message"
                                                defaultMessage='File too big (max size 2Mb)'/>
              dispatch(alertActions.error({title:'prepareinstructions.image.update.error.title',
                                          content:'recipedetails.image.update.error.message'}));
            return;
        };


        data.append('filetoupload', current_file,current_file.name);
//         const reader = new FileReader()
        reader.addEventListener("loadend", function() {
            console.log(reader.result); // will print out file content
        });
        reader.readAsText(current_file);//
        //console.log(current_file) 
        this.setState({
          file: URL.createObjectURL(event.target.files[0])
        });
        dispatch(recipesActions.updateImage(selected.id,data));
      }
    } */


    onDropAccepted(acceptedFiles) {
        const {selected} = this.state;
        const { dispatch} = this.props;

        if (selected  && acceptedFiles[0]) {
         let current_file = acceptedFiles[0];
         let data = new FormData();  
          data.append('filetoupload', current_file,current_file.name.replace(/ /g, '-'));
          this.setState({file: URL.createObjectURL(acceptedFiles[0])});
          dispatch(recipesActions.updateImage(selected.id,data));
        }
    }
  
    onDropRejected(rejectedFiles) {
       const {dispatch} = this.props;
       /*  this.setState({rejectedFile:rejectedFiles[0]});*/
    
      dispatch(alertActions.error({title: 'recipedetails.file.error.title',
                                   content:'recipedetails.file.size.error.content'})); 
    }
  
    triggerInputFile(event){
        const { dispatch} = this.props;
        const {file,selected,active_role} = this.state;
        if (file){
            event.stopPropagation()
            if (active_role != GUEST){
                this.setState({file:null});

                if (selected){
                    dispatch(recipesActions.deleteImage(selected.id));
                }
            }else{
                dispatch(alertActions.error({title: 'recipedetails.file.permission.error.content',
                                            content:'recipedetails.file.size.error.content'})); 
            }
           

        }
/*         else{
            this.fileUpload.current.click();
        } */
    } 

/*     triggerInputFile(){
        const { dispatch} = this.props;
        const {file,selected} = this.state;
        if (file){
            this.setState({file:null});
            if (selected){
                dispatch(recipesActions.deleteImage(selected.id));
            }
        }
        else{
            this.fileUpload.current.click();
        }
    }  */


    assertPriceField(){
        let sellingprice = trim(this.state.sellingprice).replace(/,/g, '.')
        if ((isFloat(sellingprice) || isNumeric(sellingprice))
          && (this.state.sellingprice != "")
          && (Math.trunc(this.state.sellingprice).toString().length)<7)
          return true;
        return false;
      }

    toggleAndSave = (e) => {
        e.preventDefault();
        
        const { quantityparts,sellingprice,grossmargin,foodcost,sellingVAT,markup,selected} = this.state;
        const { dispatch} = this.props;

        if (!isInteger(Number(quantityparts)) || Number(quantityparts)< Number(1)){
            //toaster_error("Submit Error","Quantity part is superior or equal to 1")
/*             toaster_error(<FormattedMessage id="recipedetails.margin.save.error.quantityparts.title"
                                            defaultMessage='Submit Error'/>,
                          <FormattedMessage id="recipedetails.margin.save.error.quantityparts.message"
                                            defaultMessage='Quantity part is superior or equal to 1'/>) */
             const title = <FormattedMessage id="recipedetails.margin.save.error.quantityparts.title"
                                            defaultMessage='Submit Error'/>
             const content = <FormattedMessage id="recipedetails.margin.save.error.quantityparts.message"
                                               defaultMessage='Quantity part is superior or equal to 1'/>
             dispatch(alertActions.error({title:'recipedetails.margin.save.error.quantityparts.title',
                                         content:'recipedetails.margin.save.error.quantityparts.message'}));            
             
             return;
        }

        if (!this.assertPriceField()){
            //toaster_error('Submit Error','selling price field should be numeric (below 10 000)');
/*             toaster_error(<FormattedMessage id="recipedetails.margin.save.error.pricefield.title"
                                            defaultMessage='Submit Error'/>,
                            <FormattedMessage id="recipedetails.margin.save.error.pricefield.message"
                                          defaultMessage='selling price field should be numeric (below 10 000)'/>) */
           const title = <FormattedMessage id="recipedetails.margin.save.error.pricefield.title"
                                          defaultMessage='Submit Error'/>
           const content = <FormattedMessage id="recipedetails.margin.save.error.pricefield.message"
                                             defaultMessage='selling price field should be numeric (below 10 000)'/>
           dispatch(alertActions.error({title:'recipedetails.margin.save.error.pricefield.title',
                                       content:'recipedetails.margin.save.error.pricefield.message'}));   
            return;
          }
      

        //this.computeMarginValues();
        this.setState({ toggled: !this.state.toggled });

         if (selected) {
/*             selected.quantity_parts = quantityparts;
            selected.selling_price = Number(trim(sellingprice).replace(/,/g, '.')).toFixed(3);
            selected.gross_margin = Number(grossmargin).toFixed(3);
            selected.food_cost = Number(foodcost).toFixed(3);
            selected.markup = Number(markup).toFixed(3);
            selected.selling_vat = Number(sellingVAT).toFixed(3);  */
            const recipe = {
                //mandatory
                name:trim(selected.name),
                //for allergens compute
                ingredients:selected.ingredients,
                subrecipes:selected.subrecipes,
                quantity_parts:quantityparts,
                selling_price: Number(trim(sellingprice).replace(/,/g, '.')).toFixed(3),
                gross_margin:  Number(grossmargin).toFixed(3),
                food_cost:Number(foodcost).toFixed(3),
                markup:Number(markup).toFixed(3),
                selling_vat: Number(sellingVAT).toFixed(3),
            }
            dispatch(recipesActions.update(selected.id,recipe));
         }
    }

    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }


    onUpdateRecipeLabel = (e) => {
        const {selected,label_text,toggleEditionMode,active_role} = this.state;
        const {dispatch} = this.props;
        this.setState({ submitted: true });

        if (active_role != GUEST){
            if (selected){
                dispatch(alertActions.clear());
                dispatch(recipesActions.updateLabelTag(selected.id,{label_tag:label_text,
                                                                    manual_label: toggleEditionMode
                }));
        }
        }else{
            dispatch(alertActions.error({title: 'recipedetails.file.permission.error.content',
                                         content:'recipedetails.file.permission.error.content'})); 
            if (selected){
                    this.setState({toggleEditionMode:selected.manual_label});
            }
        }
        this.setState({ activeModalLabel: false});
    }

    extractYieldType(recipe){
        let title = 'Par portion'
    
        if (unitWeight.includes(recipe.yield_units)){
          title = '100g'
        }else if (unitVolume.includes(recipe.yield_units))
        {
          title = '100ml'
        }else{
            //Case recipe (use 100g or 100ml standard)
            if (unitWeight.includes(recipe.weight_unit)){
              title = '100g'
            }else if (unitVolume.includes(recipe.weight_unit))
            {
              title = '100ml'
            }
        }
        
        return title;
    }
    
    computeFactor(recipe){
        let factor = 1;
        
        if (!recipe)
          return factor;
    
        if (unitWeight.includes(recipe.yield_units))
        {
            if (grams.includes(recipe.yield_units)){
              factor = 1/(recipe.yield_count /100) ;
            }
            if (mgrams.includes(recipe.yield_units)){
              factor = 1/(recipe.yield_count / 100000);
            }
            if (kgrams.includes(recipe.yield_units)){
              factor = 1/(recipe.yield_count * 10);
            }
            if (pounds.includes(recipe.yield_units)){ 
              factor = 1/(recipe.yield_count * 4.5359);
            }
            if (ounces.includes(recipe.yield_units)){
              factor = 1/(recipe.yield_count / 3.5274 );
            }
    
        }else if (unitVolume.includes(recipe.yield_units))
        {
          if (liters.includes(recipe.yield_units)){
            factor = 1/(recipe.yield_count*10);
          }
          if ( mliters.includes(recipe.yield_units)){
            factor = 1/(recipe.yield_count/100);
          }
          if ( cliters.includes(recipe.yield_units)){
            factor = 1/(recipe.yield_count/10);
          }
          if ( flounces.includes(recipe.yield_units)){
            factor = 1/(recipe.yield_count/3.3814);
          }
        }else{
          //Case recipe (use yield or 100g/100ml)
    
          //Case recipe (use 100g or 100ml standard)
          if (unitWeight.includes(recipe.weight_unit))
          {
            if (grams.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value /100) ;
            }
            if (mgrams.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value / 100000);
            }
            if (kgrams.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value * 10);
            }
            if (pounds.includes(recipe.weight_unit)){ 
              factor = 1/(recipe.weight_total_value * 4.5359);
            }
            if (ounces.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value / 3.5274 );
            }
    
          }else if (unitVolume.includes(recipe.weight_unit))
          {
            if (liters.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value*10);
            }
            if ( mliters.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value/100);
            }
            if ( cliters.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value/10);
            }
            if ( flounces.includes(recipe.weight_unit)){
              factor = 1/(recipe.weight_total_value/3.3814);
            }
          }else{
             factor = 1/recipe.yield_count;
          }
    
        }
        if (!isFinite(factor)){
            factor = 0
        }

        return factor;
    }

    extractPayloadPerYield(field,unit,recipe,rounding){
        let value = field.split(unit)[0] 
        //console.log(value)
        let factor = this.computeFactor(recipe);
        //console.log(factor)
        if (value>0 && recipe.yield_count > 0){
          return Number(value*factor).toFixed(rounding) + unit
        }
        return 0;
    }

    htmlToImageConvert = () =>  {
        var node = document.getElementById('label-node');
    
        if (node){
          htmlToImage.toPng(node)
            .then(function (dataUrl) {
              var link = document.createElement('a');
              link.download = 'label-ingredients.png';
              link.href = dataUrl;
              link.click();
            });
        }
      }
    

    labelClickHandler (event) {
        event.preventDefault();
        const { selected } = this.props; 
        //if (this.state.toggleEditionMode){
            this.setState({ activeModalLabel: true});
            this.setState({ label_text: selected.label_text });
        //}
    };

    onLabelUpdate = (e) => {
        e.preventDefault();
        this.setState({ label_text: e.target.value });
    }

    toggleEditionMode = (e, data) => {
      //console.log(data);
    const {customer} = this.props;
    let membership = null;
    if (customer){
        membership = customer.membership;
    } 
    if (membership && membership.membership_type=="Entreprise"){
        this.setState({toggleEditionMode:data.checked});
    }else{
        this.setState({ activeModalPremium: true});
    }

    }

    onDaysValidity = (e,item) => {
        this.setState({ daysValidity: e.target.value});
    }

    assertDaysField(days){
        if (isInteger(days) 
          && (days != "")
          && Math.trunc(days).toString().length<4)
          return true;
        return false;
      
    }


    onIncreaseDecreaseQuantity(event,recipe,increase) {
        event.preventDefault();
        const {daysValidity,selected,active_role} = this.state;
        const {dispatch} = this.props;
        //let days = Number(trim(daysValidity.toString()).replace(/,/g, '.')).toFixed(0);
        let days = parseInt(daysValidity);

        if (increase){
            days++;
        }else{
            if (days > 0) days--;
        } 
        const title = <FormattedMessage id="ingredientdetails.dlc.check.error.title"
                                        defaultMessage='Validity Update Error'/>
  
        if (this.assertDaysField(days) /* && days!=selected.days_validity */){
  
            if (active_role!= GUEST){
              selected.daysValidity = days
              this.setState({selected: selected});
              this.setState({ daysValidity: days});
              clearTimeout(this.getDataTimeout); 
              this.getDataTimeout =  setTimeout(() => {dispatch(recipesActions.updateDaysValidity(recipe.id,days))}, 2000);     
            }else{
                const content =  <FormattedMessage id="ingredientdetails.dlc.privilege.error.message"
                                                    defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'ingredientdetails.dlc.privilege.error.message',
                                             content:'ingredientdetails.dlc.check.error.title'}));  
            }
        }else{
            if (Math.trunc(days).toString().length>=3){
                const content =  <FormattedMessage id="ingredientdetails.dlc.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 4 digits)'/>
                 dispatch(alertActions.error({title:'ingredientdetails.dlc.check.error.title',
                                             content:'ingredientdetails.dlc.check.error.message'}));   
             }
             this.setState({ daysValidity:selected.days_validity});
        }
    }


    updateValidityOnEnter (event,recipe) {
        const {daysValidity,selected,active_role} = this.state;
        const {dispatch} = this.props;
        const title = <FormattedMessage id="ingredientdetails.dlc.check.error.title"
                                        defaultMessage='Validity Update Error'/>
  
        if (event.key === 'Enter') {
                let days = parseInt(daysValidity)
                if (this.assertDaysField(days) && days!=selected.days_validity){
                    if (active_role!= GUEST){
                      selected.days_validity = days
                      this.setState({selected: selected});
                      clearTimeout(this.getDataTimeout); 
                      this.getDataTimeout =  setTimeout(() => {dispatch(recipesActions.updateDaysValidity(recipe.id,days))}, 2000);    
                    }else{
                        const content =  <FormattedMessage id="ingredientdetails.dlc.privilege.error.message"
                                                            defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'ingredientdetails.dlc.privilege.error.message',
                                                    content:'ingredientdetails.dlc.check.error.title'}));  
                    }
                }else{
                    if (Math.trunc(days).toString().length>=9){
                       const content =  <FormattedMessage id="ingredientdetails.dlc.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 4 digits)'/>
                        dispatch(alertActions.error({title:'ingredientdetails.dlc.check.error.title',
                                                    content:'ingredientdetails.dlc.check.error.message'}));   
                    }
                    this.setState({ daysValidity:selected.days_validity});
                }
        }
      }
      

      updateValidityOnBlur (event,recipe) {
        const {daysValidity,selected,active_role} = this.state;
        const {dispatch} = this.props;
        const title = <FormattedMessage id="ingredientdetails.dlc.check.error.title"
                                        defaultMessage='Validity Update Error'/>
  
        let days = parseInt(daysValidity)
        if (this.assertDaysField(days) && days!=selected.days_validity){
                    if (active_role!= GUEST){
                      selected.days_validity = days
                      this.setState({selected: selected});
                      clearTimeout(this.getDataTimeout); 
                      this.getDataTimeout =  setTimeout(() => { dispatch(recipesActions.updateDaysValidity(recipe.id,days))}, 2000);    
                    }else{
                        const content =  <FormattedMessage id="ingredientdetails.dlc.privilege.error.message"
                                                            defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'ingredientdetails.dlc.privilege.error.message',
                                                    content:'ingredientdetails.dlc.check.error.title'}));  
                    }
        }else{
                    if (Math.trunc(days).toString().length>=9){
                       const content =  <FormattedMessage id="ingredientdetails.dlc.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 4 digits)'/>
                        dispatch(alertActions.error({title:'ingredientdetails.dlc.check.error.title',
                                                    content:'ingredientdetails.dlc.check.error.message'}));   
                    }
                    this.setState({ daysValidity:selected.days_validity});
        }
    }


    render() { 
        const { alert,loading,dispatch,intl,selected,updating,customer} = this.props;
        const { chart_data,isMobile,isTablet,toggled,currency,allergens,metrics,submitted,isSubRecipe,
                 typeVariation,valueVariation,/*files,*/file,active_role,toggleEditionMode,label_text,daysValidity} = this.state;

        let buttons = [];
        buttons.push(<FloatingMenuItem label=""  icon={<Icon name='edit'/>} action={this.editCurrentRecipe.bind(this)} key="m"/>); 
        let selling_no_VAT = 0
        let relevant_cost_unit = 0
        let quantity_parts = 1
        let membership = null;

        if (customer){
            membership = customer.membership;
        }

        if (selected){
            if (typeYield(selected.yield_units) == "quantity"){
                relevant_cost_unit = Number(selected.cost_unit)
            }else{
                relevant_cost_unit = Number(selected.cost_total)
            }
            selling_no_VAT = Number(selected.selling_price) / (1 + Number(selected.selling_vat))
            
            if (Number(selected.quantity_parts)>0){
                quantity_parts = Number(selected.quantity_parts)
            }
            selling_no_VAT = Number(selling_no_VAT)/Number(quantity_parts)
        }

        let factor = 1
        var energyKJ = ""
        if (metrics){
            energyKJ = metrics.calories.split("kcal")
            energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)
            factor = this.computeFactor(selected);
        }
        let tax = selected && Number(selected.selling_vat)

        return ( 
            <div >
            <Helmet>
                  <meta name="robots" content="noindex"/>
            </Helmet>
             { loading &&
                   <SideLeftUncoverRecipe >      
                        <Dimmer active={(loading)} blurring inverted>
                                <Loader inverted content='Loading' />
                        </Dimmer> 
                    </SideLeftUncoverRecipe>
             } 
             {!loading && 
              <SideLeftUncoverRecipe  id={this.props.match.params.recipeId}>
                <Grid stackable style={styles.FontLato12}>
                    {alert.message  && toaster_dispatch(dispatch,alert,intl)}
                    <Grid.Row columns={1}>
                            <Breadcrumb style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="recipedetails.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="recipedetails.link.recipes"
                                                              defaultMessage='Recipes'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active>{selected && selected.name}</Breadcrumb.Section>
                            </Breadcrumb>
                     </Grid.Row>
                     <Grid.Row columns={1}>
                        <Grid.Column width={8}>
                            <Header as='h2' >
                                {selected && selected.name}
                            </Header>
                            <span style={{'color':'#b5b1b1',marginLeft:'1em','font-size': '16px'}}>
                                <i>{selected && selected.ref_internal}</i>
                            </span>
                        </Grid.Column >
                    </Grid.Row> 
                    {/* <Grid.Row columns={1}/> */}
                    <Grid.Row columns={1} textAlign='left'>
                        <Grid.Column /*width='14'*/  mobile={8} tablet={5} computer={4}>
{/*                              <div>
                                {file && 
                                    <div className="image-div">
                                        <img src={file}
                                             style={{display:'block',width:'320px','height':'220px','object-fit': 'scale-down' }}/>

                                         <Icon name='delete' className="hidden_img" style={{cursor:'pointer'}} size='big' color='blue' onClick={this.triggerInputFile}/>
                                    </div>
                                 }
                                 {!file && 
                                    <div className="image-div">
                                        <img src={DEFAULT_RECIPE_IMAGE}
                                            style={{display:'block',width:'320px','height':'220px','object-fit': 'scale-down' }}/>
                                         <Icon name='upload' className="hidden_img" style={{cursor:'pointer'}} size='big' color='blue' onClick={this.triggerInputFile}/>
                                    </div>
                                 }
                                 <div class="overlay">
                                      <input  ref={this.fileUpload}
                                              type="file" 
                                              style={{width: '0.1px',height: '0.1px',opacity: '0',overflow: 'hidden',position: 'absolute','z-index': '-1'}} 
                                              transparent
                                              accept="image/*"
                                              onChange={this.handleChangeImage}/>
                                </div>

                            </div> */}
                            <Dropzone onDropAccepted={this.onDropAccepted.bind(this)}
                                      onDropRejected={this.onDropRejected.bind(this)}
                                      multiple = {false}
                                      maxSize={5242880} //5MB
                                      accept="image/*"
                                      disabled = {active_role==GUEST}
                                  >
                                    {({getRootProps, getInputProps}) => (
                                          <section {...getRootProps()}
                                                    style={{  'height': '220px',
                                                              'width': '320px',
                                                              'backgroundColor': '#f5f5f5',
                                                              'overflow': 'auto',
                                                              'text-align' : 'center'}}>
                                              <div>
                                                  <input {...getInputProps()}/>
                                                 {!file && <div className="image-div-ingredient">
                                                          {active_role==GUEST && 
                                                             <Popup content={<FormattedMessage id="recipedetails.image.popup.disabled.text" 
                                                                                            defaultMessage= "No Right privilege"/>}
                                                                position='bottom center'
                                                                trigger={
                                                                    <img src={DEFAULT_RECIPE_IMAGE}
                                                                        style={{display:'block',width:'320px','height':'220px', cursor:'pointer'/* ,'object-fit': 'scale-down' */ }}/>
                                                                }/>
                                                          }
                                                          {active_role!=GUEST && 
                                                          <Popup content={<FormattedMessage id="recipedetails.image.popup.text" 
                                                                                      defaultMessage= "Click or Drag'n'drop your image file"/>}
                                                            position='bottom center'
                                                            trigger={
                                                              <img src={DEFAULT_RECIPE_IMAGE}
                                                                 style={{display:'block',width:'320px','height':'220px', cursor:'pointer'/* ,'object-fit': 'scale-down' */ }}/>
                                                            }/>}
                                                 </div>}
                                                  {file &&
                                                  <div className="image-div-ingredient" style={{'text-align' : 'center',width:'100%'}}>
                                                      <img src={file} 
                                                           style={{display:'block',width:'auto','height':'auto',"max-width": "320px", "max-height":"220px","margin": "0 auto",
                                                           'text-align' : 'center','justify-content': 'center;' }}/>
                                                     <Button className="hidden_button" style={{cursor:'pointer'}} color='teal' onClick={(event)=>this.triggerInputFile(event)}>
                                                          <FormattedMessage id="recipedetails.image.button.text" 
                                                                            defaultMessage= "Remove"/></Button>
                                                  </div>}
                                            </div>
                                        </section>
                                      )}
                                </Dropzone>
                        </Grid.Column>
                    </Grid.Row>
                   {/*  <Grid.Row columns={1}/> */}
                    <Grid.Row columns={4} textAlign='right' style={{paddingLeft:'1em'}}>
                                 {!isTablet && <Grid.Column mobile={1} tablet={1} computer={2}/>}
                                 <Grid.Column mobile={4} tablet={4} computer={3}>
                                 {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}> 
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.cost.content.header"
                                                                defaultMessage='Cost summary'/>
                                            </Card.Header>
                                        </Card.Content>                           
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_moneybag}/>
                                        {selected && !isTablet &&
                                        <div>
                                           {/*  <br/>  */}
                                           {isQuantity(selected.yield_units) &&
                                             <p style={styles.FontLato14Grey}>
                                                <FormattedNumber value={selected.cost_unit} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency} />
{/*                                                 {' '}
                                                <FormattedMessage id="recipedetails.card.cost.content.line1"
                                                                defaultMessage='/ unit'/> */}
                                                {' / '}
                                                {selected.yield_units}
                                            {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/>  */}
                                            </p>}
                                            {!isQuantity(selected.yield_units) &&
                                             <p style={styles.FontLato14Grey}>
                                                <FormattedNumber value={selected.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency} />
                                                {' / '}
                                                {selected.yield_units}
                                            {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/>  */}
                                            </p>}
                                            <p style={styles.FontLato14Grey}>
                                                    <strong>
                                                        <FormattedNumber value={selected.cost_total} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                    {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                                                    </strong>
                                                    {' '}<FormattedMessage id="recipedetails.card.cost.content.line2"
                                                                defaultMessage='/ recipe'/>
                                            </p>
                                        </div> }
                                        {selected && isTablet &&
                                        <div>
                                           {/*  <br/>  */}
                                           {isQuantity(selected.yield_units) && 
                                             <p style={styles.FontLato12}>
                                                <FormattedNumber value={selected.cost_unit} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency} />
{/*                                                 {' '}
                                                <FormattedMessage id="recipedetails.card.cost.content.line1"
                                                                defaultMessage='/ unit'/> */}
                                                {' / '}
                                                {selected.yield_units}
                                            {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                                            </p>}
                                            {!isQuantity(selected.yield_units) && 
                                             <p style={styles.FontLato12}>
                                                <FormattedNumber value={selected.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency} />{' '}
                                                {' / '}
                                                {selected.yield_units}
                                            {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                                            </p>}
                                            <p style={styles.FontLato12}>
                                                    <strong>
                                                        <FormattedNumber value={selected.cost_total} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                    {/*  <FormattedMessage id='currency.symbol' defaultMessage='$'/> */}
                                                    </strong>{' '}<FormattedMessage id="recipedetails.card.cost.content.line2"
                                                                defaultMessage='/ recipe'/>
                                            </p>
                                        </div> }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column mobile={4} tablet={4} computer={3}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.yield.content.header"
                                                                defaultMessage='Yield summary'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_selection}/>
                                        {selected && !isTablet &&
                                        <div>   
                                        <p style={styles.FontLato14Grey}><FormattedNumber value={selected.yield_count}/>{'  '}{selected.yield_units}</p>
                                        {isQuantity(selected.yield_units) && (Number(selected.weight_unit_value)>0) && (Number(selected.weight_unit_value)>=1) &&
                                                 <p style={styles.FontLato14Grey}>
                                                            <FormattedNumber value={Number(selected.weight_unit_value).toFixed(3)} />{' '}{selected.weight_unit}
                                                            {' '}<FormattedMessage id="recipedetails.card.yield.content.line1"
                                                                      defaultMessage='each'/>
                                                </p>}
                                         {isQuantity(selected.yield_units) && (Number(selected.weight_unit_value)>0) && (Number(selected.weight_unit_value)<1) && (selected.weight_unit === 'kg') &&
                                                 <p style={styles.FontLato14Grey}>
                                                            <FormattedNumber value={Number(selected.weight_unit_value).toFixed(3) * 1000} />{' g '}
                                                            {' '}<FormattedMessage id="recipedetails.card.yield.content.line1"
                                                                      defaultMessage='each'/>
                                                </p>}
                                        </div> 
                                        } 
                                        {selected && isTablet &&
                                        <div>   
                                        <p style={styles.FontLato12}>{selected.yield_count}{'  '}{selected.yield_units}</p>
                                        {isQuantity(selected.yield_units) && (Number(selected.weight_unit_value)>0) && (Number(selected.weight_unit_value)>=1) &&
                                                 <p style={styles.FontLato12}>
                                                            <FormattedNumber value={Number(selected.weight_unit_value).toFixed(3)} />{' '}{selected.weight_unit}
                                                            {' '}<FormattedMessage id="recipedetails.card.yield.content.line1"
                                                                      defaultMessage='each'/>
                                                </p>}
                                        {isQuantity(selected.yield_units) && (Number(selected.weight_unit_value)>0) && (Number(selected.weight_unit_value)<1) && (selected.weight_unit === 'kg') &&
                                                 <p style={styles.FontLato12}>
                                                            <FormattedNumber value={Number(selected.weight_unit_value).toFixed(3) * 1000} />{' g '}
                                                            {' '}<FormattedMessage id="recipedetails.card.yield.content.line1"
                                                                      defaultMessage='each'/>
                                                </p>}
                                        </div> 
                                        }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column  mobile={4} tablet={4} computer={3}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.allergens.content.header"
                                                                defaultMessage='Allergens'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_gluten}/>
                                            {selected && allergens && allergens.length>0 &&
                                                <div>
                                                    {this.addAllergensList(allergens)}
                                                </div> 
                                            }
                                            {selected && (!allergens || allergens.length == 0) &&
                                                <div><br/>
                                                    {!isTablet && <p style={styles.FontLato14Grey}>
                                                    {' '}<FormattedMessage id="recipedetails.card.allergens.texte.empty"
                                                                            defaultMessage='None'/>
                                                    </p>}
                                                    {isTablet && <p style={styles.FontLato12}>
                                                    {' '}<FormattedMessage id="recipedetails.card.allergens.texte.empty"
                                                                            defaultMessage='None'/>
                                                    </p>}
                                                </div>
                                            }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                 <Grid.Column  mobile={4} tablet={3} computer={3}>
                                 {updating  &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.category.content.header"
                                                                defaultMessage='Category'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_list}/>
                                            {selected && !isTablet &&
                                                <div>
                                                    <br/>
                                                    {selected.category && selected.category.name &&
                                                    <p style={styles.FontLato14Grey}>{'  '}{selected.category.name}</p>}
                                                    {!selected.category &&
                                                    <p style={styles.FontLato14Grey}>{'  '}
                                                        <FormattedMessage id="recipedetails.card.category.text.empty"
                                                                          defaultMessage='None'/></p>}
                                                </div> 
                                            }
                                             {selected && isTablet &&
                                                <div>
                                                    <br/>
                                                    {selected.category && selected.category.name &&
                                                    <p style={styles.FontLato12}>{'  '}{selected.category.name}</p>}
                                                    {!selected.category &&
                                                    <p style={styles.FontLato12}>{'  '}
                                                        <FormattedMessage id="recipedetails.card.category.text.empty"
                                                                          defaultMessage='None'/></p>}
                                                </div> 
                                            }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} textAlign='right' style={{paddingLeft:'1em'}}>
                            {!isTablet && <Grid.Column mobile={0} tablet={0} computer={2}/>}
                             {/* <Grid.Column  mobile={4} tablet={4} computer={3}/> */}
                             <Grid.Column  mobile={4} tablet={5} computer={3}>
                                     {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card  image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.expiration.content.header"
                                                                defaultMessage='Duration of consumption'/>
                                            </Card.Header>
                                            <Card.Meta>
                                              <FormattedMessage id="recipedetails.card.expiration.content.subheader"
                                                                defaultMessage='number of day(s)'/>
                                            </Card.Meta>
                                        </Card.Content> 
                                        <Card.Content> 
                                        <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                        <div>  
                                           {/*  <Image floated='left' size='mini' src={img_expiration}/> */}
                                            <Icon name='minus circle' size='big' color='teal'
                                              style={{cursor:'pointer'}} 
                                              onClick={(event) => this.onIncreaseDecreaseQuantity(event,selected,false)} />
                                              <Input type="text"
                                                           id="quantity"
                                                           value={daysValidity}
                                                           onChange={this.onDaysValidity}
                                                           onBlur={(event) => this.updateValidityOnBlur(event,selected)} 
                                                           onKeyPress={(event) => this.updateValidityOnEnter(event,selected)} 
                                                           style={{height:'2.8em',minWidth:'4em',maxWidth:'5em',fontSize:'15px'}}
                                                        />

                                            <Icon name='plus circle'  size='big' color='teal'
                                                style={{cursor:'pointer' ,padding:'0em 0em 0em 0.2em'}} 
                                                onClick={(event) => this.onIncreaseDecreaseQuantity(event,selected,true)}
                                                />
                                            </div>
                                            </OutsideClickHandler>
                                        </Card.Content>

                                    </Card>
                                </Grid.Column>
                             <Grid.Column  mobile={4} tablet={5} computer={3}>
                                     {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}}>
                                        <Card.Content style={{background:'#f5f5f5'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.nutrition.content.header"
                                                                defaultMessage='Nutrition'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_composition}/>
                                            {selected && metrics && metrics.calories!="" && /* metrics.calories!="0" && */
                                                <div>
                                                    <p>{this.extractPayloadPerYield(metrics.calories,"kcal",selected,0)}{' '}({Number(energyKJ*factor).toFixed(0)}kJ) / {this.extractYieldType(selected)}</p>                                    
                                                    <Label as={Link} to={'/recipenutrition/'+ selected.id} color='teal'><FormattedMessage id="recipedetails.card.nutrition.content.details"
                                                                defaultMessage='More details'/></Label>
                                                </div>
                                                 
                                            }
                                            {selected && (!metrics || metrics.calories=="" /* || metrics.calories=="0" */) &&
                                                <div>
                                                    {!isTablet && <div>
                                                        <Label style={styles.FontLato12Grey} as={Link} to={'/recipenutrition/'+ selected.id}><FormattedMessage id="recipedetails.card.nutrition.content.add"
                                                                defaultMessage='Add'/></Label>
                                                        </div>
                                                    }
                                                    {isTablet && 
                                                         <Label style={styles.FontLato12} as={Link} to={'/recipenutrition/'+ selected.id}><FormattedMessage id="recipedetails.card.nutrition.content.add"
                                                         defaultMessage='Add'/></Label>
                                                    }
                                               </div>
                                            }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column  mobile={6} tablet={6} computer={6}>
                                   {updating  &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',}} ref={el => (this.componentRef = el)}>
                                        <Card.Content style={{background:'#f5f5f5'}} > 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.list.content.header"
                                                                defaultMessage='Food composition'/>{' '}
                                            </Card.Header>
                                            <Card.Meta>
                                                <span><FormattedMessage id="recipedetails.card.list.content.header.explanation"
                                                                defaultMessage='bold contains allergen(s)'/></span>
                                            
                                               <Popup trigger={<Icon name='images outline' color='teal'  size='large'  style={{cursor:'pointer',paddingRight:'1em'}}
                                                                     onClick={(e) => {this.htmlToImageConvert()}}/>}
                                                                content={<FormattedMessage id="recipedetails.card.ingredients.label.image"
                                                                                            defaultMessage='Download label as image'/>}
                                                                size='small'
                                                                position='bottom right'
                                                                inverted /> 
                                               <Popup trigger={ <span>
                                                            <ReactToPrint
                                                                        trigger={() => {
                                                                                return   <Icon name='print' 
                                                                                                size='large' 
                                                                                                color='grey'
                                                                                                style={{cursor:'pointer'}}
                                                                                                />
                                                                                            ;}}
                                                                        content={() => this.componentRef}
                                                                        //pageStyle="@page { margin: 0; size: auto;}  body {margin: 50mm !important;  transform: scale(.6);}"
                                                                        pageStyle='@page { size: auto; margin: 0; } @media print { body { -webkit-print-color-adjust: exact; padding: 50mm !important; transform: scale(.6); } }'
                                                                /></span>}
                                                        content={<FormattedMessage id="recipedetails.card.ingredients.label.print"
                                                                                   defaultMessage='Print'/>}
                                                        size='small'
                                                        position='bottom right'
                                                        inverted/>
                                                 {selected && selected.manual_label &&
                                                  <Popup trigger={ 
                                                        <Label color='blue' textAlign='right' size='tiny'>
                                                            <FormattedMessage id="recipedetails.card.ingredients.label.manual"
                                                                            defaultMessage='Manual'/>
                                                    </Label>}
                                                        content={<FormattedMessage id="recipedetails.card.ingredients.label.manual.popup"
                                                            defaultMessage='Manual update'/>}
                                                        size='small'
                                                        position='bottom right'
                                                        inverted/>
                                                }
                                                {selected && !selected.manual_label &&
                                                <Popup trigger={ 
                                                    <Label color='blue' textAlign='right' size='tiny'>
                                                        <FormattedMessage id="recipedetails.card.ingredients.label.auto"
                                                                        defaultMessage='Auto'/>
                                                                        
                                                </Label>}
                                                        content={<FormattedMessage id="recipedetails.card.ingredients.label.auto.popup"
                                                            defaultMessage='Automatic update'/>}
                                                        size='small'
                                                        position='bottom right'
                                                        inverted/>
                                                }
                                            </Card.Meta>
                                        </Card.Content> 
                                        <Popup trigger={
                                        <Card.Content> 
{/*                                         <Dimmer.Dimmable as={Segment} blurring dimmed={true}>
                                            <Dimmer active={true} inverted/> */}
                                          {/* <Image floated='left' size='mini' src={img_composition}/> */}
                                          {selected && !isTablet &&
                                                <div id={'label-node'} 
                                                     style={{'backgroundColor':'white','cursor':'pointer'}} 
                                                     onClick={(event)=>this.labelClickHandler(event)}
                                                    >
                                                    {selected.label_text &&
                                                        /*  <p style={styles.FontLato14Grey}>{'  '}{selected.label_text}</p>} */
                                                        <p style={{'font-weight':'300','font-family': 'Lato', 'font-size': '14px','color': 'grey'}}>
                                                            <FormattedMessage id="recipedetails.card.ingredients.title"
                                                                                defaultMessage='INGREDIENTS:'/>&nbsp;&nbsp;&nbsp;{this.recipeLabelFormatAllergens()}</p>}
                                                    {!selected.label_text  &&
                                                    <p style={styles.FontLato14Grey}>
                                                    {((selected.ingredients.length > 0) || (selected.subrecipes.length > 0)) &&
                                                        <Popup trigger={<Icon name='sync alternate' 
                                                                              size='large' 
                                                                              color='teal'
                                                                              style={{cursor:'pointer'}}
                                                                              onClick={(event) => this.recipeLabelTag(event)}/>}
                                                                content={<FormattedMessage id="recipedetails.card.ingredients.popup.update"
                                                                                            defaultMessage='Update'/>}
                                                                size='small'
                                                                inverted/>} 
                                                        &nbsp;&nbsp;&nbsp;
                                                        {((selected.ingredients.length == 0) && (selected.subrecipes.length == 0)) &&
                                                        <FormattedMessage id="recipedetails.card.ingredients.description.empty"
                                                                          defaultMessage='No ingredient(s)'/>}
                                                    </p>}
                                                    {/* <br/> */}
                                            </div> 
                                            }
                                            {selected && isTablet &&
                                                <div id={'label-node'} 
                                                        style={{'backgroundColor':'white','cursor':'pointer'}} 
                                                        onClick={(event)=>this.labelClickHandler(event)}
                                                    >
                                                    {selected.label_text &&
                                                   <p style={{'font-weight':'300','font-family': 'Lato', 'font-size': '14px','color': 'grey'}}>
                                                   <FormattedMessage id="recipedetails.card.ingredients.title"
                                                                      defaultMessage='INGREDIENTS:'/>&nbsp;&nbsp;&nbsp;{this.recipeLabelFormatAllergens()}</p>}
                                                    {!selected.label_text &&
                                                    <p style={styles.FontLato12}>
                                                    {((selected.ingredients.length > 0) || (selected.subrecipes.length > 0)) &&
                                                        <Popup trigger={<Icon name='sync alternate' 
                                                                              size='large' 
                                                                              color='teal'
                                                                              style={{cursor:'pointer'}}
                                                                              onClick={(event) => this.recipeLabelTag(event)}/>}
                                                                content={<FormattedMessage id="recipedetails.card.ingredients.popup.update"
                                                                                            defaultMessage='Update'/>}
                                                                size='small'
                                                                inverted/>} 
                                                        &nbsp;&nbsp;&nbsp;
                                                        {((selected.ingredients.length == 0) && (selected.subrecipes.length == 0)) &&
                                                        <FormattedMessage id="recipedetails.card.ingredients.description.empty"
                                                                          defaultMessage='No ingredient(s)'/>}
                                                    </p>}
                                                </div> 
                                            }
{/*                                             {membership &&
                                                <FormattedMessage id="recipedetails.card.ingredients.checkbox.text"
                                                                 defaultMessage='Manual mode (Premium)'>
                                                {label =>
                                                    <Checkbox checked = {toggleEditionMode}
                                                            label={label}
                                                            style={{'font-weight':'500','font-family': 'Lato','font-size': '12px','color': 'teal', textAlign:'left'}}
                                                            onChange= {this.toggleEditionMode} 
                                                            //disabled = {((membership.membership_type=="Entreprise") ? false : true) }
                                                            />}
                                                </FormattedMessage>
                                             } */}
                                            <Modal open={this.state.activeModalLabel}
                                                    dimmer='default' 
                                                     size='tiny'
                                                     style={styles.Modal} 
                                                      onClose={this.hideModal}>
                                               <Header content={<FormattedMessage id="recipedetails.card.modal.label.title"
                                                                                  defaultMessage='Update ingredients list'/>} />
                                                    <Modal.Content>
                                                    {toggleEditionMode &&
                                                        <Label color='blue' textAlign='right' size='large'>
                                                            <FormattedMessage id="recipedetails.card.modal.label.manual"
                                                                            defaultMessage='Manual mode'/>
                                                                            
                                                    </Label>}
                                                    {!toggleEditionMode &&
                                                        <Label color='blue' textAlign='right' size='large' >
                                                            <FormattedMessage id="recipedetails.card.modal.label.auto"
                                                                            defaultMessage='Automatic mode'/>
                                                                            
                                                    </Label>}
                                                    <br/><br/>
                                                    <FormattedMessage id="recipedetails.card.ingredients.checkbox.text"
                                                                 defaultMessage='Manual mode (Premium feature)'>
                                                   {label =>
                                                    <Checkbox checked = {toggleEditionMode}
                                                            label={label}
                                                            style={{'font-weight':'500','font-family': 'Lato','font-size': '13px','color': 'teal', textAlign:'left'}}
                                                            onChange= {this.toggleEditionMode}  />}
                                                  </FormattedMessage>
                                                  <br/>
                                                   <p style={{'font-weight':'500','font-family': 'Lato','font-size': '13px', textAlign:'left'}}>
                                                            <FormattedMessage id="recipedetails.card.modal.message.info"
                                                                              defaultMessage='In manual mode food composition label is not updated automatically when the recipe is updated (due to ingredients or sub-recipes updates)'/>
                                                    </p>
                                                    <TextArea
                                                                type="text"
                                                                placeholder="Note"
                                                                id="note"
                                                                rows='4'
                                                                maxlength="512"
                                                                value={this.state.label_text}
                                                                onChange={this.onLabelUpdate}
                                                                style={{ minHeight: 160, minWidth:500,resize:'none', opacity: toggleEditionMode?1:0.65}}
                                                                disabled = {((membership && membership.membership_type=="Entreprise" && toggleEditionMode) ? false : true)}
                                                                />
                                                    <br/> <br/>
                                                    <p style={{'font-weight':'400','font-family': 'Lato','font-size': '13px','color': 'teal', textAlign:'left'}}>
                                                            <Icon name='info'/>
                                                            <FormattedMessage id="recipedetails.card.modal.message.title"
                                                                              defaultMessage='Keep the ingredients with allergens in uppercase so that they appear in bold'/>
                                                     </p>
                                                     <br/>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                         <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="recipedetails.card.modal.label.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                     </Button>
                                                        {selected &&
                                                        <Button color='teal'
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="recipedetails.card.modal.label.button.yes"
                                                                                                    defaultMessage='Update'/>}
                                                                        icon='checkmark' 
                                                                        disabled = {(((membership && membership.membership_type!="Entreprise") ||
                                                                                    ((label_text == selected.label_text) && (toggleEditionMode==selected.manual_label))
                                                                                     ) ? true : false) }
                                                                        onClick={(event) =>this.onUpdateRecipeLabel(event)}
                                                            />}
                                                    </Modal.Actions>
                                        </Modal> 
                                        <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="recipedetails.recipes.header.title"
                                                                    defaultMessage='Manual mode for food composition'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="recipedetails.recipes.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="recipedetails.recipes.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '} {customer && customer.membership.name}
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="recipedetails.recipes.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="recipedetails.recipes.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="recipedetails.recipes.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal> 
{/*                                             </Dimmer.Dimmable> */}
                                        </Card.Content> }
                                        content={<FormattedMessage id="recipedetails.card.ingredients.label.click.image"
                                                   defaultMessage='Click to change the update mode or edit your food composition'/>}
                                       size='tiny'
                                       position='left bottom center'
                                       inverted 
                                       /> 
                                    </Card>
                                </Grid.Column>
{/*                                <Grid.Column  mobile={4} tablet={4} computer={3}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                     <Card image>
                                        <Card.Content style={{background:'#fafafa'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.dlc.content.header"
                                                                defaultMessage='DLC'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                            <Image floated='left' size='mini' src={img_calendar}/>
                                            {selected && allergens && allergens.length>0 &&
                                                <div>
                                                    {this.addAllergensList(allergens)}
                                                </div> 
                                            }
                                            {selected && (!allergens || allergens.length == 0) &&
                                                <div><br/>
                                                    {!isTablet && <p style={styles.FontLato14Grey}>
                                                    {' '}<FormattedMessage id="recipedetails.card.dlc.texte.empty"
                                                                            defaultMessage='None'/>
                                                    </p>}
                                                    {isTablet && <p style={styles.FontLato12}>
                                                    {' '}<FormattedMessage id="recipedetails.card.dlc.texte.empty"
                                                                            defaultMessage='None'/>
                                                    </p>}
                                                </div>
                                            }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column> */}
                    </Grid.Row> 
                   <Grid.Row>
                        <Grid.Column>
                            <br/><br/><Label style={{'background-color':'#74aced', color:'white', 'font-family': 'lato','font-size': '16px','font-weight': 'lighter'}}  size='big'>
                                     <FormattedMessage id="recipedetails.segment.costbreakdown.title"
                                                       defaultMessage='Cost breakdown'/>
                            </Label>
                        </Grid.Column>
                   </Grid.Row>
                    <Grid.Row columns={1} >
                        <Grid.Column /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                             <div className='floating-menu'>
                                <FloatingMenuItem label="" 
                                                  icon={ <Popup trigger={<Icon name='edit'/>}
                                                                content={<FormattedMessage id="recipedetails.card.popup.view.edit.text"
                                                                                            defaultMessage='Edit'/>}
                                                                size='small'
                                                                inverted/>} 
                                                  action={this.editCurrentRecipe.bind(this)} key="m"/>
                            </div>  
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{'overflow-x': 'auto'}}> 
                        <Grid.Column /*width={14}*/  mobile={18} tablet={16} computer={14}>
                             {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                            <Table selectable 
                                   //stackable
                                   unstackable 
                                   compact
                                   //striped 
                                   //singleLine 
                                   //color='grey'
                                   style={styles.FontLato13Border}
                                   //</Grid.Column>style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)','font-family': 'Lato', 'font-size': '13px', 'color': 'grey',}}
                                   >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2}>
                                            <FormattedMessage id="recipedetails.table.header.column.type"
                                                                  defaultMessage='Type'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3} textAlign='left'>
                                            <FormattedMessage id="recipedetails.table.header.column.item"
                                                            defaultMessage='Item'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='center'>
                                            <FormattedMessage id="recipedetails.table.header.column.quantitynet"
                                                              defaultMessage='Net quantity'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='center'>
                                            <FormattedMessage id="recipedetails.table.header.column.quantitygross"
                                                              defaultMessage='Gross quantity'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='right'>
                                            <FormattedMessage id="recipedetails.table.header.column.itemcost"
                                                              defaultMessage='Item Cost'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='right'>
                                            <FormattedMessage id="recipedetails.table.header.column.costpercent"
                                                              defaultMessage='Cost %'/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3} textAlign='right'>
                                         {selected && 
                                            <Label basic color='teal'>
                                             {selected.ingredients.length 
                                            + selected.subrecipes.length}  <FormattedMessage id="recipedetails.table.header.info.elements"
                                                                                             defaultMessage='element(s)'/>
                                            </Label>}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {selected && selected.ingredients &&
                                        this.addIngredientsRows(selected)
                                    }
                                    {selected && selected.subrecipes &&
                                        this.addRecipesRows(selected)
                                    }
                                </Table.Body>
                                {selected && selected.weight_total_value && Number(selected.weight_total_value)>0 &&
                                 <Table.Footer fullWidth style={styles.FontLato13Border}>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                    <Label basic style={styles.FontLato13Bold} size='medium' color='grey'>
                                                        <FormattedMessage id="recipedetails.table.footer.button.variation.text.total"
                                                                          defaultMessage='Net total' />
                                                    </Label>{' '}
                                                    {!selected.weight_variation_active &&
                                                        <Popup trigger={
                                                            <Button compact /*floated='right'*/ 
                                                                icon
                                                                //labelPosition='left'  
                                                                style={styles.ButtonGreenRecipe}
                                                                size='medium'
                                                                color='teal'
                                                                onClick={(event)=>this.addVariationHandler(event)}>
                                                                <Icon name="weight"/> <FormattedMessage id="recipedetails.table.footer.button.variation.text.apply"
                                                                                                        defaultMessage='cooking variation' />
                                                            </Button>}
                                                        content={<FormattedMessage id="recipedetails.popup.view.weight.text"
                                                                                 defaultMessage='Add cooking weight variation'/>}
                                                        inverted
                                                        size='small'
                                                        position='bottom left'/>}
                                                    {selected.weight_variation_active &&
                                                        <Popup trigger={
                                                            <Button  compact /*floated='right'*/ 
                                                                //labelPosition={'right'}  
                                                                style={styles.ButtonGreenRecipe}
                                                                size='small'
                                                                color='teal'
                                                                onClick={(event)=>this.removeVariation(event)}
                                                                >
                                                                <Icon name="delete" size='small'/>
                                                                {(selected.weight_variation_type == 'loss') && ' - '}
                                                                {(selected.weight_variation_type == 'gain') && ' + '}
                                                                {Number(selected.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                            </Button>}
                                                        content={<FormattedMessage id="recipedetails.popup.view.weight.remove.text"
                                                                                        defaultMessage='variation applied'/>}
                                                        inverted
                                                        size='small'
                                                        position='bottom left'/>}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell  width={2} textAlign='center'>
                                                    <FormattedNumber value={selected.weight_total_value} maximumFractionDigits="2"/>{' '}{selected.weight_unit}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedNumber value={selected.cost_total} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell />
                                </Table.Footer>}
                                {selected && (!selected.weight_total_value || Number(selected.weight_total_value) == 0) &&
                                 <Table.Footer fullWidth style={styles.FontLato13Border}>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <Label basic style={styles.FontLato13Bold} size='medium' color='grey'>
                                                        <FormattedMessage id="recipedetails.table.footer.button.variation.text.total"
                                                                          defaultMessage='Net total' />
                                                    </Label>{' '}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell  width={2} textAlign='center'>
                                                {!selected.weight_unit &&
                                                        <Link to={'/editrecipe/'+selected.id}><FormattedMessage id="recipedetails.table.footer.button.variation.text.update"
                                                                          defaultMessage='Update' /></Link>
                                                }
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedNumber value={selected.cost_total} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell />
                                </Table.Footer>}
                                <Modal
                                   open={this.state.activeModalVariation}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='weight' content={<FormattedMessage id="recipedetails.table.footer.variation.modal.title"
                                                                                    defaultMessage='Add a cooking variation (applied when used as subrecipe)'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'0em'}}>
                                            <Icon color='blue' name='percent'/>{' '}
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="recipedetails.table.footer.variation.percent.modal.form.option.weight"
                                                                              defaultMessage='Enter a percentage and variation type'/></label>
                                             <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="ecipedetails.table.footer.variation.modal.form.select.name"
                                                                              defaultMessage='Type of variation'/></label>
                                                        <FormattedMessage id="recipedetails.table.footer.variation.modal.type.placeholder"
                                                                    defaultMessage='Select'>
                                                                {placeholder =>   
                                                                 <Form.Group>
                                                                        <Form.Select 
                                                                                    options={variationTypeOptions} 
                                                                                    placeholder={placeholder} 
                                                                                    onChange={this.onTypeVariationChange}
                                                                                    value= {this.state.typeVariation}
                                                                                    error={submitted && !this.assertTypeField()}
                                                                                    /></Form.Group>}
                                                    </FormattedMessage>
                                                     {submitted && !this.assertTypeField()  && 
                                                            <label style={{color:'red'}}><i>
                                                             <FormattedMessage id="recipedetails.table.footer.variation.modal.select.error.message"
                                                                                defaultMessage='variation type'/> 
                                                            </i></label>
                                                    }
                                                </Form.Field >
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="recipedetails.table.footer.variation.modal.form.input.name"
                                                                              defaultMessage='Enter percentage'/></label>
                                                    <Form.Group inline>
                                                        <Form.Input
                                                            type="text"
                                                            placeholder="1-99"
                                                            id="inputVariation"
                                                            value={this.state.valueVariation}
                                                            onChange={this.onValueVariationChange}
                                                            error={submitted && !this.assertPercentField()}
                                                        />
                                                       {' '}
                                                       <label>%</label>
                                                    </Form.Group>
                                                     {submitted && !this.assertPercentField()  && 
                                                            <label style={{color:'red'}}><i>
                                                            <FormattedMessage id="recipedetails.table.footer.variation.modal.input.error.message"
                                                                                defaultMessage='1-99'/>
                                                            </i></label>
                                                    }
                                                </Form.Field>
                                            </Form.Group>
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="recipedetails.table.footer.variation.modal.label.or"
                                                                              defaultMessage='OR'/></label>
                                            <br/><br/>
                                            <Icon color='blue' name='calculator'/>{' '}
                                            <label style={styles.LabelBlackBold}><FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.weight"
                                                                              defaultMessage='Calculate with your gross/net weights (same unit)'/></label>
                                            <Form.Group widths='equal' style={{padding:'0.5em'}}>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.gross.weight"
                                                                              defaultMessage='Enter gross weight'/></label>
                                                        <FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.gross.weight.placeholder"
                                                                    defaultMessage='gross value'>
                                                                {placeholder =>   
                                                                 <Form.Group inline>
                                                                        <Form.Input placeholder={placeholder} 
                                                                               onChange={this.onValueGrossChange}
                                                                                //value= {this.state.typeVariation}
                                                                                //error={!this.assertGrossWeightField()}

                                                                        />{' '}
                                                                 <label><FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.weight.unit"
                                                                              defaultMessage='g'/></label>
                                                                </Form.Group>}
                                                    </FormattedMessage>
                                                </Form.Field >
                                                <Form.Field style={{marginLeft:'1em'}}>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.net.weight"
                                                                              defaultMessage='Enter net weight'/></label>
                                                   
                                                    <FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.net.weight.placeholder"
                                                                    defaultMessage='net value'>
                                                                {placeholder =>   
                                                                 <Form.Group inline>
                                                                        <Form.Input placeholder={placeholder} 
                                                                               onChange={this.onValueNetChange}
                                                                                //value= {this.state.typeVariation}
                                                                                //error={!this.assertNetWeightField()}
                                                                        />{' '}
                                                                   <label><FormattedMessage id="recipedetails.table.footer.variation.modal.form.option.weight.unit"
                                                                              defaultMessage='g'/></label>
                                                                   </Form.Group>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="recipedetails.table.footer.variation.modal.validate.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button style={styles.ButtonGreen} loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="recipedetails.table.footer.variation.modal.validate.button.apply"
                                                                        defaultMessage='Apply'/>}
                                            icon='checkmark' 
                                            onClick={this.onApplyVariation}
                                            >
                                        </Button>}
                                        {!updating && <Button style={styles.ButtonGreen}
                                            labelPosition='right' 
                                            content={<FormattedMessage id="recipedetails.table.footer.variation.modal.validate.button.apply"
                                                                        defaultMessage='Apply'/>}
                                            icon='checkmark' 
                                            onClick={this.onApplyVariation}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                                </Modal> 
                            </Table>
                        </Grid.Column>
                    </Grid.Row>    
{/*                     <Grid.Row columns={2} textAlign='right' style={{paddingLeft:'1em'}}>
                               <Grid.Column  mobile={4} tablet={4} computer={8}/>
                                <Grid.Column  mobile={4} tablet={3} computer={6}>
                                 {updating  &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card image fluid>
                                        <Card.Content style={{background:'#fafafa'}}> 
                                            <Card.Header>
                                                <FormattedMessage id="recipedetails.card.list.content.header"
                                                                defaultMessage='Etiquette composition'/>
                                            </Card.Header>
                                        </Card.Content> 
                                        <Card.Content> 
                                          {/*   <Image floated='left' size='mini' src={img_composition}/>
                                          {selected && !isTablet &&
                                                <div>
                                                    {selected.label_text &&
                                                    <p style={styles.FontLato14Grey}>{'  '}{selected.label_text}</p>}
                                                    {!selected.label_text &&
                                                    <p style={styles.FontLato14Grey}>{'  '}
                                                        <FormattedMessage id="recipedetails.card.list.text.empty"
                                                                          defaultMessage='None'/></p>}
                                                </div> 
                                            }
                                            {selected && isTablet &&
                                                <div>
                                                    {selected.label_text &&
                                                    <p style={styles.FontLato12}>{'  '}{selected.label_text}</p>}
                                                    {!selected.label_text &&
                                                    <p style={styles.FontLato12}>{'  '}
                                                        <FormattedMessage id="recipedetails.card.list.text.empty"
                                                                          defaultMessage='None'/></p>}
                                                </div> 
                                            }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                    </Grid.Row>    */}     
                    <Grid.Row>
                        <Grid.Column>
                            <br/><br/><Label style={{'background-color':'#74aced', color:'white', 'font-family': 'lato','font-size': '16px','font-weight': 'lighter'}}  size='big'>
                                        <FormattedMessage id="recipedetails.segment.costingredient.title"
                                                          defaultMessage='Cost by ingredient'/>
                            </Label> 
                        </Grid.Column>
                   </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{width:1300,height:550}}>
                            <br/>         
                            <ResponsivePie
                                data={chart_data}
                                margin={{
                                    "top": 40,
                                    "right": 100,
                                    "bottom": 80,
                                    "left": isMobile?80:120
                                }}
                                sortByValue={true}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors="paired"
                                colorBy="percent"
                                borderWidth={1}
                                borderColor="inherit:darker(0.2)"
                                sliceLabel='percent'
                                radialLabel='id'
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor="inherit"
                                slicesLabelsSkipAngle={20}
                                slicesLabelsTextColor="#333333"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                legends={isMobile?[]:legend_props}
                            />
                            </Grid.Column>
                    </Grid.Row> 
                    <br/> {/* <br/> */}   
                    <Grid.Row>
                        <Grid.Column>
                            <br/><br/> <Label style={{'background-color':'#74aced', color:'white', 'font-family': 'lato','font-size': '16px','font-weight': 'lighter'}}  size='big'>
                                <FormattedMessage id="recipedetails.segment.margin.title"
                                                  defaultMessage='Margin calculator'/>
                            </Label>
                        </Grid.Column>
                   </Grid.Row>
{/*                    {isSubRecipe && 
                     <Grid.Row columns={2}>
                        <Grid.Column mobile={18} tablet={16} computer={14}>
                            <p>This recipe is sub-recipe only (no selling)</p>
                            <p>(Edit your recipe if necessary)</p>
                        </Grid.Column>
                    </Grid.Row>
                   } */}
                   <Grid.Row columns={2}>
                    <Grid.Column /*width={14}*/ mobile={18} tablet={16} computer={14}>
                        {/* <br/> */}
                        {isSubRecipe && 
                            <Header as='h4' color='orange' style={{padding: '0em 0em 0em 2em'}}>
                                 <FormattedMessage id="recipedetails.segment.margin.nosell.title"
                                                      defaultMessage='This recipe is not for sell (update recipe if needed)'/>
                            </Header>
                        }
                         <br/>
                        <Form style={{padding: '0em 0em 0em 2em'}}> 
                            {!isSubRecipe && 
                                <Message style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)', 'font-family': 'Lato', 'font-size': '14px','color': 'black'}}>
                                    <Message.Header>
                                        <FormattedMessage id="recipedetails.segment.margin.message.title"
                                                        defaultMessage='Evaluate your gross margin and costs ratios'/>
                                    </Message.Header>
                                    <Message.List>
                                        <FormattedMessage id="recipedetails.segment.margin.message.subtitle"
                                                        defaultMessage='Start by adjusting quantity and selling price'/>
                                    </Message.List>
                                </Message>}
                               {/*  <br/> */}
                                <Button as='a' 
                                    size='medium' 
                                    color='teal' 
                                    //attached="bottom right" 
                                    style={styles.ButtonGreenRecipe}
                                    align='right'
                                    onClick={this.toggleMargin}
                                    disabled={isSubRecipe}
                                    >
                                <Icon name='settings' />    
                                    {!toggled && <FormattedMessage id="recipedetails.segment.margin.label.title"
                                                                          defaultMessage='Customize'/>}
                                    {toggled && <FormattedMessage id="recipedetails.segment.margin.label.cancel"
                                                                          defaultMessage='Cancel'/>}
                               </Button>
{/*                             <Form.Field>
                                <FormattedMessage id="editrecipe.form.input.subrecipe"
                                                        defaultMessage="used as Sub-Recipe">
                                    {text => 
                                    <Radio toggle label={text}
                                                onChange={this.onCheckSubRecipe}
                                                checked={isSubRecipe}  
                                                style={{'marginLeft': '1.5em'}}/>
                                    }
                                    </FormattedMessage>
                                </Form.Field>
                            </Form.Group> */}
                            <br/><br/> 
{/*                             {toggled &&
                            <div>
                                <Form.Group unstackable>
                                        <Form.Input
                                                label='Quantity of Parts'
                                                type="text"
                                                placeholder="quantity"
                                                id="quantity"
                                                value={this.state.quantityparts}
                                                onChange={this.onQuantityChange}
                                                focus
                                                style={{width: "250px"}}/>
                                        <Form.Input
                                                label='Gross Margin (%)'
                                                type="text"
                                                placeholder="gross margin"
                                                id="grossmargin"
                                                value={this.state.grossmargin}
                                                error
                                                //onChange={this.onGrossMarginChange}
                                                style={{width: "250px"}}/>
                                </Form.Group>
                                <Form.Group  unstackable>
                                        <Form.Input
                                                label='Selling Price'
                                                type="text"
                                                placeholder="selling price"
                                                id="sellingprice"
                                                value={this.state.sellingprice}
                                                onChange={this.onSellingPriceChange}
                                                focus
                                                style={{width: "250px"}}/>
                                        <Form.Input
                                                label='Mark Up (%)'
                                                type="text"
                                                placeholder="mark up"
                                                id="markup"
                                                value={this.state.markup}
                                                error
                                                //onChange={this.onSellingPriceChange}
                                                style={{width: "250px"}}/>
                                        <Form.Input
                                                label='Food Cost (%)'
                                                type="text"
                                                placeholder="food cost"
                                                id="foodcost"
                                                value={this.state.foodcost}
                                                error
                                                //onChange={this.onSellingPriceChange}
                                                style={{width: "250px"}}/>
                                </Form.Group>
                            </div>} */}
{/*                             <Form width={8}>
                                <Form.Group>
                                    <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.price.placeholder0"
                                                            defaultMessage='selling price'>
                                        {placeholder =>  
                                                <Form.Input fluid width={3} 
                                                            label = {<FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.price.title0"
                                                                                    defaultMessage='Price TTC'/>}
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="sellingprice"
                                                            value={this.state.sellingprice}
                                                            onChange={this.onSellingPriceChange}
                                                            focus/>}
                                    </FormattedMessage>
                                    <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.placeholder0"
                                                            defaultMessage='VAT'>
                                        {placeholder =>  
                                                <Form.Select fluid width={3} 
                                                            label = {<FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.title0"
                                                                                    defaultMessage='VAT'/>}
                                                            type="text"
                                                            options={VAToptions} 
                                                            placeholder={placeholder}
                                                            id="VAT"
                                                            value={this.state.sellingVAT}
                                                            onChange={this.onVATPriceChange}
                                                            focus/>}
                                    </FormattedMessage>
                                    <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.quantity.placeholder0"
                                                            defaultMessage='quantity'>
                                        {placeholder =>  
                                                <Form.Input fluid width={3} 
                                                            label = {<FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.quantity.title0"
                                                                                    defaultMessage='Quantity'/>}
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="quantity"
                                                            value={this.state.quantity_parts}
                                                            onChange={this.onQuantityChange}
                                                            focus/>}
                                    </FormattedMessage>
                                </Form.Group>
                            </Form>  */}
                            <br/><br/>              
                            <Card.Group centered itemsPerRow={4} stackable  style={styles.FontLato12Bold}> 
                                <Card color='grey' /*onClick={this.toggleMargin}*/ style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    <Card.Content textAlign='center' style={{background:'#f5f5f5'}}> 
                                        <Card.Header>
                                            <FormattedMessage id="recipedetails.segment.margin.card.selling.title"
                                                              defaultMessage='Selling price'/>
                                        </Card.Header>
                                    </Card.Content> 
                                   {/*  <Card.Content style={{'margin-left':'auto','margin-right':'auto'}}><Image size='mini' src={img_rising}/></Card.Content> */}
                                    <Card.Content textAlign='center'>
                                        {!toggled && selected && !isSubRecipe &&
                                            <div onClick={this.toggleMargin} style={{cursor:'pointer'}}>
                                            <br/> 
                                            <Header as='h2' color='blue'> <FormattedNumber value={selected.selling_price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                                {' '}<FormattedMessage id='price.VAT.text' defaultMessage='Tax incl.'/> {/* - {selected.quantity_parts}{' '}
                                                <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.title"
                                                                  defaultMessage=' part(s)'/> */}
                                            </Header>
                                            <Header as='h4' color='grey'>
                                            {selected.quantity_parts}{' '}
                                              {isQuantity(selected.yield_units) &&
                                                 <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.quantity.parts.title"
                                                                  defaultMessage=' part(s)'/>}
                                                 {!isQuantity(selected.yield_units) &&
                                                 <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.notquantity.parts.title"
                                                                  defaultMessage=' recipe(s)'/>} {' - '} 
                                                    <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.placeholder"
                                                                      defaultMessage='Tax'/>{' ('}
                                                    { tax > 0 && <FormattedNumber value={selected.selling_vat} maximumFractionDigits="2" style="percent"/>}
                                                    { tax <= 0 && <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.none" defaultMessage="none"/>}
                                                    {')'}
                                            </Header>
                                            </div>
                                        }
                                        {toggled &&
                                        <div>

                                          <p><FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.price.title"
                                                             defaultMessage='Price'/>{' : '}
                                           <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.price.placeholder"
                                                          defaultMessage='selling price'>
                                                {placeholder =>                                      
                                                        <Input 
                                                            //fluid
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="sellingprice"
                                                            value={this.state.sellingprice}
                                                            onChange={this.onSellingPriceChange}
                                                            style={{minWidth:'8em',maxWidth:'10em'}}
                                                            focus/>}
                                           </FormattedMessage></p>
                                            <br/>
                                            <p><FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.title"
                                                             defaultMessage='Tax'/>{' : '}
                                            <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.VAT.placeholder"
                                                          defaultMessage='Tax'>
                                                {placeholder =>                                      
                                                        <Select
                                                            //fluid
                                                            options={VAToptions} 
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="VAT"
                                                            value={this.state.sellingVAT}
                                                            onChange={this.onVATPriceChange}
                                                            style={{minWidth:'10em',maxWidth:'12em'}}
                                                            focus/>}
                                           </FormattedMessage></p>
                                            <br/>
                                            <p> {isQuantity(selected.yield_units) && <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.quantity.parts.title"
                                                             defaultMessage=' part(s)'/>}
                                                {!isQuantity(selected.yield_units) && <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.notquantity.parts.title"
                                                             defaultMessage=' recipe(s)'/>}{' : '}
                                           <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.quantity.placeholder"
                                                          defaultMessage='quantity'>
                                                {placeholder =>   
                                                        <Input
                                                            //fluid
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="quantity"
                                                            value={this.state.quantityparts}
                                                            onChange={this.onQuantityChange}
                                                            style={{minWidth:'8em',maxWidth:'10em'}}
                                                            focus/>}
                                            </FormattedMessage></p>
                                            <br/>
                                            <Button style={styles.ButtonGreen} icon /*floated='right'*/ onClick={this.toggleAndSave}> 
                                                <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.button"
                                                                  defaultMessage='Save'/>
                                            {/* <Icon name='check'/>*/}</Button> 
                                            <Button style={styles.ButtonGrey} icon /*floated='right'*/ onClick={this.toggleMargin}> 
                                                <FormattedMessage id="recipedetails.segment.margin.card.selling.toggle.button.cancel"
                                                                  defaultMessage='Cancel'/></Button> 
                                        </div>
                                        }
                                    </Card.Content>
                                    <Card.Content extra textAlign='center'>
                                        <br/>
                                        <p style={{color:'grey',fontSize:'13px'}}><FormattedMessage id="recipedetails.segment.margin.card.selling.explanation"
                                                           defaultMessage='Selling price of your choice (to customize)'/></p>
                                        <br/>
                                    </Card.Content>
                                </Card>
                                <Card color='grey'  style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    {toggled &&
                                    <Dimmer active inverted>
                                        <Loader inverted disabled />
                                    </Dimmer> }
                                    <Card.Content textAlign='center' style={{background:'#f5f5f5'}}> 
                                        <Card.Header>
                                            <FormattedMessage id="recipedetails.segment.margin.card.gross.title"
                                                              defaultMessage='Gross margin'/>
                                        </Card.Header>
                                    </Card.Content> 
                                    {/* <Card.Content textAlign='center' style={{'margin-left':'auto','margin-right':'auto'}}><Image size='mini' src={img_coins}/></Card.Content>  */}
                                    <Card.Content textAlign='center'>
                                     {selected && !isSubRecipe && <div><br/><Header as='h2' color='green'><FormattedNumber value={selected && selected.gross_margin} maximumFractionDigits="2" style="percent"/> 
                                        </Header>
                                        <Header as='h4' color='grey'>
                                            <FormattedMessage id="recipedetails.segment.margin.card.gross.factor"
                                                              defaultMessage='Factor'/>
                                             {' '}{/* <FormattedNumber value={selected && selected.food_cost>0 && selected.gross_margin/selected.food_cost} maximumFractionDigits="2"/> */}
                                                  <FormattedNumber value={selected && relevant_cost_unit>0 && selling_no_VAT/relevant_cost_unit} maximumFractionDigits="2"/>
                                        </Header></div>}
                                    </Card.Content>
                                    <Card.Content extra textAlign='center'>
                                        <br/>
                                        <p style={{color:'grey',fontSize:'13px'}}><FormattedMessage id="recipedetails.segment.margin.card.gross.explanation"
                                                           defaultMessage='Ratio of margin over selling price'/></p>
                                        <p style={{color:'orange',fontSize:'13px'}}><Icon name='lightbulb outline' color='orange'/><FormattedMessage id="recipedetails.segment.margin.card.gross.explanation2"
                                                                                      defaultMessage='Greater than 75% (max 100%)'/></p>
                                    </Card.Content>
                                </Card>
                                <Card color='grey'  style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                   {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    {toggled &&
                                    <Dimmer active inverted>
                                        <Loader inverted disabled />
                                    </Dimmer> }
                                    <Card.Content textAlign='center' style={{background:'#f5f5f5'}}> 
                                        <Card.Header>
                                            <FormattedMessage id="recipedetails.segment.margin.card.markup.title"
                                                              defaultMessage='Markup'/>
                                        </Card.Header>
                                    </Card.Content> 
                                   {/*  <Card.Content style={{'margin-left':'auto','margin-right':'auto'}}><Image size='mini'  src={img_percentage}/></Card.Content>  */}
                                    <Card.Content textAlign='center'>
                                        {selected && !isSubRecipe && <div><br/><Header as='h2' color='green'><FormattedNumber value={selected.markup} maximumFractionDigits="2" style="percent"/></Header>
                                        <Header as='h4' color='grey'></Header></div>}
                                    </Card.Content>
                                    <Card.Content extra textAlign='center'>
                                          <br/>
                                          <p style={{color:'grey',fontSize:'13px'}}><FormattedMessage id="recipedetails.segment.margin.card.markup.explanation"
                                                                                     defaultMessage='Ratio of margin over unitary cost'/></p>
                                           <p style={{color:'orange',fontSize:'13px'}}><Icon name='lightbulb outline' color='orange'/><FormattedMessage id="recipedetails.segment.margin.card.markup.explanation2"
                                                                                     defaultMessage='Positive and high (max inifinite)'/></p>
                                    </Card.Content>
                                </Card>
                                <Card color='grey' style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}>
                                {updating &&
                                    <Dimmer active inverted>
                                        <Loader inverted content='Loading' />
                                    </Dimmer> }
                                    {toggled &&
                                    <Dimmer active inverted>
                                        <Loader inverted disabled />
                                    </Dimmer> }
                                    <Card.Content textAlign='center' style={{background:'#f5f5f5'}}> 
                                        <Card.Header>
                                            <FormattedMessage id="recipedetails.segment.margin.card.food.title"
                                                              defaultMessage='Food Cost'/>
                                        </Card.Header>
                                    </Card.Content> 
                                   {/*  <Card.Content style={{'margin-left':'auto','margin-right':'auto'}}><Image size='mini' src={img_groceries}/></Card.Content> */}
                                    <Card.Content textAlign='center'>
                                      {selected && !isSubRecipe && <div><br/><Header as='h2' color='green'><FormattedNumber value={selected && selected.food_cost} maximumFractionDigits="2" style="percent"/></Header>
                                          <Header as='h4' color='grey'></Header></div>}
                                    </Card.Content>
                                    <Card.Content extra textAlign='center'>
                                        <br/>
                                        <p style={{color:'grey',fontSize:'13px'}}><FormattedMessage id="recipedetails.segment.margin.card.food.explanation"
                                                           defaultMessage='Ratio of food cost over selling price'/></p>
                                        <p style={{color:'orange',fontSize:'13px'}}><Icon name='lightbulb outline' color='orange'/><FormattedMessage id="recipedetails.segment.margin.card.food.explanation2"
                                                           defaultMessage='Between 25-35%'/></p>
                                    </Card.Content>
                                </Card>
                            </Card.Group>
                            <br/><br/> 
                        </Form>
                        <br/> <br/>  <br/> 
                    </Grid.Column>
                   </Grid.Row>
                </Grid>
                </SideLeftUncoverRecipe>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,alert } = state;
    const {loading,selected,updating/*,image*/} = state.recipes;
    const { customer } = state.payment;
    return {
        recipes,
        selected,
        //image,
        loading,
        updating,
        customer,
        alert
    };
}

const connectedRecipeDetails = injectIntl(connect(mapStateToProps)(RecipeDetails));
export { connectedRecipeDetails as RecipeDetails };
 