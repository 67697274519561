import React from 'react';
import './index.css';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {store} from './_helpers';
import {App} from './App';
import 'semantic-ui-css/semantic.min.css';

import {addLocaleData} from "react-intl";
import locale_fr from 'react-intl/locale-data/fr';
import locale_en from 'react-intl/locale-data/en';
//import localeData from '../public/locales/data.json';

import ConnectedIntlProvider from './Components/ConnectedIntlProvider';

//const language = navigator.language.split(/[-_]/)[0];  // language without region code
//let language = 'en';

addLocaleData([...locale_en, ...locale_fr]);


// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
//const language =
//  (navigator.languages && navigator.languages[0]) ||
//  navigator.language ||
//  navigator.userLanguage;

// Split locales with a region code
//const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
/*
let host = window.location.host;
//let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
let languageFromDomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 3) {
  subdomain = parts[0];
  // Remove the subdomain from the parts list
  //parts.splice(0, 1);
  // Set the location to the new url
  //window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
  if (subdomain =='fr'){
    languageFromDomain ='fr'
  }else if (subdomain =='en') {
    languageFromDomain ='en'
  }
}

// Try local from subdomain, try locale without region code,try full locale, fallback to 'fr'
let selected = languageFromDomain || 
               //languageWithoutRegionCode ||
               //language ||
              'fr' 

   */           

render(
/*     <IntlProvider locale={selected} 
                  key={selected}
                  //messages={messages}
                  messages={localeData[selected]}
                  //formats={formats}
                  defaultLocale = 'fr'
                  //defaultFormats?: object = {},                
                  > */
            <Provider store={store}>
              {/* <IntlProvider initLocale={selected}> */}
               <ConnectedIntlProvider>
                <App/>
               </ConnectedIntlProvider>
              {/* </IntlProvider>*/}
         </Provider> 
   /* </IntlProvider> */,
    document.getElementById('root')
);

//registerServiceWorker();
unregister();