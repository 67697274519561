import React, { Component } from 'react';
import { recipesActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid,Icon,Header,Loader,Dimmer,Breadcrumb} from 'semantic-ui-react';
import SideLeftUncoverRecipe from './SidebarRecipe';
import { history,toaster_dispatch,Capitalize} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';

class DeleteRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    props.dispatch(recipesActions.get(props.match.params.recipeId));
 }

 deleteRecipe (event,index) {
    event.preventDefault();
    this.props.dispatch(recipesActions.delete(index));
    
 }

 seeRecipe (event,index) {
    event.preventDefault();
    //this.props.dispatch(recipesActions.get(index,'/recipedetails'));
    history.push('/recipedetails/'+index);
 }


  render() {
    const { dispatch,alert,recipes,loading,deleting,intl } = this.props;
    return (
      <div>
      { loading &&
        <SideLeftUncoverRecipe>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverRecipe>
      }
      { !loading && recipes &&
      <SideLeftUncoverRecipe id={this.props.match.params.recipeId}>
         <Grid stackable style={{border:'none','background-color':'#fcfcfc','font-family': 'Lato', 'font-size': '12px','color': 'grey'}}>
          <Grid.Row columns={1}>
                    <Breadcrumb style={{background:'#fcfcfc'}}>
                              <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="deleterecipe.link.dashboard"
                                                                    defaultMessage='Dashboard'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="deleterecipe.link.recipes"
                                                                  defaultMessage='Recipes'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/recipedetails/'+this.props.match.params.recipeId}>{recipes && recipes.selected && recipes.selected.name}
                              </Breadcrumb.Section>   
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section active><FormattedMessage id="deleterecipe.link.delete"
                                                                    defaultMessage='Delete'/></Breadcrumb.Section>               
                    </Breadcrumb>
          </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={10}>
                        <Header as='h2'>
                              <Icon name='trash alternate outline' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="deleterecipe.header.title"
                                                    defaultMessage=' Delete Recipe '/>
                                  <Header.Subheader>{recipes && recipes.selected && recipes.selected.name}</Header.Subheader>
                                </Header.Content>
                        </Header>
                        <br/>
                </Grid.Column>
           </Grid.Row>
           <Grid.Row columns={1}>
              <Grid.Column width={10}>
                  {recipes.selected &&
                 <Message style={styles.FontLato13}>
                    <Message.Header> 
                          <FormattedMessage id="deleterecipe.card.header.title"
                                            defaultMessage='Deletion of Recipe  : {name}'
                                            values={{name:recipes.selected.name}}/>
                    </Message.Header>
                    <br/>
                    <p> 
                          <FormattedMessage id="deleterecipe.card.header.message"
                                            defaultMessage='Are you really sure to complete the deletion ?'/>
                    </p>
                    <br/>
                    {deleting &&
                      <Button loading style={styles.ButtonGreen} //basic bsstyle="primary" color='teal'
                            onClick={(event) =>this.deleteRecipe(event,recipes.selected.id)}>
                            <i className="trash alternate outline icon"></i>
                            <FormattedMessage id="deleterecipe.card.button.delete"
                                            defaultMessage='Delete'/>
                    </Button>}
                    {!deleting &&
                      <Button style={styles.ButtonGreen} //basic bsstyle="primary" color='teal'
                            onClick={(event) =>this.deleteRecipe(event,recipes.selected.id)}>
                            <i className="trash alternate outline icon"></i>
                            <FormattedMessage id="deleterecipe.card.button.delete"
                                            defaultMessage='Delete'/>
                    </Button>}
                    <Button style={styles.ButtonGrey} //basic bsstyle="primary" color='grey'
                            onClick={(event) =>this.seeRecipe(event,recipes.selected.id)}>
                            <i className="cancel icon"></i>
                            <FormattedMessage id="deleterecipe.card.button.cancel"
                                            defaultMessage='Cancel'/>
                    </Button>
                    <br/>
                </Message>}
             </Grid.Column>
          </Grid.Row >
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </Grid>
      </SideLeftUncoverRecipe>}
     </div> 
    );
  }
}

function mapStateToProps(state) {
  const { recipes,dispatch,alert } = state;
  const {loading,deleting} = state.recipes;
  return {
        recipes,
        dispatch,
        alert,
        loading,
        deleting
  };
}

const connectedDeleteRecipe = injectIntl(connect(mapStateToProps)(DeleteRecipe));
export { connectedDeleteRecipe as DeleteRecipe };
