import React, { Component } from 'react';
import { Table, Header, Button, Grid, Label,Dropdown,Popup,Input,Icon,Dimmer,Loader,Sidebar,Segment,Form,Message,Image,Breadcrumb,TextArea,Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {alertActions,recipesActions,categoryRecipeActions,supplierActions,paymentActions} from '../_actions'
import SideLeftUncoverProductions from './SidebarProductions';
import {history,toaster_dispatch,isNumeric,toaster_info,trim,unitWeight,unitVolume, MAX_NUMBER_RECIPES_FREE_SUBSCRIBER, 
    typeYield,isWeight,isVolume,mgrams,kgrams,cliters,grams,mliters,liters,pack_unit_key_reserved} from '../_helpers';

import {FormattedMessage,FormattedNumber,injectIntl,FormattedDate,FormattedTime} from 'react-intl';
import {isEmpty,uniqueId} from "lodash";
import {styles} from './Styles';

import img_pan from '../Assets/pan.png'
import img_planning from '../Assets/planning.png'
import img_performance from '../Assets/performance.png'
import './FloatingMenu.css';
//import OutsideClickHandler from 'react-outside-click-handler';

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

const DUPLICATE_ADD_INGREDIENT_MESSAGE_TITLE = <FormattedMessage id="production.toaster.ingredient.add.duplicate.message.title"
                                                  defaultMessage='Adding ingredient/recipe ...'/>
         
const DUPLICATE_ADD_INGREDIENT_MESSAGE_CONTENT = <FormattedMessage id="production.toaster.ingredient.add.duplicate.message.content"
                                                  defaultMessage='Already added to your order'/>


const UNITS = <FormattedMessage id="production.quantity.unit.plural.text"
                        defaultMessage='unit(s)'/>

                                                           

class Production extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location,intl} = props;
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);

        this.state = { prevRecipe : props.recipes,
                       filtering: false,
                       orderList:location && location.state && location.state.recipesList?location.state.recipesList : [],
                       availableRecipesList:[],
                       availableIngredientsList:[],
                       emptyOrders: true,
                       units: location && location.state && location.state.recipesUnits?location.state.recipesUnits : [],
                       ingredQuantities: location && location.state && location.state.ingredientsUnits?location.state.ingredientsUnits : [],
                       isMobile: false,
                       currency : userIn.user.currency,
                       newQuantity:0,
                       //orderName :  uniqueId(new Date().toLocaleDateString(props.intl.locale).split('T')[0]+'_PROD_'),
                       orderName :  uniqueId('PROD_'),
                       selectedDate: new Date(),
                       activeModalPremium: false,
                    }
        props.dispatch(recipesActions.getOrdersRecipes(0,'infinite', 0));
        props.dispatch(categoryRecipeActions.getAll()); 
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(supplierActions.getAll());

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }


    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        return null;
    }

    componentDidMount() {
        const {orderList} = this.state;

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (orderList && orderList.length > 0){
            //this.setState({ orderList:orderList});
            this.setState({ emptyOrders: false});
            history.replace(this.props.location.state, null);
        }

        this.setState({ selectedDate: this.props.location.state.date});
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setStartDate (date){
        this.setState({ selectedDate: date});
    }

    OnNoteChange = (e) => {
        this.setState({ note: e.target.value });
    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    seeIngredient (event,index) {
        event.preventDefault();
        history.push('/ingredientdetails/'+index);
    }

    createIngredient(){
        history.push('/newingredient/');
    }
    createRecipe(){
        history.push('/newrecipe/');
    }

    onNewQuantity = (e,item) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    addToCartList (event,recipe) {
        event.preventDefault();
        const {intl} = this.props;
        var found = this.state.orderList.find(x => x.id == recipe.id)

        if (!found){
            //this.props.dispatch(recipesActions.get(recipe.id));
            let updateOrderList = [...this.state.orderList,recipe]  
             //Sort list alphabetically
            updateOrderList = updateOrderList.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            this.setState({ orderList:updateOrderList});
            //this.setState({ orderList: [...this.state.orderList,recipe]});  
            this.setState({ units: [...this.state.units,{id: recipe.id, 
                                                         value: "1", 
                                                         quantity_type:typeYield(recipe.yield_units),
                                                         is_nb_recipes: false,
                                                         unit: recipe.yield_units.toLowerCase() }]});

            this.setState({ emptyOrders: false});
        }else{
            toaster_info(intl.formatMessage({ id: 'production.toaster.ingredient.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'production.toaster.ingredient.add.duplicate.message.content' }))
        }  
    }

    removeFromCartList (event,recipe) {
        event.preventDefault();
        const {orderList,units} = this.state;

        //Manage orders removal based on recipe name
        let updatedList = orderList.filter((item) => {
            return item.id!=recipe.id
        });

        if (updatedList.length == 0){
            this.setState({ orderList: []});  
            this.setState({ units: []}); 
        }
        else{
            this.setState({ orderList: updatedList});  
        }   

        //Manage units list removal based on index
        let updatedUnits = units.filter((item) => {
            return item.id!=recipe.id
          });
        this.setState({ units: updatedUnits});  

        if (updatedList.length==0){
            this.setState({ emptyOrders: true});
        }
    }

    assertQuantityPermittedIncluded(elem){
        let isFound = false;
        let unitPart = "";
        let inputs= elem.value.replace(/,/g, '.').toLowerCase();
        inputs = inputs.split(' ').filter(Boolean);

        if (inputs.length > 2)
          return false;
        
        if (inputs.length === 1){
          if (elem.quantity_type === 'quantity'){
            if (!isNumeric(inputs)) 
                  return false;
          }else{
            //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
            //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
            let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
            if (tokens.length !== 2)
              return false;
            for (let i of tokens){
              if (!isNumeric(i)) unitPart = i;
            }
          }
    
        }else{
          if (inputs.length > 2)
              return false
          if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
              return false
          for (let i of inputs){
            if (!isNumeric(i)) unitPart = i;
          }
        }
    
        if (elem.quantity_type === 'volume'){
          for (let unit of unitVolume ){
            if (unitPart === unit){
                isFound = true;
                break;
            }
          }
        }else if (elem.quantity_type === 'weight'){
          for (let unit of unitWeight ){
            if (unitPart === unit){
              isFound = true;
              //console.log('isFound');
              break;
            }
         }
        }else {
            if (inputs.length > 1)
                return false
            isFound = true;
        }
        return isFound;
      }
    


    handleAllRecipesValidation(){
        const { units} = this.state;
        let ret = true;
        units.forEach((item)=>{
            item.value = trim(item.value).toLowerCase().replace(/,/g, '.')
            if ((item.value == "") || (item.value == "0")|| (item.value.length == 0) || !isNumeric(item.value)){
                ret = false;    
            }     
            if (Math.trunc(item.value).toString().length>=9){
                ret = false;
            }
        });
        return ret;
    }

    handleAllIngredientsValidation(){
        const {ingredQuantities} = this.state;
        let ret = true;

        for (let elem of ingredQuantities){
            elem.value = trim(elem.value).toLowerCase().replace(/,/g, '.');
            if ((elem.value == "") || (elem.value == "0")|| (elem.value.length == 0)){
                ret = false;    
            } 
            if (!this.assertQuantityPermittedIncluded(elem)) ret = false;

            if (Math.trunc(elem.value).toString().length>=9){
                ret = false;
            }
        } 
        return ret;
    }

    cleanI18nDecimals(){
        const {units} = this.state;
        let cleanUnits=[]

          for (let u of units){
            cleanUnits.push({id:u.id,
                             value:trim(u.value).toLowerCase().replace(/,/g, '.'),
                             quantity_type:u.quantity_type,
                             is_nb_recipes: u.is_nb_recipes,
                             unit: u.unit});
          }
          this.setState({ units: cleanUnits});
      }
    

    createDirectOrder = (e) => {
        e.preventDefault();
        const { dispatch,customer } = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });
    
            this.cleanI18nDecimals();
    
            if (!this.handleAllRecipesValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                     defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                      defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                                 content:'order.quantity.createorder.check.error.message'}));   
    
                return;
            }
    
            if (!this.handleAllIngredientsValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                   defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                             content:'order.quantity.createorder.check.error.message'}));  
    
                return;
            }
    
            let properties = {
                    ref_name: trim(this.state.orderName).substring(0,80),
                    recipesUnits:this.state.units,
                    ingredientsUnits: this.state.ingredQuantities,
                    prod_date: this.state.selectedDate,
                    note: this.state.note,
                    is_prod: true,
            }
            this.props.dispatch(recipesActions.saveOrder(properties,false,true));  

        }else{
            this.setState({ activeModalPremium: true});
        }  
    
    } 

    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({categorySelected: ""});

        let updatedList = availableRecipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        const {availableRecipesList} = this.state;

        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});

        if (data.value!="")
        {
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(category => category.value == category_id)

            if (elem){
                let updatedList = availableRecipesList.filter((item) => {
                    if (item.category && item.category.id){
                        return item.category.id == elem.value
                    }
                    return null;
                });
                this.setState({newItems: updatedList});
                this.setState({filtering: true});
                this.setState({categorySelected: {id:category_id,
                                                  name:elem.text}});
            }
        }
    }

    onTypeQuantityChange = (event,data,id) => {
        event.preventDefault();

        let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        if (data.value == 'recipe') {
                           item.is_nb_recipes = true
                        }else{
                           item.is_nb_recipes = false
                        }
                    }
                    return item;
            }); 
        this.setState({ units: unitsList});
    }

    onTypeQuantityValue = (id,type) => {
        const {intl} = this.props;
        let toReturn = 'unit' 
        let found
   
        if (type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                if (found.is_nb_recipes) {
                    toReturn = 'recipe'
                 }else{
                    toReturn = found.unit
                 }
            } 
        }else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                    if (found.unit== '') {
                        toReturn = intl.formatMessage({ id: 'production.quantity.unit.plural.text' }) 
                     }else{
                        toReturn = found.unit
                     }
            } 
        }
        return toReturn
    }

    onInputQuantityValue = (id,type) => {
        let toReturn = '1' 
        let found
        if ( type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }
        return toReturn
    }

    onInputQuantityChange = (event,id) => {
        event.preventDefault();

        let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        //let input  = event.target.value;
                        //input = Number(trim(input.toString()).replace(/,/g, '.')).toFixed(3);
                        item.value = event.target.value
                    }
                    return item;
        }); 
        //console.log(unitsList)
        this.setState({ units: unitsList});
    }

    getRelevantOptions = (unit,type,purchase_pack) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'production.quantity.unit.plural.text' }) 
        
        unit =  unit.toLowerCase()
        if (type == 'recipe'){
            options= [{key:unit,text:unit,value:unit},
                      {key:'recipe',text:<FormattedMessage id="production.quantity.recipe.text"
                                                           defaultMessage='recipe(s)'/>,value:'recipe'}] 
        }else{
            if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value :pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                }else if (kgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }   
            }
            else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }
                 else if (mliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (liters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }     

            }else{
                options= [{key:pack_unit_key_reserved,text: purchase_pack + ' ' + emptyUnit,value:pack_unit_key_reserved},
                          {key:emptyUnit,text:emptyUnit,value:emptyUnit}]
            }
        }
        return options;
    }




    addOrdersRows(orders){
        const {currency/*,submitted,editCellRecipe,editCellIndexRecipe,newQuantity*/} = this.state;
        return orders.map((recipe,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell  width={5}>
                            <Icon name='food' color='blue'/>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black'}}>
                               {recipe.name.length >= 65 && 
                                  recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right' /* onClick={(event) => this.editQuantityHandlerInput(event,index,recipe,'recipe')} */ >
{/*                              {(!editCellRecipe || (editCellRecipe && index != editCellIndexRecipe) ) &&
                                <a href='#'><FormattedNumber value={this.getRecipeUnit(recipe)}/>{'  '}{ recipe.yield_units}</a>}
                            {editCellRecipe && index == editCellIndexRecipe && 
                            <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCellRecipe: false})}}>  */}
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input key={index} 
                                                        type="text"
                                                        id="quantity"
                                                        //value={newQuantity}
                                                        //onChange={this.onNewQuantity}
                                                        value ={this.onInputQuantityValue(recipe.id,'recipe')}
                                                        onChange={(event) => this.onInputQuantityChange(event,recipe.id)}
                                                        //onBlur={(event) => this.updateQuantityOnBlur(event,recipe,'recipe')} 
                                                        //onKeyPress={(event) => this.updateQuantityOnEnter(event,recipe,'recipe')} 
                                                        autoFocus
                                                        style={{height:'2.5em',minWidth:'5em',maxWidth:'8em',padding:'0em 0.5em 0em 0.5em'}}
                                                />  
                                            {/* {recipe.yield_units} */}
                                            <Form.Dropdown key={index} 
                                                        //button //basic
                                                        options={this.getRelevantOptions(recipe.yield_units,'recipe',"")}
                                                        onChange={(event,data)=>this.onTypeQuantityChange(event,data,recipe.id)}
                                                        value ={this.onTypeQuantityValue(recipe.id,'recipe')}
                                                        //style={{minWidth:'5em',maxWidth:'8em'}} 
                                                        style={styles.ButtonGreenNoMarginOrder}
                                                />
                                    </Form.Group>
                                </Form>
{/*                           </OutsideClickHandler>
                           } */}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                            {typeYield(recipe.yield_units) == 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{recipe.yield_units}</span>
                            }
                            {typeYield(recipe.yield_units) != 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_total} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}<FormattedMessage id="production.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                            }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                                    <Button basic circular className="ui small icon button" 
                                             onClick={(event)=>this.removeFromCartList(event,recipe)}>
                                            <i className="trash alternate outline icon"></i>
                                     </Button>
                            </Table.Cell>
                     </Table.Row>
                );
        });
    }
    
    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                                    /* onClick={(event)=>this.addToCartList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                  {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
{/*                         <Table.Cell width={3} textAlign='center'>{recipe.category && recipe.category.name}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToCartList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    toggleItemsMenu(e,type){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        //this.setState({toggled: false});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({categorySelected: ""});
    } 


    resetOrder(event){
        this.setState({ emptyOrders: true });
        this.setState({ orderList: []});  
        this.setState({ units: []}); 
        this.setState({ ingredQuantities: []});   
    }

    openPlanning = (e) => {
        e.preventDefault();
        history.push('/productions',{planning:true});
    }

    hideModal() {
        this.setState({ activeModalPremium: false });
    }

    render() { 
        const { categoriesRecipe,alert,dispatch,loading_r,loading_cr,intl,loading_o,customer } = this.props;
        const { newItems,filtering,nameSelected,categorySelected,emptyOrders,orderList,availableRecipesList,
            isMobile,sidebarToggled,selectedDate,note } = this.state;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
        let membership = null;
        let user = null;

        if (customer){
             membership = customer.membership;
             user = customer.user;
        }

		let className = "floating-menu";

        if (filtering){
                items = newItems
        }else{
                items = availableRecipesList
        }

        //Filter and notify restrictions if user unsubscribed
        if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

/*         //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        } */

        return ( 
                <div>
                    <Sidebar as={Segment}
                             animation={'overlay'}
                             direction={'right'}
                             visible={sidebarToggled}
                             vertical
                             width= {isMobile?'wide':'very wide'}
                             icon='labeled'
                             style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                             >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                      <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                      </div>  
                                      <Form>
                                      <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                         <Header as='h2' style={{'marginLeft': '1em'}}>
                                            <img src={img_pan} style={{width:'50px'}}/>
                                            <Header.Content>
                                            <FormattedMessage id="production.form.input.recipe.title"
                                                                  defaultMessage='Add recipes'/>
                                            </Header.Content>
                                        </Header>
                                       </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                             <FormattedMessage id="production.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />
                                                } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="production.form.filter.category.title"
                                                                        defaultMessage='Filter by category' />
                                                    </p>
                                                    <FormattedMessage id="production.form.filter.category.placeholder"
                                                                        defaultMessage='Select a category'>
                                                        {placeholder =>   
                                                            <Dropdown selection
                                                            options= {categoriesRecipe && categoriesRecipe.options} 
                                                            value= {categorySelected && categorySelected.id}
                                                            placeholder={placeholder} 
                                                            onChange={this.filterCategoryList}
                                                            clearable
                                                            fluid
                                                            disabled = {isLimited}
                                                            //style={{minWidth:'18em',maxWidth:'26em'}}
                                                            />}   
                                                    </FormattedMessage>
                                                </span>
                                        </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { (loading_r || loading_cr) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  //selectable 
                                                unstackable 
                                                //compact 
                                                //definition
                                                //striped 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
                                                {availableRecipesList.length > 0 && items &&
                                                    this.addRecipesRows(items)
                                                }
                                                {availableRecipesList.length == 0 &&
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="production.list.recipe.empty.message"
                                                                            defaultMessage='No recipe created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createRecipe}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="production.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="production.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="production.list.premium.message.upgradeplan.line2"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="production.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverProductions>
                        <Grid stackable  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                              <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="production.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/productions/'}><FormattedMessage id="production.link.orders"
                                                              defaultMessage='Productions'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active onClick={(e) => {this.openPlanning(e)}} ><FormattedMessage id="production.link.planning"
                                                              defaultMessage='Planning'/></Breadcrumb.Section>  
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="production.link.order"
                                                              defaultMessage='Production'/></Breadcrumb.Section>
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_planning} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="production.page.title"
                                                              defaultMessage='New production'/>
                                            <Header.Subheader><FormattedMessage id="production.header.caption.title"
                                                                    defaultMessage='Producing' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row>
                                <Grid.Column>
                                    <br/> 
                                    <Label  basic style={styles.FontLato14Bold} size='large'  color='blue'> 
                                                    <FormattedMessage id="production.list.date.title"
                                                                     defaultMessage='Production infos' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4}>
                                <Table  stackable
                                        //singleLine 
                                        //style={styles.FontLato13Border}
                                        style={{'background-color':'#fcfcfc', 'border-style':'none'}}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={2}></Table.Cell>
                                            <Table.Cell width={6} textAlign='left' >
                                                <Form  style={{paddingTop:'0em'}}  >
                                                <label><FormattedDate value={new Date(selectedDate)}
                                                                           year='numeric'
                                                                           month='long'
                                                                           day='2-digit'/>
                                                        {' '}
                                                         <FormattedTime value={new Date(selectedDate)}
                                                                        hour='numeric'
                                                                        minute='numeric'/>
                                                </label>
                                                <FormattedMessage id="production.list.datepicker.time.caption"
                                                                 defaultMessage='Time'>
                                                 {caption =>  
                                                    <DatePicker  showIcon
                                                                 selected={selectedDate}
                                                                 onChange={(date)=>this.setStartDate(date)}
                                                                 inline
                                                                 showTimeSelect
                                                                 dateFormat="Pp"
                                                                 locale={intl.locale}
                                                                 timeCaption={caption}
                                                                 showWeekNumbers
                                                    />}
                                                </FormattedMessage>
                                                </Form>
                                            </Table.Cell>
                                        {/*  <Table.Cell width={1}></Table.Cell> */}
                                            <Table.Cell width={2} textAlign='left'>
                                                <Form  style={{paddingTop:'0em'}}  >
                                                    <label><FormattedMessage id="production.list.note.title"
                                                                     defaultMessage='Production note' /></label>
                                                   <FormattedMessage id="production.list.note.placeholder"
                                                                     defaultMessage='Add instruction note'>
                                                     {placeholder =>   
                                                        <TextArea type="text" 
                                                                id="note" 
                                                                icon='text'
                                                                iconPosition='left'
                                                                value={note}
                                                                rows='4'
                                                                maxlength="650"
                                                                onChange={this.OnNoteChange} 
                                                                placeholder={placeholder}
                                                                style={{ minHeight: 160, minWidth:450, resize: 'none'}} />}
                                                 </FormattedMessage>
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell width={8}></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Row> 
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={1}/>
                            <Grid.Row>
                                <Grid.Column>
                                    <br/> 
                                    <Label  basic style={styles.FontLato14Bold} size='large'  color='blue'> 
                                                    <FormattedMessage id="production.list.selected.title"
                                                                     defaultMessage='Production list' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column  mobile={8} tablet={4} computer={4}/> 
                                <Grid.Column mobile={5} tablet={0} computer={6}/>
                                <Grid.Column mobile={3} tablet={16} computer={4}> 
                                  <div className={className}>
                                  <Popup trigger=
                                            { <Button //color='blue'
                                                      style={styles.ButtonGreyAddNoMarginOrder}
                                                      //size='big' 
                                                      onClick={(e) => {this.openPlanning(e)}}>
                                                          <Icon name='calendar alternate outline' />{' '}
                                                          <FormattedMessage id="production.floatingmenu.planning.icon.title"
                                                                            defaultMessage='Planning'/>
                                               </Button>}
                                             content={<FormattedMessage id="production.floatingmenu.planning.title"
                                                                        defaultMessage='Open planning'/>}
                                            position='top center'
                                            inverted
                                    />
                                  <Popup trigger=
                                            { <Button //circular 
                                                      //color='blue'
                                                      style={styles.ButtonBlueAddNoMarginOrder}
                                                      //size='big' 
                                                      //icon='book' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'recipe')}>
                                                          <Icon name='add circle' />{' '}
                                                          <FormattedMessage id="production.floatingmenu.recipes.icon.title"
                                                                            defaultMessage='Recipes'/>
                                               </Button>}
                                             content={<FormattedMessage id="production.floatingmenu.recipes.title"
                                                                        defaultMessage='Add recipes'/>}
                                            position='top center'
                                            inverted
                                    />
                                  </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           singleLine 
                                           //color={'grey'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={5}>
                                                    <FormattedMessage id="production.list.selected.column.element.recipe"
                                                                       defaultMessage='Recipe(s)' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    {/*  <FormattedMessage id="order.list.selected.column.units"
                                                                          defaultMessage='Unit(s)' /> */}
                                                        <FormattedMessage id="production.list.selected.column.units"
                                                                          defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                        <FormattedMessage id="production.list.selected.column.yield"
                                                                          defaultMessage='Yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                       <FormattedMessage id="production.list.selected.column.unitprice"
                                                                          defaultMessage='Unit price' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {!emptyOrders && orderList &&
                                                this.addOrdersRows(orderList)
                                        }                                        
                                        {emptyOrders && 
                                         <Table.Row>
                                            <Table.Cell>
                                                <FormattedMessage id="production.list.selected.column.empty.message"
                                                                       defaultMessage='No item added yet' />
                                            </Table.Cell>
                                          </Table.Row>
                                        }
                                        </Table.Body>
                                        {!emptyOrders && 
                                          <Table.Footer fullWidth>
                                            <Table.Row>
                                                    <Table.HeaderCell colSpan='5'>
                                                        <Button compact color='teal' 
                                                                floated='right'
                                                                size='small'
                                                                loading={loading_o}
                                                                style={styles.ButtonGreenNoMargin}
                                                                //disabled={!this.handleValidation() 
                                                                 //   || !this.assertNumericFields() || !this.handleIngredientsValidation()}
                                                                onClick={(e)=>this.createDirectOrder(e)}>
                                                                <FormattedMessage id="production.list.selected.button.order"
                                                                                    defaultMessage='New production task' />
                                                            </Button>
                                                    </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>}
                                        <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="production.planning.header.title"
                                                                    defaultMessage='Create planning task'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="production.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="production.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="production.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="production.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="production.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                        </Modal>
                                    </Table>
                                    <br/> <br/>
                                </Grid.Column>         
                            </Grid.Row>  
                        </Grid>
                    </SideLeftUncoverProductions>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,categoriesRecipe,alert,dispatch } = state;
    const { loading_r,loading_o} = state.recipes;
    const { loading_cr } = state.categoriesRecipe;
    const { subscription,customer } = state.payment;

    return {
        recipes,
        categoriesRecipe,
        alert,
        dispatch,
        loading_r,
        loading_cr,
        loading_o,
        subscription,
        customer
    };
}

const connectedProduction = injectIntl(connect(mapStateToProps)(Production));
export { connectedProduction as Production };
 
