import React, { Component } from 'react';
import { ingredientsActions,nutritionActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form,Grid, Header, Table,Icon,Message,Modal,Image,Dimmer,Loader,Breadcrumb} from 'semantic-ui-react';
import SideLeftUncoverIngredient from './SidebarIngredient';
import {Capitalize,history,get_payload, isEmpty, isNumeric, grams, mgrams, unitCalories} from '../_helpers';
import "./NutritionFacts.scss";
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'
import ReactToPrint from 'react-to-print';
import * as htmlToImage from 'html-to-image';

class NutritionFacts extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            prevNutrition : props.nutrition,
            prevIngredients : props.ingredients,
            selected: null,
            activeModalNutrition: null,
            activeModalEditNutrition: null,
            activeModalAddNutrition: null,
            inputText: '',
            nutrient_ready: false,
            nutrient_data : {},
            //current_data_edit : {},
            current_data_edit :{ usda_name:  'NA',
                                 usda_ndbno:  'NA',
                                 weight: 'NA',
                                 calories : '0 kcal',
                                 protein : '0 g',
                                 fiber : '0 g',
                                 fatTotal : '0 g',
                                 sugar : '0 g',
                                 calcium :'0 mg',
                                 iron : '0 mg',
                                 sodium : '0 g',
                                 vitaminC : '0 mg',
                                 vitaminA :  'NA',
                                 saturedFat : '0 g',
                                 transFat : 'NA',
                                 cholesterol : '0 g',
                                 totalCarbohydrates: '0 g',
                                 potassium :'0 mg',
                                },
            scratch_mode: true,
         }
        this.hideModal = this.hideModal.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveOnEdit = this.saveOnEdit.bind(this);
        this.saveOnSearch = this.saveOnSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        props.dispatch(ingredientsActions.get(props.match.params.ingredientId));
        props.dispatch(paymentActions.getCustomerMembership());
    }


    static getDerivedStateFromProps(props, state) {

      let result= {};
      let nbitems = 0;
      let nutrient_data = {};

      if((props.nutrition!==state.prevNutrition) && (props.nutrition.nutrients)) {
        
        const nutrients = props.nutrition.nutrients;
        Object.assign(result,{prevNutrition : props.nutrition});
        //Condition to be reworked

        if (nutrients.foodNutrients){
        //OLD API if needed
        //if (nutrients.foods){
          //USDA data 
          let calories = {value:0,unit:""};
          let protein = {value:0,unit:""};
          let fiber = {value:0,unit:""};
          let fatTotal = {value:0,unit:""};
          let sugar = {value:0,unit:""};
          let calcium = {value:0,unit:""};
          let potassium = {value:0,unit:""};
          let iron = {value:0,unit:""};
          let sodium = {value:0,unit:""};
          let vitaminC = {value:0,unit:""};
          let vitaminA = {value:0,unit:""};
          let saturedFat = {value:0,unit:""};
          let transFat = {value:0,unit:""};
          let cholesterol = {value:0,unit:""};
          let totalCarbohydrates = {value:0,unit:""};
          let usda_name = "";
          let usda_ndbno = "";

          //OLD API if needed
          //let elements = nutrients.foods[0].food.nutrients;
          //let description = nutrients.foods[0].food.desc;

          let elements = nutrients.foodNutrients;
          let description = nutrients.description;

          //OLD API if needed
 /*          elements.map((item,index) => {
            if (item.name == 'Energy') calories = {value:item.value,unit:item.unit};
            if (item.name == 'Protein') protein = {value:item.value,unit:item.unit};
            if (item.name == 'Fiber, total dietary') fiber = {value:item.value,unit:item.unit};
            if (item.name == 'Total lipid (fat)') fatTotal = {value:item.value,unit:item.unit};
            if (item.name == 'Sugars, total') sugar = {value:item.value,unit:item.unit}; 
            if (item.name == 'Calcium, Ca') calcium = {value:item.value,unit:item.unit};
            if (item.name == 'Iron, Fe') iron = {value:item.value,unit:item.unit};
            if (item.name == 'Sodium, Na') sodium = {value:item.value,unit:item.unit};
            if (item.name == 'Vitamin C, total ascorbic acid') vitaminC = {value:item.value,unit:item.unit};
            if (item.name == 'Vitamin A, RAE') vitaminA = {value:item.value,unit:item.unit};
            if (item.name == 'Fatty acids, total saturated') saturedFat = {value:item.value,unit:item.unit};
            if (item.name == 'Fatty acids, total trans') transFat = {value:item.value,unit:item.unit};
            if (item.name == 'Cholesterol') cholesterol = {value:item.value,unit:item.unit};
            if (item.name == 'Carbohydrate, by difference') totalCarbohydrates = {value:item.value,unit:item.unit};
          });*/

          elements.map((item,index) => {
            let nutrient = item.nutrient
            if ((nutrient.name == 'Energy') && (nutrient.unitName == 'kcal')) calories = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Protein') protein = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fiber, total dietary') fiber = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Total lipid (fat)') fatTotal = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Sugars, total including NLEA') sugar = {value:item.amount,unit:nutrient.unitName}; 
            if (nutrient.name == 'Calcium, Ca') calcium = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Iron, Fe') iron = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Sodium, Na') sodium = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Vitamin C, total ascorbic acid') vitaminC = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Vitamin A, RAE') vitaminA = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fatty acids, total saturated') saturedFat = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Fatty acids, total trans') transFat = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Cholesterol') cholesterol = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Carbohydrate, by difference') totalCarbohydrates = {value:item.amount,unit:nutrient.unitName};
            if (nutrient.name == 'Potassium, K') potassium = {value:item.amount,unit:nutrient.unitName};
          });

          if (description){
              //OLD API if needed
              //usda_name = description.name;
              //usda_ndbno = description.ndbno;
              usda_name = description;
              usda_ndbno = nutrients.fdcId
          }

          nutrient_data = {
                        usda_name: usda_name,
                        usda_ndbno: usda_ndbno,
                        weight: 'NA',
                        calories : calories.value + calories.unit,
                        protein : protein.value + protein.unit,
                        fiber : fiber.value + fiber.unit,
                        fatTotal : fatTotal.value + fatTotal.unit,
                        sugar : sugar.value + sugar.unit,
                        calcium : calcium.value + calcium.unit,
                        potassium : potassium.value + potassium.unit,
                        iron : iron.value + iron.unit,
                        sodium : sodium.value + sodium.unit,
                        vitaminC :vitaminC.value + vitaminC.unit,
                        vitaminA :vitaminA.value + vitaminA.unit,
                        saturedFat : saturedFat.value + saturedFat.unit,
                        transFat : transFat.value + transFat.unit,
                        cholesterol : cholesterol.value + cholesterol.unit,
                        totalCarbohydrates: totalCarbohydrates.value + totalCarbohydrates.unit}

        }else{
            //Ciqual data 
            nutrient_data = {
              usda_name: nutrients.alim_nom_fr,
              usda_ndbno: nutrients.alim_code,
              weight: 'NA',
              calories : nutrients.energie_kcal.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'kcal',
              protein : nutrients.proteines.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              fiber : nutrients.fibres.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              fatTotal : nutrients.lipides.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              sugar : nutrients.sucres.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              calcium : nutrients.calcium.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'mg',
              potassium : nutrients.potassium.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'mg',
              iron : nutrients.fer.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'mg',
              sodium : nutrients.sel.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0')+ 'g',
              vitaminC :nutrients.vitaminec.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'mg',
              vitaminA :  'NA',
              saturedFat : nutrients.ag_satures.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              transFat : 'NA',
              cholesterol : nutrients.cholesterol.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g',
              totalCarbohydrates: nutrients.glucides.replace(/,/g, '.').replace(/</g,'').replace(/traces/g,'0').replace(/-/g,'0') + 'g'}

        }
      
        Object.assign(result,{nutrient_data:nutrient_data})
        nbitems +=1;
      }

     if((props.ingredients!==state.prevIngredients) && (props.ingredients.selected)) {
          const selected = props.ingredients.selected;
          if (selected.metrics){
            Object.assign(result,{prevIngredients:props.ingredients})
            //Object.assign(result,{current_data:selected.metrics})
            Object.assign(result,{current_data_edit:selected.metrics})
            nbitems +=1;
          }
      }

      if (nbitems > 0)
        return result;
      return null;
    }

    componentDidMount() {
      const {intl} = this.props;
      let default_metric = (intl.locale == 'en')?'us':'france' 
      this.setState({ type_metrics: default_metric });
    }


    hideModal() {
      this.setState({ activeModalNutrition: null });
      this.setState({ activeModalEditNutrition: null });
      this.setState({ activeModalAddNutrition: null });
    }

    cancel() {
/*       let ingredientId = this.props.match.params.ingredientId;
      history.push('/');
      history.push('/ingredientnutritionfacts/'+ingredientId); */
      //history.goBack();
      window.location.reload(); 
    }

    saveOnSearch() {
      const {nutrient_ready,nutrient_data} = this.state;
      const {dispatch,selected} = this.props;
      let ingredientId = this.props.match.params.ingredientId;
      if ( nutrient_ready && nutrient_data && selected ){
        //PATCH Implementation
        //dispatch(ingredientsActions.update_nutrition(this.props.match.params.ingredientId,{metrics:nutrient_data}));
        //PUT/UPDATE Implementation
        Object.assign(nutrient_data,{ name:selected.name });
        const ingredient = {
                  name:selected.name,
                  price:selected.price,
                  note:selected.note,
                  purchase_weight:selected.purchase_weight,
                  purchase_volume:selected.purchase_volume,
                  purchase_quantity:selected.purchase_quantity,
                  category:selected.category,
                  metrics:nutrient_data
          }
        dispatch(ingredientsActions.update(ingredientId,ingredient,'/ingredientnutritionfacts/'+ingredientId,1));
      }
    }

    saveOnEdit() {
      const {current_data_edit} = this.state;
      const {dispatch,selected} = this.props;
      let ingredientId = this.props.match.params.ingredientId;
      if (current_data_edit && selected ){
        
        Object.assign(current_data_edit,{ name:selected.name });

        const ingredient = {
                  name:selected.name,
                  price:selected.price,
                  note:selected.note,
                  purchase_weight:selected.purchase_weight,
                  purchase_volume:selected.purchase_volume,
                  purchase_quantity:selected.purchase_quantity,
                  category:selected.category,
                  metrics:current_data_edit
          }
        dispatch(ingredientsActions.update(ingredientId,ingredient,'/ingredientnutritionfacts/'+ingredientId,1));
      }
    }
    
    handleInputChange(e, {value}) {
        this.setState({ inputText: value });
      }
    
    handleSearchNutrition(e) {
      this.props.dispatch(nutritionActions.search(this.state.inputText,this.props.intl.locale)); 
      this.setState({ activeModalNutrition: true});
      this.setState({ activeModalEditNutrition: null});
      this.setState({ activeModalAddNutrition: false});
      this.setState({ scratch_mode: true});
    }

    handleEditNutrition(e) {
      this.setState({ activeModalEditNutrition: true});
      this.setState({ activeModalNutrition: null});
      this.setState({ activeModalAddNutrition: false});
      this.setState({ scratch_mode: false});
    }

    handleAddNutrition(e) {
      this.setState({ activeModalEditNutrition: false});
      this.setState({ activeModalNutrition: null});
      this.setState({ activeModalAddNutrition: true});
      this.setState({ scratch_mode: true});
    }
        
    addNutritionItemsList(){
      const {items} = this.props.nutrition;
      const {intl} = this.props;
      let choices;
      if (intl && intl.locale == 'fr'){
        choices = items;
      }else {
        //OLD API if needed
        //choices = items.list.item;
        choices = items.foods;
      }

        return choices.map((item,index) => {
          //Specific USDA Branded ingredients (not SR Legacy)
           let description =""
           if (intl && intl.locale == 'en'){
            description = item.description
            if (item.dataType == 'Branded'){
              description = item.description.toLowerCase() + ' (' + item.brandOwner + ')'
            }
           }

          return (
            <Grid.Row columns={2}>
              <Grid.Column>
                <Icon name='food' color='teal'/>
                  {intl && intl.locale == 'fr' &&
                    <a href="#" 
                      onClick={(event) => this.addItem(event,item.alim_code)}>{item.alim_nom_fr}
                    </a>
                  }
                  {intl && intl.locale == 'en' &&
                    <a href="#" 
                      //OLD API if needed
                      //onClick={(event) => this.addItem(event,item.ndbno)}>{Capitalize(item.name)}
                       onClick={(event) => this.addItem(event,item.fdcId)}>{Capitalize(description)}
                    </a>
                  }
              </Grid.Column>
            </Grid.Row>
          )
        });
    }

    addItem (event,index) {
      event.preventDefault();
      //this.props.dispatch(recipesActions.get(index,'/recipedetails/'));
      //history.push('/recipedetails/'+index);
      this.props.dispatch(nutritionActions.getNutrition(index,this.props.intl.locale));
      this.setState({ activeModalNutrition: null});
      this.setState({ nutrient_ready: true});
    }

    currentDataChange = (e,{value,id}) => {
      //const {current_data} = this.state;
      const {scratch_mode} = this.state;
      const {selected} = this.props;
      //let current_data = selected.metrics;
      let current_data = null;
      if (scratch_mode){
        current_data = {
          calories : '0 kcal',
          protein : '0 g',
          fiber : '0 g',
          fatTotal : '0 g',
          sugar : '0 g',
          calcium :'0 mg',
          potassium :'0 mg',
          iron : '0 mg',
          sodium : '0 g',
          vitaminC : '0 mg',
          vitaminA :  'NA',
          saturedFat : '0 g',
          transFat : 'NA',
          cholesterol : '0 g',
          totalCarbohydrates: '0 g'}
      }else{
        current_data = selected.metrics;
      }
      e.preventDefault();
      //this.setState({ current_data: {...this.state.current_data, [id]:value } })
      //this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value } })
      
      //let agnostic_value = value.replace(/,/g, '.');
      //let value_value = get_payload(agnostic_value,true)
      //let value_unit = get_payload(agnostic_value,false)
      let value_value = value.replace(/,/g, '.');

      if ( //!isEmpty(value_unit)
          /*&&*/ isNumeric(value_value) 
          /*&& ( grams.includes(value_unit) ||
               mgrams.includes(value_unit) ||
               unitCalories.includes(value_unit.toLowerCase())
             )*/
          ){
            if (id=='calories'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.calories,false) }})
            }else if (id=='fatTotal'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.fatTotal,false) }})
            }else if (id=='saturedFat'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.saturedFat,false) }})
            }else if (id=='transFat'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.transFat,false) }})
            }else if (id=='cholesterol'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.cholesterol,false) }})
            }else if (id=='sodium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.sodium,false) }})
            }else if (id=='totalCarbohydrates'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.totalCarbohydrates,false) }})
            }else if (id=='fiber'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.fiber,false) }})
            }else if (id=='sugar'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.sugar,false) }})
            }else if (id=='protein'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.protein,false) }})
            }else if (id=='vitaminA'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.vitaminA,false) }}) 
            }else if (id=='vitaminC'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.vitaminC,false) }})
            }else if (id=='calcium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.calcium,false) }})                        
            }else if (id=='potassium'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.potassium,false) }})                        
            }
            else if (id=='iron'){
              this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:value_value + '' +  get_payload(current_data.iron,false) }})
            }else{
              //this.setState({ current_data_edit: {...this.state.current_data_edit, [id]:agnostic_value } })
            }
            
          } 
    } 

    editNutritionItemsList(){
      //const {current_data} = this.state;
      const {scratch_mode} = this.state;
      const {selected} = this.props;
      //let current_data = selected.metrics;
      let current_data = null;
      if (scratch_mode){
        current_data =
        {
          calories : '0 kcal',
          protein : '0 g',
          fiber : '0 g',
          fatTotal : '0 g',
          sugar : '0 g',
          calcium :'0 mg',
          potassium :'0 mg',
          iron : '0 mg',
          sodium : '0 g',
          vitaminC : '0 mg',
          vitaminA :  'NA',
          saturedFat : '0 g',
          transFat : 'NA',
          cholesterol : '0 g',
          totalCarbohydrates: '0 g'}
      }else{
        current_data = selected.metrics;
      }

      return  (
          <Grid.Row columns={2}>
            <Grid.Column>
                <Form>
{/*                   <Form.Field>
                    <label><FormattedMessage id="nutrition.edit.list.weight"
                                            defaultMessage='Weight'/>
                    </label>
                     <FormattedMessage id="nutrition.edit.list.weight.placeholder"
                                            defaultMessage='weight'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="weight"
                                  defaultValue={current_data.weight}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'6em',maxWidth:'8em'}}/>}
                     </FormattedMessage>
                  </Form.Field> */}
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.calories"
                                            defaultMessage='Calories'/>
                    </label>
                    <Form.Group inline >
                     <FormattedMessage id="nutrition.edit.list.calories.placeholder"
                                            defaultMessage='calories'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="calories"
                                  defaultValue={get_payload(current_data.calories,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.calories,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.totalfat"
                                            defaultMessage='Total Fat'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.totalfat.placeholder"
                                            defaultMessage='total fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="fatTotal"
                                  defaultValue={get_payload(current_data.fatTotal,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.fatTotal,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.saturedfat"
                                            defaultMessage='Satured Fat'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.saturedfat.placeholder"
                                            defaultMessage='satured fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="saturedFat"
                                  defaultValue={get_payload(current_data.saturedFat,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.saturedFat,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.transfat"
                                            defaultMessage='Trans Fat'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.transfat.placeholder"
                                            defaultMessage='Trans Fat'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="transFat"
                                  defaultValue={get_payload(current_data.transFat,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.transFat,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.cholesterol"
                                            defaultMessage='Cholesterol'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.cholesterol.placeholder"
                                            defaultMessage='cholesterol'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="cholesterol"
                                  defaultValue={get_payload(current_data.cholesterol,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.cholesterol,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.sodium"
                                            defaultMessage='Sodium'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.sodium.placeholder"
                                            defaultMessage='sodium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="sodium"
                                  defaultValue={get_payload(current_data.sodium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.sodium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.totalCarbohydrates"
                                            defaultMessage='Total Carbohydrates'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.totalCarbohydrates.placeholder"
                                            defaultMessage='total carbohydrates'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="totalCarbohydrates"
                                  defaultValue={get_payload(current_data.totalCarbohydrates,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.totalCarbohydrates,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <Form>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.fiber"
                                            defaultMessage='Fiber'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.fiber.placeholder"
                                            defaultMessage='Fiber'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="fiber"
                                  defaultValue={get_payload(current_data.fiber,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.fiber,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.sugar"
                                            defaultMessage='Sugars'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.sugar.placeholder"
                                            defaultMessage='sugars'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="sugar"
                                  defaultValue={get_payload(current_data.sugar,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.sugar,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.protein"
                                            defaultMessage='Protein'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.protein.placeholder"
                                            defaultMessage='Protein'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="protein"
                                  defaultValue={get_payload(current_data.protein,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.protein,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.vitaminA"
                                            defaultMessage='Vitamin A'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.vitaminA.placeholder"
                                            defaultMessage='vitamin A'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="vitaminA"
                                  defaultValue={get_payload(current_data.vitaminA,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.vitaminA,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Group inline></Form.Group>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.vitaminC"
                                            defaultMessage='Vitamin C'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.vitaminC.placeholder"
                                            defaultMessage='vitamin C'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="vitaminC"
                                  defaultValue={get_payload(current_data.vitaminC,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.vitaminC,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.calcium"
                                            defaultMessage='Calcium'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.calcium.placeholder"
                                            defaultMessage='calcium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="calcium"
                                  defaultValue={get_payload(current_data.calcium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.calcium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.iron"
                                            defaultMessage='Iron'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.iron.placeholder"
                                            defaultMessage='iron'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="iron"
                                  defaultValue={get_payload(current_data.iron,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.iron,false)}</label>
                     </Form.Group>
                  </Form.Field>
                  <Form.Field style={{marginLeft:'1em'}}>
                    <label><FormattedMessage id="nutrition.edit.list.potassium"
                                            defaultMessage='Potassium'/>
                    </label>
                    <Form.Group inline>
                     <FormattedMessage id="nutrition.edit.list.potassium.placeholder"
                                            defaultMessage='potassium'>      
                          {placeholder =>          
                              <Form.Input 
                                  type="text"
                                  placeholder={placeholder}
                                  id="potassium"
                                  defaultValue={get_payload(current_data.potassium,true)}
                                  onChange={this.currentDataChange}
                                  style={{minWidth:'4em',maxWidth:'6em'}}/>}
                     </FormattedMessage>
                     <label>{get_payload(current_data.potassium,false)}</label>
                     </Form.Group>
                  </Form.Field>
                </Form>
            </Grid.Column>
          </Grid.Row>
        );
  }
    

  extractPayload(field, unit, average){
    let value = field.split(unit)[0] 
    if (value>0){
      return Number((value / average) * 100).toFixed(0)
    }
    return 0;
  }

  addFoodFactsFR(data){
    const {selected} = this.props.ingredients;
    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

    return(
      <div id={'label-node'} style={{'backgroundColor':'white'}}>
      <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
      <Table compact striped unstackable singleline 
             color='grey' style={{'font-family': 'Lato', 'font-size': '14px','color': 'grey','marginLeft':'0.2em', 'backgroundColor':'white'}} >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>Pour 100g/100ml</Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>%VNR *</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell width={4} style={styles.FontLato14Bold}><b>Energie</b></Table.Cell>
          <Table.Cell width={2} textAlign='right'>{data.calories} ({energyKJ}kJ)</Table.Cell>
          <Table.Cell width={2} textAlign='right'>{this.extractPayload(data.calories,"kcal",2000)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Matières Grasses</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.fatTotal}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.fatTotal,"g",70)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato12}>dont acides gras saturés</Table.Cell>
          <Table.Cell textAlign='right'>{data.saturedFat}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.saturedFat,"g",20)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Glucides</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.totalCarbohydrates}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.totalCarbohydrates,"g",260)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato12}>dont sucres</Table.Cell>
          <Table.Cell textAlign='right'>{data.sugar}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.sugar,"g",90)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Fibres</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.fiber}</Table.Cell>
          <Table.Cell textAlign='right'>-</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Protéines</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.protein}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.protein,"g",90)}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={styles.FontLato14Bold}><b>Sel</b></Table.Cell>
          <Table.Cell textAlign='right'>{data.sodium}</Table.Cell>
          <Table.Cell textAlign='right'>{this.extractPayload(data.sodium,"g",6)}%</Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Footer>
          <Table.Row fullwidth width={8}>
             <Table.Cell textAlign='left' colSpan='3'>
              <p>* Pourcentage de la valeur quotidienne selon un régime alimentaire de 2000 calories. 
              Vos valeurs quotidiennes personnelles peuvent être plus ou moins élevées selon vos besoins énérgetiques</p>
            </Table.Cell>
          </Table.Row>
      </Table.Footer>
    </Table>
    </div>
    )
  }

  addFoodFactsCA(data){
      //const {intl} = this.props;
      const {selected} = this.props.ingredients;
      var energyKJ = data.calories.split("kcal")
      energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

      return (
           <div id={'label-node'}  class="performance-facts_around" style={{'backgroundColor':'white'}}>
           <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
            <section class="performance-facts" style={{'font-size': '12px','color': 'black','marginLeft':'0.2em', 'backgroundColor':'white'}} >
            <header /* class="performance-facts__header" */ >
              <h1 class="performance-facts__title">Nutrition Facts</h1>
              <h1 class="performance-facts__title">Valeurs nutritives</h1>
            </header>
            <table class="performance-facts__table">
              <thead>
                <tr>
                  <th colspan="3" class="small-info">Per/Pour 100g/100ml</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2"  style={{'font-family': 'verdana', 'font-size': '15px'}}>
                    <b>Calories</b> {data.calories} ({energyKJ}kJ)
                  </th>
                </tr>
                <tr class="thick-row">
                  <td colspan="3" class="small-info">
                    <b>% Daily value*</b><br/>
                    <b>% Valeur quotidienne*</b>
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Fat / Lipides</b>  {data.fatTotal}
                  </th>
                  <td >
                     {this.extractPayload(data.fatTotal,"g",70)}%
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    Saturated / Saturé {data.saturedFat}
                  </th>
                  <td class="blank-cell">
                      {this.extractPayload(data.saturedFat,"g",20)}%
                  </td>
                </tr>
                <tr > 
                  <th colspan="2">
                    <b>Carbohydrates / Glucides</b> {data.totalCarbohydrates}
                  </th>
                  <td> 
                      {this.extractPayload(data.totalCarbohydrates,"g",260)}%
                  </td>
                </tr>
                <tr>
                  <td class="blank-cell"></td>
                  <th>
                    Sugars / Sucres {data.sugar}
                  </th>
                  <td class="blank-cell">
                    {this.extractPayload(data.sugar,"g",90)}%
                  </td>
                </tr>
                <tr>
                <td class="blank-cell"></td>
                  <th>
                    Fibre / Fibres {data.fiber}
                  </th>
                  <td class="blank-cell">
                    {this.extractPayload(data.fiber,"g",25)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Protein / Protéines</b> {data.protein}
                  </th>
                  <td >
                    {this.extractPayload(data.protein,"g",90)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Cholesterol / Cholestérol</b> {data.cholesterol}
                  </th>
                  <td >
                    {this.extractPayload(data.cholesterol,"g",300)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <b>Sodium / Sel</b> {data.sodium}
                  </th>
                  <td>
                    {this.extractPayload(data.sodium,"g",6)}%
                  </td>
                </tr>
                <tr class="thick-row">
                  <th colspan="2">
                      <span>Potassium {data.potassium}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.potassium,"mg",3500)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                    <span>Calcium {data.calcium}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.calcium,"mg",1000)}%
                  </td>
                </tr>
                <tr>
                  <th colspan="2">
                      <span>Iron / Fer {data.iron}</span>
                  </th>
                  <td>
                    {this.extractPayload(data.iron,"mg",18)}%
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="performance-facts__table--grid">
            <tr class="thick-row">
              <th colspan="1">
               <span class="small-info">* 5% or less is  <b>a little</b>, 15% or more is  <b>a lot</b></span><br/>
               <span class="small-info">* 5% ou moins c'est <b>peu</b>, 15% ou plus c'est  <b>beaucoup</b></span>
              </th>
            </tr>
            </table> 
         </section>
         </div>
        )
  }


  addFoodFactsUS(data){
    //const {intl} = this.props;
    const {selected} = this.props.ingredients;
    var energyKJ = data.calories.split("kcal")
    energyKJ = Number(energyKJ[0]* 4.1868).toFixed(0)

    return (
        <div id={'label-node'}  class="performance-facts_around" style={{'backgroundColor':'white'}}>
        <h5 style={{'marginLeft':'0.5em'}}> {selected.name}</h5>
        <section class="performance-facts" style={{'font-family': 'verdana', 'font-size': '11px','color': 'grey','marginLeft':'0.2em', 'backgroundColor':'white'}}>
          <header class="performance-facts__header">
            <h1 class="performance-facts__title">Nutrition Facts</h1>
{/*                 <p>Serving Size 1/2 cup (about 82g)</p>
            <p>Serving Per Container 8</p> */}
          </header>
          <table class="performance-facts__table">
            <thead>
              <tr>
                <th colspan="3" class="small-info">Amount Per 100g/100ml</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">
                  <b>Calories</b> {data.calories}
                </th>
                <td>
                  {/* Calories from Fat 0 */}
                </td>
              </tr>
              <tr class="thick-row">
                <td colspan="3" class="small-info">
                  <b>% Daily Value*</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Total Fat</b> {data.fatTotal}
                </th>
                <td>
                  <b>{this.extractPayload(data.fatTotal,"g",65)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell"></td>
                <th>
                  Saturated Fat {data.saturedFat}
                </th>
                <td>
                  <b>{this.extractPayload(data.saturedFat,"g",20)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Trans Fat {data.transFat}
                </th>
                <td>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Cholesterol</b> {data.cholesterol}
                </th>
                <td>
                  <b>{this.extractPayload(data.cholesterol,"g",300)}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Sodium</b> {data.sodium}
                </th>
                <td>
                  <b>{this.extractPayload(data.sodium,"g",2.4)}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Total Carbohydrate</b> {data.totalCarbohydrates}
                </th>
                <td>
                  <b>{this.extractPayload(data.totalCarbohydrates,"g",300)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Dietary Fiber {data.fiber}
                </th>
                <td>
                  <b>{this.extractPayload(data.fiber,"g",25)}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Sugars {data.sugar}
                </th>
                <td>
                </td>
              </tr>
              <tr class="thick-end">
                <th colspan="2">
                  <b>Protein</b> {data.protein}
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="performance-facts__table--grid">
            <tbody>
              <tr>
                <td colspan="2">
                  Vitamin A {data.vitaminA}
                </td>
                <td>
                  Vitamin C {data.vitaminC}
                </td>
              </tr>
              <tr class="thin-end">
                <td colspan="2">
                  Calcium {data.calcium}
                </td>
                <td>
                  Iron {data.iron}
                </td>
              </tr>
            </tbody>
          </table>
         <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs:</p>
        <table class="performance-facts__table--small small-info">
          <thead>
            <tr>
              <td colspan="2"></td>
              <th>Calories:</th>
              <th>2,000</th>
              <th>2,500</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="2">Total Fat</th>
              <td>Less than</td>
              <td>65g</td>
              <td>80g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th>Saturated Fat</th>
              <td>Less than</td>
              <td>20g</td>
              <td>25g</td>
            </tr>
            <tr>
              <th colspan="2">Cholesterol</th>
              <td>Less than</td>
              <td>300mg</td>
              <td>300 mg</td>
            </tr>
            <tr>
              <th colspan="2">Sodium</th>
              <td>Less than</td>
              <td>2,400mg</td>
              <td>2,400mg</td>
            </tr>
            <tr>
              <th colspan="3">Total Carbohydrate</th>
              <td>300g</td>
              <td>375g</td>
            </tr>
            <tr>
              <td class="blank-cell"></td>
              <th colspan="2">Dietary Fiber</th>
              <td>25g</td>
              <td>30g</td>
            </tr>
          </tbody>
        </table>
        <p class="small-info">
          Calories per gram:
        </p>
        <p class="small-info text-center">
          Fat 9
          &bull;
          Carbohydrate 4
          &bull;
          Protein 4
        </p>
      </section>
      </div>
      )  
 }

 
  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  onTypeMetricsChange = (e,data) => {
    e.preventDefault();
    this.setState({ type_metrics: data.value });
  }

  htmlToImageConvert = () =>  {
    var node = document.getElementById('label-node');

    if (node){
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = 'label-nutrition.png';
          link.href = dataUrl;
          link.click();
        });
    }
  }
 
 render() {
    const { ingredients,nutrition,searching,retrieving,customer,intl,updating,loading} = this.props;
    const {nutrient_ready,activeModalEditNutrition, activeModalAddNutrition,activeModalNutrition,nutrient_data,type_metrics} = this.state;
    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
      //console.log(user);
      //console.log(membership);
  } 

    return (
      <div>
        { loading &&
        <SideLeftUncoverIngredient>
          <Dimmer active={(loading)} blurring inverted>
                  <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverIngredient>
       }
       { membership && user && 
            (((membership.membership_type=="Free")||(membership.membership_type=="Professional")) && (user.bypass==false)) &&
        <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
          <Grid stackable /* style={styles.FontLato12} */>
            <Grid.Row columns={1}>
                    <Breadcrumb style={{background:'#fcfcfc'}}>
                              <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="nutrition.link.dashboard"
                                                                    defaultMessage='Dashboard'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="nutrition.link.recipes"
                                                                  defaultMessage='Ingredients'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{ingredients && ingredients.selected && ingredients.selected.name}</Breadcrumb.Section>   
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section active><FormattedMessage id="nutrition.link.nutrition"
                                                                    defaultMessage='Nutrition'/></Breadcrumb.Section>               
                    </Breadcrumb>
            </Grid.Row>
            <Grid.Row>
                  <Grid.Column width={8}>
                          <Header as='h2'>
                              <Icon name='heartbeat' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="nutrition.header.title"
                                                    defaultMessage=' Nutrition Facts'/>
                                  <Header.Subheader>{ingredients && ingredients.selected && ingredients.selected.name}</Header.Subheader>
                                </Header.Content>
                          </Header>
{/*                           <Header as='h3' color='blue' textAlign='left'>
                              {ingredients && ingredients.selected && ingredients.selected.name}
                          </Header> */}
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                  <Grid.Column /*width={8}*/  mobile={14} tablet={12} computer={8}>
                      <br/>
                      <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                          <Image src={img_performance} size ='small' rounded/>
                          <br/>
                          <Message.Content style={{marginLeft:'1em'}}>
                              <Message.Header color='black'>
                                  <FormattedMessage id="nutrition.premium.message.upgradeplan"
                                                      defaultMessage='ENTERPRISE Feature'/>
                              </Message.Header>
                              <br/>
                              <FormattedMessage id="nutrition.premium.title.message.upgradeplan"
                                              defaultMessage='Your current plan: '/>{' '}{membership.name}   
                              <br/><br/>
                              <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                  <FormattedMessage id="nutrition.premium.button.chooseplan"
                                              defaultMessage='Choose premium plan'/>
                              </Button>                                      
                          </Message.Content>

                      </Message>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
        </SideLeftUncoverIngredient>
      }
      { membership && user &&
           ((membership.membership_type=="Entreprise") || (user.bypass==true)) &&  
      <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
         <Grid stackable style={styles.FontLato12}>
          <Grid.Row columns={1}>
                    <Breadcrumb style={{background:'#fcfcfc'}}>
                              <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="nutrition.link.dashboard"
                                                                    defaultMessage='Dashboard'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="nutrition.link.ingredients"
                                                                  defaultMessage='Ingredients'/></Breadcrumb.Section>
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{ingredients && ingredients.selected && ingredients.selected.name}</Breadcrumb.Section>   
                              <Breadcrumb.Divider icon='right chevron'/>
                              <Breadcrumb.Section active><FormattedMessage id="nutrition.link.nutrition"
                                                                    defaultMessage='Nutrition'/></Breadcrumb.Section>               
                    </Breadcrumb>
          </Grid.Row>
          <Grid.Row columns={2}>
              <Grid.Column /*width={8}*/  mobile={14} tablet={12} computer={8}>
                    <Header as='h1'>
                        <Icon name='heartbeat' size='mini' color='blue'/>
                        <Header.Content>
                        <FormattedMessage id="nutrition.header.title"
                                         defaultMessage=' Nutrition Facts'/>
                          <Header.Subheader>{ingredients && ingredients.selected && ingredients.selected.name}</Header.Subheader>
                        </Header.Content>
                    </Header>
{/*                     <Header as='h3' color='blue' textAlign='left'>
                      {ingredients && ingredients.selected && ingredients.selected.name}
                    </Header> */}
                    <br/>
                  <Form>
                  {!nutrient_ready && ingredients && ingredients.selected &&
                  <div>
                   <Form.Field /*style={{paddingLeft:'6em'}} */>
                    <br/>
                    <label  style={styles.FontLato14BoldGrey}/*style={{paddingLeft:'3em'}}*/>
                          <FormattedMessage id="nutrition.form.search.title"
                                            defaultMessage='Retrieve/Update nutrient data (USDA database)'/>
                    </label>
                    <FormattedMessage id="nutrition.form.search.placeholder"
                                      defaultMessage='Enter product/ingredient name'>
                          {placeholder => 
                              <Form.Input //defaultValue={this.state.value} 
                                      size='large'
                                      onChange={this.handleInputChange}
                                      action={{ color: 'teal', icon: 'search', onClick: (e) => {this.handleSearchNutrition()} }}
                                      placeholder={placeholder}
                                      style={{minWidth:'20em',maxWidth:'30em'}}/>
                          } 
                    </FormattedMessage>
                    <Modal open={activeModalNutrition}
                                onClose={this.hideModal}
                                style={styles.Modal}
                                closeIcon>
                        <Modal.Header>
                                <FormattedMessage id="nutrition.form.edit.title"
                                                  defaultMessage='Searching Nutrient Database'/>
                        </Modal.Header>
                        <Modal.Content /*image*/ scrolling>
                        {/*  <Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' wrapped /> */}
                        <Modal.Description>
                            <Header>
                                  <FormattedMessage id="nutrition.modal.content.header"
                                                    defaultMessage='From USDA Database source'/>
                            </Header>
                            <p>
                                  <FormattedMessage id="nutrition.modal.content.subheader"
                                                    defaultMessage='Select the item that match your ingredient'/>
                            </p>
                            <br/>
                            <Grid stackable style={styles.FontLato14}>
                            { !searching && nutrition && nutrition.items && this.addNutritionItemsList()}
                            </Grid>
                            </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button style={styles.ButtonGrey}//basic color='grey' 
                                      onClick= {(e) => this.hideModal()}>
                                 {/* <Icon name='exit' /> */}
                                <FormattedMessage id="nutrition.modal.button.cancel"
                                                  defaultMessage='Cancel'/>
                                </Button>
                            </Modal.Actions>
                     </Modal>
                     <br/>
{/*                      <label>
                            <FormattedMessage id="nutrition.edit.title"
                                                  defaultMessage='Edit current nutrient information: {activeModalEditNutrition}'
                                                  values={{activeModalEditNutrition:activeModalEditNutrition}}/>
                     </label>
                     <div style={{paddingLeft:'3em'}}>
                        <Button basic color='teal' 
                               onClick={(e) => {this.handleEditNutrition()}} 
                               disabled={!ingredients.selected.metrics}>
                                 <FormattedMessage id="nutrition.edit.button.title"
                                                  defaultMessage='Edit nutrition facts'/>
                               </Button>
                     </div> */}
                     <Modal open={activeModalEditNutrition}
                                onClose={this.hideModal}
                                style={styles.Modal}
                                closeIcon>
                        <Modal.Header>
                                <FormattedMessage id="nutrition.edit.modal.title"
                                                  defaultMessage='Edit Nutrient'/> 
                        </Modal.Header>
                        <Modal.Content scrolling>
                        <Modal.Description>
                            <Header>
                                  <FormattedMessage id="nutrition.edit.modal.ingredient.name"
                                                    defaultMessage='For {name}'
                                                    values={{name:ingredients.selected.name}}/>
                            </Header>
                            <br/>
                            <Grid stackable style={styles.FontLato12}>
                             {ingredients.selected.metrics 
                                && this.editNutritionItemsList()}
                            </Grid>
                          </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                             <Button style={styles.ButtonGreen} 
                                 //basic color='teal' 
                                  onClick= {this.saveOnEdit}>
                                  <FormattedMessage id="nutrition.edit.modal.save"
                                                  defaultMessage='Save Nutrient Data'/> 
                              </Button>
                              <Button style={styles.ButtonGrey}
                                  //basic color='grey' 
                                  onClick= {this.hideModal}
                                  style={{marginLeft:'1em'}}>   
                                 <FormattedMessage id="nutrition.edit.modal.cancel"
                                                  defaultMessage='Cancel'/> 
                              </Button>
                           </Modal.Actions>
                    </Modal>
                  </Form.Field>
                </div>}
                {!nutrient_ready && ingredients && ingredients.selected && ingredients.selected.metrics &&
                <div>
                    <Form.Field>
                        <label><FormattedMessage id="nutrition.form.select.nutrition.type"
                                                  defaultMessage='Nutrition Tags Format'/>
                        </label>
                        <FormattedMessage id="nutrition.form.select.nutrition.type.placeholder"
                                          defaultMessage='Select'>
                              {placeholder =>   
                                      <Form.Select  
                                              options={ [{key:'france',text:'FR (INCO)',value:'france'},
                                                        {key:'us',text:'USA (FDA)',value:'us'},
                                                        {key:'canada',text:'CA (CANADA)',value:'canada'}] } 
                                              value = {type_metrics} 
                                              placeholder={placeholder} 
                                              style={{minWidth:'15em',maxWidth:'30em'}}
                                              onChange={this.onTypeMetricsChange}/>}
                        </FormattedMessage>    
                    </Form.Field>
                    <Form.Field>
                        <br/>
                        <label>
                                 <FormattedMessage id="nutrition.show.metrics.title"
                                                  defaultMessage='Current metrics'/> 
                        </label>
{/*                         {intl.locale == 'fr' && this.addFoodFactsFr(ingredients.selected.metrics)}
                        {intl.locale == 'en' && this.addFoodFacts(ingredients.selected.metrics)} */}
                        <div ref={el => (this.componentRef = el)}>
                        {type_metrics =='france' && 
                                  this.addFoodFactsFR(ingredients.selected.metrics)}
                            {type_metrics =='canada' && 
                                  this.addFoodFactsCA(ingredients.selected.metrics)}
                            {type_metrics =='us' &&
                                  this.addFoodFactsUS(ingredients.selected.metrics)}
                        </div>
                     </Form.Field>
{/*                      {type_metrics =='france' && 
                            <Form.Field>
                                <br/>
                                <p style={styles.FontLato12}> Vitamin A {ingredients.selected.metrics.vitaminA} Vitamin D {ingredients.selected.metrics.vitaminD}</p>
                                <p style={styles.FontLato12}> Calcium {ingredients.selected.metrics.calcium} Fer {ingredients.selected.metrics.iron}</p>
                      </Form.Field> } */}
                     <Form.Field >
                          <br/>
                          <Button  style={styles.ButtonGreen}  
                                  onClick={(e) => {this.handleEditNutrition()}}
                                  disabled={!ingredients.selected.metrics}>
                                    <FormattedMessage id="nutrition.edit.button.title"
                                                      defaultMessage='Edit nutrition facts'/>
                          </Button>
                          <Button  style={styles.ButtonBlue}  
                                  onClick={(e) => {this.htmlToImageConvert()}}
                                  disabled={!ingredients.selected.metrics}>
                                    <FormattedMessage id="nutrition.download.button.title"
                                                      defaultMessage='Download tag as image'/>
                          </Button>
                          <ReactToPrint
                            trigger={() => {
                                      return  <Button style={styles.ButtonBlue} 
                                                      disabled={!ingredients.selected.metrics}>
                                                  <FormattedMessage id="nutrition.print.button.title"
                                                                    defaultMessage='Print tag'/>
                                              </Button>;}}
                            content={() => this.componentRef}
                            pageStyle="@page { margin: 0;} html{ margin: 0;} body {margin: 0; transform: scale(.6);}"
                         />
                     </Form.Field>
                </div>
                }
               { ingredients && ingredients.selected && !ingredients.selected.metrics && !nutrient_ready &&
                <Form.Field >
                          <Button style={styles.ButtonGreen}  
                                  onClick={(e) => {this.handleAddNutrition()}}>
                                    <FormattedMessage id="nutrition.add.button.title"
                                                      defaultMessage='Add nutrition values manually'/>
                          </Button>
                          <Modal open={activeModalAddNutrition}
                                onClose={this.hideModal}
                                style={styles.Modal}
                                closeIcon>
                            <Modal.Header>
                                    <FormattedMessage id="nutrition.add.modal.title"
                                                      defaultMessage='Add Nutrient'/> 
                            </Modal.Header>
                            <Modal.Content scrolling>
                            <Modal.Description>
                                <Header>
                                      <FormattedMessage id="nutrition.add.modal.ingredient.name"
                                                        defaultMessage='For {name}'
                                                        values={{name:ingredients.selected.name}}/>
                                </Header>
                                <br/>
                                <Grid stackable style={styles.FontLato12}>
                                    {this.editNutritionItemsList()}
                                </Grid>
                              </Modal.Description>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button style={styles.ButtonGreen} 
                                      onClick= {this.saveOnEdit}>
                                      <FormattedMessage id="nutrition.add.modal.save"
                                                      defaultMessage='Save Nutrient Data'/> 
                                  </Button>
                                  <Button style={styles.ButtonGrey}
                                      onClick= {this.hideModal}
                                      style={{marginLeft:'1em'}}>   
                                    <FormattedMessage id="nutrition.add.modal.cancel"
                                                      defaultMessage='Cancel'/> 
                                  </Button>
                              </Modal.Actions>
                    </Modal>
                </Form.Field>}
                {!retrieving && nutrient_ready && nutrition && nutrition.nutrients &&
                  <div style={{paddingLeft:'2em'}}>
                        <Form.Field> 
                            <br/>
{/*                             {intl.locale == 'fr' && this.addFoodFactsFr(nutrient_data)}
                            {intl.locale == 'en' && this.addFoodFacts(nutrient_data)} */}
                            {type_metrics =='france' && 
                                  this.addFoodFactsFR(nutrient_data)}
                            {type_metrics =='canada' && 
                                  this.addFoodFactsCA(nutrient_data)}
                            {type_metrics =='us' &&
                                  this.addFoodFactsUS(nutrient_data)}
                        </Form.Field>  
                        <Form.Field style={{paddingLeft:'6em'}}>
                              <br/>
                              {updating && <Button loading /* color='teal' */ style={styles.ButtonGreen} onClick= {this.saveOnSearch}>
                                  <FormattedMessage id="nutrition.select.choice.save"
                                                  defaultMessage='Save'/>  
                              </Button>}
                              {!updating && <Button /* color='teal' */ style={styles.ButtonGreen} onClick= {this.saveOnSearch}>
                                  <FormattedMessage id="nutrition.select.choice.save"
                                                  defaultMessage='Save'/>  
                              </Button>}
                              <Button /* color='grey'  */style={styles.ButtonGrey} onClick= {this.cancel}  style={{marginLeft:'1em'}}>
                                  <FormattedMessage id="nutrition.select.choice.cancel"
                                                  defaultMessage='Cancel'/>  
                              </Button>
                          </Form.Field> 
                  </div>} 
                </Form>
            </Grid.Column>
{/*              <Grid.Column width={5}>
              <br/><br/><br/><br/><br/>
              <Image size='huge' src="/assets/calorie-intake-calories.jpg"/>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>

      </SideLeftUncoverIngredient>
      }
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { ingredients, nutrition,dispatch} = state;
  const { selected, updating,loading} = state.ingredients;
  const { searching,retrieving} = state.nutrition;
  const { customer } = state.payment;
  return {
        ingredients,
        nutrition,
        searching,
        loading,
        updating,
        retrieving,
        customer,
        selected,
        dispatch
  };
}

const connectedNutritionFacts = injectIntl(connect(mapStateToProps)(NutritionFacts));
export { connectedNutritionFacts as NutritionFacts };
