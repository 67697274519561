import { authHeader,nonAuthHeader } from '../_helpers';
import { serverConstants } from '../_constants';
import { saveAs } from 'file-saver';


export const paymentService = {
    getSubscription,
    getSubscriptionState,
    getSubscriptionExtraUsers,
    subscribe,
    unsubscribe,
    getAvailableMemberships,
    getAvailableExtraMemberships,
    getCustomerMembership,
    getCustomerInvoices,
    getCustomerCharges,
    renderInvoice,
    updateCard,
    sendVoucher,
    validateVoucher,
    getCustomerCoupons,
    activateOfferVoucher
};


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
            const error = new Error(json.message)
            return Promise.reject(Object.assign(error, { response }))
        })
    }

    return response.json();
}

function getSubscription() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    //return fetch(serverConstants.BACKEND_URL_TEST+'/stripe/subscription/', requestOptions).then(handleResponse);
    return fetch(serverConstants.BACKEND_URL+'/subscribe/', requestOptions).then(handleResponse)
                 .then(subscription => {
                    // login successful if there's a jwt token in the response
                    //if (subscription ) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        //localStorage.setItem('subscription', JSON.stringify(subscription));
                    //}
                    return subscription;
                });
}


function getSubscriptionState() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    //return fetch(serverConstants.BACKEND_URL_TEST+'/stripe/subscription/', requestOptions).then(handleResponse);
    return fetch(serverConstants.BACKEND_URL+'/subscribe/?state_only=1', requestOptions).then(handleResponse)
                 .then(subscription => {
                    // login successful if there's a jwt token in the response
                    //if (subscription ) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        //localStorage.setItem('subscription', JSON.stringify(subscription));
                    //}
                    return subscription;
                });
}

function getSubscriptionExtraUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/subscribe/?extra_users=1', requestOptions).then(handleResponse)
                 .then(subscription => {
                    return subscription;
                });
}


function subscribe(user_infos) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user_infos)
    };

    //return fetch(serverConstants.BACKEND_URL_TEST+'/stripe/subscription/', requestOptions).then(handleResponse);
    return fetch(serverConstants.BACKEND_URL+'/subscribe/', requestOptions).then(handleResponse);
}


function unsubscribe() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    //return fetch(serverConstants.BACKEND_URL_TEST+'/stripe/subscription/', requestOptions).then(handleResponse);
    return fetch(serverConstants.BACKEND_URL+'/subscribe/', requestOptions).then(handleResponse);
}

function updateCard(pay_infos) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(pay_infos)
    };

    return fetch(serverConstants.BACKEND_URL+'/customer/', requestOptions).then(handleResponse);
}


function getAvailableMemberships() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        //headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/memberships/', requestOptions).then(handleResponse);
}

function getAvailableExtraMemberships() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        //headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/memberships/extras/', requestOptions).then(handleResponse);
}


function getCustomerMembership() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    //return fetch(serverConstants.BACKEND_URL+'/customers/customer_membership/', requestOptions).then(handleResponse);
    return fetch(serverConstants.BACKEND_URL+'/customers/customer_membership/', requestOptions).then(handleResponse)
        .then(customer => {
            // login successful if there's a jwt token in the response
            //if (customer ) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('customer', JSON.stringify(customer.membership));
            //}
            return customer;
        });
}


function getCustomerInvoices() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/invoices/invoice_customer/', requestOptions).then(handleResponse);
}


function getCustomerCharges() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/charges/charge_customer/', requestOptions).then(handleResponse);
}


function renderInvoice(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genInvoice/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'invoice.pdf'))
}


function sendVoucher(params) {
    const requestOptions = {
        method: 'POST',
        headers: nonAuthHeader(),
        body: JSON.stringify(params),
    };

    return fetch(serverConstants.BACKEND_URL+'/sendVoucher/', requestOptions).then(handleResponse);
}


function validateVoucher(params) {
    const requestOptions = {
        method: 'GET',
        headers: nonAuthHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/validateVoucher'+params, requestOptions).then(handleResponse);
}


function activateOfferVoucher(params) {
    const requestOptions = {
        method: 'GET',
        headers: nonAuthHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/activateOfferVoucher'+params, requestOptions).then(handleResponse);
}

function getCustomerCoupons() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/coupons', requestOptions).then(handleResponse);
}