import React, { Component } from 'react';
import { Table, Button, Grid,Header,Dropdown,Input,Icon,Dimmer,Loader, Message,Image,Breadcrumb } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {recipesActions/*,categoryRecipeActions*/,paymentActions} from '../_actions'
import './Recipes.css';
import SideLeftUncoverRecipes from './SidebarRecipes';
import {history,toaster_dispatch,toaster_info,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,allergens} from '../_helpers';
import {FormattedMessage/*,FormattedNumber*/,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_gluten from '../Assets/gluten.png'
import {isEmpty} from "lodash"
import './FloatingMenu.css';
//import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';
import img_performance from '../Assets/performance.png'
import img_allergens_milk from '../Assets/milk.png'
import img_allergens_mollusc from '../Assets/homard.png'
import img_allergens_mustard from '../Assets/moutarde.png'
import img_allergens_lupin from '../Assets/lupin.png'
import img_allergens_gluten from '../Assets/gluten2.png'
import img_allergens_peanuts from '../Assets/arachide.png'
import img_allergens_fish from '../Assets/poisson.png'
import img_allergens_crustaceans from '../Assets/coquille.png'
import img_allergens_sulfite from '../Assets/sulfite.png'
import img_allergens_celery from '../Assets/celeri.png'
import img_allergens_eggs from '../Assets/oeuf.png'
import img_allergens_soya from '../Assets/soja.png'
import img_allergens_sesame from '../Assets/sesame.png'
import img_allergens_nuts from '../Assets/amande.png'

/* const options = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
    { key: '100', text: '100', value: 100 },
  ] */

const ALLERGENS_MESSAGE_TITLE = <FormattedMessage id="allergens.toaster.message.title"
  defaultMessage='Allergens matrix table'/>

const ALLERGENS_MESSAGE_CONTENT = <FormattedMessage id="allergens.toaster.message.content"
  defaultMessage='Document creation ongoing ...'/>

class AllergensRecipe extends Component {
    constructor(props) {
        super(props);
        //let userIn = JSON.parse(localStorage.getItem('userIn'));
        //this.hideModal = this.hideModal.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        //this.filterCategoryList=this.filterCategoryList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        //this.createIngredient = this.createIngredient.bind(this);
        //this.createRecipe = this.createRecipe.bind(this);
        //this.createOrder = this.createOrder.bind(this);
        //this.setPageNum = this.setPageNum.bind(this);
        //this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        //this.submitAction=this.submitAction.bind(this);
        this.cancelAction=this.cancelAction.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.state = { 
            //activeModal: null,
            filtering: false,
            //currency : userIn.user.currency,
            toggled: false,
            isMobile: false,
            //page: 1,
            //itemsPerPage: 50,
            selectionList:[],
            actionSelected: "",
            //actionModal: false,
            allowMultipleSelection:true,
         }
         
        props.dispatch(recipesActions.getAllergensRecipes());
        props.dispatch(paymentActions.getSubscriptionState());
        //props.dispatch(categoryRecipeActions.getAll());
 
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

/*     setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }
 
    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModal: index});
    }; 

     hideModal() {
        this.setState({ activeModal: null })
        this.setState({ actionModal: false })
        //this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    } */

    seeRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/recipedetails/'));
        history.push('/recipedetails/'+index);
    }
/*  
    editRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/editrecipe/'));
        history.push('/editrecipe/'+index);
    }

    deleteRecipe (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(recipesActions.delete(index));
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)    
    }

    downloadRecipe (event,index) {
        event.preventDefault();
        this.props.dispatch(recipesActions.get(index,'/downloadrecipe/'+index));
    } 


   createIngredient(){
        history.push('/newingredient/');
    }

    createRecipe(){
        history.push('/newrecipe/');
    }

    createOrder(){
        history.push('/orders/');
    } */

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        //this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
    }

    filterNameList(event){
        event.preventDefault();
        
        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.props.recipes.items.filter((item) => {
          return (item.name).toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: event.target.value});
        //this.setState({categorySelected: ""});
        this.setState({ selectionList:[]});
    }

/*     filterCategoryList(event,data){
        event.preventDefault();
        let updatedList = this.props.recipes.items.filter((item) => {
            if (item.category__name){
                return item.category__name.toLowerCase().search(data.value.toLowerCase()) !== -1;
            }
            return null;    
        });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({categorySelected: data.value});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
    } */

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;
        const { dispatch,intl } = this.props;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && (data.value == 'export' || data.value == 'exportall')){
            //this.setState({actionModal: true});
            if (data.value  == 'export' ){
                dispatch(recipesActions.allergensMatrix(false,selectionList))
            }else if (data.value  == 'exportall' ){
                dispatch(recipesActions.allergensMatrix(true,selectionList))
            }
            toaster_info(intl.formatMessage({ id: 'allergens.toaster.message.title' }),
                                              intl.formatMessage({ id: 'allergens.toaster.message.content' }))   
            this.setState({ selectionList:[]});
            this.setState({isCheckedAll: false});
        }
    }

/*     submitAction(event){
        event.preventDefault();
        //this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
        this.setState({actionModal: true});
    } */

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }

/*     actionSelectExport(event) {
        event.preventDefault();
        const { selectionList,actionSelected } = this.state;
        if (actionSelected == 'export' ){
            this.props.dispatch(recipesActions.allergensMatrix(false,selectionList))
        }else if (actionSelected == 'exportall' ){
            this.props.dispatch(recipesActions.allergensMatrix(true,selectionList))
        }
        this.setState({ selectionList:[]});
    } */

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((ingredient,index) => {
                allList.push(ingredient.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }


    checkAllergenInRecipe(nameAllergen,recipe){
        if (recipe.allergens){
            for (let elem of recipe.allergens){
                if (nameAllergen == elem.name){
                    return true
                }
            }       
        }
        return false
    }


    addRecipesRows(recipes){
        const {/* currency, */allowMultipleSelection} = this.state;

        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={3} /*textAlign='center'*/>
                         {allowMultipleSelection && 
                            <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,recipe.id)}
                                   checked={this.isChecked(recipe.id)}
                                   style={{'transform': 'scale(1.1)'}}
                            />}
                            <span style={{marginLeft:'0.7em'}}>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               >{recipe.name}
                            </a></span>
                        </Table.Cell>
                        <Table.Cell  width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('peanuts',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('celery',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('crustaceans',recipe) &&
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('egg',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('fish',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('gluten',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('lupin',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('milk',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('molluscs',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('mustard',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('nuts',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('sesame',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('soya',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                            {this.checkAllergenInRecipe('sulphites',recipe) && 
                                 <Icon color='grey' name='checkmark' size='large' />
                            }
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }


    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }
    
    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    render() { 
        const { recipes,/* categoriesRecipe */alert,dispatch,loading_r,loading_cr,intl } = this.props;
        const { newItems,filtering,/* categorySelected, */nameSelected,isMobile,page,itemsPerPage,
                selectionList,isCheckedAll,actionSelected ,allowMultipleSelection} = this.state;

        //let buttons = [];
		//let className = "floating-menu";
		/* let icon = <Icon name='add'/>;
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;
            buttons.push(<FloatingMenuItemOther label={<FormattedMessage id="recipes.floatingmenu.item3.title" defaultMessage="Create an order"/>}  
                                                 icon={<Icon name='cart'/>} 
                                                 action={this.createOrder} 
                                                 mobile={isMobile}/>);
            buttons.push(<FloatingMenuItemOther label={<FormattedMessage id="recipes.floatingmenu.item2.title" defaultMessage= "Create an ingredient"/>}  
                                                icon={<Icon name='shopping basket'/>} 
                                                action={this.createIngredient} 
                                                mobile={isMobile} key="i1"/>);
            buttons.push(<FloatingMenuItem label={<FormattedMessage id="recipes.floatingmenu.item1.title" defaultMessage='Create a recipe'/>} 
                                            icon={<Icon name='book'/>} 
                                            action={this.createRecipe} 
                                            mobile={isMobile}/>);
            
		}
		buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>);  */

        //let totalPages = 1;
        let items = [];
        let length = 0;
        //let begin = 0;
        //let end  = 0;

        if (!filtering && recipes && recipes.items){
            length = recipes.items.length;
/*             totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = recipes.items.slice(begin,end); */
            items = recipes.items
        }
        if (filtering && newItems ){
            length = newItems.length;
 /*            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = newItems.slice(begin,end); */
            items = newItems
        }

        //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

        return ( 
                     <SideLeftUncoverRecipes>
                        <Grid stackable style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="allergens.link.dashboard"
                                                                            defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/recipes/'}><FormattedMessage id="allergens.link.recipes"
                                                                            defaultMessage='Recipes'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="allergens.link.categories"
                                                                            defaultMessage='Allergens'/></Breadcrumb.Section>
                             </Breadcrumb>
                             </Grid.Row>
                             <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_gluten} style={{width:'50px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="allergens.page.title"
                                                              defaultMessage='Allergens'/>
                                            <Header.Subheader><FormattedMessage id="allergens.page.subtitle"
                                                              defaultMessage='Export your matrix chart'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                             </Grid.Row> 
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Message compact icon>
                                    <Icon name='info circle' color='teal'/>
                                    <Message.Content>
                                        <Message.Header>
                                            <FormattedMessage id="allergens.message.title"
                                                                defaultMessage='Create your customized matrix'/>
                                        </Message.Header>
                                        
                                        <FormattedMessage id="allergens.message.details"
                                                                defaultMessage='Tick on "All recipes" or "Select" your recipes to create your document.' />
                                        
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column > 
                                    <p style={styles.FontLato14Grey}><FormattedMessage id="allergens.filter.name.title"
                                                         defaultMessage='Search'/></p>
                                    <FormattedMessage id="allergens.filter.byname.placeholder"
                                                        defaultMessage='Type a name'>
                                        {placeholder =>                                                       
                                            <Input
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                disabled={this.isUnSubscribedUser()}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}} ><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column width={2}/> 
{/*                                 <Grid.Column> 
                                   <p style={styles.FontLato14Grey}><FormattedMessage id="recipes.filter.category.title"
                                                         defaultMessage='Filter by category'/></p>
                                    <div><FormattedMessage id="recipes.filter.category.placeholder"
                                                        defaultMessage='Select a category'>
                                        {placeholder =>  
                                                <Dropdown selection
                                                        options= {categoriesRecipe && categoriesRecipe.options} 
                                                        value= {categorySelected}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}>
                                                    </Dropdown>}
                                         </FormattedMessage>
                                   </div> 
                                </Grid.Column>  */}
                            </Grid.Row>
{/*                             <Grid.Row columns={1} >
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                        {buttons}
                                    </div>
                                </Grid.Column>
                             </Grid.Row> */}
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    {selectionList.length > 0 && 
                                            <FormattedMessage id="allergens.action.main.placeholder"
                                                            defaultMessage='{number} selected'
                                                            values={{number:selectionList.length}}>
                                                {placeholder =>  
                                                        <Dropdown selection floating labeled
                                                                options= {[
                                                                    /* { key: 'none', icon: 'select', 
                                                                        text: <FormattedMessage id="recipes.action.info.nbitems.text"
                                                                                                defaultMessage='{number} selected'
                                                                                                values={{number:selectionList.length}}/>, value: 'none' }, */
                                                                    { key: 'none', text: ' ', value: 'none' },
                                                                    { key: 'export', icon: 'file alternate outline', 
                                                                        text: <FormattedMessage id="allergens.action.export.text"
                                                                                    defaultMessage='Export selected'/>, value: 'export' },
                                                                    { key: 'exportall', icon: 'file archive outline', 
                                                                                    text: <FormattedMessage id="allergens.action.exportall.text"
                                                                                                defaultMessage='Export all'/>, value: 'exportall' },]}
                                                                value= {actionSelected}
                                                                text={placeholder}
                                                                className='icon'
                                                                onChange={this.selectActionList}
                                                                style={{'font-size':'13px','letter-spacing': '0.025em',minWidth:'200px',
                                                                         'background-color':'#66b2b2',color:'white'}}/>}
                                            </FormattedMessage>}
{/*                                         {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="recipes.action.button.exportall.text"
                                                                   defaultMessage='Export all'/>
                                        </Button>} */}
                                        {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="allergens.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}
                                   <Dimmer.Dimmable>
                                        <Dimmer active={((loading_r))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                                    <Table //definition
                                           //selectable 
                                           //stackable 
                                           celled
                                           unstackable
                                           compact 
                                           striped 
                                           //singleLine 
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell>Number #</Table.HeaderCell> */}
                                                <Table.HeaderCell width={4} /*textAlign='center'*/>
                                                {allowMultipleSelection && <Input type='checkbox'
                                                            onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.1)'}}
                                                            disabled={this.isUnSubscribedUser()}
                                                            checked={isCheckedAll}/>}
                                                        <span style={{marginLeft:'0.7em'}}><FormattedMessage id="allergens.table.header.column.name"
                                                                          defaultMessage='All recipes'/></span>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell  width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ height:'100px','background-color':'red', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.peanuts)}<br/><br/>
                                                        <img src={img_allergens_peanuts} width='45px'/>
                                                        {/* </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'olive', color:'white'}}
                                                                 >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.celery)}<br/><br/>
                                                        <img src={img_allergens_celery} width='45px'/>
                                                      {/*   </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'pink', color:'white'}}
                                                                 >
                                                      {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.crustaceans)}<br/><br/>
                                                        <img src={img_allergens_crustaceans} width='45px'/>
                                                     {/*    </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{/*  height:'100px', */'background-color':'#f5e76e', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.egg)}<br/><br/>
                                                        <img src={img_allergens_eggs} width='45px'/>
                                                       {/*  </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                //style={{/*  height:'100px', */'background-color':'teal', color:'white'}}
                                                                >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                       {intl.formatMessage(allergens.fish)}<br/><br/>
                                                       <img src={img_allergens_fish} width='45px'/>
                                                      {/*  </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'brown', color:'white'}}
                                                                 >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.gluten)}<br/><br/>
                                                        <img src={img_allergens_gluten} width='45px'/>
                                                        {/* </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'grey', color:'white'}}
                                                                 >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.lupin)}<br/><br/>
                                                        <img src={img_allergens_lupin} width='45px'/>
                                                        {/* </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'blue', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.milk)}<br/><br/>
                                                        <img src={img_allergens_milk} width='45px'/>
                                                         {/*   </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'purple', color:'white'}}
                                                                 >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.molluscs)}<br/><br/>
                                                        <img src={img_allergens_mollusc} width='45px'/>
                                                       {/* </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'brown', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.mustard)}<br/><br/>
                                                        <img src={img_allergens_mustard} width='45px'/>
                                                       {/*  </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'violet', color:'white'}}
                                                                 >
                                                       {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.nuts)}<br/><br/>
                                                        <img src={img_allergens_nuts} width='45px'/>
                                                       {/*  </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'#f5e76e', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.sesame)}<br/><br/>
                                                        <img src={img_allergens_sesame} width='45px'/>
                                                       {/*  </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //style={{ /* height:'100px', */'background-color':'green', color:'white'}}
                                                                 >
                                                        {/* <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.soya)}<br/><br/>
                                                        <img src={img_allergens_soya} width='45px'/>
                                                        {/* </div> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} 
                                                                 textAlign='center' 
                                                                 //</Table.Row>style={{ /* height:'100px', */'background-color':'orange', color:'white'}}
                                                                 >
                                                       {/*  <div style={{'transform': 'rotate(-45deg)'}}> */}
                                                        {intl.formatMessage(allergens.sulphites)}<br/><br/>
                                                        <img src={img_allergens_sulfite} width='45px'/>
                                                       {/*  </div> */}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                      <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="allergens.table.header.row.noitems"
                                                                            defaultMessage='No recipes'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                            </Table.Row>}
                                        {items &&
                                            this.addRecipesRows(items)
                                        }
                                        </Table.Body>
                                      {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='15'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="allergens.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Recipes limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {recipes && recipes.items && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="allergens.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{recipes.items.length}{' '}
                                                                <FormattedMessage id="allergens.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="allergens.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                    </Table>  
                                    </Dimmer.Dimmable>                               
                                </Grid.Column>
                            </Grid.Row>
{/*                             <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={50} 
                                                  options={options} 
                                                  //simple 
                                                  item
                                                  inline
                                                  //icon='list'
                                                  //button 
                                                  fluid
                                                  //search
                                                  //header='Page'
                                                  //text='ingredients'
                                                  onChange={this.onElemPerPageChange}
                                                  disabled={this.isUnSubscribedUser()}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="recipes.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="recipes.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={1}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                        disabled={this.isUnSubscribedUser()}
                                    />
                                </Grid.Column>
                            </Grid.Row>*/}
                        </Grid> 
{/*                         <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='file alternate outline' content={<FormattedMessage id="allergens.modal.select.export.title"
                                                                                defaultMessage='Export your allergen matrix'/>}  />
                                <Modal.Content>
                                   {actionSelected == 'export' &&
                                        <p><FormattedMessage id="allergens.modal.select.export"
                                                         defaultMessage='Really sure to export selected recipe(s)?'
                                        /></p>}
                                  {actionSelected == 'exportall' &&
                                        <p><FormattedMessage id="allergens.modal.select.exportall"
                                                         defaultMessage='Really sure to export all recipe(s)?'
                                        /></p>}      
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="allergens.modal.select.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="allergens.modal.select.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.actionSelectExport(event)}/>
                                    </Modal.Actions>
                        </Modal> */}
                     </SideLeftUncoverRecipes>
        )
    }
}

function mapStateToProps(state) {
    const { recipes/*,categoriesRecipe*/,alert,dispatch } = state;
    const { loading_r } = state.recipes;
    //const { loading_cr } = state.categoriesRecipe;
    const { subscription } = state.payment;
    return {
        recipes,
        //categoriesRecipe,
        alert,
        dispatch,
        loading_r,
        //loading_cr,
        subscription
    };
}

const connectedAllergensRecipe = injectIntl(connect(mapStateToProps)(AllergensRecipe));
export { connectedAllergensRecipe as AllergensRecipe };
 