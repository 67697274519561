import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
    Flag,
    Dropdown
} from 'semantic-ui-react';

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {FormattedMessage,injectIntl} from 'react-intl';
import {HashLink as Link} from 'react-router-hash-link';
import { userActions,localeActions} from '../_actions';
import { serverConstants} from '../_constants';
import {toaster_dispatch} from '../_helpers';
import ButterToast from 'butter-toast';
import { connect } from 'react-redux';
import img_logo from '../Assets/ratatool-logo.png';



/* const localeOptions = [
  {
    key: 'fr',
    text: 'Français',
    value: 'fr',
    image: <Flag name='fr'/>,
  },
  {
    key: 'en',
    text: 'English',
    value: 'en',
    image: <Flag name='us'/>,
  },
] */


function retrieveDopdownWithURL(intl) {

  let target_pathname= window.location.pathname;
  if  (window.location.pathname.indexOf('/article/') != -1){
    target_pathname = '/blog'
  }
 
  return (
    <Dropdown.Menu>
      <Dropdown.Item text='Français' icon={<Flag name='fr'/>} selected={intl.locale=='fr'} 
      as='a' href={serverConstants.FRONTEND_URL_FR + target_pathname} />
      <Dropdown.Item text='English'  icon={<Flag name='us'/>} selected={intl.locale=='en'}
      as='a' href={serverConstants.FRONTEND_URL_EN + target_pathname}/>
    </Dropdown.Menu>
  )
} 


class DesktopContainer extends Component {
    constructor(props) {
      super(props);
      this.state = { }
  
    }

    componentDidMount() {
      //const { dispatch } = this.props;
       //let user = JSON.parse(localStorage.getItem('userIn'));
       /*if (user && user.token){
          dispatch(userActions.verify(user.token));
       }*/
       //dispatch(paymentActions.getAvailableMemberships());
    }
  
    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })

  
    render() {
  
      const { children,loggedIn,alert,dispatch,intl } = this.props;
      const { fixed } = this.state;
  
      return (
        <Responsive minWidth={1150/*Responsive.onlyComputer.minWidth*/}>
          <Visibility once={false} 
                      onBottomPassed={this.showFixedMenu} 
                      onBottomPassedReverse={this.hideFixedMenu}
                      >
            <Segment textAlign='center' 
                      style={{ height:85, width:'100vw', padding: '1em 0em 1em 0em', border:'none' }} 
                      vertical> 
                <Menu borderless 
                  attached={!fixed}
                  fixed={fixed ? 'top' : null}
                  //inverted={!fixed}
                  pointing={!fixed}
                  secondary={!fixed}
                  size='huge'
                  style={{border:'none'}}
                  >
                  <Container style={{width: '1400px',border:'none'}}>
                    {/* <Menu.Item header > */}
                    <a href="/">
                        <img  alt='logo' src={img_logo} style={{ marginRight: '1.5em',width: '150px', height: '43px' }}/>
                    </a>
                    {/* </Menu.Item> */}
                    <Menu.Item/>
{/*                      <Menu.Item as={Link} to='/' >
                        <FormattedMessage id="pricing.top.menu.home"
                                          defaultMessage="Home"/>
                    </Menu.Item> */}
                    <Menu.Item as={Link} to='/overview' >                      
                          <FormattedMessage id="pricing.top.menu.overview"
                                            defaultMessage="Features"/>
                   </Menu.Item>
{/*                     <Menu.Item as={Link} to='/dashboard' >                      
                            <FormattedMessage id="pricing.top.menu.dashboard"
                                              defaultMessage="Dashboard"/>
                    </Menu.Item> */}
                    <Menu.Item as={Link} to='/pricing' >                      
                            <FormattedMessage id="pricing.top.menu.pricing"
                                              defaultMessage="Plans & Pricing"/>
                    </Menu.Item>
                    <Menu.Item>
                      <Dropdown inline
                                pointing ='top left'
                                compact
                                style={{color:'black'}}
                                text={<FormattedMessage id="pricing.top.menu.resource"
                                                        defaultMessage="Resources"/>}
                                >
                        <Dropdown.Menu >
                        {/* <Dropdown.Header>{'        '}</Dropdown.Header> */}
                        <Dropdown.Item  as={Link} to='/blog'> <Icon name='blogger b' color='blue'/> <FormattedMessage id="pricing.top.menu.blog"
                                                  defaultMessage="Blog"/></Dropdown.Item>
                        <Dropdown.Item  as={Link} to='/contact'><Icon name='comment alternate outline' color='blue'/><FormattedMessage id="pricing.top.menu.about"
                                                  defaultMessage="Contact"/></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
{/*                     <Menu.Item as={Link} to='/blog'>                      
                            <FormattedMessage id="pricing.top.menu.blog"
                                              defaultMessage="Blog"/>
                    </Menu.Item> 
                    <Menu.Item as={Link} to='/contact' >                      
                            <FormattedMessage id="pricing.top.menu.about"
                                              defaultMessage="Contact"/>
                    </Menu.Item>*/}
                    <Menu.Item> 
                      <span>
                        <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                        <Dropdown text={intl.locale}
                            inline
                            //upward={true}
                            pointing ='top left'
                            compact
                            style={{color:'#4286e3'}}
                            //value = {intl.locale}
                            //options ={localeOptions}
                            >
                            {retrieveDopdownWithURL(intl)}
                          </Dropdown>
                      </span>
                    </Menu.Item>
                    <Menu.Item position='right'>
                    {loggedIn && <Link to="/dashboard">
                                <FormattedMessage id="pricing.top.menu.dashboard"
                                                                  defaultMessage="Dashboard"/> &#8594;
                              </Link> }
                             {/*  <Button  basic compact as={Link} to="/login" >
                                                <FormattedMessage id="homepage.top.menu.logout"
                                                                  defaultMessage="Log Out"/>
                                 </Button>*/} 
                    {!loggedIn && <Button basic as={Link} to="/login" >
                                      <FormattedMessage id="pricing.top.menu.login"
                                                        defaultMessage="Log In"/>
                                  </Button>}
                    {!loggedIn && <Button as={Link} to="/register" secondary={true}  style={{ marginLeft: '0.5em', backgroundColor:'#63a4dd' }}>
                                  <FormattedMessage id="pricing.top.menu.signup"
                                                        defaultMessage="Sign Up Free"/>
                                  </Button>}
                    </Menu.Item>
                  </Container>
                </Menu>
              </Segment>
          </Visibility> 
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
          {children}
        </Responsive>
      )
    }
  }
  
  
  DesktopContainer.propTypes = {
    children: PropTypes.node,
    loggedIn: PropTypes.bool
  }
  
  const ConnectedDesktopContainer = injectIntl(connect(mapStateToProps)(DesktopContainer));
  


  class MobileContainer extends Component {
    state={}
  
  /*   componentDidMount() {
      let user = JSON.parse(localStorage.getItem('userIn'));
      if (user && user.token){
         this.props.dispatch(userActions.verify(user.token));
      }
      const { dispatch} = this.props;
      dispatch(paymentActions.getAvailableMemberships());
   } */
    
    handlePusherClick = () => {
      const { sidebarOpened } = this.state
  
      if (sidebarOpened) this.setState({ sidebarOpened: false })
    }
  
    handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })
  
  
    render() {
      const { children,loggedIn,intl } = this.props
      const { sidebarOpened } = this.state
  
      return (
        <Responsive maxWidth={1149/*Responsive.onlyTablet.maxWidth*/}>
          <Sidebar.Pushable>
          <Sidebar as={Menu} secondary vertical animation='uncover' size='big' visible={sidebarOpened}>
                  <Menu.Item as={Link} to='/'>
                        <FormattedMessage id="pricing.left.menu.mobile.home"
                                           defaultMessage="Home"/>
                  </Menu.Item>
                  <Menu.Item as={Link} to='/overview' >
                      <FormattedMessage id="pricing.left.menu.mobile.overview"
                                         defaultMessage="Features"/>
                  </Menu.Item>
{/*                   <Menu.Item as={Link} to='/dashboard' >
                        <FormattedMessage id="pricing.left.menu.mobile.dashboard"
                                           defaultMessage="Dashboard"/>
                  </Menu.Item> */}
                  <Menu.Item as={Link} to='/pricing'>
                        <FormattedMessage id="pricing.left.menu.mobile.pricing"
                                           defaultMessage="Plans & Pricing"/>
                  </Menu.Item>
                  <Menu.Item>
                      <Dropdown inline
                                pointing ='top left'
                                compact
                                style={{color:'black'}}
                                text={<FormattedMessage id="pricing.left.menu.mobile.resource"
                                                         defaultMessage="Resources"/>}
                                 >
                        <Dropdown.Menu >
                        {/* <Dropdown.Header>{'        '}</Dropdown.Header> */}
                        <Dropdown.Item  as={Link} to='/blog'> <Icon name='blogger b' color='blue'/> <FormattedMessage id="pricing.left.menu.mobile.blog"
                                                  defaultMessage="Blog"/></Dropdown.Item>
                        <Dropdown.Item  as={Link} to='/contact'><Icon name='comment alternate outline' color='blue'/><FormattedMessage id="pricing.left.menu.mobile.about"
                                                  defaultMessage="Contact"/></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
{/*                   <Menu.Item as='a' href='/blog'>
                    <FormattedMessage id="pricing.left.menu.mobile.blog"
                                           defaultMessage="Blog"/>
                  </Menu.Item>
                  <Menu.Item as='a' href='/contact'>
                      <FormattedMessage id="pricing.left.menu.mobile.about"
                                           defaultMessage="Contact"/>
                  </Menu.Item> */}
                  <Menu.Item>
                    <span>
                      <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                      <Dropdown text={intl.locale}
                          inline
                          //upward={true}
                          pointing ='top left'
                          compact
                          style={{color:'#4286e3'}}
                          //value = {intl.locale}
                          //options ={localeOptions}
                          >
                          {retrieveDopdownWithURL(intl)}
                        </Dropdown>
                    </span>
                </Menu.Item>
                  <Menu.Item position='right'>
                  {
                   loggedIn && <Link to="/dashboard">
                                    <FormattedMessage id="pricing.left.menu.dashboard"
                                                      defaultMessage="Dashboard"/> &#8594;
                                </Link>
                  }
                  {
                    !loggedIn && <Button basic as='a'  href="/login" >
                                    <FormattedMessage id="pricing.left.menu.mobile.login"
                                                      defaultMessage="Log In"/>
                                </Button>
                  }
                  {
                    !loggedIn && 
                      <div>
                        <br/>
                        <Button as='a'  href="register" secondary={true} style={{ marginLeft: '0.5em', backgroundColor:'#63a4dd' }}>
                                    <FormattedMessage id="pricing.left.menu.mobile.signup"
                                                    defaultMessage="Sign Up Free"/>
                        </Button>
                      </div>
                  }
                  </Menu.Item>
            </Sidebar>
  
            <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh',width: '100%' } }>
              <Segment textAlign='center' style={{ minHeight: 50, padding: '1em 0em 0em 0em', border:'none' }} vertical>
              <Container  style={{ width: '100%' } }>
                  <Menu secondary size='big'>
                    <Menu.Item onClick={this.handleToggle}>
                      <Icon name='sidebar' size='large' />
                    </Menu.Item>
                    <Menu.Item position='right' as={Link} to='/'>
                      <img alt='logo' src={img_logo} style={{ /*marginRight: '1.5em',*/width: '150px', height: '43px'  }}/>
  {/*                     {
                      loggedIn && <Button basic as='a'  href="/login" >
                                    <FormattedMessage id="pricing.top.menu.mobile.logout"
                                                   defaultMessage="Log Out"/>
                                  </Button>
                      }
                      {
                      !loggedIn && <Button basic as='a'  href="/login" >
                                      <FormattedMessage id="pricing.top.menu.mobile.login"
                                                   defaultMessage="Log In"/>
                                    </Button>
                      }
                      {
                      !loggedIn && <Button as='a'  href="#" secondary={true} style={{ marginLeft: '0.5em' }}>
                                       <FormattedMessage id="pricing.top.menu.mobile.signup"
                                                   defaultMessage="Sign Up"/>
                                  </Button>
                      } */}
                    </Menu.Item>
                  </Menu>
                </Container>
              </Segment>
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Responsive>
      )
    }
  }
  
  MobileContainer.propTypes = {
    children: PropTypes.node,
    loggedIn: PropTypes.bool
  }
  
  const ConnectedMobileContainer = injectIntl(connect(mapStateToProps)(MobileContainer));


  
  
  export const ResponsiveContainer = ({ children }) => (
    <div>
      <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_BOTTOM' }}/> 
      <ConnectedDesktopContainer>{children}</ConnectedDesktopContainer>
      <ConnectedMobileContainer>{children}</ConnectedMobileContainer>
    </div>
  )
  
  ResponsiveContainer.propTypes = {
    children: PropTypes.node,
    loggedIn: PropTypes.bool
  }
  
  class MainFooter extends Component {

    render() {
      const { loggedIn,intl,dispatch } = this.props;
  
      return (
          <Segment inverted vertical style={{ padding: '7em 0em' }}>
              <Container>
                  <Grid divided stackable>
                  <Grid.Row>
                      <Grid.Column width={3}>
                      <Header inverted  
                              as='h4' 
                              content={<FormattedMessage id="pricing.bottom.menu.header.about"
                                                          defaultMessage="About"/>}
                      />
                      <List inverted link size='large'>
                          <List.Item as={Link} to='/'>
                              <FormattedMessage id="pricing.bottom.menu.item.about"
                                                  defaultMessage="Home"/>
                          </List.Item>
                          <List.Item/>
                          <List.Item as={Link} to='/overview'>
                            <FormattedMessage id="pricing.bottom.menu.item.overview"
                                              defaultMessage="Features"/>
                          </List.Item>
                          <List.Item/>
                          <List.Item as={Link} to='/pricing'>
                              <FormattedMessage id="pricing.bottom.menu.item.pricing"
                                                  defaultMessage="Plans & Pricing"/>
                          </List.Item>
                      </List>
{/*                       <List>
                        <List.Item/>
                        <List.Item>
                          <span>
                              <Icon name="world"/>{' '}
                              <Dropdown
                                  inline
                                  upward={true}
                                  pointing ='top left'
                                  compact
                                  value = {intl.locale}
                                  options ={localeOptions}
                                  onChange = {(e,data)=>dispatch(localeActions.changeLocale(data.value))}/>
                          </span>
                        </List.Item>
                      </List> */}
                      <List size='large'>
                        <List.Item/>
                        <List.Item>
                        <span>
                            <Icon name="world" style={{color:'#4286e3'}}/>{' '}
                            <Dropdown text={intl.locale}
                                inline
                                upward={true}
                                pointing ='top left'
                                compact
                                style={{color:'#4286e3'}}
                                //value = {intl.locale}
                                //options ={localeOptions}
                                //onChange = {(e,data)=>dispatch(localeActions.changeLocale(data.value))}/
                                >
                                {retrieveDopdownWithURL(intl)}
                              </Dropdown>
                          </span>
                        </List.Item>
                      </List>
                      </Grid.Column>
                      <Grid.Column width={3}>
                      <Header inverted  
                              as='h4' 
                              content={<FormattedMessage id="pricing.bottom.menu.header.services"
                                                          defaultMessage="Services"/>}
                      />
                        <List inverted link size='large'>
                        {!loggedIn &&
                          <List.Item as={Link} to='/register'>
                              <FormattedMessage id="pricing.bottom.menu.item.signup"
                                              defaultMessage="Sign Up"/>
                          </List.Item>}
                          <List.Item/>
                          {!loggedIn &&  <List.Item as={Link} to='/login'>
                              <FormattedMessage id="pricing.bottom.menu.item.login"
                                              defaultMessage="Login"/>
                          </List.Item>}
                          {loggedIn && 
                            <List.Item as={Link} to='/dashboard'>
                              <FormattedMessage id="pricing.bottom.menu.item.dashboard"
                                              defaultMessage="Dashboard"/>
                          </List.Item>}
                      </List>
                      </Grid.Column>
                      <Grid.Column width={3}>
                      <Header inverted  
                                as='h4' 
                                content={<FormattedMessage id="pricing.bottom.menu.item.resource"
                                                            defaultMessage="Resources"/>}
                        />
                        <List inverted link size='large'>
                          <List.Item as={Link} to='/blog'>
                              <FormattedMessage id="pricing.bottom.menu.item.blog"
                                                defaultMessage="Blog"/>
                          </List.Item>
                          <List.Item/>
                          <List.Item as={Link} to='/contact'>
                              < FormattedMessage id="pricing.bottom.menu.item.contact"
                                              defaultMessage="Contact Us"/>
                          </List.Item>
                        </List>
                      </Grid.Column>
                      <Grid.Column width={3}>
                      <Header inverted  
                                as='h4' 
                                content={<FormattedMessage id="pricing.bottom.menu.header.business"
                                                            defaultMessage="Recipe costing software"/>}
                        />
                        <List inverted link size='large'>
                          <List.Item as={Link} to='/recipe-sofware-restaurant'>
                              <FormattedMessage id="pricing.bottom.menu.item.restaurant"
                                                defaultMessage="Restaurant"/>
                          </List.Item>
                          <List.Item as={Link} to='/recipe-sofware-bakery'>
                              <FormattedMessage id="pricing.bottom.menu.item.bakery"
                                                defaultMessage="Bakery Pastry shop"/>
                          </List.Item>
                          <List.Item as={Link} to='/recipe-sofware-caterer'>
                              <FormattedMessage id="pricing.bottom.menu.item.caterer"
                                                defaultMessage="Caterer"/>
                          </List.Item>
                          <List.Item as={Link} to='/recipe-sofware-hotel'>
                              <FormattedMessage id="pricing.bottom.menu.item.hotel"
                                                defaultMessage="Hotel Restaurant"/>
                          </List.Item>
                          <List.Item as={Link} to='/recipe-sofware-kitchen'>
                              <FormattedMessage id="pricing.bottom.menu.item.kitchen"
                                                defaultMessage="Kitchen - Dark kitchen"/>
                          </List.Item>
                          <List.Item as={Link} to='/restaurant-management-software'>
                             <FormattedMessage id="pricing.bottom.menu.item.management"
                                              defaultMessage="Restaurant Management"/>
                        </List.Item>
                        </List>
                      </Grid.Column>
                      <Grid.Column width={3}>
                      <Header inverted  
                              as='h4' 
                              content={<FormattedMessage id="pricing.bottom.menu.header.copyright"
                                                          defaultMessage="Legal"/>}
                      />
                      <List inverted link size='large'>
                        <List.Item as={Link} to='/termsconditions'>
                                <FormattedMessage id="pricing.bottom.menu.item.terms"
                                                  defaultMessage="Terms and Conditions"/>
                        </List.Item>
                        <List.Item/>
                        <List.Item as={Link} to='/privacypolicy'>
                            <FormattedMessage id="pricing.bottom.menu.item.privacy"
                                              defaultMessage="Privacy Policy"/>
                        </List.Item>
                        <List.Item/>
                        <List.Item/>
                        <List.Item/>
                        <List.Item/>
                        <List.Item/>
                      <List.Item><Icon name='copyright outline'/>Ratatool 2024</List.Item>
                      </List>
                      </Grid.Column>
                  </Grid.Row>
                  </Grid>
              </Container>
          </Segment>
          )
      }
  }
  
  
  function mapStateToProps(state) {
    const { alert,dispatch} = state;
    const { loggedIn,} = state.authentication;
    return {
        loggedIn,
        alert,
        dispatch
    };
}


const ConnectedMainFooter = injectIntl(connect(mapStateToProps)(MainFooter));
export { ConnectedMainFooter as MainFooter };