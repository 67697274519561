import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {FormattedMessage,injectIntl} from 'react-intl';
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import {toaster_dispatch} from '../_helpers';
import ButterToast  from 'butter-toast';

class PwdConfirm extends React.Component {
    constructor(props) {
        super(props);

        let uid = '';
        let token = '';
        if (props.match && props.match.params){
           uid = props.match.params.uid;
           token = props.match.params.token;
        }

        this.state = {
            uid: uid,
            token: token,
            new_password1: '',
            new_password2: '',

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
    
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { new_password1, new_password2 ,uid,token} = this.state;
        
        if (new_password1 && new_password2 && uid && token) {
            let infos = {
              uid:uid,
              token:token,
              new_password1:new_password1,
              new_password2:new_password2
            }
            dispatch(userActions.passwordConfirm(infos));
        }
    }

    render() {
        const { alert,dispatch, intl } = this.props;
        const { new_password1, new_password2 ,submitted } = this.state;
        return (
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
                <Grid.Row columns={1} only=' mobile tablet' style={{'padding-bottom':0}}>
                  <Grid.Column style={{ maxWidth: 450 }}>
                    <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
{/*                       {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      } */}
                      {' '}<FormattedMessage id="pwdconfirm.form.title"
                                            defaultMessage='Password change confirmation'/>
                    </Header>
                    <Form size='large' onSubmit={this.handleSubmit}>
                      <Segment stacked>
                      <FormattedMessage id="pwdconfirm.form.new_password1.placeholder"
                                            defaultMessage='New Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='new_password1'
                                          value={new_password1}
                                          onChange={this.handleChange} 
                                        />}
                        </FormattedMessage>
                        {submitted && !new_password1 &&
                          <label style={{color:'red'}}><i>
                            <FormattedMessage id="pwdconfirm.form.new_password1.error.message"
                                              defaultMessage='New password required'/> 
                            </i></label>
                        }
                      <FormattedMessage id="pwdconfirm.form.new_password2.placeholder"
                                            defaultMessage='Confirm Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='new_password2'
                                          value={new_password2}
                                          onChange={this.handleChange} 
                                        />}
                        </FormattedMessage>
                        {submitted && !new_password2 &&
                          <label style={{color:'red'}}><i>
                            <FormattedMessage id="pwdconfirm.form.new_password2.error.message"
                                              defaultMessage='Confirm password required'/> 
                            </i></label>
                        }
                        <Button color='grey' fluid size='large'>
                                <FormattedMessage id="pwdconfirm.form.button.change"
                                                  defaultMessage="Change password"/>
                        </Button>
                      </Segment>
                    </Form>
                    <Message>
                      <Link to="/login">
                          &#8592;  <FormattedMessage id="pwdconfirm.form.button.back"
                                                     defaultMessage=" Back to Log in"/>
                      </Link>
                    </Message>
                    <br/><br/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer' style={{'padding-bottom':0}}>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 700,  height: '100vh'}}> 
                     <img src={img_window}  alt='window' style={{width: '100v',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                   <img src={img_logo} alt='logo' style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
{/*                       {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      } */}
                      {' '}<FormattedMessage id="pwdconfirm.form.title"
                                            defaultMessage='Password change confirmation'/>
                    </Header>
                    <Form size='large' onSubmit={this.handleSubmit}>
                      <Segment stacked>
                      <FormattedMessage id="pwdconfirm.form.new_password1.placeholder"
                                            defaultMessage='New Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='new_password1'
                                          value={new_password1}
                                          onChange={this.handleChange} 
                                        />}
                        </FormattedMessage>
                        {submitted && !new_password1 &&
                          <label style={{color:'red'}}><i>
                            <FormattedMessage id="pwdconfirm.form.new_password1.error.message"
                                              defaultMessage='New password required'/> 
                            </i></label>
                        }
                      <FormattedMessage id="pwdconfirm.form.new_password2.placeholder"
                                            defaultMessage='Confirm Password'>
                            {placeholder => 
                                        <Form.Input
                                          fluid
                                          icon='lock'
                                          iconPosition='left'
                                          placeholder={placeholder}
                                          type='password'
                                          name='new_password2'
                                          value={new_password2}
                                          onChange={this.handleChange} 
                                        />}
                        </FormattedMessage>
                        {submitted && !new_password2 &&
                          <label style={{color:'red'}}><i>
                            <FormattedMessage id="pwdconfirm.form.new_password2.error.message"
                                              defaultMessage='Confirm password required'/> 
                            </i></label>
                        }
                        <Button color='grey' fluid size='large'>
                                <FormattedMessage id="pwdconfirm.form.button.change"
                                                  defaultMessage="Change password"/>
                        </Button>
                      </Segment>
                    </Form>
                    <Message>
                      <Link to="/login">
                          &#8592;  <FormattedMessage id="pwdconfirm.form.button.back"
                                                     defaultMessage=" Back to Log in"/>
                      </Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
                <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_TOP' }}/>
                {alert.message && toaster_dispatch(dispatch,alert,intl)}
              </Grid>
          );
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    return {
        alert,
        dispatch
    };
}

const connectedPwdConfirm = injectIntl(connect(mapStateToProps)(PwdConfirm));
export { connectedPwdConfirm as PwdConfirm };