import React, { Component } from 'react';
import { Table, Image, Button, Grid, Label,Message,Icon,Modal,Header,Input,Breadcrumb } from 'semantic-ui-react';
import { recipesActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverOrders from './SidebarOrders';
import {toaster_dispatch,optimizeDecimalPart,unitWeight,unitVolume,history,
    computeWeightUnitFactor,computeVolumeUnitFactor, isVolume,isWeight,isPack, typeYield,trim} from '../_helpers';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
//import img_orderList from '../Assets/orderlist.jpg'
import img_performance from '../Assets/performance.png'
import {styles} from './Styles';
import img_order from '../Assets/receipt.png'

class OrderResult extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { recipesData: [],
                       ingredientsList:[],
                       currency : userIn.user.currency,
                       activeModalPremium: false,
                       activeModalSave: false,
/*                        activeModalDelete: null,
                       activeModalEdit: null, 
                       newItemWeight: '', */
                       orderName : new Date().toLocaleDateString(props.intl.locale).split('T')[0] + '_XXX',
                       recipe_only:true
                     }
        props.dispatch(paymentActions.getCustomerMembership());
        this.generatePDFBackend=this.generatePDFBackend.bind(this);
        this.updateOrder=this.updateOrder.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.saveOrder=this.saveOrder.bind(this);
    }


    componentDidMount(){
        const {recipesList,recipesUnits,ingredientsList,ingredientsUnits} = this.props.location.state;
/*         console.log(recipesList);
        console.log(recipesUnits);
        console.log(ingredientsList);
        console.log(ingredientsUnits); */
        //this.createIngredientListFromRecipesUnits(recipesList,recipesUnits,ingredientsList,ingredientsUnits);
        this.createIngredientListFromRecipesQuantities(recipesList,recipesUnits,ingredientsList,ingredientsUnits);
    }


    createIngredientListFromRecipesQuantities(recipesList,recipesUnits,ingredientsList,ingredientsUnits){
        let aggRecipes = [];
        let aggIngredients = [];

        if (recipesList && recipesList.length > 0){
            for (let l of recipesList){
                for (let u of recipesUnits){
                    if (l.id == u.id){
                        aggRecipes.push({item:l, unit:u.value, is_nb_recipes:u.is_nb_recipes,metric:u.unit})

                        let recipe_quantity_value = u.value.replace(/,/g, '.');
                        let recipe_count;

                        if (u.is_nb_recipes){
                            recipe_count = Number(recipe_quantity_value).toFixed(3) 
                        }else{
                            recipe_count = Number(recipe_quantity_value) / Number(l.yield_count)
                            recipe_count = Number(recipe_count).toFixed(3) 
                        }

                        for (let i of l.ingredients){
                            if (i.ingredient){
                                let factor = 1;
                                if (i.quantity_type == 'weight'){
                                    factor = computeWeightUnitFactor('g',i.quantity_unit);
                                    i.quantity_value *=  factor
                                    i.quantity_unit = 'g'
                                    i.quantity =  i.quantity_value + i.quantity_unit
                                }
                                else if (i.quantity_type == 'volume'){
                                    factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                    i.quantity_value *=  factor
                                    i.quantity_unit = 'l'
                                    i.quantity =  i.quantity_value + i.quantity_unit
                                }

                                //let count = Number(u.value).toFixed(3)
                                var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                
                                if (index === -1) {
                                    //console.log('adding ingredient')
                                    //console.log(i.ingredient.name + ' count ' + count)
                                    //console.log(i)
                                    i.supplier = null
                                    aggIngredients.push({id: i.ingredient.id, data:i, count:recipe_count})
                                }else {
                                    //Update when ingredient already exists
                                    let old_i = aggIngredients[index].data;
                                    let old_count = aggIngredients[index].count;
                                    let new_i = i;

                                   if (i.quantity_type == old_i.quantity_type)
                                   {
                                        new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*recipe_count;
                                        new_i.quantity_value = new_i.quantity_value.toString();
                                        //new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                        new_i.quantity = new_i.quantity_value
                                        if (old_i.quantity_unit)
                                            new_i.quantity += old_i.quantity_unit;
                                        new_i.item_cost = old_i.item_cost*old_count + i.item_cost*recipe_count;
                                        //console.log('adding ingredient')
                                        //console.log(i.ingredient.name)
                                        //console.log('count ' + 1)
                                        //console.log(new_i)
                                        new_i.supplier = null
                                        aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                    } 
                                }
                            }else{
                                //TO CHECK WHY ONE TIME
                                console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                            }
                        }
                        for (let r of l.subrecipes){
                            if (r.subrecipe){
                                let yield_count = r.subrecipe.yield_count
                                let yield_units = r.subrecipe.yield_units
                                let quantity_value = r.quantity_value
                                let factor = 1;
                                let percent = 1;

                                let recipe_type = typeYield(yield_units);
                                let recipe_factor = 1;

                                if (recipe_type == 'weight'){
                                    recipe_factor = computeWeightUnitFactor('g', yield_units);
                                    recipe_factor *= Number(yield_count);
                                }
                                else if (recipe_type == 'volume'){
                                    recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                    recipe_factor *= Number(yield_count);
                                }else{
                                    recipe_factor = Number(yield_count);
                                }

                                if (recipe_factor < 0) recipe_factor = 1;

                                for (let i of r.subrecipe.ingredients){
                                    if (i.ingredient){
                                        if (i.quantity_type == 'weight'){
                                            factor = computeWeightUnitFactor('g',i.quantity_unit);
                                            i.quantity_value *=  factor
                                            i.quantity_unit = 'g'
                                            i.quantity =  i.quantity_value + i.quantity_unit
                                        }
                                        else if (i.quantity_type == 'volume'){
                                            factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                            i.quantity_value *=  factor
                                            i.quantity_unit = 'l'
                                            i.quantity =  i.quantity_value + i.quantity_unit
                                        }
   
                                       /*  if (factor){
                                            factor *= Number(quantity_value)
                                            factor /= Number(yield_count)
                                        } */

                                        //percent = Number(quantity_value)/Number(yield_count)
                                        percent = Number(quantity_value)/recipe_factor
                                        let count = Number(recipe_count*percent).toFixed(3)

                                        var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                        if (index === -1) {
                                            //console.log('adding sub1')
                                            //console.log(i.ingredient.name + ' count ' + count)
                                            //console.log(i)
                                            i.supplier = null
                                            aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                        }else {
                                            //Update when ingredient already exists
                                            let old_i = aggIngredients[index].data;
                                            let old_count = aggIngredients[index].count;
                                            let new_i = i;
                                            if (i.quantity_type == old_i.quantity_type)
                                            {
                                                new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                new_i.quantity_value = new_i.quantity_value.toString();
                                                //new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                new_i.quantity = new_i.quantity_value
                                                if (old_i.quantity_unit)
                                                    new_i.quantity += old_i.quantity_unit;
      
                                                new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                //console.log('updating sub1')
                                                //console.log(i.ingredient.name + ' count ' + 1)
                                                //console.log(new_i)
                                                new_i.supplier = null
                                                aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                            } 
                                        }
                                    }
                                }
                                for (let sub of r.subrecipe.subrecipes){
                                    if (sub.subrecipe){
                                        let yield_count = sub.subrecipe.yield_count
                                        let yield_units = sub.subrecipe.yield_units
                                        let quantity_value = sub.quantity_value
                                        let factor= 1;
                                        let percent2=1;

                                        let recipe_type = typeYield(yield_units);
                                        let recipe_factor = 1;
        
                                        if (recipe_type == 'weight'){
                                            recipe_factor = computeWeightUnitFactor('g', yield_units);
                                            recipe_factor *= Number(yield_count);
                                        }
                                        else if (recipe_type == 'volume'){
                                            recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                            recipe_factor *= Number(yield_count);
                                        }else{
                                            recipe_factor = Number(yield_count);
                                        }

                                        if (recipe_factor < 0) recipe_factor = 1;

                                        for (let i of sub.subrecipe.ingredients){
                                            if (i.ingredient){
                                                if (i.quantity_type == 'weight'){
                                                    factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                    i.quantity_value *=  factor
                                                    i.quantity_unit = 'g'
                                                    i.quantity =  i.quantity_value + i.quantity_unit
                                                }
                                                else if (i.quantity_type == 'volume'){
                                                    factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                    i.quantity_value *=  factor
                                                    i.quantity_unit = 'l'
                                                    i.quantity =  i.quantity_value + i.quantity_unit
                                                }
                                                /* if (factor){
                                                    factor *= Number(quantity_value)
                                                    factor /= Number(yield_count)
                                                } */
                                                percent2 = Number(quantity_value)/recipe_factor
                                                //percent2 = Number(quantity_value)/Number(yield_count)
                                                let count = Number(recipe_count*percent2*percent).toFixed(3)
                                                var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                                if (index === -1) {
                                                    //console.log('adding sub2')
                                                    //console.log(i.ingredient.name + ' count ' + count)
                                                    //console.log(i)
                                                    i.supplier = null
                                                    aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                                }else {
                                                    //Update when ingredient already exists
                                                    let old_i = aggIngredients[index].data;
                                                    let old_count = aggIngredients[index].count;
                                                    let new_i = i;
                                                    if (i.quantity_type == old_i.quantity_type)
                                                    {
                                                        new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                        new_i.quantity_value = new_i.quantity_value.toString();
                                                        //new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                        new_i.quantity = new_i.quantity_value
                                                        if (old_i.quantity_unit)
                                                            new_i.quantity += old_i.quantity_unit;
                
                                                        new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                        //console.log('updating sub2')
                                                        //console.log(i.ingredient.name + ' count ' + 1)
                                                        //console.log(new_i)
                                                        new_i.supplier = null
                                                        aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                    } 
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }  
        }
        //Add single ingredients
        if (ingredientsList && ingredientsList.length > 0){
                let aggInitIngredients = []
                for (let flat_i of ingredientsList){
                    for (let u of ingredientsUnits){
                        if (flat_i.ingredient.id == u.id){
                                aggInitIngredients.push({item:flat_i.ingredient, unit:u.value,metric:u.unit,supplier:flat_i.supplier})
                                let factor = 1;
                                //console.log(flat_i)
/*                                 let i18n_value = u.value.replace(/,/g, '.');
                                let quantity_value = get_payload(i18n_value,true)
                                let quantity_unit = get_payload(i18n_value,false)
                                let quantity = quantity_value */
                                let quantity_value = u.value.replace(/,/g, '.');
                                let quantity_unit = u.unit//flat_i.unit
                                let quantity_type = 'quantity'
                                let quantity = quantity_value
                    
                                if (isWeight(quantity_unit)){
                                    quantity_type = 'weight'
                                    factor = computeWeightUnitFactor('g',quantity_unit);
                                    quantity_value *=  factor
                                    quantity_unit = 'g'
                                    quantity =  quantity_value + quantity_unit
                                }
                                else if (isVolume(quantity_unit)){
                                    quantity_type = 'volume'
                                    factor = computeVolumeUnitFactor('l',quantity_unit);
                                    quantity_value *=  factor
                                    quantity_unit = 'l'
                                    quantity =  quantity_value + quantity_unit
                                }

                                let use_price_per_unit = flat_i.ingredient.price_per_unit
                                let use_price= flat_i.ingredient.price
                                let supplier_found = false
                                //console.log(supplierSelected)
                                if (flat_i.supplier){
                                    if (flat_i.ingredient.supplier &&  flat_i.ingredient.supplier.id == flat_i.supplier.id){
                                        use_price_per_unit = flat_i.ingredient.price_per_unit
                                        use_price =  flat_i.ingredient.price
                                        supplier_found = true
                                    }
                
                                    if (!supplier_found && flat_i.ingredient.extra_suppliers){
                                        for (let s of flat_i.ingredient.extra_suppliers){
                                            if (s.supplier && s.supplier.id == flat_i.supplier.id){
                                                use_price_per_unit = s.price_per_unit
                                                use_price =  s.price
                                                break
                                            }
                                        }
                                    }
                                }else{
                                    if (flat_i.ingredient.default_supplier){
                                        use_price_per_unit = flat_i.ingredient.price_per_unit
                                        use_price =  flat_i.ingredient.price
                                    }else{
                                        if (flat_i.ingredient.extra_suppliers){
                                            for (let s of flat_i.ingredient.extra_suppliers){
                                                if (s.default_supplier){
                                                    use_price_per_unit = s.price_per_unit
                                                    use_price =  s.price
                                                    break
                                                }
                                            }
                                        }
                                    }
                                }

                                let item_cost = use_price_per_unit * quantity_value

                                if (isPack(quantity_unit)){
                                    item_cost = use_price * quantity_value
                                }else{
                                    if (quantity_unit!=flat_i.ingredient.unit){
                                        let unit_factor = 1 
                                        if (isWeight(quantity_unit)){
                                            unit_factor = computeWeightUnitFactor('g',flat_i.ingredient.unit);
                                        } else if (isVolume(quantity_unit)){
                                            unit_factor = computeVolumeUnitFactor('l',flat_i.ingredient.unit);
                                        }
                                        if (unit_factor > 0)
                                            item_cost = item_cost/unit_factor
                                    }
                                }
                                 
                                let count = Number(1).toFixed(3)
                                var index = aggIngredients.findIndex(x => x.id == flat_i.ingredient.id)
                                if (index === -1) {
                                    aggIngredients.push(
                                                        {id: flat_i.ingredient.id, 
                                                         data:{ ingredient:flat_i.ingredient,
                                                                item_cost:item_cost,
                                                                quantity_value:quantity_value,
                                                                quantity_unit:quantity_unit,
                                                                quantity_type:quantity_type,
                                                                quantity:quantity, 
                                                                supplier:flat_i.supplier
                                                             }, 
                                                         count:1
                                                    })
                                }else {
                                   //Update when ingredient already exists
                                   let old_i = aggIngredients[index].data;
                                   let old_count = aggIngredients[index].count;

                                   if (quantity_type == old_i.quantity_type)
                                   {
                                        let new_i_quantity_value = old_i.quantity_value*old_count + quantity_value*count;
                                        new_i_quantity_value = new_i_quantity_value.toString();
                                        //let new_i_quantity = new_i_quantity_value + old_i.quantity_unit;
                                        let new_i_quantity = new_i_quantity_value
                                        if (old_i.quantity_unit)
                                            new_i_quantity+= old_i.quantity_unit;

                                        let new_i_item_cost = old_i.item_cost*old_count + item_cost*count;
                                        aggIngredients[index] = {   id: flat_i.ingredient.id, 
                                                                    data:{ ingredient:flat_i.ingredient,
                                                                           item_cost:new_i_item_cost,
                                                                           quantity_value:new_i_quantity_value,
                                                                           quantity_unit:quantity_unit,
                                                                           quantity_type:quantity_type,
                                                                           quantity:new_i_quantity,
                                                                           supplier:flat_i.supplier 
                                                                        }, 
                                                                    count:1
                                                                }
                                    } 
                                }
                            }
                    }
                }
                this.setState({ingredientsData:aggInitIngredients});
                this.setState({ingredients_added:true});
            }

            this.setState({recipesData:aggRecipes});
            this.setState({ingredientsList:aggIngredients});
            //console.log(aggRecipes);
            //console.log(aggIngredients);

            let numberSuppliers = 0;
            let supplier_ids = []
            const suppliers_lists = aggIngredients.reduce((sublist, item) => {
                //console.log(item)
                let use_supplier = null
                let use_supplier_id
                if (item.data.supplier && item.data.supplier.name && item.data.supplier.name!=""){
                    use_supplier = item.data.supplier.name
                    use_supplier_id = item.data.supplier.id
                }else{
                    //Use default supplier for this ingredient
                    if (item.data.ingredient.default_supplier == true){
                        if  (item.data.ingredient.supplier__name) {
                            use_supplier = item.data.ingredient.supplier__name
                            use_supplier_id = item.data.ingredient.supplier__id
                        }else if  (item.data.ingredient.supplier && item.data.ingredient.supplier.name) {
                            use_supplier = item.data.ingredient.supplier.name
                            use_supplier_id = item.data.ingredient.supplier.id
                        }
                    }else{
                        for (let s of item.data.ingredient.extra_suppliers){
                            if (s.default_supplier == true){
                                use_supplier = s.supplier.name
                                use_supplier_id = s.supplier.id
                                break
                            }
                        }
                    }
                }

                if (use_supplier && use_supplier!="" && !sublist[use_supplier]) {
                    sublist[use_supplier] = [];
                    //numberSuppliers +=1;
                }else if (!sublist["undefined"]){
                    sublist["undefined"] = [];
                    //numberSuppliers +=1;
                }

                if (use_supplier && use_supplier!="") {
                    sublist[use_supplier].push(item);
                    supplier_ids[use_supplier] = use_supplier_id
                }else {
                    sublist["undefined"].push(item)
                }

/*               if (item.data.ingredient.supplier__name  && !sublist[item.data.ingredient.supplier__name]) {
                    sublist[item.data.ingredient.supplier__name] = [];
                    numberSuppliers +=1;
                }
                else if ((!item.data.ingredient.supplier) && (!sublist["undefined"])) {
                    sublist["undefined"] = [];
                    numberSuppliers +=1;
                }
                else if (item.data.ingredient.supplier && !sublist[item.data.ingredient.supplier.name]) {
                    sublist[item.data.ingredient.supplier.name] = [];
                    numberSuppliers +=1;
                } 
              
                if (item.data.ingredient.supplier__name) {
                    sublist[item.data.ingredient.supplier__name].push(item);
                }
                else if (!item.data.ingredient.supplier) {
                    sublist["undefined"].push(item)
                }else{
                    sublist[item.data.ingredient.supplier.name].push(item);
                }*/
                return sublist;
              }, {})

            let new_suppliers_lists = []
            for (let [supplier_name, ingredients_list] of Object.entries(suppliers_lists)) {
                new_suppliers_lists.push({supplier_name,ingredients_list});
                new_suppliers_lists.sort(function (a, b) {
                    return a.supplier_name.toLowerCase().localeCompare(b.supplier_name.toLowerCase()); 
                }) 
                if (ingredients_list.length > 0) numberSuppliers +=1;
            }

            this.setState({suppliers_lists:new_suppliers_lists});
            this.setState({supplier_ids:supplier_ids});
            this.setState({numberSuppliers:numberSuppliers});
            //console.log(suppliers_lists)
            //console.log(numberSuppliers)
    }

    
/*     createIngredientListFromRecipesUnits(recipesList,recipesUnits,ingredientsList,ingredientsUnits){
        let aggRecipes = [];
        let aggIngredients = [];

        if (recipesList && recipesList.length > 0){
            for (let l of recipesList){
                for (let u of recipesUnits){
                    if (l.id == u.id){
                        aggRecipes.push({item:l, unit:u.value})
                        for (let i of l.ingredients){
                            if (i.ingredient){
                                let factor = 1;
                                if (i.quantity_type == 'weight'){
                                    factor = computeWeightUnitFactor('g',i.quantity_unit);
                                    i.quantity_value *=  factor
                                    i.quantity_unit = 'g'
                                    i.quantity =  i.quantity_value + i.quantity_unit
                                }
                                else if (i.quantity_type == 'volume'){
                                    factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                    i.quantity_value *=  factor
                                    i.quantity_unit = 'l'
                                    i.quantity =  i.quantity_value + i.quantity_unit
                                }

                                let count = Number(u.value).toFixed(3) 
                                var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                
                                if (index === -1) {
                                    //console.log('adding ingredient')
                                    //console.log(i.ingredient.name + ' count ' + count)
                                    //console.log(i)
                                    aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                }else {
                                    //Update when ingredient already exists
                                    let old_i = aggIngredients[index].data;
                                    let old_count = aggIngredients[index].count;
                                    let new_i = i;

                                   if (i.quantity_type == old_i.quantity_type)
                                   {
                                        new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                        new_i.quantity_value = new_i.quantity_value.toString();
                                        new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                        new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                        //console.log('adding ingredient')
                                        //console.log(i.ingredient.name)
                                        //console.log('count ' + 1)
                                        //console.log(new_i)
                                        aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                    } 
                                }
                            }else{
                                //TO CHECK WHY ONE TIME
                                console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                            }
                        }
                        for (let r of l.subrecipes){
                            if (r.subrecipe){
                                let yield_count = r.subrecipe.yield_count
                                let yield_units = r.subrecipe.yield_units
                                let quantity_value = r.quantity_value
                                let factor = 1;
                                let percent = 1;

                                let recipe_type = typeYield(yield_units);
                                let recipe_factor = 1;

                                if (recipe_type == 'weight'){
                                    recipe_factor = computeWeightUnitFactor('g', yield_units);
                                    recipe_factor *= Number(yield_count);
                                }
                                else if (recipe_type == 'volume'){
                                    recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                    recipe_factor *= Number(yield_count);
                                }else{
                                    recipe_factor = Number(yield_count);
                                }

                                if (recipe_factor < 0) recipe_factor = 1;

                                for (let i of r.subrecipe.ingredients){
                                    if (i.ingredient){
                                        if (i.quantity_type == 'weight'){
                                            factor = computeWeightUnitFactor('g',i.quantity_unit);
                                            i.quantity_value *=  factor
                                            i.quantity_unit = 'g'
                                            i.quantity =  i.quantity_value + i.quantity_unit
                                        }
                                        else if (i.quantity_type == 'volume'){
                                            factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                            i.quantity_value *=  factor
                                            i.quantity_unit = 'l'
                                            i.quantity =  i.quantity_value + i.quantity_unit
                                        }
   
                                       /*  if (factor){
                                            factor *= Number(quantity_value)
                                            factor /= Number(yield_count)
                                        } //

                                        //percent = Number(quantity_value)/Number(yield_count)
                                        percent = Number(quantity_value)/recipe_factor
                                        let count = Number(u.value*percent).toFixed(3)

                                        var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                        if (index === -1) {
                                            //console.log('adding sub1')
                                            //console.log(i.ingredient.name + ' count ' + count)
                                            //console.log(i)
                                            aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                        }else {
                                            //Update when ingredient already exists
                                            let old_i = aggIngredients[index].data;
                                            let old_count = aggIngredients[index].count;
                                            let new_i = i;
                                            if (i.quantity_type == old_i.quantity_type)
                                            {
                                                new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                new_i.quantity_value = new_i.quantity_value.toString();
                                                new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                //console.log('updating sub1')
                                                //console.log(i.ingredient.name + ' count ' + 1)
                                                //console.log(new_i)
                                               aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                            } 
                                        }
                                    }
                                }
                                for (let sub of r.subrecipe.subrecipes){
                                    if (sub.subrecipe){
                                        let yield_count = sub.subrecipe.yield_count
                                        let yield_units = sub.subrecipe.yield_units
                                        let quantity_value = sub.quantity_value
                                        let factor= 1;
                                        let percent2=1;

                                        let recipe_type = typeYield(yield_units);
                                        let recipe_factor = 1;
        
                                        if (recipe_type == 'weight'){
                                            recipe_factor = computeWeightUnitFactor('g', yield_units);
                                            recipe_factor *= Number(yield_count);
                                        }
                                        else if (recipe_type == 'volume'){
                                            recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                            recipe_factor *= Number(yield_count);
                                        }else{
                                            recipe_factor = Number(yield_count);
                                        }

                                        if (recipe_factor < 0) recipe_factor = 1;

                                        for (let i of sub.subrecipe.ingredients){
                                            if (i.ingredient){
                                                if (i.quantity_type == 'weight'){
                                                    factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                    i.quantity_value *=  factor
                                                    i.quantity_unit = 'g'
                                                    i.quantity =  i.quantity_value + i.quantity_unit
                                                }
                                                else if (i.quantity_type == 'volume'){
                                                    factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                    i.quantity_value *=  factor
                                                    i.quantity_unit = 'l'
                                                    i.quantity =  i.quantity_value + i.quantity_unit
                                                }
                                                /* if (factor){
                                                    factor *= Number(quantity_value)
                                                    factor /= Number(yield_count)
                                                } //
                                                percent2 = Number(quantity_value)/recipe_factor
                                                //percent2 = Number(quantity_value)/Number(yield_count)
                                                let count = Number(u.value*percent2*percent).toFixed(3)
                                                var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                                if (index === -1) {
                                                    //console.log('adding sub2')
                                                    //console.log(i.ingredient.name + ' count ' + count)
                                                    //console.log(i)
                                                    aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                                }else {
                                                    //Update when ingredient already exists
                                                    let old_i = aggIngredients[index].data;
                                                    let old_count = aggIngredients[index].count;
                                                    let new_i = i;
                                                    if (i.quantity_type == old_i.quantity_type)
                                                    {
                                                        new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                        new_i.quantity_value = new_i.quantity_value.toString();
                                                        new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                        new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                        //console.log('updating sub2')
                                                        //console.log(i.ingredient.name + ' count ' + 1)
                                                        //console.log(new_i)
                                                        aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                    } 
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }  
        }
        //Add single ingredients
        if (ingredientsList && ingredientsList.length > 0){
                let aggInitIngredients = []
                for (let flat_i of ingredientsList){
                    for (let u of ingredientsUnits){
                        if (flat_i.id == u.id){
                                aggInitIngredients.push({item:flat_i, unit:u.value})
                                let factor = 1;
                                let i18n_value = u.value.replace(/,/g, '.');
                                //let quantity_unit = u.value.replace(/[0-9]/g, '');
                                //let quantity_value =  u.value.replace(/[^0-9]/g,'');
                                let quantity_value = get_payload(i18n_value,true)
                                let quantity_unit = get_payload(i18n_value,false)
                                let quantity = quantity_value
                                let quantity_type = 'quantity'
                    
                                if (isWeight(quantity_unit)){
                                    quantity_type = 'weight'
                                    factor = computeWeightUnitFactor('g',quantity_unit);
                                    quantity_value *=  factor
                                    quantity_unit = 'g'
                                    quantity =  quantity_value + quantity_unit
                                }
                                else if (isVolume(quantity_unit)){
                                    quantity_type = 'volume'
                                    factor = computeVolumeUnitFactor('l',quantity_unit);
                                    quantity_value *=  factor
                                    quantity_unit = 'l'
                                    quantity =  quantity_value + quantity_unit
                                }

                                let item_cost = flat_i.price_per_unit * quantity_value

                                if (quantity_unit!=flat_i.unit){
                                    let unit_factor = 1 
                                    if (isWeight(quantity_unit)){
                                        unit_factor = computeWeightUnitFactor('g',flat_i.unit);
                                    } else if (isVolume(quantity_unit)){
                                        unit_factor = computeVolumeUnitFactor('l',flat_i.unit);
                                    }
                                    if (unit_factor > 0)
                                        item_cost = item_cost/unit_factor
                                }
                                
                                let count = Number(1).toFixed(3)
                                var index = aggIngredients.findIndex(x => x.id == flat_i.id)
                                if (index === -1) {
                                    aggIngredients.push(
                                                        {id: flat_i.id, 
                                                         data:{  ingredient:flat_i,
                                                                item_cost:item_cost,
                                                                quantity_value:quantity_value,
                                                                quantity_unit:quantity_unit,
                                                                quantity_type:quantity_type,
                                                                quantity:quantity 
                                                             }, 
                                                         count:1
                                                    })
                                }else {
                                   //Update when ingredient already exists
                                   let old_i = aggIngredients[index].data;
                                   let old_count = aggIngredients[index].count;

                                   if (quantity_type == old_i.quantity_type)
                                   {
                                        let new_i_quantity_value = old_i.quantity_value*old_count + quantity_value*count;
                                        new_i_quantity_value = new_i_quantity_value.toString();
                                        let new_i_quantity = new_i_quantity_value + old_i.quantity_unit;
                                        let new_i_item_cost = old_i.item_cost*old_count + item_cost*count;
                                        aggIngredients[index] = {   id: flat_i.id, 
                                                                    data:{ ingredient:flat_i,
                                                                           item_cost:new_i_item_cost,
                                                                           quantity_value:new_i_quantity_value,
                                                                           quantity_unit:quantity_unit,
                                                                           quantity_type:quantity_type,
                                                                           quantity:new_i_quantity 
                                                                        }, 
                                                                    count:1
                                                                }
                                    } 
                                }
                            }
                    }
                }
                this.setState({ingredientsData:aggInitIngredients});
                this.setState({ingredients_added:true});
            }
            this.setState({recipesData:aggRecipes});
            this.setState({ingredientsList:aggIngredients});
            //console.log(aggRecipes);
            //console.log(aggIngredients);
    } */


    computeQuantityWithUnit(item,supplier){
        const{supplier_ids} = this.state;
        let unitPart = "";
        let valuePart = 0;
        
        if (item.data.quantity_value) valuePart = item.data.quantity_value
        if (item.data.quantity_unit) unitPart = item.data.quantity_unit

        if (item.data.quantity_type == 'volume'){

            if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeVolumeUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                valuePart = item.data.quantity_value * ifactor
                unitPart = item.data.ingredient.unit
            }
            // Apply number of items
            valuePart*= Number(item.count);

            for (let unit of unitVolume ){
              if (unitPart === unit){
                if ((unitPart === 'ml')||(unitPart === 'milliliter')||(unitPart === 'milliliters')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'L';
                        valuePart /= Number(1000);
                    }
                }
                else if ((unitPart === 'cl')||(unitPart === 'centiliter')||(unitPart === 'centiliters')){
                    if (valuePart >= Number(100)){
                        unitPart = 'L';
                        valuePart /= Number(100);
                    }
                }
                break;
              }
            }
        }else if (item.data.quantity_type == 'weight'){

            if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeWeightUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                 valuePart = item.data.quantity_value * ifactor
                 unitPart = item.data.ingredient.unit
             }

            // Apply number of items
            valuePart*= Number(item.count);
            
            for (let unit of unitWeight ){
              if (unitPart === unit){
                if ((unitPart === 'g')||(unitPart === 'gram')||(unitPart === 'grams')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'kg';
                        valuePart /= Number(1000);
                    }
                }
                else if (unitPart === 'mg'){
                    if (valuePart >= Number(1000)){
                        unitPart = 'g';
                        valuePart /= Number(1000);
                    }
                }
                break;
              }
            }
          }else if (item.data.quantity_type == 'quantity'){
                // Apply number of items
                valuePart*= Number(item.count);
          }
        
        if (item.data.quantity_type == 'quantity'){
            let is_pack = isPack(item.data.quantity_unit)
            if (is_pack){

                let unitPack = ""
                let supplier_found = false
                let type = typeYield(item.data.ingredient.unit)
                let is_volume_ingredient = (type == 'volume')
                let is_weight_ingredient = (type == 'weight')
    
                if (is_weight_ingredient) unitPack = item.data.ingredient.purchase_weight
                else if (is_volume_ingredient) unitPack = item.data.ingredient.purchase_volume
                else unitPack = item.data.ingredient.purchase_quantity
    
                if (supplier){
                    if (item.data.ingredient.supplier &&  item.data.ingredient.supplier.id == supplier_ids[supplier]){
                        supplier_found = true
                    }
    
                    if (!supplier_found && item.data.ingredient.extra_suppliers){
                        for (let s of item.data.ingredient.extra_suppliers){
                            if (s.supplier && s.supplier.id == supplier_ids[supplier]){
                                if (is_pack){
                                    if (is_weight_ingredient) unitPack = s.purchase_weight
                                    else if (is_volume_ingredient) unitPack = s.purchase_volume
                                    else unitPack = s.purchase_quantity
                                }
                                break
                            }
                        }
                    }
                }

                return optimizeDecimalPart(valuePart,3) + ' x ' + unitPack;
            }else{
                return optimizeDecimalPart(valuePart,3) + ' ' + unitPart;
            }
        }

        let valuePartI18n = <FormattedNumber value={valuePart.toFixed(3)}/>
        return <FormattedMessage id="orderresult.compute.quantity.result"
                                 defaultMessage='{valuePart} {unitPart}' 
                                 values={{valuePart: valuePartI18n,
                                          unitPart:  unitPart}}/>
    }


    computeTotalCost(ingredientsList){
        let cost = 0;
        ingredientsList.map((item,index) => {
            cost+=Number(item.data.item_cost*item.count);
            return item;
        });
        return cost.toFixed(2);
    }


    computePurchaseUnit(item,supplier){
        const{currency,supplier_ids} = this.state;
        let use_price = item.data.ingredient.price_per_unit
        let is_pack = isPack(item.data.quantity_unit)
        let is_quantity = item.data.quantity_type == 'quantity'

        let type = typeYield(item.data.ingredient.unit)
        let is_volume_ingredient = (type == 'volume')
        let is_weight_ingredient = (type == 'weight')
        let is_quantity_ingredient = (type == 'quantity')

        if (is_pack){
            use_price = item.data.ingredient.price
        }
        let supplier_found = false
        let purchase_pack = ""
        if (is_weight_ingredient) purchase_pack = item.data.ingredient.purchase_weight
        else if (is_volume_ingredient) purchase_pack = item.data.ingredient.purchase_volume
        else  purchase_pack = item.data.ingredient.purchase_quantity

        if (supplier){
            if (item.data.ingredient.supplier &&  item.data.ingredient.supplier.id == supplier_ids[supplier]){
                use_price = item.data.ingredient.price_per_unit
                if (is_pack){
                    use_price = item.data.ingredient.price
                }
                supplier_found = true
            }

            if (!supplier_found && item.data.ingredient.extra_suppliers){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.supplier && s.supplier.id == supplier_ids[supplier]){
                        use_price = s.price_per_unit
                        if (is_pack){
                            use_price = s.price
                            if (is_weight_ingredient) purchase_pack = s.purchase_weight
                            else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                            else purchase_pack = s.purchase_quantity
                        }
                        break
                    }
                }
            }
        }

        let price_per_unit = <FormattedNumber value={use_price} 
                                              style="currency" 
                                              maximumFractionDigits="3" 
                                              currencyDisplay="symbol" 
                                              currency={currency}/>

        if (is_quantity){
            if (is_pack){
                    if (is_quantity_ingredient) {
                        return <FormattedMessage id="orderresult.quantity.pack.result.quantity"
                                                defaultMessage='{valuePart} / {unitPart} units' 
                                                values={{valuePart: price_per_unit,
                                                         unitPart:  purchase_pack}}/>
                    }else{
                        return <FormattedMessage id="orderresult.quantity.pack.result"
                                            defaultMessage='{valuePart} / {unitPart}' 
                                            values={{valuePart: price_per_unit,
                                                    unitPart:  purchase_pack}}/>
                    }
                   
            }else{

                return <FormattedMessage id="orderresult.quantity.unit.result"
                                            defaultMessage='{valuePart} / unit' 
                                            values={{valuePart: price_per_unit}}/>
            }
        }else{
                return <FormattedMessage id="orderresult.notquantity.unit.result"
                                            defaultMessage='{valuePart} / {unitPart}' 
                                            values={{valuePart: price_per_unit,
                                                      unitPart:  item.data.ingredient.unit}}/>
        }
    }

    generatePDFBackend(event){
        event.preventDefault();
        const { ingredientsList,orderName } = this.state;

        const {customer} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
    
        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
        {
            if (ingredientsList){
                let properties = {
                    id: null,
                    items: ingredientsList,
                    name: orderName
                }
            
                this.props.dispatch(recipesActions.renderOrder(properties));  
            } 
        }else{
           this.setState({ activeModalPremium: true});
        }  
    }

    onNameSaveOrder = (e) => {
        e.preventDefault();
        this.setState({ orderName: e.target.value });
    }

    saveClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalSave: true});
    }; 

    saveOrder(event){
        event.preventDefault();
        const { recipesData,ingredientsList,orderName,ingredients_added } = this.state;

        if (recipesData && ingredientsList){
            let properties = {
                ref_name: trim(orderName).substring(0,80),
                items: recipesData,
                articles:ingredientsList,
                ingredients_added:ingredients_added
            }
            this.props.dispatch(recipesActions.saveOrder(properties));  
        } 
    }

    updateOrder(event){
        event.preventDefault();
        const {recipesList,recipesUnits,ingredientsList,ingredientsUnits} = this.props.location.state;
        history.push({'pathname': '/orders','state':{ recipesList: recipesList,
                                                      recipesUnits: recipesUnits,
                                                      ingredientsList:ingredientsList,
                                                      ingredientsUnits: ingredientsUnits}});

    }

/*     onEditItemWeight = (e) => {
        e.preventDefault();
        this.setState({ newItemWeight: e.target.value });
    }

    editListItem (event,id,unit) {
        event.preventDefault();
        let newItemWeight = this.state.newItemWeight;
        const { ingredientsList } = this.state;
        let newIngredientList;

        if (newItemWeight !=''){
            ingredientsList.map((item,index)=> {
                if (item.id == id) item.custom=newItemWeight+unit;
            });
        }
        this.setState({ activeModalEdit: null});
    }

    deleteListItem (event,id) {
        event.preventDefault();
        const { ingredientsList } = this.state;
        let newIngredientList = ingredientsList.filter(item => item.id != id );
        this.setState({ ingredientsList: newIngredientList });
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,weight) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ newItemWeight: weight });
    };
     */

    addOrderRows(recipesData){
        const {currency} = this.state;
        return recipesData.map((i,index) => {
                return (
                    <Table.Row key={index} >
                        {i.is_nb_recipes &&
                        <Table.Cell textAlign='center'><FormattedNumber value={i.unit} />{' '}<FormattedMessage id="orderresult.quantity.recipe.text"
                                                                                                                defaultMessage='recipe(s)'/></Table.Cell>}
                        {!i.is_nb_recipes &&
                        <Table.Cell textAlign='center'><FormattedNumber value={i.unit} />{' '}{i.item.yield_units}</Table.Cell>}
                        <Table.Cell textAlign='left'><Icon name='food' color='blue'/>
                            {i.item.name.length >= 65 && 
                                  i.item.name.substring(0,65) + '..'}
                             {i.item.name.length < 65 && 
                                    i.item.name}
                            {/*  {i.item.name} */}
                        </Table.Cell>
                        <Table.Cell textAlign='right'><FormattedNumber value={i.item.yield_count} />{' '}{i.item.yield_units}</Table.Cell>
                        <Table.Cell textAlign='right'>
                        {typeYield(i.item.yield_units) == 'quantity' &&
                           <span> 
                               <FormattedNumber value={i.item.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                               {' / '}{i.item.yield_units}</span>
                            }
                        {typeYield(i.item.yield_units) != 'quantity' &&
                            <span><FormattedNumber value={i.item.cost_total} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                            {' / '}<FormattedMessage id="orderresult.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                        }
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addOrderIngredientsRows(ingredientsData){
        const {currency} = this.state;
        return ingredientsData.map((i,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell textAlign='center'><FormattedNumber value={i.unit} />{' '}{i.metric}</Table.Cell>
                        <Table.Cell textAlign='left'><Icon name='shopping basket' color='teal'/>
                             {i.item.name.length >= 45 && 
                                  i.item.name.substring(0,45) + '..'}
                              {i.item.name.length < 45 && 
                                    i.item.name}
                              {/* {i.item.name} */}
                        </Table.Cell>
                        <Table.Cell textAlign='right'></Table.Cell>
                        {i.item.purchase_quantity !== "" &&
                        <Table.Cell width={3} textAlign='right'>
                                 <FormattedNumber value={i.item.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                    {' / '}<FormattedMessage id="orderresult.quantity.unit.text"
                                                             defaultMessage='unit'/>
                        </Table.Cell>}
                        {(i.item.purchase_volume !== "" || i.item.purchase_weight !== "") &&
                        <Table.Cell width={3} textAlign='right'>
                                <FormattedNumber value={i.item.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                {' / '}{i.item.unit}
                        </Table.Cell>}
                     </Table.Row>
                );
        });
    }


    addListRows(ingredientsList){
        const{currency} = this.state;
        //Sort by ingredient name
        ingredientsList  =  ingredientsList.sort(function (a, b) {
            return a.data.name.toLowerCase().localeCompare(b.data.name.toLowerCase());
        })
        return ingredientsList.map((item,index) => {
                let supplier = ""
                if (item.data.supplier){
                    supplier = item.data.supplier.name
                }else{
                    supplier = item.data.supplier__name
                } 
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={4} textAlign='left' style={{'color': 'black'}} >
                            {item.data.name}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>{supplier}</Table.Cell>
                        {item.data.quantity_type == 'quantity' &&
                            <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item)}
                                    {' / '}<FormattedMessage id="orderresult.quantity.unit.text"
                                                             defaultMessage='unit'/></Table.Cell>}
                        {item.data.quantity_type != 'quantity' &&
                            <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item)}{' / '}{item.data.unit}</Table.Cell>}

                        <Table.Cell width={2} textAlign='right'>{this.computeQuantityWithUnit(item)}</Table.Cell>
                        <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.stock_quantity))} {item.data.stock_unit}</Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                             <FormattedNumber value={(item.data.item_cost*item.count)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                            {/*  {intl.formatMessage({id:'currency.symbol'})} */}
                        </Table.Cell>
{/*
                         {!item.custom &&
                        <Table.Cell width={2} textAlign='center'>{this.computeQuantityWithUnit(item).value_and_unit}</Table.Cell>}
                        {!item.custom &&
                        <Table.Cell width={2} textAlign='center'>
                             <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                        </Table.Cell>}
                        {item.custom && item.custom !='' &&
                        <Table.Cell width={2} textAlign='center'>{item.custom}</Table.Cell>}
                        {item.custom && item.custom !='' &&
                        <Table.Cell width={2} textAlign='center'>
                                {this.computeCustomCost(item)}
                        </Table.Cell>}
                         <Table.Cell>
                            <Button basic id={index} className="ui small icon button" color='grey' 
                                onClick={(event)=>this.editClickHandler(event,index,this.computeQuantityWithUnit(item).value)}>
                                <i className="edit icon"></i>
                            </Button>
                            <Button basic id={index} className="ui small icon button" color='grey' 
                                onClick={(event) => this.deleteClickHandler(event,index)}>
                                <i className="trash icon"></i>
                            </Button>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='edit' content={<FormattedMessage id="orderresult.modal.edit.title"
                                                                                defaultMessage='Change weight'/>} />
                                <Modal.Content>
                                     <p>
                                    <Input
                                        type="text"
                                        placeholder="Updating"
                                        id="changeWeight"
                                        value={this.state.newItemWeight}
                                        onChange={this.onEditItemWeight}
                                        style={{minWidth:'10em',maxWidth:'20em'}}/>
                                    {' '}{this.computeQuantityWithUnit(item).unit}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="orderresult.modal.edit.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="orderresult.modal.edit.button.yes"
                                                                    defaultMessage='Yes'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editListItem(event,item.id,this.computeQuantityWithUnit(item).unit)}/>
                                    </Modal.Actions>
                            </Modal> 
                             <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash' content={<FormattedMessage id="orderresult.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="orderresult.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:item.data.ingredient.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="orderresult.modal.delete.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button basic color='teal'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="orderresult.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteListItem(event,item.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                        </Table.Cell>*/}
                     </Table.Row>
                );
        });
    }

     addListRowsPerSupplier(){
        const{currency,suppliers_lists,numberSuppliers} = this.state;
        let new_supplier = false;

        return suppliers_lists.map((elem,index) => { 
            //console.log(elem)
            //console.log(elem.supplier_name)
            //console.log(elem.ingredients_list)
            new_supplier = true
            let ingredients_list  =  elem.ingredients_list.sort(function (a, b) {
                return a.data.ingredient.name.toLowerCase().localeCompare(b.data.ingredient.name.toLowerCase()); 
            }) 

            return ingredients_list.map((item,index) => { 
                let supplier = ""
                let supplier_title = ""
                let supplier_row_title = ""

/*                if (item.data.ingredient.supplier){
                    supplier = item.data.ingredient.supplier.name
                }else{
                    supplier = item.data.ingredient.supplier__name
                } */
                supplier = elem.supplier_name
                if (elem.supplier_name == 'undefined'){
                    supplier_row_title = <FormattedMessage id="orderresult.list.ingredient.supplier.divider.notdefined"
                                                       defaultMessage='No supplier'/>
                }else{
                    supplier_row_title = elem.supplier_name
                    supplier_title = elem.supplier_name
                } 

                if (new_supplier == true && numberSuppliers > 1){
                    new_supplier = false
                    return ([
                        <Table.Row style={{'background-color':'#f5f5f5'}}>
                                <Table.Cell style={{color:'#00b3b3'}}>{supplier_row_title}</Table.Cell>
                                <Table.Cell colspan='4'/>
                                <Table.Cell textAlign='right' style={{color:'#00b3b3'}}> 
                                                    <FormattedMessage id="orderresult.list.ingredient.supplier.subtotal.text"
                                                                      defaultMessage='Total :'/>{'  '}
                                                    <FormattedNumber value={this.computeTotalCost(ingredients_list)} 
                                                                     style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                 </Table.Cell>
                        </Table.Row>,
                        <Table.Row key={index} >
                            <Table.Cell width={4} textAlign='left' style={{'color': 'black'}} >
                              {item.data.ingredient.name.length >= 45 && 
                                  item.data.ingredient.name.substring(0,45) + '..'}
                              {item.data.ingredient.name.length < 45 && 
                                    item.data.ingredient.name}
                               {/*  {item.data.ingredient.name} */}
                            </Table.Cell>
                            <Table.Cell width={2} textAlign='center'>{supplier_title}</Table.Cell>
                            {item.data.quantity_type == 'quantity' &&
                                <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item,supplier)}</Table.Cell>}
                            {item.data.quantity_type != 'quantity' &&
                                <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item,supplier)}</Table.Cell>}
    
                            <Table.Cell width={2} textAlign='right'>{this.computeQuantityWithUnit(item,supplier)}</Table.Cell>
                            <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                            <Table.Cell width={2} textAlign='right'>
                                 <FormattedNumber value={(item.data.item_cost*item.count)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                            </Table.Cell>
                         </Table.Row>])
                    ;
                }else{
                    return (
                        <Table.Row key={index} >
                            <Table.Cell width={4} textAlign='left' style={{'color': 'black'}} >
                            {item.data.ingredient.name.length >= 45 && 
                                  item.data.ingredient.name.substring(0,45) + '..'}
                            {item.data.ingredient.name.length < 45 && 
                                    item.data.ingredient.name}
                            {/*  {item.data.ingredient.name} */}
                            </Table.Cell>
                            <Table.Cell width={2} textAlign='center'>{supplier_title}</Table.Cell>
                            {item.data.quantity_type == 'quantity' &&
                                <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item,supplier)}</Table.Cell>}
                            {item.data.quantity_type != 'quantity' &&
                                <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item,supplier)}</Table.Cell>}
    
                            <Table.Cell width={2} textAlign='right'>{this.computeQuantityWithUnit(item,supplier)}</Table.Cell>
                            <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                            <Table.Cell width={2} textAlign='right'>
                                 <FormattedNumber value={(item.data.item_cost*item.count)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                            </Table.Cell>
                         </Table.Row>
                    );
                }
          });
      });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    hideModal() {
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalSave: false });
/*         this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ newItemWeight: '' }); */
    }

    render() { 
        const { alert,dispatch,customer ,intl,loading} = this.props;
        const {recipesData,ingredientsData,ingredientsList,currency,suppliers_lists } = this.state;
        let membership = null;

        if (customer){
             membership = customer.membership;
        }

        return ( 
                     <SideLeftUncoverOrders>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="orderresult.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ordersList/'}><FormattedMessage id="orderresult.link.orders"
                                                              defaultMessage='Orders'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="orderresult.link.order"
                                                              defaultMessage='Order'/></Breadcrumb.Section>
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                            <Grid.Column  width={8}>
                                    <Header as='h2'>
                                        <img src={img_order} style={{width:'50px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="orderresult.page.title"
                                                              defaultMessage='Current Order'/>
                                            <Header.Subheader><FormattedMessage id="orderresult.header.caption.title"
                                                                    defaultMessage='Creating' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {/* <Grid.Row columns={1}/> */}
                            {!this.state.ingredients_added &&
                            <Grid.Row>
                                <Grid.Column>
                                    <br/><Label basic style={styles.FontLato14Bold} size='large' color='blue'>
                                            <FormattedMessage id="orderresult.list.requested.title"
                                                              defaultMessage='Order list requested' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>}
                            {!this.state.ingredients_added && 
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           //striped 
                                           singleLine 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell>Order</Table.HeaderCell> */}
                                                <Table.HeaderCell textAlign='center'>
                                                    <FormattedMessage id="orderresult.list.requested.table.column1"
                                                                     defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='left'>
                                                    <FormattedMessage id="orderresult.list.requested.table.column2"
                                                                     defaultMessage='Item Name' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='right'>
                                                    <FormattedMessage id="orderresult.list.requested.table.column3"
                                                                     defaultMessage='Recipe yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='right'>
                                                    <FormattedMessage id="orderresult.list.requested.table.column4"
                                                                     defaultMessage='Unit price' />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {recipesData &&
                                            this.addOrderRows(recipesData)
                                        }
{/*                                         //{ingredientsData &&
                                        //    this.addOrderIngredientsRows(ingredientsData)
                                        //} */}
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>         
                            </Grid.Row>}   
                            <Grid.Row>
                                <Grid.Column>
                                    <br/><br/> <Label basic style={styles.FontLato14Bold} size='large' color='blue'>
                                            <FormattedMessage id="orderresult.shopping.list.requested.title"
                                                              defaultMessage='Your shopping list' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14} > 
                                    <Table id='table'
                                           //selectable 
                                           unstackable
                                           compact 
                                           //striped 
                                           singleLine 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column1"
                                                                        defaultMessage='Ingredient' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column5"
                                                                        defaultMessage='Supplier' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column2"
                                                                     defaultMessage='Purchase Unit' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column3"
                                                                     defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column6"
                                                                        defaultMessage='Stock Quantity' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="orderresult.shopping.list.requested.table.column4"
                                                                        defaultMessage='Cost' />
                                                </Table.HeaderCell>
                                                {/* <Table.HeaderCell/> */}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
    {/*                                     {ingredientsList &&
                                                this.addListRows(ingredientsList)
                                        } */}
                                        {suppliers_lists &&
                                                this.addListRowsPerSupplier()
                                        } 
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <Label basic style={styles.FontLato14Bold} size='large' color='grey'>
                                                        <FormattedMessage id="orderresult.shopping.list.requested.table.text.total"
                                                                          defaultMessage='TOTAL' />
                                                    </Label>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2}/>
                                                <Table.HeaderCell width={3} textAlign='center'>
{/*                                                     <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='blue'
                                                        style={styles.FontLato14Bold}
                                                        onClick={(event) => this.saveClickHandler(event)}><Icon name='save'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.save"
                                                                             defaultMessage='Save Order' />
                                                    </Button> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
{/*                                                     <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='teal'
                                                        style={styles.FontLato14Bold}
                                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.print"
                                                                             defaultMessage='Print PDF' />
                                                    </Button> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'/>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <Label basic style={styles.FontLato14Bold} size='large' color='grey'>
                                                    {ingredientsList &&
                                                                <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                }
                                                    </Label>
                                            </Table.HeaderCell>
                                            {/* <Table.HeaderCell /> */}
                                            <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="orderresult.printorder.header.title"
                                                                    defaultMessage='Print Order'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="orderresult.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="orderresult.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="orderresult.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="orderresult.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="orderresult.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal> 
                                            <Modal
                                                open={this.state.activeModalSave}
                                                dimmer='default' 
                                                size='tiny' 
                                                style={styles.Modal} 
                                                onClose={this.hideModal}>
                                                <Header icon='edit' content={<FormattedMessage id="orderresult.saveorder.modal.name.title"
                                                                                                defaultMessage='Choose order reference name'/>} />
                                                <Modal.Content>
                                                    <Input
                                                        type="text"
                                                        //placeholder="Renaming"
                                                        id="nameOrder"
                                                        value={this.state.orderName}
                                                        onChange={this.onNameSaveOrder}
                                                        style={{minWidth:'15em',maxWidth:'30em'}}/>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick={(e) =>this.hideModal()}>
                                                        <FormattedMessage id="orderresult.saveorder.modal.save.button.no"
                                                                        defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button basic color='teal' loading={loading}
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="orderresult.saveorder.modal.save.button.yes"
                                                                                    defaultMessage='Save'/>}
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.saveOrder(event)}>
                                                    </Button>
                                                    </Modal.Actions>
                                            </Modal> 
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer={14} tablet={16} only='computer tablet' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}}>
                                <p>
                                    <Icon color='blue' name='info'/>
                                    <FormattedMessage id="orderresult.info.bottom.message"
                                                    defaultMessage="Save your order for more : Split in supplier orders, Update, Deduce from stock, Destock ...">  
                                    </FormattedMessage>
                                </p>
                                </Grid.Column>
                                <Grid.Column mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}}>
                                <p>
                                    <Icon color='blue' name='info'/>
                                    <FormattedMessage id="orderresult.info.bottom.message"
                                                    defaultMessage="Save your order for more : Split in supplier orders, Update, Deduce from stock, Destock ...">  
                                    </FormattedMessage>
                                </p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                               <Grid.Column computer={14} 
                                            tablet={16} 
                                            style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} 
                                            only='computer tablet'>
                                              <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='blue'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={(event) => this.saveClickHandler(event)}><Icon name='save'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.save"
                                                                             defaultMessage='Save Order' />
                                              </Button>
                                              <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='grey'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.print"
                                                                             defaultMessage='Export PDF' />
                                                </Button>
                                               <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='teal'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={this.updateOrder}><Icon name='edit'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.update"
                                                                             defaultMessage='Update' />
                                                </Button>
                                               <br/>
                               </Grid.Column> 
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='blue'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={(event) => this.saveClickHandler(event)}><Icon name='save'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.save"
                                                                             defaultMessage='Save Order' />
                                              </Button>
                              </Grid.Column> 
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='grey'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.print"
                                                                             defaultMessage='Export PDF' />
                                                </Button>
                                                <br/> 
                               </Grid.Column> 
                               <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='teal'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={this.updateOrder}><Icon name='edit'/>
                                                            <FormattedMessage id="orderresult.shopping.list.requested.table.button.update"
                                                                             defaultMessage='Update' />
                                                </Button>
                                                <br/> 
                               </Grid.Column>            
                            </Grid.Row>
                            <Grid.Row columns={1}/>
                            
                        </Grid>
                     </SideLeftUncoverOrders>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    const { customer } = state.payment;
    const { loading } = state.recipes;
    return {
        customer,
        loading,
        alert,
        dispatch,
    };
}

const connectedOrderResult = injectIntl(connect(mapStateToProps)(OrderResult));
export { connectedOrderResult as OrderResult };
 
