import React, { Component } from 'react';
import { Table, Header, Button, Grid, Label,Dropdown,Popup,Input,Icon,Dimmer,Loader,Sidebar,Segment,Form,Message,Image,Breadcrumb,Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {alertActions,recipesActions,categoryRecipeActions,supplierActions,paymentActions, ingredientsActions} from '../_actions'
import SideLeftUncoverOrders from './SidebarOrders';
import {history,toaster_dispatch,isNumeric,toaster_info,trim,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,unitWeight,unitVolume,
    isFloat, MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER, typeYield,isWeight,isVolume,mgrams,kgrams,cliters,grams,mliters,liters,pack_unit_key_reserved, box_pack_unit_key_reserved,
    computeWeightUnitFactor,computeVolumeUnitFactor,isPack} from '../_helpers';
/* import {  WEIGHT_INSTRUCTION_LINE_TITLE,
    WEIGHT_INSTRUCTION_LINE_KG,
    WEIGHT_INSTRUCTION_LINE_G,
    WEIGHT_INSTRUCTION_LINE_MG,
    WEIGHT_INSTRUCTION_LINE_OZ,
    WEIGHT_INSTRUCTION_LINE_LB,
    WEIGHT_INSTRUCTION_LINE_EX,
    VOLUME_INSTRUCTION_LINE_TITLE,
    VOLUME_INSTRUCTION_LINE_L,
    VOLUME_INSTRUCTION_LINE_ML,
    VOLUME_INSTRUCTION_LINE_CL,
    VOLUME_INSTRUCTION_LINE_FL,
    VOLUME_INSTRUCTION_LINE_GAL,
    VOLUME_INSTRUCTION_LINE_EX,
    QUANTITY_INSTRUCTION_LINE_TITLE,
    QUANTITY_INSTRUCTION_LINE_NUMBER,
    QUANTITY_INSTRUCTION_LINE_EX
  } from '../_helpers'; */

import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {isEmpty,uniqueId} from "lodash";
import {styles} from './Styles';
import img_bag from '../Assets/shopping-bag.png';
import img_pan from '../Assets/pan.png'
import img_order from '../Assets/receipt.png'
import img_performance from '../Assets/performance.png'
import './FloatingMenu.css';
//import OutsideClickHandler from 'react-outside-click-handler';

const DUPLICATE_ADD_INGREDIENT_MESSAGE_TITLE = <FormattedMessage id="orders.toaster.ingredient.add.duplicate.message.title"
                                                  defaultMessage='Adding ingredient/recipe ...'/>
         
const DUPLICATE_ADD_INGREDIENT_MESSAGE_CONTENT = <FormattedMessage id="orders.toaster.ingredient.add.duplicate.message.content"
                                                  defaultMessage='Already added to your order'/>


const UNITS = <FormattedMessage id="order.quantity.unit.plural.text"
                        defaultMessage='unit(s)'/>

const options = [
                  { key: 1, text: <FormattedMessage id="orders.options.from.recipes"
                                                              defaultMessage='From recipes'/>, value: 'recipe', image:<img src={img_pan} style={{width:'30px'}}/> },
                  { key: 2, text: <FormattedMessage id="orders.options.from.ingredients"
                                                              defaultMessage='Ingredients order'/>, value: 'ingredient',image:<img src={img_bag} style={{width:'30px'}}/> }]

class Orders extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location} = props;
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);
        //this.filterIngredientsSupplierList=this.filterIngredientsSupplierList.bind(this);
        this.filterIngredientsSupplierList2=this.filterIngredientsSupplierList2.bind(this);
        //this.removeFilters=this.removeFilters.bind(this);
        //this.filterIngredientsNameList = this.filterIngredientsNameList.bind(this);
        //this.removeIngredientsFilters=this.removeIngredientsFilters.bind(this);
        this.toggleOrderTypeReset=this.toggleOrderTypeReset.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.state = { prevRecipe : props.recipes,
                       prevIngredient : props.ingredients,
                       filtering: false,
                       orderList:location && location.state?location.state.recipesList : [],
                       orderIngredientsList: location && location.state?location.state.ingredientsList : [],
                       availableRecipesList:[],
                       availableIngredientsList:[],
                       emptyOrders: true,
                       units: location && location.state?location.state.recipesUnits : [],
                       ingredQuantities: location && location.state?location.state.ingredientsUnits : [],
                       isMobile: false,
                       currency : userIn.user.currency,
                       newQuantity:0,
                       orderType:'recipe',
                       activeModalOrderType: false,
                       supplierSelected: null,
                       orderName :  uniqueId(new Date().toLocaleDateString(props.intl.locale).split('T')[0]+'_REF_'),
                    }
        //props.dispatch(recipesActions.getAll(true));
        props.dispatch(recipesActions.getOrdersRecipes(0,'infinite', 0));
/*      props.dispatch(recipesActions.getOrdersRecipes(0,50,1));
        props.dispatch(recipesActions.getOrdersRecipes(50,100,1));
        props.dispatch(recipesActions.getOrdersRecipes(100,200,1));
        props.dispatch(recipesActions.getOrdersRecipes(200,300,1));
        props.dispatch(recipesActions.getOrdersRecipes(300,'infinite',0)); */
        props.dispatch(categoryRecipeActions.getAll()); 
        //props.dispatch(ingredientsActions.getAll(true));
        props.dispatch(ingredientsActions.getExtraSuppliers());
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(supplierActions.getAll());
        //console.log(this.state.orderList)
        //console.log(this.state.orderIngredientsList)
    }


    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        if((props.ingredients!==state.prevIngredient)&&(props.ingredients.items)){
            return { prevIngredient : props.ingredients,
                     availableIngredientsList: props.ingredients.items };
        }
        return null;
    }

    componentDidMount() {
        const {orderList,orderIngredientsList} = this.state;

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (orderList.length > 0){
            this.setState({ orderType:'recipe'});
            //this.setState({ orderList:orderList});
            this.setState({ emptyOrders: false});
            history.replace(this.props.location.state, null);
        }else if (orderIngredientsList.length > 0){
            this.setState({ orderType:'ingredient'});
            //this.setState({ orderIngredientsList:orderIngredientsList});
            this.setState({ emptyOrders: false});
            history.replace(this.props.location.state, null);
        }
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    seeIngredient (event,index) {
        event.preventDefault();
        history.push('/ingredientdetails/'+index);
    }

    createIngredient(){
        history.push('/newingredient/');
    }
    createRecipe(){
        history.push('/newrecipe/');
    }

    onNewQuantity = (e,item) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    addToCartList (event,recipe) {
        event.preventDefault();
        const {intl} = this.props;
        var found = this.state.orderList.find(x => x.id == recipe.id)

        if (!found){
            //this.props.dispatch(recipesActions.get(recipe.id));
            let updateOrderList = [...this.state.orderList,recipe]  
             //Sort list alphabetically
            updateOrderList = updateOrderList.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            this.setState({ orderList:updateOrderList});
            //this.setState({ orderList: [...this.state.orderList,recipe]});  
            this.setState({ units: [...this.state.units,{id: recipe.id, 
                                                         value: "1", 
                                                         quantity_type:typeYield(recipe.yield_units),
                                                         is_nb_recipes: false,
                                                         unit: recipe.yield_units.toLowerCase() }]});

            this.setState({ emptyOrders: false});
        }else{
            toaster_info(intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.content' }))
        }  
    }

    addIngredientToCartList (event,ingredient) {
        event.preventDefault();
        const {intl} = this.props;
        const {supplierSelected} = this.state;
        var found = this.state.orderIngredientsList.find(x => x.ingredient.id == ingredient.id)

        if (!found){
            let updateOrderIngredientsList = [...this.state.orderIngredientsList,{ingredient:ingredient,
                                                                                  supplier:supplierSelected }]
            //Sort list alphabetically
            updateOrderIngredientsList = updateOrderIngredientsList.sort(function (a, b) {
                return a.ingredient.name.toLowerCase().localeCompare(b.ingredient.name.toLowerCase());
            })
            this.setState({ orderIngredientsList:updateOrderIngredientsList});
            //this.setState({ orderIngredientsList: [...this.state.orderIngredientsList,ingredient]});  

            this.setState({ ingredQuantities: [...this.state.ingredQuantities,{id: ingredient.id, 
                                                                               value: "1",
                                                                               quantity_type:'quantity',
                                                                               unit: pack_unit_key_reserved,/*ingredient.unit.toLowerCase()]});}*/
                                                                               supplier: supplierSelected}]});
            this.setState({ emptyOrders: false});
        }else{
            toaster_info(intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.content' }))
        }    
    }

    removeFromCartList (event,recipe) {
        event.preventDefault();
        const {orderList,orderIngredientsList,units} = this.state;

        //Manage orders removal based on recipe name
        let updatedList = orderList.filter((item) => {
            return item.id!=recipe.id
        });

        if (updatedList.length == 0){
            this.setState({ orderList: []});  
            this.setState({ units: []}); 
        }
        else{
            this.setState({ orderList: updatedList});  
        }   

        //Manage units list removal based on index
        let updatedUnits = units.filter((item) => {
            return item.id!=recipe.id
          });
        this.setState({ units: updatedUnits});  

        if (orderIngredientsList.length == 0 && updatedList.length==0){
            this.setState({ emptyOrders: true});
        }
    }


    removeIngredientFromCartList (event,ingredient) {
        event.preventDefault();
        const {orderList,orderIngredientsList,ingredQuantities} = this.state;

        //Manage orders removal based on recipe name
        let updatedList = orderIngredientsList.filter((item) => {
            return item.ingredient.id!=ingredient.id
        });

        if (updatedList.length == 0){
            this.setState({ orderIngredientsList: []});  
            this.setState({ ingredQuantities: []}); 
        }
        else{
            this.setState({ orderIngredientsList: updatedList});  
        }   

        //Manage units list removal based on index
        let updatedUnits = ingredQuantities.filter((item) => {
            return item.id!=ingredient.id
          });
        this.setState({ ingredQuantities: updatedUnits});  

        if (updatedList.length == 0 && orderList.length==0){
            this.setState({ emptyOrders: true});
        }

    }

/*     editQuantityHandlerInput (event,index,article,type) {
        event.preventDefault();
        let quantity = 0;
        if (type == 'recipe'){
            this.state.units.map((item) => {
               if (item.id == article.id){
                   quantity = item.value
               }
           }); 
           
           quantity = optimizeDecimalPart(Number(quantity))
           this.setState({ newQuantity: quantity});

            this.setState({ editCellRecipe: true});
            this.setState({ editCellIndexRecipe: index});
        }else{

            this.state.ingredQuantities.map((item) => {
                if (item.id == article.id){
                    quantity = item.value
                }
            }); 
            
            quantity = optimizeDecimalPart(Number(quantity))
            this.setState({ newQuantity: quantity});

            this.setState({ editCellIngredient: true});
            this.setState({ editCellIndexIngredient: index});
        }
    }; */

//COMMENT
/*      onUnitsChange = (event,id) => {
        event.preventDefault();
       if (this.state.units.some(unit => unit.id == id)){
            let unitsList = this.state.units.map((item) => {
                if (item.id == id){
                    let input  = event.target.value;
                    item.value =  input.trim();
                }
                return item;
            }); 
            this.setState({ units: unitsList});
        }else{
            let input  = event.target.value;
            this.setState({ units: [...this.state.units,{id: id, value: input.trim()}]});
        }
    }

    onIngredientsUnitsChange = (event,id,type) => {
        event.preventDefault();
        if (this.state.ingredQuantities.some(quantity => quantity.id == id)){
            let quantitiesList = this.state.ingredQuantities.map((item) => {
                if (item.id == id){
                    let input  = event.target.value;
                    item.value =  input.trim();
                    item.quantity_type = type;
                }
                return item;
            }); 
            this.setState({ ingredQuantities: quantitiesList});
        }else{
            let input  = event.target.value;
            this.setState({ ingredQuantities: [...this.state.ingredQuantities,{id: id, value: input.trim(), quantity_type:type}]});
        }
    } */
//FIN COMMENT

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity != "")
          && Math.trunc(quantity).toString().length<10)
          return true;
        return false;
    }

  /*    
    getRecipeUnit(recipe){
        let quantity = 0;
         this.state.units.map((item) => {
            if (item.id == recipe.id){
                quantity = item.value;
            }
        });
        return quantity;
    }

    getIngredientUnit(ingredient){
        let quantity = 0;
         this.state.ingredQuantities.map((item) => {
            if (item.id == ingredient.id){
                quantity = item.value;
            }
        });
        return quantity;
    }


    updateQuantityOnBlur (event,article,type) {
        event.preventDefault();
        const {newQuantity} = this.state;
        const {dispatch} = this.props;

        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        let quantity_origin = Number(this.getRecipeUnit(article)).toFixed(3) 

        if (this.assertQuantityField(quantity) && quantity!=quantity_origin){
            if (type == 'recipe'){
                let unitsList = this.state.units.map((item) => {
                    if (item.id == article.id){
                        item.value =  quantity.trim();
                        //item.quantity_type = type;
                    }
                    return item;
                }); 
                this.setState({ units: unitsList});
                this.setState({ editCellRecipe: false });
            }else{
                let ingredientUnitsList = this.state.ingredQuantities.map((item) => {
                    if (item.id == article.id){
                        item.value =  quantity.trim();
                        //item.quantity_type = type;
                    }
                    return item;
                }); 
                this.setState({ ingredQuantities: ingredientUnitsList});
                this.setState({ editCellIngredient: false });
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const title = <FormattedMessage id="order.quantity.check.error.title"
                                                 defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="order.quantity.check.error.message"
                                                  defaultMessage='Quantity field too big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'order.quantity.check.error.title',
                                             content:'order.quantity.check.error.message'}));   
             }
            this.setState({ editCellRecipe: false });
            this.setState({ editCellIngredient: false });
        }
    }

    updateQuantityOnEnter (event,article,type) {
        const {newQuantity,} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                let quantity_origin = Number(this.getRecipeUnit(article)).toFixed(3) 

                if (this.assertQuantityField(quantity) && quantity!=quantity_origin){
                    if (type == 'recipe'){
                        let unitsList = this.state.units.map((item) => {
                            if (item.id == article.id){
                                item.value =  quantity.trim();
                                //item.quantity_type = type;
                            }
                            return item;
                        }); 
                        this.setState({ units: unitsList});
                        this.setState({ editCellRecipe: false });
                    }else{
                        let ingredientUnitsList = this.state.ingredQuantities.map((item) => {
                            if (item.id == article.id){
                                item.value =  quantity.trim();
                                //item.quantity_type = type;
                            }
                            return item;
                        }); 
                        this.setState({ ingredQuantities: ingredientUnitsList});
                        this.setState({ editCellIngredient: false });
                    }
                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const title = <FormattedMessage id="order.quantity.check.error.title"
                                                        defaultMessage='Quantity update error'/>
                       const content =  <FormattedMessage id="order.quantity.check.error.message"
                                                         defaultMessage='Quantity field too big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'order.quantity.check.error.title',
                                                    content:'order.quantity.check.error.message'}));   
                    }
                    this.setState({ editCellRecipe: false });
                    this.setState({ editCellIngredient: false });
                }
        }
    }
 */
    assertQuantityPermittedIncluded(elem){
        let isFound = false;
        let unitPart = "";
        let inputs= elem.value.replace(/,/g, '.').toLowerCase();
        inputs = inputs.split(' ').filter(Boolean);

        //console.log(inputs);
        if (inputs.length > 2)
          return false;
        
        if (inputs.length === 1){
          if (elem.quantity_type === 'quantity'){
            if (!isNumeric(inputs)) 
                  return false;
          }else{
            //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
            //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
            let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
            if (tokens.length !== 2)
              return false;
            for (let i of tokens){
              if (!isNumeric(i)) unitPart = i;
              //console.log(unitPart);
            }
          }
    
        }else{
          if (inputs.length > 2)
              return false
          if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
              return false
          for (let i of inputs){
            if (!isNumeric(i)) unitPart = i;
          }
        }
    
        if (elem.quantity_type === 'volume'){
          for (let unit of unitVolume ){
            if (unitPart === unit){
                isFound = true;
                break;
            }
          }
        }else if (elem.quantity_type === 'weight'){
          for (let unit of unitWeight ){
            if (unitPart === unit){
              isFound = true;
              //console.log('isFound');
              break;
            }
         }
        }else {
            if (inputs.length > 1)
                return false
            isFound = true;
        }
        return isFound;
      }
    


    handleAllRecipesValidation(){
        const { units} = this.state;
        let ret = true;
        units.forEach((item)=>{
            item.value = trim(item.value).toLowerCase().replace(/,/g, '.')
            if ((item.value == "") || (item.value == "0")|| (item.value.length == 0) || !isNumeric(item.value)){
                ret = false;    
            }     
            if (Math.trunc(item.value).toString().length>=9){
                ret = false;
            }
        });
        return ret;
    }

    handleAllIngredientsValidation(){
        const {ingredQuantities} = this.state;
        let ret = true;

        for (let elem of ingredQuantities){
            elem.value = trim(elem.value).toLowerCase().replace(/,/g, '.');
            if ((elem.value == "") || (elem.value == "0")|| (elem.value.length == 0)){
                ret = false;    
            } 
            if (!this.assertQuantityPermittedIncluded(elem)) ret = false;

            if (Math.trunc(elem.value).toString().length>=9){
                ret = false;
            }
        } 
        return ret;
    }

    cleanI18nDecimals(){
        const {units/*,ingredQuantities*/} = this.state;
        let cleanUnits=[]
        //let CleanIngredQuantities=[]

          for (let u of units){
            cleanUnits.push({id:u.id,
                             value:trim(u.value).toLowerCase().replace(/,/g, '.'),
                             quantity_type:u.quantity_type,
                             is_nb_recipes: u.is_nb_recipes,
                             unit: u.unit});
          }
          this.setState({ units: cleanUnits});

/*           for (let u of ingredQuantities){
            CleanIngredQuantities.push({id:u.id,
                                        value:trim(u.value).toLowerCase().replace(/,/g, '.'),
                                        quantity_type:u.quantity_type,
                                        unit: u.unit,
                                        supplier: u.supplier});
          }
          this.setState({ ingredQuantities: CleanIngredQuantities}); */
      }
    

    createOrder = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        
        dispatch(alertActions.clear());
        this.setState({ submitted: true });

        this.cleanI18nDecimals();

        if (!this.handleAllRecipesValidation()){
            //alert('handleAllRecipesValidation');
            //toaster_error('Ingredient validation Error','One quantity at least is invalid !!');

            const title = <FormattedMessage id="order.quantity.createorder.check.error.title"
                                                 defaultMessage='Quantity update error'/>
            const content =  <FormattedMessage id="order.quantity.createorder.check.error.message"
                                                  defaultMessage='Quantity non numeric or invalid'/>
            dispatch(alertActions.error({title:'order.quantity.createorder.check.error.title',
                                             content:'order.quantity.createorder.check.error.message'}));   

            return;
        }

        if (!this.handleAllIngredientsValidation()){
            //alert('handleAllIngredientsValidation');
            //toaster_error('Ingredient validation Error','One quantity at least is invalid !!');

            const title = <FormattedMessage id="order.quantity.createorder.check.error.title"
                                            defaultMessage='Quantity update error'/>
            const content =  <FormattedMessage id="order.quantity.createorder.check.error.message"
                                               defaultMessage='Quantity non numeric or invalid'/>
            dispatch(alertActions.error({title:'order.quantity.createorder.check.error.title',
                                        content:'order.quantity.createorder.check.error.message'}));  

            return;
        }

        //dispatch(alertActions.success({title:'Order Cart Creation',content:'Created Successfully'}));
        //Need to Manage PDF file generation here
        history.push('/orderresult',{
                        recipesList:this.state.orderList,
                        recipesUnits:this.state.units,
                        ingredientsList:this.state.orderIngredientsList,
                        ingredientsUnits:this.state.ingredQuantities
                        
        });

        this.setState({ emptyOrders: true});
        this.setState({ orderList: []});  
        this.setState({ orderIngredientsList: []}); 
        this.setState({ units: []});
        this.setState({ ingredQuantities: []});
        this.setState({ availableRecipesList: []});
        this.setState({ availableIngredientsList: []});
    } 

    createDirectOrder = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        
        dispatch(alertActions.clear());
        this.setState({ submitted: true });

        this.cleanI18nDecimals();

        if (!this.handleAllRecipesValidation()){
            //alert('handleAllRecipesValidation');
            //toaster_error('Ingredient validation Error','One quantity at least is invalid !!');

            const title = <FormattedMessage id="order.quantity.createorder.check.error.title"
                                                 defaultMessage='Quantity update error'/>
            const content =  <FormattedMessage id="order.quantity.createorder.check.error.message"
                                                  defaultMessage='Quantity non numeric or invalid'/>
            dispatch(alertActions.error({title:'order.quantity.createorder.check.error.title',
                                             content:'order.quantity.createorder.check.error.message'}));   

            return;
        }

        if (!this.handleAllIngredientsValidation()){
            //alert('handleAllIngredientsValidation');
            //toaster_error('Ingredient validation Error','One quantity at least is invalid !!');

            const title = <FormattedMessage id="order.quantity.createorder.check.error.title"
                                            defaultMessage='Quantity update error'/>
            const content =  <FormattedMessage id="order.quantity.createorder.check.error.message"
                                               defaultMessage='Quantity non numeric or invalid'/>
            dispatch(alertActions.error({title:'order.quantity.createorder.check.error.title',
                                         content:'order.quantity.createorder.check.error.message'}));  

            return;
        }


        let properties = {
                ref_name: trim(this.state.orderName).substring(0,80),
                recipesUnits:this.state.units,
                ingredientsUnits: this.state.ingredQuantities,
        }
        this.props.dispatch(recipesActions.saveOrder(properties));  
    

        /*
        this.setState({ emptyOrders: true});
        this.setState({ orderList: []});  
        this.setState({ orderIngredientsList: []}); 
        this.setState({ units: []});
        this.setState({ ingredQuantities: []});
        this.setState({ availableRecipesList: []});
        this.setState({ availableIngredientsList: []});*/
    } 



/*     removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({categorySelected: ""});
    } 

     filterNameList(event){
        event.preventDefault();
        let updatedList = this.state.availableRecipesList.filter((item) => {
            return (item.name).toLowerCase().search(event.target.value.toLowerCase()) !== -1;
          });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        let updatedList = this.state.availableRecipesList.filter((item) => {
            if (item.category && item.category.name){
             return item.category.name.toLowerCase().search(data.value.toLowerCase()) !== -1;
            }
            return null;
        });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({categorySelected: data.value});
    } */

    //COMMENT
/*     onFocusIngredientField(e,index,type_quantity) {
        e.preventDefault();
        this.setState({ highlighted: {index,type_quantity} });
    } */
    //END COMMENT

    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList,availableIngredientsList,toggledRecipes} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({supplierSelected: null});
        this.setState({categorySelected: ""});

        if (toggledRecipes){
            let updatedList = availableRecipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
        }else{
            let updatedList = availableIngredientsList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
            this.setState({newItems: updatedList});
            this.setState({filtering: true}); 
        }

    }

/*     filterIngredientsNameList(event){
        event.preventDefault();
        const {availableIngredientsList} = this.state;
        let updatedList = availableIngredientsList.filter((item) => {
            return (item.name).toLowerCase().search(event.target.value.toLowerCase()) !== -1;
          });
        this.setState({newItemsIngredients: updatedList});
        this.setState({filteringIngredients: true});
    } */

    filterIngredientsSupplierList(event,data){
        event.preventDefault();
        const {availableIngredientsList} = this.state;
        const {supplier} = this.props;
        let supplier_id = data.value;
        let elem = supplier.options.find(supplier => supplier.value == supplier_id) 

        let updatedList = availableIngredientsList.filter((item) => {
                if (item.supplier__name && elem){
                 return item.supplier__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                }
                return null;
            });
        if (elem){
            this.setState({ supplierSelected: {id:supplier_id,
                                              name: elem.text } });
            this.setState({filtering: true});
        }else{
            this.setState({ supplierSelected:null})
            this.setState({filtering: false});
        }

/*         if (data.value == ""){
            this.setState({filtering: false});
            this.setState({supplierSelected: ""});
            this.setState({nameSelected: ""});
            return;
        }
        let updatedList = availableIngredientsList.filter((item) => {
            if (item.supplier__name){
             return item.supplier__name.toLowerCase().search(data.value.toLowerCase()) !== -1;
            }
            return null;
        }); */
        this.setState({newItems: updatedList});
        //this.setState({filtering: true});
        //this.setState({supplierSelected: data.value});
        this.setState({nameSelected: ""});
    }


     filterIngredientsSupplierList2(event,data){
        event.preventDefault();
        const {availableIngredientsList} = this.state;
        const {supplier} = this.props;
        let supplier_id = data.value;
        let elem = supplier.options.find(supplier => supplier.value == supplier_id) 

        let updatedList = availableIngredientsList.filter((item) => {
                let ingredient_return = null
/*               if (elem && item.supplier && item.supplier.name ){
                  ingredient_return = item.supplier.name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                  if (ingredient_return) return ingredient_return
                }

                if (elem && item.extra_suppliers && item.extra_suppliers.length > 0){
                    let extras = item.extra_suppliers
                    for (let s of extras){
                        if (s.supplier){
                            ingredient_return = s.supplier.name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                            if (ingredient_return) return ingredient_return
                        }
                    }
                } */
                if (elem && item.supplier && item.supplier.id ){
                    if (item.supplier.id == supplier_id){
                        ingredient_return = item
                    }
                    if (ingredient_return) return ingredient_return
                  }

                if (elem && item.extra_suppliers && item.extra_suppliers.length > 0){
                    let extras = item.extra_suppliers
                    for (let s of extras){
                        if (s.supplier){
                            if (s.supplier.id == supplier_id){
                                ingredient_return = item
                            }
                            if (ingredient_return) return ingredient_return
                        }
                    }
                }
                return null;
            });

        if (elem){
            this.setState({ supplierSelected: {id:supplier_id,
                                              name: elem.text } });
            this.setState({filtering: true});
        }else{
            this.setState({ supplierSelected:null})
            this.setState({filtering: false});
        }

        this.setState({newItems: updatedList});
        this.setState({nameSelected: ""});
    }


    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        const {availableRecipesList} = this.state;

        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});

        if (data.value!="")
        {
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(category => category.value == category_id)

            if (elem){
                let updatedList = availableRecipesList.filter((item) => {
/*                     if (item.category && item.category.name){
                     return item.category.name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                    } */
                    if (item.category && item.category.id){
                        return item.category.id == elem.value
                    }
                    return null;
                });
                this.setState({newItems: updatedList});
                this.setState({filtering: true});
                this.setState({categorySelected: {id:category_id,
                                                  name:elem.text}});
            }
        }
    }

/*     removeIngredientsFilters(event){
        event.preventDefault();
        this.setState({filteringIngredients: false});
    } */


    onTypeQuantityChange = (event,data,id,type) => {
        event.preventDefault();

        if (type =='recipe'){
                let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        if (data.value == 'recipe') {
                           item.is_nb_recipes = true
                        }else{
                           item.is_nb_recipes = false
                        }
                    }
                    return item;
                }); 
                //console.log(unitsList)
                this.setState({ units: unitsList});
        }else{
                let ingredQuantitiesList = this.state.ingredQuantities.map((item) => {
                    if (item.id == id){
                        item.unit = data.value
                    }
                    return item;
                });
                //console.log(ingredQuantitiesList) 
                this.setState({ ingredQuantities: ingredQuantitiesList});
        }
    }

    onTypeQuantityValue = (id,type) => {
        const {intl} = this.props;
        let toReturn = 'unit' 
        let found
   
        if (type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                if (found.is_nb_recipes) {
                    toReturn = 'recipe'
                 }else{
                    toReturn = found.unit
                 }
            } 
        }else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                    if (found.unit== '') {
                        toReturn = intl.formatMessage({ id: 'order.quantity.unit.plural.text' }) 
                     }else{
                        toReturn = found.unit
                     }
            } 
        }
        return toReturn
    }

    onInputQuantityValue = (id,type) => {
        let toReturn = '1' 
        let found
        if ( type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }
        return toReturn
    }

    onInputQuantityChange = (event,id,type) => {
        event.preventDefault();
        if (type == 'recipe'){
                let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        //let input  = event.target.value;
                        //input = Number(trim(input.toString()).replace(/,/g, '.')).toFixed(3);
                        item.value = event.target.value
                    }
                    return item;
                }); 
                //console.log(unitsList)
                this.setState({ units: unitsList});
        }else{
            let ingredientUnitsList = this.state.ingredQuantities.map((item) => {
                    if (item.id == id){
                        item.value =  event.target.value;
                        //item.quantity_type = type;
                    }
                    return item;
            });
            //console.log(ingredientUnitsList)  
            this.setState({ ingredQuantities: ingredientUnitsList});
        }
    }

    onIncreaseDecreaseQuantity = (event,id,type,increase) => {
        event.preventDefault();
        if (type == 'recipe'){
                let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        if (increase) {
                            item.value = (parseInt(item.value) + 1).toString(); 
                        }else{
                            if (parseInt(item.value)>1){
                                item.value = (parseInt(item.value) - 1).toString();
                            }
                        }  
                    }
                    return item;
                }); 
                this.setState({ units: unitsList});
        }else{
            let ingredientUnitsList = this.state.ingredQuantities.map((item) => {
                    if (item.id == id){
                        if (increase) {
                            item.value = (parseInt(item.value) + 1).toString(); 
                        }else{
                            if (parseInt(item.value)>1){
                                item.value = (parseInt(item.value) - 1).toString();
                            }
                        }  
                    }
                    return item;
            });
            this.setState({ ingredQuantities: ingredientUnitsList});
        }
    }

    getRelevantOptions = (unit,type,purchase_pack,pack_units) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'order.quantity.unit.plural.text' }) 
        
        unit =  unit.toLowerCase()
        if (type == 'recipe'){
            options= [{key:unit,text:unit,value:unit},
                      {key:'recipe',text:<FormattedMessage id="order.quantity.recipe.text"
                                                           defaultMessage='recipe(s)'/>,value:'recipe'}] 
        }else{
            if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value :pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                }else if (kgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }   
            }
            else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }
                 else if (mliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (liters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack,value :box_pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }     

            }else{
                options= [{key:pack_unit_key_reserved,text: purchase_pack + ' ' + emptyUnit,value:pack_unit_key_reserved},
                          {key:box_pack_unit_key_reserved,text: pack_units + " x " + purchase_pack + ' ' + emptyUnit,value :box_pack_unit_key_reserved},
                          {key:emptyUnit,text:emptyUnit,value:emptyUnit}]
            }
        }
        return options;
    }




    addOrdersRows(orders){
        const {currency/*,submitted,editCellRecipe,editCellIndexRecipe,newQuantity*/} = this.state;
        return orders.map((recipe,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell  width={5}>
                            <Icon name='food' color='blue'/>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black'}}>
                               {recipe.name.length >= 65 && 
                                  recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                            </a>
                        </Table.Cell>
{/*                          <Table.Cell width={3} textAlign='center'>
                            <FormattedMessage id="order.row.input.units.placeholder"
                                                defaultMessage='Number of recipe units' >
                                {placeholder => 
                                    <Popup trigger={ <Input key={index} 
                                                            focus
                                                            type='text'
                                                            placeholder={placeholder}
                                                            id="units"
                                                            onChange={(e)=>this.onUnitsChange(e,recipe.id)}
                                                            error = {submitted &&  (!this.handleRecipesValidation(index)|| !this.assertNumericFields(index))}/>} 
                                            on='hover'
                                            position = 'right center'
                                            flowing>
                                            <p>{QUANTITY_INSTRUCTION_LINE_TITLE}</p>
                                            {QUANTITY_INSTRUCTION_LINE_NUMBER}
                                            <p>{QUANTITY_INSTRUCTION_LINE_EX}</p>
                                    </Popup>}
                                </FormattedMessage> 
                        </Table.Cell> */}
                        <Table.Cell width={3} textAlign='right' /* onClick={(event) => this.editQuantityHandlerInput(event,index,recipe,'recipe')} */ >
{/*                              {(!editCellRecipe || (editCellRecipe && index != editCellIndexRecipe) ) &&
                                <a href='#'><FormattedNumber value={this.getRecipeUnit(recipe)}/>{'  '}{ recipe.yield_units}</a>}
                            {editCellRecipe && index == editCellIndexRecipe && 
                            <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCellRecipe: false})}}>  */}
                                <Form>
                                    <Form.Group widths='equal'>
                                       <Icon name='minus circle' color='teal' size='large'
                                              style={{cursor:'pointer', padding:'0.3em 0.7em 0em 0em'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,recipe.id,'recipe',false)} /> {' '}
                                        <Icon name='plus circle' color='teal' size='large'
                                              style={{cursor:'pointer', padding:'0.3em 1em 0em 0em'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,recipe.id,'recipe',true)} /> 
                                        <Form.Input key={index} 
                                                        type="text"
                                                        id="quantity"
                                                        //value={newQuantity}
                                                        //onChange={this.onNewQuantity}
                                                        value ={this.onInputQuantityValue(recipe.id,'recipe')}
                                                        onChange={(event) => this.onInputQuantityChange(event,recipe.id,'recipe')}
                                                        //onBlur={(event) => this.updateQuantityOnBlur(event,recipe,'recipe')} 
                                                        //onKeyPress={(event) => this.updateQuantityOnEnter(event,recipe,'recipe')} 
                                                        autoFocus
                                                        style={{height:'2.5em',minWidth:'5em',maxWidth:'8em',padding:'0em 0.5em 0em 0.5em'}}
                                                />  
                                            {/* {recipe.yield_units} */}
                                            <Form.Dropdown key={index} 
                                                        //button //basic
                                                        options={this.getRelevantOptions(recipe.yield_units,'recipe',"")}
                                                        onChange={(event,data)=>this.onTypeQuantityChange(event,data,recipe.id,'recipe')}
                                                        value ={this.onTypeQuantityValue(recipe.id,'recipe')}
                                                        //style={{minWidth:'5em',maxWidth:'8em'}} 
                                                        style={styles.ButtonGreenNoMarginOrder}
                                                />
                                    </Form.Group>
                                </Form>
{/*                           </OutsideClickHandler>
                           } */}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                            {typeYield(recipe.yield_units) == 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{recipe.yield_units}</span>
                            }
                            {typeYield(recipe.yield_units) != 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_total} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}<FormattedMessage id="order.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                            }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                                    <Button basic circular className="ui small icon button" 
                                             onClick={(event)=>this.removeFromCartList(event,recipe)}>
                                            <i className="trash alternate outline icon"></i>
                                     </Button>
                            </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addOrdersIngredientsRows(ingredients){
        const {currency/*,submitted,editCellIngredient,editCellIndexIngredient,newQuantity*/} = this.state;

        return ingredients.map((elem,index) => {
                   
                let use_price_per_unit = elem.ingredient.price_per_unit
                let supplier_found = false
                let purchase_pack = ""
                let purchase_pack_units = elem.ingredient.pack_units
                let type = typeYield(elem.ingredient.unit)
                let is_volume_ingredient = (type == 'volume')
                let is_weight_ingredient = (type == 'weight')

                if (is_weight_ingredient) purchase_pack = elem.ingredient.purchase_weight
                else if (is_volume_ingredient) purchase_pack = elem.ingredient.purchase_volume
                else purchase_pack = elem.ingredient.purchase_quantity

                if (elem.supplier){
                    if (elem.ingredient.supplier &&  elem.ingredient.supplier.id == elem.supplier.id){
                        use_price_per_unit = elem.ingredient.price_per_unit
                        purchase_pack_units = elem.ingredient.pack_units
                        supplier_found = true
                        if (is_weight_ingredient) purchase_pack = elem.ingredient.purchase_weight
                        else if (is_volume_ingredient) purchase_pack = elem.ingredient.purchase_volume
                        else purchase_pack = elem.ingredient.purchase_quantity
                    }

                    if (!supplier_found && elem.ingredient.extra_suppliers){
                        for (let s of elem.ingredient.extra_suppliers){
                            if (s.supplier && s.supplier.id == elem.supplier.id){
                                use_price_per_unit = s.price_per_unit
                                purchase_pack_units = s.pack_units
                                if (is_weight_ingredient) purchase_pack = s.purchase_weight
                                else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                                else purchase_pack = s.purchase_quantity
                                
                                break
                            }
                        }
                    }
                }else{
                    // Use default supplier
                    if (!elem.ingredient.default_supplier){
                        if (elem.ingredient.extra_suppliers){
                            for (let s of elem.ingredient.extra_suppliers){
                                if (s.default_supplier){
                                    use_price_per_unit = s.price_per_unit
                                    purchase_pack_units = s.pack_units
                                    if (is_weight_ingredient) purchase_pack = s.purchase_weight
                                    else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                                    else purchase_pack = s.purchase_quantity
                                    break
                                }
                            }
                        }
                    } 
                }

                return (
                    <Table.Row key={index} >
                        <Table.Cell  width={5}>
                            <Icon name='shopping basket' color='teal'/>
                            <a href="#" 
                               onClick={(event) => this.seeIngredient(event,elem.ingredient.id)}
                               style={{'color': 'black'}}>
                                {elem.ingredient.name.length >= 45 && 
                                  elem.ingredient.name.substring(0,45) + '..'}
                                    {elem.ingredient.name.length < 45 && 
                                    elem.ingredient.name}   
                            </a>
                        </Table.Cell>
                         <Table.Cell width={3} textAlign='right' /*onClick={(event) => this.editQuantityHandlerInput(event,index,ingredient,'ingredient')}*/>
{/*                               {(!editCellIngredient || (editCellIngredient && index != editCellIndexIngredient) ) &&
                                    <a href='#'><FormattedNumber value={this.getIngredientUnit(ingredient)}/>{' '}{ingredient.unit}</a>}
                                {editCellIngredient && index == editCellIndexIngredient &&
                                <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCellIngredient: false})}}>  */}
                                   <Form>
                                    <Form.Group widths='equal'>
                                      <Icon name='minus circle' color='teal' size='large'
                                              style={{cursor:'pointer', padding:'0.3em 0.7em 0em 0em'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,elem.ingredient.id,'ingredient',false)} /> {' '}
                                        <Icon name='plus circle'  color='teal' size='large'
                                              style={{cursor:'pointer', padding:'0.3em 1em 0em 0em'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,elem.ingredient.id,'ingredient',true)} /> 
                                        <Form.Input type="text"
                                                    id="quantity"
                                                    //value={newQuantity}
                                                    //onChange={this.onNewQuantity}
                                                    value ={this.onInputQuantityValue(elem.ingredient.id,'ingredient')}
                                                    onChange={(event) => this.onInputQuantityChange(event,elem.ingredient.id,'ingredient')}
                                                    //onBlur={(event) => this.updateQuantityOnBlur(event,ingredient,'ingredient')} 
                                                    //onKeyPress={(event) => this.updateQuantityOnEnter(event,ingredient,'ingredient')} 
                                                    autoFocus
                                                    style={{height:'2.5em',minWidth:'5em',maxWidth:'8em',padding:'0em 0.5em 0em 0.5em'}}
                                            />
                                         {/* {' '}{ingredient.unit} */}
                                         <Form.Dropdown key={index} //floating
                                                        //button //basic
                                                        options={this.getRelevantOptions(elem.ingredient.unit,'ingredient',purchase_pack,purchase_pack_units)}
                                                        value ={this.onTypeQuantityValue(elem.ingredient.id,'ingredient')}
                                                        //disabled = {this.getRevelantOptions(ingredient.unit,'ingredient').length == 1}
                                                        onChange={(event,data)=>this.onTypeQuantityChange(event,data,elem.ingredient.id,'ingredient')}
                                                        //style={{minWidth:'5em',maxWidth:'8em'}} 
                                                        style={styles.ButtonGreenNoMarginOrder}
                                                />
                                    </Form.Group>
                                </Form>
{/*                                 </OutsideClickHandler>
                                 } */}
                        </Table.Cell> 
{/*                         {ingredient.purchase_weight !== "" &&
                          <Table.Cell width={3} textAlign='center'>        
                              <FormattedMessage id="order.row.input.quantity.weight.placeholder"
                                                defaultMessage='Enter a weight' >
                                {placeholder => 
                                    <Popup trigger={ <Input key={index} 
                                                        focus
                                                        type='text'
                                                        placeholder={placeholder}
                                                        onFocus={(e)=>this.onFocusIngredientField(e,index,'weight')}
                                                        onChange={(e)=>this.onIngredientsUnitsChange(e,ingredient.id,'weight')}
                                                        error={submitted && !this.handleIngredientsValidation(index)}
                                                        />} 
                                            on='hover'
                                            position = 'right center'
                                            flowing >
                                            <p>{WEIGHT_INSTRUCTION_LINE_TITLE}</p>
                                            <ul>
                                             <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                             <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                             <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                             <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                             <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                            </ul> 
                                            <p>{WEIGHT_INSTRUCTION_LINE_EX}</p>
                                    </Popup>}
                                </FormattedMessage>
                          </Table.Cell>}
                          {ingredient.purchase_volume !== "" &&
                          <Table.Cell width={3} textAlign='center'>        
                            <FormattedMessage id="order.row.input.quantity.volume.placeholder"
                                                defaultMessage='Enter a volume' >
                                {placeholder => 
                                    <Popup trigger={ <Input key={index} 
                                                        focus
                                                        type='text'
                                                        placeholder={placeholder}
                                                        onFocus={(e)=>this.onFocusIngredientField(e,index,'volume')}
                                                        onChange={(e)=>this.onIngredientsUnitsChange(e,ingredient.id,'volume')}
                                                        error={submitted && !this.handleIngredientsValidation(index)}/>} 
                                            on='hover'
                                            position = 'right center'
                                            flowing >
                                            <p>{VOLUME_INSTRUCTION_LINE_TITLE}</p>
                                            <ul>
                                                <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                                <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                                <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                                <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                                <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                            </ul>
                                            <p>{VOLUME_INSTRUCTION_LINE_EX}</p>
                                    </Popup>}
                                </FormattedMessage>
                          </Table.Cell>}
                          {ingredient.purchase_quantity !== "" &&
                          <Table.Cell width={3} textAlign='center'>        
                              <FormattedMessage id="order.row.input.quantity.quantity.placeholder"
                                                defaultMessage='Enter a number of units' >
                                {placeholder => 
                                    <Popup trigger={ <Input key={index} 
                                                        focus
                                                        type='text'
                                                        placeholder={placeholder}
                                                        onFocus={(e)=>this.onFocusIngredientField(e,index,'quantity')}
                                                        onChange={(e)=>this.onIngredientsUnitsChange(e,ingredient.id,'quantity')}
                                                        error={submitted && !this.handleIngredientsValidation(index)}/>} 
                                            on='hover'
                                            position = 'right center'
                                            flowing>
                                            <p>{QUANTITY_INSTRUCTION_LINE_TITLE}</p>
                                            {QUANTITY_INSTRUCTION_LINE_NUMBER}
                                            <p>{QUANTITY_INSTRUCTION_LINE_EX}</p>
                                    </Popup>}
                                </FormattedMessage> 
                          </Table.Cell>}  */}
                        <Table.Cell width={3} textAlign='right'></Table.Cell>
                        {elem.ingredient.purchase_quantity !== "" &&
                        <Table.Cell width={3} textAlign='right'>
                                 <FormattedNumber value={use_price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                    {' / '}<FormattedMessage id="order.quantity.unit.text"
                                                             defaultMessage='unit'/>
                        </Table.Cell>}
                        {(elem.ingredient.purchase_volume !== "" || elem.ingredient.purchase_weight !== "") &&
                        <Table.Cell width={3} textAlign='right'>
                                <FormattedNumber value={use_price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                {' / '}{elem.ingredient.unit}
                        </Table.Cell>}
                        <Table.Cell width={3} textAlign='right'>
                                <Button basic circular className="ui small icon button" 
                                             onClick={(event)=>this.removeIngredientFromCartList(event,elem.ingredient)}
                                             >
                                            <i className="trash alternate outline icon"></i>
                                </Button>
                            </Table.Cell>
                     </Table.Row>
                );
        });
    }
    
    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                                    /* onClick={(event)=>this.addToCartList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                  {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
{/*                         <Table.Cell width={3} textAlign='center'>{recipe.category && recipe.category.name}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToCartList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }


    addIngredientsRows(ingredients){
        const {currency,supplierSelected} = this.state;
        return ingredients.map((ingredient,index) => {

            let default_supplier = ingredient.supplier && ingredient.supplier.name
            // Use default supplier
            if (!ingredient.default_supplier){
                  if (ingredient.extra_suppliers){
                        for (let s of ingredient.extra_suppliers){
                                if (s.default_supplier){
                                    default_supplier = s.supplier.name
                                    break
                                }
                            }
                        }
            }

            return(
                    <Table.Row key={index} textAlign='left' style={{'backgroundColor':'#fcfcfc'}} 
                              /* onClick={(event)=>this.addIngredientToCartList(event,ingredient) }*/>
                        <Table.Cell width={5} textAlign='left'>
                             <Icon name='shopping basket' color='teal' />
                             <a style={{color:'black',fontWeight:'bolder'}}>
                                {ingredient.name.length >= 45 && 
                                    ingredient.name.substring(0,45) + '..'}
                                    {ingredient.name.length < 45 && 
                                    ingredient.name}
                                 {/* {ingredient.name.substr(0, 60)} */}
                             </a>
                             <p style={{'marginLeft':'2em'}}>
                                    <span style={{'marginLeft':'0em', 'color':'#969696'}}>
{/*                                     <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/> {' /'}
                                    {ingredient.purchase_quantity !== "" &&
                                        <FormattedMessage id="order.quantity.unit.text"
                                                          defaultMessage='unit'/>}
                                    {((ingredient.purchase_volume != "" || ingredient.purchase_weight != "")) && 
                                        <FormattedMessage id="order.row.input.ingredients.popup.unit.other.text"
                                                          defaultMessage='{unit}'
                                                          values={{unit:ingredient.unit}}/>} */}
                                   <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/>{' '}{ingredient.unit}{' '} 
                                    <FormattedMessage id="order.row.input.ingredients.popup.stock.text"
                                                          defaultMessage='in stock'/>
                                   <span style={{'marginLeft':'2em','color':'#969696'}}>{/* {'  -  '} */}
                                        {supplierSelected && supplierSelected.name}
                                        {!supplierSelected && <span>{default_supplier}</span>}
                                        </span>
                                 </span>
                             </p>
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                    <Button color='teal' circular  className="ui small icon button" 
                                            onClick={(event)=>this.addIngredientToCartList(event,ingredient)}>
                                            <i className="add icon"></i>
                                    </Button>
                        </Table.Cell>
                    </Table.Row>

            );
        })
    }


    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    toggleItemsMenu(e,type){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        //this.setState({toggled: false});
        //this.setState({filteringIngredients: false});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({categorySelected: ""});
        this.setState({supplierSelected: null});
        if (type == 'recipe'){
            //console.log('recipe')
            this.setState({toggledRecipes: true});
            this.setState({toggledIngredients: false});
        }else{
            //console.log('ingredient')
            this.setState({toggledIngredients: true});
            this.setState({toggledRecipes: false});
        }
    } 

    toggleOrderType= (event,data) => {
        this.setState({orderType:data.value});
        this.resetOrder()
        //history.replace(this.props.location.state, null);
        /* this.setState({toggledIngredients: false});
        this.setState({toggledRecipes: false}); */
    }

    toggleOrderTypeReset(event, data){
        const { emptyOrders } = this.state;
        if (!emptyOrders){
            this.setState({activeModalOrderType:true})
        }
        this.setState({orderType:data.value});
    }

    hideModal() {
        const { orderType } = this.state;
        this.setState({ activeModalOrderType: false });
        if (orderType == 'recipe')
            this.setState({orderType:'ingredient'});
        else
            this.setState({orderType:'recipe'});
    }

    resetOrder(event){
        this.setState({ emptyOrders: true });
        this.setState({ orderList: []});  
        this.setState({ units: []}); 
        this.setState({ orderIngredientsList: []});  
        this.setState({ ingredQuantities: []}); 
        this.setState({ activeModalOrderType: false });     
    }

    render() { 
        const { categoriesRecipe,supplier,alert,dispatch,loading_r,loading_cr,intl,ingredients,loading_i,loading_o } = this.props;
        const { newItems,filtering,/*newItemsIngredients,filteringIngredients*/nameSelected,
            categorySelected,supplierSelected,emptyOrders,orderList,orderIngredientsList,availableRecipesList,availableIngredientsList,
            isMobile,/*toggled,*/sidebarToggled,toggledRecipes,toggledIngredients,orderType } = this.state;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
       // let itemsIngredients = [];
		let className = "floating-menu";
/*         if (filtering){
            items = newItems
        }else{
            items = availableRecipesList
        }

        if (filteringIngredients){
            itemsIngredients = newItemsIngredients
        }else{
            itemsIngredients = availableIngredientsList
        } */
        
         if (toggledRecipes){
            if (filtering){
                items = newItems
            }else{
                items = availableRecipesList
            }

            //Filter and notify restrictions if user unsubscribed
            if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
            }
        }
        if (toggledIngredients){
            if (filtering){
                items = newItems
            }else{
                items = availableIngredientsList
            }
            //Filter and notify restrictions if user unsubscribed
            if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
            }
        }

/*         //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        } */

        return ( 
                <div>
                    <Sidebar as={Segment}
                             animation={'overlay'}
                             direction={'right'}
                             visible={sidebarToggled}
                             vertical
                             width= {isMobile?'wide':'very wide'}
                             icon='labeled'
                             style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                             >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                      <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                      </div>  
                                      <Form>
                                      <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                         <Header as='h2' style={{'marginLeft': '1em'}}>
                                            {toggledRecipes && 
                                            <img src={img_pan} style={{width:'50px'}}/>}
                                            {toggledIngredients && 
                                                <img src={img_bag} style={{width:'45px'}}/>}
                                            <Header.Content>
                                            {toggledRecipes && 
                                                <FormattedMessage id="order.form.input.recipe.title"
                                                                  defaultMessage='Add recipes'/>}
                                            {toggledIngredients && 
                                                <FormattedMessage id="order.form.input.ingredient.title"
                                                                  defaultMessage='Add ingredients'/>}
                                            </Header.Content>
                                        </Header>
                                       </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
{/*                                             <p style={styles.FontLato13}>
                                                    <FormattedMessage id="order.form.input.search.title"
                                                                    defaultMessage='Search' />
                                            </p> */}
                                             <FormattedMessage id="order.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            //onChange={this.filterIngredientsNameList}
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />
                                                } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                        {toggledIngredients && 
                                            <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="order.form.filter.supplier.title"
                                                                        defaultMessage='Supplier' />
                                                    </p>
                                                    <FormattedMessage id="order.form.filter.supplier.placeholder"
                                                                        defaultMessage='Select a supplier'>
                                                        {placeholder =>   
                                                            <Dropdown selection search
                                                                    options= {supplier && supplier.options} 
                                                                    value= {supplierSelected && supplierSelected.id}
                                                                    placeholder={placeholder} 
                                                                    onChange={this.filterIngredientsSupplierList2}
                                                                    clearable
                                                                    fluid
                                                                    disabled = {isLimited}
                                                                    //style={{minWidth:'18em',maxWidth:'26em'}}
                                                                    />}   
                                                    </FormattedMessage>
{/*                                                     <Button basic icon='remove' 
                                                            onClick={this.removeIngredientsFilters}>
                                                    </Button> */}
                                                </span>
                                        </Form.Field>}
                                        {toggledIngredients && !supplierSelected &&  
                                         <Form.Field align='left' style={{'margin':'1em 1em 1em 1em'}}>
                                             <span style={{'color':'#009b9b'}}>
                                                    <FormattedMessage id="order.form.filter.supplier.default.title"
                                                                      defaultMessage='Default supplier is used'/>
                                            </span>
                                         </Form.Field>
                                        }
                                        {toggledRecipes && 
                                            <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="order.form.filter.category.title"
                                                                        defaultMessage='Filter by category' />
                                                    </p>
                                                    <FormattedMessage id="order.form.filter.category.placeholder"
                                                                        defaultMessage='Select a category'>
                                                        {placeholder =>   
                                                            <Dropdown selection search
                                                            options= {categoriesRecipe && categoriesRecipe.options} 
                                                            value= {categorySelected && categorySelected.id}
                                                            placeholder={placeholder} 
                                                            onChange={this.filterCategoryList}
                                                            clearable
                                                            fluid
                                                            disabled = {isLimited}
                                                            //style={{minWidth:'18em',maxWidth:'26em'}}
                                                            />}   
                                                    </FormattedMessage>
                                                </span>
                                        </Form.Field>}
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { ( (toggledRecipes && loading_r) || (toggledIngredients && loading_i) /*|| sequence == 0*/ || loading_cr) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  //selectable 
                                                unstackable 
                                                //compact 
                                                //definition
                                                //striped 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
{/*                                                  {availableIngredientsList.length > 0 && itemsIngredients &&
                                                    this.addIngredientsRows(itemsIngredients)
                                                }
 */}
                                                {toggledIngredients && availableIngredientsList.length > 0 && items &&
                                                    this.addIngredientsRows(items)
                                                }
                                                {toggledRecipes && availableRecipesList.length > 0 && items &&
                                                    this.addRecipesRows(items)
                                                }


                                               {toggledIngredients && availableIngredientsList.length == 0 && 
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="order.list.ingredient.empty.message"
                                                                          defaultMessage='No ingredient created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createIngredient}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }

                                                {toggledRecipes && availableRecipesList.length == 0 &&
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="order.list.recipe.empty.message"
                                                                            defaultMessage='No recipe created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createRecipe}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="order.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    {toggledRecipes && <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="order.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="order.list.premium.message.upgradeplan.line2"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>}
                                                                    {toggledIngredients && <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="order.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableIngredientsList.length}{' '}
                                                                        <FormattedMessage id="order.list.premium.message.upgradeplan.line3"
                                                                                        defaultMessage='ingredients but limited to'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>}
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="order.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverOrders>
                        <Grid stackable  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                              <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="order.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ordersList/'}><FormattedMessage id="order.link.orders"
                                                              defaultMessage='Orders'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="order.link.order"
                                                              defaultMessage='Order'/></Breadcrumb.Section>
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_order} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="order.page.title"
                                                              defaultMessage='Current Order'/>
                                            <Header.Subheader><FormattedMessage id="order.header.caption.title"
                                                                    defaultMessage='Creating' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                           <Grid.Row columns={1}/>
                            {/* <Grid.Row columns={1}>
                                <Grid.Column width={7}>
                                <Message size='large'>
                                    <Message.Header>
                                       <FormattedMessage id="order.list.message.text.header"
                                                         defaultMessage='RECIPES  and/or  INGREDIENTS' />
                                    </Message.Header>
                                    <br/>
                                    <Message.Content>
                                        <span style={{'marginLeft':'1em','font-size': '14px'}}>
                                        {'   '}<img src={img_pan} style={{width:'40px'}}/>{'    '}
                                        <span style={{'marginLeft':'1em'}}><FormattedMessage id="order.list.message.text.line1"
                                                         defaultMessage='Your recipes are automatically splitted into ingredients.' /></span></span>
                                        <br/><br/>
                                       <span style={{'marginLeft':'1em','font-size': '14px'}}>
                                        {'   '}<img src={img_bag} style={{width:'40px'}}/>{'    '}   
                                        <span style={{'marginLeft':'1em'}}><FormattedMessage id="order.list.message.text.line2"
                                                         defaultMessage='Your ingredients can be manually selected.' /></span></span>
                                         <br/> <br/>
                                    </Message.Content>
                                </Message>
                                </Grid.Column>
                            </Grid.Row> */}
                            <Grid.Row columns={1}>
                                <Grid.Column width={4} align='right'>
                                        <Dropdown selection
                                                placeholder={'Type'} 
                                                value={orderType}
                                                //icon='edit' 
                                                options={options}
                                                fluid
                                                onChange={this.toggleOrderTypeReset}
                                                style={{'background-color':'#e8f3fa','font-size': '18px','font-weight': '600','color': 'black'}}/>
{/*                                     <Button.Group >
                                        <Button primary={!toggled} 
                                                onClick={this.togglePeriodicity}>
                                        <FormattedMessage id="order.choice.check.recipes"
                                                            defaultMessage="From your recipe(s)"/></Button>
                                        <FormattedMessage id="order.choice.check.recipes.or"
                                                            defaultMessage="or">
                                            {inside => <Button.Or text={inside}/>}
                                        </FormattedMessage>
                                        <Button primary={toggled}
                                                onClick= {this.togglePeriodicity}> 
                                            <FormattedMessage id="order.choice.check.ingredients"
                                                defaultMessage="only ingredient(s)"/></Button>
                                    </Button.Group> */}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}/> 
                            <Grid.Row columns={1}/>
{/*                             <Grid.Row columns={1}>
                                {orderType=='recipe' && 
                                <Grid.Column>
                                              <span style={{'marginLeft':'2em','font-size': '16px'}}>
                                              {'   '}<img src={img_pan} style={{width:'50px'}}/>{'    '}
                                              <span style={{'marginLeft':'1em'}}><FormattedMessage id="order.list.message.text.line1"
                                                            defaultMessage='Your recipes are automatically splitted into ingredients.' /> </span></span>
                                </Grid.Column>
                                }
                                 {orderType=='ingredient' && 
                                <Grid.Column>
                                       <span style={{'marginLeft':'2em','font-size': '16px'}}>
                                        {'   '}<img src={img_bag} style={{width:'50px'}}/>{'    '}   
                                         <span style={{'marginLeft':'1em'}}><FormattedMessage id="order.list.message.text.line2"
                                                         defaultMessage='Your ingredients can be added from default or selected supplier.' /></span> </span>
                                </Grid.Column>
                                }
                            </Grid.Row> */}
                            <Grid.Row>
                                <Grid.Column>
                                    <br/>
                                    <Label  basic style={styles.FontLato14Bold} size='large'  color='blue'> 
                                                    <FormattedMessage id="order.list.selected.title"
                                                                     defaultMessage='Order list' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row columns={1}/> */}
                            {ingredients && <Grid.Row columns={3}>
                                <Grid.Column  mobile={8} tablet={4} computer={4}/> 
                                <Grid.Column mobile={5} tablet={0} computer={6}/>
                                <Grid.Column mobile={3} tablet={16} computer={4}> 
                                  <div className={className}>
                                  {orderType=='recipe' && <Popup trigger=
                                            { <Button //circular 
                                                      //color='blue'
                                                      style={styles.ButtonBlueAddNoMarginOrder}
                                                      //size='big' 
                                                      //icon='book' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'recipe')}>
                                                          <Icon name='add circle' />{' '}
                                                          <FormattedMessage id="orders.floatingmenu.recipes.icon.title"
                                                                            defaultMessage='Recipes'/>
                                               </Button>}
                                             content={<FormattedMessage id="orders.floatingmenu.recipes.title"
                                                                        defaultMessage='Add recipes'/>}
                                            position='top center'
                                            inverted
                                    />}
                                    {orderType=='ingredient' && <Popup trigger=
                                            { <Button //circular 
                                                      //color='teal'
                                                      style={styles.ButtonGreenAddNoMarginOrder}
                                                      //size='big' 
                                                      //icon='shopping basket' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'ingredient')}>
                                                     <Icon name='add circle' />{' '}
                                                     <FormattedMessage id="orders.floatingmenu.ingredients.icon.title"
                                                                       defaultMessage='Ingredients'/>
                                               </Button>}
                                             content={<FormattedMessage id="orders.floatingmenu.ingredients.title"
                                                                        defaultMessage='Add ingredients'/>}
                                            position='top center'
                                            inverted
                                    />}
                                  </div>
                                </Grid.Column>
                            </Grid.Row>}
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           //definition
                                           //striped 
                                           singleLine 
                                           //color={'grey'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={5}>
                                                   {/*  <FormattedMessage id="order.list.selected.column.element"
                                                                       defaultMessage='Elements'/> */}
                                                      {orderType=='recipe' && 
                                                            <FormattedMessage id="order.list.selected.column.element.recipe"
                                                                       defaultMessage='Recipe(s)' />}
                                                       {orderType=='ingredient' && 
                                                            <FormattedMessage id="order.list.selected.column.element.ingredient"
                                                                       defaultMessage='Ingredient(s)' />}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    {/*  <FormattedMessage id="order.list.selected.column.units"
                                                                          defaultMessage='Unit(s)' /> */}
                                                        <FormattedMessage id="order.list.selected.column.units"
                                                                          defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                        <FormattedMessage id="order.list.selected.column.yield"
                                                                          defaultMessage='Yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                       <FormattedMessage id="order.list.selected.column.unitprice"
                                                                          defaultMessage='Unit price' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {!emptyOrders && orderList &&
                                                this.addOrdersRows(orderList)
                                        }                                        
                                        {!emptyOrders && orderIngredientsList &&
                                            this.addOrdersIngredientsRows(orderIngredientsList)
                                        }
                                        {emptyOrders && 
                                         <Table.Row>
                                            <Table.Cell>
                                                 {/* <FormattedMessage id="order.list.selected.column.empty.message"
                                                                       defaultMessage='No recipe selected yet' /> */}
                                                <FormattedMessage id="order.list.selected.column.empty.message"
                                                                       defaultMessage='No item added yet' />
                                            </Table.Cell>
                                          </Table.Row>
                                        }
                                        </Table.Body>
                                        {!emptyOrders && 
                                          <Table.Footer fullWidth>
                                            <Table.Row>
                                                    <Table.HeaderCell colSpan='5'>
                                                        <Button compact color='teal' 
                                                                floated='right'
                                                                size='small'
                                                                loading={loading_o}
                                                                style={styles.ButtonGreenNoMargin}
                                                                //disabled={!this.handleValidation() 
                                                                 //   || !this.assertNumericFields() || !this.handleIngredientsValidation()}
                                                                onClick={(e)=>this.createDirectOrder(e)}>
                                                                <FormattedMessage id="order.list.selected.button.order"
                                                                                    defaultMessage='Create my order' />
                                                            </Button>
                                                    </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>}
                                    </Table>
                                    <br/> <br/>
                                </Grid.Column>         
                            </Grid.Row>  
   {/*                          <Grid.Row>
                                <Grid.Column>
                                    <br/><br/>
                                    <Label basic style={styles.FontLato13Bold} size='big' color='blue'>
                                                     <FormattedMessage id="order.list.available.title"
                                                                       defaultMessage='Available recipes' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column > 
                                    <p style={styles.FontLato14Grey}>
                                        <FormattedMessage id="order.list.available.filter.name.title"
                                                              defaultMessage='Search' />
                                    </p>
                                    <FormattedMessage id="order.list.available.filter.name.placeholder"
                                                        defaultMessage='Type a name'>
                                        {placeholder =>   
                                            <Input
                                                icon
                                                type="text"
                                                placeholder={placeholder}
                                                onChange={this.filterNameList}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}}><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column>
                                <Grid.Column width='2'/> 
                                <Grid.Column >
                                    <p style={styles.FontLato14Grey}>
                                        <FormattedMessage id="order.list.available.filter.category.title"
                                                         defaultMessage='Filter by category' />
                                    </p>
                                    <FormattedMessage id="order.list.available.filter.category.placeholder"
                                                        defaultMessage='Select a category'>
                                        {placeholder =>   
                                            <Dropdown selection
                                                    options= {categoriesRecipe && categoriesRecipe.options} 
                                                    value= {categorySelected}
                                                    placeholder={placeholder} 
                                                    onChange={this.filterCategoryList}
                                                    clearable
                                                    style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}/>}   
                                     </FormattedMessage>
                                </Grid.Column> 
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  mobile={18} tablet={16} computer={14}> 
                                    {(loading_r || loading_cr) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           //striped 
                                           definition
                                           singleLine 
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={5}>
                                                    <FormattedMessage id="order.list.available.column.recipes"
                                                                     defaultMessage='Recipes' /> 
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <FormattedMessage id="order.list.available.column.category"
                                                                     defaultMessage='Category' /> 
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                    <FormattedMessage id="order.list.available.column.yield"
                                                                     defaultMessage='Yield' /> 
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {filtering && newItems &&
                                                this.addRecipesRows(newItems)
                                        } 
                                        {items && (items.length == 0) &&
                                            <Table.Row>
                                                <Table.Cell> <FormattedMessage id="order.list.table.row.noitems"
                                                                            defaultMessage='No recipes'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                            </Table.Row>}
                                        }
                                        {items &&
                                            this.addRecipesRows(items)
                                        }
                                        </Table.Body>
                                        {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="order.list.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Recipes limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {items && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="order.list.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{availableRecipesList.length}{' '}
                                                                <FormattedMessage id="order.list.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="order.list.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                    </Table>
                                </Grid.Column>
                            </Grid.Row> */}
                        </Grid>
                        <Modal
                            open={this.state.activeModalOrderType}
                            dimmer='default' 
                            size='tiny' 
                            style={styles.Modal} 
                            onClose={this.hideModal}>
                            <Header icon='edit' content={<FormattedMessage id="order.reset.order.modal.name.title"
                                                defaultMessage='Cancel order'/>} />
                                   <Modal.Content>
                                    <p>
                                        <FormattedMessage id="order.reset.order.modal.delete.message"
                                                          defaultMessage='Really sure to cancel current order ?'/>
                                    </p>
                                   </Modal.Content>
                                   <Modal.Actions>
                                        <Button basic color='grey' onClick={(e) =>this.hideModal()}>
                                                <FormattedMessage id="order.reset.order.modal.cancel.button.no"
                                                                  defaultMessage='Back'/>
                                               </Button>
                                        <Button color='teal' //loading={loading}
                                                labelPosition='right' 
                                                content={<FormattedMessage id="order.reset.order.modal.cancel.button.yes"
                                                                          defaultMessage='Cancel'/>}
                                                icon='trash' 
                                                onClick={(event) =>this.resetOrder(event)}
                                                >
                                        </Button>
                                    </Modal.Actions>
                        </Modal> 
                    </SideLeftUncoverOrders>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,ingredients,categoriesRecipe,supplier,alert,dispatch } = state;
    const { loading_r,loading_o} = state.recipes;
    const { loading_i } = state.ingredients;
    const { loading_cr } = state.categoriesRecipe;
    const { subscription } = state.payment;

    return {
        recipes,
        ingredients,
        categoriesRecipe,
        supplier,
        alert,
        dispatch,
        loading_r,
        loading_i,
        loading_cr,
        loading_o,
        subscription
    };
}

const connectedOrders = injectIntl(connect(mapStateToProps)(Orders));
export { connectedOrders as Orders };
 
