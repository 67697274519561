import React from 'react'
import {MainFooter,ResponsiveContainer} from './Segments';
import { Grid,Segment} from 'semantic-ui-react';
import {FormattedMessage,injectIntl,defineMessages} from 'react-intl';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {styles} from './Styles';




const html_tags = defineMessages({
   html_title: {
       id: 'privacy.html.title.tag',
       defaultMessage: "Privacy Policy",
  },
  meta_name_description: {
       id: 'privacy.meta.name.description.tag',
       defaultMessage: "Privacy Policy",
  }
});


class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {post: []}
  } 

  
 render() {
     const {intl} = this.props;

    return (
    <ResponsiveContainer>
       <Helmet>
            <title>{intl.formatMessage(html_tags.html_title)} - Ratatool</title>
            {/* <meta name="description" content={'Calculez votre prix de revient recette, editez vos fiches techniques de cuisine, vos bons de commandes fournisseurs et vos étiquettes nutritionnelles INCO pour votre restaurant'}/> */}
            <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>

            { intl.locale == 'fr' &&
            <link rel="canonical" href={"https://fr.ratatool.com/privacypolicy"}/>}

             { intl.locale == 'en' &&
            <link rel="canonical" href={"https://www.ratatool.com/privacypolicy"} />}
            
            <link rel="alternate" hreflang="fr" href={"https://fr.ratatool.com/privacypolicy"} />
            <link rel="alternate" hreflang="en" href={"https://www.ratatool.com/privacypolicy"} />
            <link rel="alternate" hreflang="x-default" href={"https://www.ratatool.com/privacypolicy"} />
       </Helmet> 
      <Segment vertical style={{border:'none',padding: '5em 0em 0em 0em',/*'font-family': 'Lato'*/}}>
         <Grid  stackable container centered /*verticalAlign='middle'*/ columns={1}>

            <Grid.Row>
                <Grid.Column width={16}>
                { intl.locale == 'fr' &&
                <div>
                <h1>
                    Politique de protection des données
                </h1>
                <p>
                    La confidentialité de vos données est une de nos priorités. Il fait partie
                    inhérente de la conception de notre service. Le choix fait de ne pas
                    afficher de publicité ou encore de ne jamais fournir vos informations à des
                    tiers en sont quelques exemples.
                </p>
                <p>
                    Vous trouverez ci-dessous le détail de notre engagement en matière de
                    confidentialité de vos données personnelles.
                </p>
                <h2>
                    Collecte des données personnelles
                </h2>
                <p>
                    Les données privées que nous collectons sont les suivantes :
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        <strong>Données d’authentification et d’identification </strong>
                        lors de l’utilisation de notre site Internet ou de nos applications :
                        nom d’utilisateur, email, mot de passe.
                    </li>
                    <li>
                        <strong>Données </strong>
                        <strong>de facturation :</strong>
                        nom, prénom, informations société de facturation (adresses, TVA,
                        SIRET), numéro de téléphone mobile.
                    </li>
                    <li>
                        <strong>Données de communication : </strong>
                             si vous nous contactez pour des informations ou du support nous
                            pouvons être amené à échanger des données informatives (nom, email,
                            numéro de téléphone par exemple)
                    </li>
                </ul>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        <strong>Données de paiement</strong>
                        : Lorsque vous souscrivez à <strong>une offre payante</strong>, nous
                        n'avons aucun accès à vos données personnelles ou bancaires. Celles-ci
                        sont gérées par le prestataire de gestion de paiement par carte
                        bancaire (service Stripe). Nous avons cependant accès à données
                        informatives de facturations via ce partenaire.
                    </li>
                </ul>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        <strong>Données de transaction de paiement : </strong>
                        date, heure de transaction, montant.
                    </li>
                </ul>
                <p>
                    Nous collectons également vos données de manière automatique, lorsque vous
                    vous connectez sur notre site internet. Les données collectées sont les
                    suivantes :
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        <strong>Informations techniques </strong>
                        de connexion, en particulier votre adresse IP, le type et la version de
                        votre navigateur, le type de terminal que vous utilisez pour vous
                        connecter, votre système d’exploitation.
                    </li>
                    <li>
                        <strong>Informations sur vos visites</strong>
                        <strong> : </strong>
                        le nombre de connexions, les heures de connexion, les pages visitées,
                        les durées de connexion, les recherches effectuées, les liens sur
                        lesquels vous avez cliqué.
                    </li>
                    <li>
                        <strong>Données provenant de sous-traitant</strong>
                        <strong> : </strong>
                        afin d’améliorer notre qualité de service, nous collectons également
                        des Données provenant de nos sous-traitants, ou de bases de données
                        professionnelles.
                    </li>
                </ul>
                <p>
                Cette collecte automatique se fait par l'intermédiaire de  <span style={{'font-weight': '700'}}>Cookies</span> et autres techniques de tracking (balises ou tags)
                    que nous utilisons ou qui sont utilisés par nos partenaires tiers.
                </p>
                <h2>
                    Utilisation des données personnelles
                </h2>
                <p>
                    Nous ne traitons vos Données que pour des finalités déterminées, explicites
                    et légitimes.
                </p>
                <p>
                    Nous collectons et utilisons vos données personnelles pour les finalités
                    suivantes :
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        <strong>Pour la nécessité du service, </strong>
                        ils nous permettent de gérer les besoins clients et fournir un service
                        de qualité à nos clients.
                    </li>
                    <li>
                        <strong>A des fins d'amélioration du service, </strong>
                        ils nous permettent d'améliorer, de personnaliser, et d'étendre les
                        fonctionnalités des services.
                    </li>
                    <li>
                        <strong>A des fins d'analyse de l'utilisation du service</strong>
                        .
                    </li>
                    <li>
                        <strong>Pour développer des nouveaux services </strong>
                        et fonctionnalités clients.
                    </li>
                    <li>
                        <strong>Pour la gestion du suivi des paiements </strong>
                        et des instruments de paiement mis à votre disposition.
                    </li>
                    <li>
                        <strong>Pour assurer le support technique </strong>
                        de nos utilisateurs.
                    </li>
                    <li>
                        <strong>Pour prévenir, rechercher et détecter des fraudes </strong>
                        en matière de paiement afin que vos paiements soient sécurisés.
                    </li>
                    <li>
                        <strong>Pour communiquer </strong>
                        et envoyer des messages à nos clients.
                    </li>
                    <li>
                        <strong>Pour traiter vos réclamations.</strong>
                    </li>
                </ul>
                <h2>
                    Divulgation à des tiers
                </h2>
                <p>
                    Pour être clair, sans votre consentement, 
                    <span style={{'font-weight': '700'}}>
                         nous ne divulguons, ni ne vendons, ni ne partageons aucune de vos
                        données quelle qu'elle soit avec les tiers avec lesquels nous
                        travaillons (Prestataires, Fournisseurs, Clients, etc.)</span>
                </p>
                <p>
                    Les données personnelles pourraient être amenées à être partagées mais
                    uniquement à votre demande lorsque vous faites appel à un tiers ou à un
                    service partenaire proposé. Dans ce cas une action de votre part sera
                    nécessaire et l'acceptation du partage d'information sera explicitement
                    indiqué.
                    <br/>
                    <br/>
                    Cependant TEDIBYTE pourra être amenée – du fait de la loi, dans le cadre
                    d’une procédure en justice, d’un litige et/ou d’une requête des autorités
                    publiques de votre pays de résidence ou autre – à divulguer vos données
                    personnelles aux autorités compétentes. Nous pouvons également divulguer
                    vos données si nous pensons qu’à des fins de sécurité nationale,
                    d’application de la loi ou autre sujet d’intérêt public, la divulgation est
                    nécessaire ou appropriée. Nous pouvons également divulguer des informations
                    vous concernant si nous pensons que cette divulgation est raisonnablement
                    nécessaire pour faire valoir le respect de nos conditions générales ou
                    protéger nos activités ou nos utilisateurs. En outre, en cas de
                    restructuration, de fusion ou de vente, nous pourrons transférer toutes
                    informations personnelles que nous collectons au tiers concerné.
                </p>
                <h2>
                    Protection des données
                </h2>
                <p>
                    Nous mettons en œuvre tous les moyens afin de protéger vos données.
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        Nous ne connaissons pas votre mot de passe, il est crypté sur nos
                        serveurs.
                    </li>
                    <li>
                        Les pages de paiement sont protégées en HTTPS par un cryptage TLS
                        128bits. Votre navigateur vous l'indique par un cadenas vert
                        identifiable.
                    </li>
                    <li>
                        Nos serveurs utilisent les technologies les plus avancées en termes de
                        protection d'accès.
                    </li>
                    <li>
                        Les données de paiement (cartes bancaires, etc.) ne sont pas connues de
                        nos serveurs, elles sont stockées chez notre prestataire Stripe qui
                        possède toutes les garanties et certifications requises pour ce type
                        d'activité.
                    </li>
                </ul>
                <p>
                    Toutefois, compte tenu de la complexité de l’environnement Internet et de
                    la téléphonie mobile, TEDIBYTE ne saurait toutefois être tenue à une
                    obligation de résultat à ce titre.
                </p>
                <h2>
                    Cookies et autres technologies
                </h2>
                <p>
                    Le site Ratatool utilise les cookies et d'autres technologies telles que
                    les balises/tags web. Ces technologies sont utilisées afin de mieux
                    comprendre les utilisateurs et d'améliorer continuellement le service.
                    Elles sont également utilisées à des fins statistiques, de suivi de la
                    performance et de la pertinence de nos services.
                    <br/>
                    <br/>
                    Un cookie est également utilisé lorsque vous vous identifiez sur le site
                    afin de garder votre session ouverte. Vous pouvez à tout moment supprimer
                    ce cookie via les options de votre navigateur. Ce cookie vous permet de ne
                    pas avoir à vous ré-authentifier trop fréquemment.
                </p>
                <h2>
                    Droits des utilisateurs
                </h2>
                <p>
                    Conformément à la réglementation applicable, vous disposez de droits
                    lorsque vos Données font l’objet d’un traitement :
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit d’accès : </span>
                    vous avez le droit d’obtenir de nous la confirmation que des Données vous
                    concernant sont ou ne sont pas traitées ainsi que de recevoir une copie de
                    l’ensemble des Données que nous détenons sur vous.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit à la portabilité</span>
                    : vous pouvez recevoir de notre part les Données vous concernant dans un
                    format structuré, couramment utilisé et lisible par machine, aux fins
                    notamment de transmission à un tiers. Lorsque cela est techniquement
                    possible, vous avez également le droit d’obtenir que les Données vous
                    concernant soient transmises directement à ce tiers.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit de rectification : </span>
                    vous avez le droit de demander la correction des Données que nous détenons
                    sur vous si ces dernières sont incomplètes ou erronées. Dans ce cas, nous
                    pourrons vous solliciter afin de vérifier les nouvelles Données fournies.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit à l’oubli : </span>
                    vous pouvez nous demander l’effacement de vos Données lorsque nous n’avons
                    plus d’intérêt légitime à les détenir. Notez toutefois que ce droit n’est
                    pas applicable lorsque nous avons une obligation légale de conserver
                    certaines de vos Données, par exemple à des fins de lutte contre le
                    blanchiment de capitaux et le financement du terrorisme.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit à la limitation </span>
                    : vous pouvez dans certains cas obtenir de notre part la limitation du
                    traitement de vos Données.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit d’opposition :</span>
                    vous pouvez vous opposer à tout moment au traitement de vos Données pour
                    des raisons tenant à votre situation particulière ou lorsque vos Données
                    sont traitées à des fins de prospection.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Droit au retrait de votre consentement </span>
                    : vous avez le droit de retirer à tout moment votre consentement au
                    traitement de vos Données, ce qui ne rendra pas illicite le traitement
                    antérieur fondé sur ce consentement.
                </p>

                <p>
                    <span style={{'font-weight': '700'}}>
                        L’exercice de vos droits : 
                    </span>
                     vous pouvez exercer vos droits en adressant une demande notre Support
                Client chargé de la protection des données (    <a href="mailto:support@ratatool.com">support@ratatool.com</a>).
                </p>
                <h2>
                    Questions sur la confidentialité
                </h2>
                <p>
                    Si vous avez des questions ou des inquiétudes concernant le traitement de
                    vos données ou la politique de confidentialité décrit sur cette page,
                veuillez nous contacter par mail    <a href="mailto:support@ratatool.com">support@ratatool.com</a> ou via le
                formulaire de contact du site    <a href="mailto:https://fr.ratatool.com/contact">ici</a>.
                </p>
                </div>}
                { intl.locale == 'en' &&
                <div>
                    <h1>
                        Privacy Policy
                    </h1>
                    <p>
                        The confidentiality of your data is one of our priorities. It is an
                        inherent part of the design of our service. The choice not to display
                        advertising or to never provide your information to third parties are some
                        examples.
                    </p>
                    <p>
                        You will find below details of our commitment to the confidentiality of
                        your personal data.
                    </p>
                    <h2>
                        Personal Data We Collect
                    </h2>
                    <p>
                        The private data we collect are as follows:
                    </p>
                    <ul type="disc" style={{'color':'grey'}}>
                        <li>
                            <span style={{'font-weight': '700'}}>Authentication and identification data </span>
                                when using our website or our applications: username, email,
                                password.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>Billing data </span>
                                : last name, first name, billing company information (addresses,
                                VAT), mobile phone number.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>Communication data </span>
                                : if you contact us for information or support, we may need to
                                exchange informative data (name, email, telephone number for
                                example)
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>Payment data </span>
                                : When you subscribe to a paid offer, we have no access to your
                                personal or banking data.
                                These are managed by the credit card payment service provider
                                (Stripe service). However, we have access to billing information
                                data via this partner.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>Payment transaction data </span>
                            : date, time of transaction, amount.
                        </li>
                    </ul>
                    <p>
                        
                            We also collect your data automatically, when you connect to our
                            website. The data collected are as follows:
                        
                    </p>
                    <ul type="disc" style={{'color':'grey'}}>
                        <li>
                        <span style={{'font-weight': '700'}}>Technical connection information </span>
                                , your IP address, the type and version of your browser, the type
                                of terminal you use to connect, your operating system.
                        </li>
                        <li>
                        <span style={{'font-weight': '700'}}>Information about your visits </span>
                                : the number of connections, the hours of connection, the pages
                                visited, the durations of connection, the searches carried out, the
                                links on which you clicked.
                        </li>
                        <li>
                        <span style={{'font-weight': '700'}}>Data from subcontractors </span>
                                : to improve our quality of service, we also collect data from our
                                subcontractors, or professional databases.
                        </li>
                    </ul>
                    <p>
                        This collection is done automatically through <span style={{'font-weight': '700'}}>Cookies</span> and other tracking
                        techniques (tags) that we use or our third-parties partners use.
                    </p>
                    <h2>
                        Use made of personal data
                    </h2>
                    <p>
                        We only process your Data for specific, explicit and legitimate purposes.
                    </p>
                    <p>
                        We collect and use your personal data for the following purposes:
                    </p>
                    <ul type="disc" style={{'color':'grey'}}>
                        <li>
                            <span style={{'font-weight': '700'}}>For the need of the service, </span>
                            they allow us to manage customer needs and provide a quality service to
                            our customers.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>For service improvement purposes, </span>
                            they enable us to enhance, customize, and extend the functionality of
                            services.
                        </li>
                        <li>
                             <span style={{'font-weight': '700'}}>For the purpose of analyzing the use of the service.</span>
                        </li>
                        <li>
                             <span style={{'font-weight': '700'}}>To develop new services </span>
                            and customer features.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>For the management of payments </span>
                            and payment instruments made available to you.
                        </li>
                        <li>
                             <span style={{'font-weight': '700'}}>To provide technical support </span>
                            for our users.
                        </li>
                        <li>
                            <span style={{'font-weight': '700'}}>To prevent, track and detect payment fraud </span>
                            so your payments are secure.
                        </li>
                        <li>
                             <span style={{'font-weight': '700'}}>To communicate </span>
                            and send messages to our customers.
                        </li>
                        <li>
                             <span style={{'font-weight': '700'}}>To process your claims.</span>
                        </li>
                    </ul>
                    <h2>
                        Disclosure to third parties
                    </h2>
                    <p>
                        To be clear, without your consent,
                        <span style={{'font-weight': '700'}}>
                            we do not disclose, sell or share any of your data whatsoever with the
                            third parties with whom we work (Providers, Suppliers, Customers, etc.)
                        </span>
                    </p>
                    <p>
                        Personal data may be shared but only at your request when you use a third
                        party or a proposed partner service. In this case an action on your part
                        will be necessary and the acceptance of the sharing of information will be
                        explicitly indicated.
                    </p>
                    <p>
                        However, TEDIBYTE may be brought - by law, in the context of legal
                        proceedings, litigation and/or a request from the public authorities of
                        your country of residence or otherwise - to disclose your personal data to
                        competent authorities. We may also disclose your data if we believe that
                        for purposes of national security, law enforcement or other public
                        interest, disclosure is necessary or appropriate. We may also disclose
                        information about you if we believe that such disclosure is reasonably
                        necessary to enforce our terms and conditions or to protect our business or
                        users. In addition, in case of restructuring, merger or sale, we may
                        transfer any personal information we collect to the relevant third party.
                    </p>
                    <h2>
                        Data Protection
                    </h2>
                    <p>
                        We use every means to protect your data.
                    </p>
                    <ul type="disc" style={{'color':'grey'}}>
                        <li>
                            We do not know your password, it is encrypted on our servers.
                        </li>
                        <li>
                            Payment pages are protected in HTTPS by 128-bit TLS encryption. Your
                            browser indicates this with an identifiable green lock.
                        </li>
                        <li>
                            Our servers use the most advanced technologies in terms of access
                            protection.
                        </li>
                        <li>
                            Payment data (bank cards, etc.) are not known to our servers, they are
                            stored at our Stripe provider who has all the guarantees and
                            certifications required for this type of activity.
                        </li>
                    </ul>
                    <p>
                        However, given the complexity of the Internet environment and mobile
                        telephony, TEDIBYTE cannot however be held to an obligation of result in
                        this respect.
                    </p>
                    <h2>
                        Cookies and other tag technologies
                    </h2>
                    <p>
                        The Ratatool website uses cookies and other technologies such as web tags.
                        These technologies are used to better understand users and continually
                        improve the service. They are also used for statistical purposes,
                        monitoring the performance and relevance of our services.
                    </p>
                    <p>
                        A cookie is also used when you identify yourself on the site in order to
                        keep your session open. You can delete this cookie at any time via your
                        browser options. This cookie allows you to avoid having to re-authenticate
                        yourself too frequently.
                    </p>
                    <h2>
                        User rights
                    </h2>
                    <p>
                        In accordance with applicable regulations, you have rights when your Data
                        is processed:
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right of access: </span>
                        You have the right to obtain confirmation from us that data about you is or
                        is not processed as well as to receive a copy of all Data that we hold
                        about you.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right to portability: </span>
                        You may receive from us the Data about you in a structured, commonly used
                        and readable format, for the purpose in particular for transmission to a
                        third party. Where technically possible, you also have the right to have
                        the Data about you forwarded directly to that third party.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right of rectification: </span>
                        You have the right to request the correction of the Data that we hold on
                        you if the latter are incomplete or erroneous. In this case, we may ask you
                        to check the new data provided.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right to be forgotten: </span>
                        You can ask us to erase your Data when we no longer have a legitimate
                        interest in detaining them. Note, however, that this right is not
                        applicable when we have a legal obligation to retain some of your Data, for
                        example to combat money laundering and terrorist financing.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right to limitation: </span>
                        You may in certain cases obtain from us the limitation of the processing of
                        your Data.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right of opposition: </span>
                        You may object at any time to the processing of your Data for reasons
                        related to your particular situation or when your Data is processed for
                        prospecting purposes.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Right to Withdraw Your Consent: </span>
                        You have the right to withdraw your consent to the processing of your Data
                        at any time, which will not render prior treatment based on this consent
                        unlawful.
                    </p>
                    <p>
                        <span style={{'font-weight': '700'}}>Exercising your rights : </span>
                        You can exercise your rights by sending a request to our Customer Support
                        for data protection (support@ratatool.com).
                    </p>
                    <h2>
                        Questions about privacy
                    </h2>
                    <p>
                        If you have any questions or concerns regarding the processing of your data
                    or the privacy policy described on this page, please contact us by email    <a href="mailto:support@ratatool.com">support@ratatool.com</a> or use the
                    contact form of the website    <a href="https://www.ratatool.com/contact">here</a>.
                    </p>

                </div>}   
                </Grid.Column> 
            </Grid.Row> 
          </Grid>
        </Segment>
        <br/> <br/> <br/> <br/> <br/> 
        <MainFooter/>
    </ResponsiveContainer>
  )
 }
}




const connectedPrivacyPolicy = injectIntl(PrivacyPolicy);
export { connectedPrivacyPolicy as PrivacyPolicy };