import { nutritionConstants } from '../_constants';

export function nutrition(state = {}, action) {
  switch (action.type) {
    case nutritionConstants.SEARCH_REQUEST:
        return { 
         searching: true
        };
    case nutritionConstants.SEARCH_SUCCESS:
        return {
            searching: false,
            //items: action.payload.list.item
            items: action.payload
        };
    case nutritionConstants.SEARCH_FAILURE:
        return { 
          error: action.error
        };
        
    case nutritionConstants.GET_REQUEST:
      return { 
        retrieving: true 
      };
    case nutritionConstants.GET_SUCCESS:
      return  { 
        retrieving: false,
        nutrients: action.payload,
        //nutrients: action.payload.foods[0].food.nutrients,
        //description: action.payload.foods[0].food.desc
      };
    case nutritionConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}