import { storageAreaConstants } from '../_constants';
import { storageAreaService } from '../_api';
import { alertActions } from '.';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const storageAreaActions = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count
};

function getAll() {
    return dispatch => {
        dispatch(request());

        storageAreaService.getAll()
            .then(
                storage => dispatch(success(storage)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: storageAreaConstants.GETALL_REQUEST } }
    function success(storage) { return { type: storageAreaConstants.GETALL_SUCCESS, storage } }
    function failure(error) { return { type: storageAreaConstants.GETALL_FAILURE, error } }
}

function get(id) {
    return dispatch => {
        dispatch(request(id));

        storageAreaService.get(id)
            .then(
                storage => {
                    dispatch(success(storage));
                    //history.push('/ingredientdetails');
                },
                error => dispatch(failure(error))
            );
    };

    function request(id) { return { type: storageAreaConstants.GET_REQUEST ,id} }
    function success(storage) { return { type: storageAreaConstants.GET_SUCCESS, storage } }
    function failure(error) { return { type: storageAreaConstants.GET_FAILURE, error } }
}

function add(storage,link) {
    return dispatch => {
        dispatch(request());

        storageAreaService.add(storage)
            .then(
                storage =>{
                    dispatch(success(storage));
                    let title_text = <FormattedMessage id="storage.actions.storage.add.success.title"
                                                       defaultMessage="Storage Action"/>
                    let content_text = <FormattedMessage id="storage.actions.storage.add.success.content"
                                                         defaultMessage="Storage Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "storage.actions.storage.add.success.title",
                                                   content:"storage.actions.storage.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/storageareas');
                } 
            );
    };

    function request() { return { type: storageAreaConstants.ADD_REQUEST } }
    function success(storage) { return { type: storageAreaConstants.ADD_SUCCESS, storage } }
    function failure(error) { return { type: storageAreaConstants.ADD_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        storageAreaService.delete(id)
            .then(
                storage => { 
                    dispatch(success(id,storage));
                    let title_text = <FormattedMessage id="storage.actions.storage.delete.success.title"
                                                       defaultMessage="Storage Action"/>
                    let content_text = <FormattedMessage id="storage.actions.storage.delete.success.content"
                                                         defaultMessage="Storage Deleted Successfully"/>
                                
                    dispatch(alertActions.success({title: "storage.actions.storage.delete.success.title",
                                                   content:"storage.actions.storage.delete.success.content"}));
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                   history.push('/');
                   history.push('/storageareas');
                }
            );
    };

    function request() { return { type: storageAreaConstants.DELETE_REQUEST } }
    function success(id,storage) { return { type: storageAreaConstants.DELETE_SUCCESS,id:id, storage } }
    function failure(error) { return { type: storageAreaConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        storageAreaService.count()
            .then(
                storage => dispatch(success(storage)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: storageAreaConstants.COUNT_REQUEST } }
    function success(storage) { return { type: storageAreaConstants.COUNT_SUCCESS, storage } }
    function failure(error) { return { type: storageAreaConstants.COUNT_FAILURE, error } }
}

function update(id,storage) {
    return dispatch => {
        dispatch(request());

        storageAreaService.update(id,storage)
            .then(
                storage =>{
                    dispatch(success(id,storage));
                    let title_text = <FormattedMessage id="storage.actions.storage.update.success.title"
                                                       defaultMessage="Storage Action"/>
                    let content_text = <FormattedMessage id="storage.actions.storage.update.success.content"
                                                         defaultMessage="Storage Updated Successfully"/>
                                
                    dispatch(alertActions.success({title: "storage.actions.storage.update.success.title",
                                                   content:"storage.actions.storage.update.success.content"}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    history.push('/storageareas');
                }
            );
    };

    function request() { return { type: storageAreaConstants.UPDATE_REQUEST } }
    function success(id,storage) { return { type: storageAreaConstants.UPDATE_SUCCESS, id:id, storage } }
    function failure(error) { return { type: storageAreaConstants.UPDATE_FAILURE, error } }
}
