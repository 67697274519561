

export const serverConstants = {
	BACKEND_URL:'https://www.ratatool.com/api',
	//BACKEND_URL:'http://127.0.0.1:8000/api',
	//BACKEND_URL_TEST:'http://127.0.0.1:8000'
	FRONTEND_URL_EN: "https://www.ratatool.com",
	//FRONTEND_URL_EN: "http://www.lvh.me:3000",
	FRONTEND_URL_FR: "https://fr.ratatool.com",
	//FRONTEND_URL_FR : "http://fr.lvh.me:3000"        
};
