import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {FormattedMessage,injectIntl} from 'react-intl';
import {Helmet} from "react-helmet";
import img_logo from '../Assets/ratatool-logo.png'
import img_window from '../Assets/windows.jpg'
import {toaster_dispatch} from '../_helpers';
import ButterToast  from 'butter-toast';

class PwdReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
    
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { email } = this.state;
        
        if (email) {
            dispatch(userActions.passwordReset(email));
        }
    }

    render() {
        const { alert, dispatch, intl } = this.props;
        const { email ,submitted } = this.state;
        return (
              <Grid
                textAlign='center'
                style={{ height: '100%' }}
                verticalAlign='middle'>
                <Helmet>
                    { intl.locale == 'fr' &&
                      <link rel="canonical" href="https://fr.ratatool.com/pwdreset" />}

                    { intl.locale == 'en' &&
                        <link rel="canonical" href="https://www.ratatool.com/pwdreset" />}
                    
                    <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com/pwdreset" />
                    <link rel="alternate" hreflang="en" href="https://www.ratatool.com/pwdreset" />
                    <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com/pwdreset" />
                    
                </Helmet>
                <Grid.Row columns={1} only='tablet mobile' style={{'padding-bottom':0,'padding-left':10,'padding-right':10}} verticalAlign='middle'>
                  <Grid.Column  style={{ top: "15%", maxWidth: 450 }} >
                    <img src={img_logo} alt="logo" style={{width: '168px', height: '49px' }} />
                    <Header as='h3' color='grey' textAlign='center'>
{/*                       {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      } */}
                      {' '}<FormattedMessage id="pwdreset.form.title"
                            defaultMessage='Enter your email to reset your password'/>
                    </Header>
                    <Form size='large' onSubmit={this.handleSubmit}>
                      {/* <Segment stacked> */}
                        <FormattedMessage id="pwdreset.form.email.placeholder"
                                          defaultMessage="Email">
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='email'
                                    name='email'
                                    value={email}
                                    onChange={this.handleChange}
                                    error={submitted && !email && 
                                      { content: <FormattedMessage id="pwdreset.form.email.error.message"
                                                                   defaultMessage='Email is required'/> }}
                                    />}
                        </FormattedMessage>
{/*                         {submitted && !email &&
                            <label style={{color:'red'}}><i>
                                <FormattedMessage id="pwdreset.form.email.error.message"
                                                  defaultMessage='Email is required'/> 
                              </i></label>
                        } */}
                        <Button color='grey' fluid size='large'>
                                <FormattedMessage id="pwdreset.form.button.send.text"
                                                  defaultMessage='Send Recovery Link'/> 
                        </Button>
                      {/* </Segment> */}
                    </Form>
                    <Message>
                      <Link to="/login">&#8592;  
                                <FormattedMessage id="pwdreset.form.button.back.text"
                                                  defaultMessage='Back to Log in'/> 
                      </Link>
                    </Message>
                    <br/><br/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} only='computer'>
                   <Grid.Column floated='left' style={{width: '30%', maxWidth: 700,  height: '100vh'}}> 
                     <img src={img_window} alt="window" style={{width: '100vh',height: '100vh'}}/>
                   </Grid.Column>
                   <Grid.Column floated='left'  verticalAlign='middle' style={{width: '70%', maxWidth: 450 }}>
                    <img src={img_logo} alt="logo" style={{width: '168px', height: '49px' }} verticalAlign='middle'/>
                    <Header as='h3' color='grey' textAlign='center'>
{/*                       {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                      } */}
                      {' '}<FormattedMessage id="pwdreset.form.title"
                            defaultMessage='Enter your email to reset your password'/>
                    </Header>
                    <Form size='large' onSubmit={this.handleSubmit}>
                      {/* <Segment stacked> */}
                        <FormattedMessage id="pwdreset.form.email.placeholder"
                                            defaultMessage='Email'>
                            {placeholder => 
                                  <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder={placeholder}
                                    type='email'
                                    name='email'
                                    value={email}
                                    onChange={this.handleChange}
                                    error={submitted && !email && 
                                      { content: <FormattedMessage id="pwdreset.form.email.error.message"
                                                                   defaultMessage='Email is required'/> }}
                                    />}
                        </FormattedMessage>
{/*                         {submitted && !email &&
                            <label style={{color:'red'}}><i>
                                <FormattedMessage id="pwdreset.form.email.error.message"
                                                  defaultMessage='Email is required'/> 
                              </i></label>
                        } */}
                        <Button color='grey' fluid size='large'>
                                <FormattedMessage id="pwdreset.form.button.send.text"
                                                  defaultMessage='Send Recovery Link'/> 
                        </Button>
                     {/*  </Segment> */}
                    </Form>
                    <Message>
                      <Link to="/login">&#8592;  
                                <FormattedMessage id="pwdreset.form.button.back.text"
                                                  defaultMessage='Back to Log in'/> 
                      </Link>
                    </Message>
                  </Grid.Column>
                  </Grid.Row>
                  <ButterToast position={{ horizontal: 'POS_RIGHT', vertical: 'POS_TOP' }}/>
                  {alert.message && toaster_dispatch(dispatch,alert,intl)}
              </Grid>
          );
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    return {
        alert,
        dispatch
    };
}

const connectedPwdReset = injectIntl(connect(mapStateToProps)(PwdReset));
export { connectedPwdReset as PwdReset };