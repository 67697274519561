import { authHeader,nonAuthHeader} from '../_helpers';
import { serverConstants } from '../_constants';


export const blogService = {
    getPosts,
    getPost,
    getHomepagePosts
};


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
        return response.json().then(json => {
            const error = new Error(json.message)
            return Promise.reject(Object.assign(error, { response }))
        })
    }

    return response.json();
}


function getPosts(locale,short) {
    const requestOptions = {
        method: 'GET',
        headers: nonAuthHeader()
    };

    

    if (short == true){
        //Speed flat request (not nested)
        return fetch(serverConstants.BACKEND_URL+'/posts/get_list/?country='+locale, requestOptions).then(handleResponse);
    }else{
        return fetch(serverConstants.BACKEND_URL+'/posts/?country='+locale, requestOptions).then(handleResponse);
    }
}


function getPost(id,locale) {
    const requestOptions = {
        method: 'GET',
        headers: nonAuthHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/posts/'+id+'/?country='+locale, requestOptions).then(handleResponse);
}

function getHomepagePosts(locale) {
    const requestOptions = {
        method: 'GET',
        headers: nonAuthHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/posts/home/?country='+locale, requestOptions).then(handleResponse);
}