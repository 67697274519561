import React from 'react';
import {isNumeric} from '../_helpers';
import {FormattedMessage} from 'react-intl';

export const unitWeight=[ 'kg','kilo', 'kilogram', 'kilograms', 'g', 'gram', 'grams' ,'mg','mgram','mgrams','oz','ounce','ounces','once','onces','lbs', 'lb', 'pound', 'pounds','livre','livres'];
export const unitVolume=[ 'l', 'L','liter', 'liters','litre','litres', 'ml', 'milliliter','milliliters','cl', 'centiliter','centiliters','floz', 'fluid-ounce', 'fluid-ounces','gal', 'gallon', 'gallons',
                          'tbsp', 'tablespoon', 'tablespoons','cas','cuillere-soupe','tsp', 'teaspoon', 'teaspoons','cac','cuillere-cafe'];

export const kgrams=[ 'kg','kilo', 'kilogram', 'kilograms'];
export const grams=[ 'g', 'gram', 'grams'];
export const mgrams=[ 'mg','mgram','mgrams'];
export const pounds = ['lbs', 'lb', 'pound', 'pounds','livre','livres']
export const ounces = ['oz', 'ounce', 'ounces','once','onces']

export const liters=[ 'l', 'L','liter', 'liters','litre'];
export const mliters=[ 'ml', 'milliliter','milliliters'];
export const cliters=[ 'cl', 'centiliter','centiliters'];
export const flounces = ['floz', 'fluid-ounce', 'fluid-ounces'];
export const gallons = ['gal', 'gallon', 'gallons']
export const tablespoons = ['tbsp', 'tablespoon', 'tablespoons','cas','cuillere-soupe']
export const teaspoons = ['tsp', 'teaspoon', 'teaspoons','cac','cuillere-cafe']

export const pack_unit_key_reserved = 'pack_supply_wpr'
export const box_pack_unit_key_reserved = 'box_pack_supply_wpr'

export const VATValues = [0,1,2,2.1,2.5,3,4,5,5.5,6,6.5,7,7.7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]


export const VAToptions= [{key:0.00,text: <FormattedMessage id="units.notax.message"
                                                           defaultMessage="None"/>,value:0.00},
                   {key:0.01,text:"1%",value:0.01},
                   {key:0.02,text:"2%",value:0.02},
                   {key:0.021,text:"2.1%",value:0.021},
                   {key:0.025,text:"2.5%",value:0.025},
                   {key:0.03,text:"3%",value:0.03},
                   {key:0.04,text:"4%",value:0.04},
                   {key:0.05,text:"5%",value:0.05},
                   {key:0.055,text:"5.5%",value:0.055},
                   {key:0.06,text:"6%",value:0.06},
                   {key:0.065,text:"6.5%",value:0.065},
                   {key:0.07,text:"7%",value:0.07},
                   {key:0.077,text:"7.7%",value:0.077},
                   {key:0.08,text:"8%",value:0.08},
                   {key:0.09,text:"9%",value:0.09},
                   {key:0.10,text:"10%",value:0.10},
                   {key:0.11,text:"11%",value:0.11},
                   {key:0.12,text:"12%",value:0.12},
                   {key:0.13,text:"13%",value:0.13},
                   {key:0.14,text:"14%",value:0.14},
                   {key:0.15,text:"15%",value:0.15},
                   {key:0.16,text:"16%",value:0.16},
                   {key:0.17,text:"17%",value:0.17},
                   {key:0.18,text:"18%",value:0.18},
                   {key:0.19,text:"19%",value:0.19},
                   {key:0.20,text:"20%",value:0.20},
                   {key:0.21,text:"21%",value:0.21},
                   {key:0.22,text:"22%",value:0.22},
                   {key:0.23,text:"23%",value:0.23},
                   {key:0.24,text:"24%",value:0.24},
                   {key:0.25,text:"25%",value:0.25},
                ];


export const unitCalories = ['kcal']

export function isQuantity(unit){
    if (unitWeight.includes(unit.toLowerCase())){
        return false;
    }else if  (unitVolume.includes(unit.toLowerCase())){
        return false;
    }else{
        return true;
    }
}

export function isWeight(unit){
    if (unitWeight.includes(unit.toLowerCase())){
        return true;
    }else{
        return false;
    }
}

export function isVolume(unit){
    if (unitVolume.includes(unit.toLowerCase())){
        return true;
    }else{
        return false;
    }
}

export function isPack(unit){
    if (unit == pack_unit_key_reserved){
        return true;
    }else{
        return false;
    }
}

export function isBoxPack(unit){
    if (unit == box_pack_unit_key_reserved){
        return true;
    }else{
        return false;
    }
}

export function typeYield(unit){
    if (!unit) return "quantity";
    if (unitWeight.includes(unit.toLowerCase())){
        return "weight";
    }else if (unitVolume.includes(unit.toLowerCase())){
        return "volume";
    }else{
        return "quantity";
    }
}

export function computeWeightUnitFactor(s_weight_unit,s_quantity_unit){

    if (!s_quantity_unit || !s_weight_unit){
        return null;
    }

    let weight_unit =  s_weight_unit.toLowerCase()
    let quantity_unit = s_quantity_unit.toLowerCase()

    const kilos = ['kg','kilo','kilogram','kilograms']
    const grams = ['g','gram','grams']
    const mgrams = ['mg','mgram','mgrams']
    const pounds = ['lbs', 'lb', 'pound', 'pounds','livre','livres']
    const ounces = ['oz', 'ounce', 'ounces','once','onces']

    if (kilos.includes(weight_unit)){

        if (kilos.includes(quantity_unit))
            return 1;
        else if (grams.includes(quantity_unit))
            return 1/1000;
        else if (mgrams.includes(quantity_unit))
            return 1/1000000;
        else if (pounds.includes(quantity_unit))
            return 1/2.2;
        else if (ounces.includes(quantity_unit))
            return 1/35.274;

    }else if (grams.includes(weight_unit)){

        if (grams.includes(quantity_unit))
            return 1;
        else if (kilos.includes(quantity_unit))
            return 1000;
        else if (mgrams.includes(quantity_unit))
            return 1/1000;
        else if (pounds.includes(quantity_unit))
            return 1000/2.2;
        else if (ounces.includes(quantity_unit))
            return 28.349;

    }else if (mgrams.includes(weight_unit)){

        if (mgrams.includes(quantity_unit))
            return 1;
        else if (kilos.includes(quantity_unit))
            return 1000000;
        else if (grams.includes(quantity_unit))
            return 1000;
        else if (pounds.includes(quantity_unit))
            return 1000000/2.2;
        else if (ounces.includes(quantity_unit))
            return 28349;

    }else if (pounds.includes(weight_unit)){

        if (pounds.includes(quantity_unit))
            return 1;
        else if (kilos.includes(quantity_unit))
            return 2.2;
        else if (grams.includes(quantity_unit))
            return 2.2/1000;
        else if (mgrams.includes(quantity_unit))
            return 2.2/100000;
        else if (ounces.includes(quantity_unit))
            return 1/16;
    
    }else if (ounces.includes(weight_unit)){

        if (ounces.includes(quantity_unit))
            return 1;
        else if (kilos.includes(quantity_unit))
            return 35.274;
        else if (grams.includes(quantity_unit))
            return 35.274/1000;
        else if (mgrams.includes(quantity_unit))
            return 35.274/1000000;
        else if (pounds.includes(quantity_unit))
            return 16;
    }
    
    return null;
}


export function computeVolumeUnitFactor(s_volume_unit, s_quantity_unit){

    if (!s_quantity_unit || !s_volume_unit){
        return null;
    }

    let volume_unit = s_volume_unit.toLowerCase()
    let quantity_unit = s_quantity_unit.toLowerCase()

    const liters = ['l','L','liter','liters','litre','litres']
    const mliters = ['ml','milliliter','milliliters']
    const cliters=[ 'cl', 'centiliter','centiliters'];
    const flounces = ['floz', 'fluid-ounce', 'fluid-ounces'];
    const gallons = ['gal', 'gallon', 'gallons'];
    const tablespoons = ['tbsp', 'tablespoon', 'tablespoons','cas','cuillere-soupe'];
    const teaspoons = ['tsp', 'teaspoon', 'teaspoons','cac','cuillere-cafe'];

    if (liters.includes(volume_unit)){
        if (liters.includes(quantity_unit))
            return 1;
        else if (mliters.includes(quantity_unit))
            return 1/1000;
        else if (cliters.includes(quantity_unit))
            return 1/100;
        else if (flounces.includes(quantity_unit))
            return 0.02957;
        else if (gallons.includes(quantity_unit))
            return 3.78541;
        else if (tablespoons.includes(quantity_unit))
            return 1/68;
        else if (teaspoons.includes(quantity_unit))
            return 1/203;
    }

    else if (mliters.includes(volume_unit)){
        if (mliters.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 1000;
        else if (cliters.includes(quantity_unit))
            return 10;
        else if (flounces.includes(quantity_unit))
            return 29.5735;
        else if (gallons.includes(quantity_unit))
            return 3785.41;
        else if (tablespoons.includes(quantity_unit))
            return 14.786;
        else if (teaspoons.includes(quantity_unit))
            return  4.9289;
    }

    else if (cliters.includes(volume_unit)){
        if (cliters.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 100;
        else if (mliters.includes(quantity_unit))
            return 1/10;
        else if (flounces.includes(quantity_unit))
            return 2.95735;
        else if (gallons.includes(quantity_unit))
            return 378.541;
        else if (tablespoons.includes(quantity_unit))
            return 1.1478;
        else if (teaspoons.includes(quantity_unit))
            return  0.4929;
    }

    else if (flounces.includes(volume_unit)){
        if (flounces.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 33.814;
        else if (mliters.includes(quantity_unit))
            return 0.033814;
        else if (cliters.includes(quantity_unit))
            return 0.33814;
        else if (gallons.includes(quantity_unit))
            return 128;
        else if (tablespoons.includes(quantity_unit))
            return 1/2;
        else if (teaspoons.includes(quantity_unit))
            return  1/6;
    }

    else if (gallons.includes(volume_unit)){
        if (gallons.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 0.26417;
        else if (mliters.includes(quantity_unit))
            return 0.000264;
        else if (cliters.includes(quantity_unit))
            return 0.00264;
        else if (flounces.includes(quantity_unit))
            return 0.00781;
        else if (tablespoons.includes(quantity_unit))
            return 1/256;
        else if (teaspoons.includes(quantity_unit))
            return  1/768;
    }

    else if (teaspoons.includes(volume_unit)){
        if (teaspoons.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 203;
        else if (mliters.includes(quantity_unit))
            return 0.203;
        else if (cliters.includes(quantity_unit))
            return 2.03;
        else if (gallons.includes(quantity_unit))
            return 768;
        else if (flounces.includes(quantity_unit))
            return 6;
        else if (tablespoons.includes(quantity_unit))
            return 3;
    }

    else if (tablespoons.includes(volume_unit)){
        if (tablespoons.includes(quantity_unit))
            return 1;
        else if (liters.includes(quantity_unit))
            return 67.6;
        else if (mliters.includes(quantity_unit))
            return 0.0676;
        else if (cliters.includes(quantity_unit))
            return 0.676;
        else if (gallons.includes(quantity_unit))
            return 256;
        else if (flounces.includes(quantity_unit))
            return 2;
        else if (teaspoons.includes(quantity_unit))
            return 1/3;
    }

    return null;
}



export function get_payload(string, isdigitPart){
     let tokens = string.split(" ")
     //console.log(tokens)
     if (tokens.length == 1){
         if (isdigitPart){
            let regexp = /[\d\.\d]+/g;
            let numbers = [...string.matchAll(regexp)];
             if (numbers.length == 1)
                return numbers[0][0];
             else 
                return "-";
         }else{
            let regexp = /\D+/g;
             let input = string.replace('.','')
             let units = [...input.matchAll(regexp)];

             if ((units.length == 1) && (input!='') && (input!='NA'))
                return units[0][0]
             else 
                return "g";
        }
     }
     else if (tokens.length == 2){
        let value = []
        if (isdigitPart){
            for (let t of tokens){
                if (isNumeric(t))
                    value.push(t)
            }

            if (value.length == 1)
                return value[0];
            else 
                return "1";
        }else{
            for (let t of tokens){
                if (!isNumeric(t))
                  value.push(t)
            }

            if (value.length == 1)
                return value[0]
            else 
                return "";
        }
     }
    
     return "";
     
}