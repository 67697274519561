import { userConstants } from '../_constants';

let userIn = JSON.parse(localStorage.getItem('userIn'));
const initialState = userIn ? { loggedIn: true, userIn:userIn } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        userIn: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        userIn: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        userIn: undefined
      };
    case userConstants.LOGOUT:
      return {};
      
    case userConstants.VERIFY_REQUEST:
      return {
        ...state,
        verifying: true,
        verified: false,
      };
    case userConstants.VERIFY_SUCCESS:
      return {
        ...state,
        verifying: false,
        verified: true,
      };

    case userConstants.VERIFY_FAILURE:
      return {
        ...state,
        verifying: false,
        verified: false,
        error: action.error,
      };

      case userConstants.RENEW_REQUEST:
      return {
        ...state,
        verifying: true,
        verified: false,
      };
    case userConstants.RENEW_SUCCESS:
        let user = JSON.parse(localStorage.getItem('userIn'));
        if (user && user.token) {
          // Change local token
          user.token = action.user.token
          localStorage.setItem('userIn', JSON.stringify(user));
        }

      return {
        ...state,
        verifying: false,
        verified: true,
        userIn: user
      };

    case userConstants.RENEW_FAILURE:
      return {
        verifying: false,
        verified: false,
        error: action.error,
      };

    default:
      return state
  }
}