export function Capitalize(str){
    if (str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    else{
        return str;
    }
}

export function isNumeric(num){
    return !isNaN(num)
}


export function isInteger(x) { return typeof x === "number" && isFinite(x) && Math.floor(x) === x; }
export function isFloat(x) { return !!(x % 1); }

export function strip(number) {
    return (parseFloat(number).toPrecision(12));
}

export function trim(s) { 
    s = s.replace(/(^\s*)|(\s*$)/gi,"");
    s = s.replace(/[ ]{2,}/gi," "); 
    s = s.replace(/\n /,"\n"); 
    return s;
}

export function precise(x) {
    return Number.parseFloat(x).toPrecision(3);
  }


export function isEmpty(str) {
    return (!str || 0 === str.length);
}


export function optimizeDecimalPart(number,precision=2){
    //return number = (Number(number) === number && number % 1 !== 0) ? eval(parseFloat(number).toFixed(precision)):number;
    return Number.isInteger(number) ? number.toFixed(0) :  eval(parseFloat(number).toFixed(precision))
    //return Number.isInteger(number) ? number.toFixed(0) : number.toFixed(precision)
}


export function dateWithoutTimezone(date) {
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1);
   return withoutTimezone;
};