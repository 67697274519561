import React from 'react'
import {MainFooter,ResponsiveContainer} from './Segments';
import { Grid,Segment} from 'semantic-ui-react';
import {FormattedMessage,injectIntl,defineMessages} from 'react-intl';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {styles} from './Styles';




const html_tags = defineMessages({
   html_title: {
       id: 'cgucgv.html.title.tag',
       defaultMessage: "Legal Mentions - Terms and Conditions",
  },
  meta_name_description: {
       id: 'cgucgv.meta.name.description.tag',
       defaultMessage: "Legal Mentions - Terms and Conditions",
  }
});


class CguCgv extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {post: []}
  } 


 render() {
     const {intl} = this.props;

    return (
    <ResponsiveContainer>
       <Helmet>
            <title>{intl.formatMessage(html_tags.html_title)} - Ratatool</title>
            {/* <meta name="description" content={'Calculez votre prix de revient recette, editez vos fiches techniques de cuisine, vos bons de commandes fournisseurs et vos étiquettes nutritionnelles INCO pour votre restaurant'}/> */}
            <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>

            { intl.locale == 'fr' &&
            <link rel="canonical" href={"https://fr.ratatool.com/termsconditions"}/>}

             { intl.locale == 'en' &&
            <link rel="canonical" href={"https://www.ratatool.com/termsconditions"} />}
            
            <link rel="alternate" hreflang="fr" href={"https://fr.ratatool.com/termsconditions"} />
            <link rel="alternate" hreflang="en" href={"https://www.ratatool.com/termsconditions"} />
            <link rel="alternate" hreflang="x-default" href={"https://www.ratatool.com/termsconditions"} />
       </Helmet> 
       <Segment vertical style={{border:'none',padding: '5em 0em 0em 0em',/*'font-family': 'Lato'*/}}>
         <Grid  stackable container centered /*verticalAlign='middle'*/ columns={1}>
            <Grid.Row>
                <Grid.Column width={16}>
                { intl.locale == 'fr' &&
                <div>
                <h1>
                    MENTIONS LÉGALES
                </h1>
                <h4>
                    SERVICE RATATOOL
                </h4>
                <h4>
                    Editeur
                </h4>
                <p>
                    TEDIBYTE, Société par actions simplifiée immatriculée au RCS de Paris sous
                    le numéro 844361873 et dont le siège social se situe 18 rue Ernest Cresson,
                    75014 PARIS.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Email </span>
                    <a href="mailto:contact@ratatool.com">contact@ratatool.com</a>
                </p>
{/*                 <p>
                    <span style={{'font-weight': '700'}}>Directeur de publication </span>
                     M Khaled ABEDI, Président
                </p> */}
                <p>
                    <span style={{'font-weight': '700'}}>Hébergeur </span>
                    {' '}DigitalOcean LLC
                </p>
                <h1>
                    CONDITIONS GENERALES D'UTILISATION ET DE VENTE
                </h1>
                <h1>
                    1. Acceptation
                </h1>
                <p>
                    L’accès et l’utilisation du Service Ratatool accessible à l’adresse
                    suivante : fr.ratatool.com (ci-après dénommée « le Service Ratatool ») de
                    la société TEDIBYTE SAS, Société par actions simplifiée, immatriculée au
                    RCS de Paris sous le numéro 844 361 873 et dont le siège social se situe 18
                    RUE ERNEST CRESSON 75014 PARIS (ci-après dénommée « TEDIBYTE ») et des
                    services qui y sont proposés sont soumis au respect des présentes
                    Conditions générales d'utilisation et de vente.
                </p>
                <p>
                    Tout accès et/ou utilisation du Service RATATOOL suppose l’acceptation et
                    le respect de l’ensemble des termes des présentes conditions et leur
                    acceptation inconditionnelle. Elles constituent un contrat entre TEDIBYTE
                    et l’internaute qui accède au Service RATATOOL, ainsi que le client,
                    personne physique ou morale agissant dans le cadre de son activité
                    professionnelle, préalablement inscrit et ayant créé un compte sur le
                    Service RATATOOL (ci-après dénommé « le Client »).
                </p>
                <p>
                    Le présent contrat est conclu pour une durée indéterminée à compter de
                    l’utilisation service RATATOOL par le Client.
                </p>
                <p>
                    TEDIBYTE se réserve la possibilité de faire évoluer à tout moment le
                    service RATATOOL ainsi que les Conditions générales de services, ou encore
                    de compléter celles-ci par de nouvelles conditions contractuelles
                    complémentaires. C’est pourquoi, il est conseillé aux Clients de consulter
                    régulièrement les Conditions générales d'utilisation et de vente, afin de
                    se référer à la dernière version en vigueur.
                </p>
                <h1>
                    2. Définitions
                </h1>
                <p>
                     <span style={{'font-weight': '700'}}>Client</span>
                    : l’internaute qui accède au Service RATATOOL, ainsi que le client,
                    personne physique ou morale agissant dans le cadre de son activité
                    professionnelle, préalablement inscrit et ayant créé un compte sur le
                    Service RATATOOL.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>TEDIBYTE</span>
                    : société par actions simplifiée immatriculée au RCS de Paris sous le
                    numéro 844 361 873 et dont le siège social se situe 18 RUE ERNEST CRESSON
                    75014 PARIS, éditrice du Service Ratatool.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Service RATATOOL</span>
                    : plateforme de services en ligne accessible à l’adresse fr.ratatool.com
                </p>
                <h1>
                    3. Objet du Service Ratatool
                </h1>
                <p>
                    Le Service RATATOOL propose à ses Clients un service de gestion quotidienne
                    dédié aux restaurateurs (gestion des fiches techniques de recettes, gestion d'ingrédients fournisseurs, 
                    génération de bon de commandes, outils de calcul d'indicateurs financiers
                    de profitabilité) par abonnement mensuel ou annuel.
                </p>
                <h1>
                    4. Disponibilité des Services
                </h1>
                <p>
                    Le Service RATATOOL est disponible en quelque endroit que ce soit, sur tout
                    type de périphérique, dès lors que les conditions techniques minimales sont
                    atteintes, et notamment en termes d’accès au réseau de téléphonie mobile,
                    réseau Internet et de compatibilité technique du matériel utilisé.
                </p>
                <h1>
                    5. Conditions d’inscription
                </h1>
                <p>
                    Pour ouvrir un compte sur RATATOOL le client (personne physique ou morale)
                    doit :
                </p>
                <ul style={{'color':'grey'}}>
                    <li>
                        Être majeur et être juridiquement capable.
                    </li>
                    <li>
                        Pouvoir et savoir se connecter à Internet.
                    </li>
                    <li>
                        Disposer d’une adresse mail valide.
                    </li>
                </ul>
                <p>
                    Le Client dispose d’un espace personnel après inscription sur le service
                    RATATOOL.
                </p>
                <p>
                    Le compte pourra être résilié par RATATOOL à tout moment et sans préavis du
                    fait d’un usage frauduleux ou illicite des services et contenus du service
                    RATATOOL.
                </p>
                <p>
                    Le Client pourra mettre fin à son inscription à tout moment, en respectant
                    les modalités décrites sur le service RATATOOL.
                </p>
                <h1>
                    6. Garantie
                </h1>
                <p>
                    TEDIBYTE n’est tenu à l’égard du Client qu’à une obligation de moyens et ne
                    fournit aucune garantie, expresse ou implicite, y compris, toute garantie
                    de qualité et d’adéquation à un usage particulier des prestations fournies
                    au Client.
                </p>
                <h1>
                    7. Propriété intellectuelle
                </h1>
                <p>
                    Le contenu technique, graphique, textuel ou autres du service RATATOOL
                    constituent, ensemble, une œuvre protégée par les lois en vigueur sur la
                    propriété intellectuelle, dont TEDIBYTE est titulaire. En particulier, le
                    service est fourni au moyen de logiciels et de bases de données conçus et
                    développés par RATATOOL qui lui appartiennent ou sur lesquels elle dispose
                    des droits de propriété intellectuelle
                </p>
                <p>
                    Toute reproduction, intégrale ou partielle, est systématiquement soumise à
                    l’autorisation de TEDIBYTE.
                </p>
                <h3>
                    7.1 Droits d’auteur
                </h3>
                <p>
                    Il est interdit de copier, extraire, diffuser ou modifier le contenu du
                    service RATATOOL à des fins commerciales. Le téléchargement ainsi que
                    l’impression de texte, images et éléments graphiques sont autorisés au seul
                    usage privé et non commercial. La reproduction de dessins, images,
                    documents sonores, séquences vidéo et textes dans d’autres publications
                    électroniques ou imprimées nécessite le consentement écrit préalable de
                    TEDIBYTE.
                </p>
                <p>
                    Le défaut d’autorisation est sanctionné par le délit de contrefaçon.
                </p>
                <h3>
                    7.2 Marques
                </h3>
                <p>
                    Les marques et logos figurant sur le service RATATOOL sont des marques
                    déposées et protégées.
                </p>
                <p>
                    Toute reproduction totale ou partielle des marques et/ou logos présents sur
                    le service RATATOOL, effectuée à partir du service sans l’autorisation
                    expresse de TEDIBYTE est constitutive de contrefaçon sanctionnée par les
                    articles L.713-2 et suivants du Code de la propriété intellectuelle.
                </p>
                <h1>
                    8. Responsabilité de Ratatool
                </h1>
                <p>
                    RATATOOL fait son possible pour s’assurer du bon fonctionnement de site en
                    ligne et des services y figurant, dans les limites de responsabilité des
                    présentes conditions générales.
                </p>
                <h3>
                    <strong> 8.1 Accès au service</strong>
                </h3>
                <p>
                    Le service RATATOOL est en principe accessible 24 heures sur 24 et 7 jours
                    sur 7, cependant, TEDIBYTE décline toute responsabilité, sans que cette
                    liste soit limitative :
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        En cas d’interruption du service pour des opérations de maintenance
                        techniques ou d’actualisation des informations publiées.
                    </li>
                    <li>
                        En cas d’impossibilité momentanée d’accès au service RATATOOL en raison
                        de problèmes techniques et ce quelles qu’en soient l’origine.
                    </li>
                    <li>
                        En cas d’indisponibilité ou toute autre cause empêchant le
                        fonctionnement normal du réseau utilisé pour accéder au service.
                    </li>
                    <li>
                        Plus généralement, en cas de dommages directs ou indirects causés au
                        Client, quelle qu’en soit la nature, résultant de l’accès, ou de
                        l’utilisation du service RATATOOL
                    </li>
                    <li>
                        En cas d’utilisation anormale ou d’une exploitation illicite du service
                        RATATOOL.
                    </li>
                    <li>
                        En cas de perte par le Client de son identifiant et/ou de son mot de
                        passe ou en cas d’usurpation de son identité.
                    </li>
                </ul>
                <h3>
                    <strong> 8.2 Contenus mis en ligne par les Clients</strong>
                </h3>
                <p>
                    TEDBYTE n’est pas à l’origine de la création du contenu mis en ligne sur le
                    service RATATOOL par les Clients qui sont diffusés sous la responsabilité
                    exclusive du Client.
                </p>
                <p>
                    Sans modération a priori du contenu mis en ligne par les Clients, TEDIBYTE
                    ne peut être tenu pour responsable d’un quelconque contenu qui serait
                    illicite, contraire aux bonnes mœurs ou constituant une quelconque atteinte
                    aux droits d’autrui, notamment à des droits de propriété intellectuelle ou
                    des traitements de données à caractère personnel.
                </p>
                <p>
                    TEDBYTE ne peut être tenu pour responsable des échanges intervenus entre
                    les Clients sur le service RATATOOL.
                </p>
                <p>
                    TEDBYTE ne peut être tenu pour responsable d’un quelconque contenu figurant
                    sur le service RATATOOL ou envoyé à partir du service par un tiers, quel
                    qu’il soit.
                </p>
                <p>
                    Ainsi, au regard des qualifications juridiques définies par l’article L34-1
                    du Code des postes et des communications électroniques, TEDBYTE est
                    qualifié d’hébergeur pour le contenu mis en ligne par les Clients sur le
                    service RATATOOL.
                </p>
                <p>
                    TEDBYTE n’est donc pas responsable a priori du contenu des Clients et ne
                    supporte pas d’obligation de surveillance de ce contenu.
                </p>
                <h3>
                    <strong>8.3 Contenu public du service </strong>
                    RATATOOL
                </h3>
                <p>
                    Malgré le soin apporté à la création et à la mise à jour du service
                    RATATOOL, TEDBYTE ne peut assurer aucune garantie, expresse ou tacite,
                    concernant les informations contenues sur le service RATATOOL dont il est
                    l’auteur.
                </p>
                <p>
                    Par conséquent, TEDIBYTE ne peut être tenu responsable de tout dommage,
                    direct ou indirect, résultant de quelconques erreurs, inexactitudes ou
                    omissions des informations contenues sur le service RATATOOL.
                </p>
                <h3>
                    <strong> 8.4 Force majeure</strong>
                </h3>
                <p>
                    TEDIBYTE ne pourra être tenu pour responsable, ou considéré comme ayant
                    failli aux présentes Conditions générales, pour tout retard ou
                    inexécution, lorsque la cause du retard ou de l'inexécution est liée à un
                    cas de force majeure telle que définie par la jurisprudence des cours et
                    tribunaux français y compris l'interruption, la suspension, la réduction ou
                    les dérangements de l'électricité ou autres ou toutes interruptions de
                    réseaux de communications électroniques ou en cas de faits indépendants de
                    sa volonté.
                </p>
                <h3>
                    <strong> 8.5 Intervention de </strong>
                    TEDIBYTE
                </h3>
                <p>
                    TEDIBYTE se réserve le droit de modifier les termes, conditions et mentions
                    des présentes à tout moment, notamment en cas d’évolutions techniques,
                    légales ou jurisprudentielles ou lors de la mise en place de nouveaux
                    services.
                </p>
                <h1>
                    9. Droits et responsabilité du Client
                </h1>
                <h2>
                </h2>
                <h3>
                    9.1 Responsabilité
                </h3>
                <p>
                    L’utilisation du site RATATOOL est faite sous la seule et entière
                    responsabilité du Client, il est le seul responsable des informations qu’il
                    communique sur le service RATATOOL.
                </p>
                <p>
                    En cas d’utilisation anormale ou d’exploitation illicite du service
                    RATATOOL, le Client est seul responsable des dommages causés aux tiers et
                    des conséquences des réclamations ou actions qui pourraient en découler.
                </p>
                <p>
                    Le Client renonce également à exercer tout recours contre TEDIBYTE dans le
                    cas de poursuites diligentées par un tiers à son encontre du fait de
                    l’utilisation et/ou de l’exploitation illicite du service RATATOOL.
                </p>
                <p>
                    Le Client s’engage, d’une manière générale, à respecter l’ensemble de la
                    réglementation en vigueur en France.
                </p>
                <h3>
                    9.2 Contenus
                </h3>
                <p>
                    Les Clients s’engagent à ne pas utiliser le service RATATOOL pour :
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        Publier, transmettre, partager, stocker ou mettre à disposition de tout
                        autre manière un contenu contraire à l’ordre public, préjudiciable,
                        menaçant, illégal, diffamatoire, non autorisé, abusif, injurieux,
                        malveillant, vulgaire, obscène, frauduleux, portant atteinte à la vie
                        privée ou aux droits à l'image, odieux, incitant à la violence, à la
                        haine raciale ou ethnique ou autrement répréhensible ;
                    </li>
                    <li>
                        Créer plusieurs comptes ou usurper l’identité d’un tiers ;
                    </li>
                    <li>
                        Transmettre tout élément contenant des virus informatiques ou tout
                        code, fichier ou programme informatique conçu pour interrompre,
                        détruire ou limiter la fonctionnalité de logiciels, d'équipement
                        informatique ou de communications électroniques ;
                    </li>
                    <li>
                        Publier, transmettre, partager, stocker ou mettre à disposition de tout
                        autre manière un contenu qui constitue ou encourage un acte pénalement
                        répréhensible ou fournit des instructions sur la manière de le
                        perpétrer, qui enfreint les droits d'un tiers ou est susceptible
                        d'engager la responsabilité d'un tiers ou d'enfreindre la législation
                        locale, nationale ou internationale.
                    </li>
                </ul>
                <p>
                    Le Client est seul responsable du contenu qu’il intègre dans le service
                    RATATOOL, notamment tout contenu pouvant présenter un caractère offensant
                    ou illégal ou susceptible d’enfreindre les droits des tiers.
                </p>
                <h3>
                    9.3 Accès et sécurité
                </h3>
                <p>
                    Il appartient à tout Client de prendre les mesures appropriées de façon à
                    protéger ses propres données et/ou logiciels stockés sur son équipement
                    mobile ou informatique contre toute atteinte.
                </p>
                <p>
                    Le Client a le droit de consulter gratuitement ses données hébergées sur le
                    service RATATOOL et toute autre information le concernant.
                </p>
                <p>
                    L’utilisation des services de RATATOOL nécessite l’ouverture d’un compte
                    impliquant la fourniture d’un identifiant et le choix d’un mot de passe.
                </p>
                <p>
                    Le mot de passe est personnel et confidentiel. Le Client en est seul
                    responsable. Il s’engage à ne pas le divulguer à des tiers et à prendre
                    toutes les précautions nécessaires pour éviter que des tiers ne puissent y
                    avoir accès.
                </p>
                <p>
                    Le Client s’engage à avertir sans délai TEDIBYTE en cas de perte ou
                    d’usurpation de son mot de passe.
                </p>
                <p>
                    A défaut, et sauf preuve contraire, toute connexion ou transmission
                    d’ordres ou de données effectuées au moyen du mot de passe sera réputée
                    provenir du Client et sera sous sa responsabilité exclusive.
                </p>
                <p>
                    Pour être prises en compte, les réclamations du Client devront être
                    adressées à l’adresse électronique suivante : support@ratatool.com
                </p>
                <h3>
                    9.4 Données à caractère personnel du Client
                </h3>
                <p>
                    TEDIBYTE demande à tout Client, de communiquer un certain nombre
                    d’informations personnelles limité (nom, prénom, adresse électronique,
                    pseudonyme, informations de facturations...) afin d’être en mesure de
                    l’identifier, de garantir l’unicité de son accès personnel et de fournir
                    les services nécessaires au strict fonctionnement du service RATATOOL.
                </p>
                <p>
                    Toutes les informations peuvent être supprimées à la demande du Client et
                    entraînent alors la fermeture du compte. L'inscription ou le paiement pour
                    souscrire à l’abonnement du service RATATOOL entraîne acceptation de la
                    récolte des données évoquées.
                </p>
                <p>
                    Les données sont utilisées exclusivement utilisées pour améliorer le
                    service ou à des fins de communications de nouveaux produits aux clients
                    inscrits.
                </p>
                <h1>
                    10. Souscription offre payante
                </h1>
                <h3>
                    10.1 Souscription et description des Abonnements
                </h3>
                <p>
                    Le Client qui souhaite souscrire un Abonnement doit au préalable être
                    inscrit en qualité de Membre du service RATATOOL.
                </p>
                <p>
                    Pour souscrire un Abonnement, le Client doit se rendre sur le site dans la
                section Tarifs ou sur la page    <a href="https://fr.ratatool.com/pricing">fr.ratatool.com/pricing</a> et
                    cliquer sur une offre des offres payantes proposées.
                </p>
                <p>
                    L’Abonnement offre la possibilité à l’Abonné de bénéficier des
                    fonctionnalités décrites de manière détaillée dans la page Tarifs décrites
                    précédemment.
                </p>
                <p>
                    A la première souscription d'un abonnement, le Client bénéficie
                    automatiquement d'un compte personnel virtuel.
                </p>
                <h3>
                    10.2 Durée et modification de l'Abonnement
                </h3>
                <p>
                    Le Client qui a souscrit un Abonnement s’engage pour la durée déterminée
                    dans le Formulaire d’Abonnement correspondant (1 ou 12 mois) à compter de
                    son acceptation des présentes Conditions Générales.
                </p>
                <p>
                    A l’issue de la durée initiale de l’Abonnement, celui-ci est renouvelé
                    tacitement pour la durée déterminée de l’Abonnement initial, sauf volonté
                    contraire de l’Abonné manifestée en suivant les étapes ci-après décrites.
                </p>
                <p>
                    L’Abonné a la possibilité d’annuler la reconduction tacite à tout moment
                    pour l'Abonnement auquel il a souscrit en suivant la procédure ci-dessous
                    décrite.
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        1. Se connecter sur le site
                    </li>
                    <li>
                2. Se rendre dans la "<em>Tableau de bord"</em> et dans la section        <em>Profil</em>
                    </li>
                    <li>
                        3. Dans le menu, choisir <em>"Souscriptions"</em>
                    </li>
                    <li>
                        3. Cliquer sur le bouton "Annuler souscription".
                    </li>
                </ul>
                <p>
                    Si l’Abonné ne voit pas un lien « Annuler souscription » au sein de son
                    compte, cela signifie que l’Abonnement ne sera pas renouvelé. Il conserve
                    la possibilité de le réactiver en cliquant "Renouveler souscription" ou '
                    Choisir une autre formule" avant l'expiration de celui-ci.
                </p>
                <p>
                    La demande d’annulation de la reconduction tacite d’un Abonnement sera
                    prise en compte et effective à la date anniversaire du Contrat, définie
                    comme la date d’expiration de la durée déterminée fixée dans le Formulaire
                    d’inscription. A la date anniversaire, l'Abonnement ne sera pas reconduit
                    et s'arrêtera sauf si l'Abonné choisit de le réactiver en cliquant le lien
                    "Renouveler souscription " avant cette date anniversaire.
                </p>
                <p>
                    L'Abonné conserve également la possibilité de modifier son Abonnement à
                    tout moment en changeant de formule ou de durée dans le formulaire
                    d'Abonnement correspondant.
                </p>
                <p>
                    La détermination du nouveau tarif d'abonnement se fait de la manière
                    suivante :
                </p>
                <p>
                    · Si le Client opte pour un changement sur une offre alternative sur la
                    même durée d'abonnement, le changement sera effectif immédiatement mais le
                    règlement de la différence sera calculé à la fin de la période au moment du
                    renouvellement en début de nouvelle période. Si un surplus en faveur du
                    client existe, il n'est pas perdu et est réutilisé lors des paiements
                    suivants.
                </p>
                <p>
                    · Si par contre le Client opte pour un changement sur une offre alternative
                    sur une autre durée d'abonnement, le règlement calculé au prorata temporis
                    est alors réalisé immédiatement.
                </p>
                <h3>
                    10.3 Prix, facturation et retard ou défaut de règlement
                </h3>
                <h4>
                </h4>
                <h4>
                    Prix et moyens de paiement
                </h4>
                <p>
                    Pour souscrire un Abonnement, l’Abonné doit suivre les étapes de la
                    procédure d’abonnement proposée sur le Site. Le coût de l’Abonnement et les
                    moyens de paiement sont mentionnés dans le Formulaire d’Abonnement
                    accessible en ligne.
                </p>
                <p>
                    Toute décision de remise, de réduction ou d'application de tarifs
                    dégressifs, selon un pourcentage ou un forfait demeure à la seule
                    discrétion de TEDIBYTE et ce, uniquement pour la prestation qui en est
                    l'objet. Les remises ou rabais éventuellement accordés au Professionnel ne
                    sauraient en aucun cas faire naître un droit acquis pour des prestations
                    postérieures.
                </p>
                <h4>
                    Facturation - Conditions de règlement
                </h4>
                <p>
                    Les Abonnements sont facturés à la date de souscription de l’Abonnement.
                    L’historique des factures est disponible sur le Site dans le tableau de bord, en cliquant sur le
                    lien « Profil », sous la rubrique « Factures » au sein du menu du site.
                </p>
                <p>
                    Les Abonnements ne supposent pas d’engagement ferme et peuvent faire
                    l’objet d’une résiliation dans les conditions des CGU. La commande fera
                    l’objet d’un paiement mensuel en EUROS après validation définitive auprès
                    de TEDIBYTE.
                </p>
                <p>
                    En France métropolitaine, le règlement des mensualités se fait via la
                    plateforme de paiement sécurisée Stripe.
                </p>
                <p>
                    TEDIBYTE n’est d’aucune manière un établissement bancaire et l'ensemble des
                    prestations d'intermédiaire de paiement, d'encaissement pour compte de
                    tiers et de création et gestion de porte-monnaie électronique sont assurées
                    par notre partenaire Stripe, agréé et spécialisé dans ces prestations. Les
                Conditions Générales de Vente de Stripe sont accessibles à l’adresse    <a href="https://stripe.com" target="_blank">www.stripe.com</a> Il
                    appartient au Client en conséquence de vérifier l’adéquation des Services
                    de TEDIBTYE à ses besoins.
                </p>
                <p>
                    Cette transaction est sécurisée selon un procédé de cryptage ssl à 128 bits
                    et ce afin de renforcer l'ensemble des procédés de brouillage et de
                    cryptage en optimisant la protection de toutes les données personnelles
                    liées à ce moyen de paiement.
                </p>
                <p>
                    Le premier versement s’effectue au jour de la validation définitive de
                    l’abonnement. Le second prélèvement interviendra le mois suivant la date de
                    validation définitive de l’abonnement. Le montant prélevé sera composé du
                    mois à échoir.
                </p>
                <p>
                    Certaines données personnelles peuvent être transférées à Stripe et/ou
                    d'autres organismes dans l'unique et exclusive finalité de vérifier si ces
                    informations transmises sont véridiques.
                </p>
                <p>
                    TEDIBYTE se réserve le droit de résilier tout abonnement en cas d’incident
                    de paiement et aucun remboursement ne pourra avoir lieu dès lors que le
                    travail fourni a été engagé.
                </p>
                <p>
                    Toute modification de l’abonnement à la demande du Professionnel fera
                    l’objet d’une facturation supplémentaire.
                </p>
                <h4>
                    Retard ou défaut de paiement
                </h4>
                <p>
                    Tout retard ou défaut de paiement de l’Abonnement donnera lieu à l'envoi à
                    l’Abonné d'une notification adressée par courrier électronique, lui
                    indiquant qu’il dispose d’un délai de cinq (5) jours pour régulariser sa
                    situation. Pendant cette période, l’accès aux fonctionnalités correspondant
                    à l’Abonnement pourra être suspendu.
                </p>
                <p>
                    A défaut du règlement de l’intégralité des sommes dues dans ce délai, le
                    contrat pourra être résilié de plein droit par TEDIBYTE.
                </p>
                <p>
                    En cas de retard de paiement des sommes dues par le Client au-delà des
                    délais fixés, des pénalités de retards calculées au taux d’intérêt appliqué
                    par la Banque Centrale Européenne à son opération de refinancement la plus
                    récente, majorée de 10 points de pourcentage, sur le montant TTC du prix
                    des prestations de services figurant sur ladite facture, seront
                    automatiquement et de plein droit acquises au Prestataire, sans formalité
                    ni mise en demeure préalable et entraînera l’exigibilité immédiate de la
                    totalité des sommes dues à TEDIBYTE par le Client, sans préjudice de tout
                    autre action que TEDIBYTE serait en droit d’intenter, à ce titre, à
                    l’encontre du Client.
                </p>
                <h1>
                    11. Utilisation des cookies
                </h1>
                <p>
                    Le service RATATOOL utilise la technologie des "cookies". Les cookies sont
                    utiles notamment pour faciliter la personnalisation de l'accès du Client au
                    Service. En particulier, le service RATATOOL via son site comprend un
                    cookie de connexion permettant aux clients d'accéder de manière répétée au
                    site sans avoir à s'identifier à chaque fois. La durée de vie du cookie
                    peut être limité dans le temps ce qui oblige le client à se reconnecter
                    régulièrement pour des raisons de sécurité.
                </p>
                <p>
                    D'une manière générale, les cookies sont sans dommage pour le
                    micro-ordinateur du Client. Le Client est libre d'accepter ou de refuser
                    les cookies en configurant son navigateur. Les cookies sont anonymes et ne
                    sont en aucun cas utilisés pour collecter des données à caractère
                    personnel, mais uniquement à des fins de connexion et d'amélioration du
                    service.
                </p>
                <h1>
                    12. Liens hypertextes
                </h1>
                <p>
                    TEDIBYTE précise que l’usage de liens hypertextes peut conduire le Client
                    vers d’autres sites web ou applications, indépendants du service RATATOOL.
                </p>
                <p>
                    Les liens hypertextes établis en direction d’autres sites ou applications à
                    partir du service RATATOOL ne sauraient, en aucun cas, engager la
                    responsabilité de TEDIBYTE.
                </p>
                <p>
                    De la même manière, l’insertion de liens hypertextes vers tout ou partie du
                    service RATATOOL est autorisée, à titre non exclusif et révocable à tout
                    moment, sans que TEDIBYTE ait à fournir une quelconque justification, et à
                    condition que ce lien ne puisse créer à l’encontre du service RATATOOL un
                    caractère mensonger, faux, péjoratif ou pouvant lui porter préjudice.
                </p>
                <p>
                    Au titre de cette autorisation, TEDIBYTE se réserve un droit d’opposition.
                </p>
                <p>
                    TEDIBYTE ne saurait être tenu pour responsable de tous préjudices directs,
                    indirects ou fortuits résultants de l’accès ou de l’utilisation des
                    informations provenant de sites tiers.
                </p>
                <h1>
                    12. Conditions tarifaires
                </h1>
                <p>
                Une version à jour des conditions tarifaires est disponible sur    <a href="https://fr.ratatool.com/pricing">fr.ratatool.com/pricing</a>
                </p>
                <p>
                    Tous les prix s’entendent hors taxes.
                </p>
                <h1>
                    13. Contact
                </h1>
                <p>
                Si vous avez d'autres questions ?    <a href="https://fr.ratatool.com/contact">Nous contacter</a>
                </p>
                </div>}
                { intl.locale == 'en' &&
                <div>
                 <h1>
                    LEGAL MENTIONS
                </h1>
                <h4>
                    RATATOOL SERVICE
                </h4>
                <h4>
                    Editor
                </h4>
                <p>
                    TEDIBYTE, simplified joint-stock company registered with the Trade and
                    Companies Register of Paris under number 84436187, with registered office
                    at 18 rue Ernest Cresson, 75014 PARIS.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>Email </span>
                    <a href="mailto:contact@ratatool.com">contact@ratatool.com</a>
                </p>
{/*                 <p>
                    <span style={{'font-weight': '700'}}>Publication Director </span>
                    M Khaled ABEDI, President
                </p> */}
                <p>
                    <span style={{'font-weight': '700'}}>Web host </span>
                    {' '}DigitalOcean LLC
                </p>
                <h1>
                    TERMS AND CONDITIONS
                </h1>
                <h1>
                    1. Acceptance
                </h1>
                <p>
                    The access and use of Ratatool Service accessible at the following address:
                    www.ratatool.com (hereinafter referred to as "the Ratatool Service") of the
                    company TEDIBYTE SAS, a simplified joint-stock company, with the Trade and
                    Companies Register of Paris under number 844 361 873, with registered
                    office at 18 rue Ernest Cresson, 75014 PARIS.(hereinafter referred to as
                    "TEDIBYTE") and the services provided therein are subject to compliance
                    with these Terms and Conditions.
                </p>
                <p>
                    Any access and/or use of RATATOOL Service implies acceptance and respect of
                    all terms of these conditions and their unconditional acceptance. They
                    constitute a contract between TEDIBYTE and the user who accesses the
                    RATATOOL Service, as well as the client, natural or legal person acting as
                    part of his professional activity, previously registered and having created
                    an account on RATATOOL Service (hereinafter referred to as " the customer
                    ").
                </p>
                <p>
                    This contract is concluded for an indefinite period from the use of
                    RATATOOL service by the Customer.
                </p>
                <p>
                    TEDIBYTE reserves the right to change the RATATOOL service and the Terms
                    and Conditions at any time, or to supplement them with new additional
                    contractual conditions. Therefore, Customers are advised for to regularly
                    consult the Terms and Conditions in order to refer to the latest version in
                    force.
                </p>
                <h1>
                    2. Definitions
                </h1>
                <p>
                    <span style={{'font-weight': '700'}}>Customer</span>
                    : the internet user who accesses the RATATOOL Service, as well as the
                    customer, natural or legal person acting as part of his professional
                    activity, previously registered and having created an account on the
                    RATATOOL Service.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>TEDIBYTE</span>
                    : simplified joint-stock company registered with the Trade and Companies
                    Register of Paris under number 844 361 873, with registered office at 18
                    Rue Ernest Cresson 75014 PARIS, editor of the Ratatool Service.
                </p>
                <p>
                    <span style={{'font-weight': '700'}}>RATATOOL service</span>
                    : online service available at www.ratatool.com
                </p>
                <h1>
                    3. Purpose of Ratatool Service
                </h1>
                <p>
                    The RATATOOL Service offers to his customers a daily management service
                    dedicated to restaurant owners or catering businesses (management of recipe
                    data professional forms, management of supplier ingredients, generation of
                    purchase orders, tools for calculating financial profitability indicators)
                    by monthly or annual subscription.
                </p>
                <h1>
                    4. Availability of services
                </h1>
                <p>
                    The RATATOOL Service is available in any location, on any type of device,
                    whenever the minimum technical conditions are met, and in particular in
                    terms of access to the mobile network, Internet network and technical
                    compatibility of the equipment used.
                </p>
                <h1>
                    5. Conditions of registration
                </h1>
                <p>
                    To open an account on RATATOOL the customer (natural or legal person) must:
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                      Be adult (more than 18 years old) and be legally capable.
                    </li>
                    <li>
                     Power and know how to connect to the Internet.
                    </li>
                    <li>
                        Have a valid email address.
                    </li>
                </ul>
                <p>
                    The Customer has a personal space after registration on the RATATOOL
                    service.
                </p>
                <p>
                    The account may be terminated by TEDIBYTE at any time and without notice
                    due to fraudulent or unlawful use of the services and contents of the
                    RATATOOL service.
                </p>
                <p>
                    The Customer may terminate his registration at any time, respecting the
                    terms described on the RATATOOL service.
                </p>
                <h1>
                    6. Warranty
                </h1>
                <p>
                    TEDIBYTE is liable to the Customer for an obligation of means and does not
                    provide any guarantee, express or implied, including any guarantee of
                    quality and suitability for a particular use of the services provided to
                    the Customer.
                </p>
                <h1>
                    7. Intellectual property
                </h1>
                <p>
                    The technical content, graphic charter, textual or other content of the
                    RATATOOL service together constitutes, a work protected by the laws in
                    force on intellectual property, of which TEDIBYTE is the owner. In
                    particular, the service is provided by means of software and databases
                    designed and developed by RATATOOL protected by intellectual property
                    rights.
                </p>
                <p>
                    Any reproduction, integral or partial, is systematically subjected to the
                    authorization of TEDIBYTE.
                </p>
                <h2>
                    7.1 Copyright
                </h2>
                <p>
                    It is forbidden to copy, extract, distribute or modify the content of the
                    RATATOOL service for commercial purposes. The download as well as the
                    printing of text, images and graphic elements are authorized for the only
                    private and non-commercial use. The reproduction of drawings, images, sound
                    documents, video sequences and texts in other electronic or printed
                    publications requires the prior written consent of TEDIBYTE.
                </p>
                <p>
                    The lack of authorization is penalized by the offense of counterfeiting.
                </p>
                <h2>
                    7.2 Trademarks
                </h2>
                <p>
                    Trademarks and logos appearing on the RATATOOL service are registered and
                    protected trademarks.
                </p>
                <p>
                    Any total or partial reproduction of the trademarks and/or logos present on
                    the RATATOOL service, made from elements of the service RATATOOL without
                    the express authorization of TEDIBYTE constitutes an infringement
                    punishable by the articles L.713-2 and seq. of the Intellectual Property
                    Code.
                </p>
                <h1>
                    8. Liability of TEDIBYTE
                </h1>
                <p>
                    RATATOOL makes its best effort to ensure the proper functioning of the
                    website and the services contained therein, within the limits of liability
                    of the present terms and conditions.
                </p>
                <h2>
                    <strong> </strong>
                    <strong>8.1 Access to RATATOOL service</strong>
                </h2>
                <p>
                    The RATATOOL service is in principle accessible 24 hours a day, 7 days a
                    week, however, TEDIBYTE disclaims any liability, without this list being
                    limiting:
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        In case of service interruption for technical maintenance operations or
                        updating of the published information.
                    </li>
                    <li>
                        In case of temporary impossibility to access the RATATOOL service due
                        to technical problems, regardless of their origin.
                    </li>
                    <li>
                        In case of unavailability or any other cause preventing normal
                        operation of the network used to access the service.
                    </li>
                    <li>
                        More generally, in the case of direct or indirect damage caused to the
                        Customer, whatever its nature, resulting from the access, or the use of
                        the RATATOOL service
                    </li>
                    <li>
                        In case of abnormal use or unlawful exploitation of the RATATOOL
                        service.
                    </li>
                    <li>
                        In case of loss by the Customer of his username and/or password or in
                        case of usurpation of his identity.
                    </li>
                </ul>
                <h2>
                    <strong> 8.2 Contents posted by Customers</strong>
                </h2>
                <p>
                    TEDBYTE is not at the origin of the creation of the content published
                    online on the RATATOOL service by the Customers which is distributed under
                    the exclusive responsibility of the Customer.
                </p>
                <p>
                    Without prior moderation of the content posted by the Clients, TEDIBYTE
                    cannot be held responsible for any content that is unlawful, contrary to
                    good moral or constitutes any infringement of the others' rights, including
                    intellectual property rights or the processing of personal data.
                </p>
                <p>
                    TEDBYTE cannot be held responsible for the exchanges made between the
                    Customers on the RATATOOL service.
                </p>
                <p>
                    TEDBYTE cannot be held responsible for any content appearing on the
                    RATATOOL service or sent from the service by any third party.
                </p>
                <p>
                    Thus, with regard to the legal qualifications defined by Article L34-1 of
                    the Postal and Electronic Communications Code, TEDBYTE is qualified as a
                    host for the content published online by Customers on the RATATOOL service.
                </p>
                <p>
                    TEDBYTE is therefore not responsible for the content of the Clients and
                    does not support any obligation to monitor this content.
                </p>
                <h2>
                    <strong>8.3 Public content of the RATATOOL service</strong>
                </h2>
                <p>
                    Despite the care taken in creating and updating the RATATOOL service,
                    TEDBYTE cannot provide any warranty, express or tacit, concerning the
                    information contained on the RATATOOL service of which it is the author.
                </p>
                <p>
                    Consequently, TEDIBYTE cannot be held responsible for any damage, direct or
                    indirect, resulting from any errors, inaccuracies or omissions of the
                    information contained on the RATATOOL service.
                </p>
                <h2>
                    <strong> 8.4 Force majeure</strong>
                </h2>
                <p>
                    TEDIBYTE cannot be held responsible for, or considered as having failed,
                    these Terms and Conditions, for any delay or non-performance, when the
                    cause of the delay or non-performance is related to a case of force majeure
                    as defined by the jurisprudence of French courts and tribunals including
                    interruption, suspension, reduction or disruption of electricity or other
                    means or interruptions of electronic communications networks or in the case
                    of events beyond its control.
                </p>
                <h2>
                    <strong> 8.5 Intervention of </strong>
                    TEDIBYTE
                </h2>
                <p>
                    TEDIBYTE reserves the right to modify the terms, conditions and references
                    herein at any time, particularly in the event of technical, legal or
                    jurisprudential developments or when new services are added.
                </p>
                <h1>
                    9. Rights and liability of the Customer
                </h1>
                <h2>
                </h2>
                <h2>
                    9.1 Responsibility
                </h2>
                <p>
                    The use of the RATATOOL site is done under the sole and entire
                    responsibility of the Customer, he is the only person responsible for the
                    information he communicates on the RATATOOL service.
                </p>
                <p>
                    In the event of abnormal use or unlawful exploitation of the RATATOOL
                    service, the Customer is solely responsible for damages caused to third
                    parties and the consequences of any claims or actions that may arise
                    therefrom.
                </p>
                <p>
                    The Customer also waives any right of recourse against TEDIBYTE in the case
                    of proceedings instituted by a third party against him as a result of the
                    use and/or unlawful exploitation of the RATATOOL service.
                </p>
                <p>
                    The Customer undertakes, in general, to respect all the regulations in
                    force in France.
                </p>
                <h2>
                    9.2 Contents
                </h2>
                <p>
                    Clients undertake not to use the RATATOOL service for:
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        Publish, transmit, share, store or otherwise make available any content
                        contrary to public order, prejudicial, threatening, illegal,
                        defamatory, unauthorized, abusive, insulting, malicious, vulgar,
                        obscene, fraudulent, privacy-invasive, infringing image rights, odious,
                        inciting violence, racial or ethnic hatred or otherwise reprehensible;
                    </li>
                    <li>
                        Create several accounts or usurp the identity of a third party;
                    </li>
                    <li>
                        Transmit any element containing computer viruses or any code, file or
                        computer program designed to interrupt, destroy or limit the
                        functionality of software, hardware or electronic communications;
                    </li>
                    <li>
                        Publish, transmit, share, store or otherwise make available any content
                        that constitutes or encourages a criminal offense or provides
                        instructions on how to perpetrate it, that violates the rights of a
                        third party or is likely to involve the responsibility of third party
                        or to infringe local, national or international laws.
                    </li>
                </ul>
                <p>
                    The Customer is solely responsible for the content that he incorporates
                    into the RATATOOL service, including any content that may be offensive or
                    illegal or likely to infringe the rights of third parties.
                </p>
                <h2>
                    9.3 Access and security
                </h2>
                <p>
                    It is the responsibility of any Customer to take appropriate measures to
                    protect his own data and/or software stored on his mobile or computer
                    equipment against any attack.
                </p>
                <p>
                    The Customer has the right to consult without paying his data hosted on the
                    RATATOOL service and any other information concerning him.
                </p>
                <p>
                    The use of RATATOOL services requires the opening of an account involving
                    the provision of a login and the choice of a password.
                </p>
                <p>
                    The password is personal and confidential. The Customer is solely
                    responsible. He undertakes not to disclose it to third parties and to take
                    all necessary precautions to prevent third parties from having access to
                    it.
                </p>
                <p>
                    The Customer undertakes to inform TEDIBYTE without delay in the event of
                    loss or usurpation of his password.
                </p>
                <p>
                    Failing that, and in the absence of the contrary, any connection or
                    transmission of orders or data made by using the password will be deemed to
                    originate from the Customer and will be under his exclusive responsibility.
                </p>
                <p>
                    To be taken into account, the Customer's complaints must be sent to the
                    following email address: support@ratatool.com
                </p>
                <h2>
                    9.4 Personal data of the Customer
                </h2>
                <p>
                    TEDIBYTE asks any Customer, to communicate some limited personal
                    information’s (name, first name, email address, pseudonym, billing
                    information ...) in order to be able to identify him, to guarantee the
                    uniqueness of personal access and to provide the necessary services for the
                    strict functioning of the RATATOOL service.
                </p>
                <p>
                    All information may be deleted at the request of the Customer and will lead
                    consequently to closing the account. The registration or the payment to
                    subscribe to the RATATOOL service subscription implies acceptance of the
                    collection of evoked data.
                </p>
                <p>
                    The data is used exclusively for the purpose of improving the service or
                    for communicating new products to registered customers.
                </p>
                <h1>
                    10. Subscription Paid Offer
                </h1>
                <h2>
                    10.1 Subscription details
                </h2>
                <p>
                    Customers wishing to subscribe to a paid offer must first be registered as
                    a RATATOOL Member.
                </p>
                <p>
                    To subscribe to a paid offer, the Customer must go on the website in the
                    section Pricing or on the page  <a href="https://www.ratatool.com/pricing">www.ratatool.com/pricing</a> and click the
                    available paying offers.
                </p>
                <p>
                    The Subscription offers the Member the possibility of benefiting from the
                    features described in details in the page Pricing previously described.
                </p>
                <p>
                    When you first subscribe to a paid offer, the Customer automatically
                    benefits from a virtual personal account.
                </p>
                <h2>
                    10.2 Duration and update of subscription
                </h2>
                <p>
                    The Customer who subscribed to a paid offer agrees for the duration
                    determined in the corresponding subscription form (1 or 12 months)
                    including the acceptance of these Terms and Conditions.
                </p>
                <p>
                    At the end of the initial cycle of the subscription, it is automatically
                    renewed under the same conditions of the initial subscription terms, unless
                    the Customer cancel it following the steps described below.
                </p>
                <p>
                    The Subscriber may cancel the automatic renewal at any time by following
                    the procedure described below.
                </p>
                <ul type="disc" style={{'color':'grey'}}>
                    <li>
                        1. Login to the website
                    </li>
                    <li>
                        2. Go to the "Dashboard" and in the Profile section
                    </li>
                    <li>
                        3. In the menu, choose "Subscriptions"
                    </li>
                    <li>
                        3. Click the "Cancel Subscription" button.
                    </li>
                </ul>
                <p>
                    If the Subscriber does not see a "Cancel Subscription" link in their
                    account, this means that the Subscription will not be renewed. He retains
                    the possibility to reactivate it by clicking "Renew subscription" or
                    "Choose another formula" before its expiry.
                </p>
                <p>
                    The request for cancellation of the automatic renewal of a Subscription
                    will be taken into account and effective on the anniversary date, defined
                    as the expiry date in the Registration Form. On the anniversary date, the
                    Subscription will not be renewed and will stop unless the Subscriber
                    chooses to reactivate it by clicking the "Renew subscription" link before
                    this anniversary date.
                </p>
                <p>
                    The Subscriber also retains the ability to modify his Subscription at any
                    time by changing the formula or duration in the corresponding Subscription
                    menu in Profile.
                </p>
                <p>
                    The determination of the new subscription rate is as follows:
                </p>
                <p>
                    • If the Customer choose an alternative offer for the same subscription
                    period, the change will be effective immediately but the settlement of the
                    difference will be calculated at the end of the period at the time of
                    renewal at the beginning of the new period. If a surplus in favor of the
                    customer exists, it is not lost and is reused during subsequent payments.
                </p>
                <p>
                    • If, on the other hand, the Customer choose an alternative offer for
                    another subscription period, the settlement calculated pro rata temporis is
                    then carried out immediately.
                </p>
                <h2>
                    10.3 Price, billing and late or non-payment
                </h2>
                <h4>
                </h4>
                <h4>
                    Price and means of payment
                </h4>
                <p>
                    To subscribe, the member must follow the steps of the subscription
                    procedure proposed on the website. The cost of the Subscription and the
                    means of payment are mentioned in the Subscription Form available online.
                </p>
                <p>
                    Any decision to remit, reduce or apply degressive rates, according to a
                    percentage or a flat rate remains at the sole discretion of TEDIBYTE and
                    only for the service that is subject. Any discounts or rebates that may be
                    granted to the Professional cannot in any case give rise to a vested right
                    for subsequent services.
                </p>
                <h4>
                </h4>
                <h4>
                    Billing - Terms of payment
                </h4>
                <p>
                    Subscriptions are billed on the subscription date. The invoice history is
                    available on the website in the dashboard, by clicking on the "Profile"
                    link, under the heading "Invoices" within the dashboard left menu.
                </p>
                <p>
                    Subscriptions do not assume a firm commitment and may be terminated under
                    the terms of these conditions. The order will be subject to a monthly
                    payment after definitive validation by TEDIBYTE.
                </p>
                <p>
                    Monthly payments are made via the secure payment platform Stripe.
                </p>
                <p>
                    TEDIBYTE is in no way a banking institution and all payment intermediary
                    services, collection for third parties and creation and management of
                    electronic wallet are provided by our partner Stripe, approved and
                    specialized in these benefits. Stripe's General Terms and Conditions of
                    Sale are available at www.stripe.com It is the responsibility of the
                    Customer to check the adequacy of TEDIBTYE's Services to his needs.
                </p>
                <p>
                    The payment transaction is secured using a 128-bit ssl encryption process
                    in order to reinforce all scrambling and encryption processes by optimizing
                    the protection of all personal data related to this payment method.
                </p>
                <p>
                    The first payment is made on the day of the final validation of the
                    subscription. The second withdrawal will occur the month following the date
                    of final validation of the subscription. The amount withdrawn will be
                    composed of the month to fall.
                </p>
                <p>
                    Certain personal data may be transferred to Stripe and/or other
                    organizations for the sole and exclusive purpose of verifying whether the
                    information provided is true.
                </p>
                <p>
                    TEDIBYTE reserves the right to cancel any subscription in the event of a
                    payment incident and no refund can be made once the work has been done.
                </p>
                <p>
                    Any modification of the subscription to the Professional's request will be
                    subject to additional billing.
                </p>
                <h4>
                    Delay or failure to pay
                </h4>
                <p>
                    Any delay or non-payment of the Subscription will result in sending the
                    Subscriber a notification sent by email, indicating that he has a period of
                    five (5) days to regularize his situation. During this period, access to
                    the features corresponding to the Subscription may be suspended.
                </p>
                <p>
                    In the absence of payment of all amounts due within this period, the
                    contract may be terminated automatically by TEDIBYTE.
                </p>
                <p>
                    In the event of late payment of amounts owed by the Client beyond the fixed
                    deadlines, penalties of delays calculated at the interest rate applied by
                    the European Central Bank to its most recent refinancing operation,
                    increased by 10 percentage points , on the amount inclusive of the price of
                    the services on the invoice, will automatically and automatically acquired
                    the Service Provider, without formalities or formal notice and will result
                    in the immediate payment of all sums due to TEDIBYTE by the Customer,
                    without prejudice to any other action that TEDIBYTE would be entitled to
                    bring, as such, against the Customer.
                </p>
                <h1>
                    11. Use of cookies
                </h1>
                <p>
                    The RATATOOL service uses the "cookies" technology. Cookies are useful in
                    particular to facilitate the customization of the Customer's access to the
                    Service. In particular, the RATATOOL service via its website includes a
                    login cookie allowing customers to access the website repeatedly without
                    having to identify each time. The lifetime of the cookie can be limited in
                    time which forces the customer to reconnect regularly for security reasons.
                </p>
                <p>
                    In general, cookies are without damage to the Client's computer. The
                    Customer is free to accept or refuse cookies by configuring his browser.
                    Cookies are anonymous and are in no way used to collect personal data, but
                    only for purposes of connection and improvement of the service.
                </p>
                <h1>
                    12. Hypertext links
                </h1>
                <p>
                    TEDIBYTE specifies that the use of hypertext links may lead the Customer to
                    other websites or applications, independent of the RATATOOL service.
                </p>
                <p>
                    The hypertext links established towards other sites or applications from
                    the RATATOOL service cannot, in any case, engage the responsibility of
                    TEDIBYTE.
                </p>
                <p>
                    In the same way, the insertion of hypertext links to all or part of the
                    RATATOOL service is authorized, non-exclusively and revocable at any time,
                    without TEDIBYTE having to provide any justification, and provided that
                    this link cannot create against the RATATOOL service, a dishonest, false,
                    derogatory or potentially harmful character.
                </p>
                <p>
                    Under this authorization, TEDIBYTE reserves the right to oppose.
                </p>
                <p>
                    TEDIBYTE cannot be held responsible for any direct, indirect or incidental
                    damages resulting from the access or the use of the information coming from
                    third websites.
                </p>
                <h1>
                    12. Pricing conditions
                </h1>
                <p>
                An up-to-date version of the pricing conditions is available on    <a href="https://www.ratatool.com/pricing">www.ratatool.com/pricing</a>
                </p>
                <p>
                    All prices are exclusive of taxes.
                </p>
                <h1>
                    13. Contact
                </h1>
                <p>
                If you have other questions?    <a href="https://www.ratatool.com/contact">Contact us</a>
                </p>

                </div>}
                </Grid.Column> 
            </Grid.Row> 
          </Grid>
        </Segment>
        <br/> <br/> <br/> <br/> <br/> 
        <MainFooter/>
    </ResponsiveContainer>
  )
 }
}




const connectedCguCgv = injectIntl(CguCgv);
export { connectedCguCgv as CguCgv };