import React, { Component } from 'react';
import { Card, Image, Grid,Modal,Icon,Form,Header,Button,Popup,Checkbox,Input,Dropdown,Table,Message,Breadcrumb } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {recipesActions,paymentActions} from '../_actions'
import './Recipes.css';
import {toaster_dispatch,trim,isNumeric,isFloat,VAToptions,MAX_NUMBER_MENUS_FREE_SUBSCRIBER,history} from '../_helpers';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {isEmpty} from "lodash"
import {styles} from './Styles';

import './FloatingMenu.css';

import img_menu from '../Assets/menu.png'
import img_performance from '../Assets/performance.png'
import SideLeftUncoverMenus from './SidebarMenus';

var styleNumbers = {
    'font-family': 'Lato', 
    fontWeight: 'italic',
    fontSize: '18px',
    color:  '#2d9ed6'//'#3492eb',//'#f5a51b',
  };


const inlineImageStyle = {
    img:{
      /*         'filter': 'brightness(50%)', */
          '-webkit-filter': 'brightness(79%)',
          '-moz-filter': 'brightness(79%)',
    },  
};

const  COMBO = <FormattedMessage id="menus.type.select.combo"
                                    defaultMessage="Combos"/>;
const  MENU = <FormattedMessage id="menus.type.select.menus"
                                    defaultMessage="Menus"/>;


const MenuOptions = [
    { key: 'combo', text: COMBO, value: 1 }, //is_selling true
    { key: 'menu', text: MENU, value: 2 }, //is_selling false
  ]

class Menus extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { currency : userIn.user.currency,
                        isMobile: false,
                        actionCreateModal: false,
                        name: "",
                        price: "",
                        vat: 0,
                        cost_total: "",
                        isSelling: false,
                        filtering: false,
                        gridLayout: true,
                      }
        
        this.hideModal = this.hideModal.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.filterTypeList=this.filterTypeList.bind(this);
        this.setGridLayout = this.setGridLayout.bind(this);
        this.setListLayout = this.setListLayout.bind(this);
        props.dispatch(recipesActions.getAllMenu());
        props.dispatch(paymentActions.getSubscriptionState());
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    /*     static getDerivedStateFromProps(props, state) {
        if((props.ingredients!==state.prevIngredients) && (props.ingredients.items)) {
            const ingredients = props.ingredients;
            let stockValue = 0; 
            let nb_items_in_stock = 0;
             ingredients.items.map((i,index) => {
                if (i && i.stock_price){
                    if (i.stock_price > 0) nb_items_in_stock +=1
                    stockValue+=Number(i.stock_price);
                }
            });
            return {stockValue:Math.round(stockValue * 100) / 100,
                    nb_items_in_stock:nb_items_in_stock}
        }
        return null;
    } */

    hideModal() {
        this.setState({ actionCreateModal: false })
    }

    setGridLayout(event){
        event.preventDefault();
        this.setState({gridLayout: true});
    }

    setListLayout(event){
        event.preventDefault();
        this.setState({gridLayout: false});
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({typeMenuSelected: ""});
        this.setState({nameSelected: ""});
    }

    filterNameList(event){
        event.preventDefault();
        
        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.props.menus.filter((item) => {
          return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                    .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
        });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: event.target.value});
        this.setState({typeMenuSelected: ""});
    }

    filterTypeList(event,data){
       event.preventDefault();
       let updatedList = this.props.menus.filter((item) => {
                console.log(data.value)
                if (data.value == 0){
                    return item;
                }else{
                    let is_selling_value = (data.value == 1)?true:false;
                    console.log(is_selling_value)
                    console.log(item)
                    if (item.is_selling == is_selling_value){
                        return item;
                    }
                }
                return null;
        });
        this.setState({newItems: updatedList});
        console.log(updatedList)
        this.setState({filtering: true});
        this.setState({typeMenuSelected: data.value});
        this.setState({nameSelected: ""});
    }

    OnNameChange = (e) => {
        this.setState({ name: e.target.value });
    }

    onCheckboxChange = (e, data) => {
        //console.log(data);
        if (data.id == 'selling')
            this.setState({ isSelling: data.checked});
      }

    OnPriceChange = (e) => {
        this.setState({ price: e.target.value });
    }

    onVATChange = (e,data) => {
        e.preventDefault();
        //console.log(data.value)
        this.setState({ vat: data.value});
    }


    assertNameField(){
        if ((this.state.name != "")
              &&(trim(this.state.name).length>0)
              &&(trim(this.state.name).length<100))
          return true;
        return false;
    }

    assertPriceField(){
        let price = trim(this.state.price).replace(/,/g, '.')
        if ((isFloat(price) || isNumeric(price))
          && (this.state.price != "")
          && Math.trunc(this.state.price).toString().length<7)
          return true;
        return false;
    }

    createMenu(){
        this.setState({actionCreateModal: true});
        this.setState({submitted: false});
    } 

    addMenu = (e) => {
        e.preventDefault();
        const { name,price,isSelling,vat} = this.state;

        this.setState({ submitted: true });

        if (!this.assertNameField()){
            return;
        }

        if (isSelling){
            if (!this.assertPriceField()){
                return;
            }
        }


        if ((!isSelling && name) || (isSelling && name && price) ){
            const menu_infos = {
                         name:trim(name),
                         selling_price:Number(trim(price).replace(/,/g, '.')).toFixed(3),
                         vat:vat,
                         is_selling: isSelling
                       
            }
            this.props.dispatch(recipesActions.addMenu(menu_infos,'/menus/'));
            this.hideModal()
        }
    }



    addMenuList(newMenus){
        const {currency} = this.state;
        return newMenus.map((menu,index) => {
                return (
                        <Table.Row key={index}>
                        <Table.Cell  width={4} textAlign='left'>
                               <span style={{marginLeft:'0.5em'}}>
                                <img src={img_menu}  width={30}/>
                                <Link style={{marginLeft:'0.3em'}} to={'/editmenu/'+menu.id}>
                                    {menu.name.length >= 65 && 
                                    menu.name.substring(0,65) + '..'}
                                    {menu.name.length < 65 && 
                                    menu.name}
                                   {/*  {menu.name} */}</Link></span>
                           </Table.Cell>
                          
                           {menu.is_selling && <Table.Cell  width={3} textAlign='center'>
                               <FormattedNumber value={menu.selling_price} 
                                                style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                           </Table.Cell>}
                           {menu.is_selling && <Table.Cell width={3} textAlign='center'>
                               <FormattedNumber value={menu.margin_avg} style="percent" maximumFractionDigits="2"/>
                           </Table.Cell>}
                           {menu.is_selling && <Table.Cell width={3} textAlign='center'>
                               <FormattedNumber value={menu.food_cost} style="percent" maximumFractionDigits="2"/>
                           </Table.Cell>}
                            {!menu.is_selling && <Table.Cell />}
                            {!menu.is_selling && <Table.Cell />}
                            {!menu.is_selling && <Table.Cell />}
                           <Table.Cell width={3} textAlign='center'>
                                <FormattedNumber value={menu.nb_recipes + menu.nb_ingredients}/>
                           </Table.Cell>
                           <Table.Cell width={3} textAlign='center'>
                                    <FormattedNumber value={menu.cost_total} 
                                                    style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                           </Table.Cell>
                        </Table.Row>
                );
        });
    }


    addMenuCards(newMenus){
        const {currency} = this.state;
        //const {menus} = this.props;
        return newMenus.map((menu,index) => {
                return (
                            <Card as={Link} to={'/editmenu/'+menu.id} 
                                            fluid 
                                            style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}> 
                                <Card.Content style={{'background-color': '#f9f9f9'}} > 
                                    <Card.Header>
                                        {/* <FormattedMessage id="menus.cardX.title"
                                                        defaultMessage='MENU'/> */}{' '}{menu.name.length >= 65 && 
                                                                                        menu.name.substring(0,65) + '..'}
                                                                                        {menu.name.length < 65 && 
                                                                                        menu.name}{/* {menu.name} */}
{/*                                         <span style={{'float':'right'}}>
                                            <Icon name='trash alternate outline' color='grey'/>
                                        </span> '#e8ede9' */}
                                    </Card.Header>
                                </Card.Content>                          
                                <Card.Content  style={{'background-color': '#f9f9f9'}} > 
{/*                                    <div style={inlineImageStyle.img}>
                                        <Image floated='left' size='tiny' src={img_menu}/>
                                   </div> */}
                                   <Image floated='left' size='tiny' src={img_menu} style={{width:'50px','-webkit-filter': 'brightness(79%)','-moz-filter': 'brightness(79%)',}}/>
                                    <div>
                                        <br/>
{/*                                         <div><p> <FormattedMessage id="menus.cardX.elem3.content.title"
                                                                defaultMessage='Total cost :'/>{'  '}
                                                <h style={styleNumbers}>
                                                <FormattedNumber value={menu.cost_total} 
                                                                style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </h>
                                        </p></div> */}
                                        {menu && menu.is_selling &&
                                        <div>
                                            <p> <FormattedMessage id="menus.cardX.elem1.content.title"
                                                                defaultMessage='Selling price :'/>{'  '}
                                                <h style={styleNumbers}>
                                                  <FormattedNumber value={menu.selling_price} 
                                                                  style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </h>
                                            </p>
                                            <p> <FormattedMessage id="menus.cardX.elem2.content.title"
                                                                defaultMessage='Margin average :'/>{'  '}
                                                <h style={styleNumbers}>
                                                    <FormattedNumber value={menu.margin_avg} style="percent" maximumFractionDigits="2"/>
                                                </h>
                                            </p>
                                            <p> <FormattedMessage id="menus.cardX.elem5.content.title"
                                                                defaultMessage='Food cost :'/>{'  '}
                                                <h style={styleNumbers}>
                                                    <FormattedNumber value={menu.food_cost} style="percent" maximumFractionDigits="2"/>
                                                </h>
                                            </p>
                                        </div>}
                                    </div>
                                </Card.Content>
                                <Card.Content  style={{'background-color': '#f9f9f9'}} > 
                                    <div>  
                                    <p style={{'text-align':'left'}}> 
                                         <FormattedNumber value={menu.nb_recipes + menu.nb_ingredients}/>{' '} 
                                         <FormattedMessage id="menus.cardX.elem4.content.title"
                                                                defaultMessage='items'/> 
                                    <span style={{'float':'right'}}> 
                                              <FormattedMessage id="menus.cardX.elem3.content.title"
                                                                defaultMessage='Total cost :'/>{'  '}
                                               {/*  <h style={styleNumbers}> */}
                                                <FormattedNumber value={menu.cost_total} 
                                                                style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                               {/*  </h> */}
                                    </span>
                                    </p>
                                    </div>
                                </Card.Content>
                            </Card>
                    );
                });
    }


    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }


    render() { 
        const { menus,alert,dispatch,intl } = this.props;
        const {submitted,currency,isMobile,isSelling,price,vat,name,filtering,newItems,typeMenuSelected,nameSelected,gridLayout} = this.state;
        let newMenus = menus;
        let className = "floating-menu";

        if (filtering){
            newMenus = newItems
        }
        //console.log(newMenus)

        //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && newMenus){
            newMenus = newMenus.slice(0,MAX_NUMBER_MENUS_FREE_SUBSCRIBER);
        }

        return ( 
                <SideLeftUncoverMenus callback={() => this.createMenu() }>
            
                        <Grid stackable style={{ padding: '0em 0em 0em 1em','font-family': 'Lato','font-size': '14px','color': 'grey'}}>
                            <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="menus.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="menus.link.menus"
                                                              defaultMessage='Menus'/></Breadcrumb.Section>
                            </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1} >
                                <Grid.Column width={8}>
                                   <Header as='h2'>
                                       {/*  <Icon name='block layout' color='blue'/> */}
                                        <img src={img_menu} style={{width:'55px','-webkit-filter': 'brightness(79%)','-moz-filter': 'brightness(79%)',}}/>
                                        <Header.Content>
                                             <FormattedMessage id="menus.top.title.message"
                                                              defaultMessage='Menus - Combos'/>
                                            <Header.Subheader><FormattedMessage id="menus.top.subtitle.message"
                                                              defaultMessage='Create and evaluate'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {/* <Grid.Row columns={2} /> */}
{/*                             <Grid.Row columns={2} />
                            <Grid.Row columns={2} /> */}
                            <Grid.Row columns={3} style={styles.FontLato13}>
                                <Grid.Column width={1}> 
                                        <p style={styles.FontLato13}><FormattedMessage id="menus.filter.view.title"
                                                         defaultMessage='View'/></p>
                                         <Button.Group basic>
                                            <Button icon 
                                                    onClick={this.setGridLayout} 
                                                    active={gridLayout}
                                                    disabled={newMenus && newMenus.length == 0}> 
                                                 <Icon name='grid layout' color='blue'/> 
                                            </Button>
                                            <Button icon 
                                                    onClick={this.setListLayout} 
                                                    active={!gridLayout}
                                                    disabled={newMenus && newMenus.length == 0}>
                                                <Icon name='list' color='teal'/> 
                                            </Button>
                                        </Button.Group>
                                </Grid.Column> 
                                <Grid.Column style={{'marginLeft':'4em'}}> 
                                    <p style={styles.FontLato13}><FormattedMessage id="menus.filter.name.title"
                                                         defaultMessage='Search'/></p>
                                    <FormattedMessage id="menus.filter.byname.placeholder"
                                                        defaultMessage='Type a name'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                //disabled={newMenus && newMenus.length == 0}
                                                style={{minWidth:'25em',maxWidth:'25em', minHeight:'3em'}} ><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column> 
                                   <p style={styles.FontLato13}><FormattedMessage id="menus.filter.type.title"
                                                         defaultMessage='Filter by type'/></p>
                                    <div><FormattedMessage id="menus.filter.type.placeholder"
                                                        defaultMessage='Select a type'>
                                        {placeholder =>  
                                                <Dropdown selection
                                                        options= {MenuOptions} 
                                                        value= {typeMenuSelected}
                                                        placeholder={placeholder}
                                                        onChange={this.filterTypeList}
                                                        clearable
                                                        //disabled={newMenus && newMenus.length == 0}
                                                        style={{minWidth:'20em',maxWidth:'25em', minHeight:'3em','font-size':'14px'}}>
                                                    </Dropdown>}
                                         </FormattedMessage>
                                   </div> 
                                </Grid.Column> 

                            </Grid.Row>
                            <Grid.Row columns={2} />
                            <Grid.Row columns={1} >
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                            <Popup trigger=
                                                    { <Button circular 
                                                            color='teal'
                                                            size='big' 
                                                            icon='add' 
                                                            onClick={this.createMenu.bind(this)}
                                                            ></Button>}
                                                    content={<FormattedMessage id="menus.floatingmenu.item1.title"
                                                                                defaultMessage='Add new menu/combo'/>}
                                                    position='left center'
                                                    inverted
                                            />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            {menus && menus.length == 0 && 
                              <Grid.Row columns={2} >
                                <Grid.Column mobile={18} tablet={16} computer={14}>
                                  <Card.Group itemsPerRow={isMobile?1:2} textAlign={"left"}>
                                        <Card fluid
                                            style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}> 
                                            <Card.Content style={{'background-color': '#f9f9f9'}} > 
                                                        <Card.Header>
                                                        {' '}<FormattedMessage id="menus.card0.fake.content.title"
                                                                              defaultMessage='Menu of summer (sample)'/>
                                                        </Card.Header>
                                            </Card.Content>                          
                                                 <Card.Content  style={{'background-color': '#f9f9f9'}} > 
                                                    <div style={inlineImageStyle.img}>
                                                            <Image floated='left' size='tiny' src={img_menu}/>
                                                    </div>
                                                        <div>
                                                            <br/>
{/*                                                             <div>
                                                                <p> <FormattedMessage id="menus.cardX.elem1.content.title"
                                                                                    defaultMessage='Selling price :'/>{'  '}
                                                                    <h style={styleNumbers}>
                                                                    <FormattedNumber value={14.99} 
                                                                                    style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                    </h>
                                                                </p>
                                                                <p> <FormattedMessage id="menus.cardX.elem2.content.title"
                                                                                    defaultMessage='Margin average :'/>{'  '}
                                                                    <h style={styleNumbers}>
                                                                        <FormattedNumber value={0.67} style="percent" maximumFractionDigits="2"/>
                                                                    </h>
                                                                </p>
                                                            </div> */}
                                                        </div>
                                                    </Card.Content>
                                                    <Card.Content  style={{'background-color': '#f9f9f9'}} > 
                                                        <div>  
                                                        <p style={{'text-align':'left'}}> 
                                                            <FormattedNumber value={15}/>{' '} 
                                                            <FormattedMessage id="menus.cardX.elem4.content.title"
                                                                                    defaultMessage='items'/> 
                                                        <span style={{'float':'right'}}> 
                                                                <FormattedMessage id="menus.cardX.elem3.content.title"
                                                                                    defaultMessage='Total cost :'/>{'  '}
                                                                {/*  <h style={styleNumbers}> */}
                                                                    <FormattedNumber value={34.89} 
                                                                                    style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                {/*  </h> */}
                                                        </span>
                                                        </p>
                                                        </div>
                                                    </Card.Content>
                                                </Card>
                                                <Card fluid
                                                    style={{'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)'}}> 
                                                    <Card.Content style={{'background-color': '#f9f9f9'}} > 
                                                                <Card.Header>
                                                                {' '}<FormattedMessage id="menus.card1.fake.content.title"
                                                                                    defaultMessage='Menu of the day (sample)'/>
                                                                </Card.Header>
                                                    </Card.Content>                          
                                                    <Card.Content  style={{'background-color': '#f9f9f9'}} > 
                                                            <div style={inlineImageStyle.img}>
                                                                    <Image floated='left' size='tiny' src={img_menu}/>
                                                            </div>
                                                                <div>
                                                                    <br/>
                                                                     <div>
                                                                        <p> <FormattedMessage id="menus.cardX.elem1.content.title"
                                                                                            defaultMessage='Selling price :'/>{'  '}
                                                                            <h style={styleNumbers}>
                                                                            <FormattedNumber value={14.99} 
                                                                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                            </h>
                                                                        </p>
                                                                        <p> <FormattedMessage id="menus.cardX.elem2.content.title"
                                                                                            defaultMessage='Margin average :'/>{'  '}
                                                                            <h style={styleNumbers}>
                                                                                <FormattedNumber value={0.67} style="percent" maximumFractionDigits="2"/>
                                                                            </h>
                                                                        </p>
                                                                        <p> <FormattedMessage id="menus.cardX.elem5.content.title"
                                                                                             defaultMessage='Food cost :'/>{'  '}
                                                                            <h style={styleNumbers}>
                                                                                <FormattedNumber value={0.327} style="percent" maximumFractionDigits="2"/>
                                                                            </h>
                                                                        </p>
                                                                    </div> 
                                                                </div>
                                                            </Card.Content>
                                                            <Card.Content  style={{'background-color': '#f9f9f9'}} > 
                                                                <div>  
                                                                <p style={{'text-align':'left'}}> 
                                                                    <FormattedNumber value={8}/>{' '} 
                                                                    <FormattedMessage id="menus.cardX.elem4.content.title"
                                                                                            defaultMessage='items'/> 
                                                                <span style={{'float':'right'}}> 
                                                                        <FormattedMessage id="menus.cardX.elem3.content.title"
                                                                                            defaultMessage='Total cost :'/>{'  '}
                                                                        {/*  <h style={styleNumbers}> */}
                                                                            <FormattedNumber value={4.89} 
                                                                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                        {/*  </h> */}
                                                                </span>
                                                                </p>
                                                                </div>
                                                            </Card.Content>
                                                        </Card>
                                            </Card.Group>
                                        </Grid.Column>
                           </Grid.Row>}
                           {gridLayout && <Grid.Row columns={2} >
                                <Grid.Column mobile={18} tablet={16} computer={14}>
                                    <Card.Group itemsPerRow={isMobile?1:3}  textAlign={"left"}>
                                        {newMenus && this.addMenuCards(newMenus)}  
                                    </Card.Group> 
                                </Grid.Column> 
                                {this.isUnSubscribedUser() && newMenus &&
                                    <Grid.Column mobile={18} tablet={16} computer={14}>
                                                <br/>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="menus.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Menus limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {newMenus && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="menus.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{newMenus.length}{' '}
                                                                <FormattedMessage id="menus.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='menus but your plan only permits'/>{' '}{MAX_NUMBER_MENUS_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="menus.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                    </Grid.Column>} 
                            </Grid.Row>}
                            {!gridLayout &&<Grid.Row  columns={1}>
                                <Grid.Column  mobile={18} tablet={16} computer={14}>
                                  <Table unstackable
                                         compact 
                                         striped 
                                         singleLine 
                                         style={styles.FontLato13Border}>
                                       <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                            <FormattedMessage id="menus.table.header.column.name"
                                                                defaultMessage='Name'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                        <FormattedMessage id="menus.table.header.column.price"
                                                                          defaultMessage='Selling price'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                        <FormattedMessage id="menus.table.header.column.margin"
                                                                          defaultMessage='Margin average'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                        <FormattedMessage id="menus.table.header.column.foodcost"
                                                                          defaultMessage='Food cost'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                        <FormattedMessage id="menus.table.header.column.items"
                                                                          defaultMessage='Items'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                        <FormattedMessage id="menus.table.header.column.totalcost"
                                                                          defaultMessage='Total cost'/>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {newMenus && this.addMenuList(newMenus)}  
                                        </Table.Body>
                                        {this.isUnSubscribedUser() && newMenus &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="menus.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Menus limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {newMenus && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="menus.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{newMenus.length}{' '}
                                                                <FormattedMessage id="menus.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='menus but your plan only permits'/>{' '}{MAX_NUMBER_MENUS_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="menus.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                    </Table> 
                                </Grid.Column>  
                            </Grid.Row>} 
                        </Grid>
                        <Modal open={this.state.actionCreateModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header as='h2' icon='edit' content={<FormattedMessage id="menus.modal.select.create.title"
                                                                                defaultMessage="Create your menu (or combo)"/>}  />
                                <Modal.Content style={{paddingBottom:'17em'}}>
                                    <Form style={{paddingLeft:'0em'}}>
                                            <Form.Field width='10' fluid> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="menus.modal.form.input.title"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="menus.modal.form.input.name.placeholder"
                                                                    defaultMessage='menu name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        fluid
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error={submitted && !this.assertNameField()}
                                                                        />}
                                                    </FormattedMessage>
                                                    {submitted && !this.assertNameField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="menus.form.name.error.message"
                                                                                defaultMessage='name is empty'/>
                                                        </i></label> 
                                                    }
                                            </Form.Field>
                                            <Form.Field width='10'> 
                                                    <FormattedMessage id="menus.modal.form.input.selling.placeholder"
                                                                    defaultMessage='with selling price'>
                                                            {label => 
                                                                <Checkbox label={label}
                                                                        id='selling'
                                                                        defaultChecked = {this.state.isSelling}
                                                                        onChange={this.onCheckboxChange}/>}
                                                    </FormattedMessage>
                                            </Form.Field>
                                            {isSelling &&
                                            <Form.Group>
                                                <Form.Field width='7'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="menus.modal.form.input.price"
                                                                              defaultMessage='Price'/></label>
                                                    <FormattedMessage id="menus.modal.form.input.price.placeholder"
                                                                    defaultMessage='price'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input fluid type="text" 
                                                                        placeholder={placeholder}
                                                                        id="price" 
                                                                        value={price}
                                                                        //disabled={!isSelling}
                                                                        onChange={this.OnPriceChange}
                                                                        error={submitted && !this.assertPriceField()}
                                                                        />}
                                                    </FormattedMessage>
                                                    {submitted && !this.assertPriceField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="menus.modal.input.price.error.message"
                                                                                defaultMessage='price is numeric'/>
                                                        </i></label> 
                                                    }
                                                </Form.Field>
                                                <Form.Field width='3'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="menus.modal.form.input.vat"
                                                                                    defaultMessage='Tax'/></label>
                                                    <FormattedMessage id="menus.modal.form.input.VAT.placeholder"
                                                                defaultMessage='Tax'>
                                                        {placeholder =>                                      
                                                                <Form.Select
                                                                    fluid
                                                                    options={VAToptions} 
                                                                    type="text"
                                                                    placeholder={placeholder}
                                                                    id="VAT"
                                                                    //disabled={!isSelling}
                                                                    value={this.state.vat}
                                                                    onChange={this.onVATChange}
                                                                    focus/>}
                                                 </FormattedMessage> 
                                                </Form.Field>
                                            </Form.Group>}
                                    </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="menus.modal.update.button.no"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonBlue} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="menus.modal.update.button.yes"
                                                                    defaultMessage='Create'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.addMenu(event)}/>
                                </Modal.Actions>
                            </Modal>
                </SideLeftUncoverMenus>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,alert,dispatch } = state;
    const { menus } = state.recipes;
    const { subscription } = state.payment;
    return {
        recipes,
        menus,
        alert,
        dispatch,
        subscription
    };
}

const connectedMenus = injectIntl(connect(mapStateToProps)(Menus));
export { connectedMenus as Menus };
 