import { userConstants } from '../_constants';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.user
      };
    case userConstants.GET_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
      case userConstants.ADD_LEAD_REQUEST:
        return {
          subscribing: true
        };
      case userConstants.ADD_LEAD_SUCCESS:
        return {
          subscribed: true
        };
      case userConstants.ADD_LEAD_FAILURE:
        return { 
          error: action.error
        };
      case userConstants.GET_ORGANISATION_REQUEST:
          return {
            ...state,
            loading: true
          };
      case userConstants.GET_ORGANISATION_SUCCESS:
        let options=[];

        let sorted_orgs = action.user;
/*         if (sorted_orgs){
          sorted_orgs = sorted_orgs.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }) 
        }*/
  
        sorted_orgs.map((item,index)=>
            options[index]= {key:index,text:item.name,value:item.id,selected: false});

        return {
            ...state,
            orgs_items: sorted_orgs,
            orgs_options : options
          };
      case userConstants.GET_ORGANISATION_FAILURE:
          return { 
            ...state,
            error: action.error
      };
      case userConstants.ADD_ORGANISATION_REQUEST:
          return {
            ...state,
            loading: true
          };
      case userConstants.ADD_ORGANISATION_SUCCESS:
          return {
            ...state,
            loading: false
          };
      case userConstants.ADD_ORGANISATION_FAILURE:
          return { 
            ...state,
            error: action.error
      };
      case userConstants.GET_INVITATION_REQUEST:
        return {
          ...state,
          loading: true
        };
      case userConstants.GET_INVITATION_SUCCESS:

        return {
            ...state,
            invite_items: action.user,
          };
      case userConstants.GET_INVITATION_FAILURE:
            return { 
              ...state,
              error: action.error
        };
      case userConstants.SEND_INVITATION_REQUEST:
          return {
            ...state,
            loading: true
          };
      case userConstants.SEND_INVITATION_SUCCESS:
          return {
              ...state,
              loading: false
            };
      case userConstants.SEND_INVITATION_FAILURE:
              return { 
                ...state,
                error: action.error
          };
      case userConstants.UPDATE_INVITATION_REQUEST:
          return {
            ...state,
            loading: true
          };
      case userConstants.UPDATE_INVITATION_SUCCESS:
  
          return {
              ...state,
              loading: false
            };
      case userConstants.UPDATE_INVITATION_FAILURE:
              return { 
                ...state,
                error: action.error
          };
      case userConstants.DELETE_INVITATION_REQUEST:
            return {
              ...state,
              loading: true
            };
      case userConstants.DELETE_INVITATION_SUCCESS:
    
            return {
                ...state,
                loading: false
              };
      case userConstants.DELETE_INVITATION_FAILURE:
                return { 
                  ...state,
                  error: action.error
            };
/*       case userConstants.GET_POS_REQUEST:
          return {
            ...state,
            loading: true
          };
      case userConstants.GET_POS_SUCCESS:
        return {
            ...state,
            pos: action.user,
          };
      case userConstants.GET_POS_FAILURE:
          return { 
            ...state,
            error: action.error
      }; */
      default:
        return state
  }
}