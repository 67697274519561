import { categoryRecipeConstants } from '../_constants';
import { categoryRecipeService } from '../_api';
import { alertActions } from './';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const categoryRecipeActions = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count,
    do_count_recipes,
    //sendPOS,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.getAll()
            .then(
                categoriesRecipe => dispatch(success(categoriesRecipe)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryRecipeConstants.GETALL_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.GETALL_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.GETALL_FAILURE, error } }
}

function get(id,link) {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.get(id)
            .then(
                categoriesRecipe => {
                    dispatch(success(categoriesRecipe));
                    if (link){
                        history.push(link);
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryRecipeConstants.GET_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.GET_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.GET_FAILURE, error } }
}

function add(category) {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.add(category)
            .then(
                categoriesRecipe =>{
                    dispatch(success(categoriesRecipe));
                    let title_text = <FormattedMessage id="categoryrecipe.actions.category.add.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryrecipe.actions.category.add.success.content"
                                                         defaultMessage="Category Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "categoryrecipe.actions.category.add.success.title",
                                                   content:"categoryrecipe.actions.category.add.success.content"}));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/categoriesrecipe');
                }
            );
    };

    function request() { return { type: categoryRecipeConstants.ADD_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.ADD_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.ADD_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.delete(id)
            .then(
                categoriesRecipe => { 
                    dispatch(success(id,categoriesRecipe));
                    let title_text = <FormattedMessage id="categoryrecipe.actions.category.delete.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryrecipe.actions.category.delete.success.content"
                                                         defaultMessage="Category Deleted Successfully"/>
                                
                    dispatch(alertActions.success({title: "categoryrecipe.actions.category.delete.success.title",
                                                   content:"categoryrecipe.actions.category.delete.success.content"}));
                },
                error => {
                    dispatch(failure(error));
                     //dispatch(alertActions.error(error));
                     dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                  content:error.message,
                                                  stringMode: true }));
                    history.push('/');
                    history.push('/categoriesrecipe');
                }
            );
    };

    function request() { return { type: categoryRecipeConstants.DELETE_REQUEST } }
    function success(id,categoriesRecipe) { return { type: categoryRecipeConstants.DELETE_SUCCESS, id:id , categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.count()
            .then(
                categoriesRecipe => dispatch(success(categoriesRecipe)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryRecipeConstants.COUNT_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.COUNT_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.COUNT_FAILURE, error } }
}


function do_count_recipes() {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.do_count_recipes()
            .then(
                categoriesRecipe => dispatch(success(categoriesRecipe)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: categoryRecipeConstants.COUNT_RECIPES_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.COUNT_RECIPES_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.COUNT_RECIPES_FAILURE, error } }
}

function update(id,name,is_active,notify=true) {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.update(id,name,is_active)
            .then(
                categoriesRecipe =>{
                    dispatch(success(id,categoriesRecipe));
                    let title_text = <FormattedMessage id="categoryrecipe.actions.category.update.success.title"
                                                       defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryrecipe.actions.category.update.success.content"
                                                         defaultMessage="Category Updated Successfully"/>
                    if (notify){
                        dispatch(alertActions.success({title: "categoryrecipe.actions.category.update.success.title",
                                                        content:"categoryrecipe.actions.category.update.success.content"}));
                    }        
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                    history.push('/');
                    history.push('/categoriesrecipe');
                }
            );
    };

    function request() { return { type: categoryRecipeConstants.UPDATE_REQUEST } }
    function success(id,categoriesRecipe) { return { type: categoryRecipeConstants.UPDATE_SUCCESS,id:id, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.UPDATE_FAILURE, error } }
}


/* function sendPOS(id, silent=false) {
    return dispatch => {
        dispatch(request());

        categoryRecipeService.sendPOS(id)
            .then(
                categoriesRecipe => {
                    dispatch(success(categoriesRecipe))
                    let title_text = <FormattedMessage id="categoryrecipe.actions.order.send.success.title"
                                                    defaultMessage="Category Action"/>
                    let content_text = <FormattedMessage id="categoryrecipe.actions.order.send.success.content"
                                                            defaultMessage="Category sent successfully"/>
                    dispatch(alertActions.success({title:'categoryrecipe.actions.order.send.success.title',
                                                  content:'categoryrecipe.actions.order.send.success.content'}));
                
                    // if (silent == false){
                    //    history.push('/');
                    //    //history.push('/orderslist');
                    //    history.push('/orderdetails/'+id+'/');
                    //}
                },
                error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                     content:error.message,
                                                     stringMode: true }));
                        history.push('/');
                        history.push('/categoriesrecipe/');
                    }
            );
    };

    function request() { return { type: categoryRecipeConstants.SEND_POS_REQUEST } }
    function success(categoriesRecipe) { return { type: categoryRecipeConstants.SEND_POS_SUCCESS, categoriesRecipe } }
    function failure(error) { return { type: categoryRecipeConstants.SEND_POS_FAILURE, error } }
}
 */
