export const ingredientConstants = {
    GETALL_REQUEST: 'INGREDIENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'INGREDIENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'INGREDIENTS_GETALL_FAILURE',

    GET_REQUEST: 'INGREDIENTS_GET_REQUEST',
    GET_SUCCESS: 'INGREDIENTS_GET_SUCCESS',
    GET_FAILURE: 'INGREDIENTS_GET_FAILURE',

    ADD_REQUEST: 'INGREDIENTS_ADD_REQUEST',
    ADD_SUCCESS: 'INGREDIENTS_ADD_SUCCESS',
    ADD_FAILURE: 'INGREDIENTS_ADD_FAILURE',

    ADD_FROM_FILE_REQUEST: 'INGREDIENTS_ADD_FROM_FILE_REQUEST',
    ADD_FROM_FILE_SUCCESS: 'INGREDIENTS_ADD_FROM_FILE_SUCCESS',
    ADD_FROM_FILE_FAILURE: 'INGREDIENTS_ADD_FROM_FILE_FAILURE',

    UPDATE_REQUEST: 'INGREDIENTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'INGREDIENTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'INGREDIENTS_UPDATE_FAILURE',

    DELETE_REQUEST: 'INGREDIENTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'INGREDIENTS_DELETE_SUCCESS',
    DELETE_WARN: 'INGREDIENTS_DELETE_WARN',
    DELETE_FAILURE: 'INGREDIENTS_DELETE_FAILURE',

    PRICE_EVOLUTIONS_REQUEST: 'INGREDIENTS_PRICE_EVOLUTIONS_REQUEST',
    PRICE_EVOLUTIONS_SUCCESS: 'INGREDIENTS_PRICE_EVOLUTIONS_SUCCESS',
    PRICE_EVOLUTIONS_FAILURE: 'INGREDIENTS_PRICE_EVOLUTIONS_FAILURE',

    HISTORY_REQUEST: 'INGREDIENTS_HISTORY_REQUEST',
    HISTORY_SUCCESS: 'INGREDIENTS_HISTORY_SUCCESS',
    HISTORY_FAILURE: 'INGREDIENTS_HISTORY_FAILURE',

    RECIPES_REQUEST: 'INGREDIENTS_RECIPES_REQUEST',
    RECIPES_SUCCESS: 'INGREDIENTS_RECIPES_SUCCESS',
    RECIPES_FAILURE: 'INGREDIENTS_RECIPES_FAILURE',

    UPDATE_NUTRITION_REQUEST: 'INGREDIENTS_UPDATE_NUTRITION_REQUEST',
    UPDATE_NUTRITION_SUCCESS: 'INGREDIENTS_UPDATE_NUTRITION_SUCCESS',
    UPDATE_NUTRITION_FAILURE: 'INGREDIENTS_UPDATE_NUTRITION_FAILURE',

    COUNT_REQUEST: 'INGREDIENTS_COUNT_REQUEST',
    COUNT_SUCCESS: 'INGREDIENTS_COUNT_SUCCESS',
    COUNT_FAILURE: 'INGREDIENTS_COUNT_FAILURE',

    EXPORT_CSV_REQUEST: 'INGREDIENTS_EXPORT_CSV_REQUEST',
    EXPORT_CSV_SUCCESS: 'INGREDIENTS_EXPORT_CSV_SUCCESS',
    EXPORT_CSV_FAILURE: 'INGREDIENTS_EXPORT_CSV_FAILURE',

    EXPORT_XLSX_REQUEST: 'INGREDIENTS_EXPORT_XLSX_REQUEST',
    EXPORT_XLSX_SUCCESS: 'INGREDIENTS_EXPORT_XLSX_SUCCESS',
    EXPORT_XLSX_FAILURE: 'INGREDIENTS_EXPORT_XLSX_FAILURE',

    UPDATE_STOCK_REQUEST: 'INGREDIENTS_UPDATE_STOCK_REQUEST',
    UPDATE_STOCK_SUCCESS: 'INGREDIENTS_UPDATE_STOCK_SUCCESS',
    UPDATE_STOCK_FAILURE: 'INGREDIENTS_UPDATE_STOCK_FAILURE',

    RESET_STOCK_REQUEST: 'INGREDIENTS_RESET_STOCK_REQUEST',
    RESET_STOCK_SUCCESS: 'INGREDIENTS_RESET_STOCK_SUCCESS',
    RESET_STOCK_FAILURE: 'INGREDIENTS_RESET_STOCK_FAILURE',

    INVENTORY_REQUEST: 'INGREDIENTS_INVENTORY_REQUEST',
    INVENTORY_SUCCESS: 'INGREDIENTS_INVENTORY_SUCCESS',
    INVENTORY_FAILURE: 'INGREDIENTS_INVENTORY_FAILURE',

    WEIGHT_VARIATION_REQUEST: 'INGREDIENTS_WEIGHT_VARIATION_REQUEST',
    WEIGHT_VARIATION_SUCCESS: 'INGREDIENTS_WEIGHT_VARIATION_SUCCESS',
    WEIGHT_VARIATION_FAILURE: 'INGREDIENTS_WEIGHT_VARIATION_FAILURE',

    UPDATE_IMAGE_REQUEST: 'INGREDIENTS_UPDATE_IMAGE_REQUEST',
    UPDATE_IMAGE_SUCCESS: 'INGREDIENTS_UPDATE_IMAGE_SUCCESS',
    UPDATE_IMAGE_FAILURE: 'INGREDIENTS_UPDATE_IMAGE_FAILURE',

    GET_IMAGE_REQUEST: 'INGREDIENTS_GET_IMAGE_REQUEST',
    GET_IMAGE_SUCCESS: 'INGREDIENTS_GET_IMAGE_SUCCESS',
    GET_IMAGE_FAILURE: 'INGREDIENTS_GET_IMAGE_FAILURE',

    DELETE_IMAGE_REQUEST: 'INGREDIENTS_DELETE_IMAGE_REQUEST',
    DELETE_IMAGE_SUCCESS: 'INGREDIENTS_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'INGREDIENTS_DELETE_IMAGE_FAILURE',

    EXTRA_SUPPLIER_ADD_REQUEST: 'INGREDIENTS_EXTRA_SUPPLIER_ADD_REQUEST',
    EXTRA_SUPPLIER_ADD_SUCCESS: 'INGREDIENTS_EXTRA_SUPPLIER_ADD_SUCCESS',
    EXTRA_SUPPLIER_ADD_FAILURE: 'INGREDIENTS_EXTRA_SUPPLIER_ADD_FAILURE',

    EXTRA_SUPPLIER_EDIT_REQUEST: 'INGREDIENTS_EXTRA_SUPPLIER_EDIT_REQUEST',
    EXTRA_SUPPLIER_EDIT_SUCCESS: 'INGREDIENTS_EXTRA_SUPPLIER_EDIT_SUCCESS',
    EXTRA_SUPPLIER_EDIT_FAILURE: 'INGREDIENTS_EXTRA_SUPPLIER_EDIT_FAILURE',

    EXTRA_SUPPLIER_DELETE_REQUEST: 'INGREDIENTS_EXTRA_SUPPLIER_DELETE_REQUEST',
    EXTRA_SUPPLIER_DELETE_SUCCESS: 'INGREDIENTS_EXTRA_SUPPLIER_DELETE_SUCCESS',
    EXTRA_SUPPLIER_DELETE_FAILURE: 'INGREDIENTS_EXTRA_SUPPLIER_DELETE_FAILURE',

    DEFAULT_SUPPLIER_REQUEST: 'INGREDIENTS_DEFAULT_SUPPLIER_REQUEST',
    DEFAULT_SUPPLIER_SUCCESS: 'INGREDIENTS_DEFAULT_SUPPLIER_SUCCESS',
    DEFAULT_SUPPLIER_FAILURE: 'INGREDIENTS_DEFAULT_SUPPLIER_FAILURE',

    STOCK_AREA_REQUEST: 'INGREDIENTS_STOCK_AREA_REQUEST',
    STOCK_AREA_SUCCESS: 'INGREDIENTS_STOCK_AREA_SUCCESS',
    STOCK_AREA_FAILURE: 'INGREDIENTS_STOCK_AREA_FAILURE',

    CATEGORY_ALL_REQUEST: 'INGREDIENTS_CATEGORY_ALL_REQUEST',
    CATEGORY_ALL_SUCCESS: 'INGREDIENTS_CATEGORY_ALL_SUCCESS',
    CATEGORY_ALL_FAILURE: 'INGREDIENTS_CATEGORY_ALL_AILURE',

    STOCKAREA_ALL_REQUEST: 'INGREDIENTS_STOCKAREA_ALL_REQUEST',
    STOCKAREA_ALL_SUCCESS: 'INGREDIENTS_STOCKAREA_ALL_SUCCESS',
    STOCKAREA_ALL_FAILURE: 'INGREDIENTS_STOCKAREA_ALL_FAILURE',

    UPDATE_PRICE_REQUEST: 'INGREDIENTS_UPDATE_PRICE_REQUEST',
    UPDATE_PRICE_SUCCESS: 'INGREDIENTS_UPDATE_PRICE_SUCCESS',
    UPDATE_PRICE_FAILURE: 'INGREDIENTS_UPDATE_PRICE_FAILURE',
    
};