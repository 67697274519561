import React, { Component } from 'react';
import { recipesActions, paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message,Button, Grid, Header, Image,Icon,Modal,Form,Breadcrumb} from 'semantic-ui-react';
import {history,toaster_info,Capitalize} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_antique from '../Assets/checklist.png'
import img_performance from '../Assets/performance.png'
import SideLeftUncoverMenu from './SidebarMenu';



/* const PDF_MESSAGE_TITLE = <FormattedMessage id="downloadmenu.toaster.message.title"
                                                  defaultMessage='PDF Export/Create'/>
         
const PDF_MESSAGE_CONTENT = <FormattedMessage id="downloadmenu.toaster.message.content"
                                                  defaultMessage='Document creation ongoing ...'/> */


const optionsExport = [
    { key: 1, 
      text: <FormattedMessage id="downloadmenu.format.choice.elem1"
                              defaultMessage='PDF File'/>,
      icon: 'file pdf',
      value: 'pdf' },
]


class DownloadMenu extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            format: 'pdf',
            activeModalPremium: false,  
         }

        props.dispatch(recipesActions.getMenu(props.match.params.menuId));
        props.dispatch(paymentActions.getCustomerMembership());
    }

  hideModal() {
    this.setState({ activeModalPremium: false });
  }

  onFormatChange = (e,data) => {
    e.preventDefault();
    this.setState({ format: data.value });
  }

  exportMenu () {
    const { format } = this.state;
    const {customer,menu,intl} = this.props;
    let user = null;
    let membership = null;
        
    if (customer){
        membership = customer.membership;
        user = customer.user;
        //console.log(user);
        //console.log(membership);
    }

    if (membership && user &&
        ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
    {
        let properties = {
            id: menu.id
        }
        this.props.dispatch(recipesActions.downloadMenu(properties));
        toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                     intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))  
    
    }
    else {
        this.setState({ activeModalPremium: true});
    }  
  }
 
  onSubmit = (e) => {
        //e.preventDefault();
        this.exportMenu();
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }

  upgradeSubscription = () => {
    history.push({pathname: '/profilepremium', state: {toggled: false}})
  }

  render() {
    const { customer,menu } = this.props;
    let user = null;
    let membership = null;

    if (customer){
      membership = customer.membership;
      user = customer.user;
    }

    return (
      <SideLeftUncoverMenu id={this.props.match.params.menuId}>
         <Grid stackable style={styles.FontLato12}>
         <Grid.Row columns={1}>
                                <Breadcrumb  style={{background:'#fcfcfc'}}>
                                    <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="downloadmenu.link.dashboard"
                                                                defaultMessage='Dashboard'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/menus/'}><FormattedMessage id="downloadmenu.link.menus"
                                                                defaultMessage='Menus'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/editmenu/'+this.props.match.params.menuId}>{menu && menu.name}</Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section active><FormattedMessage id="downloadmenu.link.export"
                                                                defaultMessage='Export'/></Breadcrumb.Section>
                                </Breadcrumb>
            </Grid.Row>
           <Grid.Row>
               <Grid.Column width={8}>
                    <Header as='h2'>
                              <Icon name='download' size='mini' color='blue'/>
                              <Header.Content>
                                  <FormattedMessage id="downloadmenu.form.header.title"
                                                    defaultMessage='Export your menu/combo'/>
                                  <Header.Subheader><FormattedMessage id="downloadmenu.form.subheader.title"
                                                    defaultMessage='Menus'/></Header.Subheader>
                                </Header.Content>
                    </Header>
               </Grid.Column>
           </Grid.Row>
           <Grid.Row/>
           <Grid.Row columns={2}>
              <Grid.Column /*width={5}*/ mobile={10} tablet={7} computer={5}>
                     <Form fluid>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label><FormattedMessage id="downloadmenu.form.format.select.title"
                                                         defaultMessage='Format'/>
                                </label>
                                <FormattedMessage id="downloadmenu.form.format.select.placeholder"
                                                defaultMessage='Format'>
                                    {placeholder =>   
                                            <Form.Select
                                                    options={optionsExport}
                                                    placeholder={placeholder} 
                                                    value={this.state.format}
                                                    onChange={this.onFormatChange}
                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                            />}
                                </FormattedMessage>  
                            </Form.Field>
                        </Form.Group>
                        <br/><br/>
                        <Form.Group widths='equal'>
                            <Form.Button style={styles.ButtonGreen} //basic color='teal' 
                                    fluid onClick={this.onSubmit}>
                                    {<FormattedMessage id="downloadmenu.form.submit.ok"
                                                        defaultMessage="Create"/>}
                            </Form.Button>
                            <Form.Button style={styles.ButtonGrey} //basic color='grey'
                                    fluid  onClick={this.cancelAction}>
                                    {<FormattedMessage id="downloadmenu.form.submit.cancel"
                                                       defaultMessage="Cancel"/>}
                            </Form.Button>
                            <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="downloadmenu.form.header.title"
                                                    defaultMessage='Export your menu/combo'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="downloadmenu.premium.message.upgradeplan"
                                                                defaultMessage='PREMIUM Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="downloadmenu.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{customer && customer.membership && customer.membership.name}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="downloadmenu.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="downloadmenu.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button  color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="downloadmenu.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
                        </Form.Group>
                    </Form>
             </Grid.Column>
             <Grid.Column width={5}>
                 <img alt='antique' id='test' src={img_antique}/>
             </Grid.Column>
          </Grid.Row>
        </Grid>
      </SideLeftUncoverMenu>
    );
  }
}

function mapStateToProps(state) {
  const { dispatch,alert } = state;
  const { customer } = state.payment;
  const {loading,menu} = state.recipes;
  return {
        loading,
        menu,
        customer,
        dispatch,
        alert
  };
}

const connectedDownloadMenu = injectIntl(connect(mapStateToProps)(DownloadMenu));
export { connectedDownloadMenu as DownloadMenu };
